import { useState, } from "react";
import SubForm from "./SubForm";
import { CompanyNameRegex, ContactNumberRegex, ModelNumberRegex, validateRegex, VehicleNumberRejex } from "../../../utils/validationHelper";
import { toast } from "react-toastify";

const VehicleInsurance = (props) => {
  const [contact, setContact] = useState("");
  const [contactError, setContactError] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [vehicleNumberError, setVehicleNumberError] = useState("");
  const [model, setModel] = useState("");
  const [modelError, setModelError] = useState("");
  const [company, setCompany] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    const queryParameter = new URLSearchParams(window.location.search);
    const isMob = queryParameter.get("isMob");

    if (!props.profile) {
      props.openLoginDialog()
      return;
    }

    e.preventDefault();
    if (vehicleNumberError || contactError || modelError || companyError) {
      setLoading(false);
      return;
    }
    setLoading(true);
    const selectedWheeler = document.querySelector('input[name="inlineRadioOptions"]:checked');
    const wheelerType = selectedWheeler ? selectedWheeler.value : '';

    const pid = queryParameter.get("pid")
    const pcontact = queryParameter.get('pcontact');
    const data = new FormData();
    data.append('Wheeler',wheelerType);
    data.append('RegistrationNumber', vehicleNumber);
    data.append('Contact', contact);
    data.append('ModelNumber', model);
    data.append('VehicleCompany', company);
    data.append('LogedInID', props.profile && props.profile.id || pid);
    data.append('LogedInContact', props.profile && props.profile.mobile || pcontact);
   
    try {
      fetch('https://script.google.com/macros/s/AKfycbxmsmxumRnLVneioNnFPNCObom03hylX5m-GAdPAypIDqxeKxakUOQQJAMIIFDLdCMR/exec', {
        method: 'POST',
        body: data,
      })
        .then(response => {
          if (response.ok === true) {
            setFormSubmitted(true);
          }
          else {
            alert("error while submitting data")
          }
        })
        .catch((error)=>{
          console.log("Error",error);
          toast.error("Something went wrong. Please try again later");
          setFormSubmitted(false);
          setLoading(false);
        })
    }
    catch(error){
      toast.error("Something went wrong. Please try again later");
    }
  };
  const onChangeVehicleNum = (value) => {
    if (validateRegex(value, VehicleNumberRejex)) {
      setVehicleNumber(value);
      setVehicleNumberError("");
    } else {
      setVehicleNumberError("Invalid Vehicle Number");
    }
  };
  const onChangeModelNum = (value) => {
    if (validateRegex(value, ModelNumberRegex)) {
      setModel(value);
      setModelError("")
    }
    else {
      setModelError("Invalid Model Number");
    }
  }
  const onChangeCompany = (value) => {
    if (validateRegex(value, CompanyNameRegex)) {
      setCompany(value);
      setCompanyError("")
    }
    else {
      setCompanyError("Invalid Company Name");
    }
  }
  const onChangeContactNum = (value) => {
    if (validateRegex(value, ContactNumberRegex)) {
      setContact(value);
      setContactError("")
    }
    else {
      setContactError("Invalid/ Mobile Number should be 10 digits");
    }
  }
  if (formSubmitted) {
    // console.log("change to new page ");
    return <SubForm />;
  } else {
    return (
      <>
        <section className="service-categories">
          <form onSubmit={handleSubmit}>
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-1 col-md-2 col-lg-2 col-xl-2 d-flex flex-column justify-content-start"></div>
                <div className="col-12 col-sm-10 col-md-8 col-lg-8 col-xl-8 d-flex flex-column justify-content-start border border-black rounded">
                  <div className="row ">
                    <div className="col-12 d-flex text-center justify-content-center py-4 pt-4">
                      <h3 className="text-align-center">Vehicle Insurance Inquiry</h3>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-lg-6 col-xl-6 d-flex pb-4">
                      <div className="form-check form-check-inline pr-4">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="Wheeler_2" value="2 Wheeler" required />
                        <label className="form-check-label" htmlFor="Wheeler2">2-Wheeler</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="Wheeler_4" value="4 Wheeler" required />
                        <label className="form-check-label" htmlFor="Wheeler4">4-Wheeler</label>
                      </div>
                    </div>
                    <div className="col-6"></div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column pb-4 ">
                          <label htmlFor="Reg_num">Vehicle Registration Number <span>*</span></label>
                          <input type="text" className="form-control" id="Reg_num" placeholder="KA12AB1234"
                            onChange={(e) => onChangeVehicleNum(e.target.value)} required />
                          {vehicleNumberError && (<p className="text-danger pl-2 text-sm mb-1">{vehicleNumberError}</p>)}
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column pb-4">
                          <label htmlFor="Model_num"> Model <span> *</span></label>
                          <input type="text" className="form-control" placeholder="Enter Vehicle Model" id="Model_num"
                            onChange={(e) => onChangeModelNum(e.target.value)} required />
                          {modelError && (<p className="text-danger text-sm pl-2 mb-1">{modelError}</p>)}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 ">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column pb-3">
                          <label htmlFor="Vehicle_company">Company<span> *</span></label>
                          <input className="form-control" type="text" placeholder="Enter Vehicle Company" id="Vehicle_company"
                            onChange={(e) => onChangeCompany(e.target.value)} required />
                          {companyError && (<p className="text-danger text-sm pl-2">{companyError}</p>)}
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 d-flex flex-column">
                          <label htmlFor="Contact_number">Contact Number</label>
                          <div className="input-group ">
                            <span className="input-group-text bg-light" id="inputGroup-sizing-default">+91</span>
                            <input className="form-control" type="number" placeholder="Enter Contact Number" id="Contact_number"
                              onChange={(e) => onChangeContactNum(e.target.value)}></input>
                          </div>
                          {contactError && (<p className="text-danger text-sm pl-2">{contactError}</p>)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr></hr>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 "></div>
                    <div className="form-check m-3">
                      <input className="form-check-input" type="checkbox" id="checkbox3" required />
                      <label className="form-check-label">
                        <h6>
                          Allow our team or third parties to contact you regarding your inquiry.                         
                        </h6>
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div>
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-1 col-sm-1 col-md-1 col-xl-2 col-xxl-2"></div>
                    <div className="col-10 col-sm-10 col-md-10 col-xl-8 col-xxl-8">
                      <div className="pb-3 d-flex justify-content-center">
                        <button className="animated-btn mb-3 mt-4 mt-sm-3 home-btn w-100" type="submit" disabled={loading}>
                          {loading && (
                            <span className="spinner-grow spinner-grow-sm mx-1" role="status" aria-hidden="true"></span>
                          )}
                         Submit Details
                        </button>
                      </div>
                    </div>
                    <div className="col-1 col-sm-1 col-md-1 col-xl-2 col-xxl-2"></div>
                  </div>
                </div>
                <div className="col-12 col-sm-1 col-md-2 col-lg-2 col-xl-2 d-flex flex-column justify-content-start"></div>
              </div>
            </div>
          </form>
        </section>
      </>
    );
  }
}
export default VehicleInsurance;
