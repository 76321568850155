import React from "react";
import WinnersPatrons from "../winners-list/Patrons";
import TestimonialsMetaData from "../../seo/TestimonialsMetaData";

export default function DrawWinners() {
  return (
    <div>
      <TestimonialsMetaData />
      <WinnersPatrons />
    </div>
  );
}
