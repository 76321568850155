import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import popup_bg from "../../../assets/profile/popup.png";
import SuccessBg from '../../../assets/voucher/ic_confetti.json'
import Lottie from "lottie-react";

export default function WrpCreditModal(props) {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body
          className="profile-info-box w-100 text-center success-modal p-md-5 p-3"
          style={{ backgroundImage: `url(${popup_bg})` }}
        >
          <div className=" wrp-success-modal-body m-3 pb-5">
            <h3 className="text-bold mb-3 text-grey">CONGRATS!</h3>
            <h4 className="m-0 text-grey">YOU WON</h4>
            <h1 className="text-bold text-gold m-0">{props?.wrp} WINDS Coins</h1>
            <h6 classNames="text-bold text-grey">
              {props?.desc}
            </h6>
            <button
              onClick={()=>setShow(false)}
              className="animated-btn w-75 wrp-success-btn"
              style={{zIndex:"9"}}
            >
              OK
            </button>
          </div>
          <Lottie className="wrp-success-bg-lottie" animationData={SuccessBg} loop={false} />
        </Modal.Body>
      </Modal>
    </>
  );
}
