const INITIAL_STATE = {
  latitude: null,
  longitude: null,
  formatted_address: null,
  formatted_location: null,
  errorCode: null,
};

function locationReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SET_LOCATION":
      return {
        ...state,
        latitude: action.latitude,
        longitude: action.longitude,
        errorCode: null,
      };
    case "SET_FORMATTED_ADDRESS":
      return {
        ...state,
        formatted_address: action.formatted_address,
        errorCode: null,
      };
    case "SET_FORMATTED_LOCATION":
        return {
          ...state,
          formatted_location: action.formatted_location,
          errorCode: null,
        };
    case "ACCESS_DENIED":
      return {
        ...state,
        errorCode: 1,
      };
    case "RESET_ACCESS_DENIED":
      return {
        ...state,
        errorCode: action.errorCode,
      };
    default:
      return state;
  }
}

export default locationReducer;
