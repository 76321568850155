import React, { Component } from 'react';
import MultiItemCarousel from '../../../components/MultiItemCarousel';
import { Link } from "react-router-dom";

export default class ShoppingSitesCarousel extends Component{

    render(){

        return(
            <MultiItemCarousel>
                 {
                  this.props.affiliate.map((item,index)=>{
                    return(
                      <div key={"shopping-site-"+index}>
                        <div style={{padding:'15px'}}>
                          <Link to={`/shopping-sites/${item.id}`}>
                            <div className="card mb-4 card--carousel-item card--carousel-item--shopping-sites" style={{borderRadius:'8px', backgroundImage:`url(${item.cover_image_url})`,backgroundRepeat:'no-repeat', backgroundSize:'100% auto'}}>
                                  <div className="card-body">
                                    <div className="logo" style={{borderRadius:'8px', background:'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.79) 66.41%, #FFFFFF 100%)'}}>
                                      <img src={item.web_image_url} alt={item.name} title={item.name} />
                                    </div>
                                  </div>
                              </div>
                          </Link>
                        </div>
                      </div>
                    )
                  })
                }
            </MultiItemCarousel>
        );
    }
    
}