import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import TextField, { HelperText, Input } from '@material/react-text-field';
import profileClientX from '../../../utils/api/profileClientX';
import InlineLoader from '../../../components/InlineLoader';
import ic_sign_in from '../../../assets/login/ic_sign_in_webapp.png'
import { getUserAccessToken } from '../../../utils/authorization';


export default function ForgotPassword() {
  let history = useHistory();
  const { profile } = useSelector((state) => state.global)
  const [isSendingOTP, setIsSendingOTP] = useState(false);
  const [recipient, setRecipient] = useState({
    value: '',
    isValid: true,
    error: '',
    isPristine: true
  });
  const [otp, setOtp] = useState({
    value: '',
    isValid: true,
    error: '',
    isPristine: true
  });
  const [password, setPassword] = useState({
    value: '',
    isValid: true,
    error: '',
    isPristine: true
  });
  const [passwordConfirmation, setPasswordConfirmation] = useState({
    value: '',
    isValid: true,
    error: '',
    isPristine: true
  });
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    const token = getUserAccessToken();
    (profile && token) && history.push('/')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setFormValid((recipient.isValid && recipient.value !== '') &&
      (otp.isValid && otp.value !== '') &&
      (password.isValid && password.value !== '') &&
      (passwordConfirmation.isValid && passwordConfirmation.value !== ''))
  }, [recipient, otp, password, passwordConfirmation])

  const onSendOTP = () => {
    setIsSendingOTP(true)
    profileClientX.post(`/v1/forgot-password`, {
      recipient: recipient.value
    })
      .then((response) => {
        toast.success('OTP sent successfully.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        setIsSendingOTP(false)
      }).catch((error) => {
        setIsSendingOTP(false)
        toast.error("Error in sending OTP.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      });
  }

  const onResetPassword = () => {
    profileClientX.post(`/v1/reset-password`, {
      recipient: recipient.value,
      otp: otp.value,
      password: password.value,
      password_confirmation: passwordConfirmation.value
    })
      .then((response) => {
        toast.success('Password reset successful', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        history.push('/login')
      })
      .catch((error) => {
        // if (422 === error.response.status) {
        //     var errors = error.response.data.errors;
        //     Object.getOwnPropertyNames(errors).forEach((field) => {
        //         const newData = update(this.state, {
        //             [field]:{
        //                 isValid: {$set: false},
        //                 isPristine: {$set: false},
        //                 error: {$set: errors[field][0]}
        //             }
        //         });
        //         this.setState(newData, this.validateForm);
        //     });
        //     return;
        // }
        toast.error('Error in resetting the password.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      });
  }

  const validateField = (fieldName, value) => {
    switch (fieldName) {
      case 'recipient':
        setRecipient({
          value: value,
          isValid: null !== value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) || null !== value.match(/^[6-9]\d{9}$/)
        })
        if (value.length > 10)
          setRecipient({
            ...recipient,
            value: value.slice(0, 10)
          })
        break;
      case 'otp':
        setOtp({
          value: value,
          isValid: null !== value.match(/^\d{6}$/)
        })
        break;
      case 'password':
        setPassword({
          value: value,
          isValid: value.length >= 6
        })
        break;
      case 'password_confirmation':
        setPasswordConfirmation({
          value: value,
          isValid: value === password.value
        })
        break;
      default:
        break;
    }

  }

  return (
    <div className="container-fluid ">
      <div className="row flex flex-row-reverse" style={{ minHeight: "90vh" }}>
        <div className="col-12 col-md-5 px-3 px-md-5 home-page">
          <h2 className="h2 mb-3 mt-md-5 font-bold sub-title">Forgot Password</h2>
          <div className="d-flex flex-column login-input-field">
            <div className='d-flex pb-3 row'>
              <div className="col-8 col-md-9 pr-0">
                <TextField
                  label="Mobile / Email"
                  outlined
                  helperText={!recipient.isValid && <HelperText className='text-red'>Enter valid Number</HelperText>}
                  className=" w-100"
                >
                  <Input
                    value={recipient.value}
                    name='recipient'
                    isValid={recipient.isValid}
                    onChange={(e) => validateField(e.target.name, e.target.value)}
                  />
                </TextField>
              </div>
              <div className="col-4 col-md-3 pl-0">
                {isSendingOTP ?
                  <div className="my-2 text-center"><InlineLoader /></div>
                  :
                  <button className="forgot-password-sent-otp-btn" onClick={() => onSendOTP()}>Send OTP</button>
                }
              </div>
            </div>
            <div className='mb-3'>
              <TextField
                label="OTP Code"
                outlined
                helperText={!otp.isValid && <HelperText className='text-red'>Enter valid Number</HelperText>}
                className=" w-100 "
              >
                <Input
                  name='otp'
                  isValid={otp.isValid}
                  value={otp.value}
                  onChange={(e) => validateField(e.target.name, e.target.value)}
                />
              </TextField>
            </div>
            <div className='mb-3'>
              <TextField
                label="password"
                outlined
                helperText={!password.isValid && <HelperText className='text-red'>Password is invalid</HelperText>}
                className=" w-100 "
              >
                <Input
                  name='password'
                  isValid={password.isValid}
                  value={password.value}
                  onChange={(e) => validateField(e.target.name, e.target.value)}
                />
              </TextField>
            </div>
            <div className='mb-3'>
              <TextField
                label="Confirm Password"
                outlined
                helperText={!passwordConfirmation.isValid && <HelperText className='text-red'>Password is invalid</HelperText>}
                className=" w-100 "
              >
                <Input
                  name='password_confirmation'
                  isValid={passwordConfirmation.isValid}
                  value={passwordConfirmation.value}
                  onChange={(e) => validateField(e.target.name, e.target.value)}
                />
              </TextField>
            </div>
            <button className={formValid ? "animated-btn" : "animated-btn-disabled"} onClick={() => onResetPassword()}>RESET</button>
            <label className="text-center pt-3">Don't have an account? <span style={{ color: "#203BCF" }} className="font-bold cursor-pointer" onClick={() => history.push('/login')}>Sign Up</span></label>
          </div>
        </div>
        <div className="col-12 col-md-7 d-flex justify-content-center align-items-center" style={{ background: "linear-gradient(180deg, rgba(240,238,254,1) 0%, rgba(206,197,241,1) 100%)" }}>
          <div className="w-50 py-5"><img src={ic_sign_in} alt='sign-in-logo' title='sign-in-logo' className="img-fluid" /></div>
        </div>
      </div>
    </div>
  )
}