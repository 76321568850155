import HelmetMetaData from "../components/HelmetMetaData";
import { useLocation } from "react-router-dom";

function VoucherMetaData() {
  let location = useLocation();
  let currentAppUrl = process.env.REACT_APP_URL + location.pathname;
  
  return (
    <HelmetMetaData
      title={`Vouchers & Gift Cards - Buy Brand Vouchers & E-Gift Cards Online| WINDS App`}
      description={`Vouchers & Gift Cards - Buy E-Gift Cards & Brand Vouchers Online from the WINDS App. Choose your favourite gift voucher from 200+ brands of different categories, and start shopping today.`}
      keywords={`vouchers, gift cards, e-gift cards, e-gift vouchers, online discounts, online deals, e-gift cards, e-vouchers, discount vouchers, buy gift cards, gift vouchers, winds app gift cards, winds app gift vouchers, winds app vouchers, brand vouchers`}
      voiceSchema={<script type="application/ld+json">
        {JSON.stringify(
          {
            "@context": "https://schema.org/",
            "@type": "WebPage",
            "name": "Gift Vouchers - Buy Gift Cards & E-Gift Vouchers Online| Winds App",
            "speakable":
            {
              "@type": "SpeakableSpecification",
              "xpath":
                [
                  "/html/head/title",
                  "/html/head/meta[@name='description']/@content"
                ]
            },
            "url": currentAppUrl
          }
        )}
      </script>
      }
      breadcrumbSchema={<script type="application/ld+json">
        {JSON.stringify(
          {
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            "itemListElement": [{
              "@type": "ListItem",
              "position": 1,
              "name": "Home",
              "item": process.env.REACT_APP_URL
            }, {
              "@type": "ListItem",
              "position": 2,
              "name": "Brand Vouchers",
              "item": currentAppUrl
            }]
          }
        )}
      </script>}
    ></HelmetMetaData>
  );
}

export default VoucherMetaData;