export const billpayServicesMetaContent = {
    "mobile-recharge": {
        title:"Mobile Recharge - Online Prepaid Mobile Recharge Plans | WINDS App",
        description:"Make your mobile recharge online at WINDS App and win exciting cash rewards today. Choose your preferred recharge plan for Airtel, VI, BSNL, Jio and other prepaid mobile operators.",
        keywords: "mobile recharge, online mobile recharge, prepaid mobile recharge, jio mobile recharge, airtel mobile recharge, vi mobile recharge, online recharge, recharge plan, prepaid mobile recharge rewards, online prepaid mobile recharge cash rewards"
    },
    "mobile-postpaid":{
        title:"Online Bill Payment: Pay Postpaid Mobile Bill Online with Best Offers | WINDS App",
        description:"Online Bill Payment: WINDS App offers simple mobile postpaid bill payment services for all telecom operators. Win amazing cash rewards on postpaid mobile recharge and bill payments.",
        keywords:"postpaid, online bill payment, postpaid mobile bill payment, postpaid bill payment, postpaid recharge, postpaid plan, best postpaid plan, postpaid mobile recharge, postpaid recharge plan"
    },
    "dth":{
        title:"DTH Recharge - Online DTH Recharge Plans and Offers | WINDS App",
        description:"Make fast & secure DTH Recharge online at WINDS App and get a chance to win exciting cash rewards. Choose your DTH recharge plan for Airtel Digital TV, DISH TV and other DTH operators.",
        keywords:"dth, dth recharge, online dth recharge, dth operators, airtel dth, dish tv, sun tv, tata sky, videocon d2h, dth offers, dth cash points, dth reward points, dth recharge deals, dth recharge offers"
    },
    "fastag":{
        title:"Fastag Recharge: Recharge Your Fastag Wallet Online at WINDS App",
        description:"Recharge your FASTag wallet online issued by SBI, Axis Bank, HDFC Bank and other fastag providers at WINDS App & Get a chance to win exciting rewards points on your every FASTag recharge.",
        keywords:"fastag, fastag recharge, recharge fastag, how to recharge fastag, fastag recharge online, fastag wallet, fastag recharge offers, fastag recharge rewards points, cash rewards on fastag recharge"
    },
    "electricity":{
        title:"Electricity Bill Payment - Pay Your Bijli Bill Online | WINDS App",
        description:"Electricity Bill Payment: Pay your Bijli Bill online for KSEB, UPPCL, TNEB, Mahavitaran and other electricity providers. Use WINDS App to pay your light bill & win exciting rewards points.",
        keywords:"electricity bill payment, electricity bill, electricity online bill payment, electricity bill online, electricity reward points, electricity bill payment cash rewards, pay electricity bill online, bijli bill offers, light bill payment reward points"
    },
    "gas":{
        title:"Gas Bill Payments: Pay Your Gas Bill Online | WINDS App",
        description:"Make your Gas bill payment online for Adani Gas, HP, Gail Gas Limited, Assam Gas Company Limited and other gas providers at WINDS App and win exciting rewards points today.",
        keywords:"gas bill payments, gas bill, online gas bill, bill payment, bill payment online,gas bill online, pay gas bill, gas bill pay, pay gas bill online, online gas bill payment"
    },
    "landline-postpaid":{
        title:"Landline Bill Payment: Pay Your Landline Bill Online at WINDS App",
        description:"Pay your postpaid landline bills online for BSNL, TATA Teleservices and other telecom operators. Use WINDS App to win exciting rewards points on every landline bill payment.",
        keywords:"landline bill payment, landline bill, bsnl landline bill payment, pay landline bills, online bill payment, landline online payment, tata teleservices bill payment, tikona infinet pvt ltd bill payment"
    },
    "water":{
        title:"Water Bill Payments: Pay Your Water Bill Online at WINDS App",
        description:"Pay your water bills online via WINDS App and win rewards points on every water bill payment. Check your water bill online and make instant payment using wallet, UPI, debit or credit card.",
        keywords:"water bill, water bill payments, online water bill payments, water bill payment offers, water bill rewards points, pay water bill online, water bill online, pay water bill, paani ka bill, winds app"
    },
    "broadband-postpaid":{
        title:"Broadband Bill Payment: Pay Your Broadband Bill with WINDS App",
        description:"Pay your broadband bill online with WINDS App securely. Make broadband bill payments for Airtel, Act Fibernet, Hathway and other broadband service providers and win exciting rewards points.",
        keywords:"broadband payments, broadband bill, online broadband bill payments, broadband bill online, bsnl broadband bill payment, airtel broadband bill payment, pay broadband bill, broadband bill payment"
    },
    "cable-tv":{
        title:"TV Recharge: Make Your Cable TV Recharge Online at WINDS App",
        description:"Cable TV Recharge: Make your Cable TV payment online for Asianet Digital, Hathway, UCN Cable and other cable tv providers. Use WINDS App to win huge cash rewards points on cable tv bill payments.",
        keywords:"tv recharge, cable tv recharge online, tv recharge, cable tv recharge rewards points, cable tv recharge cash rewards, tv recharge online, cable tv payment, cable tv bill payment online"
    },
    "lpg-gas":{
        title:"LPG Gas Bill Payments: Pay Your LPG Gas Bill Online | WINDS App",
        description:"Pay your LPG Gas bill online for Bharat Petroleum Corporation Limited, Indane Gas and other LPG Gas Providers. Use WINDS App to win exciting rewards points on LPG Gas bill payments.",
        keywords:"lpg gas bill payments, lpg gas bill, online lpg gas bill, lpg gas bill payment, lpg gas bill payment online, lpg gas bill online, pay lpg gas bill, lpg gas bill pay, pay lpg gas bill online, online lpg gas bill payment"
    },
    "municipal-taxes":{
        title:"Property Tax Payment: Pay Your Municipal Tax Online | WINDS App",
        description:"Make your Property Tax payment online for BBMP, KMC, MCD, AMC and other municipal tax providers. Pay your House or Property Tax now and win exciting rewards points at WINDS App.",
        keywords:"municipal tax payment, property tax payment, house tax, office tax, property tax online, pay property tax, house tax online payment, property tax online payment, tax payment, municipality property tax online payment, municipal tax payment online, pay municipal tax online"
    }
}