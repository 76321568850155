export const getRoute = (segment, itemType, value) => {
  segment = segment.toString();
  itemType = itemType && itemType.toString();
  switch (true) {
    case segment === "/":
      return "/";
    case segment === "/winds-coins-details":
      return "/winds-coins-details";
    case segment === "/orders" && itemType === "partner-purchase":
      return `/nearby-partners/orders/${value}`;
    case segment === "/orders" && itemType === "shopping-site":
      return `/shopping-sites/orders/${value}`;
    case segment === "/orders" && itemType === "billpay-order":
      return `/bill-pay/orders/${value}`;
    case segment === "/orders" && itemType === "voucher-order":
      return `/vouchers/orders/${value}`;
    case segment === "/orders" && itemType === "voucher":
      return `/vouchers/orders/${value}`;
    case segment === "/rewards":
      return "/winds-coins";
    case segment === "/reward-system":
      return "/rewards";
    case segment === "/vouchers":
      return `/voucher/${value}`;
    case segment === "/partner-offers":
      return "";
    case segment === "/shopping-sites":
      return `/shopping-sites/${value}`;
    case segment === "/wallet":
      return "/wallet";
    case segment === "/withdraw-money":
      return "";
    case segment === "/partner-categories":
      return "";
    case segment === "/partners":
      return "";
    case segment === "/mobile-recharge":
      return "";
    case segment === "/landline-bill-payment":
      return "";
    case segment === "/insurance-renewal":
      return "";
    case segment === "/gas-bill-payment":
      return "";
    case segment === "/electricity-bill-payment":
      return "";
    case segment === "/dth-recharge":
      return "";

    case segment === "/water-bill-payment":
      return "";

    case segment === "/bookmarks":
      return "";

    case segment === "/notifications":
      return "";

    case segment === "/notifications/popup":
      return "";

    case segment === "/scan":
      return "";

    case segment === "/refer-and-earn":
      return "";

    case segment === "/winners":
      return "";

    case segment === "/reviews":
      return "";

    case segment === "/account":
      return "";

    case segment === "/profile/edit":
      return "";

    case segment === "/profile/edit-mobile":
      return "";

    case segment === "/scratch-and-win":
      return "";

    case segment === "/profile":
      return "";

    case segment === "/image-preview":
      return "";

    case segment === "/login":
      return "";

    case segment === "/register":
      return "";

    case segment === "/password/forgot":
      return "";

    case segment === "/password/reset":
      return "";

    case segment === "/addresses":
      return "";

    case segment === "/addresses/add":
      return "";

    case segment === "/add-partner-review":
      return "";

    case segment === "/logged-in-devices":
      return "";

    case segment === "/invite":
      return "";

    case segment === "/settings":
      return "";

    case segment === "/privacy-policy":
      return "";

    case segment === "/terms-and-conditions":
      return "";

    case segment === "/language":
      return "";

    case segment === "/support":
      return "";

    case segment === "/about":
      return "";

    case segment === "/app-credits":
      return "";

    case segment === "/help":
      return "/faq";

    case segment === "/pan-verification":
      return "";

    case segment === "/pan-verification/add":
      return "";

    case segment === "/search-location":
      return "";

    case segment === "/others/url":
      return "";

    case segment === "/gc-wallet":
      return `/voucher/redeem?${value}`

    case segment === "wallet_history":
      return `/wallet`

    default:
      return "";
  }
};
