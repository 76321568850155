import axios from 'axios';
import { currentlySelectedLanguage } from '../helper';
import { getUserAccessToken, handleUnAuthentication } from "../authorization";
const customerCommonClientX = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_CUSTOMER_COMMON,
  withCredentials: true
});

//Request Interceptor
customerCommonClientX.interceptors.request.use(
   function (config) {
    const token = getUserAccessToken()
    if(token) config.headers["Authorization"] = `Bearer ${token}`;
    config.headers['Accept-Language'] = currentlySelectedLanguage();
    // config.headers['winds-client-ip'] = store.getState().global.windsClientIp;
    config.headers['device-os'] = 'web';
    
  // Do something before request is sent
    
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

//Response Interceptor
customerCommonClientX.interceptors.response.use(function (response) {
  // Do something with response data
  return response;

}, function (error) {
  handleUnAuthentication(error.response.status)
  // Do something with response error
  return Promise.reject(error);

});


export default customerCommonClientX;