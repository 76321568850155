import React, { Component } from "react";
import MultiItemCarousel from "../../../components/MultiItemCarousel";
import { Link } from "react-router-dom";
import DeleteIcon from "../../../assets/delete.svg";

export default class ShoppingSitesCarousel extends Component {
  deleteBookmark = (event, id) => {
    event.preventDefault();
    
    this.props.deleteBookmark(
      id,
      `api/v1/affiliates/${id}/bookmark`,
      "affiliates"
    );
  };

  render() {
    return (
      <MultiItemCarousel>
        {this.props.affiliate.map((item, index) => {
          return (
            <div key={"shopping-site-" + index}>
              <div style={{ padding: "0 15px" }}>
                <Link to={`/shopping-sites/${item.id}`}>
                  <div
                    className="card mb-4 card--carousel-item card--carousel-item--shopping-sites bookmark-list"
                    style={{
                      borderRadius: "8px",
                      backgroundImage: `url(${item.cover_image_url})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "100% auto"
                    }}
                  >
                    <div className="card-body">
                      <div className="bookmark-delete float-right">
                        <img
                          src={DeleteIcon}
                          alt="delete"
                          title="delete"
                          className="float-right"
                          onClick={event => this.deleteBookmark(event, item.id)}
                        />
                      </div>
                      <div
                        className="logo"
                        style={{
                          borderRadius: "8px",
                          background:
                            "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.79) 66.41%, #FFFFFF 100%)"
                        }}
                      >
                        <img src={item.web_image_url} alt={item.name} title={item.name} />
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          );
        })}
      </MultiItemCarousel>
    );
  }
}
