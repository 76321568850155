import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import ic_calender from '../../../assets/ic_calender.svg'
import earningApi from '../../../utils/api/earningsClient';
import {
  fetchEarningsHistory,
  setEarningsHistory,
} from '../actions';
import moment from "moment";
import ic_no_match_found from '../../../assets/ic_no_match_found.svg'

class EarningsHistory extends Component {

  componentDidMount() {
    this.props.initEarnings();
  }

  EarningsList = (props) => {
    return (
      <React.Fragment>
        {
          this.props.isFetchingEarningsHistory ? (
            <React.Fragment>
              <div className="border border-grey rounded p-4">
                <div style={{ height: "100px" }} className="shine slider-shimmer rounded mb-3"></div>
                <div style={{ height: "100px" }} className="shine slider-shimmer rounded mb-3"></div>
                <div style={{ height: "100px" }} className="shine slider-shimmer rounded"></div>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="border border-grey rounded p-4">
                <div className="earning-history">
                  {
                    this.props.earningsHistory.map((item, index) => {
                      return (
                        <div key={"earning-history" + index} className="d-flex align-items-center">
                          <div className="mr-3 d-flex align-items-center">
                            <div className="calender-icon mr-4">
                              <img src={ic_calender} alt="ic_calender" title='calender' />
                            </div>
                            <div className="text-left">
                              <small className="d-block pb-1 text-muted">
                                {moment(item.commission_date || "").format("DD MMM YYYY")}
                              </small>
                              <p className='mb-0'>No. Of Txn - <span className="font-weight-bold">{item.transaction_count}</span></p>
                            </div>
                          </div>
                          <div className="ml-auto mr-3">
                            <div className="text-right">
                              <small>
                                <FormattedMessage
                                  id="general.amount"
                                  defaultMessage="Amount"
                                />
                              </small>
                              <h5 className="text-col-primary font-weight-bold mb-0">₹ {item.commission}</h5>
                            </div>
                          </div>
                          <Link to={`/customer-commission-details?commission_date=${moment(item.commission_date || "").format("YYYY-MM-DD")}`} className="text-primary pt-2">
                            <i className="fa fa-chevron-circle-right h5"></i>
                          </Link>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </React.Fragment>
          )
        }
      </React.Fragment>
    )
  }

  render() {
    return (
      <React.Fragment>
        <div className="container">
          <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
            <div className="col-12">
              <small>
                <Link to="/">
                  <FormattedMessage
                    id="breadcrumbs.home"
                    defaultMessage="Home"
                  />
                </Link>{" "}
                /{" "}
                <Link to="/earnings">
                  <FormattedMessage
                    id="navbar.my-earnings"
                    defaultMessage="My Earnings"
                  />
                </Link>
                /{" "}
                <strong>
                  <FormattedMessage
                    id="earnings.customer_commission"
                    defaultMessage="Customer commission"
                  />
                </strong>
              </small>
            </div>
          </div>

          {
            !this.props.earningsHistory ? (
              <React.Fragment>
                <div className="row mt-5">
                  <div className="col-12 col-md-12 mx-auto">
                    <img
                      src={ic_no_match_found}
                      alt="ic_no_match_found"
                      title="ic_no_match_found"
                      className="mx-auto d-block"
                      style={{ width: "30%", height: "auto", maxWidth: "90%" }}
                    />
                    <h4 className="text-center my-5 pb-5 font-weight-bold" style={{ letterSpacing: "0.03em" }}>No match found!</h4>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="row mb-5">
                  <div className="col-12 col-md-8 mx-auto">
                    <this.EarningsList />
                  </div>
                </div>
              </React.Fragment>
            )
          }
        </div>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    isFetchingEarningsHistory: state.earnings.isFetchingEarningsHistory,
    earningsHistory: state.earnings.earningsHistory,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initEarnings: () => {
      dispatch(fetchEarningsHistory());

      earningApi.get('/v2/customer-commission').then((response) => {
        let data = response.data.data;
        dispatch(setEarningsHistory(data));
      });

      return;
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EarningsHistory);