import React from "react";
const WonCardTitle = (props) => {
  const card = props.card;
  const type = card.type;
  switch (type) {
    case "wrp":
      return `${card.value} ${card.name}`;
    case "item":
      return `${card.name}`;
    case "voucher":
      return (
        !card?.is_conditional_voucher &&
        <React.Fragment>
          &#8377; {card.value}
        </React.Fragment>
      );
    case "cash":
      return `₹ ${card.value}`;
    default:
      break;
  }
};

export default WonCardTitle;
