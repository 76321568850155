export const affiliateCategoryMetaContent = {
    "beauty-wellness-grooming": {
        title: "Buy from Beauty, Wellness & Grooming shopping site only on WINDS | WINDS App  ",
        description: "Buy beauty, wellness or grooming products from online shopping sites via WINDSApp. Shop via shopping sites on the WINDSApp & buy beauty, wellness or grooming products online.",
        keywords: "beauty  shopping site, wellness   shopping site, winds app cosmetics   shopping site, winds app grooming   shopping site, winds app beauty   shopping sites, winds app wellness   shopping sites, winds app wellness   shopping sites, winds app beauty shopping site,"
    },
    "education": {
        title: "Buy Learning courses from Ed-Tech online site only on WINDS | WINDS App  ",
        description: "Buy educational services from online shopping sites via WINDSApp. Shop via shopping sites on the WINDSApp & buy educational services online.",
        keywords: "educational sites, educational website, education online site, Ed-Tech sites, Ed-Tech site, Ed-tech online"
    },
    "fuel-automobile": {
        title: "Buy from Fuel & Automobile shopping site only on WINDS | WINDS App  ",
        description: "Buy WINDS shopping sites/  shopping site for Fuel & Automobile  s online from the WINDS App. Shop online today Fuel & Automobile    shopping site using the WINDS App. ",
        keywords: "winds app fuel   voucher, winds app oil   voucher, automobile  s, winds app fuel   shopping site, Winds online oil   shopping site, diesel pump   shopping site, automobile oil online shopping site, automobile oil   shopping site, car oil shopping site, HPCL voucher, BPCL voucher"
    },
    "home-furniture": {
        title: "",
        description: "",
        keywords: ""
    },
    "gym-fitness": {
        title: "Buy from Gym & Fitness essentials only on WINDS | WINDS App  ",
        description: "Buy gym and fitness products or gym membership from online shopping sites via WINDSApp. Shop via shopping sites on the WINDSApp, buy gym and fitness products or gym membership online. ",
        keywords: "Winds Gym & Fitness, Winds online gym  s, online gym site, online fitness , online fitness site, online gym site, gym sites"
    },
    "miscellaneous": {
        title: "",
        description: "",
        keywords: ""
    },
    "others": {
        title: "Buy products from your favourite shopping sites only on WINDS | WINDS App  ",
        description: "Buy products from online shopping sites via WINDSApp. Shop via shopping sites on the WINDSApp & buy products online.",
        keywords: "winds, winds app, shopping, shopping site, buy shopping site online, winds shopping site, winds   shopping site, gift cards, winds shopping sites, winds e-gift shopping site, online shopping site, shopping sites, e-shopping site, buy gift cards, gift shopping site, winds app gift cards, winds app gift shopping site, winds app shopping site, winds shopping app, "
    },
    "travel-hotels": {
        title: "Book from Travel & Hotels shopping site only on WINDS | WINDS App  ",
        description: "Buy flight tickets, train tickets, bus tickets or book your hotel from online shopping sites via WINDSApp. Shop via shopping sites on the WINDSApp & buy your travel tickets or holiday packages online.",
        keywords: " windsapp online travel site, winds app hotel booking site, winds app online flight booking online site, winds app bus booking site, winds app rail booking site, Winds Travel & Hotels online, Winds Travel & Hotels sites"
    },
    "clothing-accessories": {
        title: "Buy Clothing & Accessories from shopping site only on WINDS | WINDS App  ",
        description: "Buy fashion clothes, new clothes, apparel, clothing from online shopping sites via WINDSApp. Shop via shopping sites on the WINDSApp & order clothes or apparels online.",
        keywords: "Winds Clothing  shopping site, Winds Clothing & Accessories   shopping site, new fashion   shopping site, Winds Fashion   shopping site, Winds Clothes shopping sites, Clothing accessories   shopping sites, Winds Accessories shopping sites, Accessories shopping site, Winds Clothing shopping site, Winds Fashion shopping site, Apparel shopping site"
    },
    "electronics-mobile-accessories": {
        title: "Buy Electronics & Mobile Accessories from shopping site only on WINDS | WINDS App",
        description: "Buy mobile, electronics, home appliances, electronic accessories from online shopping sites via WINDSApp. Shop via shopping sites on the WINDSApp & buy electronic devices online.",
        keywords: " Winds Electronics & Mobile Accessories , Winds Electronics site, Winds Mobile Accessories and Electronics  site, Winds new Electronics  site, Electronics  shopping site, Winds Electronics shopping sites, Winds Mobile Accessories   shopping sites, Gadgets shopping sites, Tech shopping site, Winds Mobile shopping site, Winds Mobile E gift  shopping site, New Gadgets shopping site,"
    },
    "gifting": {
        title: "Buy Gifts from shopping site only on WINDS | WINDS App  ",
        description: "Buy birthday gifts/presents, wedding gifts/presents, anniversary gifts/presents from online shopping sites via WINDSApp. Shop via shopping sites on the WINDSApp & buy gifts or presents online.",
        keywords: "Winds Gifting E- shopping site, Winds Gifting shopping site, Gift cards, shopping sites, Gift certificates, Winds Voucher codes, Winds Digital gift cards, Online gift shopping site, Gift card codes, Voucher gifts, Winds Gift card offers, Discounted gift cards, Winds Gift card promotions,"
    },
    "healthcare": {
        title: "Buy Healthcare and medicine from online site only on WINDS | WINDS App  ",
        description: "Buy medicines, pharmacy products, medical products, healthcare products from online shopping sites via WINDSApp. Shop via shopping sites on the WINDSApp & buy healthcare products online.",
        keywords: "Winds Healthcare and medicine site, Winds Healthcare site, Winds Medical online site, Winds Medical site, Winds Health sites, Medical care site , Health services online, Wellness online, Nutrition site, Winds fitness online site, Health Voucher, Fitness certificates, Health cards, Online Fitness shopping site"
    },
    "jewellery": {
        title: "Buy Jewellery from shopping site only on WINDS | WINDS App  ",
        description: "Buy jewellery products from online shopping sites via WINDSApp. Shop via shopping sites on the WINDSApp & buy gold, diamond, silver, platinum jewellery online.",
        keywords: "online jewellery shopping site, winds app online jewellery shopping site, winds app jewellery shopping site, winds app jewellery   shopping site, Winds jewellery shopping site"
    },
    "movies-events": {
        title: "Buy Movies & Events tickets and pass online only on WINDS | WINDS App",
        description: "Book movie tickets or event tickets from online shopping sites via WINDSApp. Shop via shopping sites on the WINDSApp & buy movie tickets or event tickets online.",
        keywords: "Winds online movie site, winds app movie shopping site, online movie booking site, Winds online event  site, online event booking site, "
    },
    "ott": {
        title: "Buy Clothing & Accessories from shopping site only on WINDS | WINDS App  ",
        description: "Buy WINDS shopping sites/  shopping site for OTT  s online from the WINDS App. Shop online today OTT   shopping site using the WINDS App. ",
        keywords: "Winds OTT   shopping site,Winds Movies   shopping site, Winds TV Series   shopping site, Winds OTT   shopping sites, Winds Kids' streaming   shopping sites, Winds Movie Streaming   shopping sites, Winds Tv Series shopping site,"
    },
    "travel-accessories": {
        title: "Buy Travel Accessories from shopping site only on WINDS | WINDS App  ",
        description: "Buy travel accessories, suitcases, luggage, bags from online shopping sites via WINDSApp. Shop via shopping sites on the WINDSApp & buy travel bags online.",
        keywords: "Winds Travel Accessories shopping site, Winds Travel   shopping site, Winds Travel E-Gift shopping site, Travel bags  shopping sites, Winds Accessories Travel streaming   shopping sites, Travel Bags  shopping sites,Winds Travel Accessories online site"
    },
    "e-commerce": {
        title: "Buy anything from your favourite E-commerce site only on WINDS | WINDS App",
        description: "Order products from online ecommerce shopping sites via WINDSApp. Shop via ecommerce shopping sites on the WINDSApp & buy products online.",
        keywords: "Winds e-commerce site, winds app online shopping site, winds app e-commerce site, Winds e-commerce, Winds e-commerce websites"
    },
    "food-beverages": {
        title: "Buy Food & Beverages online only on WINDS | WINDS App  ",
        description: "Order food or beverages from online shopping sites via WINDSApp. Shop via shopping sites on the WINDSApp & order food or beverage online.",
        keywords: "Winds online food delivery site, winds app online food ordering site, winds app food site, Winds online beverage ordering, Winds online food order site"
    },
    "grocery": {
        title: "Buy Grocery online only on WINDS | WINDS App  ",
        description: "Order daily groceries from online shopping sites via WINDSApp. Shop via shopping sites on the WINDSApp & order groceries online.",
        keywords: "Winds online grocery delivery site, winds app online grocery ordering site, winds app food site, online grocery ordering, Winds grocery online,"
    },
    "home-decor": {
        title: "Buy Home & Decor items from shopping site only on WINDS | WINDS App  ",
        description: "Order home and decor products from online shopping sites via WINDSApp. Shop via shopping sites on the WINDSApp & order home and decor products online.",
        keywords: "Winds online home decor shopping site, winds app online home decor shopping site, winds Home & Decor online, home decor shopping site, "
    },
    "kids-books-stationery": {
        title: "Buy Kids Clothing, Books & Stationary from online shopping site only on WINDS | WINDS App  ",
        description: "Buy kids toys, stationery,books from online shopping sites via WINDSApp. Shop via shopping sites on the WINDSApp & buy kid related products online.",
        keywords: "Winds Kids, Books & Stationary shopping site, Winds Books & Stationary shopping site, Winds Books site, Winds Stationary site, Winds Stationary and Books, Stationary accessories shopping sites, Accessories shopping sites, Kids Accessories shopping site, Winds Kids Clothing shopping site, Winds Kids Fashion shopping site, Winds Apparel shopping site"
    },
    "music": {
        title: "",
        description: "",
        keywords: ""
    },
    "salon-spa": {
        title: "Book Salon & Spa online only on WINDS | WINDS App  ",
        description: "Book salon and spa services from online shopping sites via WINDSApp. Shop via shopping sites on the WINDSApp & book salon and spa services online.",
        keywords: "Winds online salon site, winds app salon site, Winds online spa site, winds app salon site, Winds salon and spa online site"
    },
}