import { currentlySelectedLanguage } from "../../../utils/helper";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import React from "react";
import Iframe from "../../../components/iFrame";
const RewardsInfo = () => {
  const rewardsInfoUrl = `${process.env.REACT_APP_BASE_URL_REWARS_INFO}`;
  return (
    <div className="container font-proxima-nova">
      <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
        <div className="col-12">
          <div className="row align-items-center">
            <div className="col-12">
              <small>
                <Link to="/">
                  <FormattedMessage
                    id="breadcrumbs.home"
                    defaultMessage="Home"
                  />
                </Link>{" "}
                /{" "}
                <Link to="/rewards">
                  <FormattedMessage
                    id="navbar.rewards"
                    defaultMessage="Cahback-Rewards"
                  />
                </Link>{" "}
                /{" "}
                <strong>
                  <FormattedMessage id="general.info" defaultMessage="Info" />
                </strong>
              </small>
            </div>
          </div>
        </div>
      </div>
      <Iframe
        source={`${rewardsInfoUrl}/${currentlySelectedLanguage()}`}
      ></Iframe>
    </div>
  );
};

export default RewardsInfo;
