import React, { useState } from "react";
import { connect } from "react-redux";

function ViewTicket(props) {
    const [viewTicketUrl] = useState(props?.viewTicketUrl)
    
    const Shimmer = (props) => {
        return (
            <React.Fragment>
                <div className='container my-5' style={{ zIndex: "999" }}>
                    <div className='row'>
                        <div className='col-12 mx-auto'>
                            <div className="box shine w-100" style={{ height: "500px" }}></div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    return (
        viewTicketUrl === null ?
            <Shimmer /> :
            <React.Fragment>
                <iframe title="windsIframe" src={viewTicketUrl} className="info-box" name="flight"></iframe>
            </React.Fragment >
    );
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

function mapStateToProps(state) {
    return {
        viewTicketUrl: state.travel.viewTicketUrl
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewTicket);