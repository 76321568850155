import pdfHi from "../../../assets/magazine/Winds-Magazine-hindi.pdf"
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import { useState } from "react";

function WindsTimesHI() {
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <>
      <div className="container">
        <Document file={pdfHi} onLoadSuccess={onDocumentLoadSuccess} loading="loading" >
          {/* eslint-disable-next-line array-callback-return */}
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(page => {
            if (page <= numPages)
              return (
                <Page pageNumber={page} />
              )
          })}
        </Document>
      </div>
    </>
  );
}

export default WindsTimesHI;