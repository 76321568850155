import axios from 'axios';
import { currentlySelectedLanguage } from '../helper';
import { getUserAccessToken, handleUnAuthentication } from "../authorization";

const bannerClientX = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_BANNER,
    withCredentials: true
});

//Request Interceptor
bannerClientX.interceptors.request.use(
    function(config) {
      const token = getUserAccessToken()
      if(token) config.headers["Authorization"] = `Bearer ${token}`;
      config.headers['Accept-Language'] =  currentlySelectedLanguage();
  
      // Do something before request is sent
      return config;
    },
    function(error) {
      handleUnAuthentication(error.response.status)
      // Do something with request error
      return Promise.reject(error);
    }
  );

export default bannerClientX;