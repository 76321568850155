import React from "react";
import step1 from "../../assets/account_deletion/patron1.png"
import step2 from "../../assets/account_deletion/patron2.png"
import step3 from "../../assets/account_deletion/patron3.png"
import step4 from "../../assets/account_deletion/patron4.png"
import step5 from "../../assets/account_deletion/patron5.png"
import step6 from "../../assets/account_deletion/patron6.png"
import step7 from "../../assets/account_deletion/patron7.png"


function PatronAccountDeletion() {
    return (
        <div className="home-page pt-0 pb-0 pt-md-5 pb-md-5" >
            <div className="container custom-container ">
                <div>
                    <h1 className="title mb-2 text-center">How to delete WINDS Patron account</h1>
                </div>
                <hr className="w-100 mb-4" />


                <div className="row">
                    <div className="col-12">
                        <div className="image-list">
                            <p className="desc mt-4"><b>Step 1.</b> Go to Hamburger menu from Home page.</p>
                        </div>
                        <img className="image-width" src={step1} alt="img" title="img" />
                        <hr className="w-100" />

                        <div className="image-list">
                            <p className="desc mt-4"><b>Step 2.</b> Go to Settings</p>
                        </div>
                        <img className="image-width" src={step2} alt="img" title="img" />
                        <hr className="w-100" />

                        <div className="image-list">
                            <p className="desc mt-4"><b>Step 3.</b> Select Delete Account</p>
                        </div>
                        <img className="image-width" src={step3} alt="img" title="img" />
                        <hr className="w-100" />

                        <div className="image-list">
                            <p className="desc mt-4"><b>Step 4.</b> Tap on Yes to confirm the Delete Account</p>
                        </div>
                        <img className="image-width" src={step4} alt="img" title="img" />
                        <hr className="w-100" />

                        <div className="image-list">
                            <p className="desc mt-4"><b>Step 5.</b> Tap on Send OTP to Delete the Account</p>
                        </div>
                        <img className="image-width" src={step5} alt="img" title="img" />
                        <hr className="w-100" />

                        <div className="image-list">
                            <p className="desc mt-4"><b>Step 6.</b> Enter the OTP received in the OTP field and Tap on Verify OTP</p>
                        </div>
                        <img className="image-width" src={step6} alt="img" title="img" />
                        <hr className="w-100" />

                        <div className="image-list">
                            <p className="desc mt-4"><b>Step 7.</b> After the OTP is verified, your WINDS Patron account is Deleted and user
                                is taken to Dashboard and requested to Rate the overall experience with
                                the App.</p>
                        </div>
                        <img className="image-width" src={step7} alt="img" title="img" />
                        <hr className="w-100" />

                        <div className="mt-3 text-center">
                            <span className="desc mt-4 font-weight-bold ">
                                Note: Once the Account is deleted all the details associated with the user will be deleted.
                            </span>
                        </div>
                        <hr className="w-100" />

                        <p className="text-center mb-4">For any issues /queries or concern contact Customer Support on the toll-free
                            number <a className="font-weight-bold" href="tel:18001031032">18001031032</a> or mail us at <a
                                className="font-weight-bold" href="mailto:support@WINDS.co.in">support@WINDS.co.in</a> </p>
                        <hr className="w-100" />
                    </div>
                </div>

                <div className="row my-4">
                    <div className="col-12">
                        <div className="text-center">
                            <small>© Copyright 2024 । Winds E Private Limited.</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PatronAccountDeletion;