import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import vectorImg from "../../../assets/bill-pay-creative.svg";
import InlineLoader from "../../../components/InlineLoader";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { onBillUploaded } from "../../../utils/events";
import MultipleFileUpload from "../../../components/MultipleFileUpload";
import customerPartnerClientX from "../../../utils/api/customerPartnerClientX";
import { showToastMessage } from "../../../utils/helper";
class BillUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      partnerCode: null,
      partner: null,
      isLoadingPartner: false,
      amount: null,
      bill: null,
      file_extension: null,
      file_type: null,
      isUploading: false,
      previewFiles: [],
      files: [],
      isAmtInValid: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      const partnerCode = this.props.match.params.id;
      if (partnerCode) {
        this.setState(
          { partnerCode: partnerCode, isLoadingPartner: true },
          () => {
            this.getPartner(partnerCode);
          }
        );
      }
    }
  }

  componentDidMount() {
    if (this.props.profile) {

      const partnerCode = this.props.match.params.id;
      if (partnerCode) {
        this.setState(
          { partnerCode: partnerCode, isLoadingPartner: true },
          () => {
            this.getPartner(partnerCode);
          }
        );
      }
    } else {
      this.props.history.push('/login')
    }
  }

  onGetPartner = () => {
    if (
      null === this.state.partnerCode ||
      0 === this.state.partnerCode.length
    ) {
      toast.error(
        <FormattedMessage
          id="nearbyPartner.enter-code-to-proceed"
          defaultMessage="Enter Partner Code to proceed."
        />,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );

      this.setState({
        isLoadingPartner: false,
      });

      return;
    }

    this.setState(
      {
        isLoadingPartner: true,
      },
      () => {
        this.getPartner(this.state.partnerCode);
      }
    );
  };

  getPartner = (code) => {
    customerPartnerClientX
      .post(`v1/bills/scan/partner-details`, {
        partner_code: code,
      })
      .then((response) => {
        let data = response.data.data;

        this.setState({
          partner: data,
          isLoadingPartner: false,
        });
      })
      .catch((error) => {
        this.setState({
          isLoadingPartner: false,
        });
      });
  };

  onClearPartner = () => {
    this.setState({
      partnerCode: null,
      partner: null,
      amount: null,
    });
  };

  onSelectBill = (event) => {
    this.setState({
      bill: event.target.files[0],
    });
  };

  uploadMultipleFiles = (e) => {
    e.persist();
    const filesArray = [];
    filesArray.push(e.target.files);
    for (let i = 0, filesArrayLength = filesArray.length; i < filesArrayLength; i++) {
      this.setState((prevState) => ({
        previewFiles: prevState.previewFiles.concat(
          URL.createObjectURL(filesArray[i][0])
        ),
        files: prevState.files.concat(filesArray[i][0]),
      }));
    }
  };

  onUploadClick = (filesArray) => {
    let that = this;
    const { amount, partner } = that.state;
    let bill_image_ids = [];
    Promise.all(
      filesArray.map(function (file) {
        let formData = new FormData();
        formData.append("file", file);
        return customerPartnerClientX
          .request({
            url: `/v2/bills/file/upload`,
            method: "post",
            data: formData,
          })
          .then((response) => {
            const data = response.data.data;
            bill_image_ids.push(data.id);
          })
          .catch((error) => {
            this.setState({
              isUploading: false,
            });
          });
      })
    )
      .then(function (finished) {
        customerPartnerClientX
          .request({
            url: `/v2/bills/${partner.id}`,
            method: "post",
            data: {
              bill_image_ids: bill_image_ids,
              bill_amount: amount,
            },
          })
          .then((response) => {
            const payload = {
              Category: "Partner",
              "Product Name": partner.name,
              Amount: amount,
              Date: response.data.data.created_at,
            };

            onBillUploaded(payload);
            toast.success(
              <FormattedMessage
                id="general.bill-upload"
                defaultMessage="You bill was uploaded successfully."
              />,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              }
            );
            that.props.history.push(
              `/nearby-partners/orders/${response.data.data.id}`
            );
          })
          .catch((error) => {
            that.setState({
              isUploading: false,
            });
          });
      })
      .catch((error) => {
        that.setState({
          isUploading: false,
        });
      });
  };

  onUpload = () => {
    this.setState({
      isUploading: true,
    });
  };

  onAmountEnter = event => {
    const { value } = event.target;
    if (value > 9999999) {
      showToastMessage('error', "Only upto 9999999 is allowed")
      this.setState({ isAmtInValid: true });
    } else {
      this.setState({ isAmtInValid: false });
    }
    this.setState({ amount: value });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
          <div className="col-12">
            <div className="row align-items-center">
              <div className="col-12">
                <small>
                  <Link to="/">
                    <FormattedMessage
                      id="breadcrumbs.home"
                      defaultMessage="Home"
                    />
                  </Link>{" "}
                  /{" "}
                  <strong>
                    <FormattedMessage
                      id="nearby-partners.upload-bill-for-nearby-partner"
                      defaultMessage="Upload Bill for Nearby Partner"
                    />
                  </strong>
                </small>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-3 mb-md-4 font-proxima-nova">
          <div className="col-12">
            <div className="row">
              <div className="col-md-6">
                <div
                  className="card border-0"
                  style={{ backgroundColor: "#f8f9fa" }}
                >
                  <div className="card-body">
                    <this.SelectPartner />

                    <div className="form-group mt-5">
                      <label>
                        <FormattedMessage
                          id="nearby-partners.enter-amount"
                          defaultMessage="Enter Amount"
                        />
                      </label>
                      <input
                        type="number"
                        className="form-control pay-now-input border-bottom w-50"
                        value={
                          null == this.state.amount ? "" : this.state.amount
                        }
                        onChange={this.onAmountEnter}
                      />
                    </div>

                    <div className="form-group mt-5">
                      <div className="row mt-4">
                        <div className="col-12">
                          <h5>Upload Bill Image</h5>
                          <p
                            style={{
                              fontSize: "14px",
                              fontStyle: "italic",
                              marginTop: "-.5rem",
                            }}
                          >
                            Please note that you can upload a maximum of 5 bill
                            images
                          </p>
                        </div>
                      </div>
                      <MultipleFileUpload
                        isUploading={this.state.isUploading}
                        onUploadClick={(files) => this.onUploadClick(files)}
                      />
                      {/* <div
                        className="custom-file bill-upload-input-wrapper"
                        style={{
                          pointerEvents: this.state.isUploading
                            ? "none"
                            : "auto",
                        }}
                      >
                        <label className="custom-file-label">
                          <input
                            type="file"
                            className="custom-file-input"
                            onChange={this.onSelectBill}
                            accept=".png, .jpg, .jpeg, application/pdf"
                          />
                          <span className="input-file-box">
                            <FormattedMessage
                              id="nearby-partner.bill-image"
                              defaultMessage="Bill Image"
                            />
                          </span>
                          <span className="input-file-btn">
                            <FormattedMessage
                              id="nearby-partners.upload-bill-for-nearby-partner"
                              defaultMessage="Upload Bill for Nearby Partner"
                            />
                          </span>
                        </label>
                      </div> */}
                    </div>
                    {this.state.isUploading ? (
                      <div className="text-center">
                        <InlineLoader />
                      </div>
                    ) : (
                      <button
                        className="btn btn-primary btn-block mt-3"
                        disabled={
                          null == this.state.partner ||
                          null == this.state.amount || this.state.isAmtInValid
                        }
                        onClick={this.onUpload}
                      >
                        <FormattedMessage
                          id="general.upload"
                          defaultMessage="Upload"
                        />
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6 text-center">
                <img
                  className="mt-3 mt-md-0 nearby-partner-bill-upload-vector"
                  src={vectorImg}
                  alt="upload nearby partner bill"
                  title="upload nearby partner bill"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  SelectPartner = (props) => {
    return (
      <React.Fragment>
        <div className="form-group">
          <label>
            <FormattedMessage
              id="nearby-partners.enter-partner-code"
              defaultMessage="Enter Partner Code"
            />
          </label>
          {null == this.state.partner ? (
            <div className="input-group mb-3">
              <input
                type="number"
                className="form-control"
                value={
                  null == this.state.partnerCode ? "" : this.state.partnerCode
                }
                onChange={(event) => {
                  this.setState({ partnerCode: event.target.value });
                }}
              />
              {this.state.isLoadingPartner ? (
                <div
                  className="input-group-append"
                  style={{ cursor: "pointer" }}
                >
                  <span className="input-group-text">
                    <InlineLoader />
                  </span>
                </div>
              ) : (
                <div
                  className="input-group-append"
                  onClick={this.onGetPartner}
                  style={{ cursor: "pointer" }}
                >
                  <span className="input-group-text">
                    <FormattedMessage
                      id="nearby-partners.get-partner"
                      defaultMessage="Get Partner"
                    />
                  </span>
                </div>
              )}
            </div>
          ) : (
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                disabled
                value={
                  null == this.state.partnerCode ? "" : this.state.partnerCode
                }
              />
              <div
                className="input-group-append"
                onClick={this.onClearPartner}
                style={{ cursor: "pointer" }}
              >
                <span className="input-group-text">
                  <FormattedMessage id="general.clear" defaultMessage="Clear" />
                </span>
              </div>
            </div>
          )}
        </div>
        {this.state.partner && (
          <div>
            <p>
              <strong>{this.state.partner.name}</strong>
            </p>
          </div>
        )}
      </React.Fragment>
    );
  };
}

function mapStateToProps(state) {
  return {
    current_partner: state.partner.partnerData,
    profile: state.global.profile,
  };
}

export default connect(mapStateToProps, null)(BillUpload);
