import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import Modal from "react-bootstrap/Modal";
import classNames from "classnames";
import profileClientX from "../utils/api/profileClientX";
const pinCodeRegex = RegExp("^[1-9][0-9]{5}$");

export const useAddressForm = (callback) => {
  const [inputs, setInputs] = useState({
    full_name: {
      value: "",
      isValid: false,
      error: "",
      isPristine: false,
    },
    mobile: {
      value: "",
      isValid: false,
      error: "",
      isPristine: false,
    },
    house_details: {
      value: "",
      isValid: false,
      error: "",
      isPristine: false,
    },
    street: {
      value: "",
      isValid: false,
      error: "",
      isPristine: false,
    },
    landmark: {
      value: "",
      isValid: false,
      error: "",
      isPristine: false,
    },
    area: {
      value: "",
      isValid: false,
      error: "",
      isPristine: false,
    },
    city: {
      value: "",
      isValid: false,
      error: "",
      isPristine: false,
    },
    state: {
      value: "",
      isValid: false,
      error: "",
      isPristine: false,
    },
    pincode: {
      value: "",
      isValid: false,
      error: "",
      isPristine: false,
    },
  });
  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    callback();
  };

  const validateField = (fieldName, value) => {
    const tempInputs = { ...inputs };
    switch (fieldName) {
      case "full_name":
        tempInputs.full_name.isValid = value.length > 2;
        tempInputs.full_name.error = tempInputs.full_name.isValid
          ? ""
          : "Full Name is required";
        break;
      case "mobile":
        tempInputs.mobile.isValid = null !== value.match(/^[6-9]\d{9}$/);
        tempInputs.mobile.error = tempInputs.mobile.isValid
          ? ""
          : "Mobile is invalid";
        break;
      case "house_details":
        tempInputs.house_details.isValid = value.length > 1;
        tempInputs.house_details.error = tempInputs.house_details.isValid
          ? ""
          : "House/Flat name and number is required";
        break;
      case "street":
        tempInputs.street.isValid = value.length > 1;
        tempInputs.street.error = tempInputs.street.isValid
          ? ""
          : "Street is required";
        break;

      case "landmark":
        tempInputs.landmark.isValid = value.length > 1;
        tempInputs.landmark.error = tempInputs.landmark.isValid
          ? ""
          : "landmark is required";
        break;
      case "area":
        tempInputs.area.isValid = value.length > 1;
        tempInputs.area.error = tempInputs.area.isValid
          ? ""
          : "Area is required";
        break;
      case "city":
        tempInputs.city.isValid = value.length > 1;
        tempInputs.city.error = tempInputs.city.isValid
          ? ""
          : "City is required";
        break;
      case "state":
        tempInputs.state.isValid = value.length > 1;
        tempInputs.state.error = tempInputs.state.isValid
          ? ""
          : "State is required";
        break;
      case "pincode":
        tempInputs.pincode.isValid = pinCodeRegex.test(value);
        tempInputs.pincode.error = tempInputs.pincode.isValid
          ? ""
          : "Pin is not valid";
        break;
      default:
        break;
    }
    setInputs((inputs) => ({
      ...inputs,
      [fieldName]: {
        value: value,
        isValid: tempInputs[fieldName].isValid,
        error: tempInputs[fieldName].error,
        isPristine: true,
      },
    }));
  };

  const handleInputChange = (event) => {
    event.persist();
    const { name, value } = event.target;
    validateField(name, value);
  };
  return {
    inputs,
    handleInputChange,
    handleSubmit,
  };
};

//   export default useSignUpForm;
const AddressFormDialog = (props) => {
  const callback = () => {
    const form = {};
    for (let field in inputs) {
      form[field] = inputs[field].value;
    }
    form.type = 1;
    profileClientX
      .post(`v1/addresses`, form)
      .then((response) => {
        props.handleClose(true);
      })
      .catch((error) => {});
  };
  const { inputs, handleInputChange, handleSubmit } = useAddressForm(callback);
  return (
    <React.Fragment>
      <Modal show onHide={props.handleClose} className="address-modal">
        <Modal.Header closeButton className="border-0">
          <Modal.Title>
            {props.isEdit ? (
              <FormattedMessage
                id="profile.edit-address"
                defaultMessage="Edit Address Details"
              />
            ) : (
              <FormattedMessage
                id="profile.add-address"
                defaultMessage="Add Address Details"
              />
            )}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form noValidate>
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="form-group">
                  <label>
                    <FormattedMessage
                      id="profile.full-name"
                      defaultMessage="Full Name"
                    />
                  </label>
                  <input
                    type="text"
                    name="full_name"
                    autoComplete="off"
                    onChange={(event) => {
                      handleInputChange(event);
                    }}
                    value={inputs.full_name.value}
                    className={classNames("form-control", {
                      "is-valid": inputs.full_name.isValid,
                      "is-invalid":
                        inputs.full_name.isPristine &&
                        !inputs.full_name.isValid,
                    })}
                  />
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="form-group">
                  <label>
                    <FormattedMessage
                      id="profile.mobile"
                      defaultMessage="Mobie Number"
                    />
                  </label>
                  <input
                    type="text"
                    name="mobile"
                    minLength="10"
                    maxLength="10"
                    autoComplete="off"
                    onChange={(event) => {
                      handleInputChange(event);
                    }}
                    value={inputs.mobile.value}
                    className={classNames("form-control", {
                      "is-valid": inputs.mobile.isValid,
                      "is-invalid":
                        inputs.mobile.isPristine && !inputs.mobile.isValid,
                    })}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-12">
                <div className="form-group">
                  <label>
                    <FormattedMessage
                      id="profile.address-details"
                      defaultMessage="House/Flat name and number"
                    />
                  </label>
                  <input
                    type="text"
                    name="house_details"
                    autoComplete="off"
                    onChange={(event) => {
                      handleInputChange(event);
                    }}
                    value={inputs.house_details.value}
                    className={classNames("form-control", {
                      "is-valid": inputs.house_details.isValid,
                      "is-invalid":
                        inputs.house_details.isPristine &&
                        !inputs.house_details.isValid,
                    })}
                  />
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="form-group">
                  <label>
                    <FormattedMessage
                      id="profile.street"
                      defaultMessage="Street"
                    />
                  </label>
                  <input
                    type="text"
                    name="street"
                    autoComplete="off"
                    onChange={(event) => {
                      handleInputChange(event);
                    }}
                    value={inputs.street.value}
                    className={classNames("form-control", {
                      "is-valid": inputs.street.isValid,
                      "is-invalid":
                        inputs.street.isPristine && !inputs.street.isValid,
                    })}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="form-group">
                  <label>
                    <FormattedMessage
                      id="profile.landmark"
                      defaultMessage="Landmark"
                    />
                  </label>
                  <input
                    type="text"
                    name="landmark"
                    autoComplete="off"
                    onChange={(event) => {
                      handleInputChange(event);
                    }}
                    value={inputs.landmark.value}
                    className={classNames("form-control", {
                      "is-valid": inputs.landmark.isValid,
                      "is-invalid":
                        inputs.landmark.isPristine && !inputs.landmark.isValid,
                    })}
                  />
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="form-group">
                  <label>
                    <FormattedMessage id="profile.area" defaultMessage="Area" />
                  </label>
                  <input
                    type="text"
                    name="area"
                    autoComplete="off"
                    onChange={(event) => {
                      handleInputChange(event);
                    }}
                    value={inputs.area.value}
                    className={classNames("form-control", {
                      "is-valid": inputs.area.isValid,
                      "is-invalid":
                        inputs.area.isPristine && !inputs.area.isValid,
                    })}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 col-12">
                <div className="form-group">
                  <label>
                    <FormattedMessage id="profile.city" defaultMessage="City" />
                  </label>
                  <input
                    type="text"
                    name="city"
                    autoComplete="off"
                    onChange={(event) => {
                      handleInputChange(event);
                    }}
                    value={inputs.city.value}
                    className={classNames("form-control", {
                      "is-valid": inputs.city.isValid,
                      "is-invalid":
                        inputs.city.isPristine && !inputs.city.isValid,
                    })}
                  />
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="form-group">
                  <label>
                    <FormattedMessage
                      id="profile.state"
                      defaultMessage="State"
                    />
                  </label>
                  <input
                    type="text"
                    name="state"
                    autoComplete="off"
                    onChange={(event) => {
                      handleInputChange(event);
                    }}
                    value={inputs.state.value}
                    className={classNames("form-control", {
                      "is-valid": inputs.state.isValid,
                      "is-invalid":
                        inputs.state.isPristine && !inputs.state.isValid,
                    })}
                  />
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="form-group">
                  <label>
                    <FormattedMessage
                      id="profile.pincode"
                      defaultMessage="Pin Code"
                    />
                  </label>
                  <input
                    type="text"
                    name="pincode"
                    autoComplete="off"
                    minLength="6"
                    maxLength="6"
                    onChange={(event) => {
                      handleInputChange(event);
                    }}
                    value={inputs.pincode.value}
                    className={classNames("form-control", {
                      "is-valid": inputs.pincode.isValid,
                      "is-invalid":
                        inputs.pincode.isPristine && !inputs.pincode.isValid,
                    })}
                  />
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer className="border-0">
          <button
            className="btn btn-primary"
            onClick={handleSubmit}
            disabled={
              !inputs.full_name.isValid ||
              !inputs.mobile.isValid ||
              !inputs.house_details.isValid ||
              !inputs.street.isValid ||
              !inputs.landmark.isValid ||
              !inputs.area.isValid ||
              !inputs.city.isValid ||
              !inputs.state.isValid ||
              !inputs.pincode.isValid
            }
          >
            <FormattedMessage
              id="profile.save-addresses"
              defaultMessage="Save Address"
            />
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default AddressFormDialog;
