import React, { Component } from "react";
import { Link } from "react-router-dom";
import profileClientX from "../../../utils/api/profileClientX"
import SideBar from "../components/SideBar";
import Profile from "../components/Profile";
import lodash from "lodash";
import ContentLoader from "react-content-loader";
import { FormattedMessage } from "react-intl";
import Modal from "react-bootstrap/Modal";
import classNames from "classnames";
import editIcon from "../../../assets/edit-icon.svg";
import deleteIcon from "../../../assets/delete.svg";
import locationIcon from "../../../assets/location-icon.svg";
import { onViewedPage } from "../../../utils/events";
import update from "immutability-helper";
import "react-toastify/dist/ReactToastify.css";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import EmptyState from "../../../components/EmptyState";
import EmptyImage from "../../../assets/empty-state-screen.svg";
import { connect } from "react-redux";
import { updateAddress, getAddresses } from "../../../actions";
const pinCodeRegex = RegExp("^[1-9][0-9]{5}$");

class Addresses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isModelOpen: false,
      currentConfirmationDialog: null,
      isEdit: false,
      editedAddress: {
        full_name: {
          value: "",
          isValid: false,
          error: "",
          isPristine: true,
        },
        mobile: {
          value: "",
          isValid: false,
          error: "",
          isPristine: true,
        },
        house_details: {
          value: "",
          isValid: false,
          error: "",
          isPristine: true,
        },
        street: {
          value: "",
          isValid: false,
          error: "",
          isPristine: true,
        },
        landmark: {
          value: "",
          isValid: false,
          error: "",
          isPristine: true,
        },
        area: {
          value: "",
          isValid: false,
          error: "",
          isPristine: true,
        },
        city: {
          value: "",
          isValid: false,
          error: "",
          isPristine: true,
        },
        state: {
          value: "",
          isValid: false,
          error: "",
          isPristine: true,
        },
        pincode: {
          value: "",
          isValid: false,
          error: "",
          isPristine: true,
        },
        formValid: false,
        id: "",
        currentIndex: "",
      },
    };
  }

  componentDidMount() {
    this.getAddress();
  }

  getAddress = () => {
    profileClientX.get("v1/addresses ").then((response) => {
      this.setState({
        addresses: response.data.data,
        isLoading: false,
      });
      this.props.getAddresses(response.data.data)
    });
  };

  edit = (item, index) => {
    const {
      id,
      full_name,
      mobile,
      house_details,
      street,
      landmark,
      area,
      city,
      state,
      pincode,
    } = item;
    const newData = update(this.state, {
      isModelOpen: { $set: true },
      isEdit: { $set: true },
      editedAddress: {
        id: { $set: id },
        currentIndex: { $set: index },
        formValid: { $set: true },
        full_name: {
          value: { $set: full_name },
          isPristine: { $set: true },
          isValid: { $set: true },
        },
        mobile: {
          value: { $set: mobile },
          isPristine: { $set: true },
          isValid: { $set: true },
        },
        house_details: {
          value: { $set: house_details },
          isPristine: { $set: true },
          isValid: { $set: true },
        },
        street: {
          value: { $set: street },
          isPristine: { $set: true },
          isValid: { $set: true },
        },
        landmark: {
          value: { $set: landmark },
          isPristine: { $set: true },
          isValid: { $set: true },
        },
        area: {
          value: { $set: area },
          isPristine: { $set: true },
          isValid: { $set: true },
        },
        city: {
          value: { $set: city },
          isPristine: { $set: true },
          isValid: { $set: true },
        },
        state: {
          value: { $set: state },
          isPristine: { $set: true },
          isValid: { $set: true },
        },
        pincode: {
          value: { $set: pincode },
          isPristine: { $set: true },
          isValid: { $set: true },
        },
      },
    });
    this.setState(newData);
  };

  addAddress = () => {
    const payload = {
      page_name: "Add Address Page",
    };

    onViewedPage(payload);
    this.setState({
      isModelOpen: true,
      isEdit: false,
      editedAddress: {
        full_name: {
          value: "",
          isValid: false,
          error: "",
          isPristine: false,
        },
        mobile: {
          value: "",
          isValid: false,
          error: "",
          isPristine: false,
        },
        house_details: {
          value: "",
          isValid: false,
          error: "",
          isPristine: false,
        },
        street: {
          value: "",
          isValid: false,
          error: "",
          isPristine: false,
        },
        landmark: {
          value: "",
          isValid: false,
          error: "",
          isPristine: false,
        },
        area: {
          value: "",
          isValid: false,
          error: "",
          isPristine: false,
        },
        city: {
          value: "",
          isValid: false,
          error: "",
          isPristine: false,
        },
        state: {
          value: "",
          isValid: false,
          error: "",
          isPristine: false,
        },
        pincode: {
          value: "",
          isValid: false,
          error: "",
          isPristine: false,
        },
        formValid: false,
      },
    });
  };

  handleClose = () => {
    this.setState({ isModelOpen: false });
  };

  onConfirmDeleteAddress = () => {
    profileClientX
      .delete(`v1/addresses/${this.state.currentConfirmationDialog.addressId}`)
      .then((response) => {
        let addresses = this.props.addresses;
        addresses.splice(this.state.currentConfirmationDialog.currentIndex, 1);
        let newState = update(this.state, {
          isModelOpen: { $set: false },
          isLoading: { $set: false },
          addresses: { $set: addresses },
          currentConfirmationDialog: { $set: null },
        });
        this.setState(newState);
        this.props.getAddresses(addresses);
      })
      .catch((error) => {
       });
  };
  onDismissDeleteAddress = () => {
    this.setState({
      currentConfirmationDialog: null,
    });
  };

  delete = (addressId, currentIndex, name) => {
    const currentModelItem = {
      addressId: addressId,
      name: name,
      currentIndex: currentIndex,
    };
    this.setState({
      currentConfirmationDialog: { ...currentModelItem },
    });
  };

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const newData = update(this.state, {
      editedAddress: {
        [name]: {
          value: { $set: value },
          isPristine: { $set: true },
        },
      },
    });

    this.setState(newData, () => {
      this.validateField(name, value);
    });
  };

  validateField(fieldName, value) {
    const editedAddress = { ...this.state.editedAddress };
    switch (fieldName) {
      case "full_name":
        editedAddress.full_name.isValid = value.length > 2;
        editedAddress.full_name.error = editedAddress.full_name.isValid
          ? ""
          : "Full Name is required";
        break;
      case "mobile":
        editedAddress.mobile.isValid = null !== value.match(/^[6-9]\d{9}$/);
        editedAddress.mobile.error = editedAddress.mobile.isValid
          ? ""
          : "Mobile is invalid";
        break;
      case "house_details":
        editedAddress.house_details.isValid = value.length > 1;
        editedAddress.house_details.error = editedAddress.house_details.isValid
          ? ""
          : "House/Flat name and number is required";
        break;
      case "street":
        editedAddress.street.isValid = value.length > 1;
        editedAddress.street.error = editedAddress.street.isValid
          ? ""
          : "Street is required";
        break;

      case "landmark":
        editedAddress.landmark.isValid = value.length > 1;
        editedAddress.landmark.error = editedAddress.landmark.isValid
          ? ""
          : "landmark is required";
        break;
      case "area":
        editedAddress.area.isValid = value.length > 1;
        editedAddress.area.error = editedAddress.area.isValid
          ? ""
          : "Area is required";
        break;
      case "city":
        editedAddress.city.isValid = value.length > 1;
        editedAddress.city.error = editedAddress.city.isValid
          ? ""
          : "City is required";
        break;
      case "state":
        editedAddress.state.isValid = value.length > 1;
        editedAddress.state.error = editedAddress.state.isValid
          ? ""
          : "State is required";
        break;
      case "pincode":
        editedAddress.pincode.isValid = pinCodeRegex.test(value);
        editedAddress.pincode.error = editedAddress.pincode.isValid
          ? ""
          : "Pin is not valid";
        break;
      default:
        break;
    }
    const newData = update(this.state, {
      editedAddress: {
        formValid: {
          $set: this.validateForm(editedAddress) && this.isFormRequiredFilled(),
        },
      },
    });

    this.setState(newData);
  }

  handleSubmit = (event) => {
    this.setState({ isLoading: true });
    const {
      full_name,
      mobile,
      house_details,
      street,
      landmark,
      area,
      city,
      state,
      pincode,
    } = this.state.editedAddress;
    const formData = {
      full_name: full_name.value,
      mobile: mobile.value,
      house_details: house_details.value,
      street: street.value,
      landmark: landmark.value,
      area: area.value,
      city: city.value,
      state: state.value,
      pincode: pincode.value,
      type: 1,
    };
    this.state.isEdit
      ? this.editAddressApi(formData)
      : this.addAddressApi(formData);
  };

  addAddressApi = (formData) => {
    profileClientX
      .post(`v1/addresses`, formData)
      .then((response) => {
        this.getAddress();
        this.setState({
          isModelOpen: false,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          isModelOpen: true,
          isLoading: false,
        });
      });
  };

  editAddressApi = (formData) => {
    profileClientX
      .put(`v1/addresses/${this.state.editedAddress.id}`, formData)
      .then((response) => {
        let newState = update(this.state, {
          isModelOpen: { $set: false },
          isLoading: { $set: false }
        });
        this.setState(newState);
        const { id } = response.data.data;

        this.props.updateAddress(response.data.data, id)
      })
      .catch((error) => {
        this.setState({
          isModelOpen: true,
          isLoading: false,
        });
      });
  };

  isFormRequiredFilled = () => {
    const {
      full_name,
      mobile,
      house_details,
      street,
      landmark,
      area,
      city,
      state,
      pincode,
    } = this.state.editedAddress;
    return [
      full_name,
      mobile,
      house_details,
      street,
      landmark,
      area,
      city,
      state,
      pincode,
    ].every((x) => x.value);
  };

  validateForm = (editedAddress) => {
    const {
      full_name,
      mobile,
      house_details,
      street,
      landmark,
      area,
      city,
      state,
      pincode,
    } = this.state.editedAddress;
    return [
      full_name,
      mobile,
      house_details,
      street,
      landmark,
      area,
      city,
      state,
      pincode,
    ].every((x) => x.isValid);
  };

  addressFormDialog = (props) => {
    return (
      <React.Fragment>
        <Modal
          show={this.state.isModelOpen}
          onHide={this.handleClose}
          className="address-modal"
        >
          <Modal.Header closeButton className="border-0">
            <Modal.Title>
              {this.state.isEdit ? (
                <FormattedMessage
                  id="profile.edit-address"
                  defaultMessage="Edit Address Details"
                />
              ) : (
                <FormattedMessage
                  id="profile.add-address"
                  defaultMessage="Add Address Details"
                />
              )}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <form noValidate>
              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="form-group">
                    <label>
                      <FormattedMessage
                        id="profile.full-name"
                        defaultMessage="Full Name"
                      />
                    </label>
                    <input
                      type="text"
                      name="full_name"
                      autoComplete="off"
                      onChange={(event) => {
                        this.handleUserInput(event);
                      }}
                      value={this.state.editedAddress.full_name.value}
                      className={classNames("form-control", {
                        "is-valid": this.state.editedAddress.full_name.isValid,
                        "is-invalid":
                          this.state.editedAddress.full_name.isPristine &&
                          !this.state.editedAddress.full_name.isValid,
                      })}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="form-group">
                    <label>
                      <FormattedMessage
                        id="profile.mobile"
                        defaultMessage="Mobie Number"
                      />
                    </label>
                    <input
                      type="text"
                      name="mobile"
                      minLength="10"
                      maxLength="10"
                      autoComplete="off"
                      onChange={(event) => {
                        this.handleUserInput(event);
                      }}
                      value={this.state.editedAddress.mobile.value}
                      className={classNames("form-control", {
                        "is-valid": this.state.editedAddress.mobile.isValid,
                        "is-invalid":
                          this.state.editedAddress.mobile.isPristine &&
                          !this.state.editedAddress.mobile.isValid,
                      })}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="form-group">
                    <label>
                      <FormattedMessage
                        id="profile.address-details"
                        defaultMessage="House/Flat name and number"
                      />
                    </label>
                    <input
                      type="text"
                      name="house_details"
                      autoComplete="off"
                      onChange={(event) => {
                        this.handleUserInput(event);
                      }}
                      value={this.state.editedAddress.house_details.value}
                      className={classNames("form-control", {
                        "is-valid": this.state.editedAddress.house_details
                          .isValid,
                        "is-invalid":
                          this.state.editedAddress.house_details.isPristine &&
                          !this.state.editedAddress.house_details.isValid,
                      })}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="form-group">
                    <label>
                      <FormattedMessage
                        id="profile.street"
                        defaultMessage="Street"
                      />
                    </label>
                    <input
                      type="text"
                      name="street"
                      autoComplete="off"
                      onChange={(event) => {
                        this.handleUserInput(event);
                      }}
                      value={this.state.editedAddress.street.value}
                      className={classNames("form-control", {
                        "is-valid": this.state.editedAddress.street.isValid,
                        "is-invalid":
                          this.state.editedAddress.street.isPristine &&
                          !this.state.editedAddress.street.isValid,
                      })}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="form-group">
                    <label>
                      <FormattedMessage
                        id="profile.landmark"
                        defaultMessage="Landmark"
                      />
                    </label>
                    <input
                      type="text"
                      name="landmark"
                      autoComplete="off"
                      onChange={(event) => {
                        this.handleUserInput(event);
                      }}
                      value={this.state.editedAddress.landmark.value}
                      className={classNames("form-control", {
                        "is-valid": this.state.editedAddress.landmark.isValid,
                        "is-invalid":
                          this.state.editedAddress.landmark.isPristine &&
                          !this.state.editedAddress.landmark.isValid,
                      })}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="form-group">
                    <label>
                      <FormattedMessage
                        id="profile.area"
                        defaultMessage="Area"
                      />
                    </label>
                    <input
                      type="text"
                      name="area"
                      autoComplete="off"
                      onChange={(event) => {
                        this.handleUserInput(event);
                      }}
                      value={this.state.editedAddress.area.value}
                      className={classNames("form-control", {
                        "is-valid": this.state.editedAddress.area.isValid,
                        "is-invalid":
                          this.state.editedAddress.area.isPristine &&
                          !this.state.editedAddress.area.isValid,
                      })}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4 col-12">
                  <div className="form-group">
                    <label>
                      <FormattedMessage
                        id="profile.city"
                        defaultMessage="City"
                      />
                    </label>
                    <input
                      type="text"
                      name="city"
                      autoComplete="off"
                      onChange={(event) => {
                        this.handleUserInput(event);
                      }}
                      value={this.state.editedAddress.city.value}
                      className={classNames("form-control", {
                        "is-valid": this.state.editedAddress.city.isValid,
                        "is-invalid":
                          this.state.editedAddress.city.isPristine &&
                          !this.state.editedAddress.city.isValid,
                      })}
                    />
                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <div className="form-group">
                    <label>
                      <FormattedMessage
                        id="profile.state"
                        defaultMessage="State"
                      />
                    </label>
                    <input
                      type="text"
                      name="state"
                      autoComplete="off"
                      onChange={(event) => {
                        this.handleUserInput(event);
                      }}
                      value={this.state.editedAddress.state.value}
                      className={classNames("form-control", {
                        "is-valid": this.state.editedAddress.state.isValid,
                        "is-invalid":
                          this.state.editedAddress.state.isPristine &&
                          !this.state.editedAddress.state.isValid,
                      })}
                    />
                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <div className="form-group">
                    <label>
                      <FormattedMessage
                        id="profile.pincode"
                        defaultMessage="Pin Code"
                      />
                    </label>
                    <input
                      type="text"
                      name="pincode"
                      autoComplete="off"
                      minLength="6"
                      maxLength="6"
                      onChange={(event) => {
                        this.handleUserInput(event);
                      }}
                      value={this.state.editedAddress.pincode.value}
                      className={classNames("form-control", {
                        "is-valid": this.state.editedAddress.pincode.isValid,
                        "is-invalid":
                          this.state.editedAddress.pincode.isPristine &&
                          !this.state.editedAddress.pincode.isValid,
                      })}
                    />
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>

          <Modal.Footer className="border-0">
            <button
              className="btn btn-primary"
              onClick={this.handleSubmit}
              disabled={!this.state.editedAddress.formValid}
            >
              <FormattedMessage
                id="profile.save-addresses"
                defaultMessage="Save Address"
              />
            </button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid pt-3 pb-3 pt-md-4 pb-md-4 profile-container-head">
          <div className="container">
            <div className="row mb-md-3">
              <div className="col-12 m-auto font-proxima-nova text-white">
                <small>
                  <Link to="/">
                    <FormattedMessage
                      id="breadcrumbs.home"
                      defaultMessage="Home"
                    />
                  </Link>
                </small>{" "}
                /{" "}
                <small>
                  <Link to="/profile">
                    <FormattedMessage
                      id="profile.profile"
                      defaultMessage="Profile"
                    />
                  </Link>
                </small>{" "}
                /{" "}
                <small>
                  <strong>
                    <FormattedMessage
                      id="profile.saved-addresses"
                      defaultMessage="Saved Addresses"
                    />
                  </strong>
                </small>
              </div>
            </div>

            <div className="d-none d-md-block">
              <Profile />
            </div>
          </div>
        </div>

        <div className="container mt-md-5 mb-4 mb-md-5">
          <div className="row mb-3 mt-md-4 mb-md-4">
            <div className="d-none col-md-4 d-md-block font-proxima-nova border-right">
              <SideBar />
            </div>
            <div className="col-12 col-md-8 d-md-block address-wrapper m-0">
              <div className="row d-md-flex">
                <div className="col-7 col-md-8 font-proxima-nova-bold">
                  <h5 className="d-none d-md-block">
                    <strong>
                      <FormattedMessage
                        id="profile.saved-addresses"
                        defaultMessage="Saved Addresses"
                      />
                    </strong>
                  </h5>
                </div>
                <div className="col-5 col-md-4">
                  <button
                    className="btn btn-outline float-right"
                    onClick={this.addAddress}
                    style={{ color: "#087CD8", marginTop: "-0.5rem" }}
                  >
                    <FormattedMessage
                      id="profile.add-address"
                      defaultMessage="Add Address"
                    />
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-12  font-proxima-nova d-flex justify-content-between flex-wrap">
                  {this.state.isLoading ? (
                    <React.Fragment>
                      {lodash.range(5).map((index) => {
                        return (
                          <div
                            className="card mb-3 bg-light border-0"
                            key={index}
                          >
                            <div className="card-body">
                              <div
                                style={{ height: "40px", overflow: "hidden" }}
                              >
                                <ContentLoader
                                  height="40px"
                                  width="300px"
                                  speed={2}
                                  primaryColor="#f3f3f3"
                                  secondaryColor="#ecebeb"
                                >
                                  <rect
                                    x="0"
                                    y="0"
                                    rx="4"
                                    ry="4"
                                    width="100"
                                    height="15"
                                  />
                                  <rect
                                    x="0"
                                    y="20"
                                    rx="3"
                                    ry="3"
                                    width="300"
                                    height="15"
                                  />
                                </ContentLoader>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </React.Fragment>
                  ) : 0 === this.props.addresses.length ? (
                    <div className="empty-state">
                      <EmptyState title={
                        <FormattedMessage
                          id="profile.no-saved-addresses"
                          defaultMessage="You don't have any saved addresses."
                        />}
                        imageWidth={40}
                        image={EmptyImage}
                      />

                      <this.addressFormDialog />
                    </div>
                  ) : (
                    <React.Fragment>
                      {this.props.addresses.map((item, index) => {
                        return (
                          <div
                            className="card mb-2 mt-2 mt-md-4 bg-light border-0 col-md-5"
                            key={index}
                          >
                            <div className="card-body">
                              <p
                                className="mb-0 text-secondary"
                                style={{ fontSize: "1.2rem" }}
                              >
                                <img
                                  className="mr-2"
                                  src={locationIcon}
                                  alt={item.full_name}
                                  title={item.full_name}
                                />
                                {item.full_name}
                              </p>
                              <p
                                className="mb-0 mt-2 text-secondary"
                                style={{ fontSize: "1rem" }}
                              >
                                {item.house_details}, {item.street},{" "}
                                {item.landmark}, {item.area}, {item.city},{" "}
                                {item.state}, {item.pincode}
                              </p>
                              <div className="row">
                                <div className="col-12 text-right">
                                  <span
                                    onClick={() => {
                                      this.edit(item, index);
                                    }}
                                    className="d-inline-block align-bottom mr-md-3 mt-2 btn"
                                  >
                                    <img
                                      src={editIcon}
                                      className="align-text-top mr-2 edit-icon"
                                      alt="edit icon"
                                      title="edit icon"
                                    />
                                    <FormattedMessage
                                      id="general.edit"
                                      defaultMessage="Edit"
                                    />
                                  </span>
                                  <span
                                    onClick={() =>
                                      this.delete(
                                        item.id,
                                        index,
                                        item.full_name
                                      )
                                    }
                                    className="d-inline-block align-bottom mr-md-3 mt-2 btn"
                                  >
                                    <img
                                      src={deleteIcon}
                                      className="align-text-top mr-2"
                                      alt="delete icon"
                                      title="delete icon"
                                    />

                                    <FormattedMessage
                                      id="general.delete"
                                      defaultMessage="Delete"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}

                      <this.addressFormDialog />
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.currentConfirmationDialog && (
          <ConfirmationDialog
            isConfirmationDialogOpen={true}
            body={
              <>
                <FormattedMessage
                  id="address.delete-address"
                  defaultMessage="Are you sure, you want to delete this address?"
                />
              </>
            }
            buttonTxt1={
              <>
                <FormattedMessage
                  id="profile.confirm"
                  defaultMessage="Confirm"
                />
              </>
            }
            buttonTxt2={
              <>
                <FormattedMessage id="profile.cancel" defaultMessage="Cancel" />
              </>
            }
            onSuccess={this.onConfirmDeleteAddress}
            onDismiss={this.onDismissDeleteAddress}
          />
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    addresses: state.global.addresses,
  };
}

function mapDispatchToProps(dispatch) {
  return {

    getAddresses: (responseData) => {
      dispatch(getAddresses(responseData));
    },
    updateAddress: (responseData, addressId) => {
      dispatch(updateAddress(responseData, addressId));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Addresses);
