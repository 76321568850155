import HelmetMetaData from "../components/HelmetMetaData";
import { useLocation } from "react-router-dom";

function PatronsMetaData() {
  let location = useLocation();
  let currentAppUrl = process.env.REACT_APP_URL + location.pathname;

  return (
    <HelmetMetaData
      title={`WINDS Patron: Save & Earn Rewards | WINDS App`}
      description={`WINDS Patron can enjoy a wide range of benefits with a seamless shopping experience. They get access to over 100+ nearby store categories, brand vouchers, online shopping sites and utility bill payments through WINDS App.`}
      keywords={`patrons, winds patron, winds patron benefits, winds patron referral program, winds patron offers, vouchers, brand vouchers, gift vouchers, e-vouchers, discount vouchers, online shopping sites, winds app, winds. `}
      voiceSchema={<script type="application/ld+json">
        {JSON.stringify(
          {
            "@context": "https://schema.org/",
            "@type": "WebPage",
            "name": "WINDS PATRON",
            "speakable":
            {
              "@type": "SpeakableSpecification",
              "xpath":
                [
                  "/html/head/title",
                  "/html/head/meta[@name='description']/@content"
                ]
            },
            "url": currentAppUrl
          }
        )}
      </script>
      }
      breadcrumbSchema={<script type="application/ld+json">
        {JSON.stringify(
          {
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            "itemListElement": [{
              "@type": "ListItem",
              "position": 1,
              "name": "Home",
              "item": process.env.REACT_APP_URL
            }, {
              "@type": "ListItem",
              "position": 2,
              "name": "WINDS PATRON",
              "item": currentAppUrl
            }]
          }
        )}
      </script>}
      faqSchema={
        <script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [
                {
                  "@type": "Question",
                  "name": "What Is WINDS App?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The WINDS App is a one of its kind ‘UNIVERSAL SUPER APP’ which brings in the convenience of discovering Online E-commerce Platforms, Neighborhood Retail Stores, E Gift Vouchers of the choicest Brands and Utility Bill Payment options. On regular day-to-day shopping or any transaction through this app, whether for a product or service, THE SHOPPERS STAND A CHANCE TO BE REWARDED! The customer collects WRP ( WINDS REWARD POINTS) on every purchase, and on collecting 5000 WRP, they get a chance to win Huge Cash Rewards* in the Lucky Draw every month."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Who Are Called WINDS Patron?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Anyone individual shopping or using any service through the WINDS ecosystem is termed a WINDS Patron (Customer)."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Is using a referral code to download the WINDS Patron App mandatory?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "No, having a referral code to download the app is not mandatory. You can always download the app from the respective Play Stores."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Do You Have A Referral Programme?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, we do have a referral programme. Whenever an individual refers the WINDS Patron App, the referer & referee both would earn 1000 WRP. Additionally, the referer would be entitled to continuous earnings. This means if PATRON B signs up using PATRON A's referral code, PATRON A will earn 10% of WINDS' Earnings on all transactions done by PATRON B through the WINDS App (For instance, if PATRON B buys a laptop worth Rs. 1 lakh through the WINDS Partner, and if the WINDS' earning on the transaction is 5%, i.e. Rs. 5,000/-, then PATRON A will get 10% of WINDS' earning, i.e. Rs. 500/-)."
                  }
                },
                {
                  "@type": "Question",
                  "name": "What Is WRP & How Can We Redeem It In Cash?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "WRP stands for WINDS Reward Points. Customers can earn WRP by making purchases at Nearby Partners (registered WINDS Partners), shopping at Online Sites, buying Brand Vouchers, or making utility bill payments through the WINDS App.\n\nThough WRPs are hugely beneficial, you cannot redeem or convert them to cash. WRP will enable your participation in a monthly cash rewards draw. On accumulating 5,000 such points, you get a digital coupon that automatically marks your entry into the draw, where you stand a chance to win Cash Rewards* worth Crores of Rupees."
                  }
                },
                {
                  "@type": "Question",
                  "name": "How Can I Keep Track of Total WRPs Earned?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Open The WINDS App > Click On The WRP Section > Total Number of WRPs & Coupons Would Be Displayed There."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Can I Transfer Money via WINDS App?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Unfortunately, you can't transfer money via the WINDS app. However, you can always buy E-Gift cards for your loved ones & gift them."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Why Should I Switch To WINDS?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "WINDS is a unique Shopping App which will reward you for your daily shopping transactions like never before. On your regular transactions, you collect WRP ( WINDS REWARD POINTS) on every rupee spent via WINDS and on collecting 5000 WRP, you get a chance to win Lakhs* & Crores* in the monthly lucky draw without doing anything extra.\n\nIn addition, you are entitled to referral benefits as a percentage of WINDS earnings. And that too, for just your routine shopping that you do every month. Where else can you get such enormous benefits?"
                  }
                },
                {
                  "@type": "Question",
                  "name": "How Can A User Participate In The WINDS Monthly Lucky Draw?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Every Rupee spent on the WINDS App by a Patron (Customer) will earn them 1 WRP. They can collect more and more WRP on regular purchases/transactions through WINDS; 5000 WRP enables the Patrons (Customers) to enter 2 Draws - the MINI MONTHLY REWARDS DRAW exclusively for Patrons (Customers) for which they qualify immediately with a 5000 WRP coupon and at a later stage, the MEGA BUMPER DRAW which will be held when the Patrons' (Customers) group size reaches 15 Lakh qualifiers for whom there will be an additional Rs. 28 crore MegaDraw where the first prize will be Rs. 2 crores with more than 2 Lakh prize winners.\n\nWhen the group size increases to 30 lakh - 45 lakh and goes up to 90 lakh Patrons (Customers), the draw will go up to more than Rs. 168 Crore with the first prize of Rs. 10 crores; there will be more than 12 lac winners in that draw."
                  }
                },
                {
                  "@type": "Question",
                  "name": "If, As A Patron (Customer), I Can Accumulate Only 4500 WRP In A Month, Will I Be Able To Participate In The Lucky Draw? If Not, Then What Will Happen To My WRP?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "In a situation like this, where you haven't been able to accumulate a minimum of 5000 WRP in a particular month, you need not get disheartened. If you earn only 4500 WRP a month, these points will not expire but will be carried forward to the next month. Whenever you collect 500 more WRPs, you will be eligible to participate in the lucky draw.\n\nAlso, remember, after earning 5000 WRP, you will get not only a chance to participate in the regular MONTHLY LUCKY DRAW but also an opportunity to participate in the MEGA BUMPER DRAW."
                  }
                },
                {
                  "@type": "Question",
                  "name": "How Will I Know If I Am Eligible For Any Of The Draws?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Open The WINDS App > Click On The WRP Section > Total Number of WRPs & Coupons Would Be Displayed There."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Are My Personal Details Safe On The App?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "It is entirely safe to update your details on WINDS App. Such information won't be shared with the Partners. WINDS understands and respects the privacy of its customers."
                  }
                },
                {
                  "@type": "Question",
                  "name": "How Much Time Does It Take For The WRP To Get Reflected After A Purchase?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "It takes less than a minute for the recently earned WRP to get reflected. You can always check them under the WRP section available on the app's homepage."
                  }
                },
                {
                  "@type": "Question",
                  "name": "What Do I Need To Do To Receive My Money If I Win In The Lucky Draw?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "After you have won the cash prize, it will reflect under the wallet option in your app. You will have to enter a withdrawal request, enter and validate your bank account details. Once you complete this process, the amount will be transferred to your bank account."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Why Do I Need To Share My Bank Account Details?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "If you participate in the lucky draw through WINDS and win rewards from the pool of Hundreds of Crores, then as per the applicable laws, rules & regulations, the reward money will have to be transferred to your bank A/C registered with WINDS. Hence, the bank details would be required to share your winnings."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Do I Have To Provide Any Government ID To Validate My Profile?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, you are required to provide your PAN Card copy for verification. Additionally, you can also give a copy of your Aadhaar Card. If you win the cash reward, WINDS will need these IDs along with your bank account details to transfer the cash directly to your account."
                  }
                },
                {
                  "@type": "Question",
                  "name": "How Can I Get In Touch With Customer Support For Any Queries/Complaints?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "You can contact Customer Support via the Chat feature available under the help section in the app. Apart from this, one can call on our Toll-Free number:1800 103 1032 or reach out to us by sending an email to: support@WINDS.co.in"
                  }
                }
              ]
            }
          )}
        </script>
      }
    ></HelmetMetaData>
  );
}

export default PatronsMetaData;