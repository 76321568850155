import React, { Component } from 'react';
import fallbackVector from '../assets/500-error-vector.svg';
import { Link } from "react-router-dom";

export default class FallBack extends Component{

    render(){

        return(
            <div className="container-fluid mt-3 mb-3 mt-md-5 mb-md-5">
                <div className="row">
                    <div className="col-12 col--404 text-center">
                        <img src={fallbackVector} alt="page not found" title="page not found" />
                        <p className="mt-5 font-weight-bold">Something went wrong!</p>
                        <Link to="/">
                            <button className="btn btn-primary mt-3">GO TO HOME</button>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}
