import React, { Component } from "react";
import { Link } from "react-router-dom";
import { resetCategory } from "../actions/partner";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import customerPartnerClientX from "../../../utils/api/customerPartnerClientX";
class CategoryList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: [],
      isLoadingCategories: false,
    };
  }

  componentDidMount() {
    // const payload = {
    //   page_name: "Nearby Partner List Page",
    // };

    // onViewedPage(payload);
    
    this.setState(
      {
        isLoadingCategories: true,
      },
      () => {
        customerPartnerClientX.get("v1/categories").then((response) => {
          let data = response.data.data;

          this.setState({
            categories: data,
            isLoadingCategories: false,
          });
        });
      }
    );
  }

  render() {
    return (
      <div className="container">
        <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
          <div className="col-12">
            <div className="row align-items-center">
              <div className="col-6 col-md-9">
                <small>
                  <Link to="/">
                    <FormattedMessage
                      id="breadcrumbs.home"
                      defaultMessage="Home"
                    />
                  </Link>{" "}
                  /{" "}
                  <strong>
                    <FormattedMessage
                      id="nearby-partners.nearby-categories"
                      defaultMessage="Nearby Categories"
                    />
                  </strong>
                </small>
              </div>
              <div className="col-6 col-md-3">
                <Link to="/nearby-partners">
                  <button className="btn btn-primary btn-sm btn-block font-weight-bold home-btn w-100">
                    <FormattedMessage
                      id="home.nearby-partners"
                      defaultMessage=" Nearby Partners"
                    />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {this.state.isLoadingCategories ? (
              <this.Shimmer />
            ) : (
              <this.Categories />
            )}
          </div>
        </div>
      </div>
    );
  }

  Shimmer = (props) => {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-6 col-md-3">
            <div className="box shine mb-3 mb-md-3"></div>
          </div>
          <div className="col-6 col-md-3">
            <div className="box shine mb-3 mb-md-3"></div>
          </div>
          <div className="col-6 col-md-3 d-none d-md-block">
            <div className="box shine mb-3 mb-md-3"></div>
          </div>
          <div className="col-6 col-md-3 d-none d-md-block">
            <div className="box shine mb-3 mb-md-3"></div>
          </div>
        </div>
        <div className="row">
          <div className="col-6 col-md-3">
            <div className="box shine mb-3 mb-md-3"></div>
          </div>
          <div className="col-6 col-md-3">
            <div className="box shine mb-3 mb-md-3"></div>
          </div>
          <div className="col-6 col-md-3 d-none d-md-block">
            <div className="box shine mb-3 mb-md-3"></div>
          </div>
          <div className="col-6 col-md-3 d-none d-md-block">
            <div className="box shine mb-3 mb-md-3"></div>
          </div>
        </div>
        <div className="row">
          <div className="col-6 col-md-3">
            <div className="box shine mb-3 mb-md-3"></div>
          </div>
          <div className="col-6 col-md-3">
            <div className="box shine mb-3 mb-md-3"></div>
          </div>
          <div className="col-6 col-md-3 d-none d-md-block">
            <div className="box shine mb-3 mb-md-3"></div>
          </div>
          <div className="col-6 col-md-3 d-none d-md-block">
            <div className="box shine mb-3 mb-md-3"></div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  Categories = (props) => {
    return (
      <div className="row">
        {this.state.categories.map((item, index) => {
          return (
            <div
              className="col-6 col-md-3"
              style={{ cursor: "pointer" }}
              key={"category-" + index}
              onClick={() => {
                this.props.onSelectCategory(item.id);
                this.props.history.push("/nearby-partners");
              }}
            >
              <div className="card  card--carousel-item card--carousel-item--nearby-partner mb-4">
                <div className="card-body">
                  <img
                    style={{ width: "100%" }}
                    src={item.web_image_url}
                    alt={item.name}
                    title={item.name}
                  />
                  <div className="category-label">
                    <span>{item.name}</span>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    onSelectCategory: (id) => {
      dispatch(resetCategory(id));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CategoryList));
