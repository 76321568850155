import Modal from "react-bootstrap/Modal";
import React, { useReducer, useState } from "react";
import { FormattedMessage } from "react-intl";
import walletClientX from "../../../utils/api/walletClientX";
import withDrawSuccessVector from "../../../assets/withdraw-success.svg";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { showToastMessage } from "../../../utils/helper";
import InlineLoader from "../../../components/InlineLoader";
import VerifyOTP from "./VerifyOTP";

const initialState = {
  amount: "",
  account_name: "",
  account_number: "",
  ifsc: "",
  bank: "",
  branch: "",
  state: "",
  city: "",
  pan: "",
  errors: {
    amount: "valid",
    account_name: "",
    account_number: "",
    ifsc: "",
    bank: "",
    branch: "",
    state: "",
    city: "",
    pan: "",
  },
};

const reducer = (formState, { field, value }, apiBro) => {
  let errors = { ...formState.errors };
  switch (field) {
    case "amount":
      errors[field] = value >= 100 ? "valid" : "invalid";
      break;
    case "account_name":
    case "bank":
    case "branch":
    case "state":
    case "city":
      errors[field] = value.length > 0 ? "valid" : "invalid";
      break;
    case "pan":
      value = value.toUpperCase();
      errors[field] = value.match(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/)
        ? "valid"
        : "invalid";
      break;
    case "ifsc":
      errors[field] = value.match(/^[A-Za-z]{4}\d{7}$/) ? "valid" : "invalid";
      break;
    case "account_number":
      errors[field] = value.match(/^\d{9,18}$/) ? "valid" : "invalid";
      break;
    default:
      break;
  }
  return {
    ...formState,
    [field]: value,
    errors: errors,
  };
};
const AddAccount = (props) => {
  const history = useHistory();
  const selectedBankDetail = props.selectedBank;
  const [isSubmitting, setSubmitting] = useState(false);
  const [isIfscVerified, verifyIfsc] = useState(
    selectedBankDetail ? true : false
  );

  const [isVerifyingIFSC, setIFSCVerifying] = useState(false);
  const [withDrawState, setWithDrawState] = useState("NOT_CONFIRMED");

  const [isVerifyOTPModalOpen, toggleVerifyOTPModel] = useState(false);

  const openVerifyOTP = () => {
    setWithDrawState("VERIFY_OTP");
    toggleVerifyOTPModel(true);
    walletClientX.post(`/v1/withdraw/send-otp`).then((response) => {

      // setTimeout(() => {
      //   history.push("/wallet");
      // }, 3000);
    });
    //props.onCloseAccountModal(false);
  };

  const closeVerifyOTPModal = (isVerifyOTPCompleted) => {
    toggleVerifyOTPModel(isVerifyOTPCompleted);
    props.onCloseAccountModal(!isVerifyOTPCompleted);
    confirmDetail();
    // submitWithdrawRequest();
  };
  const submitFormWithOtp = (otpValue) => {

    submitWithdrawRequest(otpValue);
  }
  if (selectedBankDetail) {
    selectedBankDetail.errors = {
      amount: props.balance >= 100 ? "valid" : "invalid",
      account_name: selectedBankDetail.account_name.length > 0 ? "valid" : "invalid",
      account_number: selectedBankDetail.account_number.match(/^\d{9,18}$/) ? "valid" : "invalid",
      // ifsc: selectedBankDetail.ifsc.match(/^[A-Za-z]{4}\d{7}$/)
      //   ? "valid"
      //   : "invalid",
      ifsc: selectedBankDetail.ifsc.length > 0 ? "valid" : "invalid",
      bank: selectedBankDetail.bank.length > 0 ? "valid" : "invalid",
      branch: selectedBankDetail.branch.length > 0 ? "valid" : "invalid",
      state: selectedBankDetail.state.length > 0 ? "valid" : "invalid",
      city: selectedBankDetail.city.length > 0 ? "valid" : "invalid",
      pan: selectedBankDetail.pan.match(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/) ? "valid" : "invalid",
    };
  }
  const [formState, dispatch] = useReducer(
    reducer,
    props.selectedBank || initialState
  );
  const onChange = (e) => {
    dispatch({ field: e.target.name, value: e.target.value });
    //checkFormValidity();
  };

  // const verifyOTP = () => {
  //   if (selectedBankDetail || isIfscVerified) {
  //     setWithDrawState("VERIFY_OTP");
  //   } else {
  //     showToastMessage(
  //       "info",
  //       <FormattedMessage
  //         id="wallet.pls-validate-ifsc"
  //         defaultMessage="Please Validate Your IFSC Code"
  //       />
  //     );
  //   }
  // };

  const confirmDetail = () => {
    if (selectedBankDetail || isIfscVerified) {
      setWithDrawState("CONFIRMED");
    } else {
      showToastMessage(
        "info",
        <FormattedMessage
          id="wallet.pls-validate-ifsc"
          defaultMessage="Please Validate Your IFSC Code"
        />
      );
    }
  };

  const {
    // amount,
    account_name,
    account_number,
    ifsc,
    bank,
    branch,
    state,
    city,
    pan,
    errors,
  } = formState;

  const validateIfsc = () => {
    setIFSCVerifying(true);
    walletClientX
      .post(`/v1/validate-ifsc`, { ifsc: ifsc })
      .then((response) => {
        const { bank, branch, city, state } = response.data.data;
        dispatch({ field: "bank", value: bank });
        dispatch({ field: "branch", value: branch });
        dispatch({ field: "city", value: city });
        dispatch({ field: "state", value: state });
        setIFSCVerifying(false);
        verifyIfsc(true);
      })
      .catch((error) => {
        verifyIfsc(false);
        setIFSCVerifying(false);
      });
  };

  const submitWithdrawRequest = (otpValue) => {
    setSubmitting(true);
    const form = {};
    form.amount = props.balance;
    form.otp = otpValue;
    if (props.selectedBank) {
      form.bank_account_id = props.selectedBank.id;
    } else {
      form.bank_account_name = account_name;
      form.bank_account_number = account_number;
      form.bank_ifsc = ifsc;
      form.bank_name = bank;
      form.bank_branch = branch;
      form.bank_state = state;
      form.bank_city = city;
      form.pan = pan.toUpperCase();
    }
    walletClientX.post(`/v1/withdraw`, form).then((response) => {
      setSubmitting(false);
      setWithDrawState("COMPLETE");
      // setTimeout(() => {
      //   history.push("/wallet");
      // }, 3000);
    });
  };
  const onTransferInitiated = () => {
    history.push("/wallet");
  }
  const changeIfsc = () => {
    verifyIfsc(false);
  };
  return (
    <Modal show onHide={props.onCloseAccountModal}>
      <Modal.Header closeButton className="border-0">
        {withDrawState !== "COMPLETE" && (
          <Modal.Title>
            <strong>
              {withDrawState === "CONFIRMED" ? (
                <FormattedMessage
                  id="wallet.confirm-account"
                  defaultMessage="Confirm Details"
                />
              ) : selectedBankDetail ? (
                <FormattedMessage
                  id="wallet.saved-bank-account"
                  defaultMessage="Saved Bank Account"
                />
              ) : (
                <FormattedMessage
                  id="wallet.new-bank-account"
                  defaultMessage="New Bank Account"
                />
              )}
            </strong>
          </Modal.Title>
        )}
      </Modal.Header>

      <Modal.Body className="text-center font-proxima-nova">
        {withDrawState === "NOT_CONFIRMED" && (
          <form>
            <div className="form-group text-left">
              <label htmlFor="amount" className="text-left">
                <FormattedMessage
                  id="wallet.withdraw-amount"
                  defaultMessage="Withdraw Amount"
                />{" "}
                {/* (&#8377;) */}
              </label>
              <input
                type="number"
                id="amount"
                name="amount"
                value={props.balance}
                disabled
                onChange={onChange}
                className={classNames(
                  "form-control",
                  "pay-now-input",
                  "border-bottom",
                  {
                    "is-valid": errors.amount === "valid",
                    "is-invalid": errors.amount === "invalid",
                  }
                )}
              ></input>
              {errors.amount === "invalid" && (
                <span className="error-text">
                  <FormattedMessage
                    id="wallet.min-amt"
                    defaultMessage="Minimum Withdraw Amount must be Rs. 501"
                  />
                </span>
              )}
            </div>
            {
              !selectedBankDetail &&
              <h5 className="text-left mt-4">
                <strong>
                  {" "}
                  <FormattedMessage
                    id="wallet.fill-details"
                    defaultMessage="Please fill in the details"
                  />{" "}
                </strong>
              </h5>
            }
            <div className="form-group text-left mt-3">
              <label htmlFor="pan" className="text-left">
                <FormattedMessage id="wallet.pan" defaultMessage="PAN" />
              </label>
              <input
                type="text"
                id="pan"
                name="pan"
                value={pan}
                onChange={onChange}
                disabled={selectedBankDetail}
                className={classNames(
                  "form-control",
                  "pay-now-input",
                  "border-bottom",

                  {
                    "is-valid": errors.pan === "valid",
                    "is-invalid": errors.pan === "invalid",
                  }

                )}
              ></input>
            </div>
            <div className="form-group text-left mt-3">
              <label htmlFor="acct-holder-name" className="text-left">
                <FormattedMessage
                  id="wallet.name-account"
                  defaultMessage="Account Holder's Name"
                />
              </label>
              <input
                type="text"
                id="acct-holder-name"
                name="account_name"
                value={account_name}
                onChange={onChange}
                disabled={selectedBankDetail}
                className={classNames(
                  "form-control",
                  "pay-now-input",
                  "border-bottom",
                  {
                    "is-valid": errors.account_name === "valid",
                    "is-invalid": errors.account_name === "invalid",
                  }
                )}
              ></input>
            </div>
            <div className="form-group text-left mt-3">
              <label htmlFor="acct-amount" className="text-left">
                <FormattedMessage
                  id="wallet.number-account"
                  defaultMessage="Account Number"
                />
              </label>
              <input
                type="number"
                id="acct-amount"
                name="account_number"
                value={account_number}
                onChange={onChange}
                disabled={selectedBankDetail}
                className={classNames(
                  "form-control",
                  "pay-now-input",
                  "border-bottom",
                  {
                    "is-valid": errors.account_number === "valid",
                    "is-invalid": errors.account_number === "invalid",
                  }
                )}
              ></input>
            </div>
            <div className="form-group text-left mt-3 position-relative">
              <label htmlFor="ifsc" className="text-left">
                <FormattedMessage id="wallet.ifsc" defaultMessage="IFSC" />
              </label>
              <input
                type="text"
                id="ifsc"
                name="ifsc"
                value={ifsc}
                onChange={onChange}
                disabled={isIfscVerified}
                className={classNames(
                  "form-control",
                  "pay-now-input",
                  "border-bottom",
                  {
                    "is-valid": errors.ifsc === "valid",
                    "is-invalid": errors.ifsc === "invalid",
                  }
                )}
              ></input>
              {isVerifyingIFSC ? (
                <InlineLoader />
              ) : selectedBankDetail ? (
                <></>
              ) : isIfscVerified ?
                (
                  <button
                    type="button"
                    className="btn btn-white winds-link-color chg-btn"
                    onClick={changeIfsc}
                  > Change
                  </button>
                ) :
                (
                  <button
                    type="button"
                    className="btn btn-white winds-link-color chg-btn"
                    onClick={validateIfsc}
                  >
                    <FormattedMessage
                      id="wallet.validate-ifsc"
                      defaultMessage="Validate"
                    />
                  </button>
                )
              }
            </div>
            <div className="form-group text-left mt-3 position-relative">
              <label htmlFor="bank-name" className="text-left">
                <FormattedMessage
                  id="wallet.bankname-account"
                  defaultMessage="Bank Name"
                />
              </label>
              <input
                type="text"
                id="bank-name"
                name="bank"
                value={bank}
                onChange={onChange}
                disabled={isIfscVerified}
                className={classNames(
                  "form-control",
                  "pay-now-input",
                  "border-bottom",
                  {
                    "is-valid": errors.bank === "valid",
                    "is-invalid": errors.bank === "invalid",
                  }
                )}
              ></input>
            </div>
            <div className="form-group text-left mt-3 position-relative">
              <label htmlFor="acct-branch" className="text-left">
                <FormattedMessage
                  id="wallet.branch-account"
                  defaultMessage="Branch"
                />
              </label>
              <input
                type="text"
                id="acct-branch"
                name="branch"
                value={branch}
                onChange={onChange}
                disabled={isIfscVerified}
                className={classNames(
                  "form-control",
                  "pay-now-input",
                  "border-bottom",
                  {
                    "is-valid": errors.branch === "valid",
                    "is-invalid": errors.branch === "invalid",
                  }
                )}
              ></input>
            </div>
            <div className="form-group text-left mt-3 position-relative">
              <label htmlFor="acct-city" className="text-left">
                <FormattedMessage
                  id="wallet.city-account"
                  defaultMessage="City"
                />
              </label>
              <input
                type="text"
                id="acct-city"
                name="city"
                value={city}
                onChange={onChange}
                disabled={isIfscVerified}
                className={classNames(
                  "form-control",
                  "pay-now-input",
                  "border-bottom",
                  {
                    "is-valid": errors.city === "valid",
                    "is-invalid": errors.city === "invalid",
                  }
                )}
              ></input>
            </div>
            <div className="form-group text-left mt-3 position-relative">
              <label htmlFor="acct-state" className="text-left">
                <FormattedMessage
                  id="wallet.state-account"
                  defaultMessage="State"
                />
              </label>
              <input
                type="text"
                id="acct-state"
                name="state"
                value={state}
                onChange={onChange}
                disabled={isIfscVerified}
                className={classNames(
                  "form-control",
                  "pay-now-input",
                  "border-bottom",
                  {
                    "is-valid": errors.state === "valid",
                    "is-invalid": errors.state === "invalid",
                  }
                )}
              ></input>
            </div>
            <button
              type="button"
              className="btn btn-primary home-btn mt-4"
              onClick={confirmDetail}
              // disabled={
              //   errors.amount !== "valid" ||
              //   errors.account_name !== "valid" ||
              //   errors.account_number !== "valid" ||
              //   errors.ifsc !== "valid" ||
              //   errors.bank !== "valid" ||
              //   errors.branch !== "valid" ||
              //   errors.state !== "valid" ||
              //   errors.city !== "valid" ||
              //   errors.state !== "valid" ||
              //   errors.pan !== "valid"
              // }
              disabled={
                account_name === "" ||
                account_number === "" ||
                ifsc === "" ||
                bank === "" ||
                branch === "" ||
                state === "" ||
                city === "" ||
                pan === ""
              }
            >
              <FormattedMessage
                id="general.continue"
                defaultMessage="Continue"
              />
            </button>
          </form>
        )}
        {withDrawState === "CONFIRMED" && (
          <React.Fragment>
            <div className="confiramtion-box mt-5 text-left">
              <div className="row border-bottom mt-4">
                <div className="col-6 text-left">
                  <p>
                    <FormattedMessage
                      id="wallet.name-account"
                      defaultMessage="Account Holder's Name"
                    />
                  </p>
                </div>
                <div className="col-6 text-right">
                  <p>{account_name}</p>
                </div>
              </div>
              <div className="row border-bottom mt-4">
                <div className="col-6 text-left">
                  <p>
                    <FormattedMessage
                      id="wallet.number-account"
                      defaultMessage="Account Number"
                    />
                  </p>
                </div>
                <div className="col-6 text-right">
                  <p>{account_number}</p>
                </div>
              </div>
              <div className="row border-bottom mt-4">
                <div className="col-6 text-left">
                  <p>
                    <FormattedMessage id="wallet.ifsc" defaultMessage="IFSC" />
                  </p>
                </div>
                <div className="col-6 text-right">
                  <p>{ifsc}</p>
                </div>
              </div>
              <div className="row border-bottom mt-4">
                <div className="col-6 text-left">
                  <p>
                    <FormattedMessage
                      id="wallet.bankname-account"
                      defaultMessage="Bank Name"
                    />
                  </p>
                </div>
                <div className="col-6 text-right">
                  <p>{bank}</p>
                </div>
              </div>
              <div className="row border-bottom mt-4">
                <div className="col-6 text-left">
                  <p>
                    <FormattedMessage
                      id="wallet.branch-account"
                      defaultMessage="Bank Branch"
                    />
                  </p>
                </div>
                <div className="col-6 text-right">
                  <p>{branch}</p>
                </div>
              </div>
              <div className="row border-bottom mt-4">
                <div className="col-6 text-left">
                  <p>
                    <FormattedMessage
                      id="wallet.state-account"
                      defaultMessage="State"
                    />
                  </p>
                </div>
                <div className="col-6 text-right">
                  <p>{state}</p>
                </div>
              </div>
              <div className="row border-bottom mt-4">
                <div className="col-6 text-left">
                  <p>
                    <FormattedMessage
                      id="wallet.city-account"
                      defaultMessage="City"
                    />
                  </p>
                </div>
                <div className="col-6 text-right">
                  <p>{city}</p>
                </div>
              </div>
              <div className="row border-bottom mt-4">
                <div className="col-6 text-left">
                  <p>
                    <FormattedMessage id="wallet.pan" defaultMessage="PAN" />
                  </p>
                </div>
                <div className="col-6 text-right">
                  <p>{pan}</p>
                </div>
              </div>
              <div className="row border-bottom mt-4">
                <div className="col-6 text-left">
                  <p>
                    <FormattedMessage
                      id="wallet.amount"
                      defaultMessage="Amount"
                    />{" "}
                  </p>
                </div>
                <div className="col-6 text-right">
                  <p>{props.balance}</p>
                </div>
              </div>
            </div>
            {isSubmitting ? (
              <InlineLoader />
            ) : (
              <div className="row">
                <div className="col-5">
                  <button
                    type="button"
                    className="btn btn-primary home-btn mt-4"
                    onClick={() => {
                      setWithDrawState("NOT_CONFIRMED");
                    }}
                  >
                    <FormattedMessage id="general.back" defaultMessage="Back" />
                  </button>
                </div>
                <div className="col-7">
                  <button
                    type="button"
                    className="btn btn-primary home-btn mt-4"
                    onClick={() => {
                      openVerifyOTP();
                    }}
                  //onClick={submitWithdrawRequest}
                  >
                    <FormattedMessage
                      id="general.otp"
                      defaultMessage="Verify OTP"
                    />
                  </button>
                </div>
              </div>
            )}
          </React.Fragment>
        )}


        {withDrawState === "VERIFY_OTP" && isVerifyOTPModalOpen && (
          <div>
            <VerifyOTP
              onCloseVerifyOTPModal={(isVerifyOTPCompleted) =>
                closeVerifyOTPModal(isVerifyOTPCompleted)
              }
              onVerifyOTP={(otp) =>
                submitFormWithOtp(otp)
              }
            />
          </div>
        )}

        {withDrawState === "COMPLETE" && (
          <div>
            <img src={withDrawSuccessVector} className="w-100" alt="withdraw" title="withdraw" />
            <p className="mt-4">
              {" "}
              <FormattedMessage
                id="wallet.transfer-initiated"
                defaultMessage="Your withdrawal request has been successfully initiated!"
              />{" "}
            </p>
            <button className="btn btn-primary home-btn" onClick={onTransferInitiated}>
              <FormattedMessage
                id="general.ok"
                defaultMessage="OK"
              />
            </button>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AddAccount;
