import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import billPayClientX from "../../../utils/api/billPayClientX";
import { connect } from "react-redux";
import { setRepeatData } from "../actions/order";
import classNames from "classnames";
import bpAssured from "../../../assets/billpay/ic_bp_assured.svg"
import ic_download from "../../../assets/billpay/ic_download.svg";
import ic_invoice from "../../../assets/billpay/ic_invoice.svg";
import supportImg from "../../../assets/billpay/ic_support.svg";
import wrpCoin from "../../../assets/billpay/ic_wrp_coin.svg";
import arrowBlue from "../../../assets/billpay/ic_arrow_blue.svg";
import ic_rupee_symbol from "../../../assets/billpay/ic_rupee_symbol.svg";
import Dots from "../../../assets/billpay/3-dots.png"
import { Modal } from "react-bootstrap";
import InlineLoader from "../../../components/InlineLoader";
import { toast } from "react-toastify";
import { OrderStatusLabel } from "../../../utils/helper";
import { claimNow, claimed } from "../../../components/RewardsClaim";
import Lottie from "lottie-react";
import SuccessBg from '../../../assets/voucher/ic_confetti.json'
import bharatPay from '../../../assets/billpay/B Assured Logo_SVG.svg'
class OrderDetail extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      order: null,
      isLoading: false,
      canDownloadReceipt: false,
      showDropdown: false,
      bgColor: "",
      enableTicketStatusModal: false,
      ticketStatusLoading: false,
      complaint_id: null,
      ticketStatusData: null,
      triggeredCloseTicket: false,
      closeTicketLoading: false,
      receiptDownloading: false,
      show: false,
      displayedFirstTime: false
    };
    this.loadRef = React.createRef();

  }

  downloadReceipt = () => {
    this.setState({ receiptDownloading: true })
    const orderID = this.state.order.id;
    billPayClientX.get(`/v1/order/${orderID}/receipt`).then((response) => {
      window.open(response.data.data.receipt);
      this.setState({ receiptDownloading: false })
    });
  };

  repeat = () => {
    const item = this.state.order;
    billPayClientX.get(`v1/services`).then((response) => {
      const data = response.data.data;
      data.map(service => {
        if (service.name === item.service_name) {
          this.props.repeat(item);
          this.initiateOrder(`v2/repeat-order`, { order_id: item?.id });
        }
        return null
      })
    });
  };

  initiateOrder = (url, form) => {
    billPayClientX
      .post(url, form)
      .then((response) => {
        let data = response.data.data
        this.props.history.push(`/bill-pay/${data?.service_slug}/${data?.provider_slug}`)
      })
      .catch((error) => {
      });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this.props.repeat(null);
    this._isMounted = true;

    const {
      match: { params },
    } = this.props;

    billPayClientX.get(`v1/orders/${params.id}`).then((response) => {
      if (this._isMounted) {
        let data = response.data.data;
        this.getComplaintID(data)
        this.OrderStatusLabel(data)
        this.setState({
          order: data,
        });
        if (3 === parseInt(data.status)) {
          this.setState({
            canDownloadReceipt: true,
          });
        }
        if (
          2 === parseInt(data.status) ||
          4 === parseInt(data.status) ||
          5 === parseInt(data.status) ||
          7 === parseInt(data.status)
        ) {
          this.setState(
            {
              isLoading: true,
            },
            () => {
              setTimeout(() => {
                billPayClientX.get(`v1/orders/${params.id}`).then((response) => {
                  let data = response.data.data;
                  this.setState({
                    order: data,
                    isLoading: false,
                  });
                });
              }, 30000);
            }
          );
        }
      }

      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            this.setState({ show: true })
            setTimeout(() => {
              this.setState({ displayedFirstTime: true })
            }, 100);
          }
          else {
            this.setState({ show: false })
          }
        });
      });
      this.observer?.observe(this.loadRef.current);

    });
  }

  viewInvoice = (url) => {
    window.open(url);
  };

  handleClick = () => {
    this.setState({
      showDropdown: !this.state.showDropdown,
    });
  };

  OrderStatusLabel = (order) => {
    if (order.fulfilled || order.status_code === 6)
      this.setState({ bgColor: "#11CE86" })
    else if (!order.fulfilled && order.payment_failed)
      this.setState({ bgColor: "#ED2B2A" })
    else
      this.setState({ bgColor: "#ffc107" })
  }

  OrderMessages = (order) => {
    switch (order.status) {
      case 3:
        return (
          <div className="list-group-item list-group-item-action d-flex justify-content-center" style={{ background: "linear-gradient(90deg, #FFF5F4 0%, #FFE8E6 100%)" }}>
            <img src={wrpCoin} alt="wrp icon" title="wrp icon" />
            <p className="mb-0 ml-2">
              <strong>Congrats! You have earned {order.wrp} WINDS Coins for this transaction!</strong>
            </p>
          </div>
        )
      case 0:
        return (
          <div className="list-group-item list-group-item-action d-flex justify-content-center" style={{ background: "linear-gradient(90deg, #FFF5F4 0%, #FFE8E6 100%)" }}>
            <img src={wrpCoin} alt="wrp coin" title="wrp icon" />
            <p className="mb-0 ml-2">
              <strong>Great! You will soon earn {order.wrp} WINDS Coins for this transaction!</strong>
            </p>
          </div>
        )
      case 5:
        return (
          <div className="list-group-item list-group-item-action d-flex justify-content-center" style={{ background: "linear-gradient(90deg, #FFF5F4 0%, #FFE8E6 100%)" }}>
            <p className="mb-0 ml-2">
              <strong>Your refund will be credited in your original payment source within 3 to 5 working days</strong>
            </p>
          </div>
        )
      case 1 || 4:
        if (order.statusText === "Fulfillment Failed")
          return (
            <div className="list-group-item list-group-item-action d-flex justify-content-center" style={{ background: "linear-gradient(90deg, #FFF5F4 0%, #FFE8E6 100%)" }}>
              <p className="mb-0 ml-2">
                <strong>Any amount deducted will be refunded!</strong>
              </p>
            </div>
          )
        break;
      default:
        return (
          <></>
        )
    }
  }

  handleClose = () => this.setState({ enableTicketStatusModal: false });
  handleShow = () => this.setState({ enableTicketStatusModal: true });

  getComplaintID = (order) => {
    order?.complaint_option &&
      // eslint-disable-next-line array-callback-return
      order.complaint_option.map(item => {
        if (item.key === "show_ticket_status" && item?.complaint_id)
          this.setState({ complaint_id: item.complaint_id })
      })
  }

  ticketStatusLable = () => {
    let ticketStatus = this.state.ticketStatusData?.status
    if (parseInt(ticketStatus) === 0)
      return (
        <span className="px-3 py-2 text-white" style={{ background: "#FF9601", borderRadius: "5px" }}>OPENED</span>
      )
    else if (parseInt(ticketStatus) === 1)
      return (
        <span className="px-3 py-2 text-white" style={{ background: "#11CE86", borderRadius: "5px" }}>ASSIGNED</span>
      )
    else if (parseInt(ticketStatus) === 2)
      return (
        <span className="px-3 py-2 text-white" style={{ background: "#7D70FF", borderRadius: "5px" }}>CLOSED</span>
      )
  }

  showTicketStatus = () => {
    this.setState({ ticketStatusLoading: true })
    this.handleShow()
    billPayClientX.post(`v3/complaint/status/check`, {
      complain_id: this.state.complaint_id,
    }).then((response) => {
      let data = response.data.data
      this.setState({ ticketStatusData: data, ticketStatusLoading: false })
    }).catch((error) => {
      this.setState({ ticketStatusLoading: false })
    })
  }


  triggerCloseTicket = () => {
    this.setState({ triggeredCloseTicket: true })
  }

  closeTicket = () => {
    this.setState({ closeTicketLoading: true })
    this.handleShow()
    billPayClientX.post(`v3/complaint/close_ticket`, {
      order_id: this.state.order.id,
    }).then((response) => {
      this.handleClose()
      toast.success(response.data.data.message)
      this.setState({ closeTicketLoading: false, triggeredCloseTicket: false })
    }).catch((error) => {
      this.handleClose()
      toast.error("Something went wrong. Please try again later")
      this.setState({ closeTicketLoading: false, triggeredCloseTicket: false })

    })
  }

  showTicketStatusModal = () => {
    return (
      <Modal
        className="no-header-modal"
        show={this.state.enableTicketStatusModal}
        onHide={this.handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className="text-center mx-4 mb-4 mt-0">
          {this.state.triggeredCloseTicket ?
            <>
              <h5 className="font-weight-bold mb-4">Close Ticket</h5>
              Are you sure you want to<br />
              close the ticket?
              <div className="d-flex flex-row justify-content-between w-100 mt-4">
                <button variant="primary" onClick={this.closeTicket} className="animated-btn m-2">OK</button>
                <button variant="primary" onClick={() => this.setState({ triggeredCloseTicket: false })} className="non-animated-btn m-2">CANCEL</button>
              </div>
              <div className="d-flex flex-row justify-content-between w-100 mt-2">
              </div>
            </> :
            <>
              <h5 className="font-weight-bold mb-4">Ticket Status</h5>
              {
                !this.state.ticketStatusLoading && this.state.ticketStatusData ?
                  this.ticketStatusLable() :
                  <div><InlineLoader /></div>
              }
              <div className="d-flex flex-row justify-content-between w-100 mt-4">
                {
                  this.state.ticketStatusLoading ?
                    <button variant="primary" className="animated-btn btn-disabled m-2">Loading...</button> :
                    this.state.ticketStatusData && this.state.ticketStatusData?.complaint_status === "CLOSED" ?
                      <button variant="primary" onClick={this.handleClose} className="animated-btn m-2">OK</button> :
                      <button variant="primary" onClick={this.triggerCloseTicket} className="animated-btn m-2">CLOSE TICKET</button>
                }
              </div>
            </>
          }
        </Modal.Body>
      </Modal>
    )
  }

  Shimmer = (props) => {
    return (
      <div className="container py-3 py-md-5">
        <div className="row">
          <div className="col-12 col-md-6 offset-md-3">
            <div className="box shine box-voucher-detail-logo"></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { order, canDownloadReceipt } = this.state;
    return (
      order === null ?
        <this.Shimmer />
        :
        null !== order && (
          <div className="container">
            <div className="row mt-3 mb-3 mt-md-4 mb-md-4 align-items-center">
              <div className="col-12 col-md-8 mx-auto">
                <div className="row d-flex justify-content-between">
                  <div className="col-8">
                    <small>
                      <Link to="/">
                        <FormattedMessage id="breadcrumbs.home" defaultMessage="Home" />
                      </Link>
                    </small>{" "}
                    /{" "}
                    <small>
                      <Link to="/orders">
                        <FormattedMessage id="navbar.orders" />
                      </Link>
                    </small>{" "}
                    /{" "}
                    <small>
                      <strong>
                        <FormattedMessage id="orders.order" />
                      </strong>
                    </small>
                  </div>
                  {
                    order?.complaint_option &&
                    <div className="col-4">
                      <li className="nav-item text-right cursor-pointer" onClick={this.handleClick} style={{ listStyle: "none" }}>
                        <img src={Dots} alt="3 dots" title="3 dots" width="30px" style={{ rotate: "90deg" }} />
                        <div
                          className={classNames("dropdown-menu", "dropdown-menu-right", {
                            show: this.state.showDropdown,
                          })}
                          onMouseLeave={() => {
                            this.setState({ showDropdown: false });
                          }}
                        >
                          {
                            // eslint-disable-next-line array-callback-return
                            order?.complaint_option && order?.complaint_option?.map((item, index) => {
                              switch (item.key) {
                                case "get_help":
                                  return (
                                    <Link className="dropdown-item dropdown-icons" to="/contact-us">
                                      {item.name}
                                    </Link>
                                  )
                                case "raise_complain":
                                  return (
                                    <Link className="dropdown-item dropdown-icons" to={`/bill-pay/orders/${order?.id}/rise-complaint`}>
                                      {item.name}
                                    </Link>
                                  )
                                case "show_ticket_status":
                                  return (
                                    <>
                                      <div onClick={this.showTicketStatus} className="dropdown-item dropdown-icons">
                                        {item.name}
                                      </div>
                                      <this.showTicketStatusModal />
                                    </>
                                  )
                                default:
                                  break;
                              }
                            })
                          }
                        </div>
                      </li>
                    </div>
                  }
                </div>
              </div>
            </div>

            <div className="row mb-3 mb-md-5">
              <div className="col-12 col-md-8 mx-auto">
                <div className="list-group">
                  <div className="list-group-item list-group-item-action text-center" style={{ background: this.state.bgColor }}>
                    <p className="mb-0 text-white">
                      <PaymentStatusLabel order={order} />
                    </p>
                  </div>
                  <div className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                    <p className="d-flex align-items-center mb-0">
                      <span className="billpay-order-img-box">
                        <img src={order?.web_image_url} alt={order.service_name} title={order.service_name} width="100%" />
                      </span>
                      <strong>{order.service_name}</strong>
                    </p>
                    <Link to="/contact-us"><img src={supportImg} alt="support img" title="support img" /></Link>
                  </div>
                  <div className="list-group-item list-group-item-action">
                    <p className="d-inline mb-0">
                      Operator/ Biller{" "}-{" "}
                      <strong>{order.provider_name}</strong>
                    </p>
                  </div>
                  <div className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                    <div>
                      <p className="mb-1">Amount</p>
                      <h5 className="d-flex align-items-center mb-0">
                        <img src={ic_rupee_symbol} alt="rupee symbol" title="rupee symbol" width="100%" className="mr-2" />
                        <strong>{order.amount?.toFixed(2)}</strong>
                      </h5>
                    </div>
                    {
                      order.can_repeat_order &&
                      <span className="font-weight-bold d-flex align-items-center cursor-pointer" style={{ color: "#6372ff" }} onClick={this.repeat}>REPEAT
                        <img src={arrowBlue} alt="arrow" title="arrow" width="20px" className="ml-2" />
                      </span>
                    }
                  </div>
                  <div className="list-group-item list-group-item-action">
                    <p className="d-inline mb-0">
                      <FormattedMessage id="order.number" />{" "}-{" "}
                      <strong>{order.number}</strong>
                    </p>
                  </div>
                  <div className="list-group-item list-group-item-action">
                    <div className="row align-items-center">
                      <div className="col-12 col-md-6">
                        <p className="mb-0">Order Status</p>
                        <OrderStatusLabel
                          order={order}
                          {...this.state}
                        />
                      </div>
                      <div className="col-12 col-md-6 text-md-right">
                        <p className="mb-0">
                          <FormattedMessage id="orders.your-order-number-is" />{" "}
                          <strong>{order.order_number}</strong>
                        </p>
                        <p className="mb-0">{order.created_at}</p>
                      </div>
                    </div>
                  </div>
                  {this.OrderMessages(order)}
                </div>

                <div className="display-btns-wrapper mt-2">
                  {/* add enlarge-btn to increase width */}
                  <div
                    className={classNames("btn-wrapper", {
                      "enlarge-btn": !order.invoice_url,
                      "disable-btn": !canDownloadReceipt,
                    })}
                  >
                    {canDownloadReceipt && (
                      <button
                        className={"btn mt-3 billpay-order-btn " + (order.invoice_url ? 'w-100' : 'btn-width-50 mx-auto')}
                        onClick={this.downloadReceipt}
                        disabled={this.state.receiptDownloading}
                      >
                        <img src={ic_download} alt="download" title="download" className="mr-2" width="15px" />
                        {this.state.receiptDownloading ?
                          "Downloading" :
                          <FormattedMessage
                            id="general.download-receipt"
                            defaultMessage="Download Receipt"
                          />
                        }
                      </button>
                    )}
                  </div>
                  <div
                    className={classNames("btn-wrapper", {
                      "enlarge-btn": !canDownloadReceipt,
                      "disable-btn": !order.invoice_url,
                    })}
                  >
                    {order.invoice_url && (
                      <button
                        className="btn mt-3 billpay-order-btn w-100"
                        onClick={() =>
                          this.viewInvoice(order.invoice_url)
                        }
                      >
                        <img src={ic_invoice} alt="invoice" title="invoice" className="mr-2" width="15px" />
                        <FormattedMessage
                          id="order.view-invoice"
                          defaultMessage="View Invoice"
                        />
                      </button>
                    )}
                  </div>
                </div>
                <div className="row mt-4" ref={this.loadRef}>
                  <div className="col-md-12 mx-auto">
                    {order.won_reward === false ?
                      null :
                      order.rewards_cliamed === false ?
                        <div className="">
                          {claimNow(order)}
                          <Lottie className="celebration-lottie" animationData={SuccessBg} loop={!this.state.displayedFirstTime && this.state.show} />
                        </div>
                        : claimed(order)
                    }
                  </div>
                </div>

                {
                  (this.state?.order?.biller_response && this.state?.order?.biller_response.length > 0) ?
                    (
                      <div className="position-relative p-4 pt-5 mt-5 mb-4" style={{ background: "#F4F4FF", borderRadius: "15px" }}>
                        <img src={bharatPay} className="position-absolute mx-auto" alt="Assured" title="Assured" height="75px" style={{ left: 0, right: 0, top: "-30px" }} />
                        {this.state?.order?.biller_response.map((item, index) => {
                          return (
                            <>
                              <div className="billpay-data position-relative text-center mb-3">
                                <p className="mb-1">{item.name}</p>
                                <p className="font-weight-bold">{item.value}</p>
                              </div>
                            </>
                          )
                        })}
                      </div>
                    ) :
                    (
                      this.state.order.status === 3 &&
                      <div className="d-flex justify-content-between align-items-center mt-3 mb-4">
                        <hr className="w-100"></hr>
                        <img src={bpAssured} alt="Assured" title="Assured" height="60px" />
                        <hr className="w-100"></hr>
                      </div>
                    )
                }

                <div className="p-4 mt-4 mb-3" style={{ background: "#F4F4FF", borderRadius: "15px" }}>
                  <div className="billpay-data position-relative">
                    <h5 className="mb-3 font-weight-bold">Order Summary</h5>
                    <hr className="w-100"></hr>
                    <div className="d-flex justify-content-between">
                      <p className="mb-1">Bill Amount</p>
                      <p className="mb-1 font-weight-bold">₹ {order.amount?.toFixed(2)}</p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p className="mb-1">Processing Charge</p>
                      <p className="mb-1 font-weight-bold">₹ {order.pg_charge?.toFixed(2)}</p>
                    </div>
                    <hr className="w-100"></hr>
                    <div className="d-flex justify-content-between">
                      <h5 className="font-weight-bold mb-1">Total</h5>
                      <h5 className="mb-1 font-weight-bold">₹ {order.paid_amount?.toFixed(2)}</h5>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        )
    );
  }
}





const mapDispatchToProps = (dispatch) => {
  return {
    repeat: (repeatData) => {
      dispatch(setRepeatData(repeatData));
    },
  };
};

export default connect(null, mapDispatchToProps)(OrderDetail);

function PaymentStatusLabel(props) {
  const status = props.order.status;
  switch (status) {
    case 0:
      return (
        <React.Fragment>
          <FormattedMessage
            id="order.payment-of-rs"
            defaultMessage="Payment of ₹"
          />{" "}
          <strong>{props.order.paid_amount?.toFixed(2)}</strong>{" "}
          <FormattedMessage
            id="order.payment-pending"
            defaultMessage="is Pending"
          />
        </React.Fragment>
      );
    case 1:
    case 4:
      return (
        <React.Fragment>
          <FormattedMessage
            id="order.payment-of-rs"
            defaultMessage="Payment of ₹"
          />{" "}
          <strong>{props.order.paid_amount?.toFixed(2)}</strong>{" "}
          <FormattedMessage
            id="order.payment-failed"
            defaultMessage="has failed"
          />
        </React.Fragment>
      );
    case 6:
    case 9:
      return (
        <React.Fragment>
          <FormattedMessage
            id="order.payment-of-rs"
            defaultMessage="Payment of ₹"
          />{" "}
          <strong>{props.order.paid_amount?.toFixed(2)}</strong>{" "}
          <FormattedMessage
            id="order.has-been-refunded"
            defaultMessage="has been refunded!"
          />
        </React.Fragment>
      );
    case 8:
    case 11:
    case 24:
      return (
        <React.Fragment>
          <FormattedMessage
            id="order.payment-of-rs"
            defaultMessage="Payment of ₹"
          />{" "}
          <strong>{props.order.paid_amount?.toFixed(2)}</strong>{" "}
          <FormattedMessage
            id="order.payment-failed"
            defaultMessage="is Failed"
          />
        </React.Fragment>
      );
    default:
      return (
        <React.Fragment>
          <FormattedMessage
            id="order.payment-of-rs"
            defaultMessage="Payment of ₹"
          />{" "}
          <strong>{props.order.paid_amount?.toFixed(2)}</strong>{" "}
          <FormattedMessage
            id="order.payment-success"
            defaultMessage="is Successful"
          />
        </React.Fragment>
      );
  }
}

