import wrpSummaryClientX from "../utils/api/wrpSummaryClientX";
import React from "react";
const useLoadMoreData = (slugUrl, limit) => {
  const [responseData, setResponse] = React.useState([]);
  const [isLoading, setLoading] = React.useState(false);
  const [isLastpage, setLagePage] = React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [isFetchingMore, setFetchingMore] = React.useState(false);
  const [wrp, setWrp] = React.useState("");
  React.useEffect(() => {
    if (pageNumber === 1) {
      setLoading(true);
    } else {
      setFetchingMore(true);
    }
    wrpSummaryClientX
      .get(`${slugUrl}?limit=${limit}&page=${pageNumber}`)
      .then((response) => {
        let isLast;
        setResponse(responseData.concat(response.data.data));
        let wrp;
        if (response.data && response.data.total_wrp) {
          wrp = response.data.total_wrp
        } else {
          if (response.data && response.data.consumed_wrp) {
            wrp = response.data.consumed_wrp;
          }
        }
        setWrp(wrp);
        setFetchingMore(false);
        isLast = response.data.links.next === null;
        setLagePage(isLast);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setFetchingMore(false);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);
  const incrementPageNo = () => {
    setPageNumber((pageNo) => pageNo + 1);
  };
  return {
    isLoading,
    responseData,
    pageNumber,
    isLastpage,
    incrementPageNo,
    wrp,
    isFetchingMore,
  };
};
export default useLoadMoreData;
