import React from "react";
import ic_downloads from "../../../assets/home/winds_journey/ic_download.png";
import ic_ratings from "../../../assets/home/winds_journey/ic_ratings.png";
import ic_happy_customers from "../../../assets/home/winds_journey/ic_happy_customers.png";
import ic_txn from "../../../assets/home/winds_journey/ic_txn.png";

export default function WindsJourney() {
    return (
        <div className="home-page section-space winds-journey-container">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-9 pb-3">
                        <h2 className="title">Our Journey <span className="font-slim">so far </span></h2>
                    </div>
                    {/* <div className="col-3 text-right">
                        <Link to={{ pathname: "/", state: { from: "/" } }}>
                            <span className="title-thick d-flex justify-content-end mobile-hide">
                                <FormattedMessage
                                    id="home.view-all"
                                    defaultMessage="View All"
                                    values={{ what: "react-intl" }}
                                />
                                <img className="view-all" src={viewAll} alt="view all" />
                            </span>
                        </Link>
                    </div> */}
                </div>
                <div className="row">
                    <div className="col-12">
                        {/* <h3 className="title-thick font-slim">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        </h3> */}
                    </div>
                </div>
                <div className="winds-journey-box row m-2 p-4">
                    <div className="col-12 col-sm-6 col-md-3 text-center gradient-margin-right d-flex flex-column justify-content-start align-items-center">
                        <img src={ic_downloads} alt="" className="img-fluid" />
                        <p className="title font-weight-normal text-white">44 Lakh +</p>
                        <p className="title-thick font-slim text-white gradient-margin-bottom pb-3"> App Downloads</p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-3 text-center gradient-margin-right2 d-flex flex-column justify-content-start align-items-center">
                        <img src={ic_happy_customers} alt="" className="img-fluid"/>
                        <p className="title font-weight-normal text-white">38 Lakh +</p>
                        <p className="title-thick font-slim text-white gradient-margin-bottom pb-3 ">Happy Customers</p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-3 text-center gradient-margin-right d-flex flex-column justify-content-start align-items-center">
                        <img src={ic_ratings} alt="Google Rating" title="Google Rating" className="img-fluid"/>
                        <p className="title font-weight-normal text-white">4.5</p>
                        <p className="title-thick font-slim text-white pb-3">Google Rating Out of 5</p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-3 text-center gradient-margin-top d-flex flex-column justify-content-start align-items-center">
                        <img src={ic_txn} alt="" className="img-fluid"/>
                        <p className="title font-weight-normal text-white">64 Lakh +</p>
                        <p className="title-thick font-slim text-white pb-3">Total Transactions</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
