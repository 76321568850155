import React from 'react';
const SubForm=()=>{
    return(
        <>
        <section className="home-page section-space service-categories">
            <div className='container'>
            <div className='row'>
              <div className='col-12 col-sm-1 col-md-2 col-lg-2 col-xl-2 d-flex flex-column justify-content-start'></div>
              <div className='col-12 col-sm-10 col-md-8 col-lg-8 col-xl-8 d-flex flex-column justify-content-start '>
                <div className='row d-flex f-column'>
                    <div className='col-12'>
                        <div className='d-flex justify-content-center   '>
                            <img src={require('../../../assets/icon/CheckMark.png')} className='img-fluid h-25 w-25'></img>
                        </div>
                        <div className='d-flex justify-content-center'>
                        <h4 className='text-center'> Thank You for submitting the details, <br/>Our support team will contact you soon </h4>
                        </div>
                    </div>
                </div>
              </div>
              <div className='col-12 col-sm-1 col-md-2 col-lg-2 col-xl-2 d-flex flex-column justify-content-start'></div>
              </div>
            </div>
        </section>
        </>
    );
}
export default SubForm;