import { replaceAt } from "../../../utils/helper";
const INITIAL_STATE = {
  isFetchingPartners: false,
  isFetchingPartnerCategories: false,
  isFetchingNextPage: false,
  categories: [],
  selectedCategories: [],
  partners: [],
  nextPage: 2,
  distanceValue: [5],
  windsRating: [7, 6, 5, 4, 3, 2, 1],
  selectedWindsRatings: [],
  customerRatingValue: [1, 5],
  sort: "radius",
  isPartnerFilterModelOpen: false,
  isPartnerModelTransitionDone: true,
  partnerData: null,
  partnerCartData: null
};

function partnerReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "FETCHING_PARTNERS":
      return {
        ...state,
        isFetchingPartners: true,
        isFetchingNextPage: false,
        partners: [],
        nextPage: 2
      };
    case "FETCHING_PARTNER_CATEGORIES":
      return {
        ...state,
        isFetchingpartnerCategories: true
      };
    case "FETCHING_NEXT_PARTNER_PAGE":
      return {
        ...state,
        isFetchingNextPage: true
      };
    case "SET_PARTNERS":
      return {
        ...state,
        partners: action.partners,
        isFetchingPartners: false,
        nextPage: action.nextPage
      };
    case "ADD_PARTNERS":
      return {
        ...state,
        partners: state.partners.concat(action.partners),
        isFetchingNextPage: false,
        nextPage: action.nextPage
      };
    case "SET_PARTNER_CATEGORIES":
      return {
        ...state,
        categories: action.categories,
        isFetchingpartnerCategories: false
      };
    case "NEARBY_PARTNER_SORT_CHANGE":
      return {
        ...state,
        sort: action.value
      };
    case "SELECT_RATING_FOR_NEARBY_PARTNER":
      return {
        ...state,
        selectedWindsRatings: [...state.selectedWindsRatings, action.id]
      };
    case "UNSELECT_RATING_FOR_NEARBY_PARTNER":
      return {
        ...state,
        selectedWindsRatings: state.selectedWindsRatings.filter(item => {
          return item !== action.id;
        })
      };

    case "SELECT_CATEGORY_FOR_NEARBY_PARTNER":
      return {
        ...state,
        selectedCategories: [...state.selectedCategories, action.id]
      };
    case "UNSELECT_CATEGORY_FOR_NEARBY_PARTNER":
      return {
        ...state,
        selectedCategories: state.selectedCategories.filter(item => {
          return item !== action.id;
        })
      };
    case "RESET_CATEGORY":
      return {
        ...state,
        selectedCategories: [action.id]
      };
    case "PARTNER_FILTER_CLICK":
      return {
        ...state,
        isPartnerFilterModelOpen: !state.isPartnerFilterModelOpen
      };
    case "PARTNER_MODEL_TRANSITION_DONE":
      return {
        ...state,
        isPartnerModelTransitionDone: !state.isPartnerModelTransitionDone
      };

    case "RESET_PARTNER_FILTER":
      return {
        ...state,
        selectedCategories: [],
        distanceValue: replaceAt(state.distanceValue, 0, 5),
        customerRatingValue: [1, 5],
        sort: "radius"
      };

    case "SET_DISTANCE_STATE":
      return {
        ...state,
        distanceValue: replaceAt(
          state.distanceValue,
          0,
          action.distanceValue.value
        )
      };

    case "SET_RATING_STATE":
      return {
        ...state,
        customerRatingValue: action.rating.value
      };

    case "SET_PARTNER_LIST_AFTER_BOOKMARK_TOGGLE":
      return {
        ...state,
        partners: state.partners.map(p =>
          p.id === action.id ? { ...p, bookmarked: !p.bookmarked } : p
        )
      };
    case "CURRENT_PARTNER":
      return {
        ...state,
        partnerData: action.partner
      }
    case "PARTNER_CART":
      return {
        ...state,
        partnerCartData: action.partner
      }
    default:
      return state;
  }
}

export default partnerReducer;
