import React from "react";
import { useState } from "react";
import { Card, Button, Accordion, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import arrow from "../../../assets/img/about/arrow.png"
import PartnerMetaData from "../../../seo/PartnerMetaData";

function BusinessReadMore(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="site-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter text-center">
          <FormattedMessage id="page.nav-winds-partner" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul>
          <li><FormattedMessage id="partner.section4.read-more-desc1" /></li>
          <li><FormattedMessage id="partner.section4.read-more-desc2" /></li>
          <li><FormattedMessage id="partner.section4.read-more-desc3" /></li>
        </ul>
      </Modal.Body>
    </Modal>
  );
}
const Partner = () => {
  const [style, setStyle] = useState(false);
  const changeStyle = () => {
    style === false ? setStyle(true) : setStyle(false);
  };
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <PartnerMetaData />
      <section className="header-sec">
        <nav className="navbar navbar-expand-lg all">
          <ul className="navbar-nav site mr-auto mt-2 mt-lg-0">
            <li className="nav-item">
              <Link className="nav-link" to="/patrons"><FormattedMessage id="page.nav-winds-patrons" /></Link>
            </li>
            <li className="nav-item site-active">
              <Link className="nav-link" to="/partner"><h1 style={{fontSize:"inherit", marginBottom:"0"}}><FormattedMessage id="page.nav-winds-partner" /></h1></Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/digi-world"><FormattedMessage id="page.nav-winds-digi" /></Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about-us"><FormattedMessage id="page.nav-about-us" /></Link>
            </li>
            {/* <li className="nav-item">
              <Link className="nav-link" to="/luckydraw-patrons"><FormattedMessage id="page.nav-winds-luckydraw" /></Link>
            </li> */}
            {/* <li className="nav-item">
              <Link className="nav-link" to="/testimonials"><FormattedMessage id="page.nav-winds-testimonials" /></Link>
            </li> */}
          </ul>
        </nav>
      </section>
      <section className="about-sec-1 site-partner site-page site-top">
        <div className="container font-proxima-nova">
          <div className="row reverse-col">
            <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 d-flex flex-column justify-content-center">
              <img width="100%" src={require('../../../assets/img/site-partnerImage/ic_illustration_Partner.png')} alt="partner" title="partner" className="pr-md-5 pr-0 mt-md-0 mt-3" />
            </div>
            <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 d-flex flex-column justify-content-center">
              <h2 className="title-captain"><FormattedMessage id="partner.section1.title" /></h2>
              <p className="desc"><FormattedMessage id="partner.section1.desc" /></p>
              <span className="mt-4 mb-3 download-patron"><FormattedMessage id="partner.section1.subtitle" /></span>
              <div className="paystore">
                <a href="https://p.winds.to/app" target="blank">
                  <img className="icons" src={require('../../../assets/img/site-patrons/ic_google_pay.png')} alt="play store" title="play store" />
                </a>
                <a href="https://p.winds.to/app" target="blank">
                  <img className="icons" src={require('../../../assets/img/site-patrons/ic_app_store.png')} alt="app store" title="app store" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-sec-2 site-page">
        <div className="container font-proxima-nova">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <h2 className="title-captain"><FormattedMessage id="partner.section2.title" /></h2>
              <p className="desc"><FormattedMessage id="partner.section2.desc1" /></p>
              <p className="desc"><FormattedMessage id="partner.section2.desc2" /> </p>
              <p className="desc"><FormattedMessage id="partner.section2.desc3" /></p>
              <p className="desc"><FormattedMessage id="partner.section2.desc4" /></p>
              <p className="desc"><FormattedMessage id="partner.section2.desc5" /></p>
              {/* <Accordion defaultActiveKey="0">
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="1" onClick={changeStyle}>
                      <h3 className="mb-0 font-weight-bold"><FormattedMessage id="partner.section2.mini-monthly-draw" /></h3>
                      <img src={arrow} width="15" style={{ marginLeft: "5px", style }} alt="arrow" title="arrow" className={`${style ? "down-arrow" : "up-arrow"}`} />
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <p className="desc"><FormattedMessage id="partner.section2.mini-monthly-draw-desc" /></p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion> */}
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <img width="100%" src={require('../../../assets/img/site-partnerImage/ic_partner_shop.png')} alt="partner" title="partner" />
            </div>
          </div>
        </div>
      </section>
      <section className="about-sec-3redirect site-page">
        <div className="container font-proxima-nova">
          <div className="row reverse-col">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <img width="100%" src={require('../../../assets/img/site-partnerImage/ic_partner_referral.png')} alt="partner" title="partner" className="px-5 mt-md-0 mt-3" />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <h2 className="title-captain"><FormattedMessage id="partner.section3.title" /></h2>
              <p className="desc"><FormattedMessage id="partner.section3.desc1" /></p>
              {/* <p className="desc"><FormattedMessage id="partner.section3.desc2" /></p> */}
            </div>
          </div>
        </div>
      </section>
      <section className="about-sec-4 site-page">
        <div className="container font-proxima-nova">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <h2 className="title-captain"><FormattedMessage id="partner.section4.title" /></h2>
              <p className="desc" ><FormattedMessage id="partner.section4.desc" /></p>
              {/* <p className="sub-title" style={{ "fontWeight": "600", cursor: "pointer" }} onClick={() => setModalShow(true)}>
                <FormattedMessage id="partner.section4.read-more" />
                <img src={arrow} width="15" style={{ marginLeft: "5px" }} alt="arrow" title="arrow" />
              </p> */}
              <BusinessReadMore
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
              <h2 className="download-patron pt-2"><FormattedMessage id="partner.section4.subtitle" /></h2>
              <p className="desc"><FormattedMessage id="partner.section4.subtitle-desc" /></p>
              {/* <h3 className="download-patron pt-3" style={{ textDecoration: "underline" }}>
                <Link to="/partner-faq">
                  <FormattedMessage id="partner.faq" />
                </Link>
              </h3> */}
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <img width="100%" src={require('../../../assets/img/site-partnerImage/ic_partner_mockup.png')} alt="partner" title="partner" className="pr-md-5 pr-0 mt-md-0 mt-3" />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container py-3">
          <h2 className="title-captain">Frequently Asked Questions</h2>
          <Accordion defaultActiveKey="1">
            <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="1" >
                  <h3 className="mb-0 h5 font-weight-bold">Who Can Be A WINDS Partner?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <p className="desc">- Any well-established, reputable business in the market renowned for its goods or services.
                    - An approachable, easily accessible outlet or a business.
                    - A business that will adhere to the terms and conditions laid down by WINDS.
                    - A business willing to pay the WINDS Service Charge applicable to their respective categories on the transactions done through WINDS can become a WINDS Partner.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            {/* <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="2" >
                  <h3 className="mb-0 h5 font-weight-bold">What Does WSP Stand For?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <p className="desc">WSP stands for WINDS Service Points. For every 1 Rupee given to WINDS as a Service charge by the WINDS Partner, 1 WSP will be earned by them.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card> */}
            {/* <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="3" >
                  <h3 className="mb-0 h5 font-weight-bold">How Many WSPs Will A WINDS Partner Have To Generate To Participate In The Monthly Lucky Draw?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <p className="desc">Every 5000 WSP accumulated makes a coupon that entitles the Partner to participate in the Monthly Lucky Draw. If a partner pays ₹20,000 as a service charge, he earns 20,000 WSP and thus makes four coupons, all of which will be added to the draw.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card> */}
            <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="4" >
                  <h3 className="mb-0 h5 font-weight-bold">How Does Using The WINDS App Benefits A WINDS Partner?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="4">
                <Card.Body>
                  <p className="desc">The platform of WINDS is genuinely unique and beneficial for any shopkeeper. WINDS gives a massive opportunity for the shopkeepers to increase Customer traffic at their respective outlets by joining WINDS and earning referral commission, as a Partner. WINDS also provides its registered Partners with a digital platform with free listings, which increases their online visibility and thus increases the likelihood of more customers visiting their store. With WINDS, they can also put up their customized offers on the WINDS platform and increase their sales significantly by attracting Customers in their specific areas.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="5" >
                  <h3 className="mb-0 h5 font-weight-bold">If A WINDS Partner Refers Any Patron In The WINDS Ecosystem, Would That Benefit Them?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="5">
                <Card.Body>
                  <p className="desc">If any WINDS Partner does so, they will earn 10% of WINDS earnings whenever their referred WINDS Patron (Customer) shops on the WINDS App. This referral earning will apply to all shopping done through the WINDS app, including at other WINDS Partner Retail outlets, i.e., wherever the Patron has shopped. And the said Partner will get this benefit on each of the Patrons he refers to or introduces.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="6" >
                  <h3 className="mb-0 h5 font-weight-bold">Can The Same Person Be WINDS Patron/Partner/Senior Captain?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="6">
                <Card.Body>
                  <p className="desc">Yes. One person can perform all these roles.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            {/* <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="7" >
                  <h3 className="mb-0 h5 font-weight-bold">How Much Time Does It Take For The WSP Earned To Be Updated In The WINDS Partner App?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="7">
                <Card.Body>
                  <p className="desc">Any WSPs earned would be updated immediately.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card> */}
            <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="8" >
                  <h3 className="mb-0 h5 font-weight-bold">What Are The Responsibilities Of A WINDS Partner?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="8">
                <Card.Body>
                  <p className="desc">The primary responsibilities of a WINDS Partner would be:
                   <li>Agree to pay the Service Charge to WINDS on every transaction done through the platform.</li>
                   <li>Ensure quality service and products to all WINDS Patrons (The Customers).</li>
                   <li>Encourage them to use more of the WINDS App and create exciting offers and promotions.</li>
                   <li>Overall, they should be willing to adhere to the terms & conditions as laid down by WINDS. </li></p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="9" >
                  <h3 className="mb-0 h5 font-weight-bold">For How Long The WINDS Partner Agreement Be Valid? Also, How Can It Be Renewed? </h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="9">
                <Card.Body>
                  <p className="desc">WINDS sets no validity limit for this agreement. This will be for life, provided the terms and conditions are not breached in any way by the WINDS Partner. Please note that WINDS reserves the right to terminate the contract immediately.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            {/* <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="10" >
                  <h3 className="mb-0 h5 font-weight-bold">What Does The Partner Need To Do To Receive His Money If He Wins In The Monthly Lucky Draw?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="10">
                <Card.Body>
                  <p className="desc">Once the prize has been won, it will reflect in the wallet option in the Partner's App. They must enter a withdrawal request and share and validate their bank account details. After completing this process, the said amount will be transferred to their respective bank accounts.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card> */}
            <Card className="mb-3">
              <Card.Header className="cursor-pointer p-0 accordian-bg">
                <Accordion.Toggle as={Card.Header} variant="link" eventKey="11" >
                  <h3 className="mb-0 h5 font-weight-bold">How Will A Partner Pay Service Charge?</h3>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="11">
                <Card.Body>
                  <p className="desc">When a WINDS Patron makes an online payment through the "Pay Now" feature on WINDS App after shopping through a WINDS Partner, then the Service Charge due to WINDS, as per the Service Charge category of the Partner, will automatically be transferred to WINDS. At the same time, the balance payment shall be transferred to the Partner.</p><p> Example: If a Partner is registered in the 5% Service Charge category, then 5% of the payment made by the Patron will be transferred to WINDS, and the rest 95% will be shared with the Partner by the Payment Gateway.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
      </section>
    </>
  );
};
export default Partner;
