const INITIAL_STATE = {
  repeatData: null,
  billPayForm: {},
  billPayPayload: {},
  billpayAPIVersion: null,
  billPayServices: {},
  billPayServicesLoading: false,
  provider: null
};

function orderReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SET_REPEAT_DATA":
      return {
        ...state,
        repeatData: action.repeatData,
      };
    case "RESET_REPEAT_DATA":
      return {
        ...state,
      };
    case "BILL_PAY_CART":
      return {
        ...state,
        billPayForm: action.billPayForm
      };
    case "BILL_PAY_PAYLOAD":
      return {
        ...state,
        billPayPayload: action.billPayPayload
      };
    case "SET_BILL_PAY_API_VERSION":
      return {
        ...state,
        billpayAPIVersion: action.billpayAPIVersion
      };
    case "BILL_PAY_SERVICES_LOADING":
      return {
        ...state,
        billPayServicesLoading: action.billPayServicesLoading
      };
    case "BILL_PAY_SERVICES":
      return {
        ...state,
        billPayServices: action.billPayServices
      };
    case "SET_BILLPAY_PROVIDER":
      return {
        ...state,
        provider: action.provider
      }
    default:
      return state;
  }
}

export default orderReducer;
