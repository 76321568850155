import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import rewardClientX from "../../../utils/api/rewardClientX";
import { CoverPageSkeleton } from "../../../components/skeleton";

class OfferDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offer: null,
      isLoading: true,
    };
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    this.setState(
      {
        isLoading: true,
      },
      () => {
        rewardClientX
          .get(`/v1/customer/offers/${params.id}`)
          .then((response) => {
            let data = response.data.data;

            this.setState({
              offer: data,
              isLoading: false,
            });
          });
      }
    );
  }
  render() {
    const { isLoading, offer } = this.state;
    return (
      <div className="container font-proxima-nova">
        <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
          <div className="col-12">
            <div className="row align-items-center">
              <div className="col-12">
                <small>
                  <Link to="/">
                    <FormattedMessage
                      id="breadcrumbs.home"
                      defaultMessage="Home"
                    />
                  </Link>{" "}
                  /{" "}
                  <Link to="/rewards">
                    <FormattedMessage
                      id="navbar.rewards"
                      defaultMessage="Cahback-Rewards"
                    />
                  </Link>{" "}
                  /{" "}
                  <strong>
                    <FormattedMessage
                      id="rewards.offer-details"
                      defaultMessage="Offer Details"
                    />
                  </strong>
                </small>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-12">
            <h2 className="font-proxima-nova-bold d-inline-block">
              <FormattedMessage
                id="rewards.offer-details"
                defaultMessage="Offer Details"
              />
            </h2>
          </div>
        </div>

        {isLoading ? (
          <div className="col-12">
            <CoverPageSkeleton rows={1} columns={2} height={50} width={50} />
          </div>
        ) : (
          <div className="row mt-md-3 mb-5 offer-detail">
            <div className="col-md-8 col-12 mt-5 mt-md-0">
              <h5>{offer.description}</h5>
              <div
                dangerouslySetInnerHTML={{
                  __html: offer.details,
                }}
              ></div>
              <h5>
                <FormattedMessage
                  id="footer.terms-and-conditions"
                  defaultMessage="Terms &amp; Conditions"
                />
              </h5>
              <div
                dangerouslySetInnerHTML={{
                  __html: offer.terms,
                }}
              ></div>
            </div>
            <div className="col-12 col-md-4">
              <div className="offer-details-card d-flex w-100">
                <div className="card-icon rewards-card">
                  <div className="reward-icon">
                    <img
                      src={offer.image_url}
                      alt="rewards icon."
                      title="rewards icon."
                      className="d-block"
                    />
                  </div>
                </div>
                <div className="border mb-3"></div>
                <h4>{offer.reward_type}</h4>
                <p className="text-secondary">{offer.title}</p>
                {offer.expiration && (
                  <small className="text-secondary">
                    <i className="far fa-calendar"></i> &nbsp;
                    <FormattedMessage
                      id="rewards.expires-on"
                      defaultMessage="Expires on"
                    />{" "}
                    {offer.expiration}
                  </small>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default OfferDetail;
