import React from 'react'
import ProfileHeader from "../components/Profile";
import { useSelector } from 'react-redux';
import profileClientX from "../../../utils/api/profileClientX";
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';


export default function FollowUs() {
  let history = useHistory()

  const { profile } = useSelector((state) => state.global)
  const [socialMediadata, setSocialMediadata] = useState()

  useEffect(() => {
    !profile && history.push("/")
    profileClientX
      .get(`v1/social-follow`)
      .then((response) => {
        let responseData = response.data.data;
        setSocialMediadata(responseData?.social_media_follow)
      })
      .catch((error) => { });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  return (
    <>
      <div className="">
        <div className="container-fluide profile-container-head">
          <div className="container pt-3 pb-3 pt-md-4 pb-md-4">
            {null !== profile && <ProfileHeader />}
          </div>
        </div>
        <div className="container home-page section-space ">
          <h1 className="follow-us-tittle font-bold text-center mb-3">Follow Us</h1>
          <div className="d-flex flex-column flex-md-row">
            <div className="col-12 col-md-6 mb-3">
              {socialMediadata ?
                <>
                  <div className="text-center py-1"><img src={socialMediadata?.instagram?.image_url} alt="social-media-logo" title="social-media-logo" className="mx-auto" /></div>
                  <div className="px-1 px-md-5 border-seperator">
                    <div className="text-center"><p className="font-bold follow-us-header mb-0">{socialMediadata?.instagram?.header}</p></div>
                    <p style={{ color: "#6372FF" }} className="h5 py-2 font-bold text-center mb-0">{socialMediadata?.instagram?.title}</p>
                    <div dangerouslySetInnerHTML={{ __html: socialMediadata?.instagram?.body }} className="follow-us-list"></div>
                  </div>
                  <div className="text-center py-4">
                    <form action={socialMediadata?.instagram?.social_url} method="get" target="_blank">
                      <button className="animated-btn p py-3x-4 w-50" style={{ height: "40px" }} >Follow US</button>
                    </form>
                  </div>
                </>
                :
                <div className="shine slider-shimmer"></div>
              }
              <hr/>
            </div>
            <div className="col-12 col-md-6 mb-3">
              {socialMediadata ?
                <>
                  <div className="text-center py-1"><img src={socialMediadata?.facebook?.image_url} alt="social-media-logo" title="social-media-logo" className="mx-auto" /></div>
                  <div className="px-1 px-md-5">
                    <div className="text-center "><p className="font-bold follow-us-header mb-0">{socialMediadata?.facebook?.header}</p></div>
                    <p style={{ color: "#6372FF" }} className="h5 py-2 font-bold text-center mb-0">{socialMediadata?.facebook?.title}</p>
                    <div dangerouslySetInnerHTML={{ __html: socialMediadata?.facebook?.body }} className="follow-us-list"></div>
                  </div>
                  <div className="text-center py-4">
                    <form action={socialMediadata?.facebook?.social_url} method="get" target="_blank">
                      <button className="animated-btn p py-3x-4 w-50" style={{ height: "40px" }} >Follow US</button>
                    </form>
                  </div>
                </> :
                <div className="shine slider-shimmer"></div>
              }
              <hr/>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-3">
                {socialMediadata ?
                  <>
                    <div className="text-center py-1"><img src={socialMediadata?.twitter?.image_url} alt="social-media-logo" title="social-media-logo" className="mx-auto" /></div>
                    <div className="px-1 px-md-5">
                      <div className="text-center "><p className="font-bold follow-us-header mb-0">{socialMediadata?.twitter?.header}</p></div>
                      <p style={{ color: "#6372FF" }} className="h5 py-2 font-bold text-center mb-0">{socialMediadata?.twitter?.title}</p>
                      <div dangerouslySetInnerHTML={{ __html: socialMediadata?.twitter?.body }} className="follow-us-list"></div>
                    </div>
                    <div className="text-center py-4">
                      <form action={socialMediadata?.twitter?.social_url} method="get" target="_blank">
                        <button className="animated-btn p py-3x-4 w-50" style={{ height: "40px" }} >Follow US</button>
                      </form>
                    </div>
                  </> :
                  <div className="shine slider-shimmer"></div>
                }
              </div>
        </div>
      </div>
    </>
  )
}
