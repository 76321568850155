const INITIAL_STATE = {
  windsClientIp: null,
  getCurrentUser: null,
  profile: null,
  addresses: [],
  verticalCategory: []
};

function GlobalReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SET_PROFILE":
      return {
        ...state,
        profile: action.profileData,
      };
    case "UPDATE_PROFILE":
      return {
        ...state,
        profile: action.profileData,
      };
    case "UPDATE_ADDRESS":
      return {
        ...state,
        addresses: state.addresses.map((address) =>
          address.id === action.addressId
            ? Object.assign({}, action.addressData)
            : address
        ),
      };
    case "GET_ADDRESSES":
      return {
        ...state,
        addresses: action.addressData.slice(0),
      };
    case "FETCH_ADDRESSES":
      return {
        ...state,
        addresses: action.addressData.slice(0),
      };

    case "RESET_PROFILE":
      return {
        ...state,
        profile: null,
      };
    case "GET_VERTICAL_CATEGORY":
      return {
        ...state,
        verticalCategory: action.verticalCategoryData,
      };
    case "GET_WINDS_CLIENT_IP":
      return {
        ...state,
        windsClientIp: action.windsClientIpData,
      }
    default:
      return state;
  }
}

export default GlobalReducer;
