import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import rewardClientX from "../../../utils/api/rewardClientX";
import { copyToClipboard } from "../../../utils/helper";
import { CoverPageSkeleton } from "../../../components/skeleton";

class VoucherDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      voucherData: null,
      isLoading: true,
    };
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    this.setState(
      {
        isLoading: true,
      },
      () => {
        rewardClientX
          .get(`/v1/customer/winnings/${params.id}/reward`)
          .then((response) => {
            let data = response.data.data;
            this.setState({
              voucherData: data,
              isLoading: false,
            });
          });
      }
    );
  }
  render() {
    const {
      details,
      expires_on,
      terms,
      image,
      code,
      is_conditional_voucher,
      condition_text,
      name,
      affiliate_id,
      website
    } = this.state.voucherData ? this.state.voucherData.voucher : {};
    return (
      <div className="container font-proxima-nova voucher-detail">
        <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
          <div className="col-12">
            <div className="row align-items-center">
              <div className="col-12">
                <small>
                  <Link to="/">
                    <FormattedMessage
                      id="breadcrumbs.home"
                      defaultMessage="Home"
                    />
                  </Link>{" "}
                  /{" "}
                  <Link to="/rewards">
                    <FormattedMessage
                      id="navbar.rewards"
                      defaultMessage="Cashback-Rewards"
                    />
                  </Link>{" "}
                  /{" "}
                  <strong>
                    {name}
                  </strong>
                </small>
              </div>
            </div>
          </div>
        </div>

        {this.state.isLoading ? (
          <div className="col-12">
            <CoverPageSkeleton
              rows={1}
              columns={2}
              height={50}
              width={50}
            />
          </div>
        ) : (
          <div className="row mt-md-3 mb-5 offer-detail link-highlight">
            <div className="col-md-8 col-12 mt-5 mt-md-0">
              <h2 className="font-proxima-nova-bold d-inline-block">
                <FormattedMessage
                  id="home.voucherDetails"
                  defaultMessage="Voucher Details"
                />
              </h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: details,
                }}
              ></div>
              <h5>
                {" "}
                <FormattedMessage
                  id="footer.terms-and-conditions"
                  defaultMessage="Terms &amp; Conditions"
                />
              </h5>
              <div
                dangerouslySetInnerHTML={{
                  __html: terms,
                }}
              ></div>
            </div>
            <div className=" col-12 col-md-4">
              <div className="offer-details-card d-flex w-100">
                <div className="rewards-card">
                  <img src={image} alt="rewards icon." title="rewards icon." className="d-block w-100" />
                </div>
                <div className="border my-2"></div>
                <div className="voucher-info">
                  <h4>
                    {this.state.voucherData.display_name} {" "}
                    {
                      !is_conditional_voucher &&
                      <span>₹ {this.state.voucherData.voucher.value} !</span>
                    }
                  </h4>
                </div>
                {is_conditional_voucher && (
                  <p className="error-text">{condition_text}</p>
                )}
                {expires_on && (
                  <small className="text-secondary">
                    <i className="far fa-calendar"></i> &nbsp;
                    <FormattedMessage
                      id="rewards.expires-on"
                      defaultMessage="Expires on"
                    />{" "}
                    {expires_on}
                  </small>
                )}
              </div>
              <button
                className="btn btn-outline-primary home-btn mt-4 w-100"
                onClick={() => copyToClipboard(code)}
              >
                <FormattedMessage
                  id="general.copy-code"
                  defaultMessage="Copy code"
                />
                : {code}
              </button>
              {
                !affiliate_id && website &&
                <button className="animated-btn py-1 px-4 mt-3 " style={{ height: "60px" }} onClick={() => window.open(website)}>Redeem now</button>
              }
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default VoucherDetails;
