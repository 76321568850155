import React, { Component } from 'react';
import { Link } from "react-router-dom";
import starIcon from '../../../assets/star.svg';
import lodash from 'lodash';
import Slider from "react-slick";

import DeleteIcon from "../../../assets/delete.svg";

export default class NearbyPartnerCarousel extends Component{

    deleteBookmark = (event, id) => {
        event.preventDefault();
        this.props.deleteBookmark(
          id,
          `v1/partners/${id}/bookmark-partner`,
          "partners"
        );
      };

    render(){

        const settings = {
            arrows:true,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            responsive: [
                {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows:false
                }
                }
            ]
        };

       return(
        <Slider {...settings}>
        {
            this.props.partners.map((item,index)=>{
                return(
                <div key={"partner-"+index}>
                    <div style={{padding:'0 15px'}}>
                    
                        <Link to={`/partner-profile/${item.id}`}>
                            <div className="card mb-4 card--partner bookmark-list">
                            
                                <div className="card-body" style={{backgroundImage:`url(${item.store_image})`,backgroundSize:'cover',height:'200px'}}>
                                <div className="bookmark-delete float-right">
                        <img
                          src={DeleteIcon}
                          alt="delete"
                          title="delete"
                          className="float-right"
                          onClick={event => this.deleteBookmark(event, item.id)}
                        />
                      </div>
                                </div>
                                <div className="card-footer">
                                    <div className="row">
                                        <div className="col-9">
                                            <p className="mb-0"><small>{item.category}</small></p>
                                        </div>
                                        <div className="col-3 text-right">
                                            <span style={{backgroundColor:'#009D51',borderRadius: '2px', color:'#fff',padding:'3px 6px',fontSize: '10px'}}>{item.average_rating}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <p className="mb-0"><strong>{item.name}</strong></p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-8">
                                            <p className="mb-0">
                                                {
                                                    lodash.range(item.winds_rating).map((item,index)=>{

                                                        return (
                                                            <img className="star" src={starIcon} alt="star" title='star' key={"partner-rating-"+index}/>
                                                        )

                                                    })
                                                }
                                            </p>
                                        </div>
                                        <div className="col-4 text-right">
                                                <span style={{color:'#666666',fontSize:'12px'}}>{item.store_distance} KM</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                )
            })
        }
        </Slider>
       );
    }
    
}

function NextArrow(props){
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, zIndex:2, right:'15px', background:'#fff',display: "block",borderRadius: "6px",boxShadow: "0 0 15px 0 rgba(0,0,0,0.13)",padding:"5px",height:"48px",width:"38px"}}
        onClick={onClick}
      >
      </div>
    );
  }
  
  function PrevArrow(props){
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, zIndex:2, left:'15px', background:'#fff',display: "block",borderRadius: "6px",boxShadow: "0 0 15px 0 rgba(0,0,0,0.13)",padding:"5px",height:"48px",width:"38px"}}
        onClick={onClick}
      />
    );
  }