import axios from 'axios';
import { currentlySelectedLanguage } from '../helper';
import { getUserAccessToken, handleUnAuthentication } from "../authorization";
import "react-toastify/dist/ReactToastify.css";
import { ErrorToast } from '../../components/ErrorToast';
import CombineErrorMessages from '../../components/CombineErrorMessages';

const voucherClientX = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_VOUCHERS,
  withCredentials: true
});


//Request Interceptor
voucherClientX.interceptors.request.use(function (config) {
  const token = getUserAccessToken()
  if(token) config.headers["Authorization"] = `Bearer ${token}`;
  config.headers['Accept-Language'] = currentlySelectedLanguage();
  
  // Do something before request is sent
  return config;

}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

//Response Interceptor
voucherClientX.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    handleUnAuthentication(error.response.status)
    ErrorToast(error.response.data, CombineErrorMessages);
    
    return Promise.reject(error);
  }
);

export default voucherClientX;