import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import editIcon from "../../../assets/edit-icon.svg";
import { useVerifyForm } from "./verifyEmailFormDialog";
import profileClientX from "../../../utils/api/profileClientX"
import { showToastMessage } from "../../../utils/helper";
import InlineLoader from "../../../components/InlineLoader";
import { connect } from "react-redux";
import { setProfile } from "../../../actions";
const VerifyMobileFormDialog = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const callback = () => {
    const form = {};
    for (let field in inputs) {
      form[field] = inputs[field].value;
    }
    form.type = 1;
    profileClientX
      .post(`v1/addresses`, form)
      .then((response) => {
        props.handleClose(true);
      })
      .catch((error) => {});
  };
  const { inputs, handleInputChange } = useVerifyForm(
    callback,
    props
  );

  const editMobile = () => {
    setShowOtp(false);
    setLoading(false);
    const event = {
      persist: () => {},
      target: {
        name: "otp",
        value: "",
      },
    };
    handleInputChange(event);
  };

  const verifyMobileOtp = () => {
    setLoading(true);
    profileClientX
      .post(`v1/mobile/change`, {
        mobile: inputs.mobile.value,
        otp: inputs.otp.value,
      })
      .then((response) => {
        if (response.data.success) {
          showToastMessage(
            "success",
            <FormattedMessage
              id="profile.mobile-verified"
              defaultMessage="Mobile Verified"
            />
          );
          props.setProfile(response.data.data);
          props.closeMobileFormModel(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getMobileOtp = () => {
    setLoading(true);
    profileClientX
      .post(`v1/mobile-verification-otp`, { mobile: inputs.mobile.value })
      .then((response) => {
        if (response.data.success) {
          showToastMessage(
            "success",
            <FormattedMessage id="profile.otp-sent" defaultMessage="OTP Sent" />
          );
        }
        setLoading(false);
        setShowOtp(true);
      })
      .catch((error) => {
        setShowOtp(false);
        setLoading(false);
      });
  };
  return (
    <Modal
      show
      className="font-proxima-nova"
      backdrop="static"
      onHide={props.closeMobileFormModel}
    >
      <Modal.Header closeButton className="border-0">
        <h6>
          <FormattedMessage
            id="rewards.verify-mobile"
            defaultMessage="Verify Mobile"
          />
        </h6>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="reward-form">
            <label htmlFor="mobilePhn">
              <strong>
                <FormattedMessage id="profile.mobile" defaultMessage="Mobile" />
              </strong>
            </label>
            <input
              type="tel"
              id="mobilePhn"
              className="form-control pay-now-input border-bottom"
              name="mobile"
              value={inputs.mobile.value}
              onChange={handleInputChange}
            />
            <img
              src={editIcon}
              alt="edit icon."
              title="edit icon."
              className="edit-icon"
              onClick={editMobile}
            />
          </div>
          {showOtp && (
            <React.Fragment>
              <label htmlFor="otp">
                <strong>
                  <FormattedMessage id="rewards.otp" defaultMessage="OTP" />
                </strong>
              </label>
              <input
                type="number"
                id="otp"
                className="form-control pay-now-input border-bottom"
                name="otp"
                onChange={handleInputChange}
              />
            </React.Fragment>
          )}
          {isLoading ? (
            <InlineLoader />
          ) : !showOtp ? (
            <button
              className="btn btn-primary home-btn w-100 mt-4"
              disabled={!inputs.mobile.value}
              onClick={getMobileOtp}
            >
              <span>
                <FormattedMessage
                  id="rewards.send-otp"
                  defaultMessage="Send OTP"
                />
              </span>
            </button>
          ) : isLoading ? (
            <InlineLoader />
          ) : (
            showOtp && (
              <button
                className="btn btn-primary home-btn w-100 mt-4"
                disabled={!inputs.otp.value}
                onClick={verifyMobileOtp}
              >
                <span>
                  <FormattedMessage
                    id="rewards.verify-otp"
                    defaultMessage="Verify OTP"
                  />
                </span>
              </button>
            )
          )}
        </form>
      </Modal.Body>
    </Modal>
  );
};



function mapDispatchToProps(dispatch) {
  return {
    setProfile: (responseData) => {
      dispatch(setProfile(responseData));
    },
  };
}
export default connect(
  null,
  mapDispatchToProps
)(VerifyMobileFormDialog);
