import React, { PureComponent } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import classNames from "classnames";
import update from "immutability-helper";
import { FormattedMessage, injectIntl } from "react-intl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InlineLoader from "../../../components/InlineLoader";
import ic_upi from "../../../assets/billpay/ic_upi.svg"
import ic_debit_card from "../../../assets/billpay/ic_debit_card.svg"
import ic_netbanking from "../../../assets/billpay/ic_netbanking.svg"
import ic_wallet from "../../../assets/billpay/ic_wallet.svg"
import ic_phonepe from "../../../assets/billpay/ic_phonepe.png"
import ic_gpay from "../../../assets/billpay/ic_gpay.png"
import ic_paytm from "../../../assets/billpay/ic_paytm.png"
import ic_bhim from "../../../assets/billpay/ic_bhim.png"
import ic_more from "../../../assets/billpay/ic_more.png"
import ic_airtel from "../../../assets/billpay/ic_airtel.png"
import ic_jiomoney from "../../../assets/billpay/ic_jiomoney.png"
import customerPartnerClientX from "../../../utils/api/customerPartnerClientX";
import { setProfile } from "../../../actions";
import ic_checkbox_active from "../../../assets/ic_check_blue.svg";
import ic_checkbox_empty from "../../../assets/ic_checkbox_empty.svg";
import profileClientX from "../../../utils/api/profileClientX"

class Cart extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      cart: [],
      payment_methods: [],
      load: false,
      billing_name: {
        value: null,
        isValid: false,
        error: "",
        isPristine: true,
      },
      billing_email: {
        value: null,
        isValid: false,
        error: "",
        isPristine: true,
      },
      billing_mobile: {
        value: null,
        isValid: false,
        error: "",
        isPristine: true,
      },
      shipping_name: {
        value: null,
        isValid: false,
        error: "",
        isPristine: true,
      },
      shipping_email: {
        value: null,
        isValid: false,
        error: "",
        isPristine: true,
      },
      shipping_mobile: {
        value: null,
        isValid: false,
        error: "",
        isPristine: true,
      },
      shipping_same_as_billing: false,
      formValid: false,
      paymentMode: 0,
      razorPayMethod: "",
      wallet_balance: null,
      wallet_enabled: 0,
      PG: 0,
      proceedPay: null,
      partnerCartData: this.props.partnerCartData,
      wallet_selection_enabled: false
    };
  }

  validateProfile = (profile) => {
    let email = "";
    profile?.email !== null ?
      email = profile?.email :
      email = profile?.billing_email

    const { mobile, name } = profile;
    const newData = update(this.state, {
      // isFormValid: { $set: true },
      billing_name: {
        value: { $set: name },
        isValid: { $set: false },
      },
      billing_email: {
        value: { $set: email },
        isValid: { $set: false },
      },
      billing_mobile: {
        value: { $set: mobile },
        isValid: { $set: false },
      },
      shipping_same_as_billing: { $set: true },
      formValid: { $set: true }
    });
    // this.setState(newData);
    this.setState(newData, () => {
      this.validateField('billing_name', name)
    });
    this.setState(newData, () => {
      this.validateField('billing_email', email)
    });
    this.setState(newData, () => {
      this.validateField('billing_mobile', mobile)
    });
  }

  componentDidMount() {
    profileClientX
      .get("v1/profile?billing_email=true")
      .then((response) => {
        const profileData = response.data.data;
        this.validateProfile(profileData)
      })
      .catch((error) => {
        toast.error("Something went wrong! Login and retry")
      });
    if (this.props.partnerCartData !== null)
      this.getCartSummary()
  }

  getCartSummary = () => {
    customerPartnerClientX
      .post(`v2/cart-details`, {
        partner_id: this.props.partnerCartData.partner_id,
        bill_amount: this.props.partnerCartData.bill_amount,
        wallet_enabled: this.state.wallet_enabled,
        payment_mode: this.state.paymentMode
      })
      .then((response) => {
        this.setState({ cart: response.data.data.cart_data });
        this.setState({ wallet_selection_enabled: response.data.data.wallet_info.wallet_selection_enabled })

        //disable wallet payment option based on wallet_selection_enabled key
        if (response.data.data.wallet_info.wallet_selection_enabled)
          this.setState({ wallet_balance: response.data.data.wallet_info.balance });

        // eslint-disable-next-line array-callback-return
        response.data.data.cart_data.map(item => {
          if (item.key === "processing_fee") {
            this.setState({ PG: item.value })
          }
          if (item.key === "to_pay") {
            if (item.value === "₹ 0.00")
              this.setState({ proceedPay: null })
            else
              this.setState({ proceedPay: item.value })
          }
        })

        if (response.data.data.payment_methods !== null) {
          this.setState({
            payment_methods: response.data.data.payment_methods
          })
          // eslint-disable-next-line array-callback-return
          response.data.data.payment_methods.map(item => {
            if (item.is_selected) {
              this.setState({ razorPayMethod: item.identifier })
              this.setState({ paymentMode: item.id })
            }
          })
        }
        else {
          this.setState({ payment_methods: [] })
        }

      })
      .catch((error) => {
        this.props.history.goBack();
      });
  }

  handleWallet = () => {
    this.setState({ wallet_enabled: !this.state.wallet_enabled}, () => {
      this.getCartSummary()
    })
  }

  handlePayment = (id) => {
    this.setState({ paymentMode: id },
      () => {
        this.getCartSummary()
      })
  }

  onShippingCheckboxChange = (e) => {
    const isChecked = e.target.checked;

    this.setState({ shipping_same_as_billing: isChecked },
      () => {
        this.validateForm();
      }
    );
  };

  onInitLogin = () => {
    toast.error(
      "Something went wrong! Login and retry",
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      }
    );
  }


  onInitOrder = () => {
    let that = this
    const form = {
      bill_amount: this.props.partnerCartData.bill_amount,
      wallet_enabled: this.state.wallet_enabled,
      payment_mode: this.state.paymentMode
    };

    let partnerId = this.props.partnerCartData.partner_id

    customerPartnerClientX
      .post(`/v2/bills/${partnerId}/initiate/pay-now`, form)
      .then((response) => {
        if (response.data.data.payments.proceed_to_gateway === false) {
          this.setState({ load: true })
          customerPartnerClientX
            .post(`/v2/bills/${response.data.data.payments.bill_id}/fulfil/pay-now`, {
              // payment_failed: 0,
            })
            .then((cancelresponse) => {
              that.setState({ load: false })
              that.props.history.push(
                `/nearby-partners/orders/${response.data.data.payments.bill_id}`
              );
            }).catch(error => {
              that.setState({ load: false })
              that.props.history.push(
                `/nearby-partners/orders/${response.data.data.payments.bill_id}`
              );
            })
        }
        else {
          this.setState({
            bill: response.data.data.payments,
            load: true
          });
          if (response.data.data.payments.razorpay_key === null || response.data.data.payments.razorpay_key === "") {
            this.setState({ load: false })
            toast.error(
              "Something went wrong. Please try again later",
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              }
            );
          }
          else {
            new window.Razorpay({
              key: response.data.data.payments.razorpay_key,
              order_id: response.data.data.payments.gateway_order_id,
              "theme": {
                "hide_topbar": true
              },
              "prefill": {
                "name": this.state.billing_name.value,
                "email": this.state.billing_email.value,
                "contact": this.state.billing_mobile.value,
                "method": this.state.razorPayMethod,
              },
              handler: function (pgResponse) {
                customerPartnerClientX
                  .post(
                    `/v2/bills/${response.data.data.payments.bill_id}/fulfil/pay-now`,
                    {
                      razorpay_order_id: pgResponse.razorpay_order_id,
                      razorpay_payment_id: pgResponse.razorpay_payment_id,
                      razorpay_signature: pgResponse.razorpay_signature,
                    }
                  )
                  .then((response) => {
                    that.props.history.push(
                      `/nearby-partners/orders/${that.state.bill.bill_id}`
                    );
                  })
                  .catch((error) => {
                    that.setState({ load: false })
                    that.props.history.push(
                      `/nearby-partners/orders/${that.state.bill.bill_id}`
                    );
                  });
              },
              modal: {
                ondismiss: function () {
                  customerPartnerClientX
                    .post(
                      `/v2/bills/${response.data.data.payments.bill_id}/fulfil/pay-now`,
                      {
                        razorpay_order_id: response.data.data.payments.gateway_order_id,
                        payment_failed: 1,
                      }
                    )
                    .then((cancelresponse) => { })
                    .catch((error) => {
                      that.props.history.push(
                        `/nearby-partners/orders/${that.state.bill.bill_id}`
                      );
                    });
                },
              },
            }).open();
          }
        }
      })
      .catch((error) => {
        this.setState({ load: false })
        toast.error(
          "Something went wrong. Please try again later",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      });
  };



  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const newData = update(this.state, {
      [name]: {
        value: { $set: value },
        isPristine: { $set: false },
      },
    });

    this.setState(newData, () => {
      this.validateField(name, value);
    });
  };

  validateField(fieldName, value) {
    let state = this.state;

    switch (fieldName) {
      case "billing_name":
        state.billing_name.isValid = value.length >= 3;
        state.billing_name.error = state.billing_name.isValid
          ? ""
          : "Please enter valid name";
        break;
      case "billing_mobile":
        state.billing_mobile.isValid = null !== value.match(/^[6-9]\d{9}$/);
        state.billing_mobile.error = state.billing_mobile.isValid
          ? ""
          : " Please enter valid mobile number";
        break;
      case "billing_email":
        state.billing_email.isValid = ((value === null || value === undefined) ? false : null !== value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i));
        state.billing_email.error = state.billing_email.isValid
          ? ""
          : "Please enter valid email";
        break;
      case "shipping_name":
        state.shipping_name.isValid = value.length >= 3;
        state.shipping_name.error = state.shipping_name.isValid
          ? ""
          : "Please enter valid name";
        break;
      case "shipping_mobile":
        state.shipping_mobile.isValid = null !== value.match(/^[6-9]\d{9}$/);
        state.shipping_mobile.error = state.shipping_mobile.isValid
          ? ""
          : "Please enter valid mobile number";
        break;
      case "shipping_email":
        state.shipping_email.isValid = ((value === null || value === undefined) ? false : null !== value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i));
        state.shipping_email.error = state.shipping_email.isValid
          ? ""
          : "Please enter valid email";
        break;
      default:
        break;
    }
    // this.setState(state, this.validateForm);
    this.setState({ state }, () => {
      this.validateForm()
    })
  }

  validateForm() {
    if (this.state.shipping_same_as_billing) {
      this.setState({
        formValid:
          this.state.billing_name.isValid &&
          this.state.billing_email.isValid &&
          this.state.billing_mobile.isValid,
      });

      return;
    }

    this.setState({
      formValid:
        this.state.billing_name.isValid &&
        this.state.billing_email.isValid &&
        this.state.billing_mobile.isValid &&
        this.state.shipping_name.isValid &&
        this.state.shipping_email.isValid &&
        this.state.shipping_mobile.isValid,
    });
  }

  render() {
    return (
      <div className="container">
        <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
          <div className="col-12">
            <div className="row align-items-center">
              <div className="col-12">
                <small>
                  <Link to="/">
                    <FormattedMessage
                      id="breadcrumbs.home"
                      defaultMessage="Home"
                    />
                  </Link>
                </small>{" "}
                /{" "}
                <small>
                  <Link to="/nearby-partners">
                    <FormattedMessage
                      id="home.nearby-partners"
                      defaultMessage="Nearby Partners"
                    />
                  </Link>
                </small>{" "}
                /{" "}
                <small>
                  <strong>
                    <FormattedMessage
                      id="payNow.btn"
                      defaultMessage="Pay Now"
                    />
                  </strong>
                </small>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-md-7">
                <this.BillingDetails />
                <this.ShippingDetails />
              </div>
              <div className="col-12 col-md-5">
                <this.Controls />
                {this.state.payment_methods && this.state.payment_methods.length > 0 ?
                  <this.Payments /> :
                  <></>
                }
                {this.state.load ?
                  <InlineLoader /> :
                  !this.state.load && this.state.formValid && this.state.proceedPay ? (
                    <button
                      className="animated-btn btn-block mt-3 mt-md-4 mb-3 mb-md-4"
                      onClick={this.onInitOrder}
                    >
                      <FormattedMessage id="billpay.proceed-to-pay"
                        defaultMessage="PROCEED TO PAY "
                      />
                      {" "}{this.state.proceedPay}
                    </button>
                  ) : !this.state.load && this.state.formValid && this.state.proceedPay === null ?
                    (
                      <button
                        className="animated-btn btn-block mt-3 mt-md-4 mb-3 mb-md-4"
                        onClick={this.onInitOrder}

                      >
                        <FormattedMessage id="pay-via-wallet"
                          defaultMessage="PAY VIA WALLET"
                        />
                      </button>
                    ) :
                    (
                      <button
                        className="animated-btn-disabled btn-block mt-3 mt-md-4 mb-3 mb-md-4"
                        disabled
                      >
                        <FormattedMessage id="billpay.proceed-to-pay"
                          defaultMessage="PROCEED TO PAY"
                        />
                      </button>
                    )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  BillingDetails = (props) => {
    return (
      <div className="card mb-3 mb-md-4">
        <div className="card-header">
          <h6 className="font-proxima-nova-bold mb-0">
            <FormattedMessage id="vouchers.billing-details" />
          </h6>
        </div>
        <div className="card-body">
          <div className="form-group">
            <label>
              <FormattedMessage id="vouchers.billing-name" /><sup className="text-danger">*</sup>
            </label>
            <input
              type="text"
              name="billing_name"
              value={this.state.billing_name.value}
              className={classNames("form-control", {
                "is-valid": this.state.billing_name.isValid,
                "is-invalid":
                  !this.state.billing_name.isPristine &&
                  !this.state.billing_name.isValid,
              })}
              onChange={(event) => {
                this.handleUserInput(event);
              }}
            />
            <small className="text-danger">{this.state.billing_name.error}</small>
          </div>
          <div className="form-group">
            <label>
              <FormattedMessage id="vouchers.billing-mobile" /><sup className="text-danger">*</sup>
            </label>
            <input
              type="text"
              name="billing_mobile"
              value={this.state.billing_mobile.value}
              className={classNames("form-control", {
                "is-valid": this.state.billing_mobile.isValid,
                "is-invalid":
                  !this.state.billing_mobile.isPristine &&
                  !this.state.billing_mobile.isValid,
              })}
              onChange={(event) => {
                this.handleUserInput(event);
              }}
            />
            <small className="text-danger">{this.state.billing_mobile.error}</small>
          </div>
          <div className="form-group">
            <label>
              <FormattedMessage id="vouchers.billing-email" /><sup className="text-danger">*</sup>
            </label>
            <input
              type="text"
              name="billing_email"
              value={this.state.billing_email.value}
              className={classNames("form-control", {
                "is-valid": this.state.billing_email.isValid,
                "is-invalid":
                  !this.state.billing_email.isPristine &&
                  !this.state.billing_email.isValid,
              })}
              onChange={(event) => {
                this.handleUserInput(event);
              }}
              required
            />
            <small className="text-danger">{this.state.billing_email.error}</small>
          </div>
        </div>
      </div>
    );
  };

  ShippingDetails = (props) => {
    return (
      <div className="card mb-3 mb-md-4">
        <div className="card-header">
          <div className="row align-items-center">
            <div className="col-12 col-md-6">
              <h6 className="font-proxima-nova-bold mb-0">
                <FormattedMessage id="vouchers.shipping-details" />
              </h6>
            </div>
            <div className="col-12 text-md-right col-md-6">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={this.state.shipping_same_as_billing}
                  onChange={this.onShippingCheckboxChange}
                />
                <label className="form-check-label">
                  <FormattedMessage id="vouchers.same-as-billing-details" />
                </label>
              </div>
            </div>
          </div>
        </div>

        {!this.state.shipping_same_as_billing && (
          <div className="card-body">
            <div className="form-group">
              <label>
                <FormattedMessage id="vouchers.shipping-name" />
              </label>
              <input
                type="text"
                name="shipping_name"
                value={this.state.shipping_name.value}
                className={classNames("form-control", {
                  "is-valid": this.state.shipping_name.isValid,
                  "is-invalid":
                    !this.state.shipping_name.isPristine &&
                    !this.state.shipping_name.isValid,
                })}
                onChange={(event) => {
                  this.handleUserInput(event);
                }}
              />
            </div>
            <div className="form-group">
              <label>
                <FormattedMessage id="vouchers.shipping-mobile" />
              </label>
              <input
                type="text"
                name="shipping_mobile"
                value={this.state.shipping_mobile.value}
                className={classNames("form-control", {
                  "is-valid": this.state.shipping_mobile.isValid,
                  "is-invalid":
                    !this.state.shipping_mobile.isPristine &&
                    !this.state.shipping_mobile.isValid,
                })}
                onChange={(event) => {
                  this.handleUserInput(event);
                }}
              />
            </div>
            <div className="form-group">
              <label>
                <FormattedMessage id="vouchers.shipping-email" />
              </label>
              <input
                type="text"
                name="shipping_email"
                value={this.state.shipping_email.value}
                className={classNames("form-control", {
                  "is-valid": this.state.shipping_email.isValid,
                  "is-invalid":
                    !this.state.shipping_email.isPristine &&
                    !this.state.shipping_email.isValid,
                })}
                onChange={(event) => {
                  this.handleUserInput(event);
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  Controls = (props) => {
    const { cart } = this.state;
    return (
      <>
        <div
          className="card mb-3 mb-md-4"
          style={{
            backgroundColor: "#F8F9FA",
            borderRadius: "8px",
            border: "none",
          }}
        >
          <div className="card-body" style={{ borderRadius: "8px 8px 0 0" }}>
            <div className="row align-items-center">
              <div className="col-8">
                <p style={{ fontSize: "15px", display: "inline" }}>
                  <strong>{this.props.partnerCartData.partner_name}</strong>
                </p>
              </div>
              <div className="col-4">
                <div className="align-middle d-flex justify-content-end">
                  <p className="mb-0">
                    <strong>{this.props.partnerCartData.bill_amount}</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer pt-4 pb-4"
            style={{
              border: "none",
              borderRadius: "0 0 8px 8px",
              borderTop: "solid 1px #E8E8E8",
            }}
          >
            {cart.map((item, index) => {
              return (
                <div className="row mb-2" key={index}>
                  <div className="col-6">
                    <p className="mb-0">{item.status_text_translated}</p>
                  </div>
                  <div className="col-6 text-right">
                    <p className="mb-0">
                      <strong>{item.value}</strong>
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {
          this.state.wallet_selection_enabled &&
          <div className="card-body payment-card mb-2 cursor-pointer" onClick={()=>this.handleWallet()}>
            <div className="row align-items-center">
              <div className="col-8 d-flex">
              <img src={this.state.wallet_enabled ?ic_checkbox_active:ic_checkbox_empty} alt="selection-img" className="cart-selected-tic"/>
                <div className="payment-card-top d-flex flex-row align-items-center m-0">
                  <h3 className="m-0 ml-4">
                    <FormattedMessage
                      id="wallet.wallet-balance"
                      defaultMessage="Wallet Balance"
                    />
                  </h3>
                </div>
              </div>
              <div className="col-4">
                <div className="payment-card-top d-flex flex-row justify-content-end align-items-center">
                  <h3 className="m-0">₹ {this.state.wallet_balance}</h3>
                </div>
              </div>
            </div>
          </div>
        }
      </>
    );
  };

  Payments = (props) => {
    return (
      <div
        className="card mt-3 mt-md-5 mb-3 mb-md-4"
        style={{
          backgroundColor: "#FFF",
          borderRadius: "8px",
          border: "none",
        }}
      >
        <h3 className="choose">
          <FormattedMessage id="payment-options" defaultMessage="Payment Options" />
        </h3>
        {/* eslint-disable-next-line array-callback-return */}
        {this.state.payment_methods.map((item, index) => {

          if (item.identifier === "upi")
            return (
              <div id={"b" + item.id} className={"card-body payment-card mb-2 cursor-pointer " + (item.is_selected ? 'select-border' : '')}>
                <div className="row align-items-center" onClick={()=>this.handlePayment(item.id)}>
                  <div className="col-2">
                    <img src={ic_upi} alt="upi" />
                  </div>
                  <div className="col-8 px-1">
                    <div className="payment-card-top d-flex flex-row align-items-center">
                      <h3 className="m-0">UPI</h3>
                      <div className="line"></div>
                      <p className="m-0">{item.display_string}</p>
                    </div>
                    <div className="payment-card-bottom d-flex flex-row align-items-center">
                      <img src={ic_phonepe} alt="phonepe" />
                      <img src={ic_gpay} alt="gpay" />
                      <img src={ic_paytm} alt="paytm" />
                      <img src={ic_bhim} alt="bhim" />
                      <img src={ic_more} alt="more" />
                      {/* <p className="m-0" >MORE</p> */}
                    </div>
                  </div>
                  <div className="col-2">
                  <img src={item.is_selected ?ic_checkbox_active:ic_checkbox_empty} alt="selection-img" className="cart-selected-tic"/>
                  </div>
                </div>
              </div>
            )

          if (item.identifier === "card")
            return (
              <div id={"b" + item.id} className={"card-body payment-card mb-2 cursor-pointer " + (item.is_selected ? 'select-border' : '')} >
                <div className="row align-items-center" onClick={()=>this.handlePayment(item.id)}>
                  <div className="col-2">
                    <img src={ic_debit_card} alt="upi" />
                  </div>
                  <div className="col-8 px-1">
                    <div className="payment-card-top d-flex flex-row align-items-center">
                      <h3 className="m-0">Cards</h3>
                      <div className="line"></div>
                      <p className="m-0">{item.display_string}</p>
                    </div>
                    <div className="payment-card-bottom d-flex flex-row align-items-center">
                      <p className="m-0" >Master Card, Visa, Rupay and more</p>
                    </div>
                  </div>
                  <div className="col-2">
                  <img src={item.is_selected ?ic_checkbox_active:ic_checkbox_empty} alt="selection-img" className="cart-selected-tic"/>
                    {/* <div className="round">
                      <input
                        type="checkbox"
                        className="allcheck"
                        id={item.identifier}
                        value={item.id}
                        onChange={(e) => this.handlePayment(e)}
                        name={item.identifier}
                        checked={item.is_selected}
                      />
                      <label for={item.identifier}></label>
                    </div> */}
                  </div>
                </div>
              </div>
            )

          if (item.identifier === "netbanking")
            return (
              <div id={"b" + item.id} className={"card-body payment-card mb-2 cursor-pointer " + (item.is_selected ? 'select-border' : '')} >
                <div className="row align-items-center" onClick={()=>this.handlePayment(item.id)}>
                  <div className="col-2">
                    <img src={ic_netbanking} alt="upi" />
                  </div>
                  <div className="col-8 px-1">
                    <div className="payment-card-top d-flex flex-row align-items-center">
                      <h3 className="m-0">Netbanking</h3>
                      <div className="line"></div>
                      <p className="m-0">{item.display_string}</p>
                    </div>
                    <div className="payment-card-bottom d-flex flex-row align-items-center">
                      <p className="m-0" >Pay with all indian banks</p>
                    </div>
                  </div>
                  <div className="col-2">
                  <img src={item.is_selected ?ic_checkbox_active:ic_checkbox_empty} alt="selection-img" className="cart-selected-tic"/>
                  </div>
                </div>
              </div>
            )

          if (item.identifier === "wallet")
            return (
              <div id={"b" + item.id} className={"card-body payment-card mb-2 cursor-pointer " + (item.is_selected ? 'select-border' : '')} >
                <div className="row align-items-center" onClick={()=>this.handlePayment(item.id)}>
                  <div className="col-2">
                    <img src={ic_wallet} alt="upi" />
                  </div>
                  <div className="col-8 px-1">
                    <div className="payment-card-top d-flex flex-row align-items-center">
                      <h3 className="m-0">Wallets</h3>
                      <div className="line"></div>
                      <p className="m-0">{item.display_string}</p>
                    </div>
                    <div className="payment-card-bottom d-flex flex-row align-items-center">
                      <img src={ic_phonepe} alt="phonepe" />
                      <img src={ic_paytm} alt="paytm" />
                      <img src={ic_airtel} alt="airtel" />
                      <img src={ic_jiomoney} alt="jio" />
                      <img src={ic_more} alt="more" />
                      {/* <p className="m-0" >MORE</p> */}
                    </div>
                  </div>
                  <div className="col-2">
                  <img src={item.is_selected ?ic_checkbox_active:ic_checkbox_empty} alt="selection-img" className="cart-selected-tic"/>
                  </div>
                </div>
              </div>
            )

        })}

      </div>
    );
  };

}

function mapStateToProps(state) {
  return {
    profile: state.global.profile,
    partnerCartData: state.partner.partnerCartData
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onGetBillingEmail: () => {
      profileClientX
        .get("v1/profile")
        .then((response) => {
          const responseData = response.data.data;
          dispatch(setProfile(responseData));
        })
        .catch((error) => {
        });
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Cart)));

