import React from "react";
import Lottie from "lottie-react";
import loader from "../../assets/cart/ic_loader.json";
import { useEffect } from "react";

const PayVerification = (props) => {
  useEffect(() => {
    setTimeout(() => {
      window.close() //close tab after 3 sec
    }, 3000);
  }, [])

  return (
    <>
      <section style={{ height: "100vh" }} className="homepage d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex flex-column align-items-center">
              <Lottie animationData={loader} loop={true} style={{ width: "120px" }} />
              <h4 className="title mt-4">Processing Order</h4>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default PayVerification;