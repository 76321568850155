import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import editIcon from "../../../assets/edit-icon.svg";
import ProfileClientX from "../../../utils/api/profileClientX"
import { showToastMessage } from "../../../utils/helper";
import InlineLoader from "../../../components/InlineLoader";
import { connect } from "react-redux";
import {  updateProfile } from "../../../actions";
import { toast } from "react-toastify";
export const otpRegex = RegExp("^[1-9][0-9]{5}$");

export const emailRejex = RegExp("/^w+@[a-zA-Z_]+?.[a-zA-Z]{2,3}$");
export const useVerifyForm = (callback, props) => {
  const [inputs, setInputs] = useState({
    email: {
      value: props.email || "",
      isValid: false,
      error: "",
      isPristine: false,
    },
    mobile: {
      value: props.mobile || "",
      isValid: false,
      error: "",
      isPristine: false,
    },
    otp: {
      value: "",
      isValid: false,
      error: "",
      isPristine: false,
    },
  });
  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    callback();
  };

  const validateField = (fieldName, value) => {
    const tempInputs = { ...inputs };
    switch (fieldName) {
      case "mobile":
        tempInputs.mobile.isValid = null !== value.match(/^[6-9]\d{9}$/);
        tempInputs.mobile.error = tempInputs.mobile.isValid
          ? ""
          : "Mobile is invalid";
        break;
      case "otp":
        tempInputs.otp.isValid = otpRegex.test(value);
        tempInputs.otp.error = tempInputs.otp.isValid ? "" : "otp is not valid";
        break;
      case "email":
        tempInputs.email.isValid = emailRejex.test(value);
        tempInputs.email.error = tempInputs.email.isValid
          ? ""
          : "email is not valid";
        break;
      default:
        break;
    }
    setInputs((inputs) => ({
      ...inputs,
      [fieldName]: {
        value: value,
        isValid: tempInputs[fieldName].isValid,
        error: tempInputs[fieldName].error,
        isPristine: true,
      },
    }));
  };

  const handleInputChange = (event) => {
    event.persist();
    const { name, value } = event.target;
    validateField(name, value);
  };
  return {
    inputs,
    handleInputChange,
    handleSubmit,
  };
};

const VerifyEmailFormDialog = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const callback = () => {
    const form = {};
    for (let field in inputs) {
      form[field] = inputs[field].value;
    }
    form.type = 1;
    ProfileClientX
      .post(`v1/addresses`, form)
      .then((response) => {
        props.handleClose(true);
      })
      .catch((error) => {});
  };
  const { inputs, handleInputChange } = useVerifyForm(
    callback,
    props
  );
  const editEmail = () => {
    setShowOtp(false);
    setLoading(false);
    const event = {
      persist: () => {},
      target: {
        name: "otp",
        value: "",
      },
    };
    handleInputChange(event);
  };

  const verifyEmailOtp = () => {
    setLoading(true);
    ProfileClientX
      .post(`v1/email/change`, {
        email: inputs.email.value,
        otp: inputs.otp.value,
      })
      .then((response) => {
        if (response.data.success) {
          toast.success("Your mail ID has been successfully updated!");
          props.setProfile(response.data.data);
          props.closeEmailFormModel(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getEmailOtp = () => {
    setLoading(true);
    ProfileClientX
      .post(`v1/email-verification-otp`, { email: inputs.email.value })
      .then((response) => {
        if (response.data.success) {
          showToastMessage(
            "success",
            <FormattedMessage id="profile.otp-sent" defaultMessage="OTP Sent" />
          );
        }
        setLoading(false);
        setShowOtp(true);
      })
      .catch((error) => {
        setShowOtp(false);
        setLoading(false);
      });
  };
  return (
    <Modal
      show
      className="font-proxima-nova"
      backdrop="static"
      onHide={props.closeEmailFormModel}
    >
      <Modal.Header closeButton className="border-0">
        <h6>
          <FormattedMessage
            id="rewards.verify-email"
            defaultMessage="Verify Email"
          />
        </h6>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="reward-form">
            <label htmlFor="emailAdd">
              <strong>
                <FormattedMessage id="profile.email" defaultMessage="Email" />
              </strong>
            </label>
            <input
              type="email"
              id="emailAdd"
              placeholder="jon@snow.com"
              className="form-control pay-now-input border-bottom"
              name="email"
              value={inputs.email.value}
              onChange={handleInputChange}
            />
            <img
              src={editIcon}
              alt="edit icon."
              title="edit icon."
              className="edit-icon"
              onClick={editEmail}
            />
          </div>

          {showOtp && (
            <React.Fragment>
              <label htmlFor="otp">
                <strong>
                  <FormattedMessage id="rewards.otp" defaultMessage="OTP" />
                </strong>
              </label>
              <input
                type="number"
                id="otp"
                className="form-control pay-now-input border-bottom"
                name="otp"
                value={inputs.otp.value}
                onChange={handleInputChange}
              />
            </React.Fragment>
          )}
          {isLoading ? (
            <InlineLoader />
          ) : !showOtp ? (
            <button
              className="btn btn-primary home-btn w-100 mt-4"
              disabled={!inputs.email.value}
              onClick={getEmailOtp}
            >
              <span>
                <FormattedMessage
                  id="rewards.send-otp"
                  defaultMessage="Send OTP"
                />
              </span>
            </button>
          ) : isLoading ? (
            <InlineLoader />
          ) : (
            showOtp && (
              <button
                className="btn btn-primary home-btn w-100 mt-4"
                disabled={!inputs.otp.value}
                onClick={verifyEmailOtp}
              >
                <span>
                  <FormattedMessage
                    id="rewards.verify-otp"
                    defaultMessage="Verify OTP"
                  />
                </span>
              </button>
            )
          )}
        </form>
      </Modal.Body>
    </Modal>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    setProfile: (responseData) => {
      dispatch(updateProfile(responseData));
    },
  };
}
export default connect(
  null,
  mapDispatchToProps
)(VerifyEmailFormDialog);
