const INITIAL_STATE = {
	isFetchingEarnings: false,
	totalEarnings: [],
	isFetchingEarningsHistory: false,
	earningsHistory: [],
	isFetchingEarningsHistoryDetails: false,
	earningsHistoryDetails: [],
	isFetchingNextPage: false,
	nextPage: 1,
	isFetchingReferrals: false,
	referrals: [],
};

function earningsReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case "GET_EARNINGS":
			return {
				...state,
				isFetchingEarnings: true,
				totalEarnings: [],
			}

		case "GET_EARNINGS_SUCCESS":
			return {
				...state,
				totalEarnings: action.totalEarnings,
				isFetchingEarnings: false,
			}

		case "FETCHING_EARNING_HISTORY":
			return {
				...state,
				isFetchingEarningsHistory: true,
				earningsHistory: [],
			}

		case "SET_EARNINGS_HISTORY":
			return {
				...state,
				earningsHistory: action.earningsHistory,
				isFetchingEarningsHistory: false,
			}

		case "GET_EARNINGS_HISTORY_DETAILS":
			return {
				...state,
				earningsHistoryDetails: [],
				isFetchingEarningsHistoryDetails: true,
				isFetchingNextPage: false,
				nextPage: 2
			}

		case "SET_EARNINGS_HISTORY_DETAILS":
			return {
				...state,
				earningsHistoryDetails: action.earningsHistoryDetails,
				isFetchingEarningsHistoryDetails: false
			}

		case "GET_REFERRALS": {
			return {
				...state,
				isFetchingReferrals: true,
				referrals: [],
			}
		}

		case "SET_REFERRALS": {
			return {
				...state,
				referrals: action.referrals,
				isFetchingReferrals: false,
			}
		}

		default:
			return {
				...state,
			};
	}
}

export default earningsReducer;