export function setLocation(latitude, longitude) {
  return {
    type: "SET_LOCATION",
    latitude,
    longitude,
  };
}

export function setFormattedAddress(formatted_address) {
  return {
    type: "SET_FORMATTED_ADDRESS",
    formatted_address,
  };
}

export function setFormattedLocation(formatted_location) {
  return {
    type: "SET_FORMATTED_LOCATION",
    formatted_location,
  };
}

export function updateProfile(profileData){
  return {
    type:"UPDATE_PROFILE",
    profileData
  }
}

export function setProfile(profileData) {
  return {
    type: "SET_PROFILE",
    profileData,
  };
}

export function getAddresses(addressData){
  return {
    type:"GET_ADDRESSES",
    addressData
  }
}

export function fetchAddresses(addressData){
  return {
    type:"FETCH_ADDRESSES",
    addressData
  }
}

export function updateAddress(addressData, addressId) {
  return {
    type: "UPDATE_ADDRESS",
    addressData,
    addressId
  };
}
export function getVerticalCategory(verticalCategoryData) {
  return {
    type: "GET_VERTICAL_CATEGORY",
    verticalCategoryData
  };
}
export function resetProfile() {
  return {
    type: "RESET_PROFILE"
  };
}
export function getWindsClientIp(windsClientIpData) {
  return {
    type: "GET_WINDS_CLIENT_IP",
    windsClientIpData
  };
}