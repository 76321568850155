export function fetchingShoppingSites(){
    return {
        type: 'FETCHING_SHOPPING_SITES'
    }
}

export function fetchingShoppingSiteCategories(){
    return {
        type: 'FETCHING_SHOPPING_SITES_CATEGORIES'
    }
}

export function fetchingNextPage(){
    return {
        type: 'FETCHING_NEXT_SHOPPING_SITE_PAGE'
    }
}

export function filterClicked() {
    return {
        type: 'FILTER_CLICKED'
    }
}

export function resetFilter() {
    return {
        type: 'RESET_FILTER'
    }
}

export function setShoppingSites(shoppingSites,nextPage){
    return {
        type: 'SET_SHOPPING_SITES',
        shoppingSites,
        nextPage
    }
}
export function setShoppingSitesBannerData(banner_data){
    return {
        type: 'SET_SHOPPING_SITES_BANNER',
        banner_data
    }
}
export function addShoppingSites(shoppingSites, nextPage){
    return {
        type: 'ADD_SHOPPING_SITES',
        shoppingSites,
        nextPage
    }
}

export function setShoppingSiteCategories(categories){
    return {
        type: 'SET_SHOPPING_SITE_CATEGORIES',
        categories
    }
}

export function selectCategory(id){
    return {
        type: 'SELECT_CATEGORY',
        id
    }
}

export function unselectCategory(id){
    return {
        type: 'UNSELECT_CATEGORY',
        id
    }
}

export function setShoppingListAfterBookmarkToggle(id, ShoppingSitesList) {
    return {
        type: 'SET_SHOPPING_LIST_AFTER_BOOKMARK',
        id,
        ShoppingSitesList
    } 
}