import HelmetMetaData from "../components/HelmetMetaData";
import { useLocation } from "react-router-dom";

function PartnerMetaData() {
  let location = useLocation();
  let currentAppUrl = process.env.REACT_APP_URL + location.pathname;

  return (
    <HelmetMetaData
      title={`WINDS Partner: Join & Boost Your Business with WINDS App`}
      description={`Join the WINDS App as a partner and enjoy increased earnings, enhanced online visibility, targeted promotion and referral benefits. Become WINDS Partner & start boosting your business today.`}
      keywords={` winds partner, business with winds app, partner, boost your business, vouchers, gift vouchers, e-vouchers, discount vouchers, online shopping sites, winds app, brand vouchers`}
      voiceSchema={<script type="application/ld+json">
        {JSON.stringify(
          {
            "@context": "https://schema.org/",
            "@type": "WebPage",
            "name": "WINDS PARTNER",
            "speakable":
            {
              "@type": "SpeakableSpecification",
              "xpath":
                [
                  "/html/head/title",
                  "/html/head/meta[@name='description']/@content"
                ]
            },
            "url": currentAppUrl
          }
        )}
      </script>
      }
      breadcrumbSchema={<script type="application/ld+json">
        {JSON.stringify(
          {
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            "itemListElement": [{
              "@type": "ListItem",
              "position": 1,
              "name": "Home",
              "item": process.env.REACT_APP_URL
            }, {
              "@type": "ListItem",
              "position": 2,
              "name": "WINDS PARTNER",
              "item": currentAppUrl
            }]
          }
        )}
      </script>}
      faqSchema={
        <script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [
                {
                  "@type": "Question",
                  "name": "Who Can Be A WINDS Partner?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "- Any well-established, reputable business in the market renowned for its goods or services.\n- An approachable, easily accessible outlet or a business.\n- A business that will adhere to the terms and conditions laid down by WINDS.\n- A business willing to pay the WINDS Service Charge applicable to their respective categories on the transactions done through WINDS can become a WINDS Partner."
                  }
                },
                {
                  "@type": "Question",
                  "name": "What Does WSP Stand For?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "WSP stands for WINDS Service Points. For every 1 Rupee given to WINDS as a Service charge by the WINDS Partner, 1 WSP will be earned by them."
                  }
                },
                {
                  "@type": "Question",
                  "name": "How Many WSPs Will A WINDS Partner Have To Generate To Participate In The Monthly Lucky Draw?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Every 5000 WSP accumulated makes a coupon that entitles the Partner to participate in the Monthly Lucky Draw. If a partner pays ₹20,000 as a service charge, he earns 20,000 WSP and thus makes four coupons, all of which will be added to the draw."
                  }
                },
                {
                  "@type": "Question",
                  "name": "How Does Using The WINDS App Benefits A WINDS Partner?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The platform of WINDS is genuinely unique and beneficial for any shopkeeper. WINDS is the World's Largest Rewards and Happiness program for Customers and Sellers. The Patrons, who are the Customers, stand to win prizes of CRORES* of rupees every month on their everyday purchases, and this benefit motivates them to shop at Partner's Outlets registered with WINDS. \n\nTherefore, it is a massive opportunity for the shopkeepers to not only increase Customer traffic at their respective outlets by joining WINDS and earning referral commission, but as a Partner, also to become a part of the World's Largest Rewards program, thereby getting the chance of winning CRORES* of rupees every month. WINDS also provides its registered Partners with a digital platform with free listings, which increases their online visibility and thus increases the likelihood of more customers visiting their store. With WINDS, they can also put up their customized offers on the WINDS platform and increase their sales significantly by attracting Customers in their specific areas."
                  }
                },
                {
                  "@type": "Question",
                  "name": "If A WINDS Partner Refers Any Patron In The WINDS Ecosystem, Would That Benefit Them?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "If any WINDS Partner does so, they will earn 10% of WINDS earnings \nwhenever their referred WINDS Patron (Customer) shops on the WINDS App. This referral earning will apply to all shopping done through the WINDS app, including at other WINDS Partner Retail outlets, i.e., wherever the Patron has shopped. And the said Partner will get this benefit on each of the Patrons he refers to or introduces."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Can The Same Person Be WINDS Patron/Partner/Senior Captain?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes. One person can perform all these roles."
                  }
                },
                {
                  "@type": "Question",
                  "name": "How Much Time Does It Take For The WSP Earned To Be Updated In The WINDS Partner App?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Any WSPs earned would be updated immediately."
                  }
                },
                {
                  "@type": "Question",
                  "name": "What Are The Responsibilities Of A WINDS Partner?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The primary responsibilities of a WINDS Partner would be:\n\n- Agree to pay the Service Charge to WINDS on every transaction done through the platform.\n-Ensure quality service and products to all WINDS Patrons (The Customers).\n- Encourage them to use more of the WINDS App and create exciting offers and promotions. \n- Overall, they should be willing to adhere to the terms & conditions as laid down by WINDS."
                  }
                },
                {
                  "@type": "Question",
                  "name": "For How Long The WINDS Partner Agreement Be Valid? \nAlso, How Can It Be Renewed?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "WINDS sets no validity limit for this agreement. This will be for life, provided the terms and conditions are not breached in any way by the WINDS Partner. Please note that WINDS reserves the right to terminate the contract immediately."
                  }
                },
                {
                  "@type": "Question",
                  "name": "What Does The Partner Need To Do To Receive His Money If He Wins In The Monthly Lucky Draw?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Once the prize has been won, it will reflect in the wallet option in the Partner's App. They must enter a withdrawal request and share and validate their bank account details. After completing this process, the said amount will be transferred to their respective bank accounts."
                  }
                },
                {
                  "@type": "Question",
                  "name": "How Will A Partner Pay Service Charge?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "When a WINDS Patron makes an online payment through the \"Pay Now\" feature on WINDS App after shopping through a WINDS Partner, then the Service Charge due to WINDS, as per the Service Charge category of the Partner, will automatically be transferred to WINDS. At the same time, the balance payment shall be transferred to the Partner.\n\nExample: If a Partner is registered in the 5% Service Charge category, then 5% of the payment made by the Patron will be transferred to WINDS, and the rest 95% will be shared with the Partner by the Payment Gateway."
                  }
                }
              ]
            }
          )}
        </script>
      }
    ></HelmetMetaData>
  );
}

export default PartnerMetaData;