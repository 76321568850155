import React from 'react'
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import voucher_img from '../../../assets/voucher/voucher-caro.png'
import ic_arrow_vouchers from '../../../assets/voucher/ic_arrow_nearby_vouchers copy 3.png'
import Modal from "react-bootstrap/Modal";
import icTick from "../../../assets/voucher/ic_tick_mark.png"
import voucherBanner from "../../../assets/voucher/ic_winds_gift_card_popup.png"
import { useState } from 'react';
import { useHistory } from "react-router-dom";



export default function VoucherBannerCarousal() {
  let history = useHistory();
  const voucherData = useSelector((state) => state?.voucher);
  const { windsVoucher } = useSelector((state) => state?.voucher);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const viewWindsVoucher = () => {
    history.push(`/voucher/${voucherData?.windsVoucher?.slug}`)
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          dots: false
        }
      },
    ]
  }
  const onVoucherBannerClickEvent = (banner, index) => {
    if (null == banner.link) {
      return;
    }
    if (banner.link.startsWith("http")) {
      window.open(banner.link);
    }
  };

  return (
    <div>
      <div className="w-100 m-auto mb-5 ShoppingBannerCarousal" >
        <Slider {...settings}>
          {voucherData?.banner_data?.map((item,index) => {
            return (

              <div className="justify-content-center position-relative" key={item?.id}>
                <div className="d-flex col-12">
                  <img
                    className="d-block col-12 m-auto p-0 shopping-banner-img"
                    src={item?.image_url}
                    alt={item?.title}
                    title={item?.title}
                    onClick={() => {
                      onVoucherBannerClickEvent(item, index);
                    }}
                  />
                </div>
              </div>
            );
          })}
          {
            windsVoucher &&
            <div className="justify-content-center position-relative">
              <div className=" col-12 voucher-banner-area d-flex ">
                <img
                  className="d-block col-12 m-auto p-0 shopping-banner-img"
                  style={{ borderRadius: "15px" }}
                  src={voucher_img}
                  alt="WINDS Voucher"
                  title="WINDS Voucher"
                />
                <div className="voucher-banner-text-box text-white d-flex align-items-start  flex-column h-100">
                <p className="header1 font-weight-normal m-0">Explore The Hassle-Free Gifting Solution</p>
                  <p className="header2 font-weight-bold m-0"><span className='header1 font-weight-normal'>via</span> {voucherData?.windsVoucher?.name}</p>
                  <p className="desc m-0">Don't Miss Out On The Best Deals & Cashback*</p>
                  <button className=" voucher-banner-btn d-flex justify-content-center align-items-center" onClick={() => viewWindsVoucher()}><p className="m-0 mr-1 btn-text" >BUY NOW</p><img src={ic_arrow_vouchers} alt='btn-arrow' title='btn-arrow' className="btn-arrow" /></button>
                  <p className="h6 mt-auto banner-text-small" >Learn more about the benefits of the WINDS E-Gift Voucher <span className="font-weight-bold cursor-pointer" onClick={handleShow} style={{ textDecoration: "underline" }}>Click here</span></p>
                </div>
              </div>
            </div>
          }
        </Slider>

      </div>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <Modal.Header closeButton style={{ borderBottom: "none" }}>
        </Modal.Header>
        <Modal.Body>
          <div className="voucher-benefits-popup row mb-5">
            <div className="col-12 col-md-9 m-auto">
              <div className='text-center'>
                <img className="w-100" src={voucherBanner} alt="voucher" title="voucher" />
                <span className="voucher-earn-card-text">Earn WINDS Coins</span>
              </div>
              <div className="title mt-3 mt-md-4">
                The benefits of having Winds E-Gift Voucher
              </div>
              <div className="my-4">
                {voucherData?.windsVoucher?.winds_e_voucher_content?.map((voucher_content, index) =>
                  <div className="list d-flex align-items-center border-bottom" key={index}>
                    <img src={icTick} alt="tick" title="tick" />
                    <span className="h5">{voucher_content}</span>
                  </div>
                )}
              </div>
              <button className="animated-btn" onClick={() => viewWindsVoucher()}>Buy Now</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </div>
  )
}
