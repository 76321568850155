import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

function TravelResults(props) {
  let history = useHistory();
  const { profile } = useSelector((state) => state?.global)

  const [iframeURL, setIframeURL] = useState("")
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    setCurrentUserPCID()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setCurrentUserPCID = () => {
    if (!(window.location.search.toString()) || profile === null || window.location.search.length < 20) {
      return history.push("/travel")
    }
    else if (profile !== null) {
      if ('URLSearchParams' in window) {
        var searchParams = new URLSearchParams(window.location.search);
        if (searchParams.get("PCID") === null || searchParams.get("PCID").toString() !== profile?.id.toString()) {
          searchParams.set("PCID", profile?.id);
          window.location.search = searchParams.toString();
        }
        if (searchParams.get("pid") === null) {
          searchParams.append("pid", process.env.REACT_APP_TRAVEL_PID);
          window.location.search = searchParams.toString();
        }
        setIframeURL(process.env.REACT_APP_TRAVEL_SERVICE_URL + "search-results" + props.location.search)
      }
    }
  }

  const hideLoader = () => {
    setLoading(false);
  };

  const Shimmer = (props) => {
    return (
      <React.Fragment>
        <div className="box shine w-100" style={{ height: "180px" }}></div>
        <div className='container my-4' style={{ zIndex: "999" }}>
          <div className='row'>
            <div className='col-12 mx-auto'>
              <div className="box shine w-100" style={{ height: "120vw" }}></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {loading && (
        <Shimmer />
      )}
      <iframe title="windsIframe" src={iframeURL} className="info-box" name="flight" onLoad={hideLoader}></iframe>
    </React.Fragment >
  );
}


export default TravelResults;