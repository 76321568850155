import React, { Component } from "react";
import MultiItemCarousel from "../../../components/MultiItemCarouselHome";
import { resetCategory } from "../../partners/actions/partner";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import windsBadge from "../../../assets/home/ic_winds_logo_badge.svg"

class NearbyPartnerCarousel extends Component {

  render() {
    return (
      <MultiItemCarousel>
        {this.props.partners.map((item, index) => {
          return (
            <div className="nearby-box" key={index}
              onClick={() => {
                this.props.onSelectCategory(item.id);
                this.props.history.push("/nearby-partners");
              }}
            >
              <img className="Badge" style={{ width: "20px" }} src={windsBadge} alt="logo" title="logo" />
              <img style={{ width: "100%" }} src={item.image_url} alt={item.name} title={item.name}  />
              <div className="cat-label">{item.name}</div>
            </div>
          );
        })}
      </MultiItemCarousel>
    );
  }
}

function mapStateToProps(state) {
  return {

  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSelectCategory: id => {
      dispatch(resetCategory(id));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NearbyPartnerCarousel));
