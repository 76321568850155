export const voucherCategoryMetaContent = {
    "beauty-wellness-grooming": {
        keywords: "beauty brand vouchers, wellness brand vouchers, winds app cosmetics brand vouchers, winds app grooming brand vouchers, winds app beauty brand e-gift cards, winds app wellness brand e-gift cards, winds app wellness brand e-gift cards, winds app beauty vouchers,"
    },
    "education": {
        keywords: "education brands e-gift cards, educational  e-gift cards, educational brand vouchers, education gift vouchers, Ed-Tech e-gift cards, Ed-Tech Voucher"
    },
    "fuel-automobile": {
        keywords: "winds app fuel brand voucher, winds app oil brand voucher, automobile brands, winds app fuel brand vouchers, Winds online oil brand vouchers, diesel pump brand vouchers, automobile oil online vouchers, automobile oil brand vouchers, car oil vouchers, HPCL voucher, BPCL voucher"
    },
    "gym-fitness": {
        keywords: "Winds Gym & Fitness, Winds online gym brands, online gym brands vouchers, online fitness brands, online fitness brand vouchers, online gym vouchers, gym  e-gift cards"
    },
    "jewellery": {
        keywords: "online jewellery brand vouchers, winds app online jewellery brands vouchers, winds app jewellery vouchers, winds app jewellery brand vouchers, Winds jewellery vouchers"
    },
    "others": {
        keywords: "winds, winds app, shopping, vouchers, buy vouchers online, winds vouchers, winds brand vouchers, gift cards, winds e-gift cards, winds e-gift vouchers, online vouchers, e-gift cards, e-vouchers, buy gift cards, gift vouchers, winds app gift cards, winds app gift vouchers, winds app vouchers, winds shopping app, "
    },
    "travel-hotels": {
        keywords: " windsapp online travel brand vouchers, winds app hotel booking vouchers, winds app online flight booking voucher, winds app bus booking vouchers, winds app rail booking vouchers, Winds Travel & Hotels voucher, Winds Travel & Hotels  e-gift cards"
    },
    "clothing-accessories": {
        keywords: "Winds Clothing brand vouchers, Winds Clothing & Accessories brand vouchers, new fashion brand vouchers, Winds Fashion brand vouchers, Winds Clothes brand e-gift cards, Clothing accessories brand e-gift cards, Winds Accessories brand e-gift cards, Accessories vouchers, Winds Clothing vouchers, Winds Fashion vouchers, Apparel vouchers"
    },
    "electronics-mobile-accessories": {
        keywords: " Winds Electronics & Mobile Accessories  Winds Electronics vouchers, Winds Mobile Accessories and Electronics brand vouchers, Winds new Electronics brand vouchers, Electronics brand vouchers, Winds Electronics e-gift cards, Winds Mobile Accessories brand e-gift cards, Gadgets brand e-gift cards, Tech vouchers, Winds Mobile vouchers, Winds Mobile E gift  vouchers, New Gadgets vouchers,"
    },
    "gifting": {
        keywords: "Winds Gifting E- Vouchers, Winds Gifting vouchers, Gift cards, E-gift cards, Gift certificates, Winds Voucher codes, Winds Digital gift cards, Online gift vouchers, Gift card codes, Voucher gifts, Winds Gift card offers, Discounted gift cards, Winds Gift card promotions,"
    },
    "healthcare": {
        keywords: "Winds Healthcare E- Vouchers, Winds Healthcare vouchers, Winds Medical Gift cards, Winds Medical voucher, Winds Health voucher, Medical care voucher, Health services voucher, Wellness voucher, Nutrition voucher, Winds fitness voucher, Health Voucher, Fitness certificates, Health cards, Online Fitness vouchers"
    },
    "kids-books-stationery": {
        keywords: "Winds Kids, Books & Stationary brand vouchers, Winds Books & Stationary brand vouchers, Winds Books brand vouchers, Winds Stationary brand vouchers, Winds Stationary and Books brand e-gift cards, Stationary accessories brand e-gift cards, Accessories brand e-gift cards, Kids Accessories vouchers, Winds Kids Clothing vouchers, Winds Kids Fashion vouchers, Winds Apparel vouchers"
    },
    "ott": {
        keywords: "Winds OTT brand vouchers,Winds Movies brand vouchers, Winds TV Series brand vouchers, Winds OTT brand e-gift cards, Winds Kids' streaming brand e-gift cards, Winds Movie Streaming brand e-gift cards, Winds Tv Series vouchers,"
    },
    "travel-accessories": {
        keywords: "Winds Travel Accessories brand vouchers, Winds Travel brand vouchers, Winds Travel E-Gift vouchers, Travel bags brand e-gift cards, Winds Accessories Travel streaming brand e-gift cards, Travel Bags brand e-gift cards,"
    },
    "e-commerce": {
        keywords: "Winds e-commerce brand vouchers, winds app online shopping vouchers, winds app e-commerce brand vouchers, Winds e-commerce gift card, Winds e-commerce gift voucher"
    },
    "food-beverages": {
        keywords: "Winds online food delivery brand vouchers, winds app online food ordering vouchers, winds app food vouchers, Winds online beverage ordering,"
    },
    "grocery": {
        keywords: "Winds online grocery delivery brand vouchers, winds app online grocery ordering vouchers, winds app food vouchers, online grocery ordering, Winds grocery voucher, Winds E-gift card"
    },
    "home-decor": {
        keywords: "Winds online home decor brand vouchers, winds app online home decor vouchers, windsHome & Decor E-gift card, home decor brand vouchers, "
    },
    "movies-events": {
        keywords: "Winds online movie brand vouchers, winds app movie brand vouchers, online movie booking brand vouchers, Winds online event brand vouchers, online event booking brand vouchers, "
    },
    "salon-spa": {
        keywords: "Winds online salon brand vouchers, winds app salon brand vouchers, Winds online spa brand vouchers, winds app salon brand vouchers, Winds salon and spa E-Gift card "
    },
}