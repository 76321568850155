const INITIAL_STATE = {
	isLoading: false,
	wrp: [],
};

function wrpReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case "GET_WRP":
			return {
				...state,
				isLoading: true,
				wrp: [],
			}
		case "SET_WRP":
			return {
				...state,
				isLoading: false,
				wrp: action.wrp,
			}
		case "RESET_WRP":
			return {
				...state,
				isLoading: false,
				wrp: [],
			}
		default:
			return state;
	}
}

export default wrpReducer;