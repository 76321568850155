import axios from 'axios';
import { currentlySelectedLanguage } from '../helper';
import { getUserAccessToken } from "../authorization";
import { ErrorToast } from '../../components/ErrorToast';
import CombineErrorMessages from '../../components/CombineErrorMessages';

const earningApi = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL_CUSTOMER_COMMON,
	withCredentials: true
});


//Request Interceptor
earningApi.interceptors.request.use(function (config) {
	const token = getUserAccessToken()
    if(token) config.headers["Authorization"] = `Bearer ${token}`;
	config.headers['Accept-Language'] = currentlySelectedLanguage();
	// Do something before request is sent
	return config;
},
	function (error) {
		// Do something with request error
		return Promise.reject(error);
	}
);

earningApi.interceptors.response.use(function (response) {
	// Do something with response data
	return response;
},

	function (error) {
		ErrorToast(error.response.data, CombineErrorMessages);

		// Do something with response error
		return Promise.reject(error);
	}
);

export default earningApi;