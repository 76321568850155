import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";

const PreviewDownloadedFiles = (props) => {
  const bills = props.bills;
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <div className="multiple-vouchers">
      <Modal
        show
        onHide={props.closePreview}
        className="font-proxima-nova rewards multiple-bill"
        onEntered={props.modelEntered}
        backdrop="static"
      >
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body>
          <Carousel activeIndex={index} onSelect={handleSelect} interval={null} controls={true} indicators={true}>
            {bills.map((bill,index) => (
              <Carousel.Item key={`affiliateUploadedBillImage${index}`}>
                <div>
                  <div style={{ padding: "15px" }}>
                    <div style={{ cursor: "pointer" }}>
                      <div className="card-body">
                        <div className="rewards-card carousel-card">
                          <div className="reveal-icon">
                            <img
                              src={bill}
                              alt="rewards icon."
                              title="rewards icon."
                              className="d-block"
                            />
                          </div>
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
          );
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PreviewDownloadedFiles;
