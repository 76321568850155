import React, { useEffect, useRef, useState } from 'react'
import flyingFromIcon from "../../../assets/Flying from.svg"
import flyingToIcon from "../../../assets/flying to.svg"
import AdultIcon from "../../../assets/Adult.svg"
import ChildrenIcon from "../../../assets/Children.svg"
import InfantIcon from "../../../assets/Infant.svg"
import calenderIcon from "../../../assets/calender.svg"
import ic_clear_date from "../../../assets/travel/ic_clear_date.png"
import travellerIcon from "../../../assets/No.of traveller.svg"
import ic_flight from "../../../assets/travel/ic_flight.png"
import no_result from "../../../assets/travel/ic_no_result.png"
import interchangeIcon from "../../../assets/travel/ic_interchange.svg"
import searchResults from "../../../assets/travel/ic_search_results.png"
import recentSearches from "../../../assets/travel/ic_recent_search.svg"
import popularDestinations from "../../../assets/travel/ic_populer_destinations.png"
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import format from 'date-fns/format';
import { OverlayTrigger } from "react-bootstrap";
import customerCommonClientX from '../../../utils/api/customerCommonClientX'
import { toast } from 'react-toastify'
import { useHistory } from "react-router-dom";
import { setTravelData } from '../actions'
import { useDispatch, useSelector } from 'react-redux'
import { Calendar } from 'react-date-range'
import { addDays } from 'date-fns'
import LoginDialog from '../../../components/LoginDialog'

function Flight(props) {
  let history = useHistory();
  let dispatch = useDispatch()
  const profile = useSelector((state) => state?.global?.profile);

  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [infants, setInfants] = useState(0)
  const [isCheckedOneWay, setIsCheckedOneWay] = useState(true);
  const [isCheckedRoundtrip, setIsCheckedRoundtrip] = useState(false);
  const [searchFrom, setSearchFrom] = useState("")
  const [searchTo, setSearchTo] = useState("")
  const [showFlyingFrom, setShowFlyingFrom] = useState(false);
  const [showFlyingTo, setShowFlyingTo] = useState(false)
  const [showTravellerInfo, setShowTravellerInfo] = useState(false)
  const [showDepartDate, setShowDepartDate] = useState(false)
  const [showReturnDate, setShowReturnDate] = useState(false)
  const [flightDataLoading, setFlightDataLoading] = useState(false);
  const [flightData, setFlightData] = useState(null);
  const [popularFromDestinations, setPopularFromDestinations] = useState(null);
  const [popularToDestinations, setPopularToDestinations] = useState(null);
  // const [specialFares, setSpecialFares] = useState('');
  const [selectedTravelClass, setSelectedTravelClass] = useState('Any')
  const [departDate, setDepartDate] = useState(new Date());
  const [returnDate, setReturnDate] = useState(new Date());
  const [selectedFromData, setSelectedFromData] = useState(null)
  const [selectedToData, setSelectedToData] = useState(null)
  const [recentSearchData, setRecentSearchData] = useState([])
  const [isLoginDialogOpen, setLoginDialog] = useState(false)

  const flyingFromref = useRef(null);
  const flyingToRef = useRef(null)
  const travellerInfoRef = useRef(null)
  const departDateRef = useRef(null)
  const returnDateRef = useRef(null)
  let ref = useRef(null);

  useEffect(() => {
    initFlightDataOnLoading() //load initial flight data
    document.addEventListener("click", hideFlyingFromOnClickOut, true)
    document.addEventListener("click", hideFlyingToOnClickOut, true)
    document.addEventListener("click", hideTravellerInfoClickOut, true)
    document.addEventListener("click", hideDepartDateOut, true)
    document.addEventListener("click", hideReturnDateOut, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const initFlightDataOnLoading = () => {
    setFlightDataLoading(true)
    customerCommonClientX.get("v1/flight/home")
      .then((response) => {
        let data = response.data.data;
        dispatch(setTravelData(data))
        setFlightData(data)
        setPopularFromDestinations(data?.flight?.popular_destinations)
        setPopularToDestinations(data?.flight?.popular_destinations)
        setFlightDataLoading(false)
        setSelectedFromData(data?.flight?.popular_destinations[0])
        setSelectedToData(data?.flight?.popular_destinations[1])
      }).catch(error => {
        // toast.error("Something went wrong! Please try again after sometime.")
        setFlightDataLoading(false)
      })
  }

  const searchFlightData = (searchParams, origin) => {
    if (searchParams.length > 0) {
      customerCommonClientX.get(`v1/flight/autocomplete?search=${searchParams}`)
        .then((response) => {
          let data = response.data.data;
          if (origin === "from") {
            setPopularFromDestinations(data?.airports)
          } else {
            setPopularToDestinations(data?.airports)
          }
        }).catch(error => {
          // toast.error("Something went wrong! Please try again after sometime.")
        })
    }
    else {
      if (origin === "from")
        setPopularFromDestinations(flightData?.flight?.popular_destinations)
      else
        setPopularToDestinations(flightData?.flight?.popular_destinations)
    }
  }

  const searchFlights = () => {
    if (!profile) {
      openLoginDialog()
    }
    else {
      let requestPayload = {
        "origin_name": selectedFromData?.city_fullname,
        "origin_iata": selectedFromData?.code,
        "destination_name": selectedToData?.city_fullname,
        "destination_iata": selectedToData?.code,
        "depart_date": format(departDate, "yyyy-MM-dd"),
        "return_date": isCheckedRoundtrip ? format(returnDate, "yyyy-MM-dd") : format(departDate, "yyyy-MM-dd"),
        "one_way": isCheckedOneWay ? "Yes" : "No",
        "adults": adults.toString(),
        "children": children.toString(),
        "infants": infants.toString(),
        "cabin": selectedTravelClass,
        "isDomestic": "Yes",
        "origin_country": selectedFromData.CountryCode,
        "destination_country": selectedToData.CountryCode
      }

      customerCommonClientX.post("v1/flight/search-result", requestPayload)
        .then((response) => {
          let data = response.data.data;

          let requestParams = data?.visit_url.replace(process.env.REACT_APP_TRAVEL_SERVICE_URL, "");
          requestParams = requestParams.replace("isMobile=1", "isMobile=0");
          history.push(`${requestParams}`)

        }).catch(error => {
          toast.error(error.response?.data?.message)
          setFlightDataLoading(false)
        })
    }
  }

  const Shimmer = (props) => {
    return (
      <React.Fragment>
        <div className='container' style={{ zIndex: "999" }}>
          <div className='row'>
            <div className='col-12 col-md-12 mx-auto'>
              <div className="shine slider-shimmer shine-black" style={{ height: "180px", background: "rgb(0 0 0 / 29%)" }}></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const TravellerPopover = (
    <div className='main_model'>
      <h5 className='mb-3 text-center font-weight-bold'>Add Travellers</h5>
      <div className='model_box'>
        <div className='text_box align-items-center'>
          <img className='' src={AdultIcon} alt="adult" title="adult" />
          <div className='d-flex flex-column align-items-start'>
            <h1 className='model_text'> Adults</h1>
            <h2 className='pop-over'>12 years & above</h2>
          </div>
        </div>
        <div className="add-option">
          <button className='decrement_btn' onClick={AdultsDecrement}>-</button>
          <text className='middle_text'>{adults}</text>
          <button className='increment_btn' onClick={AdultsIncrement}>+</button>
        </div>
      </div>
      <div className='model_box addtional'>
        <div className='text_box align-items-center'>
          <img src={ChildrenIcon} alt="children" title="children" />
          <div className='d-flex flex-column align-items-start'>
            <h1 className='model_text'>Children</h1>
            <h2 className='pop-over'>2-12 years</h2>
          </div>
        </div>
        <div className="add-option">
          <button className='decrement_btn' onClick={childrenDecrement}>-</button>
          <text className='middle_text'>{children}</text>
          <button className='increment_btn' onClick={childrenIncrement}>+</button>
        </div>
      </div>
      <div className='model_box'>
        <div className='text_box align-items-center'>
          <img src={InfantIcon} alt="infants" title="infants" />
          <div className='d-flex flex-column align-items-start'>
            <h1 className='model_text'>Infants</h1>
            <h2 className='pop-over'>Under 2 years</h2>
          </div>
        </div>
        <div className="add-option">
          <button className='decrement_btn' onClick={infantsDecrement}>-</button>
          <text className='middle_text'>{infants}</text>
          <button className='increment_btn' onClick={infantsIncrement}>+</button>
        </div>
      </div>
      <div className='total_travel mt-3'>
        <div className='sub_total'>
          <img src={travellerIcon} alt="travel" translate="travel" />
          <h2 className='numoftraveler'>Number of Travelers<br /><h1 className='model_text'>{adults || 0} Adults, {children || 0} Children, {infants || 0} Infants</h1></h2>
        </div>
      </div>
      <div className='d-flex justify-content-center mt-3'>
        <button className='done_btn' onClick={() => {
          setShowTravellerInfo(!showTravellerInfo)
          document.body.click()
        }}>DONE</button>
      </div>
    </div>
  );

  const onChangeFlyingFrom = (e) => {
    setSearchFrom(e.target.value)
    searchFlightData(e.target.value, "from")
  }

  const onSelectFlyingFrom = (searchTerm) => {
    let selectedCity = popularFromDestinations?.filter(item => item.name === searchTerm)
    setSelectedFromData(selectedCity[0])
    handleRecentSearchData(selectedCity)
  }

  const onChangeFlyingTo = (e) => {
    setSearchTo(e.target.value)
    searchFlightData(e.target.value, "to")
  }

  const onSelectFlyingTo = (searchTerm2) => {
    let selectedCity = popularToDestinations?.filter(item => item.name === searchTerm2)
    setSelectedToData(selectedCity[0])
    handleRecentSearchData(selectedCity)
  }

  const handleRecentSearchData = (selectedCity) => {
    if (recentSearchData.length === 0) {
      setRecentSearchData(recentSearchData.concat(selectedCity[0]))
    }
    else {
      if (!recentSearchData.find(item => item.city_fullname === selectedCity[0].city_fullname)) {
        setRecentSearchData(recentSearchData.concat(selectedCity[0]))
      }
    }
  }

  // const handleSpecialFares = (item) => {
  //   setSpecialFares(item)
  // }

  const handleChangeOneWay = () => {
    if (!isCheckedOneWay && isCheckedRoundtrip) {
      setIsCheckedOneWay(!isCheckedOneWay);
      setDepartDate(departDate)
    }
    if (isCheckedRoundtrip) {
      setIsCheckedRoundtrip(!isCheckedRoundtrip)
    }
  }

  const handleChangeRoundtrip = () => {
    if (!isCheckedRoundtrip && isCheckedOneWay) {
      setIsCheckedRoundtrip(!isCheckedRoundtrip);
    }
    if (isCheckedOneWay) {
      setIsCheckedOneWay(!isCheckedOneWay)
      setDepartDate(departDate)
    }
  }

  const hideFlyingFromOnClickOut = (e) => {
    if (flyingFromref.current && !flyingFromref.current.contains(e.target)) {
      setShowFlyingFrom(false)
    }
  }

  const hideFlyingToOnClickOut = (e) => {
    if (flyingToRef.current && !flyingToRef.current.contains(e.target)) {
      setShowFlyingTo(false)
    }
  }

  const hideTravellerInfoClickOut = (e) => {
    if (travellerInfoRef.current && !travellerInfoRef.current.contains(e.target)) {
      setShowTravellerInfo(false)
    }
  }

  const hideDepartDateOut = (e) => {
    if (departDateRef.current && !departDateRef.current.contains(e.target)) {
      setShowDepartDate(false)
    }
  }
  const hideReturnDateOut = (e) => {
    if (returnDateRef.current && !returnDateRef.current.contains(e.target)) {
      setShowReturnDate(false)
    }
  }

  function AdultsIncrement() {
    setAdults(function (prevCount) {
      return (prevCount += 1);
    });
  }

  function AdultsDecrement() {
    setAdults(function (prevCount) {
      if (prevCount > 1) {
        return (prevCount -= 1);
      } else {
        return (prevCount = 1);
      }
    });
  }

  function childrenIncrement() {
    setChildren(function (prevCount) {
      return (prevCount += 1);
    });
  }

  function childrenDecrement() {
    setChildren(function (prevCount) {
      if (prevCount > 0) {
        return (prevCount -= 1);
      } else {
        return (prevCount = 0);
      }
    });
  }

  function infantsIncrement() {
    setInfants(function (prevCount) {
      return (prevCount += 1);
    });
  }

  function infantsDecrement() {
    setInfants(function (prevCount) {
      if (prevCount > 0) {
        return (prevCount -= 1);
      } else {
        return (prevCount = 0);
      }
    });
  }

  const NoResults = () => {
    return (
      <div className='d-flex flex-column justify-content-center align-items-center mt-5 mb-2'>
        <img src={no_result} width="75%" alt="no result" title="no result" />
        <p className='mt-2'>No result found!</p>
      </div>
    )
  }

  const RecentSearchesFrom = () => {
    return (
      searchFrom === "" && recentSearchData?.length > 0 &&
      <div className='mb-3'>
        <div className='d-flex align-items-center my-2'>
          <img src={recentSearches} style={{ margin: "0px 6px 0px 17px" }} width="20px" alt="results" title="results" />
          <text className='popular_destination'>Recent Searches</text>
        </div>
        {
          // eslint-disable-next-line array-callback-return
          recentSearchData?.reverse().map((item, index) => {
            if (index < 2)
              return (
                <div className='title_name cursor-pointer d-flex align-items-center'
                  onClick={() => {
                    onSelectFlyingFrom(item.name)
                    setShowTravellerInfo(!showTravellerInfo)
                    document.body.click()
                  }}
                  key={index}>
                  <img src={ic_flight} width="15px" alt="flight" title="flight" className='mr-2' />
                  <div className='d-flex flex-column'>
                    <span className='font-weight-bold'>{item.city_fullname}</span>
                    <span className='air_name'>{item.name}</span>
                  </div>
                </div>
              )
          })
        }
      </div>
    )
  }

  const RecentSearchesTo = () => {
    return (
      searchTo === "" && recentSearchData?.length > 0 &&
      <div className='mb-3'>
        <div className='d-flex align-items-center my-2'>
          <img src={recentSearches} style={{ margin: "0px 6px 0px 17px" }} width="20px" alt="results" title="results" />
          <text className='popular_destination'>Recent Searches</text>
        </div>
        {
          // eslint-disable-next-line array-callback-return
          recentSearchData?.reverse().map((item, index) => {
            if (index < 2)
              return (
                <div className='title_name cursor-pointer d-flex align-items-center'
                  onClick={() => {
                    onSelectFlyingTo(item.name)
                    setShowTravellerInfo(!showTravellerInfo)
                    document.body.click()
                  }}
                  key={index}>
                  <img src={ic_flight} width="15px" alt="flight" title="flight" className='mr-2' />
                  <div className='d-flex flex-column'>
                    <span className='font-weight-bold'>{item.city_fullname}</span>
                    <span className='air_name'>{item.name}</span>
                  </div>
                </div>
              )
          })
        }
      </div>
    )
  }


  const PopoverFlyingFrom = (
    <div className='main'>
      <i className="fa fa-search" aria-hidden="true"></i>
      <input className='input_box' type='text' value={searchFrom} onChange={(event) => onChangeFlyingFrom(event)} placeholder='Enter City/Airport Name' />
      <div className='location'>
        {searchFrom === "" && recentSearchData?.length > 0 &&
          < RecentSearchesFrom />
        }
        {searchFrom === "" ?
          <div className='d-flex align-items-center my-2'>
            <img src={popularDestinations} style={{ margin: "0px 6px 0px 17px" }} width="20px" alt="results" title="results" />
            <text className='popular_destination'>Popular Destinations</text>
          </div> :
          <div className='d-flex align-items-center my-2'>
            <img src={searchResults} style={{ margin: "0px 6px 0px 17px" }} width="20px" alt="results" title="results" />
            <text className='popular_destination'>Search results</text>
          </div>
        }
        {
          popularFromDestinations && popularFromDestinations.length === 0 ?
            <NoResults /> :
            popularFromDestinations?.map((item, index) => {
              return (
                <div className='title_name cursor-pointer d-flex align-items-center'
                  onClick={() => {
                    onSelectFlyingFrom(item.name)
                    setShowTravellerInfo(!showTravellerInfo)
                    document.body.click()
                  }}
                  key={index}>
                  <img src={ic_flight} width="15px" alt="flight" title="flight" className='mr-2' />
                  <div className='d-flex flex-column'>
                    <span className='font-weight-bold'>{item.city_fullname}</span>
                    <span className='air_name'>{item.name}</span>
                  </div>
                </div>
              )
            })}
      </div>
    </div>
  )

  const PopoverFlyingTo = (
    <div className='main'>
      <i className="fa fa-search" aria-hidden="true"></i>
      <input className='input_box' type='text' value={searchTo} onChange={(event) => onChangeFlyingTo(event)} placeholder='Enter City/Airport Name' />
      <div className='location'>
        {searchTo === "" && recentSearchData?.length > 0 &&
          < RecentSearchesTo />
        }
        {searchTo === "" ?
          <div className='d-flex align-items-center my-2'>
            <img src={popularDestinations} style={{ margin: "0px 6px 0px 17px" }} width="20px" alt="results" title="results" />
            <text className='popular_destination'>Popular Destinations</text>
          </div> :
          <div className='d-flex align-items-center my-2'>
            <img src={searchResults} style={{ margin: "0px 6px 0px 17px" }} width="20px" alt="results" title="results" />
            <text className='popular_destination'>Search results</text>
          </div>
        }
        {
          popularToDestinations && popularToDestinations?.length === 0 ?
            <NoResults /> :
            popularToDestinations?.map((item, index) => {
              return (
                <div className='title_name cursor-pointer d-flex align-items-center'
                  onClick={() => {
                    onSelectFlyingTo(item.name)
                    setShowTravellerInfo(!showTravellerInfo)
                    document.body.click()
                  }}
                  key={index}>
                  <img src={ic_flight} width="15px" alt="flight" title="flight" className='mr-2' />
                  <div className='d-flex flex-column'>
                    <span className='font-weight-bold'>{item.city_fullname}</span>
                    <span className='air_name'>{item.name}</span>
                  </div>
                </div>
              )
            })}
      </div>
    </div>
  )

  const returnDatePopover = (
    <div className="d-flex flex-column">
      <Calendar
        minDate={new Date(departDate)}
        onChange={item => {
          setReturnDate(item)
          setTimeout(() => {
            setShowReturnDate(false)
            document.body.click()
          }, 10);
        }}
        date={returnDate} />
    </div>
  )

  const departDatePopover = (
    <div className="d-flex flex-column">
      <Calendar
        minDate={new Date()}
        onChange={item => {
          setDepartDate(item)
          setReturnDate(addDays(item, 1))
          setTimeout(() => {
            setShowDepartDate(false)
            document.body.click()
          }, 10);
        }}
        date={departDate} />
    </div>
  )

  const handleSubmitTravelClass = (selectedTravelClass) => {
    if (document.getElementById(selectedTravelClass))
      document.getElementById(selectedTravelClass).checked = true;
    setSelectedTravelClass(selectedTravelClass)
  }

  const interchangeCity = () => {
    setSelectedFromData(selectedToData)
    setSelectedToData(selectedFromData)
  }

  const openLoginDialog = () => {
    setLoginDialog(true)
  };

  const closeLoginDialog = () => {
    setLoginDialog(false)
  };

  return (
    <div className='gridinner_flight container p-3 '>
      {flightDataLoading ?
        <Shimmer /> :
        <div className='row'>
          <div className='col-12'>
            <div className='d-flex my-0 mb-2 mt-1'>
              <div className='flex'>
                <div className="form-check form-check-inline round new">
                  <input className="form-check-input allcheck" type="checkbox" id="inlineCheckbox1" value="option1" checked={isCheckedOneWay} onChange={handleChangeOneWay} />
                  <label className="form-check-label1" htmlFor="inlineCheckbox1"></label>
                  <p className='checkbox_text1 cursor-pointer mr-4' onClick={handleChangeOneWay}>One Way</p>
                </div>
              </div>
              <div className='flex_box'>
                <div className="form-check form-check-inline round new">
                  <input className="form-check-input allcheck" type="checkbox" id="inlineCheckbox2" value="option2" checked={isCheckedRoundtrip} onChange={handleChangeRoundtrip} />
                  <label className="form-check-label2" htmlFor="inlineCheckbox2"></label>
                  <p className='checkbox_text1 cursor-pointer' onClick={handleChangeRoundtrip}>Roundtrip</p>
                </div>
              </div>
            </div>
            <div className='inner-part flight row'>
              <div className='gridfirst'>
                <div className='g-col-6 flight position-relative'>
                  <div className='w-100' ref={flyingFromref} onClick={() => setShowFlyingFrom(!showFlyingFrom)}>
                    <p className='d-flex mb-1 text-white'>Flying From</p>
                    <OverlayTrigger
                      ref={r => (ref = r)}
                      container={ref.current}
                      trigger="click"
                      placement="bottom"
                      overlay={PopoverFlyingFrom}
                      rootClose
                    >
                      <div className='g-col-3 start' style={{ borderRadius: "12px 0 0 12px" }} ref={flyingFromref} onClick={() => setShowFlyingFrom(!showFlyingFrom)}>
                        <img className='cal' src={flyingFromIcon} alt="icon" title='icon' />
                        <div className='booking-date d-flex flex-column justify-content-center'>
                          <h5 className='m-0 font-weight-bold stored-value-heading'>{selectedFromData?.city_fullname || (popularFromDestinations && popularFromDestinations[0]?.city_fullname)}</h5>
                          <p className='stored-value m-0 pt-0'>{selectedFromData?.name || (popularFromDestinations && popularFromDestinations[0]?.name)}</p>
                        </div>
                      </div>
                    </OverlayTrigger>
                  </div>
                  <img className='position-absolute interchange cursor-pointer' src={interchangeIcon} alt="interchangeIcon" title="interchangeIcon" onClick={() => interchangeCity()} />
                  <div className='w-100' ref={flyingToRef} onClick={() => setShowFlyingTo(!showFlyingTo)}>
                    <p className='d-flex mb-1 text-white'>Flying To</p>
                    <OverlayTrigger
                      ref={r => (ref = r)}
                      container={ref.current}
                      trigger="click"
                      placement="bottom"
                      overlay={PopoverFlyingTo}
                      rootClose
                    >
                      <div className='g-col-3 start'>
                        <img className='cal' src={flyingToIcon} alt="icon" title='icon' />
                        <div className='booking-date d-flex flex-column justify-content-center ellip'>
                          <h5 className='m-0 font-weight-bold stored-value-heading'>{selectedToData?.city_fullname}</h5>
                          <p className='stored-value m-0 pt-0'>{selectedToData?.name}</p>
                        </div>
                      </div>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
              <div className='gridmiddle2'>
                <div className='g-col-4 middle'>
                  {isCheckedOneWay ?
                    <>
                      <div className='w-100' ref={departDateRef} onClick={() => setShowDepartDate(!showDepartDate)}>
                        <p className='d-flex mb-1 text-white'>Departure Date</p>
                        <OverlayTrigger
                          ref={r => (ref = r)}
                          container={ref.current}
                          trigger="click"
                          placement="bottom"
                          overlay={departDatePopover}
                          rootClose
                        >
                          <div className='g-col-2 new' >
                            <img className='cal' src={calenderIcon} alt="icon" title='icon' />
                            <div className='booking-date d-flex flex-column justify-content-center'>
                              <h5 className='m-0 font-weight-bold stored-value-heading'>{(departDate && format(departDate, "dd MMM")) || "Select"}</h5>
                              <p className='stored-value m-0 pt-0'>{(departDate && format(departDate, "E, yyyy")) || ""}</p>
                            </div>
                          </div>
                        </OverlayTrigger>
                      </div>
                      <div className='w-100' ref={returnDateRef} onClick={() => setShowReturnDate(!showReturnDate)}>
                        <p className='d-flex mb-1 text-white'>Return Date</p>
                        <OverlayTrigger
                          ref={r => (ref = r)}
                          container={ref.current}
                          trigger="click"
                          placement="bottom"
                          overlay={returnDatePopover}
                          rootClose
                        >
                          <div className='g-col-2 new min-book' onClick={() => isCheckedOneWay && handleChangeRoundtrip(true)}>
                            <img className='cal' src={calenderIcon} alt="icon" title='icon' />
                            <div className='booking-date d-flex flex-column justify-content-center'>
                              <p className='d-flex m-0' style={{ color: "#4355FF", textDecoration: "underline" }}>Book the return ticket</p>
                              <p className='= m-0 pt-0'>& save on round trips!</p>
                            </div>
                          </div>
                        </OverlayTrigger>
                      </div>
                    </> :
                    <>
                      <div className='w-100' ref={departDateRef} onClick={() => setShowDepartDate(!showDepartDate)}>
                        <p className='d-flex mb-1 text-white'>Departure Date</p>
                        <OverlayTrigger
                          ref={r => (ref = r)}
                          container={ref.current}
                          trigger="click"
                          placement="bottom"
                          overlay={departDatePopover}
                          rootClose
                        >
                          <div className='g-col-2 new' >
                            <img className='cal' src={calenderIcon} alt="calenderIcon" title="calenderIcon"  />
                            <div className='booking-date d-flex flex-column justify-content-center'>
                              <h5 className='m-0 font-weight-bold stored-value-heading'>{(departDate && format(departDate, "dd MMM")) || "Select"}</h5>
                              <p className='stored-value m-0 pt-0'>{(departDate && format(departDate, "E, yyyy")) || ""}</p>
                            </div>
                          </div>
                        </OverlayTrigger>
                      </div>
                      <div className='w-100' ref={returnDateRef} onClick={() => setShowReturnDate(!showReturnDate)}>
                        <p className='d-flex mb-1 text-white'>Return Date</p>
                        <OverlayTrigger
                          ref={r => (ref = r)}
                          container={ref.current}
                          trigger="click"
                          placement="bottom"
                          overlay={returnDatePopover}
                          rootClose
                        >
                          <div className='g-col-2 new position-relative'>
                            <img className='cal' src={calenderIcon} alt="calenderIcon" title="calenderIcon" />
                            <div className='booking-date d-flex flex-column justify-content-center'>
                              <h5 className='m-0 font-weight-bold stored-value-heading'>{(returnDate && format(returnDate, "dd MMM")) || "Select"}</h5>
                              <p className='stored-value m-0 pt-0'>{(returnDate && format(returnDate, "E, yyyy")) || ""}</p>
                            </div>
                            <img className='position-absolute clear-date' src={ic_clear_date} alt="date" title='date' onClick={() => isCheckedRoundtrip && handleChangeOneWay(true)} />
                          </div>
                        </OverlayTrigger>
                      </div>
                    </>
                  }
                </div>
              </div>

              <div className='gridmiddle3'>
                <p className='d-flex mb-1 text-white'>Traveller</p>
                <div className='g-col-2 end' ref={travellerInfoRef} onClick={() => setShowTravellerInfo(!showTravellerInfo)}>
                  <OverlayTrigger
                    ref={r => (ref = r)}
                    container={ref.current}
                    trigger="click"
                    placement="bottom"
                    overlay={TravellerPopover}
                    rootClose
                  >
                    <div className='d-flex' style={{ border: "none" }}>
                      <img className='cal' src={travellerIcon} alt="travellerIcon" title='travellerIcon' readOnly />
                      <div className='booking-date d-flex flex-column justify-content-center'>
                        <p className='m-0 font-weight-bold p-last'>{adults || 0} Adults, {children || 0} Children, {infants || 0} Infants</p>
                      </div>
                    </div>
                  </OverlayTrigger>
                </div>
              </div>
              <div className='search-travel d-flex align-self-end' onClick={() => searchFlights()}>Search</div>
              {isLoginDialogOpen && (
                <LoginDialog
                  isLoginDialogOpen={isLoginDialogOpen}
                  closeLoginDialog={closeLoginDialog}
                />
              )}
            </div>

            <div className='mt-3 mb-2 d-flex align-items-center travel-class'>
              <text className='title_text'>Travel Class - </text>{" "}
              <form className='list_results ml-3'>
                {
                  flightData?.flight?.travel_class &&
                  flightData?.flight?.travel_class.map((item, index) => {
                    return (
                      <div className="form-check round">
                        <input className="form-check-input" type="radio" name="flexRadioDefault" id={item.value} checked={selectedTravelClass === item?.value} onChange={() => handleSubmitTravelClass(item?.value)} />
                        <label className="form-check-label" htmlFor={item?.value}></label>
                        <p className='checkbox_text1 cursor-pointer mr-3 ml-2 ml-md-2' onClick={() => handleSubmitTravelClass(item?.value)}>{item?.value}</p>
                      </div>
                    )
                  })
                }
              </form>
            </div>

            {/* <div className="title_text">
                  <text>Special Fares</text>
                  <div className="booking_btns">
                    {
                      flightData?.flight?.special_fare &&
                      flightData?.flight?.special_fare.map((item, index) => {
                        return (
                          <button className={"btn1 " + (specialFares === item?.value ? 'btn1-active' : '')} onClick={() => handleSpecialFares(item?.value)}>{item?.value}</button>
                        )
                      })
                    }
                  </div>
                </div> */}
          </div>
        </div>
      }
    </div>
  )
}

export default Flight;
