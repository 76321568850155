import React, { Component } from "react";
import MultiItemCarousel from "../../../components/MultiItemCarousel";
// import wrpIcon from "../../../assets/wrp-icon-gold.svg";
// import lodash from "lodash";
import perforatedBorder from "../../../assets/perforated-border.svg";
import { Link } from "react-router-dom";
import DeleteIcon from "../../../assets/delete.svg";

export default class VoucherCarousel extends Component {
  deleteBookmark = (event, id) => {
    event.preventDefault();

    this.props.deleteBookmark(
      id,
      `v1/vouchers/${id}/bookmark`,
      "vouchers"
    );
  };
  render() {
    return (
      <MultiItemCarousel>
        {this.props.vouchers.map((item, index) => {
          return (
            <div key={"voucher-" + index}>
              <div style={{ padding: "0 15px" }}>
                <Link to={`/voucher/${item.id}`}>
                  <div className="card mb-4 card--carousel-item card--carousel-item--vouchers bookmark-list">
                    <div className="card-body d-flex align-items-center">
                      <div className="bookmark-delete float-right">
                        <img
                          src={DeleteIcon}
                          alt="delete"
                          title="delete"
                          className="float-right"
                          onClick={event => this.deleteBookmark(event, item.id)}
                        />
                      </div>
                      <img style={{width:"90%", height:"auto"}} src={item.web_image_url} alt={item.name} title={item?.name}/>
                      <span className="delivery-type-label">
                        {item.delivery_type}
                      </span>
                    </div>
                    <div
                      className="card-footer"
                      style={{
                        backgroundImage: `url(${perforatedBorder})`,
                        backgroundRepeat: "repeat-x",
                        backgroundPositionX: "-2px"
                      }}
                    >
                      <div className="row">
                        <div className="col-12">
                          <p className="brand-name-label mb-0">{item.name}</p>
                          {/* <p className="mb-0">
                            {lodash
                              .range(item.winds_rating)
                              .map((item, index) => {
                                return (
                                  <img
                                    className="star"
                                    src={wrpIcon}
                                    alt="wrp"
                                    key={"voucher-rating-" + index}
                                  />
                                );
                              })}
                          </p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          );
        })}
      </MultiItemCarousel>
    );
  }
}
