import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
class MultipleFileUpload extends Component {
  state = {
    previewFiles: [],
    files: [],
  };
  componentDidUpdate(prevProps) {
    let { isUploading } = this.props;
    const { files } = this.state;
    isUploading && this.props.onUploadClick(files);
  }
  uploadMultipleFiles = (e) => {
    e.persist();
    const filesArray = [];
    filesArray.push(e.target.files);
    for (
      let i = 0, filesArrayLength = filesArray.length;
      i < filesArrayLength;
      i++
    ) {
      this.setState((prevState) => ({
        previewFiles: prevState.previewFiles.concat(
          URL.createObjectURL(filesArray[i][0])
        ),
        files: prevState.files.concat(filesArray[i][0]),
      }));
    }
  };

  deleteFile = (index) => {
    this.setState({
      previewFiles: this.state.previewFiles.filter((file, i) => i !== index),
      files: this.state.files.filter((file, i) => i !== index),
    });
  };
  render() {
    const { previewFiles } = this.state;
    return (
      <div className="bill-upload mt-2 d-flex">
        <label htmlFor="upload-photo" className="upload-box">
          <span className="plus">+</span>
          <span className="winds-lightblue-color">
            <FormattedMessage
              id="payNow.add-image"
              defaultMessage="Add Image"
            />
          </span>
        </label>
        <input
          type="file"
          name="photo"
          id="upload-photo"
          onChange={this.uploadMultipleFiles}
          multiple
          disabled={previewFiles.length > 4}
          value=""
        />

        {(previewFiles || []).map((file, index) => (
          <div className="upload-box" key={`previewAffilateBillImage${index}`}>
            <div className="close-btn winds-lightblue-bg d-flex">
              <i
                className="fas fa-times"
                onClick={() => this.deleteFile(index)}
              ></i>
            </div>
            <img src={file} alt="file" title="file" />
          </div>
        ))}
      </div>
    );
  }
}

export default MultipleFileUpload;
