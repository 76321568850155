import Modal from "react-bootstrap/Modal";
import React from "react";
// useState
import { FormattedMessage } from "react-intl";
// import InlineLoader from "../../../components/InlineLoader";
// import useLoadMoreData from "../../../hooks/useLoadMoreData";
// import { CoverPageSkeleton } from "../../../components/skeleton";
// import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import billImage from "../../../assets/bill.png";
const BillSummary = (props) => {

  return (
    <Modal show onHide={props.onDismiss} className="font-proxima-nova bill-summary-modal">
      <Modal.Header closeButton className="border-0 text-center" style={{ padding: "0rem 1rem" }}></Modal.Header>
      <Modal.Body className="text-center font-proxima-nova bill-summary" style={{ padding: "0rem 2rem 2rem" }}>
        <h4 className="billTitle">
          <strong>
            <FormattedMessage id="bill.summary" defaultMessage="Bill Summary" />
          </strong>
        </h4>
        <img src={billImage} alt="summary" title="summary" className="mr-1" id="bill_summary"></img>
      </Modal.Body>
    </Modal >
  );
};

export default connect(
  null
)(BillSummary);

