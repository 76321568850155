import React, { Component } from "react";
import { Link } from "react-router-dom";
// import wrpIcon from "../../../assets/wrp-icon-gold.svg";
import filterIcon from "../../../assets/filter-icon.svg";
import sortIcon from "../../../assets/sort-icon.svg";
import lodash from "lodash";
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";
import classNames from "classnames";
import { getIsMob, getIsKamaie } from "../../../utils/helper";
import {
  fetchingPartners,
  setPartners,
  fetchingPartnerCategories,
  setPartnerCategories,
  selectCategoryForNearbyPartner,
  unselectCategoryForNearbyPartner,
  fetchingNextPage,
  addPartners,
  setPartnerListAfterBookmarkToggle,
  setDistanceState,
  setRatingState,
  onUnselectRating,
  onSelectRating,
  onSortChange,
  onPartnerFilterClick,
  resetPartnerFilter,
  onPartnerModelTransitionDone,
} from "../actions/partner";
import { resetAccessDenied } from "../../../utils/GeoLocation";
import DefaultStoreImage from "../../../assets/default-store-image.svg";
import InlineLoader from "../../../components/InlineLoader";
import { FormattedMessage, injectIntl } from "react-intl";
import bookmarkActive from "../../../assets/bookmark-active.svg";
import bookmarkInactive from "../../../assets/bookmark-inactive.svg";
import Modal from "react-bootstrap/Modal";
import { Range, getTrackBackground } from "react-range";
import { onViewedPage, onFilteredPartners } from "../../../utils/events";
import ErrorModal from "../../../components/ErrorModal";
import EmptyState from "../../../components/EmptyState";
import EmptyImage from "../../../assets/empty-state-screen.svg";
import customerPartnerClientX from "../../../utils/api/customerPartnerClientX";
import { readCookie } from "../../../utils/helper";

const STEP_DISTANCE = 1;
const MIN_DISTANCE = 1;
const MAX_DISTANCE = 10;

const STEP_RATING = 0.1;
const MIN_RATING = 1;
const MAX_RATING = 5;

class NearByPartners extends Component {
  constructor() {
    super();
    this.state = {
      isMob:0,
      is_kamaie:0
    };
  }
  _isMounted = false;
  componentDidMount() {
    const queryParameters = new URLSearchParams(window.location.search);
    this.setState({ isMob: queryParameters.get("isMob") });
    this.setState({is_kamaie:queryParameters.get("is_kamaie")}) 
    this.props.initCategory();
    this.props.initPartners(
      this.props.selectedCategories,
      this.props.selectedWindsRatings,
      this.props.latitude,
      this.props.longitude,
      this.props.distanceValue,
      this.props.customerRatingValue,
      this.props.sort
    );

    window.addEventListener("scroll", this.onScroll, false);

    this._isMounted = true;

    onViewedPage({
      page_name: "Nearby Partner List Page",
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll, false);
    this._isMounted = false;
  }

  onScroll = () => {
    let height = 0
    window.innerWidth < 767 ? height = 1800 : height = 500

    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight - height &&
      this.props.partners &&
      !this.props.isFetchingNextPage &&
      this.props.nextPage
    ) {
      this.props.onPaginatedSearch(
        this.props.selectedCategories,
        this.props.latitude,
        this.props.longitude,
        this.props.nextPage,
        this.props.distanceValue,
        this.props.customerRatingValue,
        this.props.sort
      );
    }
  };

  handleConfirmationDialog = (permission) => {
    if (permission) {
      this.props.history.push("/");
    } else {
      this.props.resetAccessDenied();
    }
  };

  toggleBookmark = (event, id, isBookmarked) => {
    event.preventDefault();
    event.stopPropagation();
    this.onToggleBookmark(
      id,
      isBookmarked,
      `v1/partners/${id}/bookmark-partner`,
      "partner"
    );
  };
  onToggleBookmark = (id, isBookmarked, url, type) =>
    !isBookmarked
      ? this.props.addBookmark(id, url, this.props.partners)
      : this.props.deleteBookmark(id, url, this.props.partners);

  onChange = (event, id, type) => {
    if (event.target.checked) {
      onFilteredPartners({ category: event.target.name });
      if (type === "rating") {
        this.props.onSelectRating(id);
      } else {
        this.props.onSelectCategory(id);
      }
      return;
    }

    if (type === "rating") {
      this.props.onUnselectRating(id);
    } else {
      this.props.onUnselectCategory(id);
    }
  };

  componentDidUpdate(prevProps) {
    if (
      !lodash.isEqual(
        this.props.selectedCategories,
        prevProps.selectedCategories
      ) ||
      !lodash.isEqual(
        this.props.selectedWindsRatings,
        prevProps.selectedWindsRatings
      ) ||
      !lodash.isEqual(this.props.distanceValue, prevProps.distanceValue) ||
      !lodash.isEqual(
        this.props.customerRatingValue,
        prevProps.customerRatingValue
      ) ||
      this.props.latitude !== prevProps.latitude ||
      this.props.sort !== prevProps.sort
    ) {
      if (this._isMounted) {
        this.props.initPartners(
          this.props.selectedCategories,
          this.props.selectedWindsRatings,
          this.props.latitude,
          this.props.longitude,
          this.props.distanceValue,
          this.props.customerRatingValue,
          this.props.sort
        );
      }
    }
  }

  render() {
    const isMob=getIsMob()
    const is_kamaie=getIsKamaie()
    return (
      <div className="container font-proxima-nova">
        <div className="row mb-3 mt-3 mt-md-4 mb-md-4">
          <div className="col-12">
            <div className="row align-items-center">
              {
                (isMob!=1 && is_kamaie!=1)&&(
                  <div className="col-12">
                <small>
                  <Link to="/">
                    <FormattedMessage
                      id="breadcrumbs.home"
                      defaultMessage="Home"
                    />
                  </Link>{" "}
                  /{" "}
                  <Link to="/nearby-partners/categories">
                    <FormattedMessage
                      id="nearby-partners.nearby-categories"
                      defaultMessage="Nearby Categories"
                    />
                  </Link>{" "}
                  /{" "}
                  <strong>
                    <FormattedMessage
                      id="home.nearby-partners"
                      defaultMessage="Nearby Partners"
                    />
                  </strong>
                </small>
              </div>
                )
              }
              
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-md-3 d-none d-md-block border-right">
                <this.FiltersBlock />
              </div>
              <div className="col-md-9 col-12 main-content">
                <div className="row mb-4 pr-4">
                  <div className="col-md-7 col-6 category-title">
                    <h4>
                      <FormattedMessage
                        id="home.nearby-partners"
                        defaultMessage="Nearby Partners"
                      />
                    </h4>
                  </div>
                  <div className="col-md-5 col-6 text-right">
                    <div
                      className="d-block d-md-none"
                      onClick={() => {
                        this.props.onPartnerFilterClick();
                        this.props.onPartnerModelTransitionDone();
                      }}
                    >
                      <img src={sortIcon} alt="filter" title="filter" className="filter-icon" />
                      <FormattedMessage
                        id="general.sort"
                        defaultMessage="Sort"
                      />
                      &nbsp; / &nbsp;{" "}
                      <img src={filterIcon} alt="filter" title="filter" className="filter-icon" />{" "}
                      <FormattedMessage
                        id="general.filters"
                        defaultMessage="Filters"
                      />
                    </div>

                    <React.Fragment>
                      <Modal
                        show={this.props.isPartnerFilterModelOpen}
                        onHide={this.props.onPartnerFilterClick}
                        onEntered={this.props.onPartnerModelTransitionDone}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Filters</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                          <this.Filters />
                        </Modal.Body>
                        <Modal.Footer>
                          <div className="row w-100">
                            <div className="col-6">
                              <button
                                className="w-100 btn btn-outline-primary"
                                onClick={this.props.onPartnerFilterClick}
                              >
                                <FormattedMessage
                                  id="general.apply"
                                  defaultMessage="Apply"
                                />
                              </button>
                            </div>
                            <div className="col-6">
                              <button
                                className="btn w-100 btn-outline-primary"
                                onClick={() => {
                                  this.props.onPartnerFilterClick();
                                  this.props.resetPartnerFilter();
                                }}
                              >
                                <FormattedMessage
                                  id="general.reset"
                                  defaultMessage="Reset"
                                />
                              </button>
                            </div>
                          </div>
                        </Modal.Footer>
                      </Modal>
                    </React.Fragment>
                    <div className="d-none d-md-block">
                      <this.SortBy {...this.state} {...this.props} />
                    </div>
                  </div>
                </div>
                {this.props.isFetchingPartners ? (
                  <this.PartnerShimmer />
                ) : this.props.partners.length === 0 ? (
                  <this.NoPartner />
                ) : (
                  <this.Partners />
                )}
                <div className="row">
                  <div className="col-12 text-center">
                    {this.props.isFetchingNextPage && <InlineLoader />}
                    {!this.props.nextPage && this.props.partners.length > 0 && (
                      <p className="font-weight-bold">
                        <FormattedMessage
                          id="nearby-partners.no-partners"
                          defaultMessage="No More Partners found"
                        />
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.props.errorCode === 1 && (
          <ErrorModal
            title={
              <FormattedMessage
                id="location.permission-denied"
                defaultMessage="Seems, Location Denied!"
              />
            }
            message={
              <>
                <FormattedMessage
                  id="location.enable-instruction"
                  defaultMessage="Please allow the browser location permission of Winds to show your Nearby Partners"
                />
              </>
            }
            buttonTxt={
              <>
                <FormattedMessage id="profile.ok" defaultMessage="OK" />
              </>
            }
            onDismiss={this.handleConfirmationDialog}
          />
        )}
      </div>
    );
  }

  NoPartner = props => {
    return (
      <div className="empty-state">
        <EmptyState
          title={
            <FormattedMessage
              id="partner.no-partner"
              defaultMessage="No Partner found."
            />
          }
          imageWidth={35}
          image={EmptyImage}
        />
      </div>
    )
  }

  SortBy = (props) => {
    return (
      <React.Fragment>
        <FormattedMessage
          id="nearby-partners.sort-by"
          defaultMessage="Sort by"
        />
        <select
          className="sort ml-3 rounded-3 form-control"
          value={this.props.sort}
          onChange={this.props.onSortChange}
        >
          <option value="radius">
            {this.props.intl.formatMessage({
              id: "nearby-partners.distance",
              defaultMessage: "Distance",
            })}{" "}
          </option>
          {/* <option value="relevance">
            {this.props.intl.formatMessage({
              id: "nearby-partners.relevance",
              defaultMessage: "Relevance",
            })}
          </option> */}
          <option value="avg_rating">
            {this.props.intl.formatMessage({
              id: "nearby-partners.rating",
              defaultMessage: "Rating",
            })}
          </option>
        </select>
      </React.Fragment>
    );
  };

  FiltersBlock = (props) => {
    return (
      <React.Fragment>
        <div className="row border-bottom filter-head align-items-center pb-2">
          <div className="col-md-6 pl-0">
            <h5
              style={{ marginTop: ".5rem" }}
              className="font-proxima-nova-bold"
            >
              <FormattedMessage id="general.filters" defaultMessage="Filters" />
            </h5>
          </div>
          <div className="col-md-6 pr-0">
            <button
              className="btn btn-outline float-right winds-link-color"
              onClick={() => {
                this.props.resetPartnerFilter();
              }}
            >
              <span>
                <FormattedMessage id="general.reset" defaultMessage="Reset" />
              </span>
            </button>
          </div>
        </div>
        {/* <this.Filters /> */}
        {this.Filters(props)}
      </React.Fragment>
    );
  };

  Filters = (props) => {
    return (
      <React.Fragment>
        {this.props.isFetchingPartnerCategories ? (
          <div className="row">
            <div className="col-md-12 pt-4">
              <ContentLoader
                height={200}
                width={200}
                speed={2}
                primaryColor="#F6F7F8"
                secondaryColor="#ecebeb"
              >
                <rect x="0" y="0" rx="2" ry="2" width="132" height="16" />
                <rect x="180" y="0" rx="2" ry="2" width="16" height="16" />
                <rect x="0" y="24" rx="2" ry="2" width="132" height="16" />
                <rect x="0" y="48" rx="2" ry="2" width="132" height="16" />
                <rect x="0" y="72" rx="2" ry="2" width="132" height="16" />
                <rect x="0" y="96" rx="2" ry="2" width="132" height="16" />
                <rect x="0" y="120" rx="2" ry="2" width="132" height="16" />
                <rect x="0" y="144" rx="2" ry="2" width="132" height="16" />
                <rect x="0" y="168" rx="2" ry="2" width="132" height="16" />
                <rect x="180" y="24" rx="2" ry="2" width="16" height="16" />
                <rect x="180" y="48" rx="2" ry="2" width="16" height="16" />
                <rect x="180" y="72" rx="2" ry="2" width="16" height="16" />
                <rect x="180" y="96" rx="2" ry="2" width="16" height="16" />
                <rect x="180" y="120" rx="2" ry="2" width="16" height="16" />
                <rect x="180" y="144" rx="2" ry="2" width="16" height="16" />
                <rect x="180" y="168" rx="2" ry="2" width="16" height="16" />
              </ContentLoader>
            </div>
          </div>
        ) : (
          <div className="row mb-3 mt-1 status">
            <div className="col-12 d-md-none pl-0 pb-4 border-bottom">
              {/* <this.SortBy /> */}
              {this.SortBy()}
            </div>
            <p className="text-uppercase mt-3 font-proxima-nova-bold pb-2">
              <FormattedMessage
                id="general.categories"
                defaultMessage="CATEGORY"
              />
            </p>
            <div className="col-12 filter-categories pb-4 border-bottom pr-0">
              <div
                style={{
                  height: "200px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                {this.props.categories.map((item, index) => {
                  return (
                    <div
                      className="row mt-3"
                      key={"nearby-partner-category-box-" + index}
                    >
                      <div className="col-9">{item.name}</div>
                      <div className="col-3 mb-1 text-center">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={"shopping-sites-category-" + item.id}
                            name={item.name}
                            checked={
                              lodash.indexOf(
                                this.props.selectedCategories,
                                item.id
                              ) > -1
                            }
                            onChange={(event) => {
                              this.onChange(event, item.id, "categories");
                            }}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={"shopping-sites-category-" + item.id}
                          ></label>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="row status align-items-center mt-4">
              <div className="col-md-12">
                <div className="row border-bottom pb-4">
                  <div className="col-12 pl-0">
                    <p className="text-uppercase font-proxima-nova-bold">
                      <FormattedMessage
                        id="nearby-partners.distance"
                        defaultMessage="Distance"
                      />
                    </p>
                    <div className="row">
                      <div className="col-6">1</div>
                      <div className="col-6 text-right">10</div>
                    </div>
                    {this.props.isPartnerModelTransitionDone && (
                      <Range
                        values={this.props.distanceValue}
                        step={STEP_DISTANCE}
                        min={MIN_DISTANCE}
                        max={MAX_DISTANCE}
                        onChange={(value) =>
                          this.props.setDistanceState({ value })
                        }
                        renderTrack={({ props, children }) => (
                          <div
                            onMouseDown={props.onMouseDown}
                            onTouchStart={props.onTouchStart}
                            style={{
                              ...props.style,
                              height: "36px",
                              display: "flex",
                              width: "100%",
                            }}
                          >
                            <div
                              ref={props.ref}
                              className="winds-blue-bg"
                              style={{
                                height: "5px",
                                width: "100%",
                                borderRadius: "4px",
                                background: getTrackBackground({
                                  values: this.props.distanceValue,
                                  colors: ["#0078D7", "#ccc"],
                                  min: MIN_DISTANCE,
                                  max: MAX_DISTANCE,
                                }),
                                alignSelf: "center",
                              }}
                            >
                              {children}
                            </div>
                          </div>
                        )}
                        renderThumb={({ props, isDragged }) => (
                          <div
                            {...props}
                            style={{
                              ...props.style,
                              height: "20px",
                              width: "30px",
                              borderRadius: "4px",
                              backgroundColor: "#0078D7",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              boxShadow: "0px 2px 6px #AAA",
                            }}
                          >
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                color: "#fff",
                                fontWeight: "bold",
                                fontSize: "12px",
                                borderRadius: "4px",
                                backgroundColor: "#0078D7",
                              }}
                            >
                              {this.props.distanceValue}
                            </div>
                            <div
                              style={{
                                height: "16px",
                                width: "5px",
                                backgroundColor: isDragged
                                  ? "#0078D7"
                                  : "#0078D7",
                              }}
                            />
                          </div>
                        )}
                      />
                    )}
                  </div>
                </div>

                <div className="row mt-4 pb-4">
                  <div className="col-12 pl-0">
                    <p className="text-uppercase font-proxima-nova-bold">
                      <FormattedMessage
                        id="general.customer_rating"
                        defaultMessage="Customer Rating"
                      />
                    </p>
                    <div className="row">
                      <div className="col-6">1</div>
                      <div className="col-6 text-right">5</div>
                    </div>
                    {this.props.isPartnerModelTransitionDone && (
                      <Range
                        values={this.props.customerRatingValue}
                        step={STEP_RATING}
                        min={MIN_RATING}
                        max={MAX_RATING}
                        onChange={(value) =>
                          this.props.setRatingState({ value })
                        }
                        renderTrack={({ props, children }) => (
                          <div
                            onMouseDown={props.onMouseDown}
                            onTouchStart={props.onTouchStart}
                            style={{
                              ...props.style,
                              height: "36px",
                              display: "flex",
                              width: "100%",
                            }}
                          >
                            <div
                              ref={props.ref}
                              className=""
                              style={{
                                height: "5px",
                                width: "100%",
                                borderRadius: "4px",
                                background: getTrackBackground({
                                  values: this.props.customerRatingValue,
                                  colors: ['#ccc', '#548BF4', '#ccc'],
                                  min: MIN_RATING,
                                  max: MAX_RATING,
                                }),
                                alignSelf: "center",
                              }}
                            >
                              {children}
                            </div>
                          </div>
                        )}
                        renderThumb={({ index, props, isDragged }) => (
                          <div
                            {...props}
                            style={{
                              ...props.style,
                              height: "20px",
                              width: "30px",
                              borderRadius: "4px",
                              backgroundColor: "#0078D7",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              boxShadow: "0px 2px 6px #AAA",
                            }}
                          >
                            <div
                              style={{
                                position: "absolute",
                                top: "0",
                                color: "#fff",
                                fontWeight: "bold",
                                fontSize: "12px",
                                borderRadius: "4px",
                                backgroundColor: "#0078D7",
                              }}
                            >
                              {this.props.customerRatingValue[index].toFixed(1)}
                            </div>
                            <div
                              style={{
                                height: "16px",
                                width: "5px",
                                backgroundColor: isDragged
                                  ? "#0078D7"
                                  : "#0078D7",
                              }}
                            />
                          </div>
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* 
            this code is being commented out because backend is not yet ready
            <div className="row status align-items-center mt-4 pb-3">
              <div className="col-md-12 pl-0">
                <p className="text-uppercase">Winds Benefit</p>
                {this.props.windsRating.map((rating, index) => {
                  return (
                    <div className="row mt-3" key={"rating" + rating}>
                      <div className="col-9 winds-grey-color">
                        {lodash.range(rating).map((item, index) => {
                          return (
                            <img
                              className="star"
                              src={wrpIcon}
                              alt="star"
                              style={{ width: "16px", marginRight: "2px" }}
                              key={"partner-star-" + index}
                            />
                          );
                        })}
                      </div>
                      <div className="col-3 mb-1 text-right">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={"nearby-partners-5star" + index}
                            checked={
                              this.props.selectedWindsRatings.indexOf(rating) >
                              -1
                            }
                            onChange={event =>
                              this.onChange(event, rating, "rating")
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={"nearby-partners-5star" + index}
                          ></label>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div> */}
          </div>
        )}
      </React.Fragment>
    );
  };

  PartnerShimmer = (props) => {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12 col-md-4">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
          <div className="col-12 col-md-4">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
          <div className="col-12 col-md-4 d-none d-md-block">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-4">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
          <div className="col-12 col-md-4">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
          <div className="col-12 col-md-4 d-none d-md-block">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-4">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
          <div className="col-12 col-md-4">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
          <div className="col-12 col-md-4 d-none d-md-block">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  Partners = (props) => {
    return (
      <div className="row pr-4">
        {this.props.partners.map((item, index) => {
          return (
            <div className="col-md-4" key={"nearby-partner-card-" + index}>
              <Link to={`/partner-profile/${item.id}`}>
                <div className="card mb-4 card--partner">
                  {null != item.store_image ? (
                    <div
                      className="card-body"
                      style={{
                        backgroundColor: "#F8F9FA",
                        backgroundImage: `url(${item.store_image})`,
                        backgroundSize: "cover",
                        height: "200px",
                      }}
                    ></div>
                  ) : (
                    <div
                      className="card-body"
                      style={{
                        backgroundColor: "#F8F9FA",
                        backgroundImage: `url(${DefaultStoreImage})`,
                        backgroundSize: "contain",
                        backgroundPosition: "center center",
                        backgroundRepeat: "no-repeat",
                        height: "200px",
                      }}
                    ></div>
                  )}
                  {
                    <img
                      src={item.bookmarked ? bookmarkActive : bookmarkInactive}
                      className="bookmark"
                      alt="bookmark"
                      title="bookmark"
                      onClick={(event) =>
                        this.toggleBookmark(event, item.id, item.bookmarked)
                      }
                    />
                  }
                  <div className="card-footer">
                    <div className="row">
                      <div className="col-9">
                        <p className="mb-0">
                          <small>{item.category}</small>
                        </p>
                      </div>
                      <div className="col-3 text-right">
                        <span
                          style={{
                            backgroundColor: "#009D51",
                            borderRadius: "2px",
                            color: "#fff",
                            padding: "3px 6px",
                            fontSize: "10px",
                          }}
                          className={classNames("text-white", {
                            "bg-rating-green": item.average_rating >= 4,
                            "bg-rating-orange":
                              item.average_rating >= 3 &&
                              item.average_rating < 4,
                            "bg-rating-red": item.average_rating < 3,
                          })}
                        >
                          {item.average_rating}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <p className="mb-0">
                          <strong title={item.name} className="d-block text-truncate w-100">{item.name}</strong>
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      {/* <div className="col-8">
                        <p className="mb-0">
                          {lodash
                            .range(item.winds_rating)
                            .map((item, index) => {
                              return (
                                <img
                                  className="star"
                                  src={wrpIcon}
                                  alt="star"
                                  key={"partner-star-" + index}
                                />
                              );
                            })}
                        </p>
                      </div> */}
                      <div className="col-4">
                        <span style={{ color: "#666666", fontSize: "12px" }}>
                          {item.store_distance} KM
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    );
  };
}

function mapStateToProps(state) {
  return {
    isFetchingPartnerCategories: state.partner.isFetchingPartnerCategories,
    categories: state.partner.categories,
    selectedCategories: state.partner.selectedCategories,
    isFetchingPartners: state.partner.isFetchingPartners,
    partners: state.partner.partners,
    isFetchingNextPage: state.partner.isFetchingNextPage,
    nextPage: state.partner.nextPage,
    latitude: state.location.latitude,
    longitude: state.location.longitude,
    errorCode: state.location.errorCode,
    distanceValue: state.partner.distanceValue,
    customerRatingValue: state.partner.customerRatingValue,
    windsRating: state.partner.windsRating,
    selectedWindsRatings: state.partner.selectedWindsRatings,
    sort: state.partner.sort,
    isPartnerFilterModelOpen: state.partner.isPartnerFilterModelOpen,
    isPartnerModelTransitionDone: state.partner.isPartnerModelTransitionDone,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initCategory: () => {
      dispatch(fetchingPartnerCategories());

      customerPartnerClientX.get("v1/categories").then((response) => {
        let data = response.data.data;

        dispatch(setPartnerCategories(data));
      });
    },
    initPartners: (
      categories,
      ratings,
      latitude,
      longitude,
      distance,
      customerRating,
      sort
    ) => {
      dispatch(fetchingPartners());

      customerPartnerClientX
        .get("v1/partners/nearby-location", {
          params: {
            latitude: Number(latitude),
            longitude: Number(longitude),
            radius: distance.join(","),
            categories: categories.join(","),
            min_rating: customerRating[0],
            max_rating: customerRating[1],
            sort: sort,
          },
        })
        .then((response) => {
          let data = response.data.data;

          let nextPageURL =
            null !== response.data.links.next &&
            new URL(response.data.links.next);

          let nextPage =
            null !== response.data.links.next &&
            nextPageURL.searchParams.get("page");

          dispatch(setPartners(data, nextPage));
        });
    },
    onSelectCategory: (id) => {
      dispatch(selectCategoryForNearbyPartner(id));
    },

    onPartnerFilterClick: () => {
      dispatch(onPartnerFilterClick());
    },
    resetPartnerFilter: () => {
      dispatch(resetPartnerFilter());
    },

    onSortChange: (event) => {
      const { value } = event.target;
      dispatch(onSortChange(value));
    },
    onUnselectCategory: (id) => {
      dispatch(unselectCategoryForNearbyPartner(id));
    },

    onSelectRating: (id) => {
      dispatch(onSelectRating(id));
    },
    onUnselectRating: (id) => {
      dispatch(onUnselectRating(id));
    },
    onPaginatedSearch: (
      categories,
      latitude,
      longitude,
      nextPage,
      distance,
      customerRating,
      sort
    ) => {
      dispatch(fetchingNextPage());

      customerPartnerClientX
        .get("v1/partners/nearby-location", {
          params: {
            latitude: Number(latitude),
            longitude: Number(longitude),
            categories: categories.join(","),
            page: nextPage,
            radius: distance.join(","),
            min_rating: customerRating[0],
            max_rating: customerRating[1],
            sort: sort,
          },
        })
        .then((response) => {
          let data = response.data.data;

          let nextPageURL =
            null !== response.data.links.next &&
            new URL(response.data.links.next);

          let nextPage =
            null !== response.data.links.next &&
            nextPageURL.searchParams.get("page");

          dispatch(addPartners(data, nextPage));
        });
    },
    addBookmark: (id, url, partner) => {
      customerPartnerClientX
        .post(url, {})
        .then((response) => {
          dispatch(setPartnerListAfterBookmarkToggle(id, partner));
        })
        .catch((error) => { });
    },

    setDistanceState: (distanceValue) => {
      onFilteredPartners({
        distance: `1-${distanceValue.value[0]}`,
      });
      dispatch(setDistanceState(distanceValue));
    },

    setRatingState: (rating) => {
      onFilteredPartners({
        rating: `${rating.value[0]}-${rating.value[1]}`,
      });
      dispatch(setRatingState(rating));
    },
    onPartnerModelTransitionDone: () => {
      dispatch(onPartnerModelTransitionDone());
    },

    deleteBookmark: (id, url, partner) => {
      customerPartnerClientX
        .delete(url)
        .then((response) => {
          dispatch(setPartnerListAfterBookmarkToggle(id, partner));
        })
        .catch((error) => { });
    },
    resetAccessDenied: () => {
      dispatch(resetAccessDenied(null));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(NearByPartners));
