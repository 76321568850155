import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import DefaultStoreImage from "../../../assets/default-store-image.svg";
import DeleteIcon from "../../../assets/delete.svg";
export default class OfffersCarousel extends Component {

  deleteBookmark = (event, id) => {
    event.preventDefault();
    
    this.props.deleteBookmark(
      id,
      `v1/nearby-partners/offers/${id}/bookmark`,
      "offers"
    );
  };
  render() {
    const settings = {
      arrows: true,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false
          }
        }
      ]
    };

    return (
      <Slider {...settings}>
        {this.props.offers.map((item, index) => {
          return (
            <div key={"offer-" + index}>
              <div style={{ padding: "15px" }}>
                <Link to={`/offer/${item.id}`}>
                  <div className="card mb-4 card--carousel-item card--carousel-item--offers bookmark-list">
                    {null != item.store_image ? (
                      <div
                        className="card-body"
                        style={{
                          backgroundColor: "#F8F9FA",
                          backgroundImage: `url(${item.store_image})`,
                          backgroundSize: "cover",
                          height: "200px"
                        }}
                      >
                        <div className="bookmark-delete float-right">
                          <img
                            src={DeleteIcon}
                            className="float-right"
                            alt="delete"
                            title="delete"
                            onClick={event =>
                              this.deleteBookmark(event, item.id)
                            }
                          />
                        </div>
                      </div>
                    ) : (
                      <div
                        className="card-body"
                        style={{
                          backgroundColor: "#F8F9FA",
                          backgroundImage: `url(${DefaultStoreImage})`,
                          backgroundSize: "contain",
                          backgroundPosition: "center center",
                          backgroundRepeat: "no-repeat",
                          height: "200px"
                        }}
                      ></div>
                    )}
                    <div className="card-footer">
                      <p className="mb-0">
                        <small>{item.partner_name}</small>
                      </p>
                      <p className="mb-0">
                        <strong>{item.title.substring(0, 25)}</strong>
                      </p>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                        {item.description !== undefined &&
                          item.description.substring(0, 50) + "..."}
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          );
        })}
      </Slider>
    );
  }
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        zIndex: 2,
        right: "15px",
        background: "#fff",
        display: "block",
        borderRadius: "6px",
        boxShadow: "0 0 15px 0 rgba(0,0,0,0.13)",
        padding: "5px",
        height: "48px",
        width: "38px"
      }}
      onClick={onClick}
    ></div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        zIndex: 2,
        left: "15px",
        background: "#fff",
        display: "block",
        borderRadius: "6px",
        boxShadow: "0 0 15px 0 rgba(0,0,0,0.13)",
        padding: "5px",
        height: "48px",
        width: "38px"
      }}
      onClick={onClick}
    />
  );
}
