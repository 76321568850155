import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Iframe from "../../../components/iFrame";
import { currentlySelectedLanguage } from "../../../utils/helper";
import { useHistory } from "react-router-dom";

const PartnerFAQ = (props) => {
  let history = useHistory()
  history.push("/digi-world")
  return (
    <div className="container font-proxima-nova">
      <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
        <div className="col-12">
          <small>
            <Link to="/">
              <FormattedMessage id="breadcrumbs.home" defaultMessage="Home" />
            </Link>{" "}
            /{" "}
            <strong>
              <FormattedMessage
                id="partner"
                defaultMessage="Partner"
                values={{ what: "react-intl" }}
              /> FAQ
            </strong>
          </small>
        </div>
      </div>
      <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
        <div className="col-12">
          <Iframe source={`${process.env.REACT_APP_BASE_URL_PARTNERS}/faq/${currentlySelectedLanguage()}`}></Iframe>
        </div>
      </div>
    </div>
  );
};
export default PartnerFAQ;
