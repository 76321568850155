import { useState, useEffect } from "react";
import { ContactNumberRegex, EmailRegex, NameRegex, validateRegex } from "../../../utils/validationHelper";
import SubForm from "./SubForm";
import { toast } from "react-toastify";
const Healthinsurance = (props) => {

  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [contactError, setContactError] = useState("");
  const [Contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    const queryParameters = new URLSearchParams(window.location.search);
    const isMob = queryParameters.get("isMob");

    if (!props.profile && isMob != '1') {
      props.openLoginDialog()
      return;
    }

    e.preventDefault();
    if (contactError || error || emailError) {
      setLoading(false);
      return;
    }
    setLoading(true);
    const self = document.querySelector('#self').checked ? 'yes' : 'no';
    const spouse = document.querySelector('#spouse').checked ? 'yes' : 'no';
    const child = document.querySelector('#child').checked ? 'yes' : 'no';
    const mother = document.querySelector('#mother').checked ? 'yes' : 'no';
    const father = document.querySelector('#father').checked ? 'yes' : 'no';

    const pid = queryParameters.get("pid")
    const pcontact = queryParameters.get('pcontact');
    const data = new FormData();
    data.append('Contact', Contact);
    data.append('Email', email);
    data.append('Name', name);
    data.append('Self', self);
    data.append('Spouse', spouse);
    data.append('Child', child);
    data.append('Mother', mother);
    data.append('Father', father);
    data.append('LogedInID', props.profile && props.profile.id || pid);
    data.append('LogedInContact', props.profile && props.profile.mobile || pcontact);

    try {
      fetch('https://script.google.com/macros/s/AKfycbxTrNE0T_WSTgmHsIBGEFlCbNV1Sa5JG2W0QzLSvt8GB79UlQGtiLeVkLVBUf0NZ_Dn/exec', {
        method: 'POST',
        body: data,
      })
        .then(response => {
          if (response.ok == true) {
            setFormSubmitted(true);
          }else {
            throw new Error("Something went wrong!")
          }
        })
        .catch((error)=>{
          toast.error("Something went wrong. Please try again later");
          setFormSubmitted(false);
          setLoading(false);
        })
    }
    catch (error) {
      // console.log("Error", error)
    }
  }
  const onChangeName = (value) => {
    if (validateRegex(value, NameRegex)) {
      setName(value);
      setError("")
    }
    else {
      setError("Invalid Name");
    }
  }
    const onChnageContactNum = (value) => {
      if (validateRegex(value, ContactNumberRegex)) {
        setContact(value);
        setContactError("");
      }
      else {
        setContactError("Invalid/ Mobile Number should be 10 digits")
      }
    }
    const onChangeEmail = (value) => {
      if (validateRegex(value, EmailRegex)) {
        setEmail(value);
        setEmailError("")
      }
      else {
        setEmailError("Invalid Email");
      }
    }

    if (formSubmitted) {  
      return <SubForm />;
    } else {
      return (
        <>
          <section className="service-categories ">
            <form onSubmit={handleSubmit}>
              <div className="container">
                <div className="row">
                  <div className="col-12 col-sm-1 col-md-2 col-lg-2 col-xl-2 d-flex flex-column justify-content-start"></div>
                  <div className="col-12 col-sm-10 col-md-8 col-lg-8 col-xl-8 d-flex flex-column justify-content-start border border-black rounded ">
                    <div className="row ">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-4 d-flex flex-column justify-content-center text-center">
                        <h3>Health Insurance Inquiry</h3>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-12 ">
                        <div className="row ">
                          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-start pb-2">
                            <div className=" d-flex flex-column form-group">
                              <label htmlFor="name">Name<span > *</span></label>
                              <input type="text" name="name" className="form-control" placeholder="Enter Your Name" onChange={(e) => onChangeName(e.target.value)}
                                id="name" required />
                              {error && <p className="text-danger text-sm pl-2">{error}</p>}
                            </div>
                          </div>
                          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-start pb-2">
                            <div className=" d-flex flex-column">
                              <label>Contact Number<span > *</span> </label>
                              <div className="input-group mb-3">
                                <span className="input-group-text bg-light" id="inputGroup-sizing-default">+91</span>
                                <input type="number" className="form-control" name="number" placeholder="Enter Contact Number"
                                  id="number" onChange={(e) => onChnageContactNum(e.target.value)} required></input>
                              </div>
                              {contactError && <p className="text-danger text-sm pl-2">{contactError}</p>}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-start ">
                            <div className="d-flex flex-column">
                              <label>Email <span>*</span></label>
                              <input type="email" className="form-control" name="email" placeholder="Enter Your Email" required id="email"
                                onChange={(e) => onChangeEmail(e.target.value)} ></input>
                              {emailError && <p className="text-danger text-sm pl-2">{emailError}</p>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-2 pt-4 d-flex flex-column justify-content-start">
                        <h5>Who Would You Like to Insure? <span>*</span> </h5>
                      </div>
                    </div>
                    <div className="row ">
                      <div className=" col-6 d-flex flex-column justify-content-start ">
                        <div className="form-check pb-2 ">
                          <input className="form-check-input" type="checkbox" value="" id="self"></input>
                          <label className="form-check-label" >Self</label>
                        </div>
                      </div>
                      <div className="col-6  d-flex flex-column justify-content-start ">
                        <div className="form-check pb-2">
                          <input className="form-check-input" type="checkbox" value="" id="spouse"></input>
                          <label className="form-check-label" >Spouse</label>
                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-6  d-flex flex-column justify-content-start">
                        <div className="form-check pb-2">
                          <input className="form-check-input" type="checkbox" value="" id="child"></input>
                          <label className="form-check-label"> Child</label>
                        </div>
                      </div>
                      <div className="col-6  d-flex flex-column justify-content-start">
                        <div className="form-check pb-2">
                          <input className="form-check-input" type="checkbox" value="" id="mother"></input>
                          <label className="form-check-label">Mother</label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6  d-flex flex-column justify-content-start">
                        <div className="form-check pb-2">
                          <input className="form-check-input" type="checkbox" value="" id="father"></input>
                          <label className="form-check-label" >Father</label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 py-3 d-flex flex-column mt-3">
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" id="flexCheckDefault" required></input>
                          <label className="form-check-label "><h6>Allow our team or third parties to contact you regarding your inquiry.</h6></label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                    <div className="col-1 col-sm-1 col-md-1 col-xl-2 col-xxl-2"></div>
                      <div className="col-10 col-sm-10 col-md-10 col-xl-8 col-xxl-8 d-flex flex-column justify-content-start">
                        <div className=" pb-3 d-flex justify-content-end">
                          <button className="animated-btn mb-3 mt-4 mt-sm-3 home-btn w-100" type="submit" disabled={loading}>
                            {loading && (
                              <span className="spinner-grow spinner-grow-sm mx-1" role="status" aria-hidden="true"></span>
                            )}
                            Submit Details</button>
                        </div>
                      </div>
                      <div className="col-1 col-sm-1 col-md-1 col-xl-2 col-xxl-2"></div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-1 col-md-2 col-lg-2 col-xl-2 d-flex flex-column justify-content-start"></div>
                </div>
              </div>
            </form>
          </section>
        </>
      )
    }
  
}
export default Healthinsurance;
