import HelmetMetaData from "../components/HelmetMetaData";
import { useLocation } from "react-router-dom";

function ShoppingSitesMetaData() {
  let location = useLocation();
  let currentAppUrl = process.env.REACT_APP_URL + location.pathname;

  return (
    <HelmetMetaData
      title={`Shopping Sites - Buy Mobiles, Electronics, Clothes & Appliances | Winds App`}
      description={` Online Shopping - Buy mobiles, home appliances, laptops, watches, clothes, shoes, grocery items, jewelry & medicines. Earn 200% WRP on every purchase from Winds App.`}
      keywords={` shopping sites, shopping site vouchers, beauty site gift cards, travel site gift cards, ecommerce shopping gift vouchers, shopping e-gift cards, shopping e-gift vouchers, shopping sites online deals, buy shoppings site gift cards, winds app shopping sites, winds app offers`}
      voiceSchema={<script type="application/ld+json">
        {JSON.stringify(
          {
            "@context": "https://schema.org/",
            "@type": "WebPage",
            "name": "Shopping Sites - Buy Mobiles, Electronics, Clothes & Appliances | Winds App",
            "speakable":
            {
              "@type": "SpeakableSpecification",
              "xpath":
                [
                  "/html/head/title",
                  "/html/head/meta[@name='description']/@content"
                ]
            },
            "url": currentAppUrl
          }
        )}
      </script>
      }
      breadcrumbSchema={<script type="application/ld+json">
        {JSON.stringify(
          {
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            "itemListElement": [{
              "@type": "ListItem",
              "position": 1,
              "name": "Home",
              "item": process.env.REACT_APP_URL
            }, {
              "@type": "ListItem",
              "position": 2,
              "name": "Shopping Sites",
              "item": currentAppUrl
            }]
          }
        )}
      </script>}
    ></HelmetMetaData>
  );
}

export default ShoppingSitesMetaData;