import React from "react";
const CombineErrorMessages = (props) => {
  const errors = props.errors;
  return <React.Fragment>
{
     Object.entries(errors)
     .reduce((result, [key, value]) => result.concat(value), [])
     .map(msg => (
        <React.Fragment key={msg}>
          <span>{msg}</span>
          <br />
        </React.Fragment>
      ))
}

  </React.Fragment>
};

export default CombineErrorMessages;
