import Modal from "react-bootstrap/Modal";
import React from "react";
import { FormattedMessage } from "react-intl";
const PartialAmount = (props) => {
  return (
    <Modal show onHide={props.onDismiss}>
      <Modal.Body className="text-center p-5 font-proxima-nova">
        <p>
          {" "}
          <FormattedMessage
            id="billpay.enter-partial-amt"
            defaultMessage="Enter Partial Amount"
          />
        </p>
        <input
          type="number"
          name="bill-amount"
          className="operator-select"
          onChange={(event) => props.onPartialAmountChange(event.target.value)}
        />
        <button
          className="btn btn-primary home-btn mt-4"
          onClick={props.onPartialAmtModalClose}
        >
          <FormattedMessage
            id="general.ok"
            defaultMessage="OK"
          />
        </button>
      </Modal.Body>
    </Modal>
  );
};
export default PartialAmount;
