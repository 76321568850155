import React, { Component } from "react";
import { Link } from "react-router-dom";
import profileClientX from "../../../utils/api/profileClientX";
import SideBar from "../components/SideBar";
import Profile from "../components/Profile";
import { FormattedMessage, injectIntl } from "react-intl";
import update from "immutability-helper";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import mobileIcon from "../../../assets/profile/ic_device.svg";

class Devices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      devices: [],
    };
  }

  componentDidMount() {
    this.getDevices();
  }

  getDevices() {
    profileClientX.get("v1/logged-in-devices").then((response) => {
      this.setState({
        devices: response.data.data,
      });
    });
  }

  updateDeviceList = (currentIndex) => {
    const deviceList = this.state.devices;
    deviceList.splice(currentIndex, 1);
    let newState = update(this.state, {
      devices: { $set: deviceList },
    });
    this.setState(newState);
  };

  logoutSelectedSession = (sessionId, currentIndex) => {
    profileClientX
      .post(`v1/logout-selected-session/${sessionId}`, {})
      .then((response) => {
        this.state.devices.length === 1
          // ? (window.location.href = `${process.env.REACT_APP_URL}/login`)
          ? this.clearLoginData()
          : this.updateDeviceList(currentIndex);
      })
      .catch((error) => { });
  };

  clearLoginData = () => {
    localStorage.clear();
    window.location.href = `${process.env.REACT_APP_URL}/login`
  }

  logoutAllSession = () => {
    profileClientX
      .post(`v1/logout-all`, {})
      .then((response) => {
        this.clearLoginData()
      })
      .catch((error) => { });
  };

  logoutExceptSelf = () => {
    profileClientX
      .post(`v1/logout-all-except-self`, {})
      .then((response) => {
        this.getDevices();
      })
      .catch((error) => { });
  };

  logoutBlock = (props) => {
    return (
      <DropdownButton
        alignRight
        title={this.props.intl.formatMessage({
          id: "devices.logout",
          defaultMessage: "Logout Devices",
        })}
      >
        <Dropdown.Item as="button" onClick={this.logoutAllSession}>
          <FormattedMessage
            id="profile.log-out-all"
            defaultMessage="Logout"
            description="Logout"
            values={{ what: "react-intl" }}
          />
        </Dropdown.Item>
        <Dropdown.Item as="button" onClick={this.logoutExceptSelf}>
          <FormattedMessage
            id="profile.log-out-all-except-self"
            defaultMessage="Logout All Except Self"
            description="Logout All Except Self"
            values={{ what: "react-intl" }}
          />
        </Dropdown.Item>
      </DropdownButton>
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className="w-100 profile-container-head">
          <div className="container pt-3 pb-3 pt-md-4 pb-md-4">
            <div className="row mb-md-3">
              <div className="col-12 m-auto font-proxima-nova text-white">
                <small>
                  <Link to="/">
                    <FormattedMessage
                      id="breadcrumbs.home"
                      defaultMessage="Home"
                    />
                  </Link>
                </small>{" "}
                /{" "}
                <small>
                  <Link to="/profile">
                    <FormattedMessage
                      id="profile.profile"
                      defaultMessage="Profile"
                    />
                  </Link>
                </small>{" "}
                /{" "}
                <small>
                  <strong>
                    <FormattedMessage
                      id="profile.logged-in-devices"
                      defaultMessage="Logged in Devices"
                    />
                  </strong>
                </small>
              </div>
            </div>

            <div className="d-none d-md-block">
              <Profile />
            </div>
          </div>
        </div>

        <div className="container mt-md-5 mb-3 mb-md-5">
          <div className="row mb-3 mt-md-4 mb-md-4">
            <div className="d-none d-md-block col-md-4 font-proxima-nova border-right">
              <SideBar />
            </div>
            <div className="col-12 col-md-8">
              <div className="row flex-column flex-md-row d-md-flex justify-content-between mt-md-0 mt-3">
                <div className="col-8 font-proxima-nova-bold ">
                  <h5>
                    <strong>
                      <FormattedMessage
                        id="profile.logged-in-devices"
                        defaultMessage="Logged in Devices"
                      />
                    </strong>
                  </h5>
                </div>
                <div className="col-4 text-right">
                  {this.state.devices.length > 0 && <this.logoutBlock />}
                </div>
              </div>
              <div className="row">
                <div className=" col-12">
                  {this.state.devices.map((item, index) => {
                    return (
                      <div
                        className="card mb-3 mt-2 mt-md-4 bg-light font-proxima-nova rounded-border border-0"
                        key={index}
                      >
                        <div className="card-body d-flex">
                          <div>
                            <img src={mobileIcon} alt="mobile-icon" title="mobile-icon" />
                          </div>
                          <div className="ml-3 d-flex flex-column w-100">
                            <p className="mb-0" style={{ color: "#4e77f7" }}>
                              <strong>Last active</strong>&nbsp;
                              <strong>{item.last_active_type_1}</strong>
                            </p>
                            <p className="mb-0">
                              {item.last_active_type_2}
                            </p>
                            <div className="d-flex flex-column justify-content-between">
                              <p className="mb-0">{item.device}</p>
                            </div>
                          </div>
                          {/* <div
                            className="float-right"
                            style={{
                              width: "18%",
                              textAlign: "right",
                              cursor: "pointer",
                              color: "#087CD8",
                            }}
                            onClick={() => {
                              this.logoutSelectedSession(item.id, index);
                            }}
                          >
                            <FormattedMessage
                              id="general.remove"
                              defaultMessage="Remove"
                              description="Remove"
                              values={{ what: "react-intl" }}
                            />
                          </div> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default injectIntl(Devices);
