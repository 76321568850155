import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import lodash from "lodash";
import InlineLoader from "../../../components/InlineLoader";
import {
  fetchingShoppingSites,
  setShoppingSites,
  setShoppingSitesBannerData,
  fetchingShoppingSiteCategories,
  setShoppingSiteCategories,
  selectCategory,
  unselectCategory,
  fetchingNextPage,
  addShoppingSites,
  setShoppingListAfterBookmarkToggle,
  filterClicked,
  resetFilter,
} from "../actions";
import { FormattedMessage } from "react-intl";
import { onViewedPage } from "../../../utils/events";
import EmptyState from "../../../components/EmptyState";
import EmptyImage from "../../../assets/voucher/ic_no_result_found.svg"
import affiliateSearch from "../../../assets/voucher/ic_search.svg"
import customerCommonClientX from "../../../utils/api/customerCommonClientX";
import ShoppingSitesMetaData from "../../../seo/ShoppingSitesMetaData";
import ShoppingBannerCarousal from "./ShoppingBannerCarousal";
import ic_clear from '../../../assets/ic_clear.svg'
import { OverlayTrigger, Popover } from "react-bootstrap";
import ic_dropdown from '../../../assets/voucher/ic_dropdown.svg'
import HelmetMetaData from "../../../components/HelmetMetaData";
import { affiliateCategoryMetaContent } from "../components/AffiliateCategoryMetaContent";
import customerAffiliateClientX from "../../../utils/api/customerAffiliateClientX";


class ShoppingSitesList extends Component {

  state = {
    affiliateSearchParam: '',
    selectedShoppingSlug: this.props?.match?.params.id ? this.props?.match?.params?.id : null,
    selectedCategoryName: ""
  }
  componentDidMount() {
    if (this.props.match?.params?.id) {
        this.updateShoppingSiteFilter(this.props?.match?.params?.id)
    }
    else {
      this.props.initShoppingSites(this.props?.selectedCategories);
    }
    this.props.catogorySelect();
    this.props.initCategory();

    onViewedPage({
      page_name: "Shopping Site List Page",
    });

    window.addEventListener("scroll", this.onScroll, false);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll, false);
  }

  updateShoppingSiteFilter = (slug) => {
    this.props.onSelectCategory(slug);
    this.props.initShoppingSites(slug);
    this.setState({ selectedShoppingSlug: slug, affiliateSearchParam: "" });
    this.props.history.push(`/shopping-sites/category/${slug}`)
    this.getAffiliateCategoryNameBySlug(slug)
  };

  clearShoppingSiteFilter = () => {
    this.props.resetFilter();
    this.props.initShoppingSites(null)
    this.setState({ selectedShoppingSlug: null })
    this.setState({ affiliateSearchParam: "" })
    this.props.history.push(`/shopping-sites`)
  }

  searchShoppingSites = (event) => {
    let searchParam = event.target.value
    // this.clearShoppingSiteFilter()
    this.setState({ affiliateSearchParam: searchParam })
    searchParam.length >= 3 &&
      this.props.searchShoppingSites(searchParam, this.props.clearShoppingSiteFilter);
    if (searchParam.length === 0) {
      this.props.initShoppingSites(this.props.selectedCategories);
    }
  }
  
  getAffiliateCategoryNameBySlug = (slug) => {
    setTimeout(() => {
      this.props.categories &&
        // eslint-disable-next-line array-callback-return
        this.props.categories.filter((item) => {
          if (slug === item?.slug) {
            this.setState({ selectedCategoryName: item?.name })
          }
        });
    }, 100);
  }

  onScroll = () => {
    let height = 0
    window.innerWidth < 767 ? height = 1800 : height = 500

    if (this.props.isFetchingNextPage) {
      return;
    }

    if (!this.props.nextPage) {
      return;
    }

    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight - height &&
      this.props.shoppingSites &&
      this.state.affiliateSearchParam.length === 0
    ) {

      if (this.props.match?.params?.id) {
        this.props.onPaginatedSearch(
          this.props.match?.params?.id,
          this.props.nextPage
        );
      } else {
        this.props.onPaginatedSearch(
          this.props.selectedCategories,
          this.props.nextPage
        );
      }
    }
  };

  // onChange = (event, id) => {
  //   let categoryName = event.target.name
  //   if (event.target.checked) {
  //     this.props.onSelectCategory(id);
  //     onFilteredShoppingSites({ category: categoryName });
  //     return;
  //   }

  //   this.props.onUnselectCategory(id);
  // };

  componentDidUpdate(prevProps) {
    const currentId = this.props?.match?.params?.id;
    const prevId = prevProps?.match?.params?.id;
    if (currentId && currentId !== prevId) {
      this.props?.categories && this.updateShoppingSiteFilter(currentId);
    }
    else{
      if (this.props.match?.path === "/shopping-sites" ) {
        this.props.resetFilter();
        this.state.selectedShoppingSlug !== null && this.setState({ selectedShoppingSlug: null });
      }
    }

    if (this.props.match?.params?.id) {
      this.props?.categories && this.getAffiliateCategoryNameBySlug(this.props?.match?.params?.id);
    }
    if (!lodash.isEqual(
      this.props.selectedCategories,
      prevProps.selectedCategories)
    ) {
      this.props.initShoppingSites(this.props.selectedCategories);
    }
  };

  AffiliateCategoryMetaData = () => {
    let currentAppUrl = process.env.REACT_APP_URL + window.location.pathname;
    return (
      <HelmetMetaData
        title={affiliateCategoryMetaContent[this.state.selectedShoppingSlug]?.title}
        description={affiliateCategoryMetaContent[this.state.selectedShoppingSlug]?.description}
        keywords={affiliateCategoryMetaContent[this.state.selectedShoppingSlug]?.keywords}
        voiceSchema={<script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org/",
              "@type": "WebPage",
              "name": affiliateCategoryMetaContent[this.state.selectedShoppingSlug]?.title,
              "speakable":
              {
                "@type": "SpeakableSpecification",
                "xpath":
                  [
                    "/html/head/title",
                    "/html/head/meta[@name='description']/@content"
                  ]
              },
              "url": currentAppUrl
            }
          )}
        </script>
        }
        breadcrumbSchema={<script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org/",
              "@type": "BreadcrumbList",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://windsapp.com/"
              }, {
                "@type": "ListItem",
                "position": 2,
                "name": "Shopping Sites",
                "item": "https://windsapp.com/shopping-sites"
              }, {
                "@type": "ListItem",
                "position": 3,
                "name": `${this.state.selectedCategoryName}`,
                "item": currentAppUrl
              }]
            }
          )}
        </script>}
      ></HelmetMetaData>
    )
  }

  render() {
    return (
      <>
        {
          this.props?.match?.params?.id ?
            <this.AffiliateCategoryMetaData /> :
            <ShoppingSitesMetaData />
        }
        <main>
          <div className="container font-proxima-nova">
            <div className="row mt-2 mt-md-4">
              <div className="col-12 main-content">
                {this.props?.isFetchingShoppingSitesCategories ? <div className="box shine w-100 mb-3 mt-2" style={{ height: "300px" }}></div> : <><ShoppingBannerCarousal /></>}
                <div className="row mb-2 d-flex align-items-center">
                  <div className="pt-3 pt-md-5 row w-100 m-auto">
                    <div className="col-12 col-md-12">
                      <h1 className="voucher-brand">
                        {this.props?.match?.params?.id ?
                          `${this.state.selectedCategoryName} Category` :
                          <FormattedMessage
                            id="home.shopping-sites"
                            defaultMessage="Shopping Sites"
                          />
                        }
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="row mb-3 d-flex align-items-center justify-content-between">
                  <div className="col-12 col-md-5 mb-2 md-mb-0">
                    {this.state?.selectedShoppingSlug === null &&
                      <OverlayTrigger trigger={'click'} placement="bottom" rootClose overlay={
                        <Popover className="voucher-popover">
                          <div className="overlay-container">
                            <div className="row p-3 ">
                              {this.props?.isFetchingShoppingSitesCategories ?
                                <div className="col-12 shine box"></div> :
                                <>
                                  {this.props.categories.map((item, index) =>
                                    <div className="col-12 col-sm-6 col-md-4 col-lg-4 py-1 selected-category " key={index} onClick={() => this.updateShoppingSiteFilter(item?.slug)}><span >{item.name}</span></div>
                                  )}
                                </>
                              }
                            </div>
                          </div>
                        </Popover>
                      }>
                        <button variant="light" className="w-100 p-0 pr-md-1 d-flex justify-content-between align-items-center p-2" id="filter-dropdown"><span>Category</span><span className="pr-2"><img src={ic_dropdown} alt="dropdown-btn" title="dropdown-btn" style={{ width: "10px" }} /></span></button>
                      </OverlayTrigger>
                    }
                    {this.state?.selectedShoppingSlug !== null && <div className="filterkey-btn d-flex justify-content-between align-items-center px-2 w-100" ><span>{this.state?.selectedCategoryName}</span><span className="ml-2"><img src={ic_clear} alt="close-btn" title="close-btn" style={{ width: "10px" }} onClick={() => this.clearShoppingSiteFilter()} className="cursor-pointer" /></span></div>}
                  </div>
                  <div className="col-12 col-md-5">
                    <img className="shopping-search-img" src={affiliateSearch} alt="search" title="search" />
                    <input className="shopping-search w-100 mt-md-0 p-2 pl-5" type="text" placeholder="Search by your favourite brand names" value={this.state.affiliateSearchParam} onChange={(e) => this.searchShoppingSites(e)} />
                  </div>
                </div>
                {this.props.isFetchingShoppingSites ? (
                  <this.ShoppingSiteShimmer />
                ) : this.props.shoppingSites.length === 0 ? (
                  <this.ShoppingSiteEmptyState />
                ) : (
                  <this.ShoppingSites />
                  
                )}
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
  // FiltersBlock = (props) => {
  //   return (
  //     <React.Fragment>
  //       <div className="row mb-3 status border-bottom">
  //         <div className="col-6 pl-0">
  //           <span className="font-proxima-nova-bold h5">
  //             <FormattedMessage id="general.filters" defaultMessage="Filters" />
  //           </span>
  //         </div>
  //         <div className="col-6 pr-0">
  //           <button
  //             className="btn btn-outline float-right"
  //             onClick={this.props.resetFilter}
  //             style={{ color: "#087CD8", marginTop: "-0.5rem" }}
  //           >
  //             <FormattedMessage id="general.reset" defaultMessage="Reset" />
  //           </button>
  //         </div>
  //       </div>
  //       {/* <this.Filters /> */}
  //       {this.Filters()}
  //     </React.Fragment>
  //   );
  // };

  // Filters = (props) => (
  //   <React.Fragment>
  //     {this.props.isFetchingShoppingSitesCategories ? (
  //       <div className="row">
  //         <div className="col-md-12">
  //           <ContentLoader
  //             height={200}
  //             width={200}
  //             speed={2}
  //             primaryColor="#F6F7F8"
  //             secondaryColor="#ecebeb"
  //           >
  //             <rect x="0" y="0" rx="2" ry="2" width="132" height="16" />
  //             <rect x="180" y="0" rx="2" ry="2" width="16" height="16" />
  //             <rect x="0" y="24" rx="2" ry="2" width="132" height="16" />
  //             <rect x="0" y="48" rx="2" ry="2" width="132" height="16" />
  //             <rect x="0" y="72" rx="2" ry="2" width="132" height="16" />
  //             <rect x="0" y="96" rx="2" ry="2" width="132" height="16" />
  //             <rect x="0" y="120" rx="2" ry="2" width="132" height="16" />
  //             <rect x="0" y="144" rx="2" ry="2" width="132" height="16" />
  //             <rect x="0" y="168" rx="2" ry="2" width="132" height="16" />
  //             <rect x="180" y="24" rx="2" ry="2" width="16" height="16" />
  //             <rect x="180" y="48" rx="2" ry="2" width="16" height="16" />
  //             <rect x="180" y="72" rx="2" ry="2" width="16" height="16" />
  //             <rect x="180" y="96" rx="2" ry="2" width="16" height="16" />
  //             <rect x="180" y="120" rx="2" ry="2" width="16" height="16" />
  //             <rect x="180" y="144" rx="2" ry="2" width="16" height="16" />
  //             <rect x="180" y="168" rx="2" ry="2" width="16" height="16" />
  //           </ContentLoader>
  //         </div>
  //       </div>
  //     ) : (
  //       <div className="row mb-3 status">
  //         <p className="text-uppercase font-proxima-nova-bold">
  //           <FormattedMessage
  //             id="general.categories"
  //             defaultMessage="CATEGORY"
  //           />
  //         </p>
  //         <div className="col-12 filter-categories pr-0">
  //           <div
  //             style={{
  //               height: "450px",
  //               overflowY: "scroll",
  //               overflowX: "hidden",
  //               // borderBottom: "1px solid #dee2e6"
  //             }}
  //           >
  //             {this.props.categories.map((item, index) => {
  //               return (
  //                 <div className="row mt-3" key={"category-box-" + index}>
  //                   <div className="col-9">{item.name}</div>
  //                   <div className="col-3 mb-1 text-center">
  //                     <div className="custom-control custom-checkbox">
  //                       <input
  //                         type="checkbox"
  //                         className="custom-control-input"
  //                         checked={
  //                           lodash.indexOf(
  //                             this.props.selectedCategories,
  //                             item.id
  //                           ) > -1
  //                         }
  //                         onChange={(event) => {
  //                           this.onChange(event, item.id);
  //                         }}
  //                         name={item.name}
  //                         id={"shopping-sites-category-" + item.id}
  //                       />
  //                       <label
  //                         className="custom-control-label"
  //                         htmlFor={"shopping-sites-category-" + item.id}
  //                       ></label>
  //                     </div>
  //                   </div>
  //                 </div>
  //               );
  //             })}
  //           </div>
  //         </div>
  //       </div>
  //     )}
  //   </React.Fragment>
  // );

  ShoppingSiteShimmer = (props) => {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-6 col-md-3 col-lg-2" >
            <div className="box shine mb-3 mb-md-4" style={{ height: "250px" }}></div>
          </div>
          <div className="col-6  col-md-2 col-lg-2">
            <div className="box shine mb-3 mb-md-4" style={{ height: "250px" }}></div>
          </div>
          <div className="col-6  col-md-2 col-lg-2 d-none d-md-block">
            <div className="box shine mb-3 mb-md-4" style={{ height: "250px" }}></div>
          </div>
          <div className="col-6  col-md-2 col-lg-2 d-none d-md-block">
            <div className="box shine mb-3 mb-md-4" style={{ height: "250px" }}></div>
          </div>
          <div className="col-6  col-md-2 col-lg-2 d-none d-md-block">
            <div className="box shine mb-3 mb-md-4" style={{ height: "250px" }}></div>
          </div>
          <div className="col-6  col-md-2 col-lg-2 d-none d-md-block">
            <div className="box shine mb-3 mb-md-4" style={{ height: "250px" }}></div>
          </div>
        </div>
        <div className="row">
          <div className="col-6  col-md-2 col-lg-2" >
            <div className="box shine mb-3 mb-md-4" style={{ height: "250px" }}></div>
          </div>
          <div className="col-6  col-md-2 col-lg-2">
            <div className="box shine mb-3 mb-md-4" style={{ height: "250px" }}></div>
          </div>
          <div className="col-6  col-md-2 col-lg-2 d-none d-md-block">
            <div className="box shine mb-3 mb-md-4" style={{ height: "250px" }}></div>
          </div>
          <div className="col-6  col-md-2 col-lg-2 d-none d-md-block">
            <div className="box shine mb-3 mb-md-4" style={{ height: "250px" }}></div>
          </div>
          <div className="col-6  col-md-2 col-lg-2 d-none d-md-block">
            <div className="box shine mb-3 mb-md-4" style={{ height: "250px" }}></div>
          </div>
          <div className="col-6  col-md-2 col-lg-2 d-none d-md-block">
            <div className="box shine mb-3 mb-md-4" style={{ height: "250px" }}></div>
          </div>
        </div>
        <div className="row">
          <div className="col-6  col-md-2 col-lg-2" >
            <div className="box shine mb-3 mb-md-4" style={{ height: "250px" }}></div>
          </div>
          <div className="col-6  col-md-2 col-lg-2">
            <div className="box shine mb-3 mb-md-4" style={{ height: "250px" }}></div>
          </div>
          <div className="col-6  col-md-2 col-lg-2 d-none d-md-block">
            <div className="box shine mb-3 mb-md-4" style={{ height: "250px" }}></div>
          </div>
          <div className="col-6  col-md-2 col-lg-2 d-none d-md-block">
            <div className="box shine mb-3 mb-md-4" style={{ height: "250px" }}></div>
          </div>
          <div className="col-6  col-md-2 col-lg-2 d-none d-md-block">
            <div className="box shine mb-3 mb-md-4" style={{ height: "250px" }}></div>
          </div>
          <div className="col-6  col-md-2 col-lg-2 d-none d-md-block">
            <div className="box shine mb-3 mb-md-4" style={{ height: "250px" }}></div>
          </div>
        </div>

      </React.Fragment>
    );
  };

  ShoppingSiteEmptyState = (props) => {
    return (
      <div className="empty-state">
        <EmptyState
          title={
            <FormattedMessage
              id="general.no-shopping"
              defaultMessage="No Shopping Sites found."
            />
          }
          imageWidth={35}
          image={EmptyImage}
        />
      </div>
    );
  };

  toggleBookmark = (event, id, isBookmarked) => {
    event.preventDefault();
    event.stopPropagation();
    this.onToggleBookmark(
      id,
      isBookmarked,
      `/v1/shopping-sites/${id}/bookmark`,
      "shoppingSites"
    );
  };

  onToggleBookmark = (id, isBookmarked, url, type) =>
    !isBookmarked
      ? this.props.addBookmark(id, url, this.props.shoppingSites)
      : this.props.deleteBookmark(id, url, this.props.shoppingSites);

  ShoppingSites = (props) => {
    return (
      <React.Fragment>
        <div className="row">
          {this.props.shoppingSites.map((item, index) => {
            return (
              <div
                className="col-6 col-sm-4 col-md-3 col-lg-2"
                key={"shopping-site-card-" + index}
                style={{ height: "auto", overflow: "hidden" }}
              >
                <Link to={item?.slug ? `/shopping-sites/${item?.slug}` : `/shopping-sites/${item?.id}`}>
                  <div className="card card--shopping-site-logo mb-3 mb-md-4 shopping-card" >
                    <img src={item?.carousel_image_url} alt={item?.name} title={item?.name} className="w-100 p-1" />
                    {/* <div className="shopping-earn-badge w-100 d-flex justify-content-center"><p className="inner-text w-75">EARN {parseFloat(item.wrp_percentage).toFixed(0)}% WINDS Coins</p> </div> */}
                    {item?.offer_text !== "N/A" &&
                      <span className="shoping-badge-content"><p className="shopping-offer-badge m-0 px-2 py-1 col-10 pl-3">{item?.offer_text}</p ></span>
                    }
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
        <div className="row">
          <div className="col-12 text-center">
            {this.props.isFetchingNextPage && <InlineLoader />}
            {!this.props.nextPage && 0 !== this.props.shoppingSites.length && (
              <p className="font-weight-bold">No More Sites</p>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  };
}

function mapStateToProps(state) {
  return {
    isFetchingShoppingSitesCategories: state.shoppingSite.isFetchingShoppingSitesCategories,
    categories: state.shoppingSite.categories,
    selectedCategories: state.shoppingSite.selectedCategories,
    isFetchingShoppingSites: state.shoppingSite.isFetchingShoppingSites,
    shoppingSites: state.shoppingSite.shoppingSites,
    isFetchingNextPage: state.shoppingSite.isFetchingNextPage,
    nextPage: state.shoppingSite.nextPage,
    isFilterModelOpen: state.shoppingSite.isFilterModelOpen,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initCategory: () => {
      dispatch(fetchingShoppingSiteCategories());

      customerAffiliateClientX.get("api/v1/categories").then((response) => {
        let data = response.data.data;
        dispatch(setShoppingSiteCategories(data));
      });
    },
    initShoppingSites: (categories) => {
      dispatch(fetchingShoppingSites());

      customerAffiliateClientX
        .get("api/v1/affiliates", {
          params: {
            categories: categories,
            banner_platform: "web",
            banner_tags: "customer_web"
          },
        })
        .then((response) => {
          let data = response.data.data;
          let banner_data = response.data.banner_data;

          let nextPageURL =
            null !== response.data.links.next &&
            new URL(response.data.links.next);

          let nextPage =
            null !== response.data.links.next &&
            nextPageURL.searchParams.get("page");

          dispatch(setShoppingSites(data, nextPage));
          dispatch(setShoppingSitesBannerData(banner_data));
        });
    },
    searchShoppingSites: (searchParam, categories) => {
      dispatch(fetchingShoppingSites());
      customerCommonClientX
        .get(`v2/search/affiliate?limit=30&q=${searchParam}`)
        .then((response) => {
          let data = response.data.data;

          let nextPageURL =
            null !== response.data.links.next &&
            new URL(response.data.links.next);

          let nextPage =
            null !== response.data.links.next &&
            nextPageURL.searchParams.get("page");

          dispatch(setShoppingSites(data, nextPage));
        });
    },
    onFilterClick: () => {
      dispatch(filterClicked());
    },
    onSelectCategory: (id) => {
      dispatch(selectCategory(id));
    },
    onUnselectCategory: (id) => {
      dispatch(unselectCategory(id));
    },
    resetFilter: () => {
      dispatch(resetFilter());
    },
    onPaginatedSearch: (categories, nextPage) => {
      dispatch(fetchingNextPage());

      customerAffiliateClientX
        .get("api/v1/affiliates", {
          params: {
            categories: categories,
            page: nextPage,
          },
        })
        .then((response) => {
          let data = response.data.data;

          let nextPageURL =
            null !== response.data.links.next &&
            new URL(response.data.links.next);

          let nextPage =
            null !== response.data.links.next &&
            nextPageURL.searchParams.get("page");

          dispatch(addShoppingSites(data, nextPage));
        });
    },
    addBookmark: (id, url, ShoppingSitesList) => {
      customerAffiliateClientX
        .post(url, {})
        .then((response) => {
          dispatch(setShoppingListAfterBookmarkToggle(id, ShoppingSitesList));
        })
        .catch((error) => { });
    },

    deleteBookmark: (id, url, ShoppingSitesList) => {
      customerAffiliateClientX
        .delete(url)
        .then((response) => {
          dispatch(setShoppingListAfterBookmarkToggle(id, ShoppingSitesList));
        })
        .catch((error) => { });
    },
    catogorySelect: () => {
      dispatch(selectCategory(null));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingSitesList);
