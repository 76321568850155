import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import referralGif from '../../../assets/earning-through-referral.gif';
import earningApi from '../../../utils/api/earningsClient';
import { fetchingEarnings, fetchReferrals, setReferrals, setEarnings } from '../actions';
// import lodash from "lodash";
// import ContentLoader from "react-content-loader";
// import { setProfile, resetProfile } from '../../../actions/index'
// import ic_no_match_found from "../../../assets/ic_no_match_found.svg";
import Modal from "react-bootstrap/Modal";
import refferalImage from '../../../assets/refferal-info.gif';
import ic_handshake from '../../../assets/handshake.svg';
import { getIsMob, getIsKamaie } from "../../../utils/helper";
class Earnings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      earnings: [],
      referrals: [],
      showModal: false,
      isMob:0,
      is_kamaie:0
    };
  }

  componentDidMount() {
    const queryParameters = new URLSearchParams(window.location.search);
    this.setState({ isMob: getIsMob("isMob") });
    this.setState({is_kamaie:getIsKamaie("is_kamaie")})  
    if (!this.props.profile) {
     this.props.history.push('/login')
    }
    else{
      this.props.initEarnings(this.props.latitude, this.props.longitude);
      this.props.initReferrals();
    }
  }

  EarningsTotal = (props) => {
    const {
      customer_referral_earnings,
      customer_referral_earnings_provisional,
      customer_referral_earnings_mtd,
      customer_referral_earnings_today,
      customer_referral_earnings_ytd,
      referred_user_count
    } = this.props.totalEarnings || "";

    const handleClose = () => this.setState({ showModal: false });
    const handleShow = () => this.setState({ showModal: true });

    return (
      <React.Fragment>
        <>
          {
            this.props.isFetchingEarnings ? (
              <>
                <div className="rounded shine slider-shimmer"></div>
                <div className="rounded shine slider-shimmer mt-4" style={{ height: "100px" }}></div>
              </>
            ) : (
              <>
                <div className="border border-grey rounded p-3">
                  <p className="font-proxima-nova mb-2 small">
                    <FormattedMessage
                      id="earnings.earning_through_referal"
                      defaultMessage="Your Earnings Through Referral"
                    />
                  </p>
                  <h2 className='text-col-primary d-flex align-items-center h2 font-weight-bold'>
                    ₹ {customer_referral_earnings ? customer_referral_earnings : '0.00'}
                  </h2>
                  <hr className="my-2" />
                  <div className="d-flex align-items-center">
                    <span>
                      <FormattedMessage
                        id="earnings.today"
                        defaultMessage="Today"
                      />
                    </span>
                    <span className="ml-auto font-weight-bold">
                      ₹ {customer_referral_earnings_today ? customer_referral_earnings_today : '0.00'}
                    </span>
                  </div>
                  <hr className="my-2" />
                  <div className="d-flex align-items-center">
                    <span>MTD</span>
                    <span className="ml-auto font-weight-bold">
                      ₹ {customer_referral_earnings_mtd ? customer_referral_earnings_mtd : '0.00'}
                    </span>
                  </div>
                  <hr className="my-2" />
                  <div className="d-flex align-items-center">
                    <span>YTD</span>
                    <span className="ml-auto font-weight-bold">
                      ₹ {customer_referral_earnings_ytd ? customer_referral_earnings_ytd : '0.00'}
                    </span>
                  </div>
                  <hr className="my-2" />
                  <div className="d-flex align-items-center">
                    <div>
                      <small>
                        <FormattedMessage
                          id="earnings.provisional_earnings"
                          defaultMessage="Provisional Earnings"
                        />
                      </small>
                      <h3 className='text-warning pt-2 font-weight-bold'>
                        ₹ {customer_referral_earnings_provisional ? customer_referral_earnings_provisional : '0.00'}
                      </h3>
                    </div>
                    <div className='ml-auto text-lg-right'>
                      <small>
                        <FormattedMessage
                          id="earnings.confirmed_earnings"
                          defaultMessage="Confirmed Earnings"
                        />
                      </small>
                      <h3 className='text-col-primary pt-2 font-weight-bold'>
                        ₹ {customer_referral_earnings ? customer_referral_earnings : '0.00'}
                      </h3>
                    </div>
                  </div>
                  <hr className="my-2" />
                  <div className="pt-2 text-center">
                    {
                      customer_referral_earnings > 0.00 ? (
                        <>
                          <Link className="btn btn-primary px-5" to="/earnings/customer-commission" >View Details</Link>
                        </>
                      ) : (
                        <>
                          <button className="btn btn-primary px-5" onClick={handleShow}>
                            <FormattedMessage
                              id="wrp.view-detail"
                              defaultMessage="View Details"
                            />
                          </button>
                        </>
                      )
                    }
                  </div>

                  <Modal
                    show={this.state.showModal}
                    onHide={handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Body>
                      <div className="earning-low">
                        <button className="btn-close" onClick={handleClose}>
                          <i className="fas fa-times"></i>
                        </button>
                        <img src={refferalImage} alt="refferalImage" title="refferalImage" />
                        <h5 className="mb-0">
                          <FormattedMessage
                            id="earnings.referral_earnings_low_text"
                            defaultMessage="Earnings still low? Get your friends to transact on WINDS and watch it grow!"
                          />
                        </h5>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>

                <div className="my-referrals">
                  <img src={ic_handshake} alt="handshake" title="handshake" width="80px" />
                  <div className="ml-4">
                    <p>
                      <FormattedMessage
                        id="earnings.my-referals"
                        defaultMessage="My Referrals"
                      />
                    </p>
                    <h3>{referred_user_count ? referred_user_count : "0"}</h3>
                  </div>
                  {
                    referred_user_count ? (
                      <>
                        <Link to="/referral-list" className="btn btn-sm btn-outline-primary ml-auto">
                          <FormattedMessage
                            id="earnings.view-all-referrals"
                            defaultMessage="View All Referrals"
                          />
                        </Link>
                      </>
                    ) : (
                      <>
                        <Link to="/refer-and-earn" className="btn btn-sm btn-outline-primary ml-auto">
                          <FormattedMessage
                            id="navbar.refer-and-earn"
                            defaultMessage="Refer & Earn"
                          />
                        </Link>
                      </>
                    )
                  }
                </div>
              </>
            )
          }
        </>
      </React.Fragment>
    )
  }

  render() {
    return (
      <React.Fragment>
        <div className="container">
          <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
            <div className="col-12">
              <div className="row align-items-center">
                {(this.state.isMob !=1 && this.state.is_kamaie !=1)&&(
                  <div className="col-12">
                  <small>
                    <Link to="/">
                      <FormattedMessage
                        id="breadcrumbs.home"
                        defaultMessage="Home"
                      />
                    </Link>{" "}
                    /{" "}
                    <strong>
                      <FormattedMessage
                        id="navbar.my-earnings"
                        defaultMessage="My Earnings"
                      />
                    </strong>
                  </small>
                </div>
                )}
                
              </div>
            </div>
          </div>

          <div className="row mb-5 align-items-center">
            <div className="col-12 col-md-6">
              <img src={referralGif} alt="referralGif" title="referralGif" className="mx-auto d-block w-100" />
            </div>
            <div className="col-12 col-md-6 pr-lg-5">
              <this.EarningsTotal />
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    latitude: state.location.latitude,
    longitude: state.location.longitude,
    isFetchingEarnings: state.earnings.isFetchingEarnings,
    totalEarnings: state.earnings.totalEarnings,
    profile: state.global.profile,
    referrals: state.earnings.referrals,
    location: state.location
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initEarnings: (latitude, longitude) => {
      dispatch(fetchingEarnings());
      earningApi.get(`/v2/dashboard`)
        .then((response) => {
          let data = response.data.data.customer_order_commission;
          dispatch(setEarnings(data));
        });
      return;
    },

    initReferrals: () => {
      dispatch(fetchReferrals());
      earningApi.get(`/v2/customer-referrals`).then((response) => {
        let data = response.data;
        dispatch(setReferrals(data));
      });
      return;
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Earnings);