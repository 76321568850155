import React from "react";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";

const LoginDialog = (props) => {
  let history = useHistory();
  return (
    <React.Fragment>
      <Modal
        show={props.isLoginDialogOpen}
        onHide={props.closeLoginDialog}
        className="font-proxima-nova"
        centered
      >
        <Modal.Body className="p-5">
          <h5 className="text-center pb-3">Please Login to WINDS!<br />
            Don't have an account? Sign Up</h5>
          <div className="d-flex flex-row justify-content-between w-100 ">
             {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className="w-50 mx-2" onClick={()=>history.push('/login')}>
              <button className="animated-btn py-1 px-5 " style={{ height: "40px" }}>Login</button>
            </a>
            <button className="animated-btn-border py-1 px-5 mx-2 w-50" style={{ height: "40px" }} onClick={props.closeLoginDialog}>Later</button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default LoginDialog;
