import CleverTap from "../utils/cleverTap";

export function onPushProfile(data, location) {
  CleverTap.pushUserLogin({
    Site: {
      Name: data.name,
      Email: data.email? data.email : undefined,
      Phone: `+91${data.mobile}`,
      City: location ? location: undefined,
      Gender: data.gender? data.gender.slice(0,1): undefined,
      DOB: data.dob? data.dob: undefined,
      Location: data.state_name? data.state_name : undefined,
      Identity: `+91${data.mobile}`,
      Avatar: data.profile_pic_url? data.profile_pic_url: undefined,
      Photo: data.profile_pic_url? data.profile_pic_url: undefined,
      Language: data.language,
      Referral_code: data.referral_code? data.referral_code: undefined,
      Referral_link: data.referral_link? data.referral_link: undefined,
      State: data.state_name? data.state_name: undefined,
    },
  });
  // CleverTap.profile({
  //   // Site: {
  //     Name: data.name,
  //     Email: data.email? data.email : undefined,
  //     Phone: `+91${data.mobile}`,
  //     Gender: data.gender? data.gender.slice(0,1): undefined,
  //     DOB: data.dob? data.dob: undefined,
  //     Location: data.state_name? data.state_name : undefined,
  //     Identity: data.id,
  //     empty:"dd"
  //   // },
  // });
  window.analytics.identify(data.id, {
    name: data.name,
    email: data.email,
  });
  window.analytics.track("Logged In", {
    name: data.name,
    email: data.email,
  });
}

export function onGenericAction(eventName) {
  CleverTap.event(eventName);
  window.analytics.track(eventName);
}

export function onViewedPage(data) {
  CleverTap.event("Viewed Page", {
    "Page Name": data.page_name,
  });
  window.analytics.page(data.page_name);
}

export function onReferred() {
  CleverTap.event("Referred");
}

export function onViewedProduct(data) {
  CleverTap.event("Viewed Product", data);
  window.analytics.track("Viewed Product", data);
}

export function onPurchaseInitiated(data) {
  CleverTap.event("Purchase Initiated", {
    Category: data.category,
    "Product Name": data.productName,
    Amount: data.amount,
    Quantity: data.quantity,
  });
  window.analytics.track("Purchase Initiated", {
    Category: data.category,
    "Product Name": data.productName,
    Amount: data.amount,
    Quantity: data.quantity,
  });
}

export function onCharged(data) {
  CleverTap.event("Charged", {
    Category: data.category,
    "Product Name": data.productName,
    Amount: data.amount,
    Quantity: data.quantity,
  });
  window.analytics.track("Charged", {
    Category: data.category,
    "Product Name": data.productName,
    Amount: data.amount,
    Quantity: data.quantity,
  });
}

export function onBannerClicked(data) {
  CleverTap.event("Banner Click", data);
  window.analytics.track("Banner Click", data);
}

export function onBillUploaded(data) {
  CleverTap.event("Uploaded Bill", data);
  window.analytics.track("Uploaded Bill", data);
}

export function onVisitedAffiliateSite(data) {
  CleverTap.event("Visited Affiliate Site", data);
  window.analytics.track("Visited Affiliate Site", data);
}

export function onFilteredShoppingSites(data) {
  CleverTap.event("Filtered Shopping Sites", { Category: data.category });
  window.analytics.track("Filtered Shopping Sites", {
    Category: data.category,
  });
}

export function onFilteredVouchers(data) {
  CleverTap.event("Filtered Vouchers", { Category: data.category });
  window.analytics.track("Filtered Vouchers", { Category: data.category });
}

export function onFilteredPartners(data) {
  const payload = {
    Rating: data.rating || "",
    Distance: data.distance || "",
    Category: data.category || "",
  };
  CleverTap.event(
    "Filtered Partners",
    Object.fromEntries(Object.entries(payload).filter(([key, value]) => value))
  );
  window.analytics.track(
    "Filtered Partners",
    Object.fromEntries(Object.entries(payload).filter(([key, value]) => value))
  );
}

export function onFilteredOffers(data) {
  CleverTap.event("Filtered Offers", { Category: data.category });
  window.analytics.track("Filtered Offers", { Category: data.category });
}

export function onSearched(data) {
  CleverTap.event("Searched", {
    Category: data.category,
    Keyword: data.keyword,
  });
  window.analytics.track("Searched", {
    Category: data.category,
    Keyword: data.keyword,
  });
}

export function onReferral(data) { }

export function onLogOutCleverTap(data) {
  CleverTap.event("Logged out",{
    Category: "Logout",
  });
  window.analytics.reset();
}
