import pdfEn from "../../../assets/magazine/Winds-Times-English.pdf"
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import { useState } from "react";

function WindsTimesEN() {
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <>
      <div className="container">
        <Document file={pdfEn} onLoadSuccess={onDocumentLoadSuccess} loading="loading">
          {/* eslint-disable-next-line array-callback-return */}
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(page => {
            if (page <= numPages)
              return (
                <Page pageNumber={page} />
              )
          })}
        </Document>
      </div>
    </>
  );
}

export default WindsTimesEN;




// import pdfEn from "../../../assets/magazine/Winds-Times-English.pdf"
// import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
// import { useState } from "react";
// import left from "../../../assets/img/site-captain/left.png"
// import right from "../../../assets/img/site-captain/right.png"

// function WindsTimesEN() {
//   const [numPages, setNumPages] = useState();
//   const [pageNumber, setPageNumber] = useState(1);
//   const [renderNavButtons, setRenderNavButtons] = useState(false);
//   const onSuccess = () => {
//     setPageNumber(1);
//     setRenderNavButtons(true);
//   }

//   const changePage = (offset) => {
//     setPageNumber(prevPageNumber => prevPageNumber + offset);
//   }
//   const previousPage = () => { changePage(-1); }
//   const nextPage = () => { changePage(+1); }


//   return (
//     <>
//       <div className="container">
//         <Document
//           file={pdfEn}
//           onLoadSuccess={({ numPages }) => {
//             setNumPages(numPages);
//             setRenderNavButtons(true);
//             onSuccess();
//           }}
//         >
//           <Page pageNumber={pageNumber} />
//           {renderNavButtons &&
//             <div className="pdf-buttons">
//               <img src={left} width="15px" alt="arrow" className={pageNumber === 1 ? `pdf-btn-disable` : ''}
//                 onClick={previousPage}
//               />

//               {"  "}
//               {pageNumber} of {numPages}
//               <img src={right} width="15px" alt="arrow" className={pageNumber === numPages ? `pdf-btn-disable` : ''}
//                 onClick={nextPage}
//               />

//             </div>}
//         </Document>
//       </div>
//     </>
//   );
// }

// export default WindsTimesEN;