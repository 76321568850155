import React from "react";
import { Card, Button, Accordion } from "react-bootstrap";
import arrow from "../../assets/img/about/arrow.png"
import spinWheel from "../../assets/img/site-patrons/ic_spin_wheel.svg"
import monthlyDraw from "../../assets/img/site-patrons/ic_reward.svg"
// import megaMonthly from "../../assets/img/site-patrons/ic_mega_monthly_draw.svg"
import vouchers from "../../assets/img/site-patrons/ic_purchase_vouchers.svg"
import payBills from "../../assets/img/site-patrons/ic_pay_bills.svg"
import getoffers from "../../assets/img/site-patrons/ic_best_offer_near_you.svg"
import earn from "../../assets/img/site-patrons/ic_earn.svg"
import { FormattedMessage } from "react-intl";

const PatronApp = () => {
  return (
    <>
      <section className="about-sec-1 patrons site-page site-top">
        <div className="container font-proxima-nova">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <p className="above-title"><FormattedMessage id="patrons.section1.abouve-title" /> </p>
              <h1 className="title-captain"><FormattedMessage id="patrons.section1.title" /> </h1>
              <p className="desc"><FormattedMessage id="patrons.section1.desc" /></p>
              <h2 className="mt-4 mb-3 download-patron"><FormattedMessage id="patrons.section1.subtitle" /></h2>
              <div className="paystore">
                <a href="https://winds.to/app" target="_blank" rel="noreferrer">
                  <img src={require('../../assets/img/site-patrons/ic_google_pay.png')} alt="icon" title="icon"/>
                </a>
                <a href="https://winds.to/app" target="_blank" rel="noreferrer">
                  <img src={require('../../assets/img/site-patrons/ic_app_store.png')} alt="Patron app" title="Patron app" />
                </a>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <img className="ab-image mt-5 mt-md-0" src={require('../../assets/img/site-patrons/ic_ptron_girl.png')} alt="Patron app" title="Patron app" />
            </div>
          </div>
        </div>
      </section>
      <section className="about-sec-2 bg site-page">
        <div className="container font-proxima-nova">
          <div className="row reverse-col">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center align-items-center">
              <img width="100%" src={require('../../assets/img/site-patrons/ic_benifits_mockup.png')} alt="Patron app" title="Patron app" className="pr-md-5 pr-0 mt-md-0 mt-3 w-75" />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <h1 className="title-captain"><FormattedMessage id="patrons.section2.title" /></h1>
              <p className="desc"><FormattedMessage id="patrons.section2.desc" /></p>
              <Accordion defaultActiveKey="0">
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                      <img className="mission-img" style={{ width: "25px", marginRight: "13px" }} src={spinWheel} alt="spinwheel" title="spin wheel" />
                      <FormattedMessage id="patrons.section2.spin-wheel" />
                      <img src={arrow} width="15" style={{ marginLeft: "5px" }} alt="arrow" title="arrow" />
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <p className="desc"><FormattedMessage id="patrons.section2.spin-wheel-desc" /></p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="1"  >
                      <img className="mission-img" src={monthlyDraw} alt="monthlydraw" title="monthlydraw" />
                      <FormattedMessage id="patrons.section2.mini-monthly-draw" />
                      <img src={arrow} width="15" style={{ marginLeft: "5px" }} alt="arrow" title="arrow"/>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <p className="desc"><FormattedMessage id="patrons.section2.mini-monthly-draw-desc" /></p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                {/* <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="2">
                      <img className="mission-img" src={megaMonthly} alt="megamonthly" />
                      <FormattedMessage id="patrons.section2.mega-monthly-draw" />
                      <img src={arrow} width="15" style={{ marginLeft: "5px" }} alt="arrow" />
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body>
                      <p className="desc"><FormattedMessage id="patrons.section2.mega-monthly-draw-desc" /></p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card> */}
              </Accordion>
            </div>
          </div>
        </div>
      </section>
      <section className="about-sec-3patrons site-page">
        <div className="container font-proxima-nova">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 d-flex flex-column justify-content-center ">
              <h1 className="title-captain"><FormattedMessage id="patrons.section3.title" /></h1>
              <p className="desc"><FormattedMessage id="patrons.section3.desc1" /></p>
              {/* <p className="desc"><FormattedMessage id="patrons.section3.desc2" /></p> */}
            </div>
            <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 d-flex flex-column justify-content-center">
              <img width="100%" src={require('../../assets/img/site-patrons/ic_referral_program.png')} alt="Patron app" title="Patron app" />
            </div>
          </div>
        </div>
      </section>
      <section className="about-sec-4 site-page">
        <div className="container font-proxima-nova">
          <div className="row reverse-col">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <img width="100%" src={require('../../assets/img/site-patrons/ic_app_features.png')} alt="Patron app" title="Patron app" className="pr-md-5 pr-0 mt-md-0 mt-3" />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <h1 className="title-captain"><FormattedMessage id="patrons.section4.title" /></h1>
              <Accordion defaultActiveKey="0">
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                      <img className="mission-img" src={vouchers} alt="vouchers" title="vouchers" />
                      <FormattedMessage id="patrons.section4.purchase-vouchers" />
                      <img src={arrow} width="15" style={{ marginLeft: "5px" }} alt="arrow" title="arrow" />
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <p className="desc"><FormattedMessage id="patrons.section4.purchase-vouchers-desc1" /></p>
                      <p className="desc"><FormattedMessage id="patrons.section4.purchase-vouchers-desc2" /></p>
                      <p className="desc"><FormattedMessage id="patrons.section4.purchase-vouchers-desc3" /></p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                      <img className="mission-img" src={payBills} alt="paybills" title="paybills" />
                      <FormattedMessage id="patrons.section4.pay-all-bills" />
                      <img src={arrow} width="15" style={{ marginLeft: "5px" }} alt="arrow" title="arrow" />
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <p className="desc"><FormattedMessage id="patrons.section4.pay-all-bills-desc1" /></p>
                      <p className="desc"><FormattedMessage id="patrons.section4.pay-all-bills-desc2" /></p>
                      <p className="desc"><FormattedMessage id="patrons.section4.pay-all-bills-desc3" /></p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="2">
                      <img className="mission-img" src={getoffers} alt="getoffers" title="getoffers" />
                      <FormattedMessage id="patrons.section4.get-the-best-offers" />
                      <img src={arrow} width="15" style={{ marginLeft: "5px" }} alt="arrow" title="arrow" />
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body>
                      <p className="desc"><FormattedMessage id="patrons.section4.get-the-best-offers-desc1" /></p>
                      <p className="desc"><FormattedMessage id="patrons.section4.get-the-best-offers-desc2" /></p>
                      {/* <p className="desc"><FormattedMessage id="patrons.section4.get-the-best-offers-desc3" /></p> */}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="3">
                      <img className="mission-img" src={earn} alt="earn" title="earn" />
                      <FormattedMessage id="patrons.section4.earn-when-friends-shop" />
                      <img src={arrow} width="15" style={{ marginLeft: "5px" }} alt="arrow" title="arrow" />
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="3">
                    <Card.Body>
                      <p className="desc"><FormattedMessage id="patrons.section4.earn-when-friends-shop-desc" /></p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default PatronApp;
