import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { OrderStatusLabel } from "../../../utils/helper";
import customerCommonClientX from '../../../utils/api/customerCommonClientX'
import "react-toastify/dist/ReactToastify.css";
import icRow from "../../../assets/orders/ic_arrow.png"
import Lottie from "lottie-react";
import EarnReward from "../../../assets/orders/ic_trophy.json";
import EarnedReward from "../../../assets/orders/ic_won_cash.json";
import supportImg from "../../../assets/billpay/ic_support.svg";
import ic_rupee_symbol from "../../../assets/billpay/ic_rupee_symbol.svg";
import wrpCoin from "../../../assets/billpay/ic_wrp_coin.svg";
import ic_reward from '../../../assets/orders/ic_reward_coin.png'
import ic_dropdown from '../../../assets/orders/dropdown.png'
import ic_voucher from '../../../assets/orders/ic_voucher.png'
import ic_redeem from "../../../assets/orders/ic_redirect.svg"


export default class OrderDetail extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      order: null,
      bgColor: "",
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  componentDidMount() {
    this._isMounted = true;
    const {
      match: { params },
    } = this.props;

      customerCommonClientX.get(`v1/winds-kart-orders/${params.id}`).then((response) => {
      let data = response.data.data;
      if (this._isMounted) {
        this.setState({
          order: data,
        });
        this.OrderStatusLabel(data)
      }
    });
  }

  
  OrderStatusLabel = (order) => {
    if (order.fulfilled || order.status_code === 6 || order.status_code === 7)
      this.setState({ bgColor: "#11CE86" })
    else if ((!order.fulfilled && order.payment_failed) || order.status_code === 4)
      this.setState({ bgColor: "#ED2B2A" })
    else
      this.setState({ bgColor: "#ffc107" })
  }


  viewOrderDetails = (url) => {
    window.open(url);
  };

  Shimmer = (props) => {
    return (
      <div className="container py-3 py-md-5">
        <div className="row">
          <div className="col-12 col-md-8 mx-auto">
            <div className="box shine box-voucher-detail-logo"></div>
          </div>
        </div>
      </div>
    );
  };

  OrderMessages = (order) => {
    return (
      order && order.wrp_display_string ? (
        <div className="list-group-item d-flex justify-content-center" style={{ background: "linear-gradient(90deg, #FFF5F4 0%, #FFE8E6 100%)" }}>
          <img src={wrpCoin} alt="wrpCoin" title="wrpCoin" />
          <p className="mb-0 ml-2">
            <strong>{order.wrp_display_string}</strong>
          </p>
        </div>
      ) : null
    );
  }


  OrderSummary = () => {
    const { order } = this.state
    return (
      <div className="row mb-3 mb-md-4">
        <div className="col-md-8 mx-auto">
          <div className="p-4" style={{ background: "#F4F4FF", borderRadius: "15px" }}>
            <div className="billpay-data position-relative">
              <h5 className="mb-3 font-weight-bold">Order Summary</h5>
              <hr className="w-100"></hr>
              <div className="d-flex justify-content-between">
                <p className="mb-1">Amount Paid</p>
                <p className="mb-1 font-weight-bold">₹ {(order.paid_amount - order.processing_fees).toFixed(2)}</p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="mb-1">Processing Charge</p>
                <p className="mb-1 font-weight-bold">₹ {order.processing_fees?.toFixed(2)}</p>
              </div>
              <hr className="w-100"></hr>
              <div className="d-flex justify-content-between">
                <h5 className="font-weight-bold mb-1">Total</h5>
                <h5 className="mb-1 font-weight-bold">₹ {order.paid_amount?.toFixed(2)}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  Rewards = () => {
    const { order } = this.state
    return (
      <div className="row mb-3 mb-md-5">
        <div className="col-md-3 col-12 mx-auto">
          <button
            style={{ borderRadius: "30px" }}
            className={"btn billpay-order-btn w-100"}
            onClick={() => this.props.history.push("/rewards")}
          >
            <img src={ic_reward} alt="reward" title="reward" className="mr-2" width="30px" />
            Rewards
            {
              order?.pending_rewards > 0 &&
              <span className="ml-2" style={{ background: "#FF0000", padding: "2px 10px", borderRadius: "20px", minWidth: "30px" }}>{order?.pending_rewards.toString().padStart(2, '0')}</span>
            }
            <img src={ic_dropdown} alt="reward" title="reward" className="ml-2" width="10px" />
          </button>
        </div>
      </div>
    )
  }

  render() {
    const {
      order,
    } = this.state;
    return (
      order === null ?
        <this.Shimmer />
        :
        null !== order && (

          <React.Fragment>
            <div className="container">
              <div className="row mt-3 mb-3 mt-md-4 mb-md-4 align-items-center">
                <div className="col-md-8 mx-auto">
                  <small>
                    <Link to="/">
                      <FormattedMessage
                        id="breadcrumbs.home"
                        defaultMessage="Home"
                      />
                    </Link>
                  </small>{" "}
                  /{" "}
                  <small>
                    <Link to="/orders">
                      <FormattedMessage id="navbar.orders" />
                    </Link>
                  </small>{" "}
                  /{" "}
                  <small>
                    <strong>
                      <FormattedMessage id="orders.order" />
                    </strong>
                  </small>
                </div>
              </div>
              <div className="row ">
                <div className="col-md-8 mx-auto">
                  <div className="list-group">
                    <div className="list-group-item text-center" style={{ background: this.state.bgColor }}>
                      <p className="mb-0 text-white">
                        <PaymentStatusLabel order={order} />
                      </p>
                    </div>
                    <div className="list-group-item d-flex justify-content-between align-items-center">
                      <p className="d-flex align-items-center mb-0">
                        <span className="billpay-order-img-box">
                          <img src={ic_voucher} alt="voucher" title="voucher" width="75%" />
                        </span>
                        <p style={{ color: "#4354FF" }} className="h5 mb-0"><strong>{order.product_name}</strong></p>
                      </p>
                      <Link to="/contact-us"><img src={supportImg} alt="supportImg" title="supportImg" /></Link>
                    </div>
                    <div className="list-group-item d-flex justify-content-between align-items-center">
                      <div>
                        <p className="mb-1">Amount</p>
                        <h5 className="d-flex align-items-center mb-0">
                          <img src={ic_rupee_symbol} alt="rupee_symbol" title="rupee_symbol" width="30px" className="mr-2" />
                          <strong>{order.amount?.toFixed(2)}</strong>
                        </h5>
                      </div>
                    </div>
                    <div className="list-group-item ">
                      <div className="row align-items-center">
                        <div className="col-md-6">
                          <p className="mb-0">Order Status</p>
                          <OrderStatusLabel order={order} />
                        </div>
                        <div className="col-md-6 text-md-right">
                          <p className="mb-0">
                            <FormattedMessage id="orders.your-order-number-is" />{" "}
                            <strong>{order.order_number}</strong>
                          </p>

                          <p className="mb-0">{order.created_at}</p>
                        </div>
                      </div>
                    </div>
                    {this.OrderMessages(order)}
                  </div>
                </div>
              </div>
              <div className="row mb-3 mb-md-4">
                <div className="col-12 col-md-4 mx-auto">
                      {order.order_status_url && (
                        <button
                          className="btn mt-3 billpay-order-btn w-100"
                          onClick={() =>
                            this.viewOrderDetails(order.order_status_url)
                          }
                        >
                          <img src={ic_redeem} alt="invoice" title="invoice" className="mr-2" width="15px" />
                          VIEW ORDER DETAILS
                        </button>
                      )}
                    </div>
              </div>

              {order.won_reward === false ?
                null :
                order.rewards_cliamed === false ?
                  claimNow(order) : claimed(order)
              }

              {/* <this.OrderSummary />
              <this.Rewards /> */}
            </div>
          </React.Fragment>
        )

    );

  }
}


const claimNow = (order) => {
  return (
    <div className="row">
      <div className="col-md-8 mx-auto">
        <Link to="/rewards">
          <div className="row mb-4 order-reward-earned">
            <div className="col-9 centered">
              <h3>You have earned the reward on this transaction!</h3>
              <span className="flex-icon">CLAIM NOW
                <img style={{ marginLeft: "5px" }} src={icRow} alt="claim now" title="claim now" width="30px" />
              </span>
            </div>
            <div className="col-3">
              <Lottie animationData={EarnReward} loop={true} width="50" />
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}


const claimed = (order) => {
  return (
    <div className="row">
      <div className="col-md-8 mx-auto">
        <Link to="/rewards">
          <div className="row mb-4 order-reward-earned">
            <div className="col-9 centered">
              <h3>You have won {order.reward_label} as Rewards for this transaction!</h3>
            </div>
            <div className="col-3">
              <Lottie animationData={EarnedReward} loop={true} width="50" />
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}

function PaymentStatusLabel(props) {
  const status = props.order.status;
  switch (status) {
    case 0:
      return (
        <React.Fragment>
          <FormattedMessage
            id="order.payment-of-rs"
            defaultMessage="Payment of ₹"
          />{" "}
          <strong>{props.order.amount.toFixed(2)}</strong>{" "}
          <FormattedMessage
            id="order.payment-pending"
            defaultMessage="is Pending"
          />
        </React.Fragment>
      );
    case 1:
    case 8:
      return (
        <React.Fragment>
          <FormattedMessage
            id="order.payment-of-rs"
            defaultMessage="Payment of ₹"
          />{" "}
          <strong>{props.order.amount.toFixed(2)}</strong>{" "}
          <FormattedMessage
            id="order.payment-failed"
            defaultMessage="is Failed"
          />
        </React.Fragment>
      );
    case 6:
      return (
        <React.Fragment>
          <FormattedMessage
            id="order.payment-of-rs"
            defaultMessage="Payment of ₹"
          />{" "}
          <strong>{props.order.amount?.toFixed(2)}</strong>{" "}
          <FormattedMessage
            id="order.has-been-refunded"
            defaultMessage="has been refunded!"
          />
        </React.Fragment>
      );
    case 4:
      return (
        <React.Fragment>
          Fulfillment of Rs. {" "}
          <strong>{props.order.amount.toFixed(2)}</strong>{" "}
          has failed.
        </React.Fragment>
      );
    default:
      return (
        <React.Fragment>
          <FormattedMessage
            id="order.payment-of-rs"
            defaultMessage="Payment of ₹"
          />{" "}
          <strong>{props.order.amount.toFixed(2)}</strong>{" "}
          <FormattedMessage
            id="order.payment-success"
            defaultMessage="is Successful"
          />
        </React.Fragment>
      );
  }
}