import React, { Component } from "react";
import { connect } from "react-redux";
import { setLocation, setFormattedAddress, setFormattedLocation } from "../actions";
import { getGeoLocation, getAddress, getPlace, getPlaceById, setUserAccessDenied } from "../utils/GeoLocation";
import { FormattedMessage, injectIntl } from "react-intl";
import SearchLocationDialogInput from "./SearchLocationDialog";
import locationPointer from "../assets/header/ic_location.svg"

class LocationAddOn extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: false,
      predictions: []
    };

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };

  }

  componentDidMount() {
    this.props.onInit();
  }

  onSelectingAddress = (formatted_address, lat, lng) => {
    this.setState({ show: false });
    this.props.onAddressSelected(formatted_address, lat, lng)
  }

  onSelectingMyLocation = () => {
    this.props.onInit();
    this.setState({ show: false });
  }

  onSearch = (event) => {
    if (event.target.value.length < 3) {
      return;
    }
    getPlace(event.target.value).then((response) => {
      this.setState({
        predictions: response.data.predictions,
      });
    });
  };

  onSelectResult = (placesId) => {
    getPlaceById(placesId).then((response) => {
      this.setState({
        predictions: [],
      },
        () => {
          this.props.onPlaceSelect(response.data.result);
          this.setState({ show: false });
        }
      );
    });
  };

  render() {
    return (
      <React.Fragment>
        <span className="d-flex align-items-center mw-100" onClick={this.handleShow} >
          <img src={locationPointer} width="20px" className="mr-1" alt="map" title="map"/>
          <span
            className="location-name"
            style={{
              display: "block",
              width: "100%",
              maxWidth: "100%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontSize: "14px",
              verticalAlign: "middle",
            }}
          >
            {null === this.props.formatted_address ? (
              <FormattedMessage
                id="navbar.fetching"
                defaultMessage="Fetching..."
                description="Fetching..."
                values={{ what: "react-intl" }}
              />
            ) : (
              this.props.formatted_address
            )}
          </span>
        </span>
        {
          this.state.show &&
          <SearchLocationDialogInput close={this.handleHide}
            onInit={this.onSelectingMyLocation}
            onSelectingAddress={(formatted_address, lat, lng) => this.onSelectingAddress(formatted_address, lat, lng)} />
        }
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    formatted_address: state.location.formatted_address,
    errorCode: state.location.errorCode,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onInit: () => {
      getGeoLocation()
        .then((geoLocation) => {
          dispatch(setLocation(geoLocation.latitude, geoLocation.longitude));
          return getAddress(geoLocation.latitude, geoLocation.longitude);
        })
        .then((response) => {
          if (response.data.results && response.data.results.length > 0) {
            dispatch(
              setFormattedAddress(response.data.results[0].formatted_address)
            );
            // eslint-disable-next-line array-callback-return
            response.data.results[0].address_components.map(loc => {
              if (loc.types.includes("sublocality_level_1")) {
                dispatch(setFormattedLocation(loc.long_name));
              }
            })
          } else {
            // alert(response.data.error_message)
          }
        })
        .catch((error) => {
          if (error.code === 1) {
            dispatch(setUserAccessDenied(error.code));
          }
        });
    },

    onAddressSelected: (formatted_address, lat, lng) => {
      dispatch(
        setLocation(lat, lng)
      );
      dispatch(setFormattedAddress(formatted_address));
    },

    onPlaceSelect: (place) => {
      dispatch(
        setLocation(place.geometry.location.lat, place.geometry.location.lng)
      );
      dispatch(setFormattedAddress(place.formatted_address));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(LocationAddOn));
