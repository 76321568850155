export function setRepeatData(repeatData){
    return {
        type: 'SET_REPEAT_DATA',
        repeatData
    }
}

export function billPayCart(billPayForm){
    return {
        type: 'BILL_PAY_CART',
        billPayForm
    }
}

export function billPay(billPayPayload){
    return {
        type: 'BILL_PAY_PAYLOAD',
        billPayPayload
    }
}

export function setBillpayAPIVersion(billpayAPIVersion){
    return {
        type: 'SET_BILL_PAY_API_VERSION',
        billpayAPIVersion
    }
}

export function billPayServicesLoading(toggle){
    return {
        type: 'BILL_PAY_SERVICES_LOADING',
        billPayServicesLoading: toggle
    }
}

export function billPayServices(billPayServices){
    return {
        type: 'BILL_PAY_SERVICES',
        billPayServices
    }
}

export function setProvider(provider){
    return {
        type: 'SET_BILLPAY_PROVIDER',
        provider
    }
}
