import React, { useState, useEffect } from 'react'
import TextField, { HelperText, Input } from '@material/react-text-field';
import Select, { Option } from '@material/react-select';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import profileClientX from '../../../utils/api/profileClientX';
import InlineLoader from '../../../components/InlineLoader';
import { resetProfile, setProfile } from '../../../actions';
import { resetWrp } from '../../home/actions';
import { getUserAccessToken, setUserAccessToken, setUserRefreshToken } from '../../../utils/authorization';
import ic_sign_up from '../../../assets/login/ic_sign_up_webapp.png'
import { onRegisterEvent } from '../../../utils/googleTagEvents';
import { encryptByAesAndBase64 } from '../../../utils/helper';

let secret_key = process.env.REACT_APP_OTP_SECRET_KEY 
let secret_iv = process.env.REACT_APP_OTP_SECRET_IV

export default function SignupPage() {
  let history = useHistory();
  let dispatch = useDispatch();
  const { profile } = useSelector((state) => state.global)
  const [isSendingOTP, setIsSendingOTP] = useState(false);
  const [isVerifyingOTP, setIsVerifyingOTP] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [states, setStates] = useState([]);
  const [step, setStep] = useState(1);
  const [verificationId, setVerificationId] = useState(null);
  const [name, setName] = useState({
    value: '',
    isValid: true,
    error: '',
    isPristine: true
  })
  const [recipient, setRecipient] = useState({
    value: '',
    isValid: true,
    error: '',
    isPristine: true,
  });
  const [state, setState] = useState({
    value: '',
    isValid: true,
    error: '',
    isPristine: true
  });
  const [otp, setOtp] = useState({
    value: '',
    isValid: true,
    error: '',
    isPristine: true
  });
  const [password, setPassword] = useState({
    value: '',
    isValid: true,
    error: '',
    isPristine: true
  });
  const [passwordConfirmation, setPasswordConfirmation] = useState({
    value: '',
    isValid: true,
    error: '',
    isPristine: true
  });
  const [referrer, setReferrer] = useState({
    value: '',
    isValid: true,
    error: '',
    success: '',
    captain: '',
    isPristine: true,
    validFeedback: false,
    inValidFeedback: false,
    captainText: false
  });
  const [hasAccepted, setHasAccepted] = useState(false);
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    const token = getUserAccessToken();
    (profile && token) && history.push('/')
    profileClientX.get(`/v1/states`)
      .then((response) => {
        setStates(response.data.data)
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setFormValid(
      (name.value !== null && name.isValid) &&
      (recipient.value !== null && recipient.isValid) &&
      verificationId !== null &&
      (state.value !== "" && state.isValid) &&
      (password.value !== null && password.isValid) &&
      (passwordConfirmation.value !== null && passwordConfirmation.isValid) &&
      hasAccepted
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, state, password, passwordConfirmation, hasAccepted])

  const onSendOTP = () => {
    if (!recipient.isValid) {
      setRecipient({
        ...recipient,
        isPristine: false,
        isValid: false,
        error: "Invalid Mobile"
      })
    }
    setIsSendingOTP(true)
    let payload = {
      mobile: recipient.value,
      timestamp: Date.now()
    }
    let encryptedPayload = encryptByAesAndBase64(payload, secret_key, secret_iv)

    profileClientX.post(`/v3/register/send-otp`, {
      data: encryptedPayload
    })
      .then((response) => {
        toast.success('OTP sent successfully.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        setStep(2)
        setIsSendingOTP(false)
      })
      .catch((error) => {
        setIsSendingOTP(false)
      });
  };

  const onResendOTP = () => {
    setIsSendingOTP(true)
    let payload = {
      mobile: recipient.value,
      timestamp: Date.now()
    }
    let encryptedPayload = encryptByAesAndBase64(payload, secret_key, secret_iv)

    profileClientX.post(`/v3/register/resend-otp`, {
      data: encryptedPayload
    })
      .then((response) => {
        toast.success('OTP sent successfully.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        setIsSendingOTP(false)
      })
      .catch((error) => {
        setIsSendingOTP(false)
      });
  }

  const onVerifyOTP = () => {
    setIsVerifyingOTP(true)
    profileClientX
      .post(`/v2/register/verify-otp`, {
        mobile: recipient.value,
        otp: otp.value
      })
      .then((response) => {
        toast.success('OTP verified successfully.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        setStep(3)
        setVerificationId(response?.data?.data?.verification_id)
        setIsVerifyingOTP(false)
      })
      .catch((error) => {
        setIsVerifyingOTP(false)
      });
  };

  const onChangeRecipient = () => {
    return (
      setStep(1),
      setVerificationId(''),
      setName({
        value: '',
        isValid: true,
        error: '',
        isPristine: true
      }),
      setRecipient({
        value: '',
        isValid: true,
        error: '',
        isPristine: true
      }),
      setState({
        value: '',
        isValid: true,
        error: '',
        isPristine: true
      }),
      setOtp({
        value: '',
        isValid: true,
        error: '',
        isPristine: true
      }),
      setPassword({
        value: '',
        isValid: true,
        error: '',
        isPristine: true
      }),
      setPasswordConfirmation({
        value: '',
        isValid: true,
        error: '',
        isPristine: true
      }),
      setReferrer({
        value: '',
        isValid: true,
        error: '',
        isPristine: true
      }),
      setHasAccepted(false),
      setFormValid(false)
    )
  }

  const onReferralValidation = (callRegisterAPI) => {
    profileClientX.post(`/v1/verify-referral-code`, {
      referrer: referrer.value
    })
      .then((response) => {
        if (callRegisterAPI) {
          onRegister()
        }
        if (response.data.success) {
          setReferrer({
            ...referrer,
            success: response?.data?.data?.message,
            captain: "You have used Referral Code of Customer:" + response?.data?.data?.name,
            error: "",
            isPristine: true,
            isValid: true,
            validFeedback: true,
            inValidFeedback: false
          })
        }
        else {
          setReferrer({
            success: "",
            captain: "",
            error: response?.data?.message,
            isPristine: false,
            isValid: false,
            validFeedback: false,
            inValidFeedback: true
          })
        }
      })
      .catch((error) => {
      });
  }

  const getProfileData = () => {
    profileClientX
      .get("v1/profile")
      .then((response) => {
        const responseData = response?.data?.data;
        dispatch(setProfile(responseData));
        onRegisterEvent(responseData?.mobile)
      })
      .catch((error) => {
        dispatch(resetProfile());
        dispatch(resetWrp());
      });
  };

  const validateReferralAndRegister = () => {
    if (referrer.value.length > 1 && referrer.success === "") onReferralValidation(true)
    else onRegister()
  }

  const onRegister = () => {
    setIsRegistering(true)
    profileClientX.post(`/v2/register`, {
      mobile: recipient.value,
      verification_id: verificationId,
      name: name.value,
      password: password.value,
      password_confirmation: passwordConfirmation.value,
      state_id: state.value,
      referrer: referrer.value,

    })
      .then((response) => {
        setIsRegistering(false)
        toast.success('Registration Successful.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        setUserAccessToken(response?.data?.access_token)
        setUserRefreshToken(response?.data?.refresh_token)
        getProfileData()
        history.push('/')
      })
      .catch((error) => {
        setIsRegistering(false)
      });
  }

  const validateField = (fieldName, value) => {
    switch (fieldName) {
      case 'name':
        setName({
          value: value,
          isValid: value.length > 3,
        })
        break;
      case 'recipient':
        setRecipient({
          ...recipient,
          value: value,
          isValid: null !== value.match(/^[6-9]\d{9}$/),
        })
        if (value.length > 10)
          setRecipient({
            ...recipient,
            value: value.slice(0, 10)
          })
        break;
      case 'state':
        setState({
          value: value,
          isValid: value !== '-1'
        })
        break;
      case 'otp':
        setOtp({
          ...otp,
          value: value,
          isValid: null != value.match(/^\d{6}$/)
        })
        break;
      case 'password':
        setPassword({
          value: value,
          isValid: value.length >= 6,
        })
        if (passwordConfirmation.value.length > 0)
          setPasswordConfirmation({
            ...passwordConfirmation,
            isValid: passwordConfirmation.value === value
          })
        break;
      case 'password_confirmation':
        setPasswordConfirmation({
          value: value,
          isValid: password.value === value
        })
        break;
      case 'referrer':
        setReferrer({
          ...referrer,
          value: value,
          // error: referrer.isValid ? "" : "Referral Code is invalid"
        })
        break;
      case 'terms':
        break
      default:
        break;
    }
    validateForm();
  };

  const validateForm = () => {
    const isFormValid =
      (name.value !== null && name.isValid) &&
      (recipient.value !== null && recipient.isValid) &&
      verificationId !== null &&
      (state.value !== null && state.isValid) &&
      (password.value !== null && password.isValid) &&
      (passwordConfirmation.value !== null && passwordConfirmation.isValid) &&
      hasAccepted;
    setFormValid(isFormValid)

  };

  const renderStepOne = () => {
    return (
      <>
        <div className='mb-3'>
          <TextField
            label="Mobile Number"
            outlined
            helperText={!recipient.isValid && <HelperText className='text-red'>Enter valid Number</HelperText>}
            className=" w-100 "
          >
            <Input
              name='recipient'
              isValid={recipient.isValid}
              value={recipient.value}
              onChange={(e) => validateField(e.target.name, e.target.value)}
              autoComplete='off'
              type='number'
            />
          </TextField>
        </div>
        {(recipient.isValid && recipient.value !== '') &&
          <div className="d-flex justify-content-end align-items-center pb-4"><p className="m-0 cursor-pointer" style={{ color: "#203BCF" }} onClick={() => setStep(2)}><small><strong>Already have an OTP?</strong></small></p></div>
        }
        {isSendingOTP ?
          <div className="text-center"><InlineLoader /></div>
          :
          <button className={(recipient.isValid && recipient.value !== '') ? "animated-btn" : "animated-btn-disabled"} onClick={() => onSendOTP()}>SEND OTP</button>
        }
        <label className="text-center pt-3">Have an account? <span style={{ color: "#203BCF" }} className="font-bold cursor-pointer" onClick={() => history.push('/login')}>Sign in</span></label>

      </>
    )
  }

  const renderStepTwo = () => {
    return (
      <>
        <div className='d-flex pb-3 row'>
          <div className="col-8 col-md-9 pr-0">
            <div className='mb-3'>
              <TextField
                label="Mobile Number"
                outlined
                className=" w-100 "
              >
                <Input
                  autoComplete='off'
                  name='recipient'
                  value={recipient.value}
                  type='number'
                />
              </TextField>
            </div>
          </div>
          <div className="col-4 col-md-3 pl-0"><button className="forgot-password-sent-otp-btn" onClick={onChangeRecipient}>Change</button> </div>
        </div>
        <div className='mb-3'>
          <TextField
            label="OTP"
            outlined
            helperText={!otp?.isValid && <HelperText className='text-red'>Enter valid Number</HelperText>}
            className=" w-100 "
          >
            <Input
              name='otp'
              isValid={otp.isValid}
              value={otp.value}
              onChange={(e) => validateField(e.target.name, e.target.value)}
              autoComplete='off'
              type='number'
            />
          </TextField>
        </div>
        <div className="d-flex justify-content-end align-items-center pb-4">
          {isSendingOTP ?
            <div className="text-center ">
              <InlineLoader />
            </div> :
            <p className="m-0 cursor-pointer" onClick={() => onResendOTP()} style={{ color: "#203BCF" }}><small><strong>Resend OTP?</strong></small></p>
          }
        </div>
        {isVerifyingOTP ?
          <div className="text-center">
            <InlineLoader />
          </div> :
          <button className={(otp.isValid && otp.value !== '') ? "animated-btn" : "animated-btn-disabled"} onClick={() => onVerifyOTP()}>VERIFY OTP</button>
        }
      </>

    )
  }

  const renderStepThree = () => {
    return (
      <>
        <div className='mb-3'>
          <TextField
            label="Mobile Number"
            outlined
            className=" w-100 "
          >
            <Input
              name='recipient'
              value={recipient.value}
              autoComplete='off'
              type='number'
            />
          </TextField>
        </div>
        <div className='mb-3 '>
          <Select
            label='Select State'
            value={state.value}
            outlined
            onChange={(e) => validateField("state", e.target.value)}
            className="w-100 "
          >
            <Option value={-1}></Option>
            {(states || []).map((state, index) =>
              <Option
                value={state?.id}
                onClick={(e) => validateField("state", e.target.value)} //only for Firefox browser
              >
                {state?.name}
              </Option>
            )}
          </Select>
          {!state.isValid &&
            <label className='px-3 pb-0 mb-0' style={{ color: "red" }}><small>Select an option</small></label>
          }
        </div>
        <div className='mb-3'>
          <TextField
            label="Name"
            outlined
            helperText={!name.isValid && <HelperText className='text-red'>Enter valid Name</HelperText>}
            className=" w-100 "
          >
            <Input
              name='name'
              isValid={name.isValid}
              value={name.value}
              onChange={(e) => validateField(e.target.name, e.target.value)}
              autoComplete='off'
            />
          </TextField>
        </div>
        <div className='mb-3'>
          <TextField
            label="Password"
            outlined
            helperText={!password.isValid && <HelperText className='text-red'>Enter valid password</HelperText>}
            className=" w-100 "
          >
            <Input
              name='password'
              type='password'
              isValid={password.isValid}
              value={password.value}
              onChange={(e) => validateField(e.target.name, e.target.value)}
              autoComplete='new-password'
            />
          </TextField>
        </div>
        <div className='mb-3'>
          <TextField
            label="Confirm Password"
            outlined
            helperText={!passwordConfirmation.isValid && <HelperText className='text-red'>Enter valid password</HelperText>}
            className=" w-100 "
          >
            <Input
              name='password_confirmation'
              type='password'
              isValid={passwordConfirmation.isValid}
              value={passwordConfirmation.value}
              onChange={(e) => validateField(e.target.name, e.target.value)}
              autoComplete='new-password'
            />
          </TextField>
        </div>
        <div className='d-flex pb-3 row '>
          <div className="col-8 col-md-9 pr-0">
            <div className='mb-3'>
              <TextField
                label="Referral Code (Optional)"
                outlined
                className=" w-100 "
              >
                <Input
                  name='referrer'
                  isValid={referrer.isValid}
                  value={referrer.value}
                  onChange={(e) => validateField(e.target.name, e.target.value)}
                />
              </TextField>
            </div>
          </div>
          <div className="col-4 col-md-3 pl-0"><button className="forgot-password-sent-otp-btn" onClick={() => onReferralValidation(false)}>Apply</button> </div>
          {referrer.validFeedback &&
            <>
              <div className="valid-feedback mx-3">
                {referrer?.success}
              </div>
              <div className="captain-Name mx-3">
                {referrer.captain}
              </div>
            </>
          }
          {referrer.inValidFeedback &&
            <div className="invalid-feedback mx-3" >
              {referrer?.error}
            </div>
          }
        </div>
        <div className="form-group form-check pb-3">
          <input type="checkbox" className="form-check-input" id="exampleCheck1" onChange={() => { setHasAccepted(!hasAccepted); validateForm("terms", true) }} />
          <label className="form-check-label" for="exampleCheck1"><small>I agree to all the <a className='text-blue' href={`${process.env.REACT_APP_URL}/terms-condition`} target="_blank" rel="noreferrer"> Terms of Service </a> & <a className='text-blue' href={`${process.env.REACT_APP_URL}/privacy-policy`} target="_blank" rel="noreferrer">Privacy Policy</a></small></label>
        </div>
        {isRegistering ?
          <div className='text-center'>
            <InlineLoader />
          </div>
          :
          <button className={formValid ? "animated-btn" : "animated-btn-disabled"} onClick={() => validateReferralAndRegister()}>CREATE ACCOUNT</button>
        }
        <label className="text-center pt-3">Have an account? <span style={{ color: "#203BCF" }} className="font-bold cursor-pointer" onClick={() => history.push('/login')}>Sign in</span></label>
      </>
    )
  }
  return (
    <>
      <div className="container-fluid ">
        <div className="row flex flex-row-reverse" style={{ minHeight: "90vh" }}>
          <div className="col-12 col-md-5 px-3 px-md-5 home-page">
            <h2 className="font-bold pb-3 sub-title">Create Your Account</h2>
            <div className="d-flex flex-column ">

              {(1 === step) && renderStepOne()}
              {(2 === step) && renderStepTwo()}
              {(3 === step) && renderStepThree()}

            </div>
          </div>
          <div className="col-12 col-md-7 d-flex justify-content-center align-items-center" style={{ background: "linear-gradient(180deg, rgba(240,238,254,1) 0%, rgba(206,197,241,1) 100%)" }}>
            <div className="w-50 py-5"><img src={ic_sign_up} alt='sign-in-logo' title='sign-in-logo' className="img-fluid" /></div>
          </div>
        </div>
      </div>
    </>
  )
}



