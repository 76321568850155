import React, { Component } from "react";
import profileClientX from "../../../utils/api/profileClientX";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
export default class WRPSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      summary: []
    };
  }

  componentDidMount() {
    profileClientX.get("v1/winds-coins/wrp-summary").then(response => {
      this.setState({
        summary: response.data.data.wrp_earned_details
      });
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
          <div className="col-12">
            <div className="row align-items-center">
              <div className="col-12">
                <small>
                  <Link to="/"> <FormattedMessage
                      id="breadcrumbs.home"
                      defaultMessage="Home"
                    /></Link>
                </small>{" "}
                /{" "}
                <small>
                  <Link to="/winds-coins"><FormattedMessage
                      id="home.wrp"
                      defaultMessage="WINDS Coins"
                    /></Link>
                </small>{" "}
                /{" "}
                <small>
                  <strong>
                    <FormattedMessage
                      id="wrp.summary"
                      defaultMessage="WINDS Coins Summary"
                    />
                  </strong>
                </small>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3 mb-md-4">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col" style={{ borderTop: "none" }}>
                        <FormattedMessage
                          id="wrp.order"
                          defaultMessage="Order"
                        />
                      </th>
                      <th scope="col" style={{ borderTop: "none" }}>
                        <FormattedMessage
                          id="wrp.credited_at"
                          defaultMessage="
                      Credited At"
                        />
                      </th>
                      <th scope="col" style={{ borderTop: "none" }}>
                        WINDS Coins
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.summary.map((item, index) => {
                      return (
                        <tr>
                          <td className="font-weight-bold" height="40">
                            {item.service_text}{" "}
                            {item.service === "referral"
                              ? ""
                              : item.order_number}
                          </td>
                          <td>{item.credited_at}</td>
                          <td>{item.wrp}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
