const INITIAL_STATE = {
    modifyFlightData: null,
    viewTicketUrl: null,
    travelData: null
};

function travelReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case "SET_MODIFY_FLIGHT_DATA":
            return {
                ...state,
                modifyFlightData: action.modifyFlightData
            };
        case "SET_VIEW_TICKET_URL":
            return {
                ...state,
                viewTicketUrl: action.viewTicketUrl
            };
        case "SET_TRAVEL_DATA":
            return {
                ...state,
                travelData: action.travelData
            };
        default:
            return state;
    }
}

export default travelReducer;