import React, { Component } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import ContentLoader from "react-content-loader";
import { FormattedMessage } from "react-intl";
import bookmarkActive from "../../../assets/bookmark-active.svg";
import bookmarkInactive from "../../../assets/bookmark-inactive.svg";
import directionIcon from "../../../assets/ic_direction.svg";
import { connect } from "react-redux";
import customerPartnerClientX from "../../../utils/api/customerPartnerClientX";
import { onViewedProduct } from "../../../utils/events";
class OfferDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      offer: null,
      isLoading: true,
    };
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    this.setState(
      {
        isLoading: true,
      },
      () => {
        customerPartnerClientX
          .get(`v1/partners/offers/${params.id}`)
          .then((response) => {
            let data = response.data.data;
            const payload = {
              Category: "Offers",
              "Product Name": data.partner_name,
            };
            onViewedProduct(payload);
            this.setState({
              offer: data,
              isLoading: false,
            });
          })
          .catch((error) => {
            if (error.status === 404) {
              this.props.history.goBack();
            }
          });
      }
    );
  }

  render() {
    return this.state.isLoading ? <this.Shimmer /> : <this.Offer />;
  }

  Shimmer = (props) => {
    return (
      <div className="container-fluid py-3 py-md-5">
        <div className="row">
          <div className="col-md-6">
            <div className="box shine box-partner-detail-logo"></div>
          </div>
          <div className="col-md-6">
            <ContentLoader
              height={160}
              width={400}
              speed={2}
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            >
              <rect x="0" y="9" rx="0" ry="0" width="120" height="6" />
              <rect x="0" y="21" rx="0" ry="0" width="320" height="12" />
              <rect x="0" y="60" rx="0" ry="0" width="320" height="6" />
              <rect x="0" y="75" rx="0" ry="0" width="250" height="6" />
              <rect x="0" y="90" rx="0" ry="0" width="153" height="6" />
            </ContentLoader>
          </div>
        </div>
      </div>
    );
  };

  toggleBookmark = (event, id, isBookmarked) => {
    event.preventDefault();
    event.stopPropagation();
    this.onToggleBookmark(
      id,
      isBookmarked,
      `/partners/offers/${id}/bookmark-partner`,
      "offers"
    );
  };

  onToggleBookmark = (id, isBookmarked, url, type) =>
    !isBookmarked
      ? this.addBookmark(id, url, this.state.offer)
      : this.deleteBookmark(id, url, this.state.offer);

  addBookmark = (id, url, offer) => {
    customerPartnerClientX
      .post(url, {})
      .then((response) => {
        this.setState((prevState) => ({
          offer: {
            ...prevState.offer,
            bookmarked: !prevState.offer.bookmarked,
          },
        }));
      })
      .catch((error) => {});
  };

  deleteBookmark = (id, url, offer) => {
    customerPartnerClientX
      .delete(url)
      .then((response) => {
        this.setState((prevState) => ({
          offer: {
            ...prevState.offer,
            bookmarked: !prevState.offer.bookmarked,
          },
        }));
      })
      .catch((error) => {});
  };

  Offer = (props) => {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 mt-3 mb-3 mt-md-4 mb-md-4">
            <div className="row align-items-center">
              <div className="col-12">
                <small>
                  <Link to="/">
                    <FormattedMessage
                      id="breadcrumbs.home"
                      defaultMessage="Home"
                    />
                  </Link>{" "}
                  /{" "}
                  <Link to="/nearby-offers">
                    <FormattedMessage id="home.offers-near-you" />
                  </Link>{" "}
                  / <strong>{this.state.offer.partner_name}</strong>
                </small>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-md-4">
          <div className="col-12">
            <div className="row">
              <div
                className={classNames("col-md-6", {
                  "d-none": null == this.state.offer.store_image,
                })}
              >
                <div
                  className="card"
                  style={{
                    boxShadow: "0 4px 14px 0 rgba(0,0,0,0.08)",
                    borderRadius: "8px",
                    border: "none",
                  }}
                >
                  <div className="card-body" style={{ padding: "0px" }}>
                    <img
                      style={{
                        width: "100%",
                        boxShadow: "0 4px 14px 0 rgba(0,0,0,0.08)",
                        borderRadius: "8px",
                      }}
                      src={this.state.offer.store_image}
                      alt={this.state.offer.partner_name}
                      title={this.state.offer.partner_name}
                    />
                  </div>
                  {
                    <img
                      src={
                        this.state.offer.bookmarked
                          ? bookmarkActive
                          : bookmarkInactive
                      }
                      className="bookmark"
                      alt="bookmark"
                      title="bookmark"
                      onClick={(event) =>
                        this.toggleBookmark(
                          event,
                          this.state.offer.id,
                          this.state.offer.bookmarked
                        )
                      }
                    />
                  }
                </div>
              </div>
              <div className="col-md-6 mt-3 mt-md-0">
                <div className="row">
                  <div className="col-md-6">
                    <p className="mb-0" style={{ color: "#797979" }}>
                      {this.state.offer.partner_name}
                    </p>
                    <p
                      className="mb-2"
                      style={{ fontSize: "28px", fontWeight: "bold" }}
                    >
                      {this.state.offer.title}
                    </p>
                    <p>{this.state.offer.description}</p>

                    <Link
                      to={`/partner-profile/${this.state.offer.partner_id}`}
                    >
                      <button
                        className="btn btn-primary home-btn"
                        style={{ backgroundColor: "#203BCF" }}
                      >
                        <FormattedMessage
                          id="offers.view-partner"
                          defaultMessage="VIEW PARTNER"
                        />
                      </button>
                    </Link>
                    <p className="mb-5 mt-3 text-secondary">
                      <img
                        className="align-middle mr-2"
                        src={directionIcon}
                        alt="icon"
                        title="icon"
                      />
                      <span
                        className="align-middle"
                        style={{ color: "#0070DE" }}
                      >
                        <a
                          href={`http://maps.google.com/?q=${this.props.location.latitude},${this.props.location.longitude}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FormattedMessage
                            id="nearby-partners.view-on-map"
                            defaultMessage="View on Map"
                          />
                        </a>
                      </span>
                    </p>
                  </div>
                  <div className="col-md-6 text-right"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    location: state.location,
  };
};

export default connect(mapStateToProps, null)(OfferDetail);
