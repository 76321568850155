import React from "react";
import { FormattedMessage } from "react-intl";

function Contact() {
	return (
		<>
			<div className="backW">
				<div className="term-container">
					<div className="row">
						<div className="col-md-12">
							<div className="termCont">
								<h2 className="pt-5" style={{ textTransform: "uppercase" }}><FormattedMessage id="contact-us" /></h2>
								<p className="last">
									<FormattedMessage id="contact-desc2" />
									<a href="mailto:support@winds.co.in"> SUPPORT@WINDS.CO.IN</a>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Contact;