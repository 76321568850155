import axios from "axios";
//import client from "./client";

export function setUserAccessDenied(errorCode) {
  return {
    type: "ACCESS_DENIED",
    errorCode,
  };
}

export function resetAccessDenied(errorCode){
  return {
    type: "RESET_ACCESS_DENIED",
    errorCode
  }
}

export function getGeoLocation() {
  return new Promise((resolve, reject) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        function success(position) {
          resolve({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        function error(error_message) {
          reject(
            //   "An error has occured while retrieving location",
            error_message,
            error_message
          );
        }
      );
    } else {
      reject("geolocation is not enabled on this browser");
    }
  });
}

export function getAddress(latitude, longitude) {
  return axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_MAP_API_KEY}`
  );
}

export function getPlace(keyword) {
  return axios.get(
    `${process.env.REACT_APP_BASE_URL}/v1/places-autocomplete?input=${keyword}&key=${process.env.REACT_APP_MAP_API_KEY}`
  );
  // return axios.get(
  //   `https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${keyword}&key=${process.env.REACT_APP_MAP_API_KEY}`
  // );
}

export function getPlaceById(id) {
  return axios.get(
    `${process.env.REACT_APP_BASE_URL}/v1/places?placeid=${id}&key=${process.env.REACT_APP_MAP_API_KEY}`
  );
  // return client.get(
  //   `/v1/places?placeid=${id}&key=${process.env.REACT_APP_MAP_API_KEY}`
  // );
}
