//import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import rewardAmazon from "../../../assets/voucher-reveal.svg";
import Carousel from "react-bootstrap/Carousel";

const MultipleVochers = (props) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <div className="multiple-vouchers">
      {/* <Modal
        show
        onHide={props.closeRewardModelHandler}
        className="font-proxima-nova rewards"
        onEntered={props.modelEntered}
        backdrop="static"
      >
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body> */}
          <Carousel activeIndex={index} onSelect={handleSelect} interval={null}>
            <Carousel.Item>
              <div>
                <div style={{ padding: "15px" }}>
                  <div
                    style={{ cursor: "pointer" }}
                  >
                    <div className="card-body">
                      <div className="rewards-card carousel-card">
                        <div className="reveal-icon">
                          <img
                            src={rewardAmazon}
                            alt="rewards icon."
                            title="rewards icon"
                            className="d-block"
                          />
                          <h5>You won Amazon voucher worth</h5>
                          <h4>&#8377; 1000</h4>
                          <p className="text-secondary">13th Mar, 2020</p>
                          <button className="winds-link-color">
                            <small>View Voucher Details</small>
                          </button>
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </div>

              <Carousel.Caption>
                <h3>Congratulations!</h3>
                <p>
                  Vouchers 1/3
                </p>
                <button className="btn btn-primary home-btn">Share with your Friends</button>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
            <div>
                <div style={{ padding: "15px" }}>
                  <div
                    style={{ cursor: "pointer" }}
                  >
                    <div className="card-body">
                      <div className="rewards-card carousel-card">
                        <div className="reveal-icon">
                          <img
                            src={rewardAmazon}
                            alt="rewards icon."
                            title="rewards icon"
                            className="d-block"
                          />
                          <h5>You won Amazon voucher worth</h5>
                          <h4>&#8377; 1000</h4>
                          <p className="text-secondary">13th Mar, 2020</p>
                          <button className="winds-link-color">
                            <small>View Voucher Details</small>
                          </button>
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </div>

              <Carousel.Caption>
              <h3>Congratulations!</h3>
                <p>
                  Vouchers 2/3
                </p>
                <button className="btn btn-primary home-btn">Share with your Friends</button>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              {/* <img
                className="d-block w-100"
                src="holder.js/800x400?text=Third slide&bg=20232a"
                alt="Third slide"
              />*/}
              <div> 
                <div style={{ padding: "15px" }}>
                  <div
                    style={{ cursor: "pointer" }}
                  >
                  <div className="card-body">
                    <div className="rewards-card carousel-card">
                      <div className="reveal-icon">
                        <img
                          src={rewardAmazon}
                          alt="rewards icon."
                          title="rewards icon"
                          className="d-block"
                        />
                        <h5>You won Amazon voucher worth</h5>
                        <h4>&#8377; 1000</h4>
                        <p className="text-secondary">13th Mar, 2020</p>
                        <button className="winds-link-color">
                          <small>View Voucher Details</small>
                        </button>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>

            <Carousel.Caption>
              <h3>Congratulations!</h3>
                <p>
                  Vouchers 3/3
                </p>
                <button className="btn btn-primary home-btn">Share with your Friends</button>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
          );
        {/* </Modal.Body>
      </Modal> */}
    </div>
  );
};

export default MultipleVochers;
