import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { currentlySelectedLanguage, showToastMessage } from "../../../utils/helper";
import "react-toastify/dist/ReactToastify.css";
import vectorImg from "../../../assets/bill-pay-creative.svg";
import InlineLoader from "../../../components/InlineLoader";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import customerPartnerClientX from "../../../utils/api/customerPartnerClientX";
import Iframe from "../../../components/iFrame";
import { FirebaseContext } from "../../../utils/firebase";
import BillSummary from "../components/BillSummary";
import aboutBlue from "../../../assets/about-blue.svg";
import { partnerCart } from "../actions/partner";

class PayNow extends Component {
  static contextType = FirebaseContext;
  fileObj = [];
  currentFileIndex = 0;
  fileArray = [];
  constructor(props) {
    super(props);

    this.state = {
      partnerCode: null,
      partner: null,
      isPayNowLoading: false,
      isLoadingPartner: false,
      amount: "",
      bill: null,
      file_extension: null,
      file_type: null,
      isUploading: false,
      taxType: "CGST",
      disablePayNow: false,
      cgst: "",
      igst: "",
      sgst: "",
      isPaynowMaintenance: false,
      razorpay_email: null,
      razorpay_mobile: null
    };
    this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
  }
  toggleBillSummaryModel = (value) => {

    this.setState({
      isBillSummaryOpen: value,
    });
  };

  componentDidMount() {
    if (this.props.profile) {
      this.setState({
        razorpay_email: this.props.profile.email,
        razorpay_mobile: this.props.profile.mobile
      })
      const { pay_now } = this.context;
      if (!pay_now) {
        const {
          match: { params },
        } = this.props;
        const partnerCode = params.id;
        //  if (!this.props.current_partner) {
        this.getPartner(partnerCode);
        //  }
      } else {
        this.setState({
          isPaynowMaintenance: pay_now,
        });
      }
    } else {
      this.props.history.push('/login')
    }
  }

  taxTypechanged = (event) => {
    this.setState({ taxType: event.target.value });
  };

  onGetPartner = () => {
    if (
      null === this.state.partnerCode ||
      0 === this.state.partnerCode.length
    ) {
      toast.error(
        <FormattedMessage
          id="nearbyPartner.enter-code-to-proceed"
          defaultMessage="Enter Partner Code to proceed."
        />,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );

      this.setState({
        isLoadingPartner: false,
      });

      return;
    }

    this.setState(
      {
        isLoadingPartner: true,
      },
      () => {
        this.getPartner(this.state.partnerCode);
      }
    );
  };

  getPartner = (code) => {
    customerPartnerClientX
      .post(`v1/bills/scan/partner-details`, {
        partner_code: code,
      })
      .then((response) => {
        let data = response.data.data;
        this.setState({
          partner: data,
          isLoadingPartner: false,
        });
      })
      .catch((error) => {
        this.setState({
          isLoadingPartner: false,
        });
      });
  };

  deleteFile = (id, index) => {
    customerPartnerClientX.delete(`/v2/bills/file/${id}`).then((response) => {
      this.fileArray = this.fileArray.filter((file, fileIndex) => {
        return index !== fileIndex;
      });
      this.setState({
        file: this.state.file.filter(
          (item, entry) => Number(item.id) !== Number(id)
        ),
      });
    });
  };

  toggleUploadScreen = (value) => {
    this.setState({
      showUploadScreen: value,
    });
  };

  onClearPartner = () => {
    this.setState({
      partnerCode: null,
      partner: null,
      amount: null,
    });
  };

  onSelectBill = (event) => {
    this.setState({
      bill: event.target.files[0],
    });
  };

  onChange = (event) => {
    const { name, value } = event.target;
    if (name === 'amount') {
      if (value > 500000) {
        showToastMessage('error', "Only upto 500000 is allowed")
        this.setState({
          disablePayNow: true
        })
      } else {
        this.setState({
          disablePayNow: false
        })
      }
    }
    this.setState({
      [name]: value,
    });
  };

  payNow = () => {
    this.props.history.push("/pay-now/nearby-partner/cart");
    let partnerData={
      partner_id: this.state.partner.id,
      bill_amount: this.state.amount,
      partner_name: this.state.partner.name
    }
    this.props.InitPartnerCart(partnerData)
    
  }

  uploadMultipleFiles(e) {
    this.fileObj.push(e.target.files);
    this.setState({
      isUploading: true,
    });
    for (
      let i = this.currentFileIndex, fileObjLength = this.fileObj.length;
      i < fileObjLength;
      i++
    ) {
      const formData = new FormData();
      formData.append("file", this.fileObj[i][0]);
      customerPartnerClientX
        .post(`/v2/bills/file/upload`, formData)
        .then((response) => {
          const data = response.data.data;
          this.setState(
            {
              isUploading: false,
            },
            () => {
              data.uploadedFile = data.url;
              this.fileArray.push(data);
              this.setState({
                file: this.fileArray,
                disablePayNow: this.fileArray.length > 10,
              });

              this.currentFileIndex++;
            }
          );
        })
        .catch((error) => {
          this.fileObj = this.fileObj.filter((file, fileIndex) => {
            return this.currentFileIndex !== fileIndex;
          });
          this.setState({
            isUploading: false,
          });
        });
    }
  }

  uploadFiles(e) {
    e.preventDefault();
  }

  render() {
    const {
      isBillSummaryOpen
    } = this.state;

    return (
      <div className="container">
        <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
          <div className="col-12">
            <div className="row align-items-center">
              <div className="col-12">
                <small>
                  <Link to="/">
                    <FormattedMessage
                      id="breadcrumbs.home"
                      defaultMessage="Home"
                    />
                  </Link>{" "}
                  /{" "}
                  <strong>
                    <FormattedMessage
                      id="nearby-partners.upload-bill-for-nearby-partner"
                      defaultMessage="Upload Bill for Nearby Partner"
                    />
                  </strong>
                </small>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-3 mb-md-4 font-proxima-nova">
          <div className="col-12">
            {this.context.pay_now ? (
              <Iframe
                source={`${process.env.REACT_APP_BASE_URL_PAYNOW_MAINTENANCE
                  }/${currentlySelectedLanguage()}`}
              ></Iframe>
            ) : (
              <div className="row">
                <div className="col-md-6 col-12">
                  <div
                    className="card border-0"
                    style={{ backgroundColor: "#f8f9fa" }}
                  >
                    <div className="card-body">
                      <h6>
                        {" "}
                        <FormattedMessage
                          id="payNow.store-name"
                          defaultMessage="Store Name"
                        />
                        :
                      </h6>
                      <h5 className="winds-lightblue-color">
                        <strong>
                          {this.props.current_partner
                            ? this.props.current_partner.name
                            : this.state.partner && this.state.partner.name}
                        </strong>
                      </h5>

                      <div className="form-group mt-5">
                        <label>
                          <FormattedMessage
                            id="nearby-partners.enter-amount"
                            defaultMessage="Enter Amount"
                          />
                        </label>
                        <input
                          type="number"
                          className="form-control pay-now-input border-bottom w-50"
                          value={this.state.amount}
                          name="amount"
                          onChange={this.onChange}
                        />
                      </div>

                      <div className="tax mt-1">
                        <p>
                          <FormattedMessage
                            id="payNow.amount-instruction"
                            defaultMessage="Please enter the bill amount"
                          />
                          <span className="ml-2">
                            <img src={aboutBlue} alt="about" title="about" onClick={() => this.toggleBillSummaryModel(true)} className="mr-1" id="bill_summary" />
                          </span>
                        </p>
                        {isBillSummaryOpen && (
                          <BillSummary

                            onDismiss={() => this.toggleBillSummaryModel(false)}
                          />
                        )}
                        {/* <div className="form-check form-check-inline mr-5">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio1"
                            value="CGST"
                            onChange={this.taxTypechanged}
                            defaultChecked
                          />
                          <label
                            className="form-check-label ml-2"
                            htmlFor="inlineRadio1"
                          >
                            <FormattedMessage
                              id="payNow.cgst-sgst"
                              defaultMessage="CGST/SGST"
                            />
                          </label>
                        </div> */}
                        {/* <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio2"
                            value="IGST"
                            onChange={this.taxTypechanged}
                          />
                          <label
                            className="form-check-label ml-2"
                            htmlFor="inlineRadio2"
                          >
                            <FormattedMessage
                              id="payNow.igst"
                              defaultMessage="IGST"
                            />
                          </label>
                        </div> */}
                      </div>

                      {/* {this.state.taxType === "IGST" ? (
                        // <div className="tax-amount mt-5 d-flex">
                        //   <div>
                        //     <label>
                        //       <FormattedMessage
                        //         id="payNow.igst-amount"
                        //         defaultMessage="IGST Amount"
                        //       />
                        //     </label>
                        //     <input
                        //       type="number"
                        //       className="form-control pay-now-input border-bottom w-50"
                        //       name="igst"
                        //       value={this.state.igst}
                        //       onChange={this.onChange}
                        //     />
                        //   </div>
                        // </div>
                      ) : (
                        <div className="tax-amount mt-5 d-flex">
                          <div>
                            <label>
                              <FormattedMessage
                                id="payNow.cgst-amount"
                                defaultMessage="CGST Amount"
                              />
                            </label>
                            <input
                              type="number"
                              className="form-control pay-now-input border-bottom w-50"
                              name="cgst"
                              value={this.state.cgst}
                              onChange={this.onChange}
                            />
                          </div>
                          <div>
                            <label>
                              <FormattedMessage
                                id="payNow.sgst-amount"
                                defaultMessage="SGST Amount"
                              />
                            </label>
                            <input
                              type="number"
                              className="form-control pay-now-input border-bottom w-50"
                              name="sgst"
                              onChange={this.onChange}
                              value={this.state.sgst}
                            />
                          </div>
                        </div>
                      )} */}

                      <div className="form-group mt-3">
                        {/* <p>
                          <FormattedMessage
                            id="payNow.include-image"
                            defaultMessage="Do you want to include bill image?"
                          />{" "}
                          <span
                            className="winds-lightblue-color"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.toggleUploadScreen(true)}
                          >
                            <FormattedMessage
                              id="payNow.add-bill-image"
                              defaultMessage="Add Bill Image"
                            />
                          </span>
                          <span style={{ fontSize: 14, display: "block" }}>
                            <em>
                              <FormattedMessage
                                id="payNow.img-size-msg"
                                defaultMessage="Please note that image size should be less than 2MB"
                              />
                            </em>
                          </span>
                        </p> */}
                        {/* <h5
                          className="d-inlinbe-block float-left"
                          style={{ lineHeight: 2 }}
                        >
                          <FormattedMessage
                            id="navbar.bill-upload"
                            defaultMessage="Bill Upload"
                          />
                        </h5> */}
                        {this.state.showUploadScreen && (
                          <button
                            className="btn btn-transparent float-right winds-lightblue-color align-middle"
                            onClick={() => this.toggleUploadScreen(false)}
                          >
                            <FormattedMessage
                              id="profile.cancel"
                              defaultMessage="Cancel"
                            />
                          </button>
                        )}
                        <div style={{ clear: "both" }}></div>
                      </div>

                      {this.state.showUploadScreen && (
                        <div className="bill-upload mt-4 d-flex">
                          <label htmlFor="upload-photo" className="upload-box">
                            <span className="plus">+</span>
                            <span className="winds-lightblue-color">
                              <FormattedMessage
                                id="payNow.add-image"
                                defaultMessage="Add Image"
                              />
                            </span>
                          </label>
                          <input
                            type="file"
                            name="photo"
                            id="upload-photo"
                            onChange={this.uploadMultipleFiles}
                            multiple
                            disabled={this.state.disablePayNow}
                          />

                          {(this.state.file || []).map((item, index) => (
                            <div className="upload-box" key={index}>
                              <div className="close-btn winds-lightblue-bg d-flex">
                                <i
                                  className="fas fa-times"
                                  onClick={() =>
                                    this.deleteFile(item.id, index)
                                  }
                                ></i>
                              </div>
                              <img src={item.uploadedFile} alt="..." title="file"/>
                            </div>
                          ))}
                          {this.state.isUploading && <InlineLoader />}
                        </div>
                      )}

                      <div className="mt-2">
                        {/* <p>
                          <FormattedMessage
                            id="payNow.add-image"
                            defaultMessage="Pay now to WINDS and get instant WRP &amp; instant approval (T&amp;C apply)"
                          />
                        </p> */}
                        {this.state.isPayNowLoading ?
                          <InlineLoader /> :
                          <button
                            disabled={!this.state.amount}
                            className="btn btn-primary home-btn w-50"
                            onClick={this.payNow}
                          >
                            <FormattedMessage
                              id="payNow.btn"
                              defaultMessage="Pay Now"
                            />
                          </button>}
                        {/* <p className="mt-3">
                          <FormattedMessage
                            id="payNow.direct"
                            defaultMessage="Want to Pay our Partner directly (T&amp;C apply)?"
                          />{" "}
                          <Link
                            to={`/upload-bill/nearby-partner/${this.state.partner
                              ? this.state.partner.partner_code
                              : this.props.current_partner &&
                              this.props.current_partner.partner_code
                              }`}
                          >
                            <span className="winds-lightblue-color">
                              <FormattedMessage
                                id="payNow.partner"
                                defaultMessage="
                          Pay Partner"
                              />
                            </span>
                          </Link>
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 text-center">
                  <img
                    className="mt-3 mt-md-0 nearby-partner-bill-upload-vector"
                    src={vectorImg}
                    alt="upload nearby partner bill"
                    title="upload nearby partner bill"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  SelectPartner = (props) => {
    return (
      <React.Fragment>
        <div className="form-group">
          <label>
            <FormattedMessage
              id="nearby-partners.enter-partner-code"
              defaultMessage="Enter Partner Code"
            />
          </label>
          {null == this.state.partner ? (
            <div className="input-group mb-3">
              <input
                type="number"
                className="form-control"
                value={
                  null == this.state.partnerCode ? "" : this.state.partnerCode
                }
                onChange={(event) => {
                  this.setState({ partnerCode: event.target.value });
                }}
              />
              {this.state.isLoadingPartner ? (
                <div
                  className="input-group-append"
                  style={{ cursor: "pointer" }}
                >
                  <span className="input-group-text">
                    <InlineLoader />
                  </span>
                </div>
              ) : (
                <div
                  className="input-group-append"
                  onClick={this.onGetPartner}
                  style={{ cursor: "pointer" }}
                >
                  <span className="input-group-text">
                    <FormattedMessage
                      id="nearby-partners.get-partner"
                      defaultMessage="Get Partner"
                    />
                  </span>
                </div>
              )}
            </div>
          ) : (
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                disabled
                value={
                  null == this.state.partnerCode ? "" : this.state.partnerCode
                }
              />
              <div
                className="input-group-append"
                onClick={this.onClearPartner}
                style={{ cursor: "pointer" }}
              >
                <span className="input-group-text">
                  <FormattedMessage id="general.clear" defaultMessage="Clear" />
                </span>
              </div>
            </div>
          )}
        </div>
        {this.state.partner && (
          <div>
            <p>
              <strong>{this.state.partner.name}</strong>
            </p>
          </div>
        )}
      </React.Fragment>
    );
  };
}

function mapStateToProps(state) {
  return {
    current_partner: state.partner.partnerData,
    profile: state.global.profile,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    InitPartnerCart: (partnerData) => {
      dispatch(partnerCart(partnerData));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PayNow);
