import HelmetMetaData from "../components/HelmetMetaData";
import { useLocation } from "react-router-dom";

function TestimonialsMetaData() {
	let location = useLocation();
	let currentAppUrl = process.env.REACT_APP_URL + location.pathname;

	return (
		<HelmetMetaData
			title={`WINDS App Testimonials: Know What Reward Winners are saying`}
			description={`Discover Testimonials from WINDS' Reward Winners. Hear from winners who have won various amounts in different months and learn about their experience with WINDS App's rewarding program.`}
			keywords={`vouchers, gift vouchers, e-vouchers, discount vouchers, online shopping sites, winds app, winds digi world, virtual shops, e-commerce portals, retail outlets, brand vouchers`}
			voiceSchema={<script type="application/ld+json">
				{JSON.stringify(
					{
						"@context": "https://schema.org/",
						"@type": "WebPage",
						"name": "TESTIMONIALS",
						"speakable":
						{
							"@type": "SpeakableSpecification",
							"xpath":
								[
									"/html/head/title",
									"/html/head/meta[@name='description']/@content"
								]
						},
						"url": currentAppUrl
					}
				)}
			</script>
			}
			breadcrumbSchema={<script type="application/ld+json">
				{JSON.stringify(
					{
						"@context": "https://schema.org/",
						"@type": "BreadcrumbList",
						"itemListElement": [{
							"@type": "ListItem",
							"position": 1,
							"name": "Home",
							"item": process.env.REACT_APP_URL
						}, {
							"@type": "ListItem",
							"position": 2,
							"name": "TESTIMONIALS",
							"item": currentAppUrl
						}]
					}

				)}
			</script>
			}
			videoSchema={
				<script type="application/ld+json">
					{JSON.stringify(
						[
							{
								"@context": "http://schema.org",
								"@type": "VideoObject",
								"name": "Listen to what the Babbar Sher 🦁 from New Delhi has to say | WINDS App | #KharchePeKamai",
								"description": "We are bringing the 'WINDS of Change' to Shopper's & Customer's lives. Everything is possible on this super app, from buying 200+ Brand Vouchers to making payments for Utility Bills to Shopping Online or Paying at your Nearby Stores. Use the WINDS App for your daily shopping or bill payments, earn* on every transaction via Spin Wheel & also win up to Lakhs* monthly via Monthly Lucky Draw. ",
								"thumbnailUrl": "https://i.ytimg.com/vi/qeT9PDKd3y8/sddefault.jpg",
								"uploadDate": "Aug 3, 2023",
								"duration": "PT1M52S",
								"publisher": {
									"@type": "Organization",
									"name": "WINDS E Pvt Ltd",
									"logo": {
										"@type": "ImageObject",
										"url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
									}
								},
								"contentUrl": "https://www.youtube.com/watch?v=qeT9PDKd3y8&t=4s",
								"embedUrl": "https://www.youtube.com/embed/qeT9PDKd3y8",
								"interactionCount": "53,592",
								"interactionStatistic": {
									"@type": "InteractionCounter",
									"userInteractionCount": "53,592"
								}
							},
							{
								"@context": "http://schema.org",
								"@type": "VideoObject",
								"name": "Deepak Gupta - ₹25 Lakhs Grand Prize Winner - June 2023 | WINDS App | windsapp.com",
								"description": "Mr. Deepak Gupta from Delhi, NCR, emerged as the fortunate winner of the Mega Cash prize of Rs. 25 Lakhs in the WINDS Lucky Draw for June 2023. His winnings were the result of his regular shopping for daily needs from grocery stores, online platforms, and offline shops, as well as performing tasks such as mobile recharge, paying electricity bills, and purchasing brand vouchers using the WINDS App (windsapp.com).",
								"thumbnailUrl": "https://i.ytimg.com/vi_webp/o5nl4yL3KWw/maxresdefault.webp",
								"uploadDate": "Jul 8, 2023",
								"duration": "PT1M52S",
								"publisher": {
									"@type": "Organization",
									"name": "WINDS E Pvt Ltd",
									"logo": {
										"@type": "ImageObject",
										"url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
									}
								},
								"contentUrl": "https://youtu.be/o5nl4yL3KWw",
								"embedUrl": "https://www.youtube.com/embed/o5nl4yL3KWw",
								"interactionCount": "353,740",
								"interactionStatistic": {
									"@type": "InteractionCounter",
									"userInteractionCount": "353,740"
								}
							},
							{
								"@context": "http://schema.org",
								"@type": "VideoObject",
								"name": "J. Sriramulu - Lucky Winner Of Grand Prize of ₹25 Lakhs For May 2023 | WINDS App | #KharchePeKamai",
								"description": "Shopping for daily needs from grocery stores, online stores & offline shops, doing mobile recharge & paying electricity bills and buying  brand vouchers using WINDS App made Mr. J. Sriramulu from Wanaparthy, Telangana, Winner of Mega Cash prize of Rs.25 Lakhs in WINDS Lucky Draw for the month of May''23.You can also win Lakhs as Cash Rewards in the June Lucky Draw without any extra effort.",
								"thumbnailUrl": "https://i.ytimg.com/vi_webp/2hlDcLn6FU4/sddefault.webp",
								"uploadDate": "Jun 8, 2023",
								"duration": "PT2M00S",
								"publisher": {
									"@type": "Organization",
									"name": "WINDS E Pvt Ltd",
									"logo": {
										"@type": "ImageObject",
										"url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
									}
								},
								"contentUrl": "https://www.youtube.com/watch?v=2hlDcLn6FU4&t=1s",
								"embedUrl": "https://www.youtube.com/embed/2hlDcLn6FU4",
								"interactionCount": "16,418",
								"interactionStatistic": {
									"@type": "InteractionCounter",
									"userInteractionCount": "16,418"
								}
							},
							{
								"@context": "http://schema.org",
								"@type": "VideoObject",
								"name": "Grand Winner Of ₹25 Lakhs | WINDS App | #KharchePeKamai",
								"description": "Meet Mr. Manoranjan, the grand cash prize winner from Sambalpur, Odisha, who has won ₹25 Lakhs in April's Monthly Lucky Draw. Our app has most of your favourite brands & payment options available that can fulfill your day-to-day needs.",
								"thumbnailUrl": "https://i.ytimg.com/vi/uP438YwmqNo/sddefault.jpg",
								"uploadDate": "May 9, 2023",
								"duration": "PT2M22S",
								"publisher": {
									"@type": "Organization",
									"name": "WINDS E Pvt Ltd",
									"logo": {
										"@type": "ImageObject",
										"url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
									}
								},
								"contentUrl": "https://www.youtube.com/watch?v=uP438YwmqNo",
								"embedUrl": "https://www.youtube.com/embed/uP438YwmqNo",
								"interactionCount": "22,615",
								"interactionStatistic": {
									"@type": "InteractionCounter",
									"userInteractionCount": "22,615"
								}
							},
							{
								"@context": "http://schema.org",
								"@type": "VideoObject",
								"name": "Grand Winner Of ₹25 Lakhs | WINDS App | #KharchePeKamai",
								"description": "Meet Mr. Guru, the grand winner from Banglore, who just won ₹25 Lakhs in March's Lucky Draw. Our app has most of your favourite brands & payment options available that can fulfill your day-to-day needs.",
								"thumbnailUrl": "https://i.ytimg.com/vi_webp/W7Iat6Cx2Hg/sddefault.webp",
								"uploadDate": "Apr 11, 2023",
								"duration": "PT2M16S",
								"publisher": {
									"@type": "Organization",
									"name": "WINDS E Pvt Ltd",
									"logo": {
										"@type": "ImageObject",
										"url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
									}
								},
								"contentUrl": "https://www.youtube.com/watch?v=W7Iat6Cx2Hg&t=1s",
								"embedUrl": "https://www.youtube.com/embed/W7Iat6Cx2Hg",
								"interactionCount": "6,603",
								"interactionStatistic": {
									"@type": "InteractionCounter",
									"userInteractionCount": "6,603"
								}
							},
							{
								"@context": "http://schema.org",
								"@type": "VideoObject",
								"name": "1st Female Grand Winner Of ₹25 Lakhs | WINDS App | #KharchePeKamai",
								"description": "Meet Mrs Rajitha, our first female grand winner from Mumbai, who just won ₹25 Lakhs in February's Lucky Draw. Our app has most of your favourite brands & payment options available that can fulfil your day-to-day needs.",
								"thumbnailUrl": "https://i.ytimg.com/vi/pXInRrlqH_M/sddefault.jpg",
								"uploadDate": "Mar 6, 2023",
								"duration": "PT2M11S",
								"publisher": {
									"@type": "Organization",
									"name": "WINDS E Pvt Ltd",
									"logo": {
										"@type": "ImageObject",
										"url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
									}
								},
								"contentUrl": "https://www.youtube.com/watch?v=pXInRrlqH_M",
								"embedUrl": "https://www.youtube.com/embed/pXInRrlqH_M",
								"interactionCount": "8,989",
								"interactionStatistic": {
									"@type": "InteractionCounter",
									"userInteractionCount": "8,989"
								}
							},
							{
								"@context": "http://schema.org",
								"@type": "VideoObject",
								"name": "Lucky Winner of ₹25 Lakhs in Jan 2023",
								"description": "Shopping for daily needs from grocery stores, online stores & offline shops, doing mobile recharge & paying electricity bills and buying  brand vouchers using WINDS App,  made Mr. Bipul Kumar from Patna (Bihar), Winner of Mega Cash prize of Rs.25 Lakhs in WINDS Lucky Draw for the month of January '23.",
								"thumbnailUrl": "https://i.ytimg.com/vi_webp/Ccyxgou_byo/sddefault.webp",
								"uploadDate": "Feb 7, 2023",
								"duration": "PT2M00S",
								"publisher": {
									"@type": "Organization",
									"name": "WINDS E Pvt Ltd",
									"logo": {
										"@type": "ImageObject",
										"url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
									}
								},
								"contentUrl": "https://www.youtube.com/watch?v=Ccyxgou_byo",
								"embedUrl": "https://www.youtube.com/embed/Ccyxgou_byo",
								"interactionCount": "43,020",
								"interactionStatistic": {
									"@type": "InteractionCounter",
									"userInteractionCount": "43,020"
								}
							},
							{
								"@context": "http://schema.org",
								"@type": "VideoObject",
								"name": "Mr Shiva Makin  - ₹ 25 Lakh Winner in December Lucky Draw",
								"description": "Winning Continues with WINDS. More than 2 Lakh people have already won Cash Rewards worth more than  ₹ 8 crores . Meet Mr Shiva Makin, from Lucknow, Uttar Pradesh, one such lucky person who won ₹25 Lakhs* in the December Lucky Draw. You can also win Lakhs & Crores * as Cash Rewards every month. ",
								"thumbnailUrl": "https://i.ytimg.com/vi_webp/qPnwnGQVR4Q/sddefault.webp",
								"uploadDate": "Jan 6, 2023",
								"duration": "PT2M45S",
								"publisher": {
									"@type": "Organization",
									"name": "WINDS E Pvt Ltd",
									"logo": {
										"@type": "ImageObject",
										"url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
									}
								},
								"contentUrl": "https://www.youtube.com/watch?v=qPnwnGQVR4Q",
								"embedUrl": "https://www.youtube.com/embed/qPnwnGQVR4Q",
								"interactionCount": "4,678",
								"interactionStatistic": {
									"@type": "InteractionCounter",
									"userInteractionCount": "4,678"
								}
							},
							{
								"@context": "http://schema.org",
								"@type": "VideoObject",
								"name": "Mr Manoj Purohit - ₹ 25 Lakh Winner in November Lucky Draw",
								"description": "Lakhs of people are winning & getting lucky with WINDS from across the country. Meet Mr Manoj Purohit, from Jaisalmer, Rajasthan, one such lucky person who won ₹25 Lakhs* in the November Lucky Draw. You can also win Lakhs & Crores * as Cash Rewards every month. Just shop via WINDS, collect 5000 WRP,  make a coupon and participate in the upcoming December Lucky Draw to get a chance to win  ₹25 Lakhs* and other cash rewards",
								"thumbnailUrl": "https://i.ytimg.com/vi_webp/zdoTnSEvPgg/sddefault.webp",
								"uploadDate": "Dec 7, 2022",
								"duration": "PT2M09S",
								"publisher": {
									"@type": "Organization",
									"name": "WINDS E Pvt Ltd",
									"logo": {
										"@type": "ImageObject",
										"url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
									}
								},
								"contentUrl": "https://www.youtube.com/watch?v=zdoTnSEvPgg",
								"embedUrl": "https://www.youtube.com/embed/zdoTnSEvPgg",
								"interactionCount": "5,249",
								"interactionStatistic": {
									"@type": "InteractionCounter",
									"userInteractionCount": "5,249"
								}
							},
							{
								"@context": "http://schema.org",
								"@type": "VideoObject",
								"name": "Mr Ashok Meena - ₹ 25 Lakh Winner in October Lucky Draw",
								"description": "Lakhs of people are winning & getting lucky with WINDS from across the country. Meet Mr Ashok Meena from Kota, Rajasthan, one such lucky person who won ₹25 Lakhs* in the October Lucky Draw. You can also win Lakhs & Crores * as Cash Rewards every month. Just shop via WINDS, collect 5000 WRP,  make a coupon and participate in the upcoming November Lucky Draw to get a chance to win  ₹25 Lakhs* and other cash rewards",
								"thumbnailUrl": "https://i.ytimg.com/vi_webp/QeTjEcdoNM4/sddefault.webp",
								"uploadDate": "Nov 6, 2022",
								"duration": "PT2M17S",
								"publisher": {
									"@type": "Organization",
									"name": "WINDS E Pvt Ltd",
									"logo": {
										"@type": "ImageObject",
										"url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
									}
								},
								"contentUrl": "youtube.com/watch?v=QeTjEcdoNM4",
								"embedUrl": "https://www.youtube.com/embed/QeTjEcdoNM4",
								"interactionCount": "300,354",
								"interactionStatistic": {
									"@type": "InteractionCounter",
									"userInteractionCount": "300,354"
								}
							},
							{
								"@context": "http://schema.org",
								"@type": "VideoObject",
								"name": "Winner of GRAND CASH PRIZE of ₹ 5 LAKHS - Mr. Jitendra Kumar #KharchePeKamai",
								"description": "Here's one of the July's Lucky Draw Winners. Mr. Jitendra Kumar from Lucknow, Uttar Pradesh, who just won Rs. 5 Lakh",
								"thumbnailUrl": "https://i.ytimg.com/vi_webp/6YtHPuRNQx4/sddefault.webp",
								"uploadDate": "Aug 5, 2023",
								"duration": "PT1M11S",
								"publisher": {
									"@type": "Organization",
									"name": "WINDS E Pvt Ltd",
									"logo": {
										"@type": "ImageObject",
										"url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
									}
								},
								"contentUrl": "https://www.youtube.com/watch?v=6YtHPuRNQx4&t=1s",
								"embedUrl": "https://www.youtube.com/embed/6YtHPuRNQx4",
								"interactionCount": "87",
								"interactionStatistic": {
									"@type": "InteractionCounter",
									"userInteractionCount": "87"
								}
							}, {
								"@context": "http://schema.org",
								"@type": "VideoObject",
								"name": "I WON ₹5 LAKH IN JUNE 2023 LUCKY DRAW - Mrs. B. ANNAMMA #KharchePeKamai | WINDS App | windsapp.com",
								"description": "Here’s another of the June's 2023 Lucky Draw Winners. Mrs. B. ANNAMMA  from Vijayawada, Andhra Pradesh, who won Rs. 5 Lakh",
								"thumbnailUrl": "https://i.ytimg.com/vi_webp/mQP4RM5jkdo/sddefault.webp",
								"uploadDate": "Jul 22, 2023",
								"duration": "PT5M22S",
								"publisher": {
									"@type": "Organization",
									"name": "WINDS E Pvt Ltd",
									"logo": {
										"@type": "ImageObject",
										"url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
									}
								},
								"contentUrl": "https://www.youtube.com/watch?v=mQP4RM5jkdo",
								"embedUrl": "https://www.youtube.com/embed/mQP4RM5jkdo",
								"interactionCount": "165",
								"interactionStatistic": {
									"@type": "InteractionCounter",
									"userInteractionCount": "165"
								}
							}, {
								"@context": "http://schema.org",
								"@type": "VideoObject",
								"name": "How Can You Win Rewards via WINDS App? | WINDS App | #KharchePeKamai",
								"description": "Meet Mr Naveen Mukka Kumar aka 'The Man of Rewards' from Hanamkonda, Telangana, who, just by doing his daily shopping & making his bill payments, has won a total of ₹11.25 Lakhs till now by using the WINDS App. ",
								"thumbnailUrl": "https://i.ytimg.com/vi_webp/sllhDL75ctY/sddefault.webp",
								"uploadDate": "Aug 1, 2023",
								"duration": "PT1M56S",
								"publisher": {
									"@type": "Organization",
									"name": "WINDS E Pvt Ltd",
									"logo": {
										"@type": "ImageObject",
										"url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
									}
								},
								"contentUrl": "https://www.youtube.com/watch?v=sllhDL75ctY&t=40s",
								"embedUrl": "https://www.youtube.com/embed/sllhDL75ctY",
								"interactionCount": "25,089",
								"interactionStatistic": {
									"@type": "InteractionCounter",
									"userInteractionCount": "25,089"
								}
							}, {
								"@context": "http://schema.org",
								"@type": "VideoObject",
								"name": "I won ₹5 Lakhs by shopping on WINDS - Mrs. Priyanka Swain #KharchePeKamai",
								"description": "Here’s another of the April's Lucky Draw Winners. Mrs. Priyanka Swain from Khurda - Odisha, who just won Rs. 5 Lakh",
								"thumbnailUrl": "https://i.ytimg.com/vi_webp/Uba7M-hqr2Y/sddefault.webp",
								"uploadDate": "Jun 23, 2023",
								"duration": "PT1M42S",
								"publisher": {
									"@type": "Organization",
									"name": "WINDS E Pvt Ltd",
									"logo": {
										"@type": "ImageObject",
										"url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
									}
								},
								"contentUrl": "https://www.youtube.com/watch?v=Uba7M-hqr2Y&t=1s",
								"embedUrl": "https://www.youtube.com/embed/Uba7M-hqr2Y",
								"interactionCount": "48",
								"interactionStatistic": {
									"@type": "InteractionCounter",
									"userInteractionCount": "48"
								}
							}, {
								"@context": "http://schema.org",
								"@type": "VideoObject",
								"name": "Winner of GRAND CASH PRIZE of  ₹ 5 LAKHS - Mr. Deepak Kumar",
								"description": "Mr. Deepak Kumar, a Mobile shop keeper (Ganpati Mobile) at MI Road, Jaipur, Rajasthan is a registered WINDS partner who has won GRAND CASH PRIZE of ₹ 5 LAKHS. Heartiest Congratulations to Mr. Deepak Kumar.",
								"thumbnailUrl": "https://i.ytimg.com/vi_webp/bZKRfX4Q99M/sddefault.webp",
								"uploadDate": "Jun 22, 2023",
								"duration": "PT0M33S",
								"publisher": {
									"@type": "Organization",
									"name": "WINDS E Pvt Ltd",
									"logo": {
										"@type": "ImageObject",
										"url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
									}
								},
								"contentUrl": "https://www.youtube.com/watch?v=bZKRfX4Q99M",
								"embedUrl": "https://www.youtube.com/embed/bZKRfX4Q99M",
								"interactionCount": "18",
								"interactionStatistic": {
									"@type": "InteractionCounter",
									"userInteractionCount": "18"
								}
							}, {
								"@context": "http://schema.org",
								"@type": "VideoObject",
								"name": "WINDS से खरीददारी करके ₹5 लाख कॅश प्राइज जीता",
								"description": "मोबाइल रिचार्ज, बिजली का बिल, विंड्स रजिस्टर्ड दुकानों से घरेलु सामान WINDS App से करने पर श्री प्रेमचंद जी को प्रत्येक ट्रांसक्शन पर कॅश बैक,  रिवार्ड्स पॉइंट्स मिले। इसी प्रकार हर खरीददारी से एकत्रित 5000 पॉइंट्स पर बने कूपन से इसी जनवरी के लकी ड्रा में ₹5 लाख का बम्पर कॅश प्राइज श्री प्रेमचंद जी को प्राप्त हुआ जिसकी उन्होंने कभी कल्पना भी नहीं किया था। ",
								"thumbnailUrl": "https://i.ytimg.com/vi_webp/RFDGiXrQ_xg/sddefault.webp",
								"uploadDate": "Jun 22, 2023",
								"duration": "PT1M07S",
								"publisher": {
									"@type": "Organization",
									"name": "WINDS E Pvt Ltd",
									"logo": {
										"@type": "ImageObject",
										"url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
									}
								},
								"contentUrl": "https://www.youtube.com/watch?v=RFDGiXrQ_xg",
								"embedUrl": "https://www.youtube.com/embed/RFDGiXrQ_xg",
								"interactionCount": "24",
								"interactionStatistic": {
									"@type": "InteractionCounter",
									"userInteractionCount": "24"
								}
							}, {
								"@context": "http://schema.org",
								"@type": "VideoObject",
								"name": "I won ₹5 Lakhs by shopping on WINDS - B.AMRUTHAIAH",
								"description": "₹5 Lakhs Cash prize Winner of January Lucky Draw, Mr. B.AMRUTHAIAH from Nagarkurnool, Telangana says the story, how he won such a huge cash prize just by doing his online and offline shopping of his daily needs and regular utility bill payments using WINDS App.",
								"thumbnailUrl": "https://i.ytimg.com/vi_webp/SdiWAzVNthw/sddefault.webp",
								"uploadDate": "Jun 19, 2023",
								"duration": "PT1M34S",
								"publisher": {
									"@type": "Organization",
									"name": "WINDS E Pvt Ltd",
									"logo": {
										"@type": "ImageObject",
										"url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
									}
								},
								"contentUrl": "https://www.youtube.com/watch?v=SdiWAzVNthw",
								"embedUrl": "https://www.youtube.com/embed/SdiWAzVNthw",
								"interactionCount": "26",
								"interactionStatistic": {
									"@type": "InteractionCounter",
									"userInteractionCount": "26"
								}
							}, {
								"@context": "http://schema.org",
								"@type": "VideoObject",
								"name": "Rozmarra ki shopping se jeeta mai Rs. 5 LAKHS Cash Prize - Rantu Hazarika",
								"description": "Rantu ji to mana rahe hai Lakhon* ka jashan, aap bhi Lakhon* kamao, WINDS se shopping karo.",
								"thumbnailUrl": "https://i.ytimg.com/vi_webp/qBa_Uex1uSY/sddefault.webp",
								"uploadDate": "Jun 23, 2023",
								"duration": "PT1M03S",
								"publisher": {
									"@type": "Organization",
									"name": "WINDS E Pvt Ltd",
									"logo": {
										"@type": "ImageObject",
										"url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
									}
								},
								"contentUrl": "https://www.youtube.com/watch?v=qBa_Uex1uSY",
								"embedUrl": "https://www.youtube.com/embed/qBa_Uex1uSY",
								"interactionCount": "9",
								"interactionStatistic": {
									"@type": "InteractionCounter",
									"userInteractionCount": "9"
								}
							}, {
								"@context": "http://schema.org",
								"@type": "VideoObject",
								"name": "I won ₹ 5 Lakhs by shopping on WINDS - Mr. Amit Kumar",
								"description": "Shop like Mr. Amit Kumar  on WINDS and you too can become a Lakhpati",
								"thumbnailUrl": "https://i.ytimg.com/vi_webp/g03gXUS-Ln8/sddefault.webp",
								"uploadDate": "https://www.youtube.com/embed/g03gXUS-Ln8",
								"duration": "PT1M27S",
								"publisher": {
									"@type": "Organization",
									"name": "WINDS E Pvt Ltd",
									"logo": {
										"@type": "ImageObject",
										"url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
									}
								},
								"contentUrl": "https://www.youtube.com/watch?v=g03gXUS-Ln8&t=1s",
								"embedUrl": "https://www.youtube.com/embed/g03gXUS-Ln8",
								"interactionCount": "8",
								"interactionStatistic": {
									"@type": "InteractionCounter",
									"userInteractionCount": "8"
								}
							}, {
								"@context": "http://schema.org",
								"@type": "VideoObject",
								"name": "Winner of GRAND CASH PRIZE of  ₹ 5 LAKHS - Mr  Manish Soni",
								"description": "Being a partner with WINDS helped him win a grand prize of Rs. 5 Lakhs",
								"thumbnailUrl": "https://i.ytimg.com/vi_webp/jR3B4cLqCH0/sddefault.webp",
								"uploadDate": "Jun 20, 2023",
								"duration": "PT0M41S",
								"publisher": {
									"@type": "Organization",
									"name": "WINDS E Pvt Ltd",
									"logo": {
										"@type": "ImageObject",
										"url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
									}
								},
								"contentUrl": "https://www.youtube.com/watch?v=jR3B4cLqCH0",
								"embedUrl": "https://www.youtube.com/embed/jR3B4cLqCH0",
								"interactionCount": "22",
								"interactionStatistic": {
									"@type": "InteractionCounter",
									"userInteractionCount": "22"
								}
							}, {
								"@context": "http://schema.org",
								"@type": "VideoObject",
								"name": "WINNER OF Rs. 5 Lakhs - Mr. Sawan Kumar",
								"description": "Being a partner with WINDS helped him win a grand prize of Rs. 5 Lakhs*. You can be the next winner too.",
								"thumbnailUrl": "https://i.ytimg.com/vi/X389zPNR4MI/sddefault.jpg?s…H8gEygpMA8=&rs=AOn4CLChCQuDxKc0QkBS9iPF6E9qvvl8wg",
								"uploadDate": "Jun 20, 2023",
								"duration": "PT1M33S",
								"publisher": {
									"@type": "Organization",
									"name": "WINDS E Pvt Ltd",
									"logo": {
										"@type": "ImageObject",
										"url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
									}
								},
								"contentUrl": "https://www.youtube.com/watch?v=X389zPNR4MI",
								"embedUrl": "https://www.youtube.com/embed/X389zPNR4MI",
								"interactionCount": "12",
								"interactionStatistic": {
									"@type": "InteractionCounter",
									"userInteractionCount": "12"
								}
							}, {
								"@context": "http://schema.org",
								"@type": "VideoObject",
								"name": "Winner of  Rs. 5 Lakhs* in the November Lucky Draw - Mrs. Laxmi Kumari",
								"description": "Laxmi ji toh shopping karte karte ban gayi Lakhpati",
								"thumbnailUrl": "https://i.ytimg.com/vi_webp/87RVzCTqSUM/sddefault.webp",
								"uploadDate": "Jun 20, 2023",
								"duration": "PT0M44S",
								"publisher": {
									"@type": "Organization",
									"name": "WINDS E Pvt Ltd",
									"logo": {
										"@type": "ImageObject",
										"url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
									}
								},
								"contentUrl": "https://www.youtube.com/watch?v=87RVzCTqSUM",
								"embedUrl": "https://www.youtube.com/embed/87RVzCTqSUM",
								"interactionCount": "43",
								"interactionStatistic": {
									"@type": "InteractionCounter",
									"userInteractionCount": "43"
								}
							}, {
								"@context": "http://schema.org",
								"@type": "VideoObject",
								"name": "SMART SHOPPER Ms. Zeba Usmani wins 5 LAKHS*, JUST BY SHOPPING THROUGH WINDS",
								"description": "You too can also WIN* Lakhs*  as Cash Rewards every month. Just Shop on WINDS, collect 5000 WRP and participate in the upcoming November Lucky Draw to get a chance to win up to  ₹25 Lakhs",
								"thumbnailUrl": "https://i.ytimg.com/vi_webp/wMHxxTRetOo/sddefault.webp",
								"uploadDate": "Jun 22, 2023",
								"duration": "PT0M47S",
								"publisher": {
									"@type": "Organization",
									"name": "WINDS E Pvt Ltd",
									"logo": {
										"@type": "ImageObject",
										"url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
									}
								},
								"contentUrl": "https://www.youtube.com/watch?v=wMHxxTRetOo",
								"embedUrl": "https://www.youtube.com/embed/wMHxxTRetOo",
								"interactionCount": "29",
								"interactionStatistic": {
									"@type": "InteractionCounter",
									"userInteractionCount": "29"
								}
							}, {
								"@context": "http://schema.org",
								"@type": "VideoObject",
								"name": "Here's how I won Rs. 5 Lakhs* via the WINDS App - Rajkumar Dua",
								"description": "The September Lucky Draw proved to be a game-changer for Mr. Rajkumar Dua. Here’s his story of how he managed to shop, earn and also win the prize of Rs. 5 Lakhs",
								"thumbnailUrl": "https://i.ytimg.com/vi_webp/Ew3VT-FLf9I/sddefault.webp",
								"uploadDate": "Jun 22, 2023",
								"duration": "PT0M41S",
								"publisher": {
									"@type": "Organization",
									"name": "WINDS E Pvt Ltd",
									"logo": {
										"@type": "ImageObject",
										"url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
									}
								},
								"contentUrl": "https://www.youtube.com/watch?v=Ew3VT-FLf9I",
								"embedUrl": "https://www.youtube.com/embed/Ew3VT-FLf9I",
								"interactionCount": "15",
								"interactionStatistic": {
									"@type": "InteractionCounter",
									"userInteractionCount": "15"
								}
							}, {
								"@context": "http://schema.org",
								"@type": "VideoObject",
								"name": "WINNER OF 5 LAKH CASH REWARDS",
								"description": "₹ 5 Lakh ka reward pa kar Varanasi ke Anand Kumar ki zindagi mein chhaya hai anand hi anand! Just with regular shopping on WINDS app, he won Rs. 5 Lakhs* in the August Lucky Draw! ",
								"thumbnailUrl": "https://i.ytimg.com/vi_webp/MHDzRxLCZJ8/sddefault.webp",
								"uploadDate": "Jun 22, 2023",
								"duration": "PT1M05S",
								"publisher": {
									"@type": "Organization",
									"name": "WINDS E Pvt Ltd",
									"logo": {
										"@type": "ImageObject",
										"url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
									}
								},
								"contentUrl": "https://www.youtube.com/watch?v=MHDzRxLCZJ8",
								"embedUrl": "https://www.youtube.com/embed/MHDzRxLCZJ8",
								"interactionCount": "19",
								"interactionStatistic": {
									"@type": "InteractionCounter",
									"userInteractionCount": "19"
								}
							}, {
								"@context": "http://schema.org",
								"@type": "VideoObject",
								"name": "ROZ KI SHOPPING SE JEETE HUMNE RS. 5 LAKH- NEERAJ SRIVASTAVA, LUCKNOW, WINNER IN JULY LUCKY DRAW",
								"description": "Shop like Mr. Neeraj on WINDS and become a Lakhpati*. Listen to what he has to share.",
								"thumbnailUrl": "https://i.ytimg.com/vi_webp/FpjiWwikLDM/sddefault.webp",
								"uploadDate": "Jun 23, 2023",
								"duration": "PT0M51S",
								"publisher": {
									"@type": "Organization",
									"name": "WINDS E Pvt Ltd",
									"logo": {
										"@type": "ImageObject",
										"url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
									}
								},
								"contentUrl": "https://www.youtube.com/watch?v=FpjiWwikLDM&t=1s",
								"embedUrl": "https://www.youtube.com/embed/FpjiWwikLDM",
								"interactionCount": "17",
								"interactionStatistic": {
									"@type": "InteractionCounter",
									"userInteractionCount": "17"
								}
							}, {
								"@context": "http://schema.org",
								"@type": "VideoObject",
								"name": "I WON Rs. 5 LAKHS CASH BY JUST SHOPPING FROM WINDS, TARIQUE USMANI, DELHI JULY LUCKY DRAW WINNER",
								"description": "Usmaniji to mana rahe hai Lakhon* ka jashan, aap bhi Lakhon* kamao, WINDS se shopping karo.",
								"thumbnailUrl": "https://i.ytimg.com/vi_webp/m_0w8aWRsXs/sddefault.webp",
								"uploadDate": "Jun 23, 2023 ",
								"duration": "PT0M43S",
								"publisher": {
									"@type": "Organization",
									"name": "WINDS E Pvt Ltd",
									"logo": {
										"@type": "ImageObject",
										"url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
									}
								},
								"contentUrl": "https://www.youtube.com/watch?v=m_0w8aWRsXs",
								"embedUrl": "https://www.youtube.com/embed/m_0w8aWRsXs",
								"interactionCount": "12",
								"interactionStatistic": {
									"@type": "InteractionCounter",
									"userInteractionCount": "12"
								}
							}, {
								"@context": "http://schema.org",
								"@type": "VideoObject",
								"name": "DAILY SHOPPING HAS MADE ME WIN Rs. 5 LAKHS CASH PRIZE - MR. INDRASENA REDDY",
								"description": "Shopping can really change your life for the better. Check out what Mr. Indrasena, WINNER IN THE JUNE 2022 LUCKY DRAW, has to share.",
								"thumbnailUrl": "https://i.ytimg.com/vi_webp/nFoSUMfV-CE/sddefault.webp",
								"uploadDate": "Jun 23, 2023",
								"duration": "PT0M51S",
								"publisher": {
									"@type": "Organization",
									"name": "WINDS E Pvt Ltd",
									"logo": {
										"@type": "ImageObject",
										"url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
									}
								},
								"contentUrl": "https://www.youtube.com/watch?v=nFoSUMfV-CE",
								"embedUrl": "https://www.youtube.com/embed/nFoSUMfV-CE",
								"interactionCount": "40",
								"interactionStatistic": {
									"@type": "InteractionCounter",
									"userInteractionCount": "40"
								}
							},
							{
								"@context": "http://schema.org",
								"@type": "VideoObject",
								"name": "What Is WINDS Reward Point (WRP) & How Can You Earn? | WINDS App | windsapp.com | #KharchePeKamai",
								"description": "What Is WINDS Reward Point (WRP) & How Can You Earn? Here's a brief tutorial that will resolve all your doubts about the terms & usage of our application. Sit back, relax & enjoy the video till the end! Don't just shop but shop smart with #WINDS today!",
								"thumbnailUrl": "https://i.ytimg.com/vi/GJHSTqFG9Z0/sddefault.jpg",
								"uploadDate": "Jul 12, 2023",
								"duration": "PT1M51S",
								"publisher": {
									"@type": "Organization",
									"name": "WINDS E Pvt Ltd",
									"logo": {
										"@type": "ImageObject",
										"url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
									}
								},
								"contentUrl": "https://www.youtube.com/watch?v=GJHSTqFG9Z0",
								"embedUrl": "https://www.youtube.com/embed/GJHSTqFG9Z0",
								"interactionCount": "555",
								"interactionStatistic": {
									"@type": "InteractionCounter",
									"userInteractionCount": "555"
								}
							}, {
								"@context": "http://schema.org",
								"@type": "VideoObject",
								"name": "Refer One Time - Earn Lifetime with WINDS App | windsapp.com | #KharchePeKamai",
								"description": "Refer as many friends as you can to WINDS app, and while they collect WRP (WINDS Reward Points) on their purchases. You get to earn 10% on WINDS earnings. So more shopping by friends, more earnings for you!",
								"thumbnailUrl": "https://i.ytimg.com/vi/qlRw7TuuYrI/sddefault.jpg",
								"uploadDate": "Jul 11, 2023",
								"duration": "PT1M02S",
								"publisher": {
									"@type": "Organization",
									"name": "WINDS E Pvt Ltd",
									"logo": {
										"@type": "ImageObject",
										"url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
									}
								},
								"contentUrl": "https://www.youtube.com/watch?v=qlRw7TuuYrI",
								"embedUrl": "https://www.youtube.com/embed/qlRw7TuuYrI",
								"interactionCount": "416",
								"interactionStatistic": {
									"@type": "InteractionCounter",
									"userInteractionCount": "416"
								}
							}, {
								"@context": "http://schema.org",
								"@type": "VideoObject",
								"name": "Refer One Time - Earn Lifetime with WINDS App #KharchePeKamai",
								"description": "Refer as many friends as you can to WINDS app, and while they collect WRP (WINDS Reward Points) on their purchases. You get to earn 10% on WINDS earnings. So more shopping by friends, more earnings for you!",
								"thumbnailUrl": "https://i.ytimg.com/vi/byIwq5P3MgU/sddefault.jpg",
								"uploadDate": " Jun 30, 2023",
								"duration": "PT0M49S",
								"publisher": {
									"@type": "Organization",
									"name": "WINDS E Pvt Ltd",
									"logo": {
										"@type": "ImageObject",
										"url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
									}
								},
								"contentUrl": "https://www.youtube.com/watch?v=byIwq5P3MgU&t=1s",
								"embedUrl": "https://www.youtube.com/embed/byIwq5P3MgU",
								"interactionCount": "292,696",
								"interactionStatistic": {
									"@type": "InteractionCounter",
									"userInteractionCount": "292,696"
								}
							}, {
								"@context": "http://schema.org",
								"@type": "VideoObject",
								"name": "Presenting WINDS E-Gift Voucher | WINDS App | windsapp.com",
								"description": "Ditch the dilemma when it comes to gifting! Introducing WINDS E-Gift Voucher, a perfect gift option for your loved ones. With the WINDS E-Gift Voucher, the receiver can select from 200+ Brand Vouchers & purchase as per their wish or use them to make their Utility Bill Payments. Not just that, but both the gifter & the receiver earn WINDS Reward Points (WRP). ",
								"thumbnailUrl": "https://i.ytimg.com/vi/QbR9ep_3kjY/sddefault.jpg",
								"uploadDate": "Jul 20, 2023",
								"duration": "PT1M15S",
								"publisher": {
									"@type": "Organization",
									"name": "WINDS E Pvt Ltd",
									"logo": {
										"@type": "ImageObject",
										"url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
									}
								},
								"contentUrl": "https://www.youtube.com/watch?v=QbR9ep_3kjY&t=1s",
								"embedUrl": "https://www.youtube.com/embed/QbR9ep_3kjY",
								"interactionCount": "277",
								"interactionStatistic": {
									"@type": "InteractionCounter",
									"userInteractionCount": "277"
								}
							}, {
								"@context": "http://schema.org",
								"@type": "VideoObject",
								"name": "Claim your #RightToRewards today 😎 | WINDS App | windsapp.com | #KharchePeKamai",
								"description": "",
								"thumbnailUrl": "https://i.ytimg.com/vi/Jb5f9EAe-0I/sddefault.jpg",
								"uploadDate": "Jul 17, 2023",
								"duration": "PT0M31S",
								"publisher": {
									"@type": "Organization",
									"name": "WINDS E Pvt Ltd",
									"logo": {
										"@type": "ImageObject",
										"url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
									}
								},
								"contentUrl": "https://www.youtube.com/watch?v=Jb5f9EAe-0I",
								"embedUrl": "https://www.youtube.com/embed/Jb5f9EAe-0I",
								"interactionCount": "624",
								"interactionStatistic": {
									"@type": "InteractionCounter",
									"userInteractionCount": "624"
								}
							}, {
								"@context": "http://schema.org",
								"@type": "VideoObject",
								"name": "How To Buy Myntra Vouchers via WINDS? | WINDS App | #KharchePeKamai",
								"description": "Here's a step-by-step guide on buying Myntra Vouchers using the #WINDSApp. Earn exciting #rewards via Spin Wheel & Monthly Lucky Draw upon purchase of any vouchers. ",
								"thumbnailUrl": "https://i.ytimg.com/vi/FoZB5YmDkT8/sddefault.jpg",
								"uploadDate": "Aug 5, 2023",
								"duration": "PT1M21S",
								"publisher": {
									"@type": "Organization",
									"name": "WINDS E Pvt Ltd",
									"logo": {
										"@type": "ImageObject",
										"url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
									}
								},
								"contentUrl": "https://www.youtube.com/watch?v=FoZB5YmDkT8&t=1s",
								"embedUrl": "https://www.youtube.com/embed/FoZB5YmDkT8",
								"interactionCount": "114",
								"interactionStatistic": {
									"@type": "InteractionCounter",
									"userInteractionCount": "114"
								}
							}, {
								"@context": "http://schema.org",
								"@type": "VideoObject",
								"name": "How To Buy Flipkart Vouchers via WINDS? | WINDS App | #KharchePeKamai",
								"description": "Here's a step-by-step guide on buying Flipkart Vouchers using the #WINDSApp. Earn exciting #rewards via Spin Wheel & Monthly Lucky Draw upon purchase of any vouchers. ",
								"thumbnailUrl": "https://i.ytimg.com/vi/FocB7Pb-mz8/sddefault.jpg",
								"uploadDate": "Aug 5, 2023",
								"duration": "PT0M51S",
								"publisher": {
									"@type": "Organization",
									"name": "WINDS E Pvt Ltd",
									"logo": {
										"@type": "ImageObject",
										"url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
									}
								},
								"contentUrl": "https://www.youtube.com/watch?v=FocB7Pb-mz8",
								"embedUrl": "https://www.youtube.com/embed/FocB7Pb-mz8",
								"interactionCount": "96",
								"interactionStatistic": {
									"@type": "InteractionCounter",
									"userInteractionCount": "96"
								}
							}, {
								"@context": "http://schema.org",
								"@type": "VideoObject",
								"name": "How To Buy Amazon Vouchers via WINDS? | WINDS App | #KharchePeKamai",
								"description": "Here's a step-by-step guide on buying Amazon Vouchers using the #WINDSApp. Earn exciting #rewards via Spin Wheel & Monthly Lucky Draw upon purchase of any vouchers. ",
								"thumbnailUrl": "https://i.ytimg.com/vi/FWP8QKQoDuI/sddefault.jpg",
								"uploadDate": "Aug 5, 2023",
								"duration": "PT0M51S",
								"publisher": {
									"@type": "Organization",
									"name": "WINDS E Pvt Ltd",
									"logo": {
										"@type": "ImageObject",
										"url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
									}
								},
								"contentUrl": "https://www.youtube.com/watch?v=FWP8QKQoDuI&t=2s",
								"embedUrl": "https://www.youtube.com/embed/FWP8QKQoDuI",
								"interactionCount": "88",
								"interactionStatistic": {
									"@type": "InteractionCounter",
									"userInteractionCount": "88"
								}
							},
						]
					)}
				</script>
			}
		></HelmetMetaData>
	);
}

export default TestimonialsMetaData;