import React from "react";
import RandomBillAmoutAndWrpEarned from "./RandomBillAmtAndWrpEarned";
import Modal from "react-bootstrap/Modal";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
const WrpBenefitModel = (props) => {
  const { wrp_ratio, description } = props;
  const percentage = (wrp_ratio * 100).toFixed(0);
  return (
    <React.Fragment>
      <Modal
        show={props.isWrpBenefitModelOpen}
        onHide={props.closeWrpModelHandler}
        className="font-proxima-nova"
      >
        <Modal.Header closeButton className="border-0">
          <Modal.Title>
            {" "}
            <FormattedMessage
              id="general.wrp_benefits"
              defaultMessage="WINDS Coins Benefits"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 text-center">
              <p>{description}</p>
              {/* <p>WRP Earning: &nbsp; {"Rs 100 Spent =  " + (wrp_ratio * 100).toFixed(0) + " WRP"}</p> */}
              <hr className="w-100" />
              {/* <p className="font-proxima-nova-bold">Example:</p>
              <div className="mb-2" style={{ lineHeight: "1em" }}>
                <RandomBillAmoutAndWrpEarned
                  wrp_ratio={wrp_ratio}
                  min={100}
                  max={1000}
                  percentage={percentage}
                />
              </div>
              <hr className="w-100" />
              <div style={{ lineHeight: "1em" }}>
                <RandomBillAmoutAndWrpEarned
                  wrp_ratio={wrp_ratio}
                  min={100}
                  max={1000}
                  percentage={percentage}
                />
              </div> */}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="border-0 justify-content-center">
          <Link to="/winds-coins-details">
            <button className="btn btn-primary animated-btn py-2 px-5" style={{height:"auto"}}>
              <FormattedMessage
                id="home.know-more"
                defaultMessage="Know More"
              />
            </button>
          </Link>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default WrpBenefitModel;
