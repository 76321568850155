import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import TextField, { HelperText, Input } from '@material/react-text-field';
import profileClientX from "../../../utils/api/profileClientX";
import { toast } from "react-toastify";
import { resetProfile, setProfile } from "../../../actions";
import { resetWrp } from "../../home/actions";
import { handleCombinedErrors } from "../../../utils/helper";
import { setUserAccessToken, getUserAccessToken, setUserRefreshToken } from "../../../utils/authorization";
import InlineLoader from "../../../components/InlineLoader";
import ic_sign_in from "../../../assets/login/ic_sign_in_webapp.png";
import ic_hidden_password from "../../../assets/login/ic_hidden (1).svg";
import ic_show_password from "../../../assets/login/ic_show_password (1).svg";
import { onLoginEvent } from "../../../utils/googleTagEvents";


export default function LoginPage() {
  let history = useHistory();
  let dispatch = useDispatch();
  const { profile } = useSelector((state) => state.global)
  const [isLoading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false)
  const [activeForm, setActiveForm] = useState(1)
  const [username, setUsername] = useState({
    value: "",
    isValidate: true,
    error: "",
  });
  const [password, setPassword] = useState({
    value: "",
    isValidate: true,
    error: "",
  });
  const [mobile, setMobile] = useState({
    value: "",
    isValidate: true,
    error: "",
  })
  const [otp, setOtp] = useState({
    showField: false,
    value: "",
    isValidate: true,
    error: "",
  })
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    const token = getUserAccessToken();
    (profile && token) && history.push('/')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  var input = document.getElementById("passwordInput");
  input?.addEventListener("keypress", function (event) {
    if (event.key === "Enter") {
      event.preventDefault();
      document.getElementById("loginButton")?.click();
    }
  });

  const handleAuthentication = () => {
    const payload = { username: username.value, password: password.value };
    setLoading(true)
    profileClientX
      .post("v1/login", payload)
      .then((response) => {
        setLoading(false)
        setUserAccessToken(response?.data?.access_token)
        setUserRefreshToken(response?.data?.refresh_token)
        getProfileData();
      })
      .catch((error) => {
        setLoading(false)
        let errorMessage = handleCombinedErrors(error.response?.data?.errors)
        setErrorMessage(errorMessage)
      });
  };


  const getProfileData = () => {
    profileClientX
      .get("v1/profile")
      .then((response) => {
        const responseData = response.data?.data;
        dispatch(setProfile(responseData));
        history.push("/");
        onLoginEvent(responseData?.mobile)
      })
      .catch((error) => {
        dispatch(resetProfile());
        dispatch(resetWrp());
      });
  };

  const onSendOTP = () => {
    setLoading(true)
    profileClientX.post(`v1/send/login/otp`, {
      username: mobile.value
    })
      .then((response) => {
        setLoading(false)

        toast.success('OTP sent successfully.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        setOtp({
          ...otp,
          showField: true
        })
      })
      .catch((error) => {
        try {
          setLoading(false)
          // setMobile({ ...mobile, isValidate: false })
          let errors = error?.response?.data?.errors?.mobile;
          toast.error(errors[0], {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });

        } catch (e) {
          // toast.error("Error in sending OTP. Try again after some time.", {
          //   position: "top-right",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true
          // });
        }
      });
  };

  const onResendOTP=()=>{
    setOtp({
      ...otp,
      value: "",
      isValidate:true
    })
    // setMobile({
    //   ...mobile,
    //   isValidate:true
    // })
    onSendOTP()

  }

  const handleOtpToggle = () => {
    setActiveForm(2)
    //Auto fill Username filed to Mobile field
    if (username.isValidate) {
      setMobile({
        ...mobile,
        value: username.value
      })
    }
  }
  const handleAuthenticationByOtp = () => {
    const payload = { otp: otp.value, username: mobile.value };
    setLoading(true)
    profileClientX
      .post("/v1/verify/login/otp", payload)
      .then((response) => {
        setLoading(false)
        setUserAccessToken(response?.data?.access_token)
        setUserRefreshToken(response?.data?.refresh_token)
        getProfileData();
        history.push("/");
      })
      .catch((error) => {
        setOtp({ ...otp, isValidate: false })
        setLoading(false)
      });
  }

  const handleValidation = (fieldName, value) => {
    switch (fieldName) {
      case "username":
        setUsername({
          value: value,
          isValidate:
            null !== value.match(/^[6-9]\d{9}$/) ||
            null !== value.match(/\S+@\S+\.\S+/),
        });
        // if (value.length > 10)
        //   setUsername({
        //     ...username,
        //     value: value.slice(0, 10)
        //   })
        break;
      case "password":
        setPassword({
          value: value,
          isValidate: value.length >= 3
        });
        break;
      case "mobile":
        setMobile({
          value: value,
          isValidate: 
            null !== value.match(/^[6-9]\d{9}$/) ||
            null !== value.match(/\S+@\S+\.\S+/),
        });
        // if (value.length > 10)
        //   setMobile({
        //     ...mobile,
        //     value: value.slice(0, 10)
        //   })
        break;
      case "otp":
        setOtp({
          ...otp,
          value: value,
          isValidate: null != value.match(/^\d{6}$/)
        });
        setErrorMessage("")
        break;
      default:
        break;
    }
  };

  const loginForm = () => {
    return (
      <>
        <h2 className=" font-bold mb-3 sub-title">Login</h2>
        <div className="d-flex flex-column login-form">
          <div className="mb-4">

            <TextField
              label="Mobile / Email"
              outlined
              helperText={!username.isValidate && <HelperText className="text-red">Enter valid username</HelperText>}
              className="w-100 "
            >
              <Input
                isValid={username.isValidate}
                value={username.value}
                onChange={(e) => handleValidation('username', e.target.value)}
              />
            </TextField>
          </div>
          <div className="mb-2 w-100" >
            <TextField
              label="Password"
              outlined
              helperText={!password.isValidate && <HelperText className="text-red">Enter valid password</HelperText>}
              className="w-100"
              trailingIcon={
                <i className='material-icons cursor-pointer' tabIndex={0} role="button" >
                  <p className="mb-0" onClick={() => setShowPassword(!showPassword)}>
                    <img src={showPassword ? ic_show_password : ic_hidden_password} alt="eye_icon" title="eye_icon" />
                  </p>
                </i>
              }
            >
              <Input
                type={showPassword ? "text" : "password"}
                isValid={password.isValidate}
                value={password.value}
                onChange={(e) => handleValidation('password', e.target.value)}
                id="passwordInput"
              />

            </TextField>
          </div>
          <div className="d-flex justify-content-between">
            <div className="form-group form-check">
              <input type="checkbox" className="form-check-input" id="exampleCheck1" />
              <label className="form-check-label" htmlFor="exampleCheck1">
                <small>Remember Me</small>
              </label>
            </div>
            <div className="d-flex justify-content-center align-items-center pb-2">
              <p className="m-0 cursor-pointer" style={{ color: "#203BCF" }} onClick={() => history.push('/forgot-password')}>
                <small><strong>Forgot Password?</strong></small>
              </p>
            </div>
          </div>
          <p className="text-center" style={{ color: "red" }}><small>{errorMessage}</small></p>
          {isLoading ?
            <div className="mx-auto"><InlineLoader /></div>
            :
            <button className={username.isValidate && password.isValidate && password.value !== "" && username.value !== "" ? "animated-btn" : "animated-btn-disabled"} id="loginButton" onClick={() => handleAuthentication()}>SIGN IN </button>
          }
          <p className="text-center mb-0 py-3">OR</p>
          <p style={{ color: "#203BCF", width: "fit-content" }} className="text-center font-bold cursor-pointer mx-auto mb-2" onClick={() => handleOtpToggle()}>LOGIN USING OTP</p>
          <label className="text-center pt-1"> Don't have an account? <span style={{ color: "#203BCF" }} className="font-bold cursor-pointer" onClick={() => history.push('/signup')}>
            Sign Up
          </span>
          </label>
        </div>

      </>
    )
  }

  const otpLoginForm = () => {
    return (
      <>
        <h2 className=" font-bold mb-3 sub-title">OTP Login</h2>
        <div className="d-flex flex-column login-form">
          <div className="mb-4">
            <TextField
              label="Mobile or Email"
              outlined
              helperText={!mobile.isValidate && <HelperText className="text-red">Enter valid Mobile Number</HelperText>}
              className="w-100 "
            >
              <Input
                isValid={mobile.isValidate}
                value={mobile.value}
                onChange={(e) => handleValidation('mobile', e.target.value)}
                readOnly={otp?.showField && true}
              />
            </TextField>
          </div>
          {otp.showField &&
            <div className="mb-4">
              <TextField
                label="OTP"
                outlined
                helperText={!otp.isValidate && <HelperText className="text-red">Enter valid OTP</HelperText>}
                className="w-100 "
              >
                <Input
                  isValid={otp.isValidate}
                  value={otp.value}
                  onChange={(e) => handleValidation('otp', e.target.value)}
                />
              </TextField>
               <div className="d-flex justify-content-end align-items-center pb-2 mt-2">
              <p className="m-0 cursor-pointer" style={{ color: "#203BCF" }} onClick={() => onResendOTP()}>
                <small><strong>Resend OTP</strong></small>
              </p>
            </div>
            </div>
          }
          {/* <p className="text-center" style={{ color: "red" }}><small>{errorMessage}</small></p> */}
          {otp.showField ?
            <>
              {isLoading ?
                <div className="text-center"><InlineLoader /></div> :
                <button className={mobile.isValidate && mobile.value !== "" && otp.isValidate && otp.value !== "" ? "animated-btn" : "animated-btn-disabled"} onClick={() => handleAuthenticationByOtp()}>SUBMIT</button>
              }
            </>
            :
            <>
              {isLoading ?
                <div className="text-center"><InlineLoader /></div> :
                <button className={mobile.isValidate && mobile.value !== "" ? "animated-btn" : "animated-btn-disabled"} onClick={() => onSendOTP()}>SEND OTP</button>
              }
            </>
          }
          <label className="text-center pt-3"> First time user? <span style={{ color: "#203BCF" }} className="font-bold cursor-pointer" onClick={() => history.push('/signup')}>
            Register Here
          </span>
          </label>
        </div>
      </>
    )
  }


  return (
    <>
      <div className="container-fluid ">
        <div className="row flex flex-row-reverse" style={{minHeight:"90vh"}}>
          <div className="col-12 col-md-5 px-3 px-md-5 home-page">

            {(1 === activeForm) && loginForm()}
            {(2 === activeForm) && otpLoginForm()}

          </div>
          <div className="col-12 col-md-7 d-flex justify-content-center align-items-center" style={{ background: "linear-gradient(180deg, rgba(240,238,254,1) 0%, rgba(206,197,241,1) 100%)" }} >
            <div className="w-50 py-5">
              <img src={ic_sign_in} alt="sign-in-logo" title="sign-in-logo" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
