import Modal from "react-bootstrap/Modal";
import React from "react";

const ErrorModal = (props) => {
  const { message, buttonTxt, title } = props;
  return (
    <React.Fragment>
      <Modal show onHide={props.onDismiss}>
        {title && (
          <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
        )}

        <Modal.Body className="text-center p-5 font-proxima-nova">
          {message}
          <button
            className="btn btn-primary home-btn mt-3"
            onClick={props.onDismiss}
          >
            {buttonTxt}
          </button>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};
export default ErrorModal;
