const INITIAL_STATE = {
  query: "",
  results: [],
  count: 0,
  isSearching: false
};

function searchReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SEARCH":
      return {
        ...state,
        query: action.query,
        results: action.results,
        count: state.count + 1,
        isSearching: false
      };
    case "SET_QUERY":
      return {
        ...state,
        query: action.query
      };
    case "UPDATE_SEARCH_STATUS":
      return {
        ...state,
        isSearching: action.status
      };
    case "RESET_SEARCH_RESULT":
      return {
        ...state,
        results: [],
        count:0
      };
    default:
      return state;
  }
}

export default searchReducer;
