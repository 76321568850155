import React from "react";
// import emptyStateVector from "../assets/voucher/ic_no_result_found.svg";

const EmptyState = (props) => {
  const { title, description, imageWidth, image } = props;
  return (
    <div className="container font-proxima-nova my-5">
      <div className="row">
        <div className="col-12">
          <img
            src={image}
            alt="Empty Element"
            title="Empty Element"
            className="d-block m-auto"
            style={{
              width: `${imageWidth}%`,
            }}
          />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12 text-center">
          <span className="no-offers-title mb-2 h5">{title}</span>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};
export default EmptyState;
