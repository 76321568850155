import React, { useEffect, useRef, useState } from 'react'
import ic_destination from "../../../assets/travel/ic_destination.png"
import AdultIcon from "../../../assets/Adult.svg"
import ChildrenIcon from "../../../assets/Children.svg"
import calenderIcon from "../../../assets/calender.svg"
import travellerIcon from "../../../assets/No.of traveller.svg"
import ic_hotel from "../../../assets/travel/ic_hotel.png"
import ic_rooms from "../../../assets/travel/ic_rooms.png"
import no_result from "../../../assets/travel/ic_no_result.png"
import searchResults from "../../../assets/travel/ic_search_results.png"
import popularDestinations from "../../../assets/travel/ic_populer_destinations.png"
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import format from 'date-fns/format';
import { OverlayTrigger } from "react-bootstrap";
import customerCommonClientX from '../../../utils/api/customerCommonClientX'
import { toast } from 'react-toastify'
import { useHistory } from "react-router-dom";
import { setTravelData } from '../actions'
import { useDispatch, useSelector } from 'react-redux'
import { DateRangePicker } from 'react-date-range'
import { addDays } from 'date-fns'
import LoginDialog from '../../../components/LoginDialog'

function Hotel(props) {
  let history = useHistory();
  let dispatch = useDispatch();
  const profile = useSelector((state) => state?.global?.profile);

  const [rooms, setRooms] = useState([1]);
  //create room data for 7 rooms
  const [roomData, setRoomData] = useState([1, 2, 3, 4, 5, 6, 7].map((item) => {
    return {
      adults: 1,
      children: 0,
      childAge: [0, 0]
    }
  }))
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState("");
  const [searchCity, setSearchCity] = useState("")
  const [showCity, setShowCity] = useState(false);
  const [showTravellerInfo, setShowTravellerInfo] = useState(false)
  const [showTravelDate, setShowTravelDate] = useState(false)
  const [hotelDataLoading, setHotelDataLoading] = useState(false);
  const [hotelData, setHotelData] = useState(null);
  const [popularCities, setPopularCities] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null)
  const [isLoginDialogOpen, setLoginDialog] = useState(false)
  const [count, setCount] = useState(1)
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 1),
      key: 'selection'
    }
  ]);

  const flyingFromref = useRef(null);
  const travellerInfoRef = useRef(null)
  const departDateRef = useRef(null)
  let ref = useRef(null);

  useEffect(() => {
    initHotelDataOnLoading()
    document.addEventListener("click", hideDestinationsOnClickOut, true)
    document.addEventListener("click", hideTravellerInfoClickOut, true)
    document.addEventListener("click", hideTravelDateOut, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateData = (adultCount) => {
    let childrenCount = 0
    // eslint-disable-next-line array-callback-return
    roomData.map((item, index) => {
      if (index < rooms.length) {
        adultCount = adultCount + parseInt(roomData[index].adults)
        childrenCount = childrenCount + parseInt(roomData[index].children)
        setAdults(adultCount)
        setChildren(childrenCount)
      }
    })
  }

  const initHotelDataOnLoading = () => {
    setHotelDataLoading(true)
    customerCommonClientX.get("v1/flight/home")
      .then((response) => {
        let data = response.data.data;
        dispatch(setTravelData(data))
        setHotelData(data)
        setPopularCities(data?.hotel?.popular_city)
        setHotelDataLoading(false)
        setSelectedCity(data?.hotel?.popular_city[0])
      }).catch(error => {
        toast.error("Something went wrong! Please try again after sometime.")
        setHotelDataLoading(false)
      })
  }

  const searchHotelsData = (searchParams) => {
    if (searchParams.length > 0) {
      customerCommonClientX.get(`v1/hotel/autocomplete?search=${searchParams}`)
        .then((response) => {
          let data = response.data.data;
          setPopularCities(data?.cities)
        }).catch(error => {
          toast.error("Something went wrong! Please try again after sometime.")
        })
    }
    else {
      setPopularCities(hotelData?.hotel?.popular_city)
    }
  }

  const searchHotels = () => {
    let adultsParams = []
    let childrenParams = []
    let childAgeParams = []
    // eslint-disable-next-line array-callback-return
    roomData.map((room, index) => {
      if (index < rooms.length) {
        adultsParams.push(room.adults)
        childrenParams.push(room.children)
        childAgeParams.push(room.childAge[0].toString())
        parseInt(room.children) > 1 && childAgeParams.push(room.childAge[1].toString())
        childAgeParams.push('&')
      }
    })
    adultsParams = adultsParams.toString()
    childrenParams = childrenParams.toString()
    childAgeParams = childAgeParams.join().replaceAll(",&,", "&")
    childAgeParams = childAgeParams.replace(",&", "")

    if (!profile) {
      openLoginDialog()
    }
    else {
      let requestPayload = {
        "dest": selectedCity?.latinFullName,
        "cityId": selectedCity?.regionid,
        "checkIn": format(date[0].startDate, "yyyy-MM-dd"),
        "checkOut": format(date[0].endDate, "yyyy-MM-dd"),
        "rooms": rooms.length,
        "adults": adultsParams,
        "children": childrenParams,
        "nationality": "indian",
        "countryCode": selectedCity.CountryCode,
        "childAge": childAgeParams
      }

      customerCommonClientX.post("v1/hotel/search-result", requestPayload)
        .then((response) => {
          let data = response.data.data;
          let requestParams = data?.visit_url.replace(process.env.REACT_APP_TRAVEL_SERVICE_URL, "");
          requestParams = requestParams.replace("isMobile=1", "isMobile=0");
          history.push(`${requestParams}`)

        }).catch(error => {
          toast.error(error.response?.data?.message)
          setHotelDataLoading(false)
        })
    }
  }

  const Shimmer = (props) => {
    return (
      <React.Fragment>
        <div className='container' style={{ zIndex: "999" }}>
          <div className='row'>
            <div className='col-12 col-md-12 mx-auto'>
              <div className="shine slider-shimmer shine-black" style={{ height: "100px", background: "rgb(0 0 0 / 29%)" }}></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const TravellerPopover = (
    <div className='main_model hotel'>
      <h5 className='mb-1 text-center font-weight-bold'>Add Travellers</h5>
      <div className='total_travel mt-2 mb-3'>
        <div className='sub_total'>
          <img src={travellerIcon} alt="travel" title='travel' />
          <h2 className='numoftraveler'>Number of Travellers<br /><h1 className='model_text'>{adults || 0} Adults, {children || 0} Children</h1></h2>
        </div>
      </div>
      <div className='all-room'>
        {
          rooms.map((room, index) => {
            return (
              index < 7 &&
              <div className="single-room border-bottom" id={room} key={room}>
                <div className='d-flex justify-content-between'>
                  <h1 className='model_text'> Room {room}</h1>
                  {index !== 0 &&
                    <h1 className='model_text cursor-pointer' style={{ color: "red" }} id={room} key={room} onClick={(e) => {
                      rooms.pop()
                      updateData(0)
                    }}>Delete</h1>
                  }
                </div>
                <div className='model_box py-2'>
                  <div className='text_box align-items-center'>
                    <img className='' src={AdultIcon} alt="adult" title='adult' />
                    <div className='d-flex flex-column align-items-start'>
                      <h1 className='model_text'> Adults</h1>
                    </div>
                  </div>
                  <div>
                    <select style={{ height: "inherit" }} className="form-control px-3 py-0"
                      value={roomData[index].adults}
                      onChange={(event) => {
                        const newRoomData = [...roomData];
                        newRoomData[index].adults = event.target.value;
                        setRoomData(newRoomData);
                        updateData(0)
                      }}
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </select>
                  </div>
                </div>
                <div className='model_box py-2'>
                  <div className='text_box align-items-center'>
                    <img src={ChildrenIcon} alt="children" title='children' />
                    <div className='d-flex flex-column align-items-start'>
                      <h1 className='model_text'>Children</h1>
                      {/* <h2 className='pop-over'>2-12 years</h2> */}
                    </div>
                  </div>
                  <div>
                    <select style={{ height: "inherit" }} className="form-control px-3 py-0"
                      value={roomData[index].children}
                      onChange={(event) => {
                        // setRoomData({ ...roomData, children: event.target.value });
                        const newRoomData = [...roomData];
                        newRoomData[index].children = event.target.value;
                        setRoomData(newRoomData);
                        updateData(0)
                        if (event.target.value === "0") {
                          const newRoomData = [...roomData];
                          newRoomData[index].childAge[0] = "0";
                          newRoomData[index].childAge[1] = "0";
                          setRoomData(newRoomData);
                        }
                        else if (event.target.value === "1") {
                          const newRoomData = [...roomData];
                          newRoomData[index].childAge[1] = "0";
                          setRoomData(newRoomData);
                        }
                      }}
                    >
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                    </select>
                  </div>
                </div>
                <div className='model_box py-2'>
                  {parseInt(roomData[index].children) >= 1 &&
                    <div className='text_box align-items-center'>
                      <div className='d-flex flex-column align-items-start'>
                        <span className='pb-2'>Age Below 12 years</span>
                        <div className="">
                          <select style={{ height: "inherit" }} className="form-control px-3 py-0"
                            value={roomData[index].childAge[0]}
                            onChange={(event) => {
                              // setRoomData({ ...roomData, childAge: event.target.value });
                              const newRoomData = [...roomData];
                              newRoomData[index].childAge[0] = event.target.value;
                              setRoomData(newRoomData);
                            }}
                          >
                            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((value) => {
                              return (
                                <option value={value}>{value}</option>
                              )
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  }
                  {parseInt(roomData[index].children) >= 2 &&
                    <div className='text_box align-items-center'>
                      <div className='d-flex flex-column align-items-start'>
                        <span className='pb-2'>Age Below 12 years</span>
                        <div className="">
                          <select style={{ height: "inherit" }} className="form-control px-3 py-0"
                            value={roomData[index].childAge[1]}
                            onChange={(event) => {
                              const newRoomData = [...roomData];
                              newRoomData[index].childAge[1] = event.target.value;
                              setRoomData(newRoomData);
                            }}
                          >
                            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((value) => {
                              return (
                                <option value={value}>{value}</option>
                              )
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            )
          })
        }
      </div>
      <div
        style={{ color: "#4455ff" }}
        className='d-flex justify-content-start mt-2 cursor-pointer font-weight-bold'
        onClick={() => {
          updateData(1)
          setRooms(rooms.concat(rooms.length + 1))
        }}>+ Add Room</div>

      <div className='d-flex justify-content-center mt-3'>
        <button className='done_btn' onClick={() => {
          setShowTravellerInfo(!showTravellerInfo)
          document.body.click()
        }}>DONE</button>
      </div>
    </div>
  );

  const onChangeCityFrom = (e) => {
    setSearchCity(e.target.value)
    searchHotelsData(e.target.value)
  }

  const onSelectCityFrom = (searchTerm) => {
    let selectedCity = popularCities?.filter(item => item.latinFullName === searchTerm)
    setSelectedCity(selectedCity[0])
  }

  const hideDestinationsOnClickOut = (e) => {
    if (flyingFromref.current && !flyingFromref.current.contains(e.target)) {
      setShowCity(false)
    }
  }


  const hideTravellerInfoClickOut = (e) => {
    if (travellerInfoRef.current && !travellerInfoRef.current.contains(e.target)) {
      setShowTravellerInfo(false)
    }
  }

  const hideTravelDateOut = (e) => {
    if (departDateRef.current && !departDateRef.current.contains(e.target)) {
      setShowTravelDate(false)
    }
  }

  const NoResults = () => {
    return (
      <div className='d-flex flex-column justify-content-center align-items-center mt-5 mb-2'>
        <img src={no_result} width="75%" alt="no result" title="no result" />
        <p className='mt-2'>No result found!</p>
      </div>
    )
  }

  const PopoverFlyingFrom = (
    <div className='main'>
      <i className="fa fa-search" aria-hidden="true"></i>
      <input className='input_box' type='text' value={searchCity} onChange={(event) => onChangeCityFrom(event)} placeholder='Enter City Name' />
      <div className='location'>
        {searchCity === "" ?
          <div className='d-flex align-items-center my-2'>
            <img src={popularDestinations} style={{ margin: "0px 6px 0px 17px" }} width="20px" alt="results" title="results" />
            <text className='popular_destination'>Popular Destinations</text>
          </div> :
          <div className='d-flex align-items-center my-2'>
            <img src={searchResults} style={{ margin: "0px 6px 0px 17px" }} width="20px" alt="results" title="results" />
            <text className='popular_destination'>Search results</text>
          </div>
        }
        {
          popularCities && popularCities.length === 0 ?
            <NoResults /> :
            popularCities?.map((item, index) => {
              return (
                <div className='title_name cursor-pointer d-flex align-items-center'
                  onClick={() => {
                    onSelectCityFrom(item.latinFullName)
                    setShowTravellerInfo(!showTravellerInfo)
                    document.body.click()
                  }}
                  key={index}>
                  <img src={ic_hotel} width="15px" alt="hotel" title='hotel' className='mr-2' />
                  <div className='d-flex flex-column'>
                    <span className='font-weight-bold'>{item.latinFullName}</span>
                  </div>
                </div>
              )
            })}
      </div>
    </div>
  )

  const travelDatePopover = (
    <div className="d-flex flex-column">
      <DateRangePicker
        onChange={item => {
          setDate([item.selection])
          setTimeout(() => {
            setCount(count + 1)
            if (count % 2 === 0)
              document.body.click()
          }, 10);
        }}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={date}
        direction={window.innerWidth > 767 ? "horizontal" : "vertical"}
        minDate={new Date()}
      />
    </div>
  )


  const openLoginDialog = () => {
    setLoginDialog(true)
  };

  const closeLoginDialog = () => {
    setLoginDialog(false)
  };

  return (
    <div className='gridinner_flight container p-3 py-4'>
      {hotelDataLoading ?
        <Shimmer /> :
        <div className='row'>
          <div className='col-12'>
            <div className='inner-part flight row'>
              <div className='gridfirst'>
                <div className='g-col-6 flight position-relative'>
                  <div className='w-100' ref={flyingFromref} onClick={() => setShowCity(!showCity)}>
                    <p className='d-flex mb-1 text-white'>Where are you travelling</p>
                    <OverlayTrigger
                      ref={r => (ref = r)}
                      container={ref.current}
                      trigger="click"
                      placement="bottom"
                      overlay={PopoverFlyingFrom}
                      rootClose
                    >
                      <div className='g-col-3 start destination' style={{ borderRadius: "12px 0 0 12px" }} ref={flyingFromref} onClick={() => setShowCity(!showCity)}>
                        <img className='cal' src={ic_destination} alt="icon" title='icon' />
                        <div className='booking-date d-flex flex-column justify-content-center'>
                          <h5 className='m-0 font-weight-bold stored-value-heading'>{selectedCity?.latinFullName || (popularCities && popularCities[0]?.latinFullName)}</h5>
                        </div>
                      </div>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
              <div className='gridmiddle2'>
                <div className='g-col-4 middle mob'>
                  <>
                    <div className='w-100' ref={departDateRef} onClick={() => setShowTravelDate(!showTravelDate)}>
                      <p className='d-flex mb-1 text-white'>Travel Dates</p>
                      <OverlayTrigger
                        ref={r => (ref = r)}
                        container={ref.current}
                        trigger="click"
                        placement="bottom"
                        overlay={travelDatePopover}
                        rootClose
                      >
                        <div className='g-col-2 new travel-dates'>
                          <img className='cal' src={calenderIcon} alt="calenderIcon" title='calenderIcon'/>
                          <div className='booking-date d-flex flex-column justify-content-center' >
                            <div className='d-flex align-items-center'>
                              <p className='model_text m-0 pt-0'>{(date && format(date[0].startDate, "dd MMM yyyy")) || ""}</p>
                              <p className='model_text m-0 pt-0 px-2'>{"-"}</p>
                              <p className='model_text m-0 pt-0'>{" "} {(date && format(date[0].endDate, "dd MMM yyyy")) || ""}</p>

                            </div>
                          </div>
                        </div>
                      </OverlayTrigger>
                    </div>
                    <div className='w-100'>
                      <p className='d-flex mb-1 text-white'>Travellers</p>
                      <div className='g-col-2 new min-book rooms' ref={travellerInfoRef} onClick={() => setShowTravellerInfo(!showTravellerInfo)}>
                        <OverlayTrigger
                          ref={r => (ref = r)}
                          container={ref.current}
                          trigger="click"
                          placement="bottom"
                          overlay={TravellerPopover}
                          rootClose
                        >
                          <div className='booking-date d-flex flex-column justify-content-center'>
                            <div className='model_box'>
                              <div className='text_box align-items-center'>
                                <img className='room-img' src={ic_rooms} alt="rooms" title="rooms" />
                                <div className='d-flex flex-column align-items-start'>
                                  <h1 className='model_text'> {rooms.length} Rooms, {adults || 0} Adults, {children || 0} Children</h1>
                                </div>
                              </div>
                            </div>
                          </div>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </>
                </div>
              </div>
              <div className='search-travel d-flex align-self-end' onClick={() => searchHotels()}>Search</div>
              {isLoginDialogOpen && (
                <LoginDialog
                  isLoginDialogOpen={isLoginDialogOpen}
                  closeLoginDialog={closeLoginDialog}
                />
              )}
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default Hotel;
