import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import OtpInput from "react-otp-input";
// import otpClientX from "../utils/api/otpClientX";
import profileClientX from "../utils/api/profileClientX"
import { showToastMessage } from "../utils/helper";
import { FormattedMessage } from "react-intl";
import { useEffect } from "react";


const OtpValidationModal = (props) => {
  // const { profile } = useSelector((state) => state?.global)
  const [otp, setOTP] = useState('');
  const [otpVerificationMessage, setOtpVerificationMessage] = useState("");
  const [renderTime, setRenderTime] = useState('');
  const [otpLoad, setOtpLoading] = useState(false);
  const [disableResendBtn, setDisableResendBtn] = useState(false);

  useEffect(() => {
    getOTP()
  }, [])

  const getOTP = () => {
    setOtpLoading(true);
    setDisableResendBtn(true)
    setTimeout(() => {
      setDisableResendBtn(false)
    }, 30*1000); //disable resend btn for 30 sec
    profileClientX.post(`/v1/order-verification-otp`)
      .then((response) => {
        setOtpLoading(false);
        if (response.data.success) {
          showToastMessage(
            "success",
            <FormattedMessage id="wallet.otp-sent" defaultMessage="OTP Sent Successfully" />
          );
        }
      }).catch(error => {
        setOtpLoading(false);
      })
  }
  const handleResendOTP = () => {
    setOTP('')
    setOtpVerificationMessage('')
    setRenderTime(Date.now())
    getOTP()
  }

  const verifyOTP = () => {
    let payload = {
      // mobile: profile?.mobile,
      otp: otp
    }

    profileClientX.post(`/v1/verify-order_verification-otp`, payload)
      .then((response) => {
        if (response.data.success) {
          props.onVerifyOTP(true)
          props.closeOtpValidationModal()
          setOTP("");
        }
      }).catch(error => {
        if (error.response.data?.errors?.otp)
          setOtpVerificationMessage(error.response.data?.errors?.otp)
        else
          setOtpVerificationMessage(error.response.data?.message)
      })
  }

  return (
    <React.Fragment>
      <Modal
        show={props.isOtpValidationModalOpen}
        // onHide={props.closeOtpValidationModal}
        className="font-proxima-nova"
        centered
      >
        <Modal.Body className="p-md-5 p-4">
          <button className="btn-close-card-type " onClick={props.closeOtpValidationModal}>
            <i className="fa fa-close"></i>
          </button>
          <h4 className="text-center pb-3 font-weight-bold h4 w-100">Enter Validation OTP</h4>
          <span className="text-center pb-4 h5 d-block">An 6 digit code has been sent to your <strong>Registered Mobile Number</strong></span>
          <div className="d-flex flex-col justify-content-between w-100 ">
            {otpLoad ?
              <div className="box shine w-100 mt-md-4 mt-3" style={{ height: "70px" }}></div> :
              <div className="w-100 mt-4 d-flex justify-content-center align-items-center cart-otp-box">
                <OtpInput
                  value={otp}
                  onChange={setOTP}
                  numInputs={6}
                  separator={<span></span>}
                  className="otp-input"
                  key={renderTime}
                  isInputNum={true}
                  shouldAutoFocus={true}
                  inputStyle={{
                    width: "auto",
                    margin: "0.3rem",
                    marginTop: "0rem",
                    fontSize: "2rem",
                    backgroundColor: "#f6f6f6",
                    borderWidth: 0,
                    borderRadius: ".2rem",
                    inputType: "number"
                  }}
                />
              </div>
            }
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center w-100">
            <p className="validation-error-text" style={{ color: "red" }}>
              <small>{otpVerificationMessage}</small>
            </p>
            {disableResendBtn?
            <p className="text-blue mt-3" style={{opacity:"50%"}}>Resend OTP</p>:
            <p className="cursor-pointer text-blue mt-3" onClick={() => handleResendOTP()}>Resend OTP</p>
            }
            <button
              disabled={otp.toString().length < 6}
              className={`animated-btn py-1 px-5 ${otp.toString().length < 6 ? "animated-btn-disabled" : ""}`}
              style={{ height: "50px", width: "auto" }}
              onClick={() => verifyOTP()}
            >VERIFY & PAY</button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default OtpValidationModal;
