import HelmetMetaData from "../components/HelmetMetaData";
import { useLocation } from "react-router-dom";

function HomeMetaData() {
  let location = useLocation();
  let currentAppUrl = process.env.REACT_APP_URL + location.pathname;
  return (
    <HelmetMetaData
      title={`Online Shopping, Recharge, Bill Payments, Brand Vouchers | WINDS App`}
      description={`Shop from Online Shopping Sites, Recharge & Pay Utility Bills, Buy Brand Vouchers or Shop from Nearby Retail Stores. Collect 5,000 WRP & stand a chance to win up to Lakhs* every month on your regular shopping at WINDS App.`}
      keywords={`gift cards, vouchers, brand vouchers, e-gift cards, gift vouchers, online discounts, online deals, e-gift cards, e-vouchers, discount vouchers, buy gift card, gift voucher, winds app gift cards, winds app gift vouchers, winds app vouchers.`}
      voiceSchema={<script type="application/ld+json">
        {JSON.stringify(
          {
            "@context": "https://schema.org/",
            "@type": "WebPage",
            "name": "Online Shopping, Recharge, Bill Payments, Brand Vouchers | WINDS App",
            "speakable":
            {
              "@type": "SpeakableSpecification",
              "xpath":
                [
                  "/html/head/title",
                  "/html/head/meta[@name='description']/@content"
                ]
            },
            "url": currentAppUrl
          }
        )}
      </script>
      }
      videoSchema={
        <script type="application/ld+json">
          {JSON.stringify(
            [
              {
                "@context": "http://schema.org",
                "@type": "VideoObject",
                "name": "Listen to what the Babbar Sher 🦁 from New Delhi has to say | WINDS App | #KharchePeKamai",
                "description": "We are bringing the 'WINDS of Change' to Shopper's & Customer's lives. Everything is possible on this super app, from buying 200+ Brand Vouchers to making payments for Utility Bills to Shopping Online or Paying at your Nearby Stores. Use the WINDS App for your daily shopping or bill payments, earn* on every transaction via Spin Wheel & also win up to Lakhs* monthly via Monthly Lucky Draw. ",
                "thumbnailUrl": "https://i.ytimg.com/vi/qeT9PDKd3y8/sddefault.jpg",
                "uploadDate": "Aug 3, 2023",
                "duration": "PT1M52S",
                "publisher": {
                  "@type": "Organization",
                  "name": "WINDS E Pvt Ltd",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
                  }
                },
                "contentUrl": "https://www.youtube.com/watch?v=qeT9PDKd3y8&t=4s",
                "embedUrl": "https://www.youtube.com/embed/qeT9PDKd3y8",
                "interactionCount": "53,592",
                "interactionStatistic": {
                  "@type": "InteractionCounter",
                  "userInteractionCount": "53,592"
                }
              },
              {
                "@context": "http://schema.org",
                "@type": "VideoObject",
                "name": "Deepak Gupta - ₹25 Lakhs Grand Prize Winner - June 2023 | WINDS App | windsapp.com",
                "description": "Mr. Deepak Gupta from Delhi, NCR, emerged as the fortunate winner of the Mega Cash prize of Rs. 25 Lakhs in the WINDS Lucky Draw for June 2023. His winnings were the result of his regular shopping for daily needs from grocery stores, online platforms, and offline shops, as well as performing tasks such as mobile recharge, paying electricity bills, and purchasing brand vouchers using the WINDS App (windsapp.com).",
                "thumbnailUrl": "https://i.ytimg.com/vi_webp/o5nl4yL3KWw/maxresdefault.webp",
                "uploadDate": "Jul 8, 2023",
                "duration": "PT1M52S",
                "publisher": {
                  "@type": "Organization",
                  "name": "WINDS E Pvt Ltd",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
                  }
                },
                "contentUrl": "https://youtu.be/o5nl4yL3KWw",
                "embedUrl": "https://www.youtube.com/embed/o5nl4yL3KWw",
                "interactionCount": "353,740",
                "interactionStatistic": {
                  "@type": "InteractionCounter",
                  "userInteractionCount": "353,740"
                }
              },
              {
                "@context": "http://schema.org",
                "@type": "VideoObject",
                "name": "J. Sriramulu - Lucky Winner Of Grand Prize of ₹25 Lakhs For May 2023 | WINDS App | #KharchePeKamai",
                "description": "Shopping for daily needs from grocery stores, online stores & offline shops, doing mobile recharge & paying electricity bills and buying  brand vouchers using WINDS App made Mr. J. Sriramulu from Wanaparthy, Telangana, Winner of Mega Cash prize of Rs.25 Lakhs in WINDS Lucky Draw for the month of May''23.You can also win Lakhs as Cash Rewards in the June Lucky Draw without any extra effort.",
                "thumbnailUrl": "https://i.ytimg.com/vi_webp/2hlDcLn6FU4/sddefault.webp",
                "uploadDate": "Jun 8, 2023",
                "duration": "PT2M00S",
                "publisher": {
                  "@type": "Organization",
                  "name": "WINDS E Pvt Ltd",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
                  }
                },
                "contentUrl": "https://www.youtube.com/watch?v=2hlDcLn6FU4&t=1s",
                "embedUrl": "https://www.youtube.com/embed/2hlDcLn6FU4",
                "interactionCount": "16,418",
                "interactionStatistic": {
                  "@type": "InteractionCounter",
                  "userInteractionCount": "16,418"
                }
              },
              {
                "@context": "http://schema.org",
                "@type": "VideoObject",
                "name": "Grand Winner Of ₹25 Lakhs | WINDS App | #KharchePeKamai",
                "description": "Meet Mr. Manoranjan, the grand cash prize winner from Sambalpur, Odisha, who has won ₹25 Lakhs in April's Monthly Lucky Draw. Our app has most of your favourite brands & payment options available that can fulfill your day-to-day needs.",
                "thumbnailUrl": "https://i.ytimg.com/vi/uP438YwmqNo/sddefault.jpg",
                "uploadDate": "May 9, 2023",
                "duration": "PT2M22S",
                "publisher": {
                  "@type": "Organization",
                  "name": "WINDS E Pvt Ltd",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
                  }
                },
                "contentUrl": "https://www.youtube.com/watch?v=uP438YwmqNo",
                "embedUrl": "https://www.youtube.com/embed/uP438YwmqNo",
                "interactionCount": "22,615",
                "interactionStatistic": {
                  "@type": "InteractionCounter",
                  "userInteractionCount": "22,615"
                }
              },
              {
                "@context": "http://schema.org",
                "@type": "VideoObject",
                "name": "Grand Winner Of ₹25 Lakhs | WINDS App | #KharchePeKamai",
                "description": "Meet Mr. Guru, the grand winner from Banglore, who just won ₹25 Lakhs in March's Lucky Draw. Our app has most of your favourite brands & payment options available that can fulfill your day-to-day needs.",
                "thumbnailUrl": "https://i.ytimg.com/vi_webp/W7Iat6Cx2Hg/sddefault.webp",
                "uploadDate": "Apr 11, 2023",
                "duration": "PT2M16S",
                "publisher": {
                  "@type": "Organization",
                  "name": "WINDS E Pvt Ltd",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
                  }
                },
                "contentUrl": "https://www.youtube.com/watch?v=W7Iat6Cx2Hg&t=1s",
                "embedUrl": "https://www.youtube.com/embed/W7Iat6Cx2Hg",
                "interactionCount": "6,603",
                "interactionStatistic": {
                  "@type": "InteractionCounter",
                  "userInteractionCount": "6,603"
                }
              },
              {
                "@context": "http://schema.org",
                "@type": "VideoObject",
                "name": "1st Female Grand Winner Of ₹25 Lakhs | WINDS App | #KharchePeKamai",
                "description": "Meet Mrs Rajitha, our first female grand winner from Mumbai, who just won ₹25 Lakhs in February's Lucky Draw. Our app has most of your favourite brands & payment options available that can fulfil your day-to-day needs.",
                "thumbnailUrl": "https://i.ytimg.com/vi/pXInRrlqH_M/sddefault.jpg",
                "uploadDate": "Mar 6, 2023",
                "duration": "PT2M11S",
                "publisher": {
                  "@type": "Organization",
                  "name": "WINDS E Pvt Ltd",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
                  }
                },
                "contentUrl": "https://www.youtube.com/watch?v=pXInRrlqH_M",
                "embedUrl": "https://www.youtube.com/embed/pXInRrlqH_M",
                "interactionCount": "8,989",
                "interactionStatistic": {
                  "@type": "InteractionCounter",
                  "userInteractionCount": "8,989"
                }
              },
              {
                "@context": "http://schema.org",
                "@type": "VideoObject",
                "name": "Lucky Winner of ₹25 Lakhs in Jan 2023",
                "description": "Shopping for daily needs from grocery stores, online stores & offline shops, doing mobile recharge & paying electricity bills and buying  brand vouchers using WINDS App,  made Mr. Bipul Kumar from Patna (Bihar), Winner of Mega Cash prize of Rs.25 Lakhs in WINDS Lucky Draw for the month of January '23.",
                "thumbnailUrl": "https://i.ytimg.com/vi_webp/Ccyxgou_byo/sddefault.webp",
                "uploadDate": "Feb 7, 2023",
                "duration": "PT2M00S",
                "publisher": {
                  "@type": "Organization",
                  "name": "WINDS E Pvt Ltd",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
                  }
                },
                "contentUrl": "https://www.youtube.com/watch?v=Ccyxgou_byo",
                "embedUrl": "https://www.youtube.com/embed/Ccyxgou_byo",
                "interactionCount": "43,020",
                "interactionStatistic": {
                  "@type": "InteractionCounter",
                  "userInteractionCount": "43,020"
                }
              },
              {
                "@context": "http://schema.org",
                "@type": "VideoObject",
                "name": "Mr Shiva Makin  - ₹ 25 Lakh Winner in December Lucky Draw",
                "description": "Winning Continues with WINDS. More than 2 Lakh people have already won Cash Rewards worth more than  ₹ 8 crores . Meet Mr Shiva Makin, from Lucknow, Uttar Pradesh, one such lucky person who won ₹25 Lakhs* in the December Lucky Draw. You can also win Lakhs & Crores * as Cash Rewards every month. ",
                "thumbnailUrl": "https://i.ytimg.com/vi_webp/qPnwnGQVR4Q/sddefault.webp",
                "uploadDate": "Jan 6, 2023",
                "duration": "PT2M45S",
                "publisher": {
                  "@type": "Organization",
                  "name": "WINDS E Pvt Ltd",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
                  }
                },
                "contentUrl": "https://www.youtube.com/watch?v=qPnwnGQVR4Q",
                "embedUrl": "https://www.youtube.com/embed/qPnwnGQVR4Q",
                "interactionCount": "4,678",
                "interactionStatistic": {
                  "@type": "InteractionCounter",
                  "userInteractionCount": "4,678"
                }
              },
              {
                "@context": "http://schema.org",
                "@type": "VideoObject",
                "name": "Mr Manoj Purohit - ₹ 25 Lakh Winner in November Lucky Draw",
                "description": "Lakhs of people are winning & getting lucky with WINDS from across the country. Meet Mr Manoj Purohit, from Jaisalmer, Rajasthan, one such lucky person who won ₹25 Lakhs* in the November Lucky Draw. You can also win Lakhs & Crores * as Cash Rewards every month. Just shop via WINDS, collect 5000 WRP,  make a coupon and participate in the upcoming December Lucky Draw to get a chance to win  ₹25 Lakhs* and other cash rewards",
                "thumbnailUrl": "https://i.ytimg.com/vi_webp/zdoTnSEvPgg/sddefault.webp",
                "uploadDate": "Dec 7, 2022",
                "duration": "PT2M09S",
                "publisher": {
                  "@type": "Organization",
                  "name": "WINDS E Pvt Ltd",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
                  }
                },
                "contentUrl": "https://www.youtube.com/watch?v=zdoTnSEvPgg",
                "embedUrl": "https://www.youtube.com/embed/zdoTnSEvPgg",
                "interactionCount": "5,249",
                "interactionStatistic": {
                  "@type": "InteractionCounter",
                  "userInteractionCount": "5,249"
                }
              },
              {
                "@context": "http://schema.org",
                "@type": "VideoObject",
                "name": "Mr Ashok Meena - ₹ 25 Lakh Winner in October Lucky Draw",
                "description": "Lakhs of people are winning & getting lucky with WINDS from across the country. Meet Mr Ashok Meena from Kota, Rajasthan, one such lucky person who won ₹25 Lakhs* in the October Lucky Draw. You can also win Lakhs & Crores * as Cash Rewards every month. Just shop via WINDS, collect 5000 WRP,  make a coupon and participate in the upcoming November Lucky Draw to get a chance to win  ₹25 Lakhs* and other cash rewards",
                "thumbnailUrl": "https://i.ytimg.com/vi_webp/QeTjEcdoNM4/sddefault.webp",
                "uploadDate": "Nov 6, 2022",
                "duration": "PT2M17S",
                "publisher": {
                  "@type": "Organization",
                  "name": "WINDS E Pvt Ltd",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "https://winds-admin.s3.ap-south-1.amazonaws.com/images/og_1.png"
                  }
                },
                "contentUrl": "youtube.com/watch?v=QeTjEcdoNM4",
                "embedUrl": "https://www.youtube.com/embed/QeTjEcdoNM4",
                "interactionCount": "300,354",
                "interactionStatistic": {
                  "@type": "InteractionCounter",
                  "userInteractionCount": "300,354"
                }
              }
            ]
          )}
        </script>
      }
      reviewSchema={
        <script type="application/ld+json">
          {JSON.stringify(
            [
              {
                "@context": "http://schema.org/",
                "@id": "#product",
                "@type": "Product",
                "name": "WINDS E Pvt Ltd",
                "description": "Shop from Online Shopping Sites, Recharge & Pay Utility Bills, Buy Brand Vouchers or Shop from Nearby Retail Stores. Collect 5,000 WRP & stand a chance to win up to Lakhs* every month on your regular shopping at WINDS App.",
                "image": "https://windsapp.com/static/media/logo-icon.1525bf06850008ccab83.png",
                "url": "https://windsapp.com/",
                "aggregateRating": {
                  "@type": "AggregateRating",
                  "bestRating": "5",
                  "ratingValue": "5",
                  "reviewCount": "1"
                }
              },
              {
                "@context": "http://schema.org/",
                "@id": "#product",
                "@type": "Product",
                "name": "WINDS E Pvt Ltd",
                "description": "Shop from Online Shopping Sites, Recharge & Pay Utility Bills, Buy Brand Vouchers or Shop from Nearby Retail Stores. Collect 5,000 WRP & stand a chance to win up to Lakhs* every month on your regular shopping at WINDS App.",
                "image": "https://windsapp.com/static/media/logo-icon.1525bf06850008ccab83.png",
                "url": "https://windsapp.com/",
                "aggregateRating": {
                  "@type": "AggregateRating",
                  "bestRating": "5",
                  "ratingValue": "5",
                  "reviewCount": "2"
                }
              },
              {
                "@context": "http://schema.org/",
                "@id": "#product",
                "@type": "Product",
                "name": "WINDS E Pvt Ltd",
                "description": "Shop from Online Shopping Sites, Recharge & Pay Utility Bills, Buy Brand Vouchers or Shop from Nearby Retail Stores. Collect 5,000 WRP & stand a chance to win up to Lakhs* every month on your regular shopping at WINDS App.",
                "image": "https://windsapp.com/static/media/logo-icon.1525bf06850008ccab83.png",
                "url": "https://windsapp.com/",
                "aggregateRating": {
                  "@type": "AggregateRating",
                  "bestRating": "5",
                  "ratingValue": "5",
                  "reviewCount": "3"
                }
              },
              {
                "@context": "http://schema.org/",
                "@id": "#product",
                "@type": "Product",
                "name": "WINDS E Pvt Ltd",
                "description": "Shop from Online Shopping Sites, Recharge & Pay Utility Bills, Buy Brand Vouchers or Shop from Nearby Retail Stores. Collect 5,000 WRP & stand a chance to win up to Lakhs* every month on your regular shopping at WINDS App.",
                "image": "https://windsapp.com/static/media/logo-icon.1525bf06850008ccab83.png",
                "url": "https://windsapp.com/",
                "aggregateRating": {
                  "@type": "AggregateRating",
                  "bestRating": "5",
                  "ratingValue": "5",
                  "reviewCount": "4"
                }
              },
              {
                "@context": "http://schema.org/",
                "@id": "#product",
                "@type": "Product",
                "name": "WINDS E Pvt Ltd",
                "description": "Shop from Online Shopping Sites, Recharge & Pay Utility Bills, Buy Brand Vouchers or Shop from Nearby Retail Stores. Collect 5,000 WRP & stand a chance to win up to Lakhs* every month on your regular shopping at WINDS App.",
                "image": "https://windsapp.com/static/media/logo-icon.1525bf06850008ccab83.png",
                "url": "https://windsapp.com/",
                "aggregateRating": {
                  "@type": "AggregateRating",
                  "bestRating": "5",
                  "ratingValue": "4.5",
                  "reviewCount": "5"
                }
              }
            ]

          )}
        </script>
      }
    ></HelmetMetaData>
  );
}

export default HomeMetaData;