import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Iframe from "../../../components/iFrame";
import { currentlySelectedLanguage } from "../../../utils/helper";
const Credits = (props) => {
  return (
    <div className="container-fluid font-proxima-nova">
      <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
        <div className="col-12">
          <small>
            <Link to="/">
              <FormattedMessage id="breadcrumbs.home" defaultMessage="Home" />
            </Link>{" "}
            /{" "}
            <strong>
              <FormattedMessage
                id="footer.credits"
                defaultMessage="Credits"
                description="Credits"
                values={{ what: "react-intl" }}
              />
            </strong>
          </small>
        </div>
      </div>
      <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
        <div className="col-12">
          <Iframe
            source={`${process.env.REACT_APP_BASE_URL_CREDITS
              }/${currentlySelectedLanguage()}`}
          ></Iframe>
        </div>
      </div>
    </div>
  );
};
export default Credits;
