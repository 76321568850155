import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import earningApi from '../../../utils/api/earningsClient';
import i_confirmed from '../../../assets/i_confirmed.svg';
import i_provisional from '../../../assets/i_provisional.svg';
import ic_no_match_found from '../../../assets/ic_no_match_found.svg';
import lodash from "lodash";
// import { onViewedPage } from '../../../utils/events';
import {
  fetchEarningsHistoryDetails,
  setEarningsHistoryDetails,
} from '../actions';

class EarningsHistoryDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      earnings: [],
      isFetchingEarningsHistoryDetails: false,
      isFetchingNextPage: false,
      isReachEnd: false,
      nextPage: 1,
      maxPage: 3,
      statusFilter: {
        0: false,
        1: false,
        2: false,
        3: false
      },
      from_dateFilter: "",
      to_dateFilter: "",
      isFilterApplied: false,
      selectedCategories: [],
      earningServiceFilter: [
        {
          "key": 0,
          "value": "Shopping Sites"
        },
        {
          "key": 1,
          "value": "Bill Pay"
        },
        {
          "key": 2,
          "value": "Partner Purchase"
        },
        {
          "key": 3,
          "value": "Vouchers"
        },
      ],
    }
  }

  componentDidMount() {
    this.props.initEarnings();
    window.addEventListener("scroll", this.onScroll, false);
  }

  handleEarningStatusFilter = (e) => {
    const { name } = e.target;

    this.setState(prevState => {
      return {
        statusFilter: {
          ...prevState.statusFilter,
          [name]: !prevState.statusFilter[name]
        }
      };
    })
  }

  EarningsList = (props) => {
    return (
      <React.Fragment>
        {
          this.props.isFetchingEarningsHistoryDetails ? (
            <React.Fragment>
              {lodash.range(3).map((index) => {
                return (
                  <div className="row" key={index}>
                    <div className="col-12">
                      <div className="box shine mb-3 mb-md-4" style={{ height: 100 }}></div>
                    </div>
                  </div>
                );
              })}
            </React.Fragment>
          ) : this.props.earningsHistoryDetails === 0 ? (
            <React.Fragment>
              <img
                src={ic_no_match_found}
                alt="ic_no_match_found"
                title="ic_no_match_found"
                className="mx-auto d-block"
                style={{ width: "30%", height: "auto", maxWidth: "90%" }}
              />
              <h4 className="text-center my-5 pb-5 font-weight-bold" style={{ letterSpacing: "0.03em" }}>No match found!</h4>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="earning-history-details">
                {
                  this.props.earningsHistoryDetails && this.props.earningsHistoryDetails.map((item, index) => {
                    return (
                      <div key={index} className="card p-3">
                        <div className="border-bottom pb-3 mb-3 d-flex align-items-center">

                          {item.confirmed_date ? (
                            <>
                              <small className='text-muted'>
                                {item.confirmed_date}
                              </small>
                            </>
                          ) : (
                            <>
                              <small className='text-muted flex align-items-center'>
                                {item.tracked_date}
                              </small>
                            </>
                          )}
                          {
                            item.confirmed_date ? (
                              <>
                                <span className="ml-auto text-success font-weight-bold d-flex">
                                  <img src={i_confirmed} alt="confirmed" title='confirmed' className='mr-1' />
                                  <FormattedMessage
                                    id="earnings.confirmed_earnings"
                                    defaultMessage="Confirmed Earnings"
                                  />
                                </span>
                              </>
                            ) : (
                              <>
                                <span className="ml-auto text-warning font-weight-bold d-flex">
                                  <img src={i_provisional} alt="provisional" title='provisional' className='mr-1' />
                                  <FormattedMessage
                                    id="earnings.provisional_earnings"
                                    defaultMessage="Provisional Earnings"
                                  />
                                </span>
                              </>
                            )
                          }
                        </div>
                        <div className="d-flex">
                          <div className="">
                            <div className="small text-muted mb-1">Customer ID : {item.customer_id}</div>
                            <div className="font-weight-bold h5 mb-4">{item.customer_name}</div>
                            {
                              item.transaction_type === 'Bill-Pay' ? (
                                <>
                                  <span className="btn btn-sm btn-success py-1 text-white">
                                    <FormattedMessage
                                      id="earnings.bill-pay"
                                      defaultMessage="Bill-Pay"
                                    />
                                  </span>
                                </>
                              ) : item.transaction_type === 'Partner-Purchase' ? (
                                <>
                                  <span className="btn btn-sm btn-warning py-1 text-white">
                                    <FormattedMessage
                                      id="earnings.partner-purchase"
                                      defaultMessage="Partner-Purchase"
                                    />
                                  </span>
                                </>
                              ) : item.transaction_type === 'Voucher' ? (
                                <>
                                  <span className="btn btn-sm btn-info py-1 text-white">
                                    <FormattedMessage
                                      id="earnings.voucher"
                                      defaultMessage="Voucher"
                                    />
                                  </span>
                                </>
                              ) : item.transaction_type === 'Shopping Site' ? (
                                <>
                                  <span className="btn btn-sm btn-primary py-1 text-white">
                                    <FormattedMessage
                                      id="earnings.shopping-site"
                                      defaultMessage="Shopping-Site"
                                    />
                                  </span>
                                </>) : (
                                <></>
                              )
                            }
                          </div>
                          <div className="ml-auto text-right">
                            <div className='small text-muted mb-1'>
                              <FormattedMessage
                                id="general.amount"
                                defaultMessage="Shopping-Site"
                              />
                            </div>
                            <div className="font-weight-bold h5 mb-3">₹ {item.commission_amount}</div>
                            <span className='small text-muted'>TXN ID: {item.transaction_id}</span>
                          </div>
                        </div>


                      </div>
                    )
                  })
                }
              </div>
            </React.Fragment>
          )
        }
      </React.Fragment>
    )
  }

  EarningFilters = (props) => {
    return (
      <React.Fragment>
        <div style={{ position: 'sticky', top: '10px' }} >
          <div className="border-bottom d-flex align-items-center status pb-3 mb-4">
            <h5 className="mb-0">
              <strong>
                <span>
                  <FormattedMessage
                    id="general.filters"
                    defaultMessage="Filters"
                  />
                </span>
              </strong>
            </h5>
            <button className="btn btn-outline float-right winds-link-color ml-auto">Reset</button>
          </div>

          <div className="border-bottom mt-3 pb-2 status">
            <p className="text-uppercase">
              <strong>
                <FormattedMessage
                  id="order.status"
                  defaultMessage="Status"
                />
              </strong>
            </p>
            <div className="d-flex mb-3">
              <FormattedMessage
                id="earnings.confirmed_commission"
                defaultMessage="Confirmed Commission"
              />
              <div className="custom-control custom-checkbox ml-auto">
                <input type="checkbox" className="custom-control-input" id="confirmed_commission" />
                <label className="custom-control-label" htmlFor="confirmed_commission"></label>
              </div>
            </div>
          </div>

          <div className="border-bottom mt-3 pb-2 status">
            <p className="text-uppercase">
              <strong>
                <FormattedMessage
                  id="earnings.transaction_type"
                  defaultMessage="Transaction Type"
                />
              </strong>
            </p>

            {this.state.earningServiceFilter.map((item, index) => {
              return (
                <div className="d-flex mb-3" key={"transaction-type-" + index}>
                  <span>{item.value}</span>
                  <div className="custom-control custom-checkbox ml-auto">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={"transaction-type-" + item.key}
                      // onChange={(e) => this.handleEarningStatusFilter(e, item.key)}
                      name={item.key}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={"transaction-type-" + item.key}
                    ></label>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="mt-3 pb-2 status">
            <p className="text-uppercase">
              <strong>
                <FormattedMessage id="order.date" defaultMessage="DATE" />
              </strong>
            </p>
            <p className="mb-2"><FormattedMessage id="order.from" defaultMessage="From" /></p>
            <input type="date" name="from-date" className="date-input" />
          </div>

          <div className="mt-3 pb-2 status">
            <p className="mb-2"><FormattedMessage id="order.to" defaultMessage="To" /></p>
            <input type="date" name="from-date" className="date-input" />
          </div>

          <div className="mt-3 status">
            <button
              className="btn btn-sm btn-primary btn-block"
            // onClick={props.resetFilter}
            >
              <FormattedMessage id="general.reset" defaultMessage="Reset" />
            </button>
          </div>
        </div>
      </React.Fragment>
    )
  }

  render() {
    return (
      <React.Fragment>
        <div className="container">
          <div className="row mt-3 mb-3 mt-md-4 mb-md-5">
            <div className="col-12 d-flex">
              <small>
                <Link to="/">
                  <FormattedMessage
                    id="breadcrumbs.home"
                    defaultMessage="Home"
                  />
                </Link>
                {" "}/{" "}
                <Link to="/earnings">
                  <FormattedMessage
                    id="navbar.my-earnings"
                    defaultMessage="My Earnings"
                  />
                </Link>
                {" "}/{" "}
                <Link to="/earnings/customer-commission">
                  <FormattedMessage
                    id="earnings.customer_commission"
                    defaultMessage="Customer Commission"
                  />
                </Link>
                {" "}/{" "}
                <strong>
                  <FormattedMessage
                    id="earnings.customer_commission_details"
                    defaultMessage="Customer Commission Details"
                  />
                </strong>
              </small>
            </div>
          </div>
          <div className="row mb-5" style={{ minHeight: 400 }}>
            <div className="col-12 col-md-8 pl-md-4 pr-md-4 mx-auto">
              <this.EarningsList />
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    isFetchingEarningsHistoryDetails: state.earnings.isFetchingEarningsHistoryDetails,
    earningsHistoryDetails: state.earnings.earningsHistoryDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initEarnings: () => {
      dispatch(fetchEarningsHistoryDetails());
      const urlParams = new URLSearchParams(window.location.search);
      const commission_date = urlParams.get('commission_date');

      earningApi.get(`/v2/customer-commission-details?commission_date=${commission_date}`).then((response) => {
        let data = response.data.data;
        dispatch(setEarningsHistoryDetails(data));
      });
      return;
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EarningsHistoryDetails);