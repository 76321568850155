  export function setModifyFlightData(modifyFlightData) {
    return {
      type: "SET_MODIFY_FLIGHT_DATA",
      modifyFlightData
    };
  }

  export function setViewTicketUrl(viewTicketUrl) {
    return {
      type: "SET_VIEW_TICKET_URL",
      viewTicketUrl
    };
  }

  export function setTravelData(travelData) {
    return {
      type: "SET_TRAVEL_DATA",
      travelData
    };
  }