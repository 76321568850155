import React, { Component } from "react";
import { Tab, Tabs } from "react-bootstrap";
import ContentLoader from "react-content-loader";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import rupee from "../../../assets/voucher/ic_rupee.png"
import orderIcon from "../../../assets/voucher/ic_voucher_purcgase.png"
import orderArrow from "../../../assets/voucher/ic_arrow_vouchers.png"
import voucherClientX from "../../../utils/api/voucherClientX";
import { addVoucherBalanceHistory, addVoucherPurchaseHistory, fetchingNextPageBalanceHistory, fetchingNextPagePurchaseHistory, fetchingVoucherBalanceHistory, fetchingVoucherPurchaseHistory, fetchWindsEGiftCardBalance, getWindsEGiftBalance, setVoucherBalanceHistory, setVoucherPurchaseHistory, voucherBalanceCurrentPage, voucherPurchaseCurrentPage } from "../actions";
import { toast } from "react-toastify";
import Lottie from "lottie-react";
import SuccessTick from "../../../assets/voucher/ic_success_tick.json"
import SuccessBg from "../../../assets/voucher/ic_confetti.json"
import InlineLoader from "../../../components/InlineLoader";
import EmptyState from "../../../components/EmptyState";
import EmptyImage from "../../../assets/voucher/ic_empty_state_gift_balance.svg";
import { FirebaseContext } from "../../../utils/firebase";
import windsTag from "../../../assets/voucher/ic_winds_badge-Winds.svg"
class RedeemWindsVoucher extends Component {
  static contextType = FirebaseContext;

  constructor(props) {
    super(props)
    this.state = {
      voucherCode: "",
      voucherRedeemData: null,
      redeemStatus: null,
      errorMessage: null,
      defaultTabKey: this.props.location.search,
      activeTab:this.props.location.search || "?1"
    }
  }

  componentDidMount() {
    if (this.props.profile) {
      this.props.getBalance()
      this.props.getPurchaseHistory()
      this.props.getBalanceHistory()
      this.props.resetVoucherBlanceCurrentPage()
      this.props.resetVoucherPurchaseCurrentPage()
    }
    else {
      toast.error("Something went wrong. Login & retry.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
    window.addEventListener("scroll", this.onScrollHistory, false);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScrollHistory, false);
  }

  orderDetails = (id) => {
    this.props.history.push(
      `/vouchers/orders/${id}`
    );
  }

  transactionOrderDetails = (type, id, transactionType) => {
    if (type === 1) {
      switch (transactionType) {
        case "VoucherOrder":
          this.props.history.push(`/vouchers/orders/${id}`);
          break;
        case "BillPayOrder":
          this.props.history.push(`/bill-pay/orders/${id}`);
          break;
        default:
          this.props.history.push(`/orders`);
          break;
      }
    }
  }

  handleTabSelect = (tabKey) => {
    this.setState({ activeTab: tabKey });
  };

  onScrollHistory = () => {
    let height = window.innerWidth < 767 ? 1800 : 500;

    if(this.state.activeTab==="?1"){
      if (
        window.innerHeight + window.scrollY >= document.body.offsetHeight - height &&
        this.props.voucherPurchaseHistory &&
        !this.props.isFetchingNextPagePurchaseHistory &&
        this.props.voucherPurchasePageNo > 0
      ) {
        this.props.voucherPurchasePageNo !== 0 && this.props.onPaginatedSearchPurchaseHistory(this.props.voucherPurchasePageNo);
      }
    }
    else{
      if (
        window.innerHeight + window.scrollY >= document.body.offsetHeight - height &&
        this.props.voucherBalanceHistory &&
        !this.props.isFetchingNextPageBalanceHistory &&
        this.props.voucherBalancePageNo > 0
      ) {
        this.props.voucherBalancePageNo !== 0 && this.props.onPaginatedSearchBalanceHistory(this.props.voucherBalancePageNo);
      }
    }

   
  };

  addVoucherBalance = () => {
    voucherClientX
      .post(`v1/redeem-gift-card`, {
        voucher_code: this.state.voucherCode
      })
      .then((response) => {
        this.setState({ voucherRedeemData: response.data })
        if (response.data.success) {
          this.setState({ redeemStatus: true })
          this.props.getBalance()
          toast.success(response?.data?.data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
        else {
          this.setState({ errorMessage: response.message })
        }
      }).catch((error) => {
        this.setState({ redeemStatus: null })
        this.setState({ errorMessage: error?.response?.data?.message })
      })
  }

  BreadCrubs = () => {
    return (
      <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
        <div className="col-12">
          <div className="row align-items-center">
            <div className="col-12">
              <small>
                <Link to="/">
                  <FormattedMessage
                    id="breadcrumbs.home"
                    defaultMessage="Home"
                  />
                </Link>
              </small>{" "}
              /{" "}
              <small>
                <Link to="/vouchers">
                  <FormattedMessage
                    id="home.vouchers"
                    defaultMessage="Vouchers"
                  />
                </Link>
              </small>{" "}
              {true && (
                <React.Fragment>
                  /{" "}
                  <small>
                    <strong>Winds E-Gift Voucher Balance</strong>
                  </small>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  Shimmer = (props) => {
    return (
      <div className="container py-3 py-md-5">
        <div className="row">
          <div className="col-md-6">
            <div className="box shine box-voucher-detail-logo"></div>
          </div>
          <div className="col-md-6 pt-1 pt-md-0">
            <ContentLoader
              height={120}
              width={400}
              speed={2}
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            >
              <rect x="0" y="9" rx="0" ry="0" width="120" height="6" />
              <rect x="0" y="21" rx="0" ry="0" width="320" height="12" />
              <rect x="0" y="39" rx="0" ry="0" width="92" height="6" />
              <rect x="0" y="60" rx="0" ry="0" width="506" height="6" />
              <rect x="0" y="75" rx="0" ry="0" width="136" height="6" />
              <rect x="0" y="90" rx="0" ry="0" width="153" height="6" />
            </ContentLoader>
            <ContentLoader
              height={80}
              width={400}
              speed={2}
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            >
              <rect x="0" y="0" rx="2" ry="2" width="99" height="6" />
              <rect x="0" y="16" rx="2" ry="2" width="300" height="24" />
            </ContentLoader>
          </div>
        </div>
        <div className="row mt-3 d-flex justify-content-center">
          <div className="col-md-4">
            <div className="box shine w-100" style={{ height: "38px" }}></div>
          </div>
          <div className="col-md-4">
            <div className="box shine w-100" style={{ height: "38px" }}></div>
          </div>
          <div className="col-md-10">
            <div className="box shine box-voucher-detail-logo"></div>
          </div>
        </div>
      </div>
    );
  };

  VoucherBalance = (props) => {
    return (
      <>
        <div className="voucher-balance position-relative">
          <img className="position-absolute tag" src={windsTag} alt="windsTag" title="windsTag" style={{ top: "1%", right: "8%" }} />
          <h4 className="mb-3">Winds E-Gift Voucher Balance</h4>
          <h3>
            <img src={rupee} alt="rupee" title="rupee" />
            {this.props.windsEGiftBalance?.balance?.toFixed(2)}
          </h3>
        </div>
      </>
    );
  };

  VoucherRedeem = (props) => {
    return (
      <>
        <div className="voucher-redeem">
          {this.state.redeemStatus === null ?
            <>
              <h4 className="mb-3">Add a Winds E-Gift Voucher</h4>
              <input className="voucher-amount w-100 mt-2" type="text" placeholder="e.g. 73897298" onChange={(e) => { this.setState({ voucherCode: e.target.value }) }} value={this.state.voucherCode} />
              {
                this.state.errorMessage !== null ?
                  <div className="redeem-error-message mt-1">{this.state.errorMessage}</div> :
                  <></>
              }
              {this.state.voucherCode === "" ?
                <button className="animated-btn-disabled btn-block mb-3 mt-4">
                  <FormattedMessage
                    id="general.add-balance"
                    defaultMessage="ADD TO YOUR BALANCE"
                  />
                </button> :
                <button
                  className="animated-btn btn-block mb-3 mt-4"
                  onClick={this.addVoucherBalance}
                >
                  <FormattedMessage
                    id="general.add-balance"
                    defaultMessage="ADD TO YOUR BALANCE"
                  />
                </button>
              }
              <a href={this.context.redeem_winds_giftcard_url} target="_blank" rel="noreferrer">
                <p style={{ color: "#4E77F7" }}>Need help with adding the Winds E-Gift Voucher</p>
              </a>
            </> :
            this.RedeemSuccess()
          }
        </div>
      </>
    );
  };

  RedeemSuccess = (props) => {
    return (
      <div className="redeem-success">
        <Lottie className="success-bg-lottie" animationData={SuccessBg} loop={false} />
        <div className="success-box">
          <Lottie className="success-lottie" animationData={SuccessTick} loop={false} />
          <h3>
            <img src={rupee} alt="rupee" title="rupee" />
            {this.state.voucherRedeemData?.data?.gift_voucher_amount}
          </h3>
          <h4>{this.state.voucherRedeemData?.data?.message}</h4>
        </div>
      </div>
    )
  }

  OrdersTab = (props) => {
    return (
      <Tabs
        className="wrp-tabs w-md-75 m-auto"
        id="controlled-tab-example"
        defaultActiveKey={this.state.defaultTabKey || "?1"}
        onSelect={this.handleTabSelect}
      >
        <Tab
          eventKey="?1"
          // title={props.intl.formatMessage({ id: "general.purchase-history" })}
          title={
            <FormattedMessage
              id="general.purchase-history"
              defaultMessage="Purchase History"
            />
          }
                  >
          <>
            {this.PurchaseHistory()}
          </>
        </Tab>
        <Tab
          eventKey="?2"
          title={
            <FormattedMessage
              id="general.transaction-history"
              defaultMessage="Transaction History"
            />
          }
                  >
          <>
            {this.TransactionHistory()}
          </>
        </Tab>
      </Tabs>
    );
  };

  emptyHistory = () => {
    return (
      <>
        <EmptyState
          title="Nothing to see yet!"
          description="You don’t have any history to show here"
          imageWidth={30}
          image={EmptyImage}
        />
        <h5 className="text-center mt-n4 mb-3">Buy a WINDS E-Gift Vouchers & get all the benefits from it</h5>
        <Link to="/vouchers">
          <button className="animated-btn btn-block  w-50 m-auto mb-3 mt-4">BUY NOW</button>
        </Link>
      </>
    )
  }

  PurchaseHistory = (props) => {
    return (
      this.props.voucherPurchaseHistory && this.props.voucherPurchaseHistory.length === 0 ?
        this.emptyHistory() :
        <>
          <div className="history my-4">
            {this.props.voucherPurchaseHistory && this.props.voucherPurchaseHistory.map((item, index) => {
              return (
                <div className="row order align-items-center cursor-pointer" key={index} onClick={() => this.orderDetails(item.id)}>
                  <div className="col-12 col-md-4 d-flex align-items-center">
                    <img className="order-icon" src={orderIcon} alt="icon" title="icon" />
                    <div className="d-flex flex-column">
                      <h4>{item.service}</h4>
                      <p>{item.created_at}</p>
                      {item.is_expired !== null &&
                        <p>Expiry date :{item.expiry_date}</p>
                      }
                    </div>
                  </div>
                  <div className="col-4 col-md-2">
                    <h5 className="font-bold">₹ {item.amount}</h5>
                  </div>
                  <div className="col-12 col-md-3">
                    <h5>Vouchers {item.order_number}</h5>
                  </div>
                  <div className="col-12 col-md-3 d-flex justify-content-between mt-3 mt-md-0">
                    <div className="d-flex flex-column">
                      <span className="order-success">{item.status_text_translated}</span>
                      <p>{item.wrp} WINDS Coins Credited</p>
                    </div>
                    <img className="order-arrow" src={orderArrow} alt="arrow" title="icon" />
                  </div>
                </div>
              )
            })}
          </div>
          <div className="row">
            <div className="col-12 text-center">
              {this.props.voucherPurchasePageNo !== 0 && <InlineLoader />}
              {this.props.voucherPurchasePageNo === 0 && (
                <p className="font-weight-bold">No More Data</p>
              )}
            </div>
          </div>
        </>
    )
  }

  TransactionHistory = (props) => {
    return (
      this.props?.voucherBalanceHistory && this.props?.voucherBalanceHistory.length === 0 ?
        this.emptyHistory() :
        <div className="history my-4">
          {this.props.voucherBalanceHistory && this.props.voucherBalanceHistory.map((item, index) => {
            return (
              <div className={"row order align-items-center" + (item.type === 1 && !item.is_expired ? " cursor-pointer" : "")} key={index} onClick={() => !item.expiry_date && this.transactionOrderDetails(item.type, item.item_order_id, item.transaction_type)}>
                <div className="col-12 col-md-4 d-flex align-items-center">
                  <img className="order-icon" src={orderIcon} alt="icon" title="icon" />
                  <div className="d-flex flex-column">
                    <h4>{item.title}</h4>
                    <p>{item.date}{" "}{item.time}</p>
                    {item.expiry_date !== null &&
                      <p>Expiry date : <span className={item.is_expired ? "expired-text" : ""}>{item.expiry_date}</span></p>
                    }
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <h5>{item.transaction_type_display} #{item.master_order_id}</h5>
                </div>
                <div className="col-12 col-md-3">
                  {item.type === 1 &&
                    <p className={item.is_expired ? "d-none" : "d-block"}>Purchased Item: {item.transaction_type_display}</p>
                  }
                </div>
                <div className="col-12 col-md-2 d-flex justify-content-between mt-1 mt-md-0">
                  <div className="d-flex flex-column">
                  <h5 className={item.is_expired ? "expired-text font-bold" : "font-bold"}>{item.type === 1 ? "-" : "+"} ₹ {item.amount}</h5>
                  </div>
                  {item.type === 1 &&
                    <img className={item.is_expired ? "d-none" : "d-block order-arrow"} src={orderArrow} alt="arrow" title="icon" />
                  }
                </div>
              </div>
            )
          })}
          <div className="row">
            <div className="col-12 text-center">
              {this.props.voucherBalancePageNo !== 0 && <InlineLoader />}
              {this.props.voucherBalancePageNo === 0 && (
                <p className="font-weight-bold">No More Data</p>
              )}
            </div>
          </div>
        </div>
    )
  }

  WindsVoucher = (props) => {
    return (
      <div className="container font-proxima-nova">
        {this.BreadCrubs()}
        <div className="row">
          <div className="col-12">
            <div className="row d-flex align-items-center">
              <div className="col-md-7 mb-3 mb-md-0">
                {this.VoucherBalance()}
              </div>
              <div className="col-md-5">
                {this.VoucherRedeem()}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-2 mt-md-3 mb-md-0 mb-3">
          <div className="col-12">
            <hr />
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-12 col-md-12 mt-2 mt-md-4 m-auto">
            {this.OrdersTab()}
          </div>
        </div>
      </div>
    );
  };
  render() {
    return (
      <React.Fragment>
        {this.props.isfetchingWindsEGiftCardBalance || this.props.profile === null || !this.context.is_winds_giftcard_enabled ? (
          <this.Shimmer />
        ) : (
          <this.WindsVoucher />
        )
        }
      </React.Fragment>
    );
  }
}


function mapStateToProps(state) {
  return {
    profile: state.global.profile,
    home: state.home,
    windsEGiftBalance: state.voucher.windsEGiftBalance,
    isfetchingWindsEGiftCardBalance: state.voucher.isfetchingWindsEGiftCardBalance,
    isFetchingVoucherPurchaseHistory: state.voucher.isFetchingVoucherPurchaseHistory,
    voucherPurchaseHistory: state.voucher.voucherPurchaseHistory,
    isFetchingNextPagePurchaseHistory: state.voucher.isFetchingNextPagePurchaseHistory,
    nextPagePurchaseHistory: state.voucher.nextPagePurchaseHistory,
    isFetchingVoucherBalanceHistory: state.voucher.isFetchingVoucherBalanceHistory,
    voucherBalanceHistory: state.voucher.voucherBalanceHistory,
    isFetchingNextPageBalanceHistory: state.voucher.isFetchingNextPageBalanceHistory,
    voucherBalancePageNo: state.voucher.voucherBalancePageNo,
    voucherPurchasePageNo: state.voucher.voucherPurchasePageNo,
  };
}

function mapDispatchToProps(dispatch) {
  return {

    getBalance: () => {
      dispatch(fetchWindsEGiftCardBalance());
      voucherClientX
        .get("v1/get-gift-card-balance")
        .then((response) => {
          let data = response.data.data;
          dispatch(getWindsEGiftBalance(data));
        });
    },

    getPurchaseHistory: () => {
      dispatch(fetchingVoucherPurchaseHistory());
      voucherClientX
      .get("v1/get-gift-voucher-purchase-history")
        .then((response) => {
          const data = response.data.data;
          dispatch(setVoucherPurchaseHistory(data));
        })
    },

    onPaginatedSearchPurchaseHistory: (voucherPurchaseHistory) => {
      dispatch(fetchingNextPagePurchaseHistory());
      voucherClientX
        .get("v1/get-gift-voucher-purchase-history", {
          params: {
            page: voucherPurchaseHistory,
          },
        })
        .then((response) => {
          let data = response.data.data;
          if (data.length !== 0) {
            dispatch(addVoucherPurchaseHistory(data));
            dispatch(voucherPurchaseCurrentPage('INCREMENT'));
          }
          else {
            dispatch(voucherPurchaseCurrentPage('COMPLETED'));
          }
        });

    },
    resetVoucherPurchaseCurrentPage: () => dispatch(voucherPurchaseCurrentPage('RESET')),


    getBalanceHistory: async () => {
      dispatch(fetchingVoucherBalanceHistory());
      try {
        const response = await voucherClientX.get("v1/get-gift-voucher-balance-history");
        const data = response.data.data.transactions;
        dispatch(setVoucherBalanceHistory(data));
      } catch (error) {
        // console.error("Error fetching balance history:", error);
      }
    },

    onPaginatedSearchBalanceHistory: (voucherBalancePageNo) => {
      dispatch(fetchingNextPageBalanceHistory());
      voucherClientX
        .get("v1/get-gift-voucher-balance-history", {
          params: {
            page: voucherBalancePageNo,
          },
        })
        .then((response) => {
          let data = response.data.data.transactions;
          if (data.length !== 0) {
            dispatch(addVoucherBalanceHistory(data));
            dispatch(voucherBalanceCurrentPage('INCREMENT'));
          }
          else {
            dispatch(voucherBalanceCurrentPage('COMPLETED'));
          }
        });
    },
    resetVoucherBlanceCurrentPage: () => dispatch(voucherBalanceCurrentPage('RESET')),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RedeemWindsVoucher);
