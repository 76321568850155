import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// import InputGroup from "react-bootstrap/InputGroup";
// import searchIcon from "../../../assets/games/ic_search.png";
import Slider from "react-slick";
import customerCommonClientX from "../../../utils/api/customerCommonClientX";
import { toast } from "react-toastify";
import LoginDialog from "../../../components/LoginDialog";

export default function Games() {
  const carousalData = useSelector((state) => state?.home.fun_game_zone?.game_data);
  const profile = useSelector((state) => state?.global?.profile);

  const [genreList, setGenreList] = useState();
  const [loading, setloading] = useState(true);
  const [isLoginDialogOpen, setLoginDialog] = useState(false)

  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          dots: false
        }
      },
    ]
  }

  const openLoginDialog = () => {
    setLoginDialog(true)
  };
  const closeLoginDialog = () => {
    setLoginDialog(false)
  };

  const playFunGame = (url) => {
    if (profile) window.open(url, "_blank", "noopener,noreferrer");
    else openLoginDialog()
  };

  useEffect(() => {
    customerCommonClientX
      .get(`/v2/fun_zone/games`)
      .then((response) => {
        setGenreList(response?.data?.game_data);
        setloading(false);
      })
      .catch((error) => {
        toast.error("Something went wrong! Login and retry");
      });
  }, []);

  const shimmer = () => {
    return (
      <div className="container py-3 py-md-5">
        <div className="row">
          <div className="col-12">
            <div className="box shine fun-game-shimmer-head mb-2"></div>
          </div>
          <div className="col-8 col-md-4">
            <div className="box shine my-4" style={{ height: "50px" }}></div>
          </div>
          <div className="col-12 d-flex row row-0 justify-content-center">
            <div className="box shine col-4 col-md-3 col-lg-2 shimmer-card-body"></div>
            <div className="box shine col-4 col-md-3 col-lg-2 shimmer-card-body"></div>
            <div className="box shine col-4 col-md-3 col-lg-2 shimmer-card-body"></div>
            <div className="box shine col-4 col-md-3 col-lg-2 shimmer-card-body"></div>
            <div className="box shine col-4 col-md-3 col-lg-2 shimmer-card-body"></div>
            <div className="box shine col-4 col-md-3 col-lg-2 shimmer-card-body"></div>
            <div className="box shine col-4 col-md-3 col-lg-2 shimmer-card-body"></div>
            <div className="box shine col-4 col-md-3 col-lg-2 shimmer-card-body"></div>
            <div className="box shine col-4 col-md-3 col-lg-2 shimmer-card-body"></div>
            <div className="box shine col-4 col-md-3 col-lg-2 shimmer-card-body"></div>
            <div className="box shine col-4 col-md-3 col-lg-2 shimmer-card-body"></div>
            <div className="box shine col-4 col-md-3 col-lg-2 shimmer-card-body"></div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {loading ? (
        shimmer()
      ) : (
        <>
          <div
            className="container py-5 d-none d-md-block"
            id="fun-game-carousel-controls"
          >
            <Slider {...settings}>
              {carousalData?.map((item) => {
                return (
                  <div className="justify-content-center position-relative">
                    <div className="d-flex col-12">
                      <div className="fun-game-banner-data col-4">
                        <div className="fun-game-banner-data-text mx-3 d-flex align-items-start flex-column">
                          <div className="mb-4 mt-1 w-100">
                            <h2 className="text-fun-game-zone mt-3">
                              Fun Game Zones
                            </h2>
                            <h4 className="text-fun-game-zone-sub">
                              Don't miss these favorites
                            </h4>
                            <hr />
                          </div>
                          <div className="w-100">
                            <h1 className="text-fun-game-zone-title mb-4">
                              {item.name}
                            </h1>
                            <button
                              className="fun-game-button mb-5 w-100"
                              onClick={() => {
                                playFunGame(item?.url);
                              }}
                            >
                              Play Now
                            </button>
                          </div>
                        </div>
                      </div>
                      <img
                        className="d-block col-8 "
                        src={item?.web_image}
                        alt={item.name}
                        title={item.name}
                        id="side-sizing"
                      />
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>

          <div className="container d-md-none">
            <Slider {...settings}>
              {carousalData?.map((item) => {
                return (
                  <div className="justify-content-center position-relative">
                    <img
                      className="d-block col-12"
                      src={item?.image}
                      alt={item?.name}
                      title={item?.name}
                      id="side-sizing-mob"
                    />
                    <div className="fun-game-text-zone text-center pb-3 pt-5 col-12">
                      <h6>Don't miss these favorites</h6>
                      <h3>{item?.name}</h3>

                      <button
                        type="submit"
                        className="fun-game-button"
                        onClick={() => {
                          playFunGame(item?.url);
                        }}
                      >
                        Play Now
                      </button>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>

          <div className="container all-fun-game-container py-5">
            {genreList?.map((item) => {
              return (
                <>
                  <h3 className="fun-game-catagory-head font-weight-bold text-center text-md-left">
                    {item?.category}
                  </h3>
                  <div className="pb-5 pt-2 d-flex row justify-content-center justify-content-lg-space-between fun-game-spacing">
                    {item?.game?.map((card, id) => {
                      return (
                        <div className="fun-game-card-body col-6 col-sm-4 col-md-3 col-lg-2 m-0 p-0 d-flex row  justify-content-center pb-2 mb-4">
                          <img
                            src={card?.image}
                            className="fungame-scroll-img-fix"
                            alt="card"
                            title="card"
                          />

                          <h3 className="fungame-scroll-name m-0 p-2">
                            {card?.name}
                          </h3>
                          <button
                            type="submit"
                            className="fungame-playnow-button py-1 m-auto "
                            onClick={() => {
                              playFunGame(card.url);
                            }}
                          >
                            Play Now
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </>
              );
            })}
          </div>
          {isLoginDialogOpen && (
            <LoginDialog
              isLoginDialogOpen={isLoginDialogOpen}
              closeLoginDialog={closeLoginDialog}
            />
          )}
        </>
      )}
    </div>
  );
}

// {
/* <InputGroup className="mb-3 col-4 m-auto pb-5">
          <InputGroup.Text id="fun-game-search-icon">
            <img src={searchIcon} />
          </InputGroup.Text>
          <Form.Control
            id="fun-game-search-input"
            placeholder="Search by your favourite game names"
            aria-label="Username"
            aria-describedby="basic-addon1"
          />
        </InputGroup> */
// }
