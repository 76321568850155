import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
// import Recaptcha from 'react-recaptcha';
// import axios from "axios";

const DigiWorldApp = () => {

  const scriptURL = 'https://script.google.com/macros/s/AKfycbzYhaqYroNNyemarEfys4fCOjMZtcYx457WtyJcjupe9eBEZv3tD3kAtKS9VT1RSb98Lw/exec'
  // const [isVerified, setIsVerified] = useState(false)

  // const recaptchaLoaded = () => {
  //   console.log('capcha successfully loaded');
  // }

  // const verifyCallback = (responseToken) => {
  //   if (responseToken) {
  //     axios.post(`https://www.google.com/recaptcha/api/siteverify?secret=${process.env.REACT_APP_RECAPTCHA_SECRETKEY}&response=${responseToken}`)
  //       .then(response => {
  //         response.data.success &&
  //           setIsVerified(true)
  //       })
  //       .catch(error => {
  //         setIsVerified(false)
  //       });
  //   }
  // }

  const handleSubmit = (event) => {

    event.preventDefault()
    const form = document.forms['submit-to-google-sheet']

    // if (isVerified) {
    document.getElementById("submit-btn").innerHTML = "Submitting Your Data...";
    document.getElementById('success-show').style.display = "none"
    document.getElementById('error-show').style.display = "none"
    console.log(new FormData(form))
    fetch(scriptURL, { method: 'POST', body: new FormData(form) })
      .then(response => {
        document.getElementById("submit-btn").innerHTML = "Submitted";
        document.getElementById("submit-btn").style.opacity = "50%"
        document.getElementById("submit-btn").style.cursor = "not-allowed"
        document.getElementById("submit-btn").style.pointerEvents = "none"
        document.getElementById('success-show').style.display = "block"
      })
      .catch(error => {
        document.getElementById('error-show').style.display = "block"
      })
    // } else {
    //   document.getElementById('error-show').style.display = "block"
    // }
  }

  return (
    <>
      <section className="about-sec-1 patrons bg site-page site-top">
        <div className="container font-proxima-nova">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <img className="w-50 mb-4 mb-md-3" src={require('../../assets/img/site-captain/DigiWorldLogo.png')} alt="about" title="about" />
              <h1 className="title-captain"><FormattedMessage id="captain.section1.title" /></h1>
              <p className="desc"><FormattedMessage id="captain.section1.desc" /></p>
              <h2 className="mt-4 mb-3 download-patron"><FormattedMessage id="captain.section1.subtitle" /></h2>
              <div className="paystore">
                <a href="https://c.winds.to/app" target="blank">
                  <img src={require('../../assets/img/site-patrons/ic_google_pay.png')} alt="icon" title="icon" />
                </a>
                <a href="https://c.winds.to/app" target="blank">
                  <img src={require('../../assets/img/site-patrons/ic_app_store.png')} alt="about" title="about" />
                </a>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <img className="px-md-5 px-0 digi-form-bg" width="100%" src={require('../../assets/img/site-captain/ic_winds_captain.png')} alt="about" title="about" />
              <div className="py-md-4 px-md-5 p-3 ml-md-2 ml-0 mt-md-0 mt-4" style={{ background: "rgb(255 255 255 / 48%)", borderRadius: "30px", position: "relative" }}>
                <h1 className="download-patron text-center mb-3 mb-md-4">Please submit your interest below</h1>
                <form className='form-group w-100 m-auto'
                  name="submit-to-google-sheet"
                  onSubmit={(e) => handleSubmit(e)}
                >
                  <label>Name*</label>
                  <input type='text' className='form-control form-lp' required
                    placeholder='Enter your Name'
                    name="Name"
                  />
                  <br></br>
                  <label>Phone Number*</label>
                  <input type='number' className='form-control form-lp' required
                    placeholder='Enter your Phone Number'
                    name="Phone Number"
                  />
                  <br></br>
                  <label>Email (Optional)</label>
                  <input type='email' className='form-control form-lp'
                    placeholder='Enter your Email'
                    name="Email"
                  />
                  <br></br>
                  <label>Qualification (Optional)</label>
                  <input type='text' className='form-control form-lp'
                    placeholder='Enter Qualification'
                    name="Qualification"
                  />
                  <br></br>
                  <label>Are you ready to invest RS. 15000 for Franchise?*</label>
                  <br></br>
                  <input type="radio" id="yes" name="Ready to Invest" value="Yes" required checked />
                  <label for="yes" className="ml-2">Yes</label>
                  <input type="radio" id="no" name="Ready to Invest" value="No" className="ml-3" />
                  <label for="no" className="ml-2">No</label>

                  <br></br>
                  {/* <Recaptcha
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                    render="explicit"
                    onloadCallback={recaptchaLoaded}
                    verifyCallback={verifyCallback}
                  />
                  <br></br> */}
                  <div style={{ display: "flex", justifyContent: 'flex-end' }} className="mt-3">
                    <button type='submit' className='btn btn-primary animated-btn digi-btn' id="submit-btn">Submit</button>
                  </div>
                  <p id="success-show" className="desc mt-2 text-center" style={{ color: "green", display: "none" }}>Thank your for submitting your data!</p>
                  <p id="error-show" className="desc mt-2 text-center" style={{ color: "red", display: "none" }}>Invalid Captcha. Please try again!</p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-sec-2captain site-page">
        <div className="container font-proxima-nova">
          <div className="row reverse-col">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <img width="100%" src={require('../../assets/img/site-captain/ic_criteria.png')} alt="about" title="about" className="pr-md-5 pr-0 mt-md-0 mt-3" />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <h1 className="title-captain"><FormattedMessage id="captain.section2.title" /></h1>
              <p className="desc"><FormattedMessage id="captain.section2.desc1" /></p>
              <p className="desc"><FormattedMessage id="captain.section2.desc2" /></p>
            </div>
          </div>
        </div>
      </section>
      <section className="about-sec-3captain site-page">
        <div className="container font-proxima-nova">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 d-flex flex-column justify-content-center ">
              <h1 className="title-captain"><FormattedMessage id="captain.section3.title" /></h1>
              <p className="desc"><FormattedMessage id="captain.section3.desc1" /></p>
              <p className="desc"><FormattedMessage id="captain.section3.desc2" /></p>
              <p className="desc"><FormattedMessage id="captain.section3.desc3" /></p>
              <p className="desc"><FormattedMessage id="captain.section3.desc4" /> </p>
            </div>
            <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 d-flex flex-column justify-content-center align-items-center">
              <img src={require('../../assets/img/site-captain/ic_responsibilities_mockup.png')} alt="about" title="about" className="px-0 px-md-5 mt-md-0 mt-3 w-100" />
            </div>
          </div>
        </div>
      </section>
      <section className="about-sec-4 site-page">
        <div className="container font-proxima-nova">
          <div className="row reverse-col">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <img width="100%" src={require('../../assets/img/site-captain/digiworld_chart.jpg')} alt="about" title="about" className="pr-0 mt-md-0" />
              <h2 className="download-patron text-center"><FormattedMessage id="captain.section4.image-title" /></h2>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <h1 className="title-captain"><FormattedMessage id="captain.section4.title" /></h1>
              <p className="desc"><FormattedMessage id="captain.section4.desc1" /></p>
              <ol className="bullet">
                <li><FormattedMessage id="captain.section4.desc1a" /></li>
                <li><FormattedMessage id="captain.section4.desc1b" /></li>
              </ol>
              <p className="desc"><FormattedMessage id="captain.section4.desc2" /></p>
              <ol className="bullet">
                <li><FormattedMessage id="captain.section4.desc2a" /></li>
                <li><FormattedMessage id="captain.section4.desc2b" /></li>
              </ol>
              <p className="desc"><FormattedMessage id="captain.section4.desc3" /></p>
              <ol className="bullet">
                <li><FormattedMessage id="captain.section4.desc3a" /></li>
                <li><FormattedMessage id="captain.section4.desc3b" /></li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="site-page" style={{ background: "#FAF1E8" }}>
        <div className="container font-proxima-nova">
          <div className="row">
            <div className="col-12 d-flex flex-column justify-content-center">
              <h1 className="title-captain"><FormattedMessage id="captain.section5.title" /></h1>
              <div className="d-flex align-items-start">
                <img className="mr-2" src={require('../../assets/img/site-captain/ic_tick_mark.png')} width="30px" alt="tick" title="tick"/>
                <p className="desc"><FormattedMessage id="captain.section5.desc1" /></p>
              </div>
              <div className="d-flex align-items-start">
                <img className="mr-2" src={require('../../assets/img/site-captain/ic_tick_mark.png')} width="30px" alt="tick" title="tick"/>
                <p className="desc"><FormattedMessage id="captain.section5.desc2" /> </p>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12 d-flex flex-column justify-content-center">
              <h1 className="title-captain"><FormattedMessage id="captain.section6.title" /></h1>
              <p className="desc"><FormattedMessage id="captain.section6.desc1" /></p>
              <p className="desc"><FormattedMessage id="captain.section6.desc2" /></p>
              <p className="desc"><FormattedMessage id="captain.section6.desc3" /></p>
              <h1 className="download-patron pt-3" style={{ textDecoration: "underline" }}>
                <Link to="/digi-world-faq">
                  <FormattedMessage id="captain.faq" />
                </Link>
              </h1>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="site-page about-sec-3redirect">
        <div className="container font-proxima-nova">
          <div className="row">
            <div className="col-12 d-flex flex-column justify-content-center pb-4">
              <h1 className="title-captain text-center">Please submit your interest below</h1>
              <form className='form-group w-md-50 m-auto'
                name="submit-to-google-sheet"
                onSubmit={(e) => handleSubmit(e)}
              >
                <label>Name*</label>
                <input type='text' className='form-control form-lp' required
                  placeholder='Enter your name'
                  name="name"
                />
                <br></br>
                <label>Email*</label>
                <input type='email' className='form-control form-lp' required
                  placeholder='Enter your Email'
                  name="email"
                />
                <br></br>
                <label>Mobile*</label>
                <input type='number' className='form-control form-lp' required
                  placeholder='Enter your Mobile Number'
                  name="mobile"
                />
                <br></br>
                <div className="d-flex">
                <input type="text" placeholder="Enter Captcha" className="form-control form-lp w-50" id="cpatchaTextBox" required />
                <div className="ml-4" id="captcha"></div>
                </div>
                <br></br>
                <div style={{ display: "flex", justifyContent: 'flex-end' }} className="mt-3">
                  <button type='submit' className='btn btn-primary animated-btn' id="submit-btn">Submit</button>
                </div>
                <p id="success-show" className="desc mt-2 text-center" style={{ color: "green", display: "none" }}>Thank your for submitting your data!</p>
                <p id="error-show" className="desc mt-2 text-center" style={{ color: "red", display: "none" }}>Invalid Captcha. Please try again!</p>
              </form>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};
export default DigiWorldApp;
