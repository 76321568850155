const INITIAL_STATE = {
  isFetchingVouchers: false,
  isFetchingVoucherCategories: false,
  isFetchingNextPage: false,
  categories: [],
  selectedCategories: null,
  vouchers: [],
  nextPage: 2,
  voucher: null,
  denomination: null,
  quantity: 1,
  isVoucherFilterModelOpen: false,
  giftDetails: null,
  isfetchingWindsEGiftCardBalance: false,
  windsEGiftBalance: 0.00,
  windsVoucher: null,
  isFetchingVoucherPurchaseHistory: false,
  voucherPurchaseHistory: [],
  nextPagePurchaseHistory: 2,
  isFetchingNextPagePurchaseHistory: false,
  isFetchingVoucherBalanceHistory: false,
  voucherBalanceHistory: [],
  nextPageBalanceHistory: 1,
  isFetchingNextPageBalanceHistory: false,
  discountData: null,
  isFetchingInitialVouchers: false,
  banner_data: [],
  voucherBalancePageNo: 2,
  voucherPurchasePageNo: 2
};

function voucherReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "FETCHING_VOUCHERS":
      return {
        ...state,
        isFetchingVouchers: true,
        vouchers: [],
        nextPage: 2,
        isFetchingNextPage: false
      };
    case "FETCHING_INITIAL_VOUCHERS":
      return {
        ...state,
        isFetchingInitialVouchers: true,
      };
    case "FETCHING_VOUCHER_CATEGORIES":
      return {
        ...state,
        isFetchingVoucherCategories: true
      };
    case "FETCHING_NEXT_VOUCHER_PAGE":
      return {
        ...state,
        isFetchingNextPage: true
      };
    case "SET_VOUCHERS":
      return {
        ...state,
        vouchers: action.vouchers,
        isFetchingVouchers: false,
        isFetchingInitialVouchers: false,
        nextPage: action.nextPage,
        discountData: action.discountData
      };
    case "SET_WINDS_GIFT_CARD":
      return {
        ...state,
        windsVoucher: action.windsVoucher,
      };
    case "ADD_VOUCHERS":
      return {
        ...state,
        vouchers: state.vouchers.concat(action.vouchers),
        isFetchingNextPage: false,
        nextPage: action.nextPage
      };
    case "SET_VOUCHER_CATEGORIES":
      return {
        ...state,
        categories: action.categories,
        isFetchingVoucherCategories: false
      };
    case "SET_VOUCHER_BANNER":
      return {
        ...state,
        banner_data: action.banner_data,
      }
    case "SELECT_CATEGORY_FOR_VOUCHERS":
      return {
        ...state,
        selectedCategories: action.id
      };
    case "UNSELECT_CATEGORY_FOR_VOUCHERS":
      return {
        ...state,
        selectedCategories: state.selectedCategories.filter(item => {
          return item !== action.id;
        })
      };
    case "VOUCHER_FILTER_CLICK":
      return {
        ...state,
        isVoucherFilterModelOpen: !state.isVoucherFilterModelOpen
      };
    case "RESET_VOUCHER_FILTER":
      return {
        ...state,
        selectedCategories: null
      };
    case "SET_VOUCHER":
      return {
        ...state,
        voucher: action.voucher
      };
    case "SET_SEARCH_VOUCHERS":
      return {
        ...state,
        vouchers: action.vouchers,
        isFetchingVouchers: false,
        isFetchingInitialVouchers: false,
        nextPage: action.nextPage,
      };
    case "SET_DENOMINATION":
      return {
        ...state,
        denomination: action.denomination
      };
    case "SET_QUANTITY":
      return {
        ...state,
        quantity: action.quantity
      };
    case "SET_GIFT_DETAILS":
      return {
        ...state,
        giftDetails: action.giftDetails
      };

    case "SET_VOUCHER_LIST_AFTER_TOGGLE_BOOKMARK":
      return {
        ...state,
        vouchers: state.vouchers.map(v =>
          v.id === action.id ? { ...v, bookmarked: !v.bookmarked } : v
        )
      };
    case "FETCHING_WINDS_E_GIFT_BALANCE":
      return {
        ...state,
        isfetchingWindsEGiftCardBalance: true,
        windsEGiftBalance: []
      };
    case "GET_WINDS_E_GIFT_BALANCE":
      return {
        ...state,
        windsEGiftBalance: action.windsEGiftBalance,
        isfetchingWindsEGiftCardBalance: false,
      };
    case "FETCHING_VOUCHER_PURCHASE_HISTORY":
      return {
        ...state,
        isFetchingVoucherPurchaseHistory: true,
        voucherPurchaseHistory: [],
        nextPagePurchaseHistory: 2,
        isFetchingNextPagePurchaseHistory: false
      };
    case "SET_VOUCHER_PURCHASE_HISTORY":
      return {
        ...state,
        voucherPurchaseHistory: action.voucherPurchaseHistory,
        isFetchingVoucherPurchaseHistory: false,
        nextPagePurchaseHistory: action.nextPagePurchaseHistory
      };
    case "ADD_VOUCHER_PURCHASE_HISTORY":
      return {
        ...state,
        voucherPurchaseHistory: state.voucherPurchaseHistory.concat(action.voucherPurchaseHistory),
        isFetchingNextPagePurchaseHistory: false,
        nextPagePurchaseHistory: action.nextPagePurchaseHistory
      };
    case "FETCHING_NEXT_VOUCHER_PAGE_PURCHASE_HISTORY":
      return {
        ...state,
        isFetchingNextPagePurchaseHistory: true
      };
    case "FETCHING_VOUCHER_BALANCE_HISTORY":
      return {
        ...state,
        isFetchingVoucherBalanceHistory: true,
        voucherBalanceHistory: [],
        nextPageBalanceHistory: 2,
        isFetchingNextPageBalanceHistory: false
      };
    case "SET_VOUCHER_BALANCE_HISTORY":
      return {
        ...state,
        voucherBalanceHistory: action.voucherBalanceHistory,
        isFetchingVoucherBalanceHistory: false,
        nextPageBalanceHistory: action.nextPageBalanceHistory
      };
    case "ADD_VOUCHER_BALANCE_HISTORY":
      return {
        ...state,
        voucherBalanceHistory: state.voucherBalanceHistory.concat(action.voucherBalanceHistory),
        isFetchingNextPageBalanceHistory: false,
        // nextPageBalanceHistory: action.nextPageBalanceHistory+1
      };
    case "FETCHING_NEXT_VOUCHER_PAGE_BALANCE_HISTORY":
      return {
        ...state,
        isFetchingNextPageBalanceHistory: true
      };
    case 'VOUCHER_BALANCE_CURRENT_PAGE':
      switch (action.status) {
        case 'RESET':
          return {
            ...state,
            voucherBalancePageNo: 2,
          };
        case 'INCREMENT':
          return {
            ...state,
            voucherBalancePageNo: state.voucherBalancePageNo + 1,
          };
        case 'COMPLETED':
          return {
            ...state,
            voucherBalancePageNo: 0,
          };
        default:
          return state;
      }
    case 'VOUCHER_PURCHASE_CURRENT_PAGE':
      switch (action.status) {
        case 'RESET':
          return {
            ...state,
            voucherPurchasePageNo: 2,
          };
        case 'INCREMENT':
          return {
            ...state,
            voucherPurchasePageNo: state.voucherPurchasePageNo + 1,
          };
        case 'COMPLETED':
          return {
            ...state,
            voucherPurchasePageNo: 0,
          };
        default:
          return state;
      }

    default:
      return state;
  }
}

export default voucherReducer;
