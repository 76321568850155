export function fetchingVouchers() {
  return {
    type: "FETCHING_VOUCHERS"
  };
}

export function fetchingInitialVouchers() {
  return {
    type: "FETCHING_INITIAL_VOUCHERS"
  };
}

export function fetchingVoucherCategories() {
  return {
    type: "FETCHING_VOUCHER_CATEGORIES"
  };
}

export function fetchingNextPage() {
  return {
    type: "FETCHING_NEXT_VOUCHER_PAGE"
  };
}

export function setVouchers(vouchers, nextPage, discountData) {
  return {
    type: "SET_VOUCHERS",
    vouchers,
    nextPage,
    discountData
  };
}
export function setSearchVouchers(vouchers, nextPage,) {
  return {
    type: "SET_SEARCH_VOUCHERS",
    vouchers,
    nextPage,
  };
}
export function setVouchersBannerData(banner_data){
  return {
      type: 'SET_VOUCHER_BANNER',
      banner_data
  }
}

export function setWindsGiftCard(windsVoucher) {
  return {
    type: "SET_WINDS_GIFT_CARD",
    windsVoucher,
  };
}

export function addVouchers(vouchers, nextPage) {
  return {
    type: "ADD_VOUCHERS",
    vouchers,
    nextPage
  };
}

export function setVoucherCategories(categories) {
  return {
    type: "SET_VOUCHER_CATEGORIES",
    categories
  };
}

export function selectCategoryForVouchers(id) {
  return {
    type: "SELECT_CATEGORY_FOR_VOUCHERS",
    id
  };
}

export function onVouchersFilterClick() {
  return {
    type: "VOUCHER_FILTER_CLICK"
  }
}

export function unselectCategoryForVouchers(id) {
  return {
    type: "UNSELECT_CATEGORY_FOR_VOUCHERS",
    id
  };
}

export function resetVoucherFilter() {
  return {
    type:'RESET_VOUCHER_FILTER'
  }
}

export function setVoucher(voucher) {
  return {
    type: "SET_VOUCHER",
    voucher
  };
}

export function setDenomination(denomination) {
  return {
    type: "SET_DENOMINATION",
    denomination
  };
}

export function setQuantity(quantity) {
  return {
    type: "SET_QUANTITY",
    quantity
  };
}

export function setGiftDetails(giftDetails) {
  return {
    type: "SET_GIFT_DETAILS",
    giftDetails
  };
}

export function fetchWindsEGiftCardBalance() {
  return {
    type: "FETCHING_WINDS_E_GIFT_BALANCE"
  };
}
export function getWindsEGiftBalance(windsEGiftBalance) {
  return {
    type: "GET_WINDS_E_GIFT_BALANCE",
    windsEGiftBalance
  };
}

export function setVoucherListAfterBookmarkToggle(id, vouchers) {
  return {
    type: "SET_VOUCHER_LIST_AFTER_TOGGLE_BOOKMARK",
    id,
    vouchers
  };
}

export function fetchingVoucherPurchaseHistory() {
  return {
    type: "FETCHING_VOUCHER_PURCHASE_HISTORY"
  };
}

export function setVoucherPurchaseHistory(voucherPurchaseHistory, nextPagePurchaseHistory) {
  return {
    type: "SET_VOUCHER_PURCHASE_HISTORY",
    voucherPurchaseHistory,
    nextPagePurchaseHistory
  };
}

export function addVoucherPurchaseHistory(voucherPurchaseHistory, nextPagePurchaseHistory) {
  return {
    type: "ADD_VOUCHER_PURCHASE_HISTORY",
    voucherPurchaseHistory,
    nextPagePurchaseHistory
  };
}

export function fetchingNextPagePurchaseHistory() {
  return {
    type: "FETCHING_NEXT_VOUCHER_PAGE_PURCHASE_HISTORY"
  };
}

export function fetchingVoucherBalanceHistory() {
  return {
    type: "FETCHING_VOUCHER_BALANCE_HISTORY"
  };
}

export function setVoucherBalanceHistory(voucherBalanceHistory, nextPageBalanceHistory) {
  return {
    type: "SET_VOUCHER_BALANCE_HISTORY",
    voucherBalanceHistory,
    nextPageBalanceHistory
  };
}

export function addVoucherBalanceHistory(voucherBalanceHistory) {
  return {
    type: "ADD_VOUCHER_BALANCE_HISTORY",
    voucherBalanceHistory,
  };
}

export function fetchingNextPageBalanceHistory() {
  return {
    type: "FETCHING_NEXT_VOUCHER_PAGE_BALANCE_HISTORY"
  };
}

export const voucherBalanceCurrentPage = (status) => ({
  type: 'VOUCHER_BALANCE_CURRENT_PAGE',
  status
});

export const voucherPurchaseCurrentPage = (status) => ({
  type: 'VOUCHER_PURCHASE_CURRENT_PAGE',
  status
});