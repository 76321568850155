import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import TestimonialsMetaData from "../../seo/TestimonialsMetaData";
import WinnersPatrons from "../winners-list/Patrons";

function Testimonials() {
  return (
    <>
      <TestimonialsMetaData />
      <section className="header-sec">
        <nav className="navbar navbar-expand-lg all">
          <ul className="navbar-nav site mr-auto mt-2 mt-lg-0">
            <li className="nav-item">
              <Link className="nav-link" to="/patrons"><FormattedMessage id="page.nav-winds-patrons" /></Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/partner"><FormattedMessage id="page.nav-winds-partner" /></Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/digi-world"><FormattedMessage id="page.nav-winds-digi" /></Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about-us"><FormattedMessage id="page.nav-about-us" /></Link>
            </li>
            {/* <li className="nav-item">
              <Link className="nav-link" to="/luckydraw-patrons"><FormattedMessage id="page.nav-winds-luckydraw" /></Link>
            </li> */}
            <li className="nav-item site-active">
              <Link className="nav-link" to="/testimonials">
                <h1 style={{ fontSize: "inherit", marginBottom: "0" }}><FormattedMessage id="page.nav-winds-testimonials" /></h1>
              </Link>
            </li>
          </ul>
        </nav>
      </section>
     <WinnersPatrons/>
    </>
  );
}

export default Testimonials;