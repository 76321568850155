import React from "react";
import Modal from "react-bootstrap/Modal";
//import lodash from "lodash";
import { FormattedMessage } from "react-intl";
let autoComplete;

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";
  if (script.readyState) {
    script.onreadystatechange = function() {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }
  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};
// const loadScript = (url) => {
//   let script = document.createElement("script");
//   script.type = "text/javascript";
//   script.src = url;
//   document.getElementsByTagName("head")[0].appendChild(script);
// };
class SearchLocationDialogInput extends React.Component {
  constructor() {
    super();
    this.state = {
      query: "",
      //  key: "AIzaSyDq38-QJCuQZk8-QoTeuLO-diT-HCPohCA",  //old production key "AIzaSyABjo6NXceSsgVpHrLSjT41vdzT_BOELQ8"
      lat: null,
      lng: null,
      canFetch: false,
    };
    this.autoCompleteRef = React.createRef(null);
  }

  handlePlaceSelect = async (updateQuery) => {
    const addressObject = autoComplete.getPlace();
    const formatted_address = addressObject.formatted_address;
    const latitude = addressObject.geometry.location.lat();
    const longitude = addressObject.geometry.location.lng();
    this.setState(
      {
        query: formatted_address,
        lat: latitude,
        lng: longitude,
      },
      () => {
        this.props.onSelectingAddress(formatted_address, latitude, longitude);
      }
    );
  };
  handleScriptLoad = (updateQuery, autoCompleteRef) => {
    const defaultBounds = new window.google.maps.LatLngBounds();
    const options = {
      bounds: defaultBounds,
      componentRestrictions: { country: "in" },
    };
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      options
    );
    autoComplete.setFields([
      "address_components",
      "formatted_address",
      "geometry",
    ]);
    autoComplete.addListener("place_changed", () =>
      this.handlePlaceSelect(updateQuery)
    );
  };
  UNSAFE_componentWillMount() {
    console.debug("removing google script...");
    let keywords = ["maps.googleapis"];

    //Remove google from BOM (window object)
    window.google = undefined;

    //Remove google map scripts from DOM
    let scripts = document.head.getElementsByTagName("script");
    for (let scriptLength = scripts.length, i = scriptLength - 1; i >= 0; i--) {
      let scriptSource = scripts[i].getAttribute("src");
      if (scriptSource != null) {
        if (keywords.filter((item) => scriptSource.includes(item)).length) {
          scripts[i].remove();
          // scripts[i].parentNode.removeChild(scripts[i]);
        }
      }
    }
  }

  componentDidMount() {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_API_KEY}&libraries=places`,
      () => {
        this.handleScriptLoad(this.state.query, this.autoCompleteRef);
      }
    );
    // loadScript(
    //   `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_API_KEY}&libraries=places`
    // );
  }

  // componentDidUpdate(prevProps, prevState) {
  //   console.log(prevState);
  //   if (prevState.canFetch) {
  //     this.handleScriptLoad(this.state.query, this.autoCompleteRef);
  //   }
  // }

  // debouncedLog = lodash.debounce((event) => {
  //   this.setState({
  //     canFetch: true,
  //   });
  // }, 1000);
  setQuery = (event) => {
    //  this.debouncedLog(event);
    this.setState({
      query: event.target.value,
    });
  };

  handleClose = () => {
    this.props.close();
  };

  render() {
    return (
      <Modal size="lg" show onHide={this.handleClose}>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage
              id="navbar.search-location"
              defaultMessage="Search Location"
            />
          </Modal.Title>
          <button type="button" className="close" onClick={this.handleClose}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="input-group mb-3">
            <input
              className="form-control"
              ref={this.autoCompleteRef}
              onChange={this.setQuery}
              placeholder="Enter a City"
              value={this.state.query}
            />
            <div className="input-group-append">
              <span className="input-group-text">
                <i className="fas fa-search"></i>
              </span>
            </div>
          </div>
          <div>
            <p
              style={{ color: "#0078D7", cursor: "pointer" }}
              onClick={() => {
                this.props.onInit();
                this.setState({ show: false });
              }}
            >
              <i className="fas fa-crosshairs"></i>{" "}
              <FormattedMessage
                id="navbar.use-current-location"
                defaultMessage="Use Current Location"
              />
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={this.handleClose}>
            <FormattedMessage id="navbar.close" defaultMessage="Close" />
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default SearchLocationDialogInput;
