import HelmetMetaData from "../components/HelmetMetaData";
import { useLocation } from "react-router-dom";

function BillpayMetaData() {
  let location = useLocation();
  let currentAppUrl = process.env.REACT_APP_URL + location.pathname;

  return (
    <HelmetMetaData
      title={`Bill Pay: Online Bill Payments, Mobile Recharge DTH and FASTag | WINDS App`}
      description={`Bill Pay: Make your all bill payments online hassle free at WINDS App. Pay electricity bill, water bill, broadband, gas, mobile recharge, DTH, FASTag and municipal taxes today.`}
      keywords={`bill pay, bill payments, online bill pay, electricity bill, pay electricity bill, water bill, broadband, gas, mobile recharge, dth recharge, fastag recharge, municipal taxes`}
      voiceSchema={<script type="application/ld+json">
        {JSON.stringify(
          {
            "@context": "https://schema.org/",
            "@type": "WebPage",
            "name": "Recharge & Pay Bills",
            "speakable":
            {
              "@type": "SpeakableSpecification",
              "xpath":
                [
                  "/html/head/title",
                  "/html/head/meta[@name='description']/@content"
                ]
            },
            "url": currentAppUrl
          }
        )}
      </script>
      }
      breadcrumbSchema={<script type="application/ld+json">
        {JSON.stringify(
          {
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            "itemListElement": [{
              "@type": "ListItem",
              "position": 1,
              "name": "Home",
              "item": process.env.REACT_APP_URL
            }, {
              "@type": "ListItem",
              "position": 2,
              "name": "Recharge & Pay Bills",
              "item": currentAppUrl
            }]
          }
        )}
      </script>}
    ></HelmetMetaData>
  );
}

export default BillpayMetaData;