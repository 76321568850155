const INITIAL_STATE = {
  isFetchingOffers: false,
  isFetchingOfferCategories: false,
  isFetchingNextPage: false,
  categories: [],
  selectedCategories: [],
  offers: [],
  nextPage: 2,
  isOffersFilterModelOpen:false
};

function offerReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "FETCHING_OFFERS":
      return {
        ...state,
        isFetchingOffers: true,
        isFetchingNextPage: false,
        offers: [],
        nextPage: 2
      };
    case "FETCHING_OFFER_CATEGORIES":
      return {
        ...state,
        isFetchingOfferCategories: true
      };
    case "FETCHING_NEXT_OFFER_PAGE":
      return {
        ...state,
        isFetchingNextPage: true
      };
    case "SET_OFFERS":
      return {
        ...state,
        offers: action.offers,
        isFetchingOffers: false,
        nextPage: action.nextPage
      };
    case "ADD_OFFERS":
      return {
        ...state,
        offers: state.offers.concat(action.offers),
        isFetchingNextPage: false,
        nextPage: action.nextPage
      };
    case "SET_OFFER_CATEGORIES":
      return {
        ...state,
        categories: action.categories,
        isFetchingOfferCategories: false
      };
    case "SELECT_CATEGORY_FOR_OFFERS":
      return {
        ...state,
        selectedCategories: [...state.selectedCategories, action.id]
      };
      case "OFFER_FILTER_CLICK":
        return {
          ...state,
          isOffersFilterModelOpen: !state.isOffersFilterModelOpen
        }
      case "RESET_ORDER_FILTER":
        return {
          ...state,
          selectedCategories: []
        }
    case "UNSELECT_CATEGORY_FOR_OFFERS":
      return {
        ...state,
        selectedCategories: state.selectedCategories.filter(item => {
          return item !== action.id;
        })
      };
    case "RESET_CATEGORY":
      return {
        ...state,
        selectedCategories: [action.id]
      };
    case "SET_PARTNER_LIST_AFTER_BOOKMARK_TOGGLE":
      return {
        ...state,
        offers: state.offers.map(o =>
          o.id === action.id ? { ...o, bookmarked: !o.bookmarked } : o
        )
      };
    default:
      return state;
  }
}

export default offerReducer;
