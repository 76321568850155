import React from 'react'
import { useSelector } from 'react-redux';
import Slider from 'react-slick';

export default function ShoppingBannerCarousal() {
  const banner_data = useSelector((state) => state?.shoppingSite?.banner_data);
    const settings = {
        dots: true,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 767,
            settings: {
              dots: false
            }
          },
        ]
      }
      const onShoppingBannerClickEvent = (banner, index) => {
        if (null == banner.link) {
          return;
        }
        if (banner.link.startsWith("http")) {
          window.open(banner.link);
        }
      };
  return (
    <div className="w-100 m-auto mb-5 ShoppingBannerCarousal">
         <Slider {...settings}>
              {banner_data?.map((item,index) => {
                return (
                  <div className="justify-content-center position-relative" key={item?.id}>
                    <div className="d-flex col-12">
                      <img
                        className="d-block col-12 m-auto p-0 shopping-banner-img"
                        src={item?.image_url}
                        alt={item?.id}
                        title={item?.id}
                        onClick={() => {
                          onShoppingBannerClickEvent(item, index);
                        }}
                      />
                    </div>
                  </div>
            
                );
                
              })}
        </Slider>
    </div>
  )
}
