 import React from 'react';
 import ContentLoader from "react-content-loader";
 const WindsLoader = props => {
    const { rows, columns, height, width, padding, speed } = props;
    // Hardcoded values
    //  rows = rows || 3
    //  columns = columns || 4
    //  height = height || 60
    //  width = width || 100
    //  padding = padding || 2
   //  speed = speed 
    const coverHeightWithPadding = height + padding
    const coverWidthWithPadding = width + padding
    const initial = 0
    const covers = Array(columns * rows).fill(2)
  
    return (
      <ContentLoader
        speed={speed}
        width={columns * coverWidthWithPadding}
        height={rows * coverHeightWithPadding}
        primaryColor="#F6F7F8"
      secondaryColor="#ecebeb"
        {...props}
      >
  
        {covers.map((g, i) => {
          let vy = Math.floor(i / columns) * coverHeightWithPadding + initial
          let vx = (i * coverWidthWithPadding) % (columns * coverWidthWithPadding)
          return (
            <rect
              x={vx}
              y={vy}
              rx="0"
              ry="0"
              width={width}
              height={height}
              key={i}
            />
          )
        })}
      </ContentLoader>
    )
  }

  export default WindsLoader;