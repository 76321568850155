import React from "react";
import { Card, Button, Accordion, Modal } from "react-bootstrap";
import mission from "../../../assets/img/about/ic_mission.svg"
import vision from "../../../assets/img/about/ic_vision.svg"
import arrow from "../../../assets/img/about/arrow.png"
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import AboutMetaData from "../../../seo/AboutMetaData";

function BusinessReadMore(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="site-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter text-center">
          <FormattedMessage id="about.section5.title" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul>
          <li><FormattedMessage id="about.section5.read-more-desc1" /></li>
          <li><FormattedMessage id="about.section5.read-more-desc2" /></li>
          {/* <li><FormattedMessage id="about.section5.read-more-desc3" /></li> */}
        </ul>
      </Modal.Body>
    </Modal>
  );
}
const AboutUs = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <AboutMetaData />
      <section className="header-sec">
        <nav className="navbar navbar-expand-lg all">
          <ul className="navbar-nav site mr-auto mt-2 mt-lg-0">
            <li className="nav-item">
              <Link className="nav-link" to="/patrons"><FormattedMessage id="page.nav-winds-patrons" /></Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/partner"><FormattedMessage id="page.nav-winds-partner" /></Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/digi-world"><FormattedMessage id="page.nav-winds-digi" /></Link>
            </li>
            <li className="nav-item site-active">
              <Link className="nav-link" to="/about-us"><h1 style={{fontSize:"inherit", marginBottom:"0"}}><FormattedMessage id="page.nav-about-us" /></h1></Link>
            </li>
            {/* <li className="nav-item">
              <Link className="nav-link" to="/luckydraw-patrons"><FormattedMessage id="page.nav-winds-luckydraw" /></Link>
            </li> */}
            {/* <li className="nav-item">
              <Link className="nav-link" to="/testimonials"><FormattedMessage id="page.nav-winds-testimonials" /></Link>
            </li> */}
          </ul>
        </nav>
      </section>
      <section className="about-sec-1 site-page site-top">
        <div className="container font-proxima-nova">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 d-flex flex-column justify-content-center">
              <h2 className="title"><FormattedMessage id="about.section1.title" /></h2>
              <p className="sub-title"><FormattedMessage id="about.section1.subtitle" /></p>
            </div>
            <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 d-flex flex-column justify-content-center">
              <img width="100%" src={require('../../../assets/img/about/ic_shopping-min.png')} alt="about" title="about" />
            </div>
          </div>
        </div>
      </section>
      <section className="about-sec-2 site-page">
        <div className="container font-proxima-nova">
          <div className="row reverse-col">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <img width="100%" src={require('../../../assets/img/about/ic_about_us.png')} alt="about" title="about" className="pr-md-5 pr-0 mt-md-0 mt-3" />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <h2 className="title"><FormattedMessage id="about.section2.title" /></h2>
              {/* <p className="sub-title"><FormattedMessage id="about.section2.subtitle" /></p> */}
              <p className="desc"><FormattedMessage id="about.section2.desc1" /></p>
              <p className="desc"><FormattedMessage id="about.section2.desc2" /></p>
              {/* <p className="desc"><FormattedMessage id="about.section2.desc3" /></p>
              <p className="desc"><FormattedMessage id="about.section2.desc4" /></p> */}
            </div>
          </div>
        </div>
      </section>
      <section className="about-sec-3 site-page">
        <div className="container font-proxima-nova">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <h2 className="title"><FormattedMessage id="about.section3.title" /></h2>
              {/* <p className="sub-title"><FormattedMessage id="about.section3.desc1" /></p> */}
              <p className="desc"><FormattedMessage id="about.section3.desc1" /></p>
              {/* <p className="desc"><FormattedMessage id="about.section3.desc2" /></p> */}
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              {/* <img width="100%" src={require('../../../assets/img/about/ic_shopping-min.png')} alt="about" /> */}
            </div>
          </div>
        </div>
      </section>
      <section className="about-sec-4 site-page">
        <div className="container font-proxima-nova">
          <div className="row reverse-col">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <img width="100%" src={require('../../../assets/img/about/ic_what_we_do.png')} alt="about" title="about" className="pr-md-5 pr-0 mt-md-0 mt-3" />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <h2 className="title"><FormattedMessage id="about.section4.title" /></h2>
              <p className="desc"><FormattedMessage id="about.section4.desc1" /></p>
              <p className="desc"><FormattedMessage id="about.section4.desc2" /></p>
              <Accordion defaultActiveKey="2">
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                      <img className="mission-img" src={mission} alt="mission" />
                      <h3 className="mb-0 font-weight-bold"><FormattedMessage id="about.section4.mission" /></h3>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <p className="desc"><FormattedMessage id="about.section4.mission-desc" /></p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                      <img className="mission-img" src={vision} alt="vision" title="vision" />
                      <h3 className="mb-0 font-weight-bold"><FormattedMessage id="about.section4.vision" /></h3>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <p className="desc"><FormattedMessage id="about.section4.vision-desc" /></p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="about-sec-5 site-page">
        <div className="container font-proxima-nova">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 d-flex flex-column justify-content-center">
              <h2 className="title"><FormattedMessage id="about.section5.title" /></h2>
              <p className="desc"><FormattedMessage id="about.section5.desc1" /></p>
              <p className="desc"><FormattedMessage id="about.section5.desc2" /></p>
              <p className="desc"><FormattedMessage id="about.section5.desc3" /></p>
              <br />
              <p className="sub-title" style={{ "fontWeight": "600", cursor: "pointer" }} onClick={() => setModalShow(true)}>
                <FormattedMessage id="about.section5.read-more" />
                <img src={arrow} width="15" style={{ marginLeft: "5px" }} alt="arrow" />
              </p>
              <BusinessReadMore
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 d-flex flex-column justify-content-center">
            </div>
          </div>
        </div>
      </section> */}
      <section className="about-sec-4 site-page pb-0 pt-0">
        <div className="container font-proxima-nova">
          <div className="row reverse-col">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center align-items-center">
              <img width="100%" src={require('../../../assets/img/about/ic_user_friendly_mockup.png')} alt="about" title="about" className="pr-md-5 pr-0 mt-md-0 mt-3 w-75" />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <h2 className="title"><FormattedMessage id="about.section6.title" /></h2>
              <p className="desc"><FormattedMessage id="about.section6.desc1" /></p>
              <p className="desc"><FormattedMessage id="about.section6.desc2" /></p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default AboutUs;
