const INITIAL_STATE = {
  isFetchingShoppingSites: false,
  isFetchingShoppingSitesCategories: false,
  isFetchingNextPage: false,
  categories: [],
  selectedCategories: null,
  shoppingSites: [],
  nextPage: 2,
  isFilterModelOpen: false,
  banner_data:[],
};

function shoppingSitesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "FETCHING_SHOPPING_SITES":
      return {
        ...state,
        isFetchingShoppingSites: true,
        isFetchingNextPage: false,
        shoppingSites: [],
        nextPage: 2
      };
    case "FETCHING_SHOPPING_SITES_CATEGORIES":
      return {
        ...state,
        isFetchingShoppingSitesCategories: true
      };
    case "FETCHING_NEXT_SHOPPING_SITE_PAGE":
      return {
        ...state,
        isFetchingNextPage: true
      };
    case "SET_SHOPPING_SITES":
      return {
        ...state,
        shoppingSites: action.shoppingSites,
        isFetchingShoppingSites: false,
        nextPage: action.nextPage
      };
    case "SET_SHOPPING_SITES_BANNER":
      return{
        ...state,
        banner_data:action.banner_data,
      }
    case "ADD_SHOPPING_SITES":
      return {
        ...state,
        shoppingSites: state.shoppingSites.concat(action.shoppingSites),
        isFetchingNextPage: false,
        nextPage: action.nextPage
      };
    case "SET_SHOPPING_SITE_CATEGORIES":
      return {
        ...state,
        categories: action.categories,
        isFetchingShoppingSitesCategories: false
      };
    case "SELECT_CATEGORY":
      return {
        ...state,
        selectedCategories: action.id
      };
    case "UNSELECT_CATEGORY":
      return {
        ...state,
        selectedCategories: state.selectedCategories.filter(item => { 
          return item !== action.id;
        })
      };
    case "RESET_FILTER":
      return {
        ...state,
        selectedCategories: null
      };
    case "SET_SHOPPING_LIST_AFTER_BOOKMARK":
      return {
        ...state,
        shoppingSites: state.shoppingSites.map(ss =>
          ss.id === action.id ? { ...ss, bookmarked: !ss.bookmarked } : ss
        )
      };

    case "FILTER_CLICKED":
      return {
        ...state,
        isFilterModelOpen: !state.isFilterModelOpen
      };
    default:
      return state;
  }
}

export default shoppingSitesReducer;
