import React, { Component } from "react";
import { Link } from "react-router-dom";
import customerPartnerClientX from "../../../utils/api/customerPartnerClientX";
import SideBar from "../components/SideBar";
import Profile from "../components/Profile";
import DefaultStoreImage from "../../../assets/default-store-image.svg";
import deleteIcon from "../../../assets/delete.svg";
import classNames from "classnames";
import update from "immutability-helper";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import { FormattedMessage } from "react-intl";
import EmptyState from "../../../components/EmptyState";
import EmptyImage from "../../../assets/empty-state-screen.svg";
export default class Ratings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ratings: [],
      isFetchingRatings: false,
      isConfirmationDialogOpen: false,
      currentConfirmationDialog: null,
    };
  }

  componentDidMount() {
    if (this.props.profile === null) {
      this.props.history.push('/login')
    }
    else {
      this.setState({
        isFetchingRatings: true,
      }, () => {
        customerPartnerClientX.get("v1/reviews").then((response) => {
          this.setState({
            ratings: response.data.data,
            isFetchingRatings: false,
          });
        });
      });
    }
  }

  deleteRating = (id, currentIndex, storeName) => {
    const currentModelItem = {
      id: id,
      currentIndex: currentIndex,
      storeName: storeName,
    };
    this.setState({
      isConfirmationDialogOpen: true,
      currentConfirmationDialog: { ...currentModelItem },
    });
  };

  onConfirmDeleteRating = () => {
    this.setState(
      {
        isConfirmationDialogOpen: false,
      },
      () => {
        customerPartnerClientX
          .delete(
            `v1/reviews/${this.state.currentConfirmationDialog.id}`
          )
          .then((response) => {
            let ratings = this.state.ratings;
            ratings.splice(
              this.state.currentConfirmationDialog.currentIndex,
              1
            );
            let newState = update(this.state, {
              ratings: { $set: ratings },
            });
            this.setState(newState);
          })
          .catch((error) => { });
      }
    );
  };

  onDismissDeleteRating = () => {
    this.setState({
      isConfirmationDialogOpen: false,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="w-100 profile-container-head">
          <div className="container pt-3 pb-3 pt-md-4 pb-md-4">
            <div className="row mb-md-3">
              <div className="col-12 m-auto font-proxima-nova text-white">
                <small>
                  <Link to="/">
                    <FormattedMessage
                      id="breadcrumbs.home"
                      defaultMessage="Home"
                    />
                  </Link>
                </small>{" "}
                /{" "}
                <small>
                  <Link to="/profile">
                    <FormattedMessage
                      id="profile.profile"
                      defaultMessage="Profile"
                    />
                  </Link>
                </small>{" "}
                /{" "}
                <small>
                  <strong>
                    <FormattedMessage
                      id="profile.my-ratings"
                      defaultMessage="My Ratings"
                    />
                  </strong>
                </small>
              </div>
            </div>

            <div className="d-none d-md-block">
              {null !== this.state.profile && <Profile />}
            </div>
          </div>
        </div>

        <div className="container mt-md-5 mt-0 ">
          <div className="row mb-4 mt-md-4 mb-md-4">
            <div className="col-12 col-md-4 d-none d-md-block font-proxima-nova border-right">
              <SideBar />
            </div>
            <div className="col-12 col-md-8 d-md-block">
              <div className="row d-none d-md-flex">
                <div className="col-8 font-proxima-nova-bold">
                  <h5>
                    <strong>
                      <FormattedMessage
                        id="profile.my-ratings"
                        defaultMessage="My Ratings"
                      />
                    </strong>
                  </h5>
                </div>
                <div className="col-2"></div>
              </div>
              <div className="row">
                <div className="col-12 ml-md-5 d-flex flex-wrap">
                  {this.state.ratings.length === 0 &&
                    !this.state.isFetchingRatings && (
                      <div className="empty-state">
                        <EmptyState
                          title={
                            <FormattedMessage
                              id="profile.no-rating"
                              defaultMessage="No Ratings yet. Let your executive partner know what you think of them."
                            />
                          }
                          imageWidth={25}
                          image={EmptyImage}
                        />
                      </div>
                    )}
                  {this.state.ratings.length > 0 &&
                    this.state.ratings.map((item, index) => {
                      return (
                        <div
                          className="card mb-2 mt-2 mt-md-4 bg-light border-0 col-md-5 mr-md-3"
                          key={index}
                        >
                          <div
                            className="card-body font-proxima-nova"
                            style={{ padding: "1.25rem .5rem" }}
                          >
                            {null != item.store_image ? (
                              <div
                                className="d-inline-block mr-3 profile-pic"
                                style={{
                                  backgroundImage: `url(${item.store_image})`,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "cover",
                                }}
                              ></div>
                            ) : (
                              <div
                                className="d-inline-block mr-3 profile-pic"
                                style={{
                                  backgroundImage: `url(${DefaultStoreImage})`,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                }}
                              ></div>
                            )}
                            <div className="d-inline-block pr-1 ratings-info">
                              <p className="mb-0">
                                <strong>{item.store_name}</strong>
                              </p>
                              <p className="mb-0">
                                <span
                                  style={{
                                    height: "15px",
                                    width: "22px",
                                    borderRadius: "2px",
                                    fontSize: "10px",
                                    textAlign: "center",
                                    display: "inline-block",
                                  }}
                                  className={classNames("text-white mr-2", {
                                    "bg-rating-green": item.rating >= 4,
                                    "bg-rating-orange":
                                      item.rating >= 3 && item.rating < 4,
                                    "bg-rating-red": item.rating < 3,
                                  })}
                                >
                                  {item.rating}
                                </span>
                                <span
                                  style={{ color: "#797979", fontSize: "12px" }}
                                >
                                  {item.created_at}
                                </span>
                              </p>
                            </div>
                            <span
                              onClick={() =>
                                this.deleteRating(
                                  item.id,
                                  index,
                                  item.store_name
                                )
                              }
                              className="mt-3 float-right d-block"
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                src={deleteIcon}
                                alt="delete icon"
                                title="delete icon"
                                className="mr-2 align-text-top"
                              />
                              <FormattedMessage
                                id="general.delete"
                                defaultMessage="Delete"
                              />
                            </span>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              {this.state.currentConfirmationDialog && (
                <ConfirmationDialog
                  isConfirmationDialogOpen={this.state.isConfirmationDialogOpen}
                  title={
                    <>
                      <FormattedMessage
                        id="profile.delete-ratings"
                        defaultMessage="Delete Ratings"
                      />
                    </>
                  }
                  body={
                    <>
                      <FormattedMessage
                        id="profile.delete-rating-confirmation"
                        defaultMessage="Are you sure, you want to delete the rating of"
                      />{" "}
                      <strong>
                        {this.state.currentConfirmationDialog.storeName}
                      </strong>
                    </>
                  }
                  buttonTxt1={
                    <>
                      <FormattedMessage
                        id="profile.confirm"
                        defaultMessage="Confirm"
                      />
                    </>
                  }
                  buttonTxt2={
                    <>
                      <FormattedMessage
                        id="profile.cancel"
                        defaultMessage="Cancel"
                      />
                    </>
                  }
                  onSuccess={this.onConfirmDeleteRating}
                  onDismiss={this.onDismissDeleteRating}
                />
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
