import profileClientX from "./api/profileClientX"
import Cookies from 'universal-cookie';
import CryptoJS from "crypto-js";
const cookies = new Cookies();
const secretKey = process.env.REACT_APP_SECRET_KEY
export const accessTokenName = process.env.REACT_APP_ACCESS_TOKEN_NAME
const refreshTokenName = process.env.REACT_APP_REFRESH_TOKEN_NAME

export const setUserAccessToken = (accessToken) => {
  try {
    const encrypted = CryptoJS.AES.encrypt(accessToken, secretKey).toString();
    // localStorage.setItem("token", encrypted)

    var date = new Date();
    date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000); //set token validity as 1 DAY
    cookies.set(accessTokenName, encrypted, {
      path: '/',
      httpOnly: false,
      secure: true, // Set to true if using HTTPS
      expires: date
    });
    localStorage.setItem('wst', encrypted);
  } catch (error) {
  }
};

export const getUserAccessToken = () => {
  try {
    let encryptedAccessToken = cookies.get(accessTokenName);

    if(encryptedAccessToken === null || encryptedAccessToken === undefined){
      encryptedAccessToken = localStorage.getItem('wst')
    }
    if (!encryptedAccessToken) {
      return null; // Handle case where the token is not found
    }
    const bytes = CryptoJS.AES.decrypt(encryptedAccessToken, secretKey);
    const accessToken = bytes.toString(CryptoJS.enc.Utf8);
    return accessToken

  } catch (error) {
    return null; // Handle invalid token
  }
};

export const setUserRefreshToken = (refresh_token) => {
  const encrypted = CryptoJS.AES.encrypt(refresh_token, secretKey).toString();
  localStorage.setItem(refreshTokenName, encrypted)
}

export const getUserRefreshToken = () => {
  try {
    const encryptedRefreshToken = localStorage.getItem(refreshTokenName)

    if (!encryptedRefreshToken) {
      return null; // Handle case where the token is not found
    }
    const bytes = CryptoJS.AES.decrypt(encryptedRefreshToken, secretKey);
    const refreshToken = bytes.toString(CryptoJS.enc.Utf8);
    return refreshToken

  } catch (error) {
    return null; // Handle invalid token
  }
};

export const handleUnAuthentication = (status) => {
  if (401 === parseInt(status)) {
    getLatestAccessToken()
  }
}

export const getLatestAccessToken = () => {
  let refresh_token = getUserRefreshToken()
  if (refresh_token) {
    profileClientX
      .post("v1/refresh-token", { refresh_token: refresh_token })
      .then((response) => {
        setUserAccessToken(response?.data?.access_token)
        setUserRefreshToken(response?.data?.refresh_token)
        let access_token = getUserAccessToken()
        if (access_token) {
          window.location.reload()
        }
      })
      .catch((error) => {
        InvalidTokenHandler()
      });
  }
  else {
    InvalidTokenHandler()
  }
}

export const InvalidTokenHandler = (token) => {
  removeCookie(accessTokenName)
  localStorage.clear();
  window.location.href = '/login'
}

export const removeCookie = (token) => {
  cookies.remove(token, { path: '/' })
}
