import React, { Component } from "react";
import { Link } from "react-router-dom";
import SideBar from "../components/SideBar";
import ProfileHeader from "../components/Profile";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { updateProfile } from "../../../actions";
import { ErrorToast } from "../../../components/ErrorToast";
import CombineErrorMessages from "../../../components/CombineErrorMessages";
import ProfileDetails from "./ProfileDetails";

class Profile extends Component {

  componentDidMount() {
    if (this.props.profile === null) {
      ErrorToast("Unauthenticated", CombineErrorMessages);
      this.props.history.push('/login')
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="container-fluide profile-container-head">
          <div className="container pt-3 pb-3 pt-md-4 pb-md-4">
            <div className="row mb-3">
              <div className="col-12 m-auto text-white">
                <small>
                  <Link to="/">
                    <FormattedMessage
                      id="breadcrumbs.home"
                      defaultMessage="Home"
                    />
                  </Link>
                </small>{" "}
                /{" "}
                <small>
                  <strong>
                    <FormattedMessage
                      id="profile.profile"
                      defaultMessage="Profile"
                    />
                  </strong>
                </small>
              </div>
            </div>

            {null !== this.props.profile && <ProfileHeader />}
          </div>
        </div>
        <div className="container mt-5">
          <div className="row mt-5 mb-3 mt-md-4 mb-md-4">
            <div className="col-12 col-md-4 d-md-block font-proxima-nova border-right">
              <SideBar />
            </div>
            <div className="col-12 col-md-8 d-none d-md-block ">
              <ProfileDetails />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.global.profile,
    location: state.location.formatted_location,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setProfile: (responseData) => {
      dispatch(updateProfile(responseData));
    },
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Profile));
