import React, { Component } from "react";
import BannerCarousel from "../components/BannerCarousel";
import NearbyPartnerCarousel from "../components/NearbyPartnerCarousel";
import VoucherCarousel from "../components/VoucherCarousel";
import BillPayCarousel from "../components/BillPayCarousel";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  setHome,
  setHomeAfterBookmarkToggle,
  setLoading,
  setWrp,
  setCompleteProfileCount,
  setMonetizedAffiliate,
  setDeal_of_the_day,
} from "../actions";
import { FormattedMessage } from "react-intl";
import lodash from "lodash";
import { currentlySelectedLanguage } from "../../../utils/helper";
import { FirebaseContext } from "../../../utils/firebase";
import { onGenericAction, onReferred } from "../../../utils/events";
import ic_copy from "../../../assets/home/ic_copy.svg";
import ic_share from "../../../assets/home/ic_share.svg";
import { toast } from "react-toastify";
import arrow from "../../../assets/home/ic_view_arrow.svg";
import viewAll from "../../../assets/home/ic_view_all_arrow.svg";
import insuranceBG0 from "../../../assets/home/ic_rectangle_bike.png";
import insuranceBG1 from "../../../assets/home/ic_rectangle_car.png";
import insuranceBG2 from "../../../assets/home/ic_rectangle_health.png";
import referEarnMockup from "../../../assets/img/site-patrons/ic_app_features.png";
import FunGameCarousel from "../components/FunGameCarousel";
import HomeMetaData from "../../../seo/HomeMetaData";
import LoginDialog from "../../../components/LoginDialog";
import CompleteProfileDialog from "../../../components/CompleteProfileDialog";
import bpAssured from "../../../assets/billpay/ic_billpay_logo.svg";
import TestimonialCarousal from "../components/TestimonialCarousal";
import WinnersCarousal from "../components/WinnersCarousal";
import WindsJourney from "../components/WindsJourney";
import ShoppingCarousal from "../components/ShoppingCarousal";
import { setEarnings } from "../../earnings/actions";
import { getWindsClientIp } from "../../../actions";
import customerCommonClientX from "../../../utils/api/customerCommonClientX";
import axios from "axios";
import SponsoredSlider from "../components/SponsoredSilder";
import { profile } from "../../../utils/cleverTap";
import DealsCorousel from "../components/DealsCarousel";
import bharatPay from '../../../assets/billpay/B mnemonic_SVG.svg'
import Iframe from "../../../components/iFrame";
import FeaturedProducts from "../../../components/FeaturedProducts";
class Home extends Component {
  static contextType = FirebaseContext;
  state = {
    showWrpTooltip: false,
    isRedeemWrpModelOpen: false,
    redeemableWrp: null,
    profile: null,
    referrer_wrp: null,
    heading_text: this.props.heading_text,
    services: [],
    flight: null,
    URL: [
      { key: "vouchers", url: "/vouchers" },
      { key: "nearby_partners", url: "/nearby-partners" },
      { key: "shopping", url: "/shopping-sites" },
      { key: "bill_pay", url: "/bill-pay" },
      { key: "wrp", url: "/winds-coins" },
      { key: "wallet", url: "/wallet" },
      { key: "my_earning", url: "/earnings" },
      { key: "rewards", url: "/rewards" },
      { key: "insurance", url: "/insurance" },
      { key: "url", url: "" },
    ],
    insuranceBG: [
      { bgImage: insuranceBG1, color: "#289186", buttonBG: "#58C8BB" },
      { bgImage: insuranceBG0, color: "#C44760", buttonBG: "#FB90A5" },
      { bgImage: insuranceBG2, color: "#6F53C2", buttonBG: "#AB93F0" },
    ],
    insuranceDescrption: [
      { desc: `${(<FormattedMessage id="health-insurance-desc" />)}` },
    ],
    isLoginDialogOpen: false,
    isCompleteProfileDialogOpen: false,
  };

  componentDidMount() {
    onGenericAction("Home Screen Loaded");
    const { app_maintenance } = this.context;
    if (!app_maintenance) {
      this.props.onInit(
        this.props.latitude,
        this.props.longitude,
        this.props.windsClientIp,
        this.props.profile
      );
      setTimeout(() => {
        if (this.props.profile) {
          if (
            this.props.profile?.can_show_basic_profile_progress_popup ||
            this.props.profile?.can_show_preference_percentage_popup
          ) {
            if (this.props.completeProfileCount !== 1) {
              this.props.banner.length > 0 && this.openCompleteProfileDialog();
            }
          }
          this.props.setCompleteProfileCount();
        }
      }, 1000);
    } else {
      window.location.href = `${
        process.env.REACT_APP_BASE_URL_APP_MAINTENANCE
      }/${currentlySelectedLanguage()}`;
    }

    axios
      .get("https://customer-common-api.winds.co.in/v1/flight/home")
      .then((response) => {
        if (response.data.success) {
          this.setState({
            services: response.data.data.services,
            flight: response.data.data.flight,
          });
        }
      })
      .catch((error) => {
        // console.error('Error fetching data:', error);
      });
  }

  componentDidUpdate(prevProps) {
    if (this.context.app_maintenance) {
      window.location.href = `${
        process.env.REACT_APP_BASE_URL_APP_MAINTENANCE
      }/${currentlySelectedLanguage()}`;
    }
    if (this.props.latitude !== prevProps.latitude) {
      this.props.onInit(
        this.props.latitude,
        this.props.longitude,
        this.props.windsClientIp,
        this.props.profile
      );
    }
    if (this.props.total_points !== prevProps.total_points) {
      const redeemableWrp =
        this.props.active_coupons * this.props.total_points_per_coupon +
        this.props.total_points;
      this.setState({ redeemableWrp });
    }
  }

  referred = () => {
    onReferred();
  };

  insuranceURL = (URL) => {
    if (this.props?.profile) window.open(URL, "_blank", "noopener,noreferrer");
    else this.openLoginDialog();
  };

  onMouseEnterOnProgressBar = () => {
    this.setState({
      showWrpTooltip: true,
    });
  };

  onMouseLeaveOnProgressBar = () => {
    this.setState({
      showWrpTooltip: false,
    });
  };

  openLoginDialog = () => {
    this.setState({ isLoginDialogOpen: true });
  };

  closeLoginDialog = () => {
    this.setState({ isLoginDialogOpen: false });
  };

  openCompleteProfileDialog = () => {
    this.setState({ isCompleteProfileDialogOpen: true });
  };
  closeCompleteProfileDialog = () => {
    this.setState({ isCompleteProfileDialogOpen: false });
  };

  onCopy = (e) => {
    this.code.select();
    document.execCommand("copy");
    toast.success(
      <FormattedMessage
        id="general.code-copied"
        defaultMessage="Code copied successfully."
      />,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      }
    );
    e.target.focus();
  };

  onToggleBookmark = (id, isBookmarked, url, type) =>
    !isBookmarked
      ? this.props.addBookmark(
          id,
          type,
          url,
          this.props.banner,
          this.props.affiliate,
          this.props.popular_affiliate,
          this.props.partner,
          this.props.voucher,
          this.props.billpay,
          this.props.offers,
          this.props.tags
        )
      : this.props.deleteBookmark(
          id,
          type,
          url,
          this.props.banner,
          this.props.affiliate,
          this.props.popular_affiliate,
          this.props.partner,
          this.props.voucher,
          this.props.billpay,
          this.props.offers,
          this.props.tags
        );

  handleWindsKartLink = (URL) => {
    const { profile, history } = this.props;
    if (profile?.email) {
      const urlToOpen =
        URL ||
        `${process.env.REACT_APP_SSO_URL}/login?continue=${process.env.REACT_APP_URL}`;
      window.open(urlToOpen, "_blank");
    } else {
      if (profile?.email === null || profile?.email === "") {
        this.props.history.push({
          pathname: "/profile",
          state: { mailState: "true" },
        });
        toast.error("Complete your mailing info to continue");
      } else {
        history.push("/login");
        toast.error("Login to continue.");
      }
    }
  };

  render() {
    const { services, flight, monetized_affiliate } = this.state;
    const hotelService = services.find((service) => service.name === "Hotel");
    const flightService = services.find((service) => service.name === "Flight");
    return this.props.banner.length === 0 ? (
      <this.Shimmer />
    ) : (
      <React.Fragment>
        <HomeMetaData />
        <this.ServiceCategories />
        <BannerCarousel banner={this.props.banner} />
        <this.Featured_products />
        {this.props.monetized_affiliate &&
          this.props.monetized_affiliate.length > 0 && (
            <SponsoredSlider
              title={this.props.heading_text.monetized_affiliates.percentage}
              data={this.props.monetized_affiliate}
            />
          )}

        {/* {this.props?.deal_of_the_day?.deals &&
          this.props.deal_of_the_day?.deals?.length > 0 && <this.deals />} */}
        {this.props.voucher.length > 0 && <this.Vouchers />}
        <this.BillPayments />
        {/* <ShoppingCarousal /> */}
        {/* <this.ShoppingSites /> */}
        <this.NearbyPartners />
        {this.props?.insurance && <this.Insurance />}
        {this.props?.heading_text?.travel && <this.Travel />}
        {this.props?.fun_game_zone?.game_data?.length > 0 && (
          <FunGameCarousel />
        )}
        {!this.props.profile && <this.Refer />}
        {this.props.profile && <this.hasProfileRefer />}
        <WindsJourney />
        <TestimonialCarousal />
        {/* <WinnersCarousal/> */}

        {/* <this.eCommerceBanner /> */}
        {/* <this.ShopNow /> */}
        {/* <this.Offers /> */}
        {/* <AllBrands tags={this.props.tags} /> */}
        {this.state.isLoginDialogOpen && (
          <LoginDialog
            isLoginDialogOpen={this.state.isLoginDialogOpen}
            closeLoginDialog={this.closeLoginDialog}
          />
        )}
        {this.state.isCompleteProfileDialogOpen && (
          <CompleteProfileDialog
            isCompleteProfileDialogOpen={this.state.isCompleteProfileDialogOpen}
            closeCompleteProfileDialog={this.closeCompleteProfileDialog}
          />
        )}
      </React.Fragment>
    );
  }

  Shimmer = (props) => {
    return (
      <React.Fragment>
        <div className="shine slider-shimmer"></div>
        {lodash.range(4).map((index) => {
          return (
            <div
              className="container-fluid container-fluid--home-section"
              key={index}
            >
              <div className="row row--home-section-title">
                <div className="col-md-12">
                  <div className="heading shine"></div>
                </div>
              </div>
              <div className="row">
                <div className="col-4 col-md-3">
                  <div className="box shine"></div>
                </div>
                <div className="col-4 col-md-3">
                  <div className="box shine"></div>
                </div>
                <div className="col-4 col-md-3">
                  <div className="box shine"></div>
                </div>
                <div className="col-4 col-md-3 d-none d-md-block">
                  <div className="box shine"></div>
                </div>
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  };

  ServiceCategories = (props) => {
    return (
      <React.Fragment>
        <section className="home-page  service-categories">
          <div className="container font-proxima-nova">
            <div className="row home-reverse">
              {/* <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 d-flex flex-column justify-content-start">
                <h3 className="title"><FormattedMessage id="home.section1.title" /></h3>
              
                <p className="desc"><FormattedMessage id="home.section1.desc" /></p>
              </div> */}
              {/* <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 d-flex flex-column justify-content-center cate-img">
                <img className="categories-img" src={require('../../../assets/home/ic_catogery_bg.png')} alt="categories" title="categories"/>
              </div> */}
            </div>
            <div className="row">
              <div className="col-12">
                {/* <h3 className="mb-2"><span className=""><FormattedMessage id="home.section1.shop-by" /></span> <FormattedMessage id="home.section1.categories" /></h3> */}
                <div className="row service-categories-link px-md-3 d-flex justify-content-md-between pt-2">
                  {this.props.service_categories &&
                    this.props.service_categories.map((item, index) => {
                      let getFilteredURL = this.state?.URL?.find(
                        (list) => list.key === item.key
                      );
                      if (getFilteredURL?.key === "url") {
                        return (
                          <div
                            key={index}
                            className="col-3 col-lg-auto d-sm-block "
                          >
                            <div
                              className="box cursor-pointer"
                              onClick={() => this.handleWindsKartLink(item.url)}
                            >
                              <img
                                className="icon"
                                src={item.icon}
                                alt={item.key}
                              />
                              <img className="arrow" src={arrow} alt="arrow" />
                            </div>
                            <p className="box-title">{item.value}</p>
                          </div>
                        );
                      } else {
                        return (
                          <div
                            key={index}
                            className="col-3 col-lg-auto d-sm-block"
                          >
                            <Link to={`${getFilteredURL?.url}`}>
                              <div className="box">
                                <img
                                  className="icon"
                                  src={item.icon}
                                  alt={item.key}
                                  title={item.key}
                                />
                                <img
                                  className="arrow"
                                  src={arrow}
                                  alt="arrow"
                                  title="arrow"
                                />
                              </div>
                              <p className="box-title">{item.value}</p>
                            </Link>
                          </div>
                        );
                      }
                    })}
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  };

  Vouchers = (props) => {
    let wrp;
    if (this.props.heading_text && this.props.heading_text.nearby_voucher) {
      wrp = this.props.heading_text.nearby_voucher.wrp;
    }

    return (
      <div className="home-page section-space home-vouchers">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-9">
              <h2 className="title">
                <FormattedMessage id="home.brand-vouchers" />
              </h2>
            </div>
            <div className="col-3 text-right   ">
              <Link to={{ pathname: "/vouchers", state: { from: "/" } }}>
                <span className="title-thick d-flex justify-content-end mobile-hide">
                  <FormattedMessage
                    id="home.view-all"
                    defaultMessage="View All"
                    values={{ what: "react-intl" }}
                  />
                  <img
                    className="view-all"
                    src={viewAll}
                    alt="view all"
                    title="view all"
                  />
                </span>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h3 className="title-thick font-slim">
                <FormattedMessage id="home.online-vouchers-desc1" /> {wrp}{" "}
                <FormattedMessage id="home.online-vouchers-desc2" />{" "}
              </h3>
            </div>
          </div>
          <div className="row d-flex justify-content-between mt-3">
            {this.props.voucher.length > 0 && (
              <VoucherCarousel
                vouchers={this.props.voucher}
                onToggleBookmarkVouchers={(id, isBookmarked, url, type) => {
                  this.onToggleBookmark(id, isBookmarked, url, type);
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  };
  deals = (props) => {
    return (
      <div className="service-categories">
        <div className="container service-categories">
          <div className="row">
            <div className="col-9">
              <h2 className="title bold pt-4 pl-2 pb-0">
                <FormattedMessage
                  id="breadcrumbs.deals"
                  defaultMessage="Deals of the Day"
                />
              </h2>
            </div>
            {/* <div className="col-2 d-flex text-center align-items-center">
            <span
              className="font-proxima-nova-bold more "
              style={{ color: "#0078D7", fontWeight: 600 }} >
              <Link to="/deals"> <FormattedMessage id="home.more"  defaultMessage="More"  description="More"  values={{ what: "react-intl" }}  />
              </Link>
            </span>
          </div> */}
            <div className="col-3 text-right d-flex align-items-center justify-content-end">
              <Link to={{ pathname: "/deals", state: { from: "/" } }}>
                <span className="title-thick d-flex justify-content-end mobile-hide">
                  <FormattedMessage
                    id="home.view-all"
                    defaultMessage="View All"
                    values={{ what: "react-intl" }}
                  />
                  <img
                    className="view-all"
                    src={viewAll}
                    alt="view all"
                    title="view all"
                  />
                </span>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h3 className="title-thick font-slim pl-1">
                <FormattedMessage id="home.online-deals.desc-1" />{" "}
              </h3>
            </div>
          </div>
          <div className="row ">
            <div className="col-12 deals-carousel-home">
              {this.props.deal_of_the_day.deals &&
                this.props.deal_of_the_day.deals.length > 0 && (
                  // <DealsCorousel deals={this.props.deals} />

                  <DealsCorousel deals={this.props.deal_of_the_day.deals} />
                )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  NearbyPartners = (props) => {
    let wrp;
    if (this.props?.heading_text && this.props?.heading_text?.nearby_partners) {
      wrp = this.props?.heading_text?.nearby_partners.wrp;
    }
    return (
      <div className="home-page section-space home-nearby-partners">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-9">
              <h2 className="title">
                <FormattedMessage id="categories-nearby" />
              </h2>
            </div>
            <div className="col-3 text-right">
              <Link
                to={{
                  pathname: "/nearby-partners/categories",
                  state: { from: "/" },
                }}
              >
                <span className="title-thick d-flex justify-content-end mobile-hide">
                  <FormattedMessage
                    id="home.view-all"
                    defaultMessage="View All"
                    values={{ what: "react-intl" }}
                  />
                  <img
                    className="view-all"
                    src={viewAll}
                    alt="view all"
                    title="view all"
                  />
                </span>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h3 className="title-thick font-slim">
                <FormattedMessage id="categories-nearby-desc1" /> {wrp}{" "}
                <FormattedMessage id="categories-nearby-desc2" />
              </h3>
            </div>
          </div>
          <div className="row d-flex justify-content-between mt-4">
            <div className="col-12">
              {this.props.partner.length > 0 && (
                <NearbyPartnerCarousel partners={this.props.partner} />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  Refer = (props) => {
    return (
      <React.Fragment>
        <section className="home-page section-space home-refer-earn">
          <div className="container font-proxima-nova">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 d-flex flex-column justify-content-center">
                <p className="title">
                  <span className="font-slim">
                    {" "}
                    <FormattedMessage id="home.download-title-abouve" />
                  </span>
                </p>
                <div className="space"></div>
                <h2 className="title">
                  <FormattedMessage id="home.download-title" />
                </h2>
                <p className="desc ">
                  <FormattedMessage id="home.download-desc" />
                </p>
                <div className="space"></div>
                <span className="title-thick mb-3">
                  <FormattedMessage id="home.download-subtitle" />
                </span>
                <div className="paystore">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.windseworld.customer"
                    target="blank"
                  >
                    <img
                      className="icons"
                      src={require("../../../assets/img/site-patrons/ic_google_pay.png")}
                      alt="play store"
                      title="play store"
                    />
                  </a>
                  <a
                    href="https://apps.apple.com/in/app/winds-the-patron-app/id1483895863"
                    target="blank"
                  >
                    <img
                      className="icons"
                      src={require("../../../assets/img/site-patrons/ic_app_store.png")}
                      alt="app store"
                      title="app store"
                    />
                  </a>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 d-flex flex-column justify-content-start">
                <img
                  className="refer-mock"
                  src={referEarnMockup}
                  alt="login"
                  title="login"
                />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  };

  hasProfileRefer = (props) => {
    const { referral_code, referral_link, name } = this.props.profile;

    return (
      <React.Fragment>
        <section className="home-page section-space home-refer-earn">
          <div className="container font-proxima-nova">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 d-flex flex-column justify-content-center">
                <p className="title">
                  <span className="font-slim">
                    <FormattedMessage id="home.download-title-abouve" />
                  </span>
                </p>
                <div className="space"></div>

                <h2 className="title">
                  <FormattedMessage id="home.download-title" />
                </h2>
                <p className="desc w-75">
                  <FormattedMessage id="home.download-desc" />
                </p>
                <div className="space"></div>
                <span className="title-thick mb-3">
                  <FormattedMessage id="home.download-subtitle" />
                </span>
                <div className="paystore">
                  <a href="https://winds.to/app" target="blank">
                    <img
                      className="icons"
                      src={require("../../../assets/img/site-patrons/ic_google_pay.png")}
                      alt="icon"
                      title="icon"
                    />
                  </a>
                  <a href="https://winds.to/app" target="blank">
                    <img
                      className="icons"
                      src={require("../../../assets/img/site-patrons/ic_app_store.png")}
                      alt="about"
                      title="about"
                    />
                  </a>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 d-flex flex-column justify-content-start">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                    <div className="refer-earn-box">
                      <img
                        className="refer-img"
                        src={require("../../../assets/home/ic_wrp_coin_box.png")}
                        alt="wrp"
                        title="wrp"
                      />
                      <span className="title text-white mt-3">
                        <FormattedMessage id="home.refer" />{" "}
                        <br className="d-md-block d-none" />
                        <FormattedMessage id="home.earn" />
                      </span>
                      <p className="desc text-white">
                        <FormattedMessage id="home.refer-desc" />
                      </p>
                      <div className="input-group mb-4">
                        <input
                          type="text"
                          ref={(code) => (this.code = code)}
                          className="form-control refferal-code-input"
                          value={
                            this.props.profile.referral_code
                              ? this.props.profile.referral_code
                              : ""
                          }
                          readOnly
                        />
                        <div className="input-group-append">
                          <span
                            className="input-group-text px-3"
                            onClick={this.onCopy}
                          >
                            <img
                              className="share-copy"
                              src={ic_copy}
                              alt="copy"
                              title="copy"
                            />
                          </span>
                          <span
                            className="input-group-text px-3 share-links"
                            style={{ cursor: "pointer", background: "#FF5F00" }}
                          >
                            <img
                              className="share-copy"
                              src={ic_share}
                              alt="share"
                              title="share"
                            />
                            <div className="share-links-dropdown">
                              <div className="d-flex justify-content-center align-items-center">
                                <a
                                  onClick={this.referred}
                                  title={`Share on facebook`}
                                  href={encodeURI(
                                    `https://www.facebook.com/sharer/sharer.php?u=${referral_link}`
                                  )}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <i
                                    className="fab fa-facebook-square"
                                    aria-hidden="true"
                                    style={{
                                      fontSize: "30px",
                                      color: "#4267B2",
                                    }}
                                  ></i>
                                </a>
                                <a
                                  onClick={this.referred}
                                  href={`https://web.whatsapp.com/send?text=A+brilliant+opportunity+to+WIN+BIG+CASH+REWARDS+by+just+doing+your+daily+shopping+through+the+WINDS+App.%0D%0A%0D%0ADo+your+regular+transactions%3A%0D%0A%E2%97%8F+Offline+and+Online+Shopping%0D%0A%E2%97%8F+Utility+Bill+Payments%0D%0A%E2%97%8F+Purchasing+Brand+Vouchers%0D%0A%5Bwith+upto+25%25+discount%5D%0D%0A%0D%0ALink%3A+${referral_link}%0D%0A%E2%96%BA+Earn+WINDS+Reward+Points+%5BWRP%5D+on+every+purchase+of+yours+%5BNote%3A+%E2%82%B9+1+spent+%3D+1+WRP+earned+on+every+transaction%5D%0D%0A%E2%96%BA+Collect+a+total+of+5000+WRP+to+enter+into+a+Monthly+Lucky+Draw+and+WIN+LAKHS+%26amp%3B+CRORES%2A.%0D%0A%E2%96%BA+Also+EARN+10%25+of+WINDS%E2%80%99+earnings+on+every+purchase+your+referral+makes+via+the+WINDS+Referral+Program.%0D%0A%0D%0APlease+enter+my+referral+code+${referral_code}+or+click+on+the+link+below%2C+download+the+WINDS+App+and+become+a+part+of+the+World%E2%80%99s+Largest+Happiness+Program+today%21%0D%0A%0D%0ALink%3A+${referral_link}%0D%0ABest+Regards%2C%0D%0A${name}`}
                                  data-action="share/whatsapp/share"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  title="Share on whatsapp"
                                >
                                  {" "}
                                  <i
                                    className="fab fa-whatsapp-square"
                                    aria-hidden="true"
                                    style={{
                                      fontSize: "30px",
                                      color: "#25D366",
                                    }}
                                  ></i>
                                </a>
                                <a
                                  onClick={this.referred}
                                  href={`https://twitter.com/intent/tweet?text=A+brilliant+opportunity+to+WIN+BIG+CASH+REWARDS+by+just+doing+your+daily+shopping+through+the+WINDS+App.%0D%0A%0D%0ADo+your+regular+transactions%3A%0D%0A%E2%97%8F+Offline+and+Online+Shopping%0D%0A%E2%97%8F+Utility+Bill+Payments%0D%0A%E2%97%8F+Purchasing+Brand+Vouchers%0D%0A%5Bwith+upto+25%25+discount%5D%0D%0A%0D%0ALink%3A+${referral_link}%0D%0A%E2%96%BA+Earn+WINDS+Reward+Points+%5BWRP%5D+on+every+purchase+of+yours+%5BNote%3A+%E2%82%B9+1+spent+%3D+1+WRP+earned+on+every+transaction%5D%0D%0A%E2%96%BA+Collect+a+total+of+5000+WRP+to+enter+into+a+Monthly+Lucky+Draw+and+WIN+LAKHS+%26amp%3B+CRORES%2A.%0D%0A%E2%96%BA+Also+EARN+10%25+of+WINDS%E2%80%99+earnings+on+every+purchase+your+referral+makes+via+the+WINDS+Referral+Program.%0D%0A%0D%0APlease+enter+my+referral+code+${referral_code}+or+click+on+the+link+below%2C+download+the+WINDS+App+and+become+a+part+of+the+World%E2%80%99s+Largest+Happiness+Program+today%21%0D%0A%0D%0ALink%3A+${referral_link}%0D%0ABest+Regards%2C%0D%0A${name}`}
                                  data-action="share/whatsapp/share"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  title="Share on Twitter"
                                >
                                  {" "}
                                  <i
                                    className="fab fa-twitter-square"
                                    aria-hidden="true"
                                    style={{
                                      fontSize: "30px",
                                      color: "#1DA1F2",
                                    }}
                                  ></i>
                                </a>
                                <a
                                  onClick={this.referred}
                                  href={encodeURI(
                                    `https://www.linkedin.com/sharing/share-offsite/?url=${referral_link}`
                                  )}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  title="Share on LinkedIn"
                                >
                                  <i
                                    className="fab fa-linkedin"
                                    aria-hidden="true"
                                    style={{
                                      fontSize: "30px",
                                      color: "#0072b1",
                                    }}
                                  ></i>
                                </a>
                              </div>
                            </div>
                          </span>
                        </div>
                      </div>
                      <Link to="/refer-and-earn">
                        <span className="font-button button-color1">
                          <FormattedMessage id="home.knowmore" />
                          <img
                            src={require("../../../assets/home/ic_arrow_white.png")}
                            alt="know more"
                            title="know more"
                          />
                        </span>
                      </Link>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                    <div className="d-flex flex-column">
                      <div className="what-is-wrp">
                        <img
                          className="wrp-img"
                          src={require("../../../assets/home/ic_coins.png")}
                          alt="wrp"
                          title="wrp"
                        />
                        <span className="title text-white">
                          <FormattedMessage id="home.what-is" />{" "}
                          <br className="d-md-block d-none" />
                          <FormattedMessage id="home.wrp" />?
                        </span>
                        <p className="desc text-white">
                          <FormattedMessage id="home.what-is-wrp-desc" />
                        </p>
                        <Link to="/winds-coins-details">
                          <span className="font-button button-color2">
                            <FormattedMessage id="home.knowmore" />
                            <img
                              src={require("../../../assets/home/ic_arrow_white.png")}
                              alt="know more"
                              title="know more"
                            />
                          </span>
                        </Link>
                      </div>
                      <div className="space"></div>
                      {/* <div className="wrp-coupons mt-md-4 mt-2">
                        <img className="coupons-img" src={require('../../../assets/home/ic_rewards_trophy.png')} alt="coupons" title="coupons" />
                        <span className="title text-white"><FormattedMessage id="home.wrp" /> & <br className="d-md-block d-none" /><FormattedMessage id="home.coupons" /></span>
                        <div className="d-flex align-items-end justify-content-between mt-4">
                          <div style={{ width: "80%" }} className="d-flex wrp-coup">
                            <div className="wrp">
                              <span className="count">{this.props?.wrp?.wrp?.confirmed_wrp || 0}</span>
                              <p className="desc text-white mb-0 mr-2"><FormattedMessage id="wrp.confirmed-wrp" /></p>
                            </div>
                            <div className="coupons">
                              <span className="count">{this.props?.wrp?.wrp?.monthly_draw_eligible_coupon || 0}</span>
                              <p className="desc text-white mb-0"><FormattedMessage id="coupon.current_months_coupon" /></p>
                            </div>
                          </div>
                          <Link to="/coupons">
                            <span className="font-button button-color3">
                              <img src={require('../../../assets/home/ic_arrow_white.png')} alt="know more" title="know more" />
                            </span>
                          </Link>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  };

  BillPayments = (props) => {
    let wrp;
    if (this.props.heading_text && this.props.heading_text.recharge) {
      wrp = this.props.heading_text.recharge.wrp;
    }
    return (
      <div className="home-page section-space home-billpay">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-9 d-flex">
              <h2 className="title">
                <FormattedMessage id="home.utility-bill-title" />
              </h2>
              <img
                className="billpay ml-3"
                src={bharatPay}
                alt="billpay"
                title="billpay"
              />
            </div>
            <div className="col-3 text-right">
              <Link to={{ pathname: "/bill-pay", state: { from: "/" } }}>
                <span className="title-thick d-flex justify-content-end mobile-hide">
                  <FormattedMessage
                    id="home.view-all"
                    defaultMessage="View All"
                    values={{ what: "react-intl" }}
                  />
                  <img
                    className="view-all"
                    src={viewAll}
                    alt="view all"
                    title="view-all"
                  />
                </span>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h3 className="title-thick font-slim">
                <FormattedMessage id="home.utility-bill-desc1" />
                 {wrp} <FormattedMessage id="home.utility-bill-desc2" />
              </h3>
            </div>
          </div>
          <div className="row d-flex justify-content-between mt-4">
            <div className="col-12">
              {this.props.billpay.length > 0 && (
                <BillPayCarousel services={this.props.billpay} />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  Insurance = (props) => {
    let wrp;
    if (this.props.heading_text && this.props.heading_text.insurance) {
      wrp = this.props.heading_text.insurance.wrp;
    }
    return (
      <div className="home-page section-space home-insurance">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-9">
              <h2 className="title">
                <span className="font-slim">
                  <FormattedMessage id="home.insurance-title1" />
                </span>{" "}
                <FormattedMessage id="home.insurance-title2" />
                <span className="font-slim">
                  <FormattedMessage id="home.insurance-title3" />
                </span>
              </h2>
            </div>
            <div className="col-3 text-right">
              <Link to={{ pathname: "/insurance", state: { from: "/" } }}>
                <span className="title-thick d-flex justify-content-end mobile-hide">
                  <FormattedMessage
                    id="home.view-all"
                    defaultMessage="View All"
                    values={{ what: "react-intl" }}
                  />
                  <img
                    className="view-all"
                    src={viewAll}
                    alt="view all"
                    title="view-all"
                  />
                </span>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h3 className="title-thick font-slim">
                <FormattedMessage id="home.insurance-desc1" /> {wrp}{" "}
                <FormattedMessage id="home.insurance-desc2" />
              </h3>
            </div>
          </div>
          <div className="row d-flex justify-content-between mt-4">
            {this.props?.insurance && this.props?.insurance.length > 1
              ? this.props.insurance.map((item, index) => {
                  return (
                    <div
                      className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 d-flex"
                      key={index}
                    >
                      <div
                        className="insurance-box d-flex flex-column align-items-start"
                        style={{
                          backgroundImage: `url(${this.state?.insuranceBG[index]?.bgImage})`,
                        }}
                      >
                        <img src={item.web_image} alt="wrp" title="wrp" />
                        <span
                          style={{
                            color: `${this.state?.insuranceBG[index]?.color}`,
                          }}
                          className="title mt-4 w-50 text-uppercase"
                        >
                          {item.title}
                        </span>
                        <p className="desc mt-2 text-black">
                          {this.state?.insuranceDescrption[index]?.desc}
                        </p>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a onClick={() => this.insuranceURL(item.url)}>
                          <span
                            style={{
                              background: `${this.state?.insuranceBG[index]?.buttonBG}`,
                            }}
                            className="font-button mt-2"
                          >
                            <FormattedMessage id="home.insure-now" />
                            <img
                              src={require("../../../assets/home/ic_arrow_white.png")}
                              alt="know more"
                              title="know more"
                            />
                          </span>
                        </a>
                      </div>
                    </div>
                  );
                })
              : this.props.insurance.map((item, index) => {
                  return (
                    <div
                      className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex"
                      key={index}
                    >
                      <div
                        className="insurance-box single-padding d-flex flex-row align-items-center justify-content-between mb-0"
                        style={{
                          backgroundImage: `url(${this.state?.insuranceBG[index]?.bgImage})`,
                        }}
                      >
                        <div>
                          <span
                            style={{
                              color: `${this.state?.insuranceBG[index]?.color}`,
                            }}
                            className="title text-uppercase"
                          >
                            {item.title}
                          </span>
                          <p className="desc mt-2 text-black">
                            <FormattedMessage id="home.health-insurance-desc" />
                          </p>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <a onClick={() => this.insuranceURL(item.url)}>
                            <span
                              style={{
                                background: `${this.state?.insuranceBG[index]?.buttonBG}`,
                              }}
                              className="font-button mt-2"
                            >
                              <FormattedMessage id="home.insure-now" />
                              <img
                                src={require("../../../assets/home/ic_arrow_white.png")}
                                alt="arrow"
                                title="arrow"
                              />
                            </span>
                          </a>
                        </div>
                        <img
                          className="ins-single"
                          src={item.web_image}
                          alt="insurance"
                        />
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
    );
  };

  Travel = (props) => {
    let percentage;
    if (this.props.heading_text && this.props.heading_text.travel) {
      percentage = this.props.heading_text.travel.percentage;
    }
    const { services, flight } = this.state;

    const hotelService = services.find((service) => service.name === "Hotel");
    const flightService = services.find((service) => service.name === "Flight");
    return (
      <div className="home-page section-space home-travel">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-9">
              <h2 className="title">
                Travel
                <span className="font-slim">
                  {" "}
                  - Adding a Joy to your Journey
                </span>
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h3 className="title-thick font-slim">
                Book your Flight Tickets at the best available rates through
                WINDS and WINDS Coins On every booking
              </h3>
            </div>
          </div>
          <div className="row d-flex justify-content-between mt-4">
            {flightService && (
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex">
                <div className="travel-box d-flex flex-row align-items-center justify-content-between">
                  <div>
                    <span className="title text-white">Flight Booking</span>
                    <p className="desc mt-2 text-white">
                      Let your take off be as smooth as your landing! Explore
                      the most economical fares while booking tickets on all
                      airline services through WINDS.{" "}
                    </p>
                    <Link to="/flights">
                      <span className="font-button button-color4 mt-2">
                        Book Now
                        <img
                          src={require("../../../assets/home/ic_arrow_white.png")}
                          alt="arrow"
                          title="arrow"
                        />
                      </span>
                    </Link>
                  </div>
                  <img
                    className="travel-img mb-4 mb-md-0"
                    src={require("../../../assets/home/ic_flight.png")}
                    alt="flights"
                    title="flights"
                  />
                </div>
              </div>
            )}
          </div>
          {hotelService && (
            <div className="row d-flex justify-content-between mt-4">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex">
                <div className="travel-box-hotel d-flex flex-row align-items-center justify-content-between">
                  <div>
                    <span className="title text-white">Hotel Booking</span>
                    <p className="desc mt-2 text-white">
                      Let your take off be as smooth as your landing! Explore
                      the most economical fares while booking tickets on all
                      airline services through WINDS.{" "}
                    </p>
                    <Link to="/hotels">
                      <span className="font-button button-color5 mt-2">
                        Book Now
                        <img
                          src={require("../../../assets/home/ic_arrow_white.png")}
                          alt="arrow"
                          title="arrow"
                        />
                      </span>
                    </Link>
                  </div>
                  <img
                    className="travel-img mb-4 mb-md-0"
                    src={require("../../../assets/home/ic_hotel.png")}
                    alt="hotel"
                    title="hotel"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  ShoppingSites = (props) => {
    let wrp;
    if (this.props?.heading_text && this.props?.heading_text?.shopping_site) {
      wrp = this.props?.heading_text?.shopping_site.wrp;
    }
    return (
      <div className="home-page section-space home-shopping-sites">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-9">
              <h2 className="title">
                <FormattedMessage id="home.shoppingsites-title1" />
                <span className="font-slim">
                  <FormattedMessage id="home.shoppingsites-title2" />
                </span>
              </h2>
            </div>
            <div className="col-3 text-right">
              <Link to={{ pathname: "/shopping-sites", state: { from: "/" } }}>
                <span className="title-thick d-flex justify-content-end mobile-hide">
                  <FormattedMessage
                    id="home.view-all"
                    defaultMessage="View All"
                    values={{ what: "react-intl" }}
                  />
                  <img
                    className="view-all"
                    src={viewAll}
                    alt="view all"
                    title="view all"
                  />
                </span>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h3 className="title-thick font-slim">
                <FormattedMessage id="home.shoppingsites-desc1" /> {wrp}{" "}
                <FormattedMessage id="home.shoppingsites-desc2" />
              </h3>
            </div>
          </div>
          <div className="row d-flex justify-content-between mt-4">
            <div className="col-12">
              <div className="shopping-box">
                {this.props.affiliate.length > 0 &&
                  this.props.affiliate.map((item, index) => {
                    return (
                      <Link
                        className="shopping-box-image d-flex justify-content-center"
                        to={`/shopping-sites/${item.id}`}
                        key={index}
                      >
                        <img
                          src={item.image_url}
                          alt={item.name}
                          title={item.name}
                        />
                      </Link>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  Featured_products = (props) => {
    const isOdd = this.props?.home?.featured_products?.count % 2 !== 0;
    const handleFeatureClick=(e,loginRequired,redirect_url,slug,isExternalRedirect)=>{
      if(loginRequired){
        if(!this.props.profile){
          e.preventDefault();
          this.openLoginDialog();
        }
        else{
          if(redirect_url){
            customerCommonClientX.post(`/v2/featured-products-url`, { slug })
            .then(response => {
              const data = response.data.data
              const  externalUrl= data.redirect_url 
              if (isExternalRedirect === 0) {
                this.props.history.push({
                  pathname: '/Featured_products',
                  state: { redirect_url: externalUrl }
                });
              }
                else{
                  window.open(externalUrl, '_blank', 'noopener,noreferrer');
                }
            })
            .catch(error => {
              console.error("Error fetching featured products URL:", error);
            });
          }
        }
      }
    
    }
    return (
      <div className="home-page home-shopping-sites section-space py-3">
        <div className="container">
          <div className="row align-items-center pt-2">
            <div className="col-12 align-items-center">
              <div className="d-flex align-items-center">
                <div
                  className="bg-danger rounded"
                  style={{ height: "30px", width: "15px" }}
                ></div>
                <span className="text-danger pl-2">Featured Products</span>
              </div>
              <h3 className="mt-2">New Arrival</h3>
            </div>
          </div>

          <div className="row d-flex justify-content-between mt-4 mx-0">
            {this.props?.home?.featured_products?.data?.slice(0,5).map((item, index) => {
         
              const colClass =  isOdd && index === 0 ? "col-12 mb-2 px-1" : "col-6 px-1 my-1";
              const imageClass = isOdd && index === 0 ? "featured-image" : "sub-feature-img";
              const normalizeUnicode = (str) => str.normalize();

              // Normalize the description before rendering
              const normalizedDescription = normalizeUnicode(item.description);
              return (
                
                <div className={colClass} key={index}>
                  <div  className="d-flex justify-content-center" onClick={(e)=>handleFeatureClick(e,item.login_required,item.redirect_url,item.slug,item.is_external_redirect)}>
                  <div className="p-2 w-100 position-relative" style={{ backgroundColor: "rgb(248,248,248)",}}>
                      <div className="position-relative">
                        <img src={item.image_url} alt={item.name} title={item.name} className={`w-100 ${imageClass} zoom-image`} style={{ maxHeight: "450px", objectFit: "cover" }} />
                      </div>
                      <div className="pl-2 pt-3 d-md-flex justify-content-center" style={{ backgroundColor: "rgb(248,248,248)" }} >
                        <span className="text-center mr-sm-3 d-flex justify-content-center align-items-center"><p><strong className="text-center featured-desc-2">{item.name}</strong></p></span>
                        <div dangerouslySetInnerHTML={{ __html: normalizedDescription }} className="text-center featured-desc d-flex align-items-center"  />
                      </div>
                    </div>
                    </div>
                  </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };
}
function mapStateToProps(state) {
  return {
    latitude: state.location.latitude,
    longitude: state.location.longitude,
    banner: state.home.banner,
    heading_text: state.home.heading_text,
    affiliate: state.home.affiliate,
    popular_affiliate: state.home.popular_affiliate,
    partner: state.home.partner,
    voucher: state.home.voucher,
    billpay: state.home.billpay,
    offers: state.home.offers,
    tags: state.home.tags,
    deals: state.home.deals,
    isLoading: state.home.isLoading,
    wrp: state.wrp,
    customer_order_commission: state.wrp.customer_order_commission,
    profile: state.global.profile,
    home: state.home,
    service_categories: state.home.service_categories,
    insurance: state.home.insurance,
    rewards: state.home.rewards,
    fun_game_zone: state.home.fun_game_zone,
    completeProfileCount: state.home.completeProfileCount,
    gtagEventsCount: state.home.gtagEventsCount,
    windsClientIp: state.global.windsClientIp,
    monetized_affiliate: state.home.monetized_affiliate,
    deal_of_the_day: state.home.deal_of_the_day,  
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onInit: (latitude, longitude, windsClientIp, profile) => {
      dispatch(setLoading(true));
      try {
        if (!windsClientIp) {
          fetch("https://api.ipify.org?format=json")
            .then((response) => response.json())
            .then((data) => {
              dispatch(getWindsClientIp(data.ip));
            })
            .catch((error) => {
              console.error("Error fetching IP:", error);
            });
        }
      } catch (e) {}
      customerCommonClientX
        .get(
          "/v2/dashboard?latitude=" +
            latitude +
            "&longitude=" +
            longitude +
            "&feature=1" +
            "&radius=5" +
            "&banner_tags=customer_web" +
            "&banner_platform=web" +
            "&banner_lang=" +
            currentlySelectedLanguage()
        )
        .then((response) => {
          var data = response.data.data;
          var wrpData = response.data.data?.wrp;
          let earningData = response.data.data?.customer_order_commission;
          let monetized = data.monetized_affiliate;
          dispatch(
            setHome(
              data.banner,
              data.heading_text,
              data.affiliate,
              data.popular_affiliate,
              data.partner.categories,
              data.voucher,
              // billPayServices,
              data.billpay.services,
              data.partner.offers,
              data.tags,
              data.customer_order_commission,
              data.wrp,
              data.insurance,
              data.service_categories,
              data.rewards,
              data.fun_game_zone,
              data.winds_kart,
              data.monetized_affiliate,
              data.deal_of_the_day,
              data.featured_products
            )
          );
          dispatch(setWrp(wrpData));
          dispatch(setEarnings(earningData));
          dispatch(setLoading(false));
          dispatch(setMonetizedAffiliate(true));
          if (data.monetized_affiliate && data.monetized_affiliate.length > 0) {
            window["jsonpCallback_111"] = (data) => {
              try {
                dispatch(setMonetizedAffiliate(data?.data || []));
              } catch (error) {
                console.error("Error handling JSONP response:", error);
              }
            };

            const url = `https://huc14.veve.com/qlapi?o=huc14&s=60243&u=windsapp.com&callback=${"jsonpCallback_111"}&ua=Mozilla/5.0 (compatible, MSIE 11, Windows NT 6.3; Trident/7.0; rv:11.0)itype=cs&f=json&n=20&i=1&is=155x155&ist=3&ua=windsapp&subid=${
              profile ? profile.id : "48841"
            }`;
            const script = document.createElement("script");
            script.src = url;
            script.async = true;

            script.onerror = () => {
              console.error("Failed to load the script.");
              if (window["jsonpCallback_111"]) {
                delete window["jsonpCallback_111"];
              }
            };
            document.body.appendChild(script);
          }
        });
    },

    addBookmark: (
      id,
      listTypeToUpdate,
      url,
      banner,
      affiliate,
      popular_affiliate,
      partner,
      voucher,
      billpay,
      offers,
      tags,
      customer_order_commission,
      wrp
    ) => {
      customerCommonClientX
        .post(url, {})
        .then((response) => {
          dispatch(
            setHomeAfterBookmarkToggle(
              id,
              listTypeToUpdate,
              banner,
              affiliate,
              popular_affiliate,
              partner,
              voucher,
              billpay,
              offers,
              tags,
              customer_order_commission,
              wrp
            )
          );
        })
        .catch((error) => {});
    },

    deleteBookmark: (
      id,
      listTypeToUpdate,
      url,
      banner,
      affiliate,
      popular_affiliate,
      partner,
      voucher,
      billpay,
      offers,
      tags,
      customer_order_commission,
      wrp
    ) => {
      customerCommonClientX
        .delete(url)
        .then((response) => {
          dispatch(
            setHomeAfterBookmarkToggle(
              id,
              listTypeToUpdate,
              banner,
              affiliate,
              popular_affiliate,
              partner,
              voucher,
              billpay,
              offers,
              tags,
              wrp,
              customer_order_commission
            )
          );
        })
        .catch((error) => {});
    },
    setCompleteProfileCount: () => {
      dispatch(setCompleteProfileCount(1));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
