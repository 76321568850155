import React from "react";
import WindsLoader from "./WindsLoader";

const Iframe = ({ source }) => {
  const [loading, setLoading] = React.useState(true);
  const hideLoader = () => {
    setLoading(false);
  };

  const src = source;
  return (
    <div className="emdeb-responsive">
      {loading && (
        <WindsLoader
          rows={2}
          columns={9}
          height={100}
          width={300}
          padding={1}
          speed={1}
        />
      )}
      <iframe title="windsIframe" src={src} className="info-box" onLoad={hideLoader}></iframe>
    </div>
  );
};

export default Iframe;
