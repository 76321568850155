export function fetchingOffers(){
    return {
        type: 'FETCHING_OFFERS'
    }
}

export function fetchingOfferCategories(){
    return {
        type: 'FETCHING_OFFER_CATEGORIES'
    }
}

export function fetchingNextPage(){
    return {
        type: 'FETCHING_NEXT_OFFER_PAGE'
    }
}

export function setOffers(offers,nextPage){
    return {
        type: 'SET_OFFERS',
        offers,
        nextPage
    }
}

export function onOffersFilterClick() {
    return {
        type: 'OFFER_FILTER_CLICK'
    }

}

export function resetOrderFilter() {
    return {
        type: 'RESET_ORDER_FILTER'
    }
}

export function addOffers(offers, nextPage){
    return {
        type: 'ADD_OFFERS',
        offers,
        nextPage
    }
}

export function setOfferCategories(categories){
    return {
        type: 'SET_OFFER_CATEGORIES',
        categories
    }
}

export function selectCategoryForOffers(id){
    return {
        type: 'SELECT_CATEGORY_FOR_OFFERS',
        id
    }
}

export function resetCategory(id){
    return {
        type: 'RESET_CATEGORY',
        id
    }
}

export function unselectCategoryForOffers(id){
    return {
        type: 'UNSELECT_CATEGORY_FOR_OFFERS',
        id
    }
}

export function setPartnerListAfterBookmarkToggle(id,offers){
    return {
        type:'SET_PARTNER_LIST_AFTER_BOOKMARK_TOGGLE',
        id,offers
    }
}