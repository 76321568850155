import Lottie from "lottie-react"
import { Link } from "react-router-dom"
import EarnReward from "../assets/orders/ic_trophy.json";
import EarnedReward from "../assets/orders/ic_won_cash.json";
import icRow from "../assets/orders/ic_arrow.png"


export const claimNow = (order) => {
  return (
    <Link to="/rewards">
      <div className="row mb-4 order-reward-earned position-relative">
        <div className="col-9 centered">
          <h3
            dangerouslySetInnerHTML={{
              __html: order?.reward_string || 'You have earned the reward on this transaction!',
            }}
          ></h3>
          <div className="d-flex align-items-center">
            <span
              dangerouslySetInnerHTML={{
                __html: order?.reward_type || 'CLAIM NOW',
              }}
              className="flex-icon">
            </span>
            <img style={{ marginLeft: "5px" }} src={icRow} alt="arrow" width="30px" />
          </div>
        </div>
        <div className="col-3">
          <Lottie animationData={EarnReward} loop={true} width="50" />
        </div>
      </div>
    </Link>
  )
}

export const claimed = (order) => {
  return (
    <Link to="/rewards">
      <div className="row mb-4 order-reward-earned">
        <div className="col-9 centered">
          <h3
            dangerouslySetInnerHTML={{
              __html: order?.reward_string || `You have won ${order?.reward_label} as Rewards for this transaction!`,
            }}
          ></h3>
        </div>
        <div className="col-3">
          <Lottie animationData={EarnedReward} loop={true} width="50" />
        </div>
      </div>
    </Link>
  )
}

// export const winCash = (order) => {
//   return (
//     <div className="row mb-3 mb-md-4 ">
//       <div className="col-md-6 offset-md-3">
//         <Link to="/rewards">
//           <div className="row mb-4 order-reward-cash">
//             <div className="col-9 centered">
//               <h3>Spend Rs. 100 or more on WINDS and win cash & WRP as Rewards</h3>
//             </div>
//             <div className="col-3">
//             </div>
//           </div>
//         </Link>
//       </div>
//     </div>
//   )
// }