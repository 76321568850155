import React, { Component } from "react";
import { Link, withRouter, generatePath } from "react-router-dom";
import billPayClientX from "../../../utils/api/billPayClientX";
// import billPayCreative from "../../../assets/bill-pay-creative.svg";
import serviceDownImg from "../../../assets/service-down.svg";
import Modal from "react-bootstrap/Modal";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import "react-toastify/dist/ReactToastify.css";
import { FormattedMessage, injectIntl } from "react-intl";
import aboutBlue from "../../../assets/about-blue.svg";
import ErrorModal from "../../../components/ErrorModal";
import PartialAmount from "../components/PartialAmount";
// import PastTransactions from "../components/PastTransaction";
import InlineLoader from "../../../components/InlineLoader";
import { setBillpayAPIVersion, setProvider, setRepeatData } from "../actions/order";
import { onViewedProduct } from "../../../utils/events";
import { currentlySelectedLanguage, isDate, readCookie, reverseDateFormat } from "../../../utils/helper";
import { billPayCart, billPay } from "../actions/order";
import Iframe from "../../../components/iFrame";
import InfoDialog from "../../../components/InfoDialog";
import FormattedMessageWithClass from "../../../components/FormattedMessageWithClass";
import { FirebaseContext } from "../../../utils/firebase";
import { connect } from "react-redux";
import bpAssuredHorizontal from "../../../assets/billpay/ic_bp_logo_horizontal.svg"
import bpAssured from "../../../assets/billpay/ic_bp_assured.svg"
import ic_rupee_symbol from "../../../assets/billpay/ic_rupee_symbol.svg";
import ic_recently_paid from "../../../assets/billpay/ic_recently_paid.svg";
import ic_all_results from "../../../assets/billpay/ic_all_results.png"
import ic_search_results from "../../../assets/billpay/ic_search_results.png"
import SearchImg from "../../../assets/billpay/search.png"
import ic_clear_search from "../../../assets/billpay/ic_clear_search.png"
// import billpay_default from "../../../assets/billpay/billpay-default.png"
import { toast } from "react-toastify";
import { ServiceData, ProviderData } from "../components/SectionContent";
import LoginDialog from "../../../components/LoginDialog";
import HelmetMetaData from "../../../components/HelmetMetaData";
import { billpayServicesMetaContent } from "../components/BillpayServicesMetaContent";
import { billpayProviderMetaContent } from "../components/BillpayProviderMetaContent";
import { getIsMob, getIsKamaie } from "../../../utils/helper";
import billPayIcon from '../../../assets/billpay/bharatPayIcon.svg'
import billPayLogo from '../../../assets/billpay/B Assured Logo_SVG.svg'
class BillPayForm extends Component {
  static contextType = FirebaseContext;

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      service: null,
      services: [],
      providers: [],
      provider: null,
      number: null,
      amount: null,
      account: null,
      authenticator: null,
      payBtnState: true,
      circles: [],
      circle: null,
      plans: [],
      isWrpBenefitModelOpen: false,
      isFetchBill: false,
      isErrorModalOpen: false,
      isPartialAmtModalOpen: false,
      numberValidation: "",
      ad1Validation: "",
      ad2Validation: "",
      isFetchBillSuccessful: false,
      fetchBillResponse: [],
      transactions: [],
      isLoading: false,
      isBilPayMaintenance: false,
      serviceDisable: false,
      plan_id: null,
      pageNo: 1,
      isLastPage: false,
      paramID: "",
      pageLoading: false,
      parameterKeys: [],
      selectedProvider: null,
      unchangedProviders: [],
      isLodingProviders: false,
      searchParams: "",
      isRecentTransactionModelOpen: false,
      isLastPaidTransactionsLoading: false,
      lastPaidTransactions: [],
      validateBillDataLoading: false,
      selectedPlan: null,
      isLoginDialogOpen: false,
      isMob:0,
      is_kamaie:0
    };

    this.handleShow = () => {
      if (this.state.circle)
        this.setState({ show: true });
      else
        toast.error("Please select the correct circle ...")
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };
  }

  openLoginDialog = () => {
    this.setState({ isLoginDialogOpen: true });
  };

  closeLoginDialog = () => {
    this.setState({ isLoginDialogOpen: false });
  };

  sendBillEventData = (productName) => {
    const payload = {
      category: "Utility Bill",
      productName: productName,
    };
    this.setState({ clevertapPayload: payload })
    // onViewedProduct(payload);
  };
  getProviders = (id) => {
    this.setState({ isLodingProviders: true })
    billPayClientX.get(`/v3/services/${id}/providers`).then((response) => {
      const providers = response.data.data.providers;
      this.setState({
        providers: providers,
        unchangedProviders: providers,
        isLodingProviders: false
      });
    });
  };
  

  repeatOrder = (order) => {
    // const payload = {
    //   category: "Utility Bill",
    //   productName: order.service_name,
    //   amount: order.amount,
    // };
    this.initiateOrder(`v2/repeat-order`, { order_id: order.id });
  };

  componentDidMount() {
    const queryParameters = new URLSearchParams(window.location.search);
    this.setState({ isMob: queryParameters.get("isMob") });
    this.setState({is_kamaie:queryParameters.get("is_kamaie")}) 
    setTimeout(() => {
      onViewedProduct(this.state.clevertapPayload);
    }, 1000);
    this.setState({ pageLoading: true })
    const {
      match: { params },
    } = this.props;

    let id = params.id
    const { bill_pay } = this.context;


    this.getServices(id);
    if (!bill_pay) {
      if (this.props.order.repeatData) {
        this.repeatOrder(this.props.order.repeatData);
      }
      //sending only one time repeat order data to further then resetting to null
      setTimeout(() => {
        this.props.repeat(null);
      }, 3000);
      this.getProviders(id);
      this.getCircles();
    } else {
      this.setState({
        isBilPayMaintenance: bill_pay,
      });
    }
    //unselect provider when user trigger browser back button
    window.addEventListener('popstate', (event) => {
      this.ChangeProvider()
    });
  }

  loadProviderData = () => {
    setTimeout(() => {
      if (this.props.match?.params?.id && this.props.match?.params?.provider && !this.state.isLodingProviders && !this.props.order.repeatData) {
        if (this.state.providers) {
          let selectedItem = this.filterProvidersBySlug(this.state.providers, this.props.match?.params?.provider)
          this.selectProvider(selectedItem[0])
        }
      }
    }, 300);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }


  handleScroll = (props) => {
    const newPageNo = this.state.pageNo + 1;
    if (newPageNo < 5)
      this.setState({ pageNo: newPageNo },
        () => {
          this.getPastTransactions(this.state.pageNo, this.state.paramID);
        }
      );
  };

  initiateOrder = (url, form) => {
    billPayClientX
      .post(url, form)
      .then((response) => {
        let data = response.data.data
        setTimeout(() => {
          this.setState({
            isLoading: false,
          });
          this.repeatTransaction(data)
        }, 300);
        this.props.history.push(`/bill-pay/${data?.service_slug}/${data?.provider_slug}`)
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
      });
  };

  getCircles = () => {
    billPayClientX.get(`v1/circles`).then((response) => {
      let data = response.data.data;

      this.setState({
        circles: data
      });
    });
  };

  filterService = (data, id) => {
    return data.filter((item) => {
      return id === item.slug || Number(id) === item.id
    });
  };

  filterProviders = (data, id) => {
    return data.filter((item) => {
      return Number(id) === Number(item.id);
    });
  };

  filterProvidersBySlug = (data, slug) => {
    return data.filter((item) => {
      return slug === item.slug;
    });
  };

  getServices = (slug) => {
    billPayClientX.get(`v1/services`).then((response) => {
      let data = response.data.data;
      const service = this.filterService(data, slug);

      service.length === 0 ? this.props.history.push("/bill-pay") :
        this.props.match?.params?.provider === undefined && this.props.history.push(`/bill-pay/${service[0]?.slug}`)

      this.setState(
        {
          pageLoading: false,
          service: service[0],
          services: data,
          paramID: service[0]?.id
        },
        () => {
          this.loadProviderData()
          if (this.props?.profile) {
            this.getPastTransactions(this.state.pageNo, this.state.service?.id);
            this.getLastPaidTransactions(this.state.service?.id);
            this.sendBillEventData(this.state.service?.name);
          }
        }
      );
    });
  };

  getPastTransactions = (pageNo, serviceId) => {
    billPayClientX
      .get(`/v1/orders/service/${serviceId}`, {
        params: {
          page: pageNo,
        },
      })
      .then((response) => {
        let isLast;
        isLast = response.data.links.next === null;
        this.setState((prevState) => ({
          ...prevState,
          transactions: prevState.transactions.concat(response.data.data),
          isLastPage: isLast,
        }));
      }).catch(error => {
      })
  };

  getLastPaidTransactions = (serviceId) => {
    this.setState({ isLastPaidTransactionsLoading: true })
    billPayClientX
      .get(`/v1/orders/service/${serviceId}`)
      .then((response) => {
        this.setState({ isLastPaidTransactionsLoading: false })
        this.setState({ lastPaidTransactions: response.data.data })
      }).catch(error => {
        this.setState({ isLastPaidTransactionsLoading: false })
      })
  };

  onSelectProvider = (id) => {
    const providorID = id;
    this.setState({ circle: null });

    const provider = this.state.providers?.filter((item) => {
      return Number(item.id) === Number(providorID);
    });
    if (provider[0]?.temp_disabled) {
      this.setState({
        serviceDisable: true,
        provider: null,
      });
    } else {
      this.setState({
        provider: provider[0],
        serviceDisable: false,
      }, () => {
        this.props.setBillpayAPIVersion(this.state.provider?.billpay_api_version)
        this.props.history.push(`/bill-pay/${this.state.service?.slug}/${this.state.provider?.slug}`)
      });
    }
  };

  onSelectCircle = (event) => {
    const circleID = event.target.value;
    this.setState({ circle: circleID });
    let providerID = "";
    if (this.state.provider) {
      providerID = this.state.provider?.id;
    }
    billPayClientX
      .get("v1/plans", {
        params: {
          circle_id: circleID,
          provider_id: providerID,
        },
      })
      .then((response) => {
        this.setState({
          plans: response.data.data,
        });
      });
  };

  onMobileRechargeTypeChange = (event) => {
    this.state.paramID === 1 &&
      this.setState({ paramID: 2 })
    this.state.paramID === 2 &&
      this.setState({ paramID: 1 })

    this.setState({ transactions: [], pageNo: 1, searchParams: "" })

    setTimeout(() => {
      this.getPastTransactions(this.state.pageNo, this.state.paramID);
      this.getLastPaidTransactions(this.state.paramID);
    }, 100);

    const value = event.target.value;
    this.getProviders(value);

    this.props.history.push({
      pathname: generatePath(this.props.match.path, { id: value }),
    });
  };

  onChangeNumber = (event, parameter) => {
    event.persist();

    // auto convert vehicle input field to uppercase
    let number = "";
    if (this.state.service?.name === "Fastag")
      number = event.target.value.toUpperCase();
    else
      number = event.target.value;

    this.setState(
      {
        number: number,
      },
      () => {
        if (parameter.rule) {
          const rejex = RegExp(parameter.rule);
          if (!rejex.test(number)) {
            this.setState({
              [`${parameter.key}Validation`]: `${parameter.value} is not valid`,
              payBtnState: true
            });
          } else {
            this.setState({
              [`${parameter.key}Validation`]: "",
              payBtnState: false
            });
          }
        }
        else if (parameter.value === "Mobile Number (+91)") {
          if (this.state.number?.length !== 10) {
            this.setState({
              [`${parameter.key}Validation`]: `${parameter.value} is not valid`,
              payBtnState: true
            });
          } else {
            this.setState({
              [`${parameter.key}Validation`]: "",
              payBtnState: false
            });
          }
        }
      }
    );
  };

  onChangeAdFields = (event, parameter) => {
    event.persist();
    const value = event.target.value;
    this.setState(
      {
        [`${parameter.key}`]: value,
      },
      () => {
        if (parameter.rule) {
          const rejex = RegExp(parameter.rule);
          if (!rejex.test(value)) {
            this.setState({
              [`${parameter.key}Validation`]: `${parameter.value} is not valid`,
              payBtnState: true
            });
          } else {
            this.setState({
              [`${parameter.key}Validation`]: "",
              payBtnState: false
            });
          }
        }
      }
    );
  };

  onAmountChange = (event) => {
    const amount = event.target.value;
    const isValid = this.state.numberValidation === '' && this.state.ad1Validation === '' && this.state.ad2Validation === '';
    this.setState({
      amount: amount,
      payBtnState: isValid ? false : true
    });
  };

  onChangeAccount = (event, parameter) => {
    const account = event.target.value;
    this.setState(
      {
        account: account,
      },
      () => {
        if (parameter.rule) {
          let rejex;
          rejex = RegExp(parameter.rule);
          if (!rejex.test(account)) {
            if (parameter.value !== "Date Of Birth") {
              this.setState({
                [`${parameter.key}Validation`]: `${parameter.value} is not valid`,
              });
            }
          } else {
            this.setState({
              [`${parameter.key}Validation`]: "",
            });
          }
        }
      }
    );
  };

  onChangeAuthenticator = (event, parameter) => {
    const authenticator = event.target.value;

    this.setState({
      authenticator: authenticator,
    },
      () => {
        if (parameter.rule) {
          const rejex = RegExp(parameter.rule);
          if (!rejex.test(authenticator)) {
            this.setState({
              [`${parameter.key}Validation`]: `${parameter.value} is not valid`,
              payBtnState: true
            });
          } else {
            this.setState({
              [`${parameter.key}Validation`]: "",
              payBtnState: false
            });
          }
        }
      }
    );
  };

  onSelectPlan = (planId, cost) => {
    this.setState({
      show: false,
      amount: cost,
      payBtnState: false,
      plan_id: planId,
    });
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.onFetchBill();
    }
  };

  InitInputParameter = (account) => {
    let input = [...new Set(this.state.parameterKeys)];
    let input_parameter = [input[0] ? this.state.number : null, input[1] ? account : null, input[2] ? this.state.authenticator : null]

    this.state?.provider?.parameters && this.state?.provider?.parameters.map((item, index) => {
      return (
        this.state[`ad${index}`] && (
          // form[`ad${index}`] = this.state[`ad${index}`],
          input_parameter.push(this.state[`ad${index}`])
        )
      )
    })

    input_parameter = input_parameter.filter(param => param != null);
    return input_parameter;
  }

  onFetchBill = () => {
    if (!this.props.profile)
      return this.openLoginDialog()

    let account = this.state.account;
    if (isDate(account) && this.state.provider?.billpay_api_version === "v2") {
      account = reverseDateFormat(account);
    }
    let input_parameter = this.InitInputParameter(account)

    let form = {
      number: this.state.number,
      provider_id: this.state.provider?.id,
      ad1: account || "",
      ad2: this.state.authenticator || "",
      input_parameter: input_parameter
    }

    this.setState(
      {
        isLoading: true,
      },
      () => {
        billPayClientX
          .post(`/${this.state?.provider?.billpay_api_version}/view-bill`, form)
          .then((response) => {
            this.setState({
              isFetchBillSuccessful: true,
              fetchBillResponse: response.data.data.bills,
              isLoading: false,
              amount: response.data.data.bills[0].billnetamount,
            });
          })
          .catch((error) => {
            this.setState({
              isLoading: false,
            });
          });
      }
    );
    this.props.setProvider(this.state.provider)
  }

  onInitOrder = () => {
    if (!this.props.profile)
      return this.openLoginDialog()

    const isFound = this.state.provider?.parameters.find(parameter => parameter.ishiddent);
    let account = this.state.account;
    if (isDate(account)) {
      account = reverseDateFormat(account);
    }
    if (isFound) {
      account = isFound['expected-response'];
    }

    const payload = {
      category: "Utility Bill",
      productName: this.state.service?.name,
      amount: this.state.amount,
      quanity: 1,
    };
    // onPurchaseInitiated(payload);

    this.setState(
      {
        isLoading: true,
      },
      () => {
        let input_parameter = this.InitInputParameter(account)

        const form = {
          provider_id: this.state.provider?.id,
          number: this.state.number,
          amount: this.state.amount,
          account: account,
          authenticator: this.state.authenticator,
          wallet_enabled: 0,
          payment_mode: null,
          provider_key1: this.state.service?.provider_label,
          provider_value1: this.state.provider?.name,
          provider_key2: this.state.provider?.parameters[0]?.value,
          provider_value2: this.state.number,
        };

        if (this.state.service?.id === 1) {
          form.circle_id = this.state.circle;
          form.plan_id = this.state.plan_id;
        }

        form['input_parameter'] = input_parameter

        this.props.billPayCart(form)
        this.props.billPay(payload)
        this.ValidateFetchBillData(this.state.provider?.id, this.state.amount)
      }
    );
    this.props.setProvider(this.state.provider)
  };

  ValidateFetchBillData = (provider_id, amount) => {
    this.setState({ validateBillDataLoading: true })
    billPayClientX
      .post(`/v3/cart-details`, {
        provider_id: provider_id,
        amount: amount
      })
      .then((response) => {
        this.setState({ validateBillDataLoading: false, isLoading: false })
        this.props.history.push(`/bill-pay/cart`)
      })
      .catch((error) => {
        this.setState({ validateBillDataLoading: false, isLoading: false })
      });
  }

  openWrpBenefitModel = () => {
    this.setState({ isWrpBenefitModelOpen: true });
  };
  closeWrpModelHandler = () => {
    this.setState({ isWrpBenefitModelOpen: false });
  };

  openRecentTransactionModelHandler = () => {
    this.setState({ isRecentTransactionModelOpen: true });
  };
  closeRecentTransactionModelHandler = () => {
    this.setState({ isRecentTransactionModelOpen: false });
  };

  onDismissErrorModal = () => {
    this.setState({
      isErrorModalOpen: false,
    });
  };

  onPartialAmtModalClose = () => {
    this.setState({
      isPartialAmtModalOpen: false,
    });
  };

  openPartialPayment = () => {
    this.setState({
      isPartialAmtModalOpen: true,
    });
  };

  ViewPlans = () => {
    return (
      <Modal size="lg" show={this.state.show} onHide={this.handleClose}>
        <Modal.Header
          closeButton
          className="border-0"
          onClick={this.handleHide}
        >
          <Modal.Title>
            <FormattedMessage
              id="billpay.choose-a-plan"
              defaultMessage="Choose a plan"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="nav--plans">
            <Tabs>
              {this.state.plans.map((item, index) => {
                return (
                  <Tab
                    eventKey={item.category}
                    className="nav-tabs--plan"
                    title={item.category}
                    key={`category${index}`}
                  >
                    {item.plans.map((item, index) => {
                      return (
                        <div className="card mt-3" key={`plans${index}`}>
                          <div className="card-header">
                            <h3>
                              <strong>{item.cost}</strong>
                            </h3>
                          </div>
                          <div className="card-body">
                            <p className="mb-0">
                              <FormattedMessage
                                id="billpay.talktime"
                                defaultMessage="Talktime"
                              />
                            </p>
                            <p>
                              <strong>{item.talk_time}</strong>
                            </p>
                            <p className="mb-0">
                              <FormattedMessage
                                id="billpay.validity"
                                defaultMessage="Validity"
                              />
                            </p>
                            <p>
                              <strong>{item.validity}</strong>
                            </p>
                            <p className="mb-0">
                              <FormattedMessage
                                id="billpay.plan-detail"
                                defaultMessage="Plan Detail"
                              />
                            </p>
                            <p>
                              <strong>{item.description}</strong>
                            </p>
                            <button
                              className="animated-btn w-auto px-4 py-2 h-auto"
                              onClick={() => {
                                this.onSelectPlan(item.id, item.cost);
                                this.setState({ selectedPlan: item })
                              }}
                              type="button"
                            >
                              <FormattedMessage
                                id="billpay.select-plan"
                                defaultMessage="Select plan"
                              />
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </Tab>
                );
              })}
            </Tabs>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            type="button"
            onClick={this.handleHide}
          >
            <FormattedMessage id="general.close" defaultMessage="Close" />
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  RecentTransactionsModel = (show, onHide) => {
    return (
      <React.Fragment>
        <Modal
          show={show}
          onHide={onHide}
          className="font-proxima-nova"
          size="md"
          scrollable
          centered
        >
          <Modal.Header closeButton className="border-0">
            <Modal.Title>
              <strong>Recent Transactions</strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-4"
            id="recent-transaction-modal"
          // onScrollCapture={() => this.handleScroll()}
          >
            {(this.state.transactions && this.state.transactions?.length > 0) ?
              (this.state.transactions?.map((item, index) => {
                return (
                  <div
                    className="row border-bottom mt-4 mb-4 pb-3"
                    key={`transaction${index}`}
                  >
                    <div className="col-md-1 col-2 pr-0">
                      <div className="billpay-paid-img-box" style={{ width: "30px", height: "30px", minWidth: "30px", maxWidth: "30px" }}>
                        {
                          item.provider_web_image_url ?
                            <img src={item?.provider_web_image_url} alt={item?.provider_name} title={item?.provider_name} width="70%" /> :
                            this.getFirstLetter(item?.provider_name)
                        }
                      </div>
                    </div>
                    <div className="col-md-9 col-10">
                      <div>
                        <p className="text-secondary mr-1">
                          <b>{item.provider_name}</b> <br />
                          <small>{this.state?.lastPaidTransactions[0]?.pay_bill_request?.params[0]?.label} - {this.state?.lastPaidTransactions[0]?.pay_bill_request?.params[0]?.value}</small> <br />
                          <small>{item.recent_transaction_message}</small>
                        </p>
                      </div>
                    </div>
                    <div className="col-md-2 col-12">
                      {
                        this.state.service?.id !== 1 ?
                          <button
                            type="button"
                            className="billpay-btn"
                            // onClick={() => this.repeatTransaction(item)}
                            onClick={() => this.initiateOrder(`v2/repeat-order`, { order_id: item.id })}
                          >
                            PAY BILL{" >"}
                          </button> : item.can_repeat_order ?
                            <button
                              type="button"
                              className="billpay-btn"
                              // onClick={() => this.repeatTransaction(item)}
                              onClick={() => this.initiateOrder(`v2/repeat-order`, { order_id: item.id })}
                            >
                              <FormattedMessage id="billpay.repeat" defaultMessage="Repeat" />{" >"}
                            </button> : item.can_retry_order ?
                              <button
                                type="button"
                                className="billpay-btn"
                                // onClick={() => this.repeatTransaction(item)}
                                onClick={() => this.initiateOrder(`v2/repeat-order`, { order_id: item.id })}

                              >
                                <FormattedMessage id="billpay.retry" defaultMessage="Retry" />{" >"}
                              </button> : null
                      }
                    </div>
                  </div>
                );
              })) :
              (
                <h5 className="text-center">No Transactions Found.</h5>
              )}
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  };

  WrpBenefitModel = (show, onHide) => {
    return (
      <React.Fragment>
        <Modal
          show={show}
          onHide={onHide}
          className="font-proxima-nova"
        >
          <Modal.Header closeButton className="border-0">
            <Modal.Title>
              <FormattedMessage
                id="general.wrp_benefits"
                defaultMessage="WINDS Coins Benefits"
              />
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="row">
              <div className="col-12 text-center">
                <p>
                  Earn WINDS Coins on every transaction and Redeem those WINDS Coins in Future transactions in WINDS App
                </p>
                {/* <div className="mt-2">
                  <p className="font-proxima-nova-bold">
                    <FormattedMessage
                      id="billPay.wrp-earning"
                      defaultMessage="WRP Earning"
                    />{" "}
                    :
                  </p>
                  <p>
                    <FormattedMessage
                      id="billPay.wrp-dummy1"
                      defaultMessage={"Rs 100 Spent =  " + (this.state.provider?.wrp_ratio * 100).toFixed(0) + " WRP"}
                    />{" "}
                  </p>
                </div> */}
                <hr className="w-100" />
                {/* <div className="mt-4">
                  <p className="font-proxima-nova-bold">
                    <FormattedMessage
                      id="billPay.example"
                      defaultMessage="Example"
                    />{" "}
                    :
                  </p>
                  <p>
                    <FormattedMessage
                      id="billPay.wrp-dummy1"
                      defaultMessage={"Payment Value: "}
                    />{" "}
                    <span style={{ color: "#4343ff" }} className="font-weight-bold">Rs 500</span>
                  </p>
                  <p>
                    <FormattedMessage
                      id="billPay.wrp-dummy1"
                      defaultMessage={"WRP Earned: "}
                    />{" "}
                    <span style={{ color: "#4343ff" }} className="font-weight-bold">{((this.state.provider?.wrp_ratio / 1) * 500).toFixed(0) + " WRP"}</span>
                  </p>
                </div> */}
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer className="border-0 justify-content-center">
            <Link to="/winds-coins-details">
              <button className="animated-btn px-5">
                <FormattedMessage
                  id="home.know-more"
                  defaultMessage="Know More"
                />
              </button>
            </Link>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  };

  onPartialAmountChange = (value) => { };

  repeatTransaction = (item) => {
    const providers = this.state.providers;
    const providerId = item.pay_bill_request?.provider_id;
    const params = item.pay_bill_request?.params;
    const circleID = item.pay_bill_request?.circle_id;

    this.setState({
      selectedProvider: item
    }, () => {
      this.props.setBillpayAPIVersion(this.state.selectedProvider?.billpay_api_version) //set API version
    })

    this.closeRecentTransactionModelHandler() //manually closing recent transaction modal after clicking repeat button

    // resetting plans after clicking repeat/retry buttons
    this.state.service?.id === 1 &&
      billPayClientX
        .get("v1/plans", {
          params: {
            circle_id: circleID,
            provider_id: providerId,
          },
        })
        .then((response) => {
          this.setState({
            plans: response.data.data,
          });
        });

    if (circleID && this.state.circles) {
      const circle = this.state.circles.filter((item) => item.id === circleID);

      circle[0]?.id &&
        this.setState({ selectedProvider: item })

      this.setState({
        circle: circle[0]?.id,
      });
    }
    this.setState({
      amount: item.amount,
      payBtnState: false
    });

    for (let i = 0, paramsLength = params?.length; i < paramsLength; i++) {
      const param = params[i];
      if (param.key === "number") {
        this.setState({
          number: param.value,
        });
      }
      if (param.key === "ad1") {
        this.setState({
          account: param.value,
        });
      }
      if (param.key === "ad2") {
        this.setState({
          authenticator: param.value,
        });
      }
      // else{
      //   this.setState({
      //     [`${param.key}`]: `${param.value}`
      //   });
      // }
    }

    const provider = this.filterProviders(providers, providerId);
    if (provider[0]?.temp_disabled) {
      this.setState({
        serviceDisable: true,
        provider: null,
      });
    } else {
      this.setState({
        provider: provider[0],
        serviceDisable: false,
      });
      this.props.history.push(`/bill-pay/${this.state.service?.slug}/${this.state.provider?.slug}`)
    }
    window.scrollTo(0, 0);
  };

  validatefetchAmount = () => {
    let billAmount = parseInt(this.state.fetchBillResponse[0]?.billAmount)

    if (this.state.provider?.amount_exactness === "Exact and above" && this.state.amount < billAmount) {
      toast.error(`Amount should be greater than or equal to Rs ${billAmount}`)
    }
    else if (this.state.provider?.amount_exactness === "Exact and below" && this.state.amount > billAmount) {
      toast.error(`Amount should be less than or equal to Rs ${billAmount}`)
    }
    else {
      this.onInitOrder()
    }
  }

  BillDetail = () => {
    return (
      <React.Fragment>
        <div className="">
          <h4 className="text-center">
            <strong>
              {" "}
              <FormattedMessage
                id="billPay.details"
                defaultMessage="Bill Details"
              />
            </strong>
          </h4>
          {this.state.fetchBillResponse.map((bill, index) => {
            return (
              <div className="" key={`fetchBill${index}`}>
                <div className="text-center mt-3">
                  <div className="billpay-providers-img-box mx-auto" style={{ maxWidth: "75px", height: "75px", minWidth: "75px" }}>
                    {
                      this.state.provider?.web_image_url ?
                        <img src={this.state.provider?.web_image_url} alt={this.state.provider?.name} title={this.state.provider?.name} width="80%" /> :
                        this.getFirstLetter(this.state.provider?.name)
                    }
                  </div>
                  <h5 className="text-center mt-2 font-weight-bold" style={{ color: "#5d5d5d" }}>
                    {this.state.provider ? this.state.provider?.name : ""}
                  </h5>

                  {this.state.provider &&
                    this.state.provider.parameters.map((parameter, index) => {
                      return (
                        parameter.key === "number" && (
                          <small>{parameter.value} : {this.state.number}</small>
                        )
                      );
                    })}
                </div>
                <React.Fragment key={`display${index}`}>
                  <div className="border mt-5 position-relative d-flex flex-column align-items-center p-4" style={{ borderRadius: "15px" }}>
                    <img src={billPayLogo} className="position-absolute mx-auto" alt="Assured" title="Assured" height="60px" style={{ left: 0, right: 0, top: "-30px" }} />
                    <h5 className="mt-4 mb-3" style={{ color: "#7D70FF" }}><strong>{bill.display.find(x => x.key === "Amount").key}</strong></h5>
                    <h4 className="d-flex align-items-center mb-0">
                      <img src={ic_rupee_symbol} alt="rupee" title="rupee" width="40px" className="mr-2" />
                      {
                        this.state.provider?.is_fetch_bill && (this.state.provider?.amount_exactness === null || this.state.provider?.amount_exactness === "Exact") ?
                          (
                            <strong>{bill.display.find(x => x.key === "Amount").value}</strong>
                          ) :
                          (
                            <input type="number"
                              value={this.state.amount}
                              className="form-control operator-select"
                              style={{ "border": "2px solid #e7e7e7", "width": "150px", fontWeight: 600, fontSize: "large" }}
                              onChange={(e) => { this.setState({ amount: e.target.value }) }} />
                          )
                      }
                    </h4>
                    {
                      bill.display.find(x => x.key === "Due Date").value !== "" &&
                      <>
                        <hr className="w-100"></hr>
                        <p className="m-0" style={{ color: "#FF0000" }}>{bill.display.find(x => x.key === "Due Date").key} - {bill.display.find(x => x.key === "Due Date").value}</p>
                      </>
                    }

                  </div>
                  {
                    bill?.additionalInfo && bill?.additionalInfo?.length > 0 &&
                    <div className="border mt-4 d-flex flex-column  p-4" style={{ borderRadius: "15px" }}>
                      <span className="h5 text-center mb-3 font-weight-bold">Additional Details</span>
                      {bill?.additionalInfo.map(item => {
                        return (
                          <div className="add-info d-flex flex-column flex-md-row justify-content-between py-3">
                            <p className="m-0">{item?.name}</p>
                            <p className="font-weight-bold m-0">{item?.value}</p>
                          </div>
                        )
                      })
                      }
                    </div>
                  }
                </React.Fragment>
              </div>
            );
          })}
          <div className="row">
            <div className="col-12">
              {this.state.validateBillDataLoading ? (
                <div className="mb-3 mt-3 d-flex justify-content-center">
                  <InlineLoader />
                </div>
              ) : (
                <button
                  type="button"
                  className="animated-btn mb-0 mt-4 git"
                  onClick={() => this.validatefetchAmount()}
                >
                  <FormattedMessage
                    id="billpay.proceed-to-pay"
                    defaultMessage="PROCEED TO PAY"
                  />
                </button>
              )}
            </div>
            {/* <div className="col-4">
            <button
              type="button"
              className="animated-btn mb-3 mt-5 git"
              onClick={() =>
                this.setState({
                  isFetchBillSuccessful: false,
                })
              }
            >
              <FormattedMessage id="general.back" defaultMessage="Back" />
            </button>
          </div> */}
          </div>
          {this.state.fetchBillResponse?.acceptPartPay && (
            <button
              type="button"
              className="btn btn-white btn-block mb-3 mt-3 git winds-link-color text-capitalize bill-detail-btn"
              onClick={this.openPartialPayment}
              style={{ letterSpacing: 0, fontSize: "1rem", textAlign: "left" }}
            >
              <img src={aboutBlue} alt="about" title="about" className="mr-2 mb-1" />
              <strong>
                {" "}
                <FormattedMessage
                  id="billPay.partial-payment"
                  defaultMessage="Make Partial Payment"
                />
              </strong>
            </button>
          )}
        </div>
      </React.Fragment>
    );
  };
  selectProvider = (selectedItem) => {
    this.setState({
      selectedProvider: selectedItem,
      number: "",
      account: "",
      amount: ""
    })
    this.onSelectProvider(selectedItem?.id)
  }

  ChangeProvider = () => {
    this.setState({
      selectedProvider: null,
      provider: null,
      searchParams: "",
      isLoading: false,
      plan_id: null
    })
    this.props.history.push(`/bill-pay/${this.state.service?.slug}`)
  }

  searchProvider = (event) => {
    let searchParams = event.target.value
    this.setState({ searchParams: searchParams })
    if (searchParams.length === 0) {
      this.setState({ providers: this.state.unchangedProviders })
    }
    else {
      let searchResults = this.state.unchangedProviders?.filter(item =>
        item.name.toLowerCase().substring(0, searchParams.length) === searchParams.toLowerCase()
      );
      this.setState({ providers: searchResults })
    }
  }

  clearSearch = () => {
    this.setState({ searchParams: "" })
    this.setState({ providers: this.state.unchangedProviders })
  }

  getFirstLetter = (name) => {
    return <span style={{ fontSize: "x-large", fontWeight: "600", color: "#6c757d" }}>{name?.substring(0, 1)}</span>
  }

  SelectedProvider = (props) => {
    return (
      <div className="d-flex align-items-center justify-content-between border-bottom py-3">
        <div className="d-flex align-items-center">
          <div className="billpay-providers-img-box">
            {this.state.provider?.web_image_url ?
              <img src={this.state.provider?.web_image_url} alt={this.state.provider?.name} title={this.state.provider?.name}  width="70%" /> :
              this.getFirstLetter(this.state.provider?.name)
            }
          </div>
          {this.state.provider ? this.state.provider?.name : ""}
        </div>
        <div className="billpay-btn ml-2 cursor-pointer" onClick={() => this.ChangeProvider()}>
          CHANGE {">"}
        </div>
      </div>
    )
  }

  SearchProviders = () => {
    return (
      <div className="position-relative mt-md-0 mt-2 mb-3">
        {
          this.state.searchParams === "" ?
            <img className="provider-search-img" src={SearchImg} alt="search" title="search" /> :
            <img className="provider-search-img cursor-pointer" width="16px" src={ic_clear_search} alt="clear" title="clear" onClick={() => this.clearSearch()} />
        }
        <input className="biller-search w-100 " type="text" placeholder="Search by biller" value={this.state.searchParams} onChange={(e) => this.searchProvider(e)} />
      </div>)
  }

  RecentlyPaid = (props) => {
    return (
      this.state?.isLastPaidTransactionsLoading ?
        (
          <div className="box shine"></div>
        ) :
        this.state?.lastPaidTransactions && this.state?.lastPaidTransactions.length > 0 ?
          (
            <>
              <div className="d-flex align-items-center mb-2 mt-4">
                <img className="mr-2" src={ic_recently_paid} alt="recently paid" title="recently paid" width="20px" />
                <span style={{ color: "#4354FF" }}>Recently Paid</span>
              </div>
              <div className="row mt-4">
                <div className="col-md-1 col-2 pr-0">
                  <div className="billpay-paid-img-box">
                    {
                      this.state?.lastPaidTransactions[0]?.provider_web_image_url ?
                        <img src={this.state?.lastPaidTransactions[0]?.provider_web_image_url} alt={this.state?.lastPaidTransactions[0]?.provider_name} title={this.state?.lastPaidTransactions[0]?.provider_name} width="70%" /> :
                        this.getFirstLetter(this.state?.lastPaidTransactions[0]?.provider_name)
                    }
                  </div>
                </div>
                <div className="col-md-9 col-10">
                  <div>
                    <p className="text-secondary mr-1">
                      <b style={{ display: "block", width: "90%" }}>{this.state?.lastPaidTransactions[0]?.provider_name}</b>
                      <small>{this.state?.lastPaidTransactions[0]?.pay_bill_request?.params[0]?.label} - {this.state?.lastPaidTransactions[0]?.pay_bill_request?.params[0]?.value}</small> <br />
                      <small>Last bill - {this.state?.lastPaidTransactions[0]?.recent_transaction_message}</small>
                    </p>
                  </div>
                </div>
                <div className="col-md-2 col-12">
                  {
                    this.state.service?.id !== 1 ?
                      <button
                        type="button"
                        className="billpay-btn"
                        // onClick={() => this.repeatTransaction(this.state?.lastPaidTransactions[0])}
                        onClick={() => this.initiateOrder(`v2/repeat-order`, { order_id: this.state?.lastPaidTransactions[0].id })}
                      >
                        PAY BILL{" >"}
                      </button> : this.state?.lastPaidTransactions[0]?.can_repeat_order ?
                        <button
                          type="button"
                          className="billpay-btn"
                          // onClick={() => this.repeatTransaction(this.state?.lastPaidTransactions[0])}
                          onClick={() => this.initiateOrder(`v2/repeat-order`, { order_id: this.state?.lastPaidTransactions[0].id })}
                        >
                          <FormattedMessage id="billpay.repeat" defaultMessage="Repeat" />{" >"}
                        </button> : this.state?.lastPaidTransactions[0]?.can_retry_order ?
                          <button
                            type="button"
                            className="billpay-btn"
                            // onClick={() => this.repeatTransaction(this.state?.lastPaidTransactions[0])}
                            onClick={() => this.initiateOrder(`v2/repeat-order`, { order_id: this.state?.lastPaidTransactions[0].id })}
                          >
                            <FormattedMessage id="billpay.retry" defaultMessage="Retry" />{" >"}
                          </button> : null
                  }
                </div>
              </div>
              <div className="border-bottom mb-4">
                <p
                  onClick={this.openRecentTransactionModelHandler}
                  className="mb-4 mt-2 mt-md-0 d-flex justify-content-end cursor-pointer" style={{ color: "#7D70FF", textDecoration: "underline" }}><small>View all Past Transactions</small></p>
                {this.state.isRecentTransactionModelOpen && (
                  this.RecentTransactionsModel(this.state.isRecentTransactionModelOpen, this.closeRecentTransactionModelHandler)
                )}
              </div>
            </>
          ) :
          (
            <></>
          )
    )
  }

  BillPayProviders = (props) => {
    return (
      <>
        <div className="mb-0">
          {
            this.state.searchParams.length === 0 ?
              <div className="d-flex align-items-center mb-2 mt-4">
                <img className="mr-2" src={ic_all_results} alt="All results" title="All results" width="20px" />
                <span style={{ color: "#4354FF" }}>All  {this.state.service?.id !== 1 && this.state.service?.name} {this.state.service?.id === 1 && "Mobile Network"} Providers</span>
              </div> :
              <div className="d-flex align-items-center mt-md-4 mt-4">
                <img className="mr-2" src={ic_search_results} alt="search results" title="search results" width="20px" />
                <span style={{ color: "#4354FF" }}>Search Results</span>
              </div>
          }
          <div className="provider-scroll-list">
            {this.state?.providers && this.state?.providers?.map((item, index) => {
              return (
                <div className="d-flex align-items-center border-bottom py-3 cursor-pointer" key={item.id} onClick={() => this.selectProvider(item)}>
                  <div className="billpay-providers-img-box">
                    {
                      item.web_image_url ?
                        <img src={item?.web_image_url} alt={item?.name} title={item?.name} width="75%" /> :
                        this.getFirstLetter(item?.name)
                    }
                  </div>
                  {item.name}
                </div>
              );
            })}
          </div>
        </div>
      </>
    )
  }

  SwitchMobileOptions = (props) => {
    const params = props.params;
    return (
      (Number(params.id) === 1 || Number(params.id) === 2) && (
        <React.Fragment>
          <div className="mb-3">
            <div className="form-check form-check-inline mr-5">
              <input
                className="form-check-input mr-3 cursor-pointer"
                type="radio"
                name="rechargeOptions"
                id="prepaid"
                value="1"
                onChange={this.onMobileRechargeTypeChange}
                checked={Number(params.id) === 1}
              />
              <label className="form-check-label cursor-pointer" htmlFor="prepaid">
                <FormattedMessage
                  id="billPay.prepaid"
                  defaultMessage="Prepaid"
                />
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input mr-3 cursor-pointer"
                type="radio"
                name="rechargeOptions"
                id="postpaid"
                value="2"
                onChange={this.onMobileRechargeTypeChange}
                checked={Number(params.id) === 2}
              />
              <label className="form-check-label cursor-pointer" htmlFor="postpaid">
                <FormattedMessage
                  id="billPay.postpaid"
                  defaultMessage="Postpaid"
                />
              </label>
            </div>
          </div>
        </React.Fragment>
      )
    )
  }

  BillForm = (props) => {
    const params = props.params;
    const isWrpBenefitModelOpen = props.isWrpBenefitModelOpen;
    return (
      <React.Fragment>
        <form onSubmit={(e) => e.preventDefault()} className="billpay-form">
          {null !== this.state.service && this.state.service?.id === 1 && (
            <div className="form-group mt-4">
              <label className="text-secondary">
                <strong>
                  <FormattedMessage
                    id="billpay.choose-your-circle"
                    defaultMessage="Choose your circle"
                  />
                </strong>
              </label>
              <select
                className="form-control operator-select"
                id="default-operator"
                onChange={this.onSelectCircle}
              >
                {!this.state.circle && (
                  <option value="-1">
                    {this.props.intl.formatMessage({
                      id: "billpay.choose-your-circle",
                      defaultMessage: "Choose your circle",
                    })}
                  </option>
                )}
                {this.state.circles.map((item, index) => {
                  return (
                    <option
                      selected={this.state.circle === item.id}
                      value={item.id}
                      key={"circle-" + index}
                    >
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
          {null !== this.state.provider &&
            this.state.provider?.parameters &&
            this.state.provider?.parameters.length > 0 &&
            this.state.provider?.parameters.map((parameter, index) => {
              let parameterKeys = this.state.parameterKeys || []
              parameterKeys.push(parameter.value)
              return (
                <div className="form-group mt-4" key={index}>
                  {
                    !parameter.ishiddent &&
                    <label className="text-secondary">
                      <strong>{parameter.value}</strong>
                    </label>
                  }
                  {parameter.key === "number" && (
                    <React.Fragment>
                      <input
                        type={parameter.value === "Mobile Number (+91)" ? "number" : "text"}
                        id="default-mobile"
                        className="form-control operator-select"
                        onChange={(event) =>
                          this.onChangeNumber(event, parameter)
                        }
                        name={parameter.key}
                        value={this.state.number || ""}
                        onKeyDown={this.handleKeyDown}
                      />
                      <strong className="error-text">
                        {this.state.numberValidation}
                      </strong>
                    </React.Fragment>
                  )}

                  {parameter.key === "ad1" && !parameter.ishiddent &&
                    (parameter.options.length > 0 ? (
                      <select
                        className="form-control operator-select"
                        onChange={(event) =>
                          this.onChangeAccount(event, parameter)
                        }
                      >
                        <option value="-1">
                          {this.props.intl.formatMessage({
                            id: "general.select",
                            defaultMessage: "Select",
                          })}
                        </option>
                        {parameter.options.map((option, index) => {
                          return (
                            <option value={option} key={"options-" + index}>
                              {option}
                            </option>
                          );
                        })}
                      </select>
                    ) : (
                      <React.Fragment>
                        <input
                          id="default-consumer"
                          type={Number(params.id) === 7 && this.state.provider?.billpay_api_version === "v2" ? "date" : "text"}
                          // type={Number(params.id) === 7 ? "text" : "date"}
                          className="form-control operator-select"
                          onChange={(event) =>
                            this.onChangeAccount(event, parameter)
                          }
                          name={parameter.key}
                          value={this.state.account || ""}
                          onKeyDown={this.handleKeyDown}
                        />

                        <strong className="error-text">
                          {this.state.ad1Validation}
                        </strong>
                      </React.Fragment>
                    ))}

                  {parameter.key === "ad2" && (
                    <React.Fragment>
                      <input
                        type="text"
                        className="form-control operator-select"
                        onChange={(event) => this.onChangeAuthenticator(event, parameter)}
                        name={parameter.key}
                        value={this.state.authenticator || ""}
                        onKeyDown={this.handleKeyDown}
                      />
                      <strong className="error-text">
                        {this.state.ad2Validation}
                      </strong>
                    </React.Fragment>
                  )}

                  {!parameter.ishiddent && parameter.key !== "ad1" && parameter.key !== "ad2" && parameter.key !== "number" && (
                    <React.Fragment>
                      <input
                        type="text"
                        className="form-control operator-select"
                        onChange={(event) =>
                          this.onChangeAdFields(event, parameter)
                        }
                        name={parameter.key}
                        value={this.state[`${parameter.key}`] || ""}
                        onKeyDown={this.handleKeyDown}
                      />
                      <strong className="error-text">
                        {this.state[`${parameter.key}Validation`]}
                      </strong>
                    </React.Fragment>
                  )}

                </div>
              );
            })}
          {null !== this.state.number &&
            this.state.provider &&
            !this.state.provider?.is_fetch_bill && (
              <div className="form-group mt-4">
                <label className="text-secondary font-weight-bold">
                  <FormattedMessage
                    id="general.amount"
                    defaultMessage="Amount"
                  />
                </label>
                {1 === this.state.service?.id ? (
                  <div className="input-group mb-3">
                    <input
                      id="default-account"
                      type="number"
                      className="form-control operator-select"
                      value={this.state.amount || ""}
                      onChange={this.onAmountChange}
                    />
                    <div className="input-group-append">
                      <span
                        className="input-group-text"
                        onClick={this.handleShow}
                        style={{ cursor: "pointer" }}
                      >
                        {
                          this.state.plan_id === null && !this.state.amount ?
                            <FormattedMessage
                              id="billpay.view-plans"
                              defaultMessage="View Plans"
                            /> :
                            <span>Change Plan</span>
                        }
                      </span>
                      {this.ViewPlans()}
                    </div>
                  </div>
                ) : (
                  <input
                    type="number"
                    id="default-amount"
                    className="form-control operator-select"
                    value={this.state.amount || ""}
                    onChange={this.onAmountChange}
                  />
                )}
              </div>
            )}
          {this.state.service?.id === 1 &&
            <div>
              {this.state.plan_id &&
                <p style={{ color: "#6161f9" }}>{this.state.selectedPlan?.description || ""}</p>
              }
              <p><small>Please select correct CIRCLE and AMOUNT or valid PLAN to ensure successful recharge. </small></p>
            </div>
          }
          <div className="row mt-3 align-items-center">
            <div className="col-12 d-flex justify-content-center">
              {this.state.isLoading ? (
                <div className="mb-3">
                  <InlineLoader />
                </div>
              ) : this.state.provider &&
                this.state.provider &&
                this.state.provider?.is_fetch_bill ? (
                <button
                  type="button"
                  className="animated-btn mb-3 mt-3 git w-100"
                  onClick={this.onFetchBill}
                  disabled={!this.state.number}
                >
                  <FormattedMessage
                    id="billpay.general.continue"
                    defaultMessage="CONTINUE"
                  />
                </button>
              ) : this.state.service?.name === "Mobile" ? (
                <button
                  type="button"
                  className="animated-btn mb-3 mt-3 git w-100"
                  onClick={this.onInitOrder}
                  disabled={this.state.payBtnState}
                >
                  <FormattedMessage
                    id="billpay.proceed-to-pay"
                    defaultMessage="PROCEED TO PAY"
                  />
                </button>
              ) :
                (
                  <button
                    type="button"
                    className="animated-btn mb-3 mt-3 git w-100"
                    onClick={this.onInitOrder}
                    disabled={this.state.payBtnState}
                  >
                    <FormattedMessage
                      id="billpay.proceed-to-pay"
                      defaultMessage="PROCEED TO PAY"
                    />
                  </button>
                )}
            </div>
            <div className="col-12 d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-outline"
                style={{
                  color: "#087CD8",
                  marginTop: "-0.5rem",
                }}
                onClick={this.openWrpBenefitModel}
              >
                <img src={aboutBlue} alt="wrp" title="wrp" className="mr-1" />{" "}
                <FormattedMessage
                  id="general.wrp_benefits"
                  defaultMessage="WINDS Coins Benefits"
                />
              </button>
            </div>
            {this.state.isWrpBenefitModelOpen && (
              this.WrpBenefitModel(isWrpBenefitModelOpen, this.closeWrpModelHandler)
            )}
          </div>
        </form>
      </React.Fragment>
    );
  };

  BillPayTerms = () => {
    return (
      <div className="border-top py-4 mt-3">
        <div className="row ">
          <div className="col-3">
            <img src={billPayIcon} alt="BillPay Assured" title="BillPay Assured" className="bp-assured-horizontal" style={{ top: "0px" }} />
          </div>
          <div className="col-9">
            <p className="m-0">Paying bills through WINDS allows us to fetch your current and future bills to make the payments on time.</p>
          </div>
        </div>
      </div>
    )
  }

  onDismissInfo = () => {
    this.setState({
      serviceDisable: false,
    });
  };


  Shimmer = (props) => {
    return (
      <div className="container py-3 py-md-5">
        <div className="row d-flex justify-content-center">
          <div className="col-12 col-md-6">
            <div className="shine box-billpay-provider"></div>
            <div className="box shine box-voucher-detail-logo"></div>
            <div className="shine box-billpay-provider"></div>
            <div className="shine box-billpay-provider"></div>
            <div className="shine box-billpay-provider"></div>
            <div className="shine box-billpay-provider"></div>
            <div className="shine box-billpay-provider"></div>
            <div className="shine box-billpay-provider"></div>
            <div className="shine box-billpay-provider"></div>
            <div className="shine box-billpay-provider"></div>
            <div className="shine box-billpay-provider"></div>
          </div>
        </div>
      </div>
    );
  };

  BillpayServicesMetaData = () => {
    let currentAppUrl = process.env.REACT_APP_URL + window.location.pathname;
    let currentService = this.props.match?.params?.id

    return (
      <HelmetMetaData
        title={billpayServicesMetaContent[currentService]?.title}
        description={billpayServicesMetaContent[currentService]?.description}
        keywords={billpayServicesMetaContent[currentService]?.keywords}
        voiceSchema={<script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org/",
              "@type": "WebPage",
              "name": this.state.service?.name,
              "speakable":
              {
                "@type": "SpeakableSpecification",
                "xpath":
                  [
                    "/html/head/title",
                    "/html/head/meta[@name='description']/@content"
                  ]
              },
              "url": currentAppUrl
            }
          )}
        </script>
        }
        breadcrumbSchema={<script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org/",
              "@type": "BreadcrumbList",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://windsapp.com/"
              }, {
                "@type": "ListItem",
                "position": 2,
                "name": "Recharge & Pay Bills",
                "item": "https://windsapp.com/bill-pay"
              }, {
                "@type": "ListItem",
                "position": 3,
                "name": this.state.service?.name,
                "item": currentAppUrl
              }]
            }
          )}
        </script>}
      ></HelmetMetaData>
    )
  }

  BillpayProviderMetaData = () => {
    let currentAppUrl = process.env.REACT_APP_URL + window.location.pathname;
    let currentProvider = this.props.match?.params?.provider;
    let currentService = this.props.match?.params?.id

    return (
      <HelmetMetaData
        title={billpayProviderMetaContent[currentProvider]?.title}
        description={billpayProviderMetaContent[currentProvider]?.description}
        keywords={billpayProviderMetaContent[currentProvider]?.keywords}
        voiceSchema={<script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org/",
              "@type": "WebPage",
              "name": this.state.provider?.name,
              "speakable":
              {
                "@type": "SpeakableSpecification",
                "xpath":
                  [
                    "/html/head/title",
                    "/html/head/meta[@name='description']/@content"
                  ]
              },
              "url": currentAppUrl
            }
          )}
        </script>
        }
        breadcrumbSchema={<script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org/",
              "@type": "BreadcrumbList",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://windsapp.com/"
              }, {
                "@type": "ListItem",
                "position": 2,
                "name": "Recharge & Pay Bills",
                "item": "https://windsapp.com/bill-pay"
              }, {
                "@type": "ListItem",
                "position": 3,
                "name": this.state.service?.name,
                "item": `https://windsapp.com/bill-pay/${currentService}`
              },
              {
                "@type": "ListItem",
                "position": 4,
                "name": this.state.provider?.name,
                "item": currentAppUrl
              }]
            }
          )}
        </script>}
      ></HelmetMetaData>
    )
  }


  render() {
    
    const {
      match: { params },
    } = this.props;
    const currentProvider = this.props.match?.params?.provider
    const { isWrpBenefitModelOpen } = this.state;
    const isMob=getIsMob()
    const is_kamaie=getIsKamaie();
    if (this.state.pageLoading)
      return (<this.Shimmer />)
    else
      return (
        null != this.state.service && (
          <div className="bill-pay-page" style={{ backgroundColor: "#F2F6FA" }}>
            {this.state.serviceDisable && (
              <InfoDialog
                message={
                  <>
                    <FormattedMessageWithClass
                      className="mt-3 font-weight-bold"
                      id="rewards.soryy"
                      defaultMessage="Oops! Sorry we are unable to process your request at the moment. Please try again after some time."
                    />
                  </>
                }
                
                buttonTxt={
                  <>
                    <FormattedMessage id="profile.ok" defaultMessage="OK" />
                  </>
                }
                url={serviceDownImg}
                onDismiss={this.onDismissInfo}
              />
            )}
            {currentProvider && billpayProviderMetaContent[currentProvider] ?
              <this.BillpayProviderMetaData /> :
              <this.BillpayServicesMetaData />
            }
            <div className="container container-fluid--bill-pay-icon">
              <div className="row pt-3 mb-3 align-items-center">
                {(isMob!=1 && is_kamaie !=1)&&(
                    <div className="col-10">
                    <small>
                      <Link to="/">
                        <FormattedMessage
                          id="breadcrumbs.home"
                          defaultMessage="Home"
                        />
                      </Link>
                    </small>{" "}
                    /{" "}
                    <small>
                      <Link to="/bill-pay">
                        <FormattedMessage
                          id="home.recharge-pay-bills"
                          defaultMessage="Recharge & Pay Bills"
                        />
                      </Link>
                    </small>{" "}
                    /{" "}
                    <small>
                      <strong>{this.state.service?.name}</strong>
                    </small>
                  </div>
                )}
                {(this.state.isMob ==="1" || this.state.is_kamaie==="1")&&(
                  <div className="col-10"></div>
                )}
              
                <div className="col-2 d-flex justify-content-end">
                  <img src={billPayIcon} alt="Billpay Assured" title="Billpay Assured" className="bp-assured-horizontal" style={{ top: "0px" }} />
                </div>
              </div>

              <div className="row">
                {this.state.isErrorModalOpen && (
                  <ErrorModal
                    message={
                      <>
                        <FormattedMessage
                          id="billPay.no-bill"
                          defaultMessage="No bill is due"
                        />
                      </>
                    }
                    buttonTxt={
                      <>
                        <FormattedMessage id="profile.ok" defaultMessage="OK" />
                      </>
                    }
                    onDismiss={this.onDismissErrorModal}
                  />
                )}
                {this.state.isPartialAmtModalOpen && (
                  <PartialAmount
                    onPartialAmtModalClose={this.onPartialAmtModalClose}
                    onPartialAmountChange={(value) =>
                      this.onPartialAmountChange(value)
                    }
                  />
                )}
              </div>
            </div>

            {(this.context.bill_pay || (!this.state.isLodingProviders && (this.state.unchangedProviders?.length === 0 || this.state?.unchangedProviders === undefined))) ? (
              <Iframe
                source={`${process.env.REACT_APP_BASE_URL_BILLPAY_MAINTENANCE
                  }/${currentlySelectedLanguage()}`}
              ></Iframe>
            ) : (
              <div
                className="container">
                <div className="row">
                  <div className="col-12 col-md-12 mx-auto">
                    <div className="card card--bill-pay-form">
                      <div className="card-body">
                        <div className="row font-proxima-nova mt-2 mt-md-4 mb-2 mb-md-4 d-flex justify-content-center">
                          <div className="col-12 col-md-6 border-md p-2 p-md-4" style={{ borderRadius: "15px" }}>
                            {this.state.isFetchBillSuccessful ? (
                              <this.BillDetail />
                            ) : (
                              <>
                                {this.state.selectedProvider === null || !this.state.provider ?
                                  (
                                    <>
                                      {/* <this.SwitchMobileOptions params={params} /> */}
                                      <this.SearchProviders />
                                      {this.state.searchParams.length === 0 &&
                                        <this.RecentlyPaid />
                                      }
                                      <this.BillPayProviders />
                                    </>
                                  ) :
                                  <>
                                    <this.SelectedProvider />
                                    <this.BillForm
                                      params={params}
                                      isWrpBenefitModelOpen={isWrpBenefitModelOpen}
                                    />
                                    <this.BillPayTerms />
                                    <this.RecentlyPaid />
                                  </>
                                }
                              </>
                            )}
                          </div>
                          <div className="col-12 pt-3 pt-md-4">
                            <hr className="w-100" />
                            {this.state.selectedProvider === null || !this.state.provider ?
                              <>
                                <div
                                  className="py-3 py-md-4 px-0 px-md-5"
                                  dangerouslySetInnerHTML={{
                                    __html: ServiceData[this.state?.service?.slug],
                                    
                                    
                                  }}
                                ></div>

                              </> : <>
                                <div
                                  className="py-3 py-md-4 px-0 px-md-5"
                                  dangerouslySetInnerHTML={{
                                    __html: ProviderData[this.state?.provider?.slug],

                                  }}
                                ></div>
                              </>
                            }
                          </div>
                          {this.state.isLoginDialogOpen && (
                            <LoginDialog
                              isLoginDialogOpen={this.state.isLoginDialogOpen}
                              closeLoginDialog={this.closeLoginDialog}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )
      )
  }
}

const mapStateToProps = (state) => {
  return {
    order: state.order,
    profile: state.global.profile,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setBillpayAPIVersion: (billpayAPIVersion) => {
      dispatch(setBillpayAPIVersion(billpayAPIVersion));
    },
    billPayCart: (billPayForm) => {
      dispatch(billPayCart(billPayForm));
    },
    billPay: (billPayPayload) => {
      dispatch(billPay(billPayPayload));
    },
    repeat: (repeatData) => {
      dispatch(setRepeatData(repeatData));
    },
    setProvider: (provider) => {
      dispatch(setProvider(provider));
    },
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(BillPayForm))
);
