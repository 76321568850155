import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import InlineLoader from "./InlineLoader";
import voucherClientX from '../utils/api/voucherClientX';
const SubVouchers = ({ onClose, parentId }) => {
  const [vouchers, setVouchersList] = useState([]);
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    voucherClientX
      .get(`/v3/vouchers`, {
        params: {
          parent_id: parentId,
        },
      })
      .then((response) => {
        setLoading(false);
        setVouchersList(response.data.data);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Modal size="lg" show onHide={onClose}>
      <Modal.Header closeButton className="border-0"></Modal.Header>
      <Modal.Body className="voucher-modal">
        {isLoading ? (
          <InlineLoader />
        ) : (
          vouchers.map((item) => {
            return (
              <React.Fragment>
                <Link to={`/voucher/${item.id}`}>
                  <div className="sub-voucher-card-wrapper mt-4">
                    <div className="sub-voucher-card">
                      <div className="sub-voucher-logo-wrapper">
                        <img src={item.image_url} alt="Dineout Logo." title="Dineout Logo." />
                      </div>
                      <div className="sub-voucher-details">
                        <h5>{item.name}</h5>
                        <small>{item.delivery_type}</small>
                      </div>
                    </div>
                  </div>
                </Link>
              </React.Fragment>
            );
          })
        )}
      </Modal.Body>
      <Modal.Footer className="border-0 pb-5"></Modal.Footer>
    </Modal>
  );
};

export default SubVouchers;
