import axios from 'axios';
// import { ErrorToast } from '../components/ErrorToast';
// import CombineErrorMessages from '../components/CombineErrorMessages';
import { currentlySelectedLanguage } from '../helper';
import { getUserAccessToken, handleUnAuthentication } from "../authorization";

const gatewayClientX = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true
});


//Request Interceptor
gatewayClientX.interceptors.request.use(function (config) {
  const token = getUserAccessToken()
  if(token) config.headers["Authorization"] = `Bearer ${token}`;
  config.headers['Accept-Language'] = currentlySelectedLanguage();

  // Do something before request is sent
  return config;

}, function (error) {

  // Do something with request error
  return Promise.reject(error);

});

//Response Interceptor
gatewayClientX.interceptors.response.use(function (response) {
  // Do something with response data
  return response;

},
  function (error) {
    handleUnAuthentication(error.response.status)
    try {
      switch (error.response.status) {
        case '401':
          error('User session expired. Please login again.');
          break;
        case '400':
          error('Invalid request. Login & retry.');
          break;
        default:
          error('Something went wrong. Login & retry.');
          break;
      }
    }
    catch (error) {
      throw error.response;
    }
  }
);

export default gatewayClientX;