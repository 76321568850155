import React, { Component } from "react";
import profileClientX from "../../../utils/api/profileClientX";
import { Link } from "react-router-dom";
import RefferalInfoImage from '../../../assets/refferal-info.gif'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FormattedMessage } from "react-intl";
import { onViewedPage, onReferred } from "../../../utils/events";
import { connect } from "react-redux";
import { currentlySelectedLanguage } from "../../../utils/helper";
import twitterImage from "../../../assets/ic_x.svg"
const url = process.env.REACT_APP_REFERRAL_SCHEME + '/' + currentlySelectedLanguage();
class Referral extends Component {

  constructor(props) {
    super(props);

    this.state = {
      points: null,
      referrer_wrp: null,
      whatsAppUrl: "",
      facebookUrl: "",
      twitterUrl: "",
      linkedinUrl: "",
      referral_description_info: null,
      load: false
    };
  }

  componentDidMount() {
    const payload = {
      page_name: "Referral Page",
    };

    onViewedPage(payload);
    this.getReferralData()
  }

  getReferralData = () => {
    this.setState({ load: true })
    profileClientX
      .get("v1/profile")
      .then((response) => {
        const { referral_code, referral_link, name, referral_description_info } = response.data.data;
        this.setState(
          {
            load: false,
            referral_description_info: referral_description_info,
            whatsAppUrl: `https://web.whatsapp.com/send?text=A+brilliant+opportunity+to+WIN+BIG+CASH+REWARDS+by+just+doing+your+daily+shopping+through+the+WINDS+App.%0D%0A%0D%0ADo+your+regular+transactions%3A%0D%0A%E2%97%8F+Offline+and+Online+Shopping%0D%0A%E2%97%8F+Utility+Bill+Payments%0D%0A%E2%97%8F+Purchasing+Brand+Vouchers%0D%0A%5Bwith+upto+25%25+discount%5D%0D%0A%0D%0ALink%3A+${referral_link}%0D%0A%E2%96%BA+Earn+WINDS+%Coins%5D+on+every+purchase+of+yours+%5B+Also+EARN+10%25+of+WINDS%E2%80%99+earnings+on+every+purchase+your+referral+makes+via+the+WINDS+Referral+Program.%0D%0A%0D%0APlease+enter+my+referral+code+${referral_code}+or+click+on+the+link+below%2C+download+the+WINDS+App+and+become+a+part+of+the+World%E2%80%99s+Largest+Happiness+Program+today%21%0D%0A%0D%0ALink%3A+${referral_link}%0D%0ABest+Regards%2C%0D%0A${name}`,
            facebookUrl: encodeURI(`https://www.facebook.com/sharer/sharer.php?u=${referral_link}`),
            twitterUrl: `https://twitter.com/intent/tweet?text=A+brilliant+opportunity+to+WIN+BIG+CASH+REWARDS+by+just+doing+your+daily+shopping+through+the+WINDS+App.%0D%0A%0D%0ADo+your+regular+transactions%3A%0D%0A%E2%97%8F+Offline+and+Online+Shopping%0D%0A%E2%97%8F+Utility+Bill+Payments%0D%0A%E2%97%8F+Purchasing+Brand+Vouchers%0D%0A%5Bwith+upto+25%25+discount%5D%0D%0A%0D%0ALink%3A+${referral_link}%0D%0A%E2%96%BA+Earn+WINDS+Coins%5D+on+every+purchase+of+yours+%5B.%0D%0A%E2%96%BA+Also+EARN+10%25+of+WINDS%E2%80%99+earnings+on+every+purchase+your+referral+makes+via+the+WINDS+Referral+Program.%0D%0A%0D%0APlease+enter+my+referral+code+${referral_code}+or+click+on+the+link+below%2C+download+the+WINDS+App+and+become+a+part+of+the+World%E2%80%99s+Largest+Happiness+Program+today%21%0D%0A%0D%0ALink%3A+${referral_link}%0D%0ABest+Regards%2C%0D%0A${name}`,
            linkedinUrl: encodeURI(`https://www.linkedin.com/sharing/share-offsite/?url=${referral_link}`),
          }
        );
      })
      .catch((error) => {
      });
  }

  referred = () => {
    onReferred();
  }

  Shimmer = (props) => {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12 col-md-12">
            <div className="box shine" style={{ height: "300px" }}></div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  onCopy = (e) => {
    this.code.select();
    document.execCommand("copy");

    toast.success(
      <FormattedMessage
        id="general.code-copied"
        defaultMessage="Code copied successfully."
      />,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      }
    );

    e.target.focus();
  };

  render() {
    return (
      <div className="container">
        <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
          <div className="col-12">
            <small>
              <Link to="/">
                <FormattedMessage id="breadcrumbs.home" defaultMessage="Home" />
              </Link>{" "}
              /{" "}
              <strong>
                <FormattedMessage
                  id="navbar.refer-and-earn"
                  defaultMessage="Refer & Earn"
                />
              </strong>
            </small>
          </div>
        </div>
        <div className="row mb-3 mb-md-4">
          <div className="col-12 col-md-6">
            <img
              src={RefferalInfoImage}
              alt="RefferalInfoImage"
              title="RefferalInfoImage"
              className="d-block mx-auto w-100"
            />
          </div>
          <div className="col-12 col-md-6 mt-3 mt-md-0">
            <div className="card">
              {
                this.state.load ?
                  <this.Shimmer /> :
                  <div className="card-body">
                    <h2 className="font-proxima-nova-bold mb-3"
                      dangerouslySetInnerHTML={{
                        __html: this.state.referral_description_info?.header,
                      }}
                    >
                    </h2>

                    <p className="mb-3"
                      dangerouslySetInnerHTML={{
                        __html: this.state.referral_description_info?.body.replace(/\n/g, "<br />"),
                      }}
                    >
                    </p>
                    <p className="pb-2">
                      <small className="f-12 text-muted">
                        Please read
                        {/* <Link to={} className="termsandCond pl-1" target="_blank"> */}
                        <a className="termsandCond pl-1" href={`${url}`} target="_blank" rel="noopener noreferrer">
                          <FormattedMessage
                            id="general.terms-condition"
                            defaultMessage=" Please review Terms &amp; Conditions"
                          />
                        </a>
                        {/* </Link> */}
                      </small>
                    </p>

                    {this.props.profile && (
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          ref={(code) => (this.code = code)}
                          className="form-control"
                          value={this.props.profile?.referral_code}
                          readOnly
                        />
                        <div className="input-group-append">
                          <span
                            className="input-group-text"
                            style={{ cursor: "pointer" }}
                            onClick={this.onCopy}
                          >
                            <FormattedMessage
                              id="general.copy"
                              defaultMessage="Copy"
                            />
                          </span>
                        </div>
                      </div>
                    )}

                    {this.props.profile && (
                      <div className="d-flex justify-content-center align-items-center mt-3">
                        <a
                          onClick={this.referred}
                          title={`Share on facebook`}
                          href={this.state.facebookUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i
                            className="fab fa-facebook-square"
                            aria-hidden="true"
                            style={{
                              fontSize: "40px",
                              marginRight: ".5rem",
                              color: "#4267B2",
                            }}
                          ></i>
                        </a>

                        <a
                          onClick={this.referred}
                          href={this.state.whatsAppUrl}
                          data-action="share/whatsapp/share"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Share on whatsapp"
                        >
                          {" "}
                          <i
                            className="fab fa-whatsapp-square"
                            aria-hidden="true"
                            style={{
                              fontSize: "40px",
                              color: "#25D366",
                              marginRight: ".5rem",
                            }}
                          ></i>
                        </a>

                        <a
                          onClick={this.referred}
                          href={this.state.twitterUrl}
                          data-action="share/whatsapp/share"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Share on twitter"
                        >
                          {" "}
                          <img alt="twitter" title="twitter" src={twitterImage} style={{ width: "35px", marginRight: ".5rem" }} />
                        </a>
                        <a
                          onClick={this.referred}
                          href={this.state.linkedinUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Share on LinkedIn"
                        >
                          <i
                            className="fab fa-linkedin"
                            aria-hidden="true"
                            style={{
                              fontSize: "40px",
                              marginRight: ".5rem",
                              color: "#0072b1",
                            }}
                          ></i>
                        </a>
                      </div>
                    )}
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.global.profile
  };
}


export default connect(mapStateToProps, null)(Referral);
