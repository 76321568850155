import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import lightningIcon from "../../../assets/voucher/ic_instant_delivery.png";
import { connect } from "react-redux";
import { setVoucher, setDenomination, setQuantity, setGiftDetails } from "../actions";
import ContentLoader from "react-content-loader";
import { FormattedMessage, injectIntl } from "react-intl";
import bookmarkActive from "../../../assets/bookmark-active.svg";
import bookmarkInactive from "../../../assets/bookmark-inactive.svg";
import VoucherWrpModal from "../../../components/VoucherWrpModal";
import classNames from "classnames";
import update from "immutability-helper";
import { currentlySelectedLanguage } from "../../../utils/helper";
import { onViewedProduct } from "../../../utils/events";
import Iframe from "../../../components/iFrame";
import { FirebaseContext } from "../../../utils/firebase";
import Modal from "react-bootstrap/Modal";
import ic_save from "../../../assets/voucher/ic_save.svg";
import aboutWhite from "../../../assets/voucher/ic_info_circle_white.png"
import gift from "../../../assets/voucher/ic_gift.png"
import shape from "../../../assets/voucher/CombinedShape.png"
import { Accordion, Card } from "react-bootstrap";
import arrow from "../../../assets/voucher/ic_add.png"
import close from "../../../assets/voucher/ic_close.png"
import saveicon from "../../../assets/ic_save.svg"
import wrpTag from "../../../assets/voucher/wrp-tag.png"
import redeemableTag from "../../../assets/voucher/redeemable-tag.png"
import wrpCoin from "../../../assets/voucher/ic_wrp_coin.svg"
import Slider from "react-slick";
import LoginDialog from "../../../components/LoginDialog";
import HelmetMetaData from "../../../components/HelmetMetaData";
import voucherClientX from "../../../utils/api/voucherClientX";
import { readCookie } from "../../../utils/helper";
import { getIsMob, getIsKamaie } from "../../../utils/helper";
class VoucherDetail extends Component {
  static contextType = FirebaseContext;

  constructor(props) {
    super(props);

    this.state = {
      // isMob:0,
      // is_kamaie:0,
      voucher: null,
      amount: 0,
      isLoading: true,
      isWrpBenefitModelOpen: false,
      isVoucerMaintenance: false,
      show: false,
      giftOptionEnabled: false,
      name: {
        value: null,
        isValid: false,
        error: "",
        isPristine: true,
      },
      email: {
        value: null,
        isValid: false,
        error: "",
        isPristine: true,
      },
      phone_number: {
        value: null,
        isValid: false,
        error: "",
        isPristine: true,
      },
      message: {
        value: null,
        isValid: false,
        error: "",
        isPristine: true,
      },
      selectedDenomination: null,
      popularDenominations: [],
      isLoginDialogOpen: false,
    };
    
  }

  handleShow = () => {
    this.setState({ show: true });
  };

  handleHide = () => {
    this.setState({ show: false });
  };

  openLoginDialog = () => {
    this.setState({ isLoginDialogOpen: true });
  };
  closeLoginDialog = () => {
    this.setState({ isLoginDialogOpen: false });
  };

  componentDidMount() {
    const queryParameters = new URLSearchParams(window.location.search);
    this.setState({ isMob: queryParameters.get("isMob") });
    this.setState({ is_kamaie: queryParameters.get("is_kamaie") });
    this.props.setDenomination(undefined);
    this.props.setQuantity(1); //reset voucher quantity onload
    const { voucher_detail } = this.context;
    if (!voucher_detail) {
      const {
        match: { params },
      } = this.props;
      this.setState(
        {
          isLoading: true,
        },
        () => {
          voucherClientX.get(`v1/vouchers/${params.id}`)
          .then((response) => {
            let data = response.data.data;

            //redirect voucher ID to slug
            let voucherID = parseInt(this.props?.match?.params?.id)
            if (voucherID === data?.id && data?.slug !== null) {
              this.props.history.push(`/voucher/${data.slug}`)
            }
            
            const payload = {
              Category: "Voucher",
              "Product Name": data.name,
            };
            onViewedProduct(payload);

            this.setState({
              voucher: data,
              key: "details",
              isLoading: false,
              popularDenominations: data?.popular_denominations || []
            });

            if (data.voucher_type === "fixed") {
              if (data.denominations.length === 1)
                this.onSelectDenomination(data?.denominations[0]?.id)
              else
                this.onSelectDenomination(data?.denominations[data.denominations.length - 2]?.id)
            } else {
              if (this.state.popularDenominations.length === 0)
                this.onEnterAmount(parseInt(data?.denominations[0].min_value))
              else
                this.onEnterAmount(parseInt(this.state.popularDenominations[this.state.popularDenominations.length - 2]))
            }

            this.props.setVoucher(data);
          })
          .catch(error=>{
            this.props.history.push("/vouchers")
          })
        }
      );
    } else {
      this.setState({
        isVoucerMaintenance: voucher_detail,
      });
    }
  }
  controlGiftOption = (event) => {
    this.setState({ giftOptionEnabled: !this.state.giftOptionEnabled })
  }

  openWrpBenefitModel = () => {
    this.setState({ isWrpBenefitModelOpen: true });
  };

  closeWrpModelHandler = () => {
    this.setState({ isWrpBenefitModelOpen: false });
  };

  toggleBookmark = (event, id, isBookmarked) => {
    event.preventDefault();
    event.stopPropagation();
    this.onToggleBookmark(id, isBookmarked, `/v1/vouchers/${id}/bookmark`);
  };

  onToggleBookmark = (id, isBookmarked, url) =>
    !isBookmarked
      ? this.addBookmark(id, url, this.props.vouchers)
      : this.deleteBookmark(id, url, this.props.vouchers);

  addBookmark = (id, url, vouchers) => {
    voucherClientX
      .post(url, {})
      .then((response) => {
        this.setState((prevState) => ({
          voucher: {
            ...prevState.voucher,
            bookmarked: !prevState.voucher.bookmarked,
          },
        }));
      })
      .catch((error) => { });
  };

  deleteBookmark = (id, url, vouchers) => {
    voucherClientX
      .delete(url)
      .then((response) => {
        this.setState((prevState) => ({
          voucher: {
            ...prevState.voucher,
            bookmarked: !prevState.voucher.bookmarked,
          },
        }));
      })
      .catch((error) => { });
  };

  onSelectDenomination = (id) => {
    let denomination = this.state.voucher.denominations.filter(
      (item, index) => {
        return parseInt(item.id) === parseInt(id);
      }
    );
    if (denomination && denomination[0] && denomination[0].value) {
      this.setState({ amount: denomination[0].value });
      this.setState({ selectedDenomination: denomination[0] })
      // const $select = document.querySelector('#denomination');
      // $select.value = denomination[0].id
    }
    else
      this.setState({ amount: 0 });
    this.props.setDenomination(denomination[0]);
  };

  onEnterAmount = (value) => {
    this.setState({ amount: value, selectedDenomination: value });

    this.props.setDenomination({
      id: this.state.voucher.denominations[0].id,
      value: value,
    });
  };

  onIncreaseQuantity = () => {
    this.props.setQuantity(this.props.quantity + 1);
  };

  onDecreaseQuantity = () => {
    if (this.props.quantity > 1) {
      this.props.setQuantity(this.props.quantity - 1);
    }
  };

  onProceed = () => {
    if (this.props.profile) {
      if ("range" === this.state.voucher.voucher_type) {
        if (
          this.state.amount >
          parseInt(this.state.voucher.denominations[0]?.max_value) ||
          this.state.amount <
          parseInt(this.state.voucher.denominations[0]?.min_value)
        ) {
          toast.error(this.getInstruction(), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          return;
        }
      }
      let giftDetails = {
        gifting_name: this.state.giftOptionEnabled ? this.state.name.value : null,
        gifting_mobile: this.state.giftOptionEnabled ? this.state.phone_number.value : null,
        gifting_email: this.state.giftOptionEnabled ? this.state.email.value : null,
        gifting_message: this.state.giftOptionEnabled ? this.state.message.value : null
      }
      this.props.setGiftDetails(giftDetails);
      this.props.history.push("/vouchers/cart");
    }
    else {
      this.openLoginDialog()
    }
  };

  VoucherDetailMetaData = () => {
    let currentAppUrl = process.env.REACT_APP_URL + window.location.pathname;
    return (
      <HelmetMetaData
        title={`${this.state.voucher?.name} Gift Cards & Brand Vouchers Offers Online | WINDS App`}
        description={`Buy ${this.state.voucher?.name} gift cards & brand vouchers online with WINDS Coins benefits on the WINDS App. Start your shopping to redeem your <brand> e-Gift voucher to avail the amazing discounts today.`}
        keywords={`${this.state.voucher?.name} gift cards, ${this.state.voucher?.name} gift vouchers, ${this.state.voucher?.name} vouchers, ${this.state.voucher?.name} online discounts, ${this.state.voucher?.name} online deals, ${this.state.voucher?.name} e-gift cards, ${this.state.voucher?.name} e-vouchers, ${this.state.voucher?.name} gift online, buy ${this.state.voucher?.name} gift card, ${this.state.voucher?.name} gift voucher, ${this.state.voucher?.name} winds app vouchers, ${this.state.voucher?.name} winds app gift cards, ${this.state.voucher?.name} brand vouchers`}
        voiceSchema={<script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org/",
              "@type": "WebPage",
              "name": `${this.state.voucher?.name} Gift Cards & Brand Vouchers Offers Online | WINDS App`,
              "speakable":
              {
                "@type": "SpeakableSpecification",
                "xpath":
                  [
                    "/html/head/title",
                    "/html/head/meta[@name='description']/@content"
                  ]
              },
              "url": currentAppUrl
            }
          )}
        </script>
        }
        breadcrumbSchema={<script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org/",
              "@type": "BreadcrumbList",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://windsapp.com/"
              }, {
                "@type": "ListItem",
                "position": 2,
                "name": "Vouchers",
                "item": "https://windsapp.com/vouchers"
              }, {
                "@type": "ListItem",
                "position": 3,
                "name": `${this.state.voucher?.name}`,
                "item": currentAppUrl
              }]
            }
          )}
        </script>}
      ></HelmetMetaData>
    )
  }

  render() {
    const isMob=getIsMob();
    const is_kamaie=getIsKamaie()
    return (
      this.context.voucher_detail ?
        <div className="container font-proxima-nova">
          <this.BreadCrubs />
          <Iframe
            source={`${process.env.REACT_APP_BASE_URL_VOUCHER_MAINTENANCE
              }/${currentlySelectedLanguage()}`}
          ></Iframe>
        </div>
        : this.state.isLoading ? (
          <this.Shimmer />
        ) : (
          <>
            <this.VoucherDetailMetaData />
            <this.Voucher />
          </>
        )
    )
  }

  Shimmer = (props) => {
    return (
      <div className="container py-3 py-md-5">
        <div className="row">
          <div className="col-md-6">
            <div className="box shine box-voucher-detail-logo"></div>
          </div>
          <div className="col-md-6 pt-1 pt-md-0">
            <ContentLoader
              height={120}
              width={400}
              speed={2}
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            >
              <rect x="0" y="9" rx="0" ry="0" width="120" height="6" />
              <rect x="0" y="21" rx="0" ry="0" width="320" height="12" />
              <rect x="0" y="39" rx="0" ry="0" width="92" height="6" />
              <rect x="0" y="60" rx="0" ry="0" width="506" height="6" />
              <rect x="0" y="75" rx="0" ry="0" width="136" height="6" />
              <rect x="0" y="90" rx="0" ry="0" width="153" height="6" />
            </ContentLoader>
            <ContentLoader
              height={80}
              width={400}
              speed={2}
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            >
              <rect x="0" y="0" rx="2" ry="2" width="99" height="6" />
              <rect x="0" y="16" rx="2" ry="2" width="300" height="24" />
            </ContentLoader>
          </div>
        </div>
        <div className="row mt-3 d-flex justify-content-center">
          <div className="col-md-4">
            <div className="box shine w-100" style={{ height: "38px" }}></div>
          </div>
          <div className="col-md-4">
            <div className="box shine w-100" style={{ height: "38px" }}></div>
          </div>
          <div className="col-md-10">
            <div className="box shine box-voucher-detail-logo"></div>
          </div>
        </div>
      </div>
    );
  };

   BreadCrubs = () => {
    return (
      <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
        <div className="col-12">
          <div className="row align-items-center">
            <div className="col-12">
              <small>
                <Link to="/">
              
                  <FormattedMessage
                    id="breadcrumbs.home"
                    defaultMessage="Home"
                  />
                </Link>
              </small>{" "}
              /{" "}
              <small>
                <Link to="/vouchers">
                  <FormattedMessage
                    id="home.vouchers"
                    defaultMessage="Vouchers"
                  />
                </Link>
              </small>{" "}
              {this.state.voucher && (
                <React.Fragment>
                  /{" "}
                  <small>
                    <strong>{this.state.voucher.name}</strong>
                  </small>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  BreadCrubsSpace=()=>{
    return(
      <>
      <div className="p-4">

      </div>
      </>
    )
  }

  Voucher = (props) => {
    const isMob=getIsMob();
    const is_kamaie=getIsKamaie()
    return (
     
      <div className="container font-proxima-nova">
        {/* <this.BreadCrubs /> */}
        {(isMob != 1 && is_kamaie != 1) && (
          this.BreadCrubs())
        }
        {  (isMob ==1 || is_kamaie ==1) && (
            this.BreadCrubsSpace()
          )
        }
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-md-6 mb-3">
                {this.VoucherImage()}
              </div>
              <div className="col-md-6">
                {this.Controls()}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-2 mt-md-3 mb-md-0 mb-3">
          <div className="col-12">
            <hr />
          </div>
        </div>
        <div className="row mb-md-4 mb-5">
          <div className="col-12 col-md-9 mt-2 mt-md-4 m-auto">
            {this.Details()}
          </div>
        </div>
      </div>
    );
  };

  getInstruction = () => {
    const language = currentlySelectedLanguage();
    switch (language) {
      case "en":
        return `Pick an amount between ${this.state.voucher.denominations[0].min_value} and ${this.state.voucher.denominations[0].max_value}`;
      case "hi":
        return `${this.state.voucher.denominations[0].min_value} से ${this.state.voucher.denominations[0].max_value} के बीच की राशि चुनें`;
      case "gu":
        return `${this.state.voucher.denominations[0].min_value} અને ${this.state.voucher.denominations[0].max_value} ની વચ્ચે રકમ પસંદ કરો`;
      case "bn":
        return `${this.state.voucher.denominations[0].min_value} এবং ${this.state.voucher.denominations[0].max_value} এর মধ্যে একটি পরিমাণ বাছাই করুন`;
      case "kn":
        return `${this.state.voucher.denominations[0].min_value} ಮತ್ತು ${this.state.voucher.denominations[0].max_value} ರ ನಡುವೆ ಮೊತ್ತವನ್ನು ಆರಿಸಿ`;
      case "ml":
        return `${this.state.voucher.denominations[0].min_value} നും ${this.state.voucher.denominations[0].max_value} നും ഇടയിൽ ഒരു തുക തിരഞ്ഞെടുക്കുക`;
      case "mr":
        return `পিচক ামোুনত বেতৱৈন ${this.state.voucher.denominations[0].min_value}  ানদ ${this.state.voucher.denominations[0].max_value}`;
      case "or":
        return `${this.state.voucher.denominations[0].min_value} ରୁ ${this.state.voucher.denominations[0].max_value} ମଧ୍ୟରେ ପରିମାଣ ବାଛନ୍ତୁ `;
      case "ta":
        return `${this.state.voucher.denominations[0].min_value} முதல் ${this.state.voucher.denominations[0].max_value} வரை ஒரு தொகையைத் தேர்ந்தெடுக்கவும்`;
      case "te":
        return `${this.state.voucher.denominations[0].min_value} మరియు ${this.state.voucher.denominations[0].max_value} మధ్య మొత్తాన్ని ఎంచుకోండి`;

      default:
        return `Pick an amount between ${this.state.voucher.denominations[0].min_value} and ${this.state.voucher.denominations[0].max_value}`;
    }
  };

  VoucherImage = (props) => {
    const { bookmarked, id, image_url, delivery_type, name, turn_around_time_in_hours, wrp_ratio, redeemable, validity } = this.state.voucher;
    const { isWrpBenefitModelOpen } = this.state;
    let title = `WINDS Coins`

    return (
      <>
        <div
          className="card voucher-card"
          style={{
            boxShadow: "0 4px 30px 0 rgba(0,0,0,0.08)",
            borderRadius: "8px",
            border: "none",
          }}
        >
          <div
            className="card-body"
            style={{ backgroundColor: "#EFF6FF", borderRadius: "8px 8px 0 0" }}
          >
            <div className="row align-items-center">
              {
                <img
                  src={bookmarked ? bookmarkActive : bookmarkInactive}
                  className="bookmark"
                  alt="bookmark"
                  title="bookmark"
                  onClick={(event) => this.toggleBookmark(event, id, bookmarked)}
                />
              }
              <div className="col-4 col-md-3">
                <div
                  className="voucher-detail-logo-wrapper d-flex align-items-center"
                >
                  <img src={image_url} alt={name} title={name} className="voucher-logo" style={{ width: "80%", margin: "auto" }}/>
                  <img src={shape} alt="shape" title="shape" className="border-shape" />
                </div>
              </div>
              <div className="col-8 col-md-9">
                <div className="row">
                  <div className="col-12">
                    <h1 className="voucher-title" style={{ color: "#fff" }}>
                      <strong>{name}</strong>
                    </h1>
                    <div className="mb-3">
                      <div className="d-flex">
                        <p className="wrp-tag position-relative text-white font-bold mb-0" >
                          <img src={wrpTag} alt="wrptag" title="wrptag" />
                          <strong className="position-absolute" >{title}</strong>
                        </p>
                        <p className="redeem-tag position-relative text-white font-bold mb-0 ml-n1" >
                          <img src={redeemableTag} alt="redeemableTag" title="redeemableTag" />
                          <strong className="position-absolute">{delivery_type}</strong>
                        </p>
                      </div>
                    </div>
                    <div className="d-flex validity-redeem">
                      <div className="d-flex flex-column border-right text-white pr-3">
                        <p className="mb-0">Validity</p>
                        <p className="mb-0"><strong>{validity}</strong></p>
                      </div>
                      <div className="d-flex flex-column text-white pl-3">
                        <p className="mb-0">Redeemable</p>
                        <p className="mb-0"><strong>{redeemable}</strong></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="card-footer py-2 py-md-3"
            style={{
              background: "#034C4F",
              border: "none",
              borderRadius: "0 0 8px 8px"
            }}
          >
            <div className="row font-proxima-nova align-items-center">
              <div className="col-7">
                {0 === parseInt(turn_around_time_in_hours) ? (
                  <p className="mb-0 voucher-delivery-detail d-flex align-items-center">
                    <img src={lightningIcon} alt="instant delivery" title="instant delivery" />
                    Instant Delivery
                  </p>
                ) : (
                  <p className="mb-0 voucher-delivery-detail">
                    <img src={lightningIcon} alt="lightningIcon" title="lightningIcon" />
                    <FormattedMessage
                      id="voucher.delivered-in"
                      defaultMessage="Delivered in"
                    />
                    &nbsp;
                    {turn_around_time_in_hours}{" "}
                    {turn_around_time_in_hours > 1 ? "Hours" : "Hour"}
                  </p>
                )}
              </div>
              <div className="col-5 voucher-delivery-detail text-right">
                <button
                  className="btn btn-outline p-0 d-flex align-items-center ml-auto voucher-delivery-detail"
                  style={{ color: "#fff" }}
                  onClick={this.openWrpBenefitModel}
                >
                  <img src={aboutWhite} alt="aboutWhite" title="aboutWhite" className="mr-1" />{" "}
                  <FormattedMessage
                    id="general.wrp_benefits"
                    defaultMessage="WINDS Coins Benefits"
                  />
                </button>
                {isWrpBenefitModelOpen && (
                  <VoucherWrpModal
                    isWrpBenefitModelOpen={isWrpBenefitModelOpen}
                    wrp_ratio={wrp_ratio}
                    closeWrpModelHandler={this.closeWrpModelHandler}
                  />
                )}
              </div>
            </div>

          </div>
        </div>
      </>
    );
  };

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const newData = update(this.state, {
      [name]: {
        value: { $set: value },
        isPristine: { $set: false },
      },
    });

    this.setState(newData, () => {
      this.validateField(name, value);
    });
  };

  validateField(fieldName, value) {
    let state = this.state;

    switch (fieldName) {
      case "name":
        state.name.isValid = value.length >= 3;
        state.name.error = state.name.isValid
          ? ""
          : "Please enter valid name";
        break;
      case "phone_number":
        state.phone_number.isValid = null !== value.match(/^[6-9]\d{9}$/);
        state.phone_number.error = state.phone_number.isValid
          ? ""
          : " Please enter valid mobile number";
        break;
      case "email":
        state.email.isValid = ((value === null || value === undefined) ? false : null !== value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i));
        state.email.error = state.email.isValid
          ? ""
          : "Please enter valid email";
        break;
      case "message":
        state.message.isValid = value.length >= 1;
        state.message.error = state.message.isValid
          ? ""
          : "Please write custom message";
        break;
      default:
        break;
    }
    this.setState(state, this.validateForm);
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.name.isValid &&
        this.state.email.isValid &&
        this.state.phone_number.isValid
    });
  }



  Controls = (props) => {
    const { voucher_type } = this.state.voucher;
    const { denomination, quantity } = this.props;
    const settings = {
      arrows: true,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 4,
      nextArrow: <nextArrow />,
      prevArrow: <prevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            arrows: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: false
          }
        }
      ]
    };


    return (
      <React.Fragment>
        <div className="row align-items-center mt-3 mt-md-0">
          <div className="col-8 col-md-8">
            <div className="form-group">
              {
                voucher_type === "range" &&
                <label htmlFor="denomination" style={{ color: "#333", fontWeight: "500", fontSize: "large" }}>
                  <FormattedMessage id="general.enter-voucher-amount" defaultMessage="Enter Voucher Amount" />
                  
                </label>
              }
              {"fixed" === voucher_type ? (
                <span className="h4 font-bold">Rs. {this.state.amount}</span>
                // <select
                //   className="form-control"
                //   id="denomination"
                //   onChange={(e) => this.onSelectDenomination(e.target.value)}
                // >
                //   <option value="-1">
                //     {this.props.intl.formatMessage({
                //       id: "vouchers.choose-denomination",
                //     })}
                //   </option>
                //   {denominations.map((item, index) => {
                //     return (
                //       <option
                //         key={"voucher-denomination-" + index}
                //         value={item.id}
                //         selected={index === 0}
                //       >
                //         {item.value}
                //       </option>
                //     );
                //   })}
                // </select>
              ) : (
                <React.Fragment>
                  <input
                    type="number"
                    className="form-control"
                    placeholder={this.props.intl.formatMessage({
                      id: "vouchers.enter-amount",
                    })}
                    value={this.state.amount}
                    onChange={(event) => this.onEnterAmount(event.target.value)}
                  />
                </React.Fragment>
              )}
            </div>
          </div>
          <div className="col-4 col-md-4 text-right">
            <div className="align-middle d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-outline-secondary btn-sm"
                style={{
                  height: "30px",
                  width: "30px",
                  lineHeight: "20px",
                  borderRadius: "50%",
                  fontWeight: "600"
                }}
                onClick={this.onDecreaseQuantity}
              >
                -
              </button>
              <span className="mr-2 ml-2">{quantity}</span>
              <button
                className="btn btn-quantity btn-sm text-white"
                type="submit"
                style={{
                  height: "30px",
                  width: "30px",
                  lineHeight: "20px",
                  borderRadius: "50%",
                  fontWeight: "600"
                }}
                onClick={this.onIncreaseQuantity}
              >
                +
              </button>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center mt-0 mb-2">
          <small className="form-text text-muted">
            Min {this.state.voucher.denominations[0]?.min_value}, Max {this.state.voucher.denominations[this.state.voucher.denominations?.length - 1]?.max_value}
          </small>
          <div className="mt-0">
            <div className="terms-box d-flex justify-content-end">
              <span className="m-0" style={{ color: "rgb(37 13 171)", cursor: "pointer" }} onClick={this.handleShow}>{this.props.intl.formatMessage({ id: "footer.terms-and-conditions" })}</span>
            </div>
            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={this.state.show} onHide={this.handleHide}>
              <Modal.Header>
                <Modal.Title>
                  <FormattedMessage
                    id="navbar.Terms-and-Conditions"
                    defaultMessage="Terms and Conditions"
                  />
                </Modal.Title>
                <button type="button" className="close" onClick={this.handleHide}>
                  <span aria-hidden="true">×</span>
                  <span className="sr-only">Close</span>
                </button>
              </Modal.Header>
              <Modal.Body>
                <div
                  style={{
                    color: "#646464 !important",
                    fontSize: "16px",
                    linHeight: "1.5em",
                  }}
                  dangerouslySetInnerHTML={{ __html: this.state.voucher.terms }}
                />
              </Modal.Body>
            </Modal>
          </div>
        </div>


        {this.state.voucher.voucher_type === "range" ?
          (
            <div className="row mx-auto mb-3 mt-3">
              <div className="col-12">
                <Slider {...settings} className="denom-slider">
                  {/* eslint-disable-next-line array-callback-return */}
                  {this.state.popularDenominations && this.state.popularDenominations.map(item => {
                    if (item <= this.state.voucher?.denominations[0]?.max_value)
                      return (
                        <div id={item}
                          className={"all-denominations py-2 px-4 w-auto mr-3 " + (parseInt(this.state.selectedDenomination) === item ? "selected-denomination" : "")}
                          onClick={() => this.onEnterAmount(item)}>
                          {item}
                        </div>
                      )
                  })}
                </Slider>
              </div>
            </div>
          ) :
          (
            <div className="row mx-auto mb-3 mt-3">
              <div className="col-12 p-0">
                <p className="mb-2" style={{ color: "#333", fontSize: "large", fontWeight: "500" }}>Select Voucher Amount</p>
              </div>
              <div className="col-12">
                <Slider {...settings} className="denom-slider">
                  {this.state.voucher?.denominations.map(item => {
                    return (
                      <div id={item.id}
                        className={"all-denominations py-2 px-4 w-auto mr-3 " + (this.state.selectedDenomination?.id === item.id ? "selected-denomination" : "")}
                        onClick={() => this.onSelectDenomination(item.id)}>
                        {item.value}
                      </div>
                    )
                  }
                  )}
                </Slider>
              </div>
            </div>
          )
        }

        <div className="row mt-0">
          <div className="col-12">
            {this.state.voucher.customer_discount > 0 &&
              <>
                <div className="d-flex mb-2">
                  <span className="border-right pr-2" style={{ color: "#6302FD" }}><FormattedMessage id="voucher.avail-discount" defaultMessage="Avail Discount" /> {this.state.voucher.customer_discount}%</span>
                  <span className="pl-2"><FormattedMessage id="voucher.get1" /> {((this.state.amount) - ((this.state.voucher.customer_discount * this.state.amount) / 100)) * this.props.quantity} <FormattedMessage id="voucher.get2" /></span>
                </div>
                <div className="terms-box d-flex justify-content-between">
                  <span style={{ color: "#6302FD" }}><img src={saveicon} width="25px" style={{ marginRight: "8px" }} alt="save-money" title="save-money" /><FormattedMessage id="voucher.save1" /> {((this.state.voucher.customer_discount * this.state.amount) / 100) * this.props.quantity} <FormattedMessage id="voucher.save2" /></span>
                </div>
              </>
            }
          </div>
        </div>

        {
          this.state.voucher.cashback !== 0 &&
          (
            <div className="row mt-1 mb-2">
              <div className="col-12">
                <span style={{ color: "#6302FD" }}>
                  <img src={ic_save} width="25px" style={{ marginRight: "8px" }} alt="cashback" title="cashback" />
                  <FormattedMessage id="voucher.cashback1" defaultMessage="Cashback of Rs." />
                  {this.state.voucher.cashback_type === "fixed_value" ?
                    this.state.voucher.cashback :
                    ((this.state.voucher.cashback * this.state.amount) / 100) * this.props.quantity}
                  <FormattedMessage id="voucher.cashback2" defaultMessage="will be credited to your wallet" />
                </span>
              </div>
            </div>
          )
        }

        {
          this.state.voucher?.display_gifting_flag &&
          <div className="row mt-2 mt-md-2">
            <div className="col-12">
              <Accordion defaultActiveKey="">
                <Card className="gift-voucher">
                  <Card.Header >
                    <Accordion.Toggle as={Card.Header} variant="link" eventKey="0" className="gift-header" onClick={(e) => this.controlGiftOption(e)}>
                      <div className="d-flex align-items-center">
                        <img className="gift-img" src={gift} alt="gift" title="gift" />
                        Gift this to your friends and family
                      </div>
                      {this.state.giftOptionEnabled ?
                        <img src={close} className="gift-arrow" alt="cross" title="cross" /> :
                        <img src={arrow} className="gift-arrow" alt="arrow" title="arrow" />
                      }
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <div className="form-group">
                        <input
                          type="text"
                          name="name"
                          placeholder="Name"
                          value={this.state.name.value}
                          className={classNames("form-control", {
                            "is-valid": this.state.name.isValid,
                            "is-invalid":
                              !this.state.name.isPristine &&
                              !this.state.name.isValid
                          })}
                          onChange={(event) => {
                            this.handleUserInput(event);
                          }}
                        />
                        <small className="text-danger">{this.state.name.error}</small>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          name="phone_number"
                          placeholder="Phone Number"
                          value={this.state.phone_number.value}
                          className={classNames("form-control", {
                            "is-valid": this.state.phone_number.isValid,
                            "is-invalid":
                              !this.state.phone_number.isPristine &&
                              !this.state.phone_number.isValid,
                          })}
                          onChange={(event) => {
                            this.handleUserInput(event);
                          }}
                        />
                        <small className="text-danger">{this.state.phone_number.error}</small>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          name="email"
                          placeholder="Email"
                          value={this.state.email.value}
                          className={classNames("form-control", {
                            "is-valid": this.state.email.isValid,
                            "is-invalid":
                              !this.state.email.isPristine &&
                              !this.state.email.isValid,
                          })}
                          onChange={(event) => {
                            this.handleUserInput(event);
                          }}
                          required
                        />
                        <small className="text-danger">{this.state.email.error}</small>
                      </div>
                      <div className="form-group">
                        <textarea
                          type="textarea"
                          name="message"
                          placeholder="Type your custom message"
                          value={this.state.message.value}
                          className={classNames("form-control", {
                            "is-valid": this.state.message.isValid,
                            "is-invalid":
                              !this.state.message.isPristine &&
                              !this.state.message.isValid
                          })}
                          onChange={(event) => {
                            this.handleUserInput(event);
                          }}
                        />
                        <small className="text-danger">{this.state.message.error}</small>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
          </div>
        }

        <div className="row mt-3 align-items-center">
          <div className="col-12 d-flex justify-content-center align-items-center">
            <p className="m-0">You will earn</p>
            <img className="mx-1" src={wrpCoin} alt="coin" title="coin" />
            <p className="m-0">
              {/* {(this.state.voucher.wrp_ratio * this.state.amount * this.props.quantity).toFixed(0)}  */}
              WINDS Coins for this voucher</p>
          </div>
        </div>

        <div className="row mt-3 align-items-center">
          <div className="col-12">
            {denomination && quantity > 0 && this.state.amount > 0 && ((this.state.giftOptionEnabled && this.state.name.isValid && this.state.phone_number.isValid && this.state.email.isValid) || !this.state.giftOptionEnabled) ?
              (
                <button
                  className="animated-btn btn-block mb-3"
                  onClick={this.onProceed}
                >
                  BUY FOR ₹{((this.state.amount) - ((this.state.voucher.customer_discount * this.state.amount) / 100)) * this.props.quantity}
                </button>
              ) : (
                <button
                  className="animated-btn-disabled btn-block mb-3"
                  disabled={true}
                >
                  BUY
                </button>
              )}
          </div>
          {this.state.isLoginDialogOpen && (
            <LoginDialog
              isLoginDialogOpen={this.state.isLoginDialogOpen}
              closeLoginDialog={this.closeLoginDialog}
            />
          )}
        </div>
      </React.Fragment>
    );
  };

  Details = (props) => {
    const { key, voucher } = this.state;
    return (
      <Tabs
        className="wrp-tabs mx-auto justify-content-center"
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(selectedKey) => this.setState({ key: selectedKey })}
      >
        <Tab
          eventKey="details"
          title={<h2 className="h5 mb-0">{this.props.intl.formatMessage({ id: "general.how_to_redeem" })}</h2>}
        >
          <img src={voucher.redeem_image_path} width="100%" style={{ padding: "3%" }} alt="how to redeem" title="how to redeem" />
          <div
            style={{
              color: "#646464",
              fontSize: "16px",
              linHeight: "22px",
              paddingTop: "30px",
            }}
            dangerouslySetInnerHTML={{ __html: voucher.redeem_text }}
          />
        </Tab>
        <Tab
          eventKey="terms"
          title={<h2 className="h5 mb-0">{this.props.intl.formatMessage({ id: "general.details" })}</h2>}
        >
          <div
            style={{
              color: "#646464 !important",
              fontSize: "16px",
              linHeight: "1.5em",
              marginTop: "2rem",
            }}
            dangerouslySetInnerHTML={{ __html: voucher.description }}
          />
        </Tab>
      </Tabs>
    );
  };
}

function mapStateToProps(state) {
  return {
    voucher: state.voucher.voucher,
    denomination: state.voucher.denomination,
    quantity: state.voucher.quantity,
    profile: state.global.profile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setVoucher: (id) => {
      dispatch(setVoucher(id));
    },
    setDenomination: (denomination) => {
      dispatch(setDenomination(denomination));
    },
    setQuantity: (quantity) => {
      dispatch(setQuantity(quantity));
    },
    setGiftDetails: (giftDetails) => {
      dispatch(setGiftDetails(giftDetails));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withRouter(VoucherDetail)));