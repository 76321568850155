export function search(query, results) {
    return { 
        type: "SEARCH", 
        query,
        results 
    }
};

export function setSearchQuery(query){
    return {
        type:'SET_QUERY',
        query
    }
}

export function resetSearchResult() {
    return {
        type:'RESET_SEARCH_RESULT'
    }
}

export function isSearching(status){
    return {
        type:'UPDATE_SEARCH_STATUS',
        status
    }
}