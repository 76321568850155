import Modal from "react-bootstrap/Modal";
import React from "react";

const InfoDialog = (props) => {
  const { message, url,onDismiss,buttonTxt, title } = props;
  return (
      <Modal
        show
        onHide={onDismiss}
        onEntered={props.modelEntered}
        backdrop="static"
        className="claim-dialog font-proxima-nova"
      >
        <Modal.Body>
          <img src={url} alt="dialog" title="dialog"/>
          <h3>{title}</h3>
          <p>{message}</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
        <button className="btn btn-primary home-btn" onClick={onDismiss}>
          {buttonTxt}
        </button>   
        </Modal.Footer>
       
      </Modal>
  
  );
};

export default InfoDialog;
