import HelmetMetaData from "../components/HelmetMetaData";
import { useLocation } from "react-router-dom";

function DigiWorldMetaData() {
  let location = useLocation();
  let currentAppUrl = process.env.REACT_APP_URL + location.pathname;

  return (
    <HelmetMetaData
      title={`WINDS Digi World: Discover True Business Growth with WINDS App`}
      description={`Unlock boundless business potential with WINDS Digi World. Expand income with virtual shops, e-commerce portals, registered partner retail outlets, exclusive brand vouchers and many more. Join now!`}
      keywords={`vouchers, gift vouchers, e-vouchers, discount vouchers, online shopping sites, winds app, winds digi world, virtual shops, e-commerce portals, retail outlets, brand vouchers`}
      voiceSchema={<script type="application/ld+json">
        {JSON.stringify(
          {
            "@context": "https://schema.org/",
            "@type": "WebPage",
            "name": "WINDS DIGI-WORLD",
            "speakable":
            {
              "@type": "SpeakableSpecification",
              "xpath":
                [
                  "/html/head/title",
                  "/html/head/meta[@name='description']/@content"
                ]
            },
            "url": currentAppUrl
          }
        )}
      </script>
      }
      breadcrumbSchema={<script type="application/ld+json">
        {JSON.stringify(
          {
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            "itemListElement": [{
              "@type": "ListItem",
              "position": 1,
              "name": "Home",
              "item": process.env.REACT_APP_URL
            }, {
              "@type": "ListItem",
              "position": 2,
              "name": "WINDS DIGI-WORLD",
              "item": currentAppUrl
            }]
          }
        )}
      </script>}
      faqSchema={
        <script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [
                {
                  "@type": "Question",
                  "name": "How Much Has To Be Paid To Become A WINDS Digi World Franchisee?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "You will have to pay a one-time fee of ₹15,000 (Inclusive of GST) to become a WINDS Digi World Franchisee."
                  }
                },
                {
                  "@type": "Question",
                  "name": "What Will Be The Primary Responsibilities Of The Digi World Franchisee?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The primary responsibility of a Franchisee is to appoint multiple franchisees under them so that they get more opportunities to earn through the Referral Commission on appointment besides the Overriding Commission (ORC) through businesses of those appointed Franchisees. They must identify and establish more WINDS Partners and ensure that the appointed Partners increasingly transact through WINDS so that the Franchisee can earn Commission from every transaction. They must refer and add on/tag WINDS Patrons and ensure they shop as much as possible through WINDS Partners. Also, they must ensure that the Franchisee referred by them add on/tag more Digi World Franchisees, WINDS Partners and WINDS Patrons."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Is There Validity To Purchasing This DIGI WORLD FRANCHISE?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "No, there is no validity. Just pay the one-time fees & become the owner of the WINDS DIGI World Franchise."
                  }
                },
                {
                  "@type": "Question",
                  "name": "If A Franchisee Introduces Another Franchisee, How Much Referral Commission Will They Get?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "In such a situation, the Franchisee will get 15% of the Franchisee fee amount, which is 15% of ₹ 15,000/ i.e. ₹ 2250/- for every Franchisee referred."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Suppose the WINDS Patron added by the Franchisee purchases the introduced WINDS Partner through the same Franchisee. What percentage of the WINDS' income on the purchase will be earned as a commission by the Franchisee?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Suppose the same Franchisee has introduced both the transacting Partner and the Patron. In that case, the Franchisee will get 20% of WINDS' earnings from the total business done between them (the Partner and the Patron)."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Is The Deposit Amount Of Owning The DIGI WORLD FRANCHISE Refundable?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Unfortunately, the one-time fee isn't refundable."
                  }
                },
                {
                  "@type": "Question",
                  "name": "What Documents Are Required For Owning A WINDS DIGI WORLD FRANCHISE?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Only bank details & a PAN card are required to own a WINDS DIGI WORLD Franchise."
                  }
                },
                {
                  "@type": "Question",
                  "name": "How Much Overriding Commission Will Franchisees Get Based On The Business Done Through Their Recruited Franchisees?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "A Franchisee will earn 25% of the earnings (excluding referral commission) of every Franchisee directly recruited by them as Overriding Commission (ORC) if that referred Franchisee has on-boarded 20 Partners and 100 Patrons or more. For Franchisees who have on-boarded fewer than 20 Partners and fewer than 100 Patrons, the ORC earned would be 18.75% of the recruited franchisees’ earnings."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Will I Get Additional Benefits From Owning The DIGI WORLD FRANCHISE Compared To A Regular WINDS App User?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, of course. After owning the WINDS DIGI World Franchise, you will instantly receive 75,000 WRPs on your WINDS Patron app, which means 15 coupons for this month's Lucky draw prize worth up to Rs. 25 lakhs* cash and also a lifetime benefit of 150% WRPs on all WINDS transactions done by you on our Patron app (exclusive of all drives and offers)."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Can I Own More Than One DIGI WORLD FRANCHISE As An Individual?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, you can."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Do I Need Educational Qualifications or Any Business Experience To Become A Franchisee?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "There is no requirement for any educational qualification or prior business experience for anybody to become a Franchisee."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Can Two Individuals Be A Joint Owner Of A DIGI WORLD FRANCHISE?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "On record, only an individual can own the WINDS DIGI World Franchise."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Do We Require A GST Number To Own This Franchise?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "GST Number is not mandatory to own the Winds DIGI Owner Franchise."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Will There Be A Separate Draw From The DIGI WORLD FRANCHISE Owner?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "No, but you can participate in Winds Patron Lucky Draw by shopping and generating WRPs. You can also join WINDS Partner Lucky Draw if registered as WINDS Partner."
                  }
                },
                {
                  "@type": "Question",
                  "name": "Does the DIGI WORLD FRANCHISE Subscription Have A Validity?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "No, after a one-time Franchise fee, you can enjoy all the benefits after subscribing to the WINDS DIGI World Franchise for a lifetime."
                  }
                },
                {
                  "@type": "Question",
                  "name": "How Will I Receive My Earnings Of Being A DIGI WORLD FRANCHISE Owner?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Every commission you earn by introducing accumulates in your WINDS DIGI Franchise App. Once you collect Rs. 501, you can transfer the money to your Bank Account."
                  }
                }
              ]
            }
          )}
        </script>

      }
    ></HelmetMetaData>
  );
}

export default DigiWorldMetaData;