import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import lodash from "lodash";
import ContentLoader from "react-content-loader";
import DefaultStoreImage from "../../../assets/default-store-image.svg";
import InlineLoader from "../../../components/InlineLoader";
import filterIcon from "../../../assets/filter-icon.svg";
import {
  fetchingOffers,
  setOffers,
  fetchingOfferCategories,
  setOfferCategories,
  selectCategoryForOffers,
  unselectCategoryForOffers,
  fetchingNextPage,
  addOffers,
  setPartnerListAfterBookmarkToggle,
  onOffersFilterClick,
  resetOrderFilter
} from "../actions/offer";
import { FormattedMessage } from "react-intl";
import Modal from "react-bootstrap/Modal";
import bookmarkActive from "../../../assets/bookmark-active.svg";
import bookmarkInactive from "../../../assets/bookmark-inactive.svg";
import customerAffiliateClientX from "../../../utils/api/customerAffiliateClientX";
import { onViewedPage, onFilteredOffers } from '../../../utils/events';

class NearByOffers extends Component {
  componentDidMount() {
    this.props.initCategory();
    this.props.initOffers(
      this.props.selectedCategories,
      this.props.latitude,
      this.props.longitude
    );

    window.addEventListener("scroll", this.onScroll, false);
    const payload = {
      page_name: "Offer List Page"
    };

    onViewedPage(payload);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll, false);
  }

  onScroll = () => {
    let height = 0
    window.innerWidth < 767 ? height = 1800 : height = 500

    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight - height &&
      this.props.offers &&
      !this.props.isFetchingNextPage &&
      this.props.nextPage
    ) {
      this.props.onPaginatedSearch(
        this.props.selectedCategories,
        this.props.latitude,
        this.props.longitude,
        this.props.nextPage
      );
    }
  };

  onChange = (event, id) => {
    if (event.target.checked) {
      this.props.onSelectCategory(id);
      return;
    }

    this.props.onUnselectCategory(id);
  };

  componentDidUpdate(prevProps) {
    if (
      !lodash.isEqual(
        this.props.selectedCategories,
        prevProps.selectedCategories
      ) ||
      this.props.latitude !== prevProps.latitude
    ) {
      this.props.initOffers(
        this.props.selectedCategories,
        this.props.latitude,
        this.props.longitude
      );
    }
  }

  render() {
    return (
      <div className="container-fluid font-proxima-nova">
        <div className="row mt-3 mb-3 mt-md-4 mb-md-4">
          <div className="col-12">
            <div className="row align-items-center">
              <div className="col-8 col-md-12">
                <small>
                  <Link to="/">
                    <FormattedMessage
                      id="breadcrumbs.home"
                      defaultMessage="Home"
                    />
                  </Link>{" "}
                  /{" "}
                  <strong>
                    <FormattedMessage id="home.offers-near-you" />
                  </strong>
                </small>
              </div>
              <div className="col-4 text-right d-block d-md-none">
                <button
                  className="btn text-primary"
                  onClick={this.props.onOffersFilterClick}
                >
                  <img src={filterIcon} alt="filter" title="filter" className="filter-icon mr-1" />
                  <FormattedMessage
                    id="general.filters"
                    defaultMessage="Filters"
                  />
                </button>

                <React.Fragment>
                  <Modal
                    show={this.props.isOffersFilterModelOpen}
                    onHide={this.props.onOffersFilterClick}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Filters</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                      <this.Filters />
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="row w-100">
                        <div className="col-6">
                          <button
                            className="w-100 btn btn-outline-primary"
                            onClick={this.props.onOffersFilterClick}
                          >
                            <FormattedMessage
                              id="general.apply"
                              defaultMessage="Apply"
                            />
                          </button>
                        </div>
                        <div className="col-6">
                          <button
                            className="btn w-100 btn-outline-primary"
                            onClick={() => {
                              this.props.onOffersFilterClick();
                              this.props.resetOrderFilter();
                            }}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </Modal.Footer>
                  </Modal>
                </React.Fragment>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4 status">
          <div className="col-12">
            <div className="row">
              <div className="col-md-3 d-none d-md-block border-right">
                <this.FiltersBlock />
              </div>
              <div className="col-md-9">
                {this.props.isFetchingOffers ? (
                  <this.OffersShimmer />
                ) : (
                  <this.Offers />
                )}
                <div className="row">
                  <div className="col-12 text-center">
                    {this.props.isFetchingNextPage && <InlineLoader />}
                    {!this.props.nextPage && (
                      <p className="font-weight-bold">
                        <FormattedMessage
                          id="offers.no-more"
                          defaultMessage="No More Offers"
                        />{" "}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  FiltersBlock = props => {
    return (
      <React.Fragment>
        <div
          className="row mb-3 status border-bottom"
          style={{ borderBottom: "solid 1px #E8E8E8" }}
        >
          <div className="col-6 pl-0">
            <h5>
              <FormattedMessage id="general.filters" defaultMessage="Filters" />
            </h5>
          </div>
          <div className="col-6 pr-0">
            <button
              className="btn btn-outline float-right"
              onClick={this.props.resetOrderFilter}
              style={{ color: "#087CD8", marginTop: "-0.5rem" }}
            >
              <FormattedMessage id="general.reset" defaultMessage="Reset" />
            </button>
          </div>
        </div>
        <this.Filters />
      </React.Fragment>
    );
  };

  Filters = props => {
    return (
      <React.Fragment>
        {this.props.isFetchingOfferCategories ? (
          <div className="row">
            <div className="col-md-12">
              <ContentLoader
                height={200}
                width={200}
                speed={2}
                primaryColor="#F6F7F8"
                secondaryColor="#ecebeb"
              >
                <rect x="0" y="0" rx="2" ry="2" width="132" height="16" />
                <rect x="180" y="0" rx="2" ry="2" width="16" height="16" />
                <rect x="0" y="24" rx="2" ry="2" width="132" height="16" />
                <rect x="0" y="48" rx="2" ry="2" width="132" height="16" />
                <rect x="0" y="72" rx="2" ry="2" width="132" height="16" />
                <rect x="0" y="96" rx="2" ry="2" width="132" height="16" />
                <rect x="0" y="120" rx="2" ry="2" width="132" height="16" />
                <rect x="0" y="144" rx="2" ry="2" width="132" height="16" />
                <rect x="0" y="168" rx="2" ry="2" width="132" height="16" />
                <rect x="180" y="24" rx="2" ry="2" width="16" height="16" />
                <rect x="180" y="48" rx="2" ry="2" width="16" height="16" />
                <rect x="180" y="72" rx="2" ry="2" width="16" height="16" />
                <rect x="180" y="96" rx="2" ry="2" width="16" height="16" />
                <rect x="180" y="120" rx="2" ry="2" width="16" height="16" />
                <rect x="180" y="144" rx="2" ry="2" width="16" height="16" />
                <rect x="180" y="168" rx="2" ry="2" width="16" height="16" />
              </ContentLoader>
            </div>
          </div>
        ) : (
          <div className="row mb-3 status winds-grey-color">
            <p className="text-uppercase">
              <FormattedMessage id="general.categories" />
            </p>
            <div className="col-12 filter-categories">
              <div
                style={{
                  height: "500px",
                  overflowY: "scroll",
                  overflowX: "hidden"
                }}
              >
                {this.props.categories.map((item, index) => {
                  return (
                    <div
                      className="row mt-3"
                      key={"offer-category-box-" + index}
                    >
                      <div className="col-9">{item.name}</div>
                      <div className="col-3 mb-1 text-right">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={"shopping-sites-category-" + item.id}
                            checked={
                              lodash.indexOf(
                                this.props.selectedCategories,
                                item.id
                              ) > -1
                            }
                            onChange={event => {
                              this.onChange(event, item.id);
                            }}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={"shopping-sites-category-" + item.id}
                          ></label>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };

  toggleBookmark = (event, id, isBookmarked) => {
    event.preventDefault();
    event.stopPropagation();
    this.onToggleBookmark(
      id,
      isBookmarked,
      `/nearby-partners/${id}/bookmark`,
      "offers"
    );
  };

  onToggleBookmark = (id, isBookmarked, url, type) =>
    !isBookmarked
      ? this.props.addBookmark(id, url, this.props.partners)
      : this.props.deleteBookmark(id, url, this.props.partners);

  OffersShimmer = props => {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12 col-md-4">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
          <div className="col-12 col-md-4">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
          <div className="col-12 col-md-4 d-none d-md-block">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-4">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
          <div className="col-12 col-md-4">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
          <div className="col-12 col-md-4 d-none d-md-block">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-4">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
          <div className="col-12 col-md-4">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
          <div className="col-12 col-md-4 d-none d-md-block">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  Offers = props => {
    return (
      <div className="row">
        {this.props.offers.map((item, index) => {
          return (
            <div className="col-md-4" key={"offer-card-" + index}>
              <Link to={`/offer/${item.id}`}>
                <div className="card mb-4 card--carousel-item card--carousel-item--offers">
                  {null != item.store_image ? (
                    <div
                      className="card-body"
                      style={{
                        backgroundColor: "#F8F9FA",
                        backgroundImage: `url(${item.store_image})`,
                        backgroundSize: "cover",
                        height: "200px"
                      }}
                    ></div>
                  ) : (
                    <div
                      className="card-body"
                      style={{
                        backgroundColor: "#F8F9FA",
                        backgroundImage: `url(${DefaultStoreImage})`,
                        backgroundSize: "contain",
                        backgroundPosition: "center center",
                        backgroundRepeat: "no-repeat",
                        height: "200px"
                      }}
                    ></div>
                  )}
                  {
                    <img
                      src={item.bookmarked ? bookmarkActive : bookmarkInactive}
                      className="bookmark"
                      alt="bookmark"
                      title="bookmark"
                      onClick={event =>
                        this.toggleBookmark(event, item.id, item.bookmarked)
                      }
                    />
                  }
                  <div className="card-footer">
                    <p className="mb-0">
                      <small>{item.partner_name}</small>
                    </p>
                    <p className="mb-0">
                      <strong>{item.title}</strong>
                    </p>
                    <p className="mb-0" style={{ textAlign: "justify" }}>
                      {item.description !== undefined &&
                        item.description.substring(0, 50) + "..."}
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    );
  };
}

function mapStateToProps(state) {
  return {
    isFetchingOfferCategories: state.offer.isFetchingOfferCategories,
    categories: state.offer.categories,
    selectedCategories: state.offer.selectedCategories,
    isFetchingOffers: state.offer.isFetchingOffers,
    offers: state.offer.offers,
    isFetchingNextPage: state.offer.isFetchingNextPage,
    nextPage: state.offer.nextPage,
    latitude: state.location.latitude,
    longitude: state.location.longitude,
    isOffersFilterModelOpen: state.offer.isOffersFilterModelOpen
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initCategory: () => {
      dispatch(fetchingOfferCategories());

      customerAffiliateClientX.get("v1/nearby-partners/categories").then(response => {
        let data = response.data.data;

        dispatch(setOfferCategories(data));
      });
    },
    initOffers: (categories, latitude, longitude) => {
      dispatch(fetchingOffers());

      customerAffiliateClientX
        .get("v1/nearby-partners/offers", {
          params: {
            latitude: Number(latitude),
            longitude: Number(longitude),
            radius: 5,
            categories: categories.join(",")
          }
        })
        .then(response => {
          let data = response.data.data;

          let nextPageURL =
            null !== response.data.links.next &&
            new URL(response.data.links.next);

          let nextPage =
            null !== response.data.links.next &&
            nextPageURL.searchParams.get("page");

          dispatch(setOffers(data, nextPage));
        });
    },
    onSelectCategory: id => {
      onFilteredOffers({
        category: id
      })
      dispatch(selectCategoryForOffers(id));
    },
    onUnselectCategory: id => {
      dispatch(unselectCategoryForOffers(id));
    },
    onOffersFilterClick: () => {
      dispatch(onOffersFilterClick());
    },
    resetOrderFilter: () => {
      dispatch(resetOrderFilter());
    },
    addBookmark: (id, url, offers) => {
      customerAffiliateClientX
        .post(url, {})
        .then(response => {
          dispatch(setPartnerListAfterBookmarkToggle(id, offers));
        })
        .catch(error => { });
    },

    deleteBookmark: (id, url, offers) => {
      customerAffiliateClientX
        .delete(url)
        .then(response => {
          dispatch(setPartnerListAfterBookmarkToggle(id, offers));
        })
        .catch(error => { });
    },
    onPaginatedSearch: (categories, latitude, longitude, nextPage) => {
      dispatch(fetchingNextPage());

      customerAffiliateClientX
        .get("v1/nearby-partners/offers", {
          params: {
            latitude: Number(latitude),
            longitude: Number(longitude),
            radius: 5,
            categories: categories.join(","),
            page: nextPage
          }
        })
        .then(response => {
          let data = response.data.data;

          let nextPageURL =
            null !== response.data.links.next &&
            new URL(response.data.links.next);

          let nextPage =
            null !== response.data.links.next &&
            nextPageURL.searchParams.get("page");

          dispatch(addOffers(data, nextPage));
        });
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NearByOffers);
