export const onLoginEvent = (mobile) => {
  window.dataLayer.push({
    'event': 'Logged_In',
    'mobile': mobile
  });
}

export const onRegisterEvent = (mobile) => {
  window.dataLayer.push({
    'event': 'Signup_Completed',
    'mobile': mobile
  });
}