import React, { Component } from "react";
import voucherClientX from "../../../utils/api/voucherClientX";
import { Link, Redirect } from "react-router-dom";
import ContentLoader from "react-content-loader";
import lodash from "lodash";
import { connect } from "react-redux";
import InlineLoader from "../../../components/InlineLoader";
import bookmarkActive from "../../../assets/bookmark-active.svg";
import bookmarkInactive from "../../../assets/bookmark-inactive.svg";
import windsLogo from "../../../assets/winds_Logo.svg";
import offer from "../../../assets/ic_offer.svg";
import SubVouchers from "../../../components/SubVouchers";
import { readCookie } from "../../../utils/helper";
import {
  fetchingVouchers,
  setVouchers,
  fetchingVoucherCategories,
  setVoucherCategories,
  selectCategoryForVouchers,
  unselectCategoryForVouchers,
  fetchingNextPage,
  addVouchers,
  setVoucherListAfterBookmarkToggle,
  onVouchersFilterClick,
  resetVoucherFilter,
  setWindsGiftCard,
  fetchingInitialVouchers,
  setVouchersBannerData,
  setSearchVouchers,
} from "../actions";
import { FormattedMessage } from "react-intl";
import { onViewedPage, onFilteredVouchers } from "../../../utils/events";
import { currentlySelectedLanguage } from "../../../utils/helper";
import Iframe from "../../../components/iFrame";
import { FirebaseContext } from "../../../utils/firebase";
import EmptyState from "../../../components/EmptyState";
import voucherSearch from "../../../assets/voucher/ic_search.svg";
import voucherCashback from "../../../assets/voucher/ic_cashback.svg";
import customerCommonClientX from "../../../utils/api/customerCommonClientX";
import EmptyImage from "../../../assets/voucher/ic_no_result_found.svg";
import VoucherMetaData from "../../../seo/VoucherMetaData";
import { Dropdown, OverlayTrigger, Popover } from "react-bootstrap";
import VoucherBannerCarousal from "../components/VoucherBannerCarousal";
import ic_clear from "../../../assets/ic_clear.svg";
import ic_dropdown from "../../../assets/voucher/ic_dropdown.svg";
import HelmetMetaData from "../../../components/HelmetMetaData";
import { voucherCategoryMetaContent } from "../components/VoucherCategoryMetaContent"
import { accessTokenName } from "../../Agoda_hotel/Hotel-booking";
import { decryptByAesAndBase64WST } from "../../../utils/helper";
import profileClientX from "../../../utils/api/profileClientX";
import { setProfile } from "../../../actions";
import { setUserAccessToken } from "../../../utils/authorization";
import { profile } from "../../../utils/cleverTap";
import { resetProfile } from "../../../actions";
let secret_key = process.env.REACT_APP_OTP_SECRET_KEY;
let secret_iv = process.env.REACT_APP_OTP_SECRET_IV;

class VouchersList extends Component {
  static contextType = FirebaseContext;

  state = {
    isMultipleVoucherAvailable: false,
    selected: 0,
    redirect: false,
    isVoucherMaintenance: false,
    discountFilter: {},
    customerDiscountFilter: [],
    discountStatus: "",
    showModal: false,
    voucherSearchParam: "",
    selectedFilterSlug: this.props?.match?.params?.id
      ? this.props?.match?.params?.id
      : null,
    selectedFilterRange: "",
    selectedType: null,
    selectedCategoryName: ""
  };

  componentDidMount() {
    
      this.props.resetFilterOnLoad();
      this.props.resetVoucherFilter();
          const { voucher_detail } = this.context;
    if (!voucher_detail) {
      if (this.props.match?.params?.id) {
        this.updateVoucherFilter(this.props?.match?.params?.id);
      }
      else {
        this.props.initVouchers(this.props?.selectedCategories, this.state?.discountStatus);
      }
      this.props.initCategory();
      setTimeout(() => {
        this.createDiscountFilter();
      }, 500);
    } else {
      this.setState({
        isVoucherMaintenance: voucher_detail,
      });
    }

    const payload = {
      page_name: "Voucher List Page",
    };

    onViewedPage(payload);
    window.addEventListener("scroll", this.onScroll, false);

    const queryParameters = new URLSearchParams(window.location.search);
  const parameterData = queryParameters.get(accessTokenName);
  const accessToken = decryptByAesAndBase64WST(
    parameterData,
    secret_key,
    secret_iv
  );
  
 
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll, false);
  }

  resetDiscountFilter() {
    let discountFilter = {}
    // eslint-disable-next-line array-callback-return
    this.props.discountData?.filter_details?.map((item) => {
      discountFilter[`${item.actual_from_value}-${item.actual_to_value}`] = false;
    });
    this.setState({ discountFilter: discountFilter, selectedFilterRange: "" });
    this.setState({ discountStatus: "" });
    this.props.updateVouchers(this.props?.selectedCategories, "", "");

  }

  createDiscountFilter() {
    let filterData = this.props?.discountData?.filter_details.map((item) => {
      return {
        key: `${item.actual_from_value}-${item.actual_to_value}`,
        value: item.display_text_translated,
      };
    });

    let discountFilter = {};
    // eslint-disable-next-line array-callback-return
    this.props.discountData?.filter_details?.map((item) => {
      discountFilter[
        `${item.actual_from_value}-${item.actual_to_value}`
      ] = false;
    });
    this.setState({
      customerDiscountFilter: filterData,
      discountFilter: discountFilter,
    });
  }

  onScroll = () => {
    let height = 0;
    window.innerWidth < 767 ? (height = 1800) : (height = 500);

    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight - height &&
      this.props.vouchers &&
      !this.props.isFetchingNextPage &&
      this.props.nextPage &&
      this.state.voucherSearchParam.length === 0
    ) {
      if (this.props.match?.params?.id) {
        this.props.onPaginatedSearch(
          this.props.match?.params?.id,
          this.props.nextPage,
          this.state.discountStatus,
          this.state.selectedType?.id
        );
      }
      else {
        this.props.onPaginatedSearch(
          this.props.selectedCategories,
          this.props.nextPage,
          this.state.discountStatus,
          this.state.selectedType?.id
        );
      }
    }
  };

  updateVoucherFilter = (slug) => {
    this.props.onSelectCategory(slug);
    this.props.initVouchers(slug, this.state.discountStatus);
    this.setState({ selectedFilterSlug: slug, voucherSearchParam: "" });
    this.props.history.push(`/vouchers/category/${slug}`);
    this.getVoucherCategoryNameBySlug(slug);
  };

  getVoucherCategoryNameBySlug = (slug) => {
    setTimeout(() => {
      this.props.categories &&
        // eslint-disable-next-line array-callback-return
        this.props.categories.forEach((item) => {
          if (slug === item?.slug) {
            this.setState({ selectedCategoryName: item?.value });
          }
        });
    }, 1000);
  }

  clearVoucherFilter = () => {
    this.setState({ selectedFilterSlug: null });
    this.props.resetVoucherFilter();
    this.setState({ voucherSearchParam: "" });
    this.props.history.push(`/vouchers`);
  };

  onChange = (event, id) => {
    let name = event.target.name;
    if (event.target.checked) {
      this.props.onSelectCategory(id);
      onFilteredVouchers({ category: name });
      return;
    }
    this.props.onUnselectCategory(id);
  };

  searchVouchers = (event) => {
    let searchParam = event.target.value;
    this.setState({ voucherSearchParam: searchParam });
    if (searchParam.length >= 3) {
      this.setState({ selectedType: null, selectedFilterRange: "", selectedFilterSlug: null });
      this.props.searchVouchers(
        searchParam,
        this.props.selectedCategories,
        this.state?.discountStatus
      );
    }
    if (searchParam.length === 0) {
      this.resetDiscountFilter();
      this.props.updateVouchers(null, "", "");
      this.clearVoucherFilter();
    }
  };

  viewWindsVoucher = () => {
    this.props.history.push(`/voucher/${this.props?.windsVoucher?.slug}`);
  };

  componentDidUpdate(prevProps) {

    const currentId = this.props?.match?.params?.id;
    const prevId = prevProps?.match?.params?.id;
    if (currentId && currentId !== prevId) {
      this.props?.categories && this.updateVoucherFilter(currentId);
    }
    else {
      if (this.props?.match?.path === "/vouchers") {
        this.props.resetVoucherFilter();
        this.state.selectedFilterSlug !== null &&
          this.setState({ selectedFilterSlug: null });
      }
    }
    if (
      !lodash.isEqual(
        this.props.selectedCategories,
        prevProps.selectedCategories
      )
    ) {
      this.props.updateVouchers(
        this.props.selectedCategories,
        this.state.discountStatus,
        this.state.selectedType?.id
      );
    }
  }
  handleVoucherType = (data) => {
    this.setState({ selectedType: data });
    this.props.updateVouchers(
      this.props.selectedCategories,
      this.state.discountStatus,
      data?.id
    );
  }
  resetVoucherType = () => {
    this.setState({ selectedType: null });
    this.props.updateVouchers(
      this.props.selectedCategories,
      this.state.discountStatus,
      ""
    );

  }
  // createDiscountFilter() {
  //   let filterData = this.props?.discountData?.filter_details.map((item) => {
  //     return {
  //       key: `${item.actual_from_value}-${item.actual_to_value}`,
  //       value: item.display_text_translated,
  //     };
  //   });

  //   let discountFilter = {};
  //   // eslint-disable-next-line array-callback-return
  //   this.props.discountData?.filter_details?.map((item) => {
  //     discountFilter[
  //       `${item.actual_from_value}-${item.actual_to_value}`
  //     ] = false;
  //   });
  //   this.setState({
  //     customerDiscountFilter: filterData,
  //     discountFilter: discountFilter,
  //   });
  // }

  Breadcrubs = (props) => {
    return (
      <div className="col-8 col-md-12">
        <small>
          <Link to="/">
            <FormattedMessage id="breadcrumbs.home" defaultMessage="Home" />
          </Link>{" "}
          /{" "}
          <strong>
            <FormattedMessage
              id="home.brand-vouchers"
              defaultMessage="Vouchers"
            />
          </strong>
        </small>
      </div>
    );
  };

  VoucherCategoryMetaData = () => {
    let currentAppUrl = process.env.REACT_APP_URL + window.location.pathname;
    return (
      <HelmetMetaData
        title={`Buy ${this.state.selectedCategoryName} Vouchers & E-Gift Cards Online From WINDS | WINDS App`}
        description={`Buy WINDS e-gift cards/ brand vouchers for ${this.state.selectedCategoryName} brands online from the WINDS App. Shop online today ${this.state.selectedCategoryName} Vouchers from WINDS App. `}
        keywords={voucherCategoryMetaContent[this.state.selectedFilterSlug]?.keywords}
        voiceSchema={<script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org/",
              "@type": "WebPage",
              "name": `Buy ${this.state.selectedCategoryName} Vouchers & E-Gift Cards Online From WINDS | WINDS App`,
              "speakable":
              {
                "@type": "SpeakableSpecification",
                "xpath":
                  [
                    "/html/head/title",
                    "/html/head/meta[@name='description']/@content"
                  ]
              },
              "url": currentAppUrl
            }
          )}
        </script>
        }
        breadcrumbSchema={<script type="application/ld+json">
          {JSON.stringify(
            {
              "@context": "https://schema.org/",
              "@type": "BreadcrumbList",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://windsapp.com/"
              }, {
                "@type": "ListItem",
                "position": 2,
                "name": "Vouchers",
                "item": "https://windsapp.com/vouchers"
              }, {
                "@type": "ListItem",
                "position": 3,
                "name": `${this.state.selectedCategoryName}`,
                "item": currentAppUrl
              }]
            }
          )}
        </script>}
      ></HelmetMetaData>
    )
  }

  render() {
    return (
      <>
        {
          this.props?.match?.params?.id ?
            <this.VoucherCategoryMetaData /> :
            <VoucherMetaData />
        }
        {this.context.voucher_detail ? (
          <div className="container font-proxima-nova">
            <div className="row mt-3 mb-3 mt-md-4 mb-md-4 align-items-center">
              <this.Breadcrubs />
            </div>
            <div className="row">
              <div className="col-12">
                <Iframe
                  source={`${process.env.REACT_APP_BASE_URL_VOUCHER_MAINTENANCE
                    }/${currentlySelectedLanguage()}`}
                ></Iframe>
              </div>
            </div>
          </div>
        ) : (
          <div className="container font-proxima-nova mt-2 mb-3 mt-md-4 mb-md-4">
            {
              this.props.isFetchingInitialVouchers ?
                <div className="box shine w-100 mb-3 mt-2" style={{ height: "300px" }}></div> :
                <VoucherBannerCarousal />
            }
            <div className="col-12">
              <div className="row mb-2 d-flex align-items-center pt-3 pt-md-5">
                <div className="col-12 col-md-12">
                
                  <h1 className="voucher-brand">
                    {this.props?.match?.params?.id ? `${this.state?.selectedCategoryName} E-Gift Cards & Brand Vouchers` :
                      "Brand Vouchers & E-Gift Cards"}
                  </h1>
                </div>
              </div>
              <div className="d-flex justify-content-between  row">
                <div className="col-12 col-md-8 d-flex align-items-center">
                  {this.state?.selectedFilterSlug === null && (
                    <OverlayTrigger
                      trigger={"click"}
                      placement="bottom"
                      rootClose
                      overlay={
                        <Popover className="voucher-popover">
                          <div className="overlay-container">
                            <div className="row p-3 ">

                              <>
                                {this.props.categories.map((item, index) => (
                                  <div className="col-12 col-sm-6 col-md-4 py-1 selected-category " key={index} onClick={() => this.updateVoucherFilter(item?.slug)}>
                                    <span>{item.value}</span>
                                  </div>
                                ))}
                              </>
                            </div>
                          </div>
                        </Popover>
                      }
                    >
                      <button
                        variant="light"
                        className="col-5 col-md-4 pr-md-1 d-flex justify-content-between align-items-center"
                        id="filter-dropdown"
                      >
                        <span>Category</span>
                        <span className="pr-2">
                          <img
                            src={ic_dropdown}
                            alt="dropdown-btn"
                            style={{ width: "10px" }}
                          />
                        </span>
                      </button>
                    </OverlayTrigger>
                  )}
                  {this.state?.selectedFilterSlug !== null && (
                    <div className="col-5 col-md-4 filterkey-btn d-flex justify-content-between align-items-center px-2">
                      <span>{this.state?.selectedCategoryName}</span>
                      <span className="ml-2">
                        <img
                          src={ic_clear}
                          alt="close-btn"
                          title="close-btn"
                          style={{ width: "10px" }}
                          onClick={() => this.clearVoucherFilter()}
                          className="cursor-pointer"
                        />
                      </span>
                    </div>
                  )}
                  {this.state.selectedType === null ?
                    <>
                      <Dropdown className="col-3 ml-md-0 p-0 pl-md-1 dropdown">
                        <Dropdown.Toggle variant="light" className="w-100 d-flex justify-content-between align-items-center" id="filter-dropdown">Types</Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item className="selected-category" onClick={() => this.handleVoucherType({ id: 0, name: "Offline" })}>Offline</Dropdown.Item>
                          <Dropdown.Item className="selected-category" onClick={() => this.handleVoucherType({ id: 1, name: "Online" })}>Online</Dropdown.Item>
                          <Dropdown.Item className="selected-category" onClick={() => this.handleVoucherType({ id: 2, name: "Both" })}>Both</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </> : <>
                      <div className="col-3 filterkey-btn d-flex justify-content-between align-items-center px-2 ml-0 ml-md-1">
                        <span className="px-2">{this.state?.selectedType?.name}</span>
                        <span className="ml-2">
                          <img src={ic_clear} alt="close-btn" style={{ width: "10px" }} className="cursor-pointer"
                            onClick={() => { this.resetVoucherType() }}
                          />
                        </span>
                      </div>

                    </>
                  }

                  {
                    this.props.discountData &&
                      this.state?.selectedFilterRange === "" ?
                      <>
                        <Dropdown className="col-4 col-md-3 ml-md-0 p-0 pl-md-1 dropdown">
                          <Dropdown.Toggle variant="light" id="filter-dropdown" className="w-100 d-flex justify-content-between align-items-center">
                            Discounts
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {this.state?.customerDiscountFilter &&
                              this.state.customerDiscountFilter.map((item, index) => {
                                return (
                                  <Dropdown.Item eventKey={item.key} onClick={(e) => this.handleDiscountStatusFilter(item.key, item.value)} className="selected-category">{item.value}</Dropdown.Item>
                                )
                              })
                            }
                          </Dropdown.Menu>
                        </Dropdown>

                      </>
                      :
                      this.props.discountData &&
                      <>
                        <div className="col-4 col-md-3 filterkey-btn d-flex justify-content-between align-items-center px-2 ml-0 ml-md-1">
                          <span className="px-2">{this.state?.selectedFilterRange}</span>
                          <span className="ml-2">
                            <img
                              src={ic_clear}
                              alt="close-btn"
                              style={{ width: "10px" }}
                              className="cursor-pointer"
                              onClick={() => { this.resetDiscountFilter() }}
                            />
                          </span>
                        </div>
                      </>
                  }

                </div>
                <div className="col-12 col-md-4">
                  <img
                    className="shopping-search-img"
                    src={voucherSearch}
                    alt="search"
                  />
                  <input
                    className="shopping-search w-100 mt-md-0 p-2 my-2 pl-5"
                    type="text"
                    placeholder="Search by your favourite brand names"
                    value={this.state.voucherSearchParam}
                    onChange={(e) => this.searchVouchers(e)}
                  />
                </div>

              </div>

              {this.props.isFetchingVouchers ? (
                  <this.VoucherShimmer />
                ) : this.props.vouchers.length === 0 ? (
                  <this.NoVoucher />
                ) : (
                  <this.Vouchers />
                )}
              <div className="row">
                <div className="col-12 text-center">
                  {this.props.isFetchingNextPage && <InlineLoader />}
                  {!this.props.nextPage && this.props.vouchers.length > 0 && (
                    <p className="font-weight-bold">No More Vouchers</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  NoVoucher = (props) => {
    return (
      <div className="empty-state">
        <EmptyState
          title={
            <FormattedMessage
              id="voucher.no-voucher"
              defaultMessage="No Voucher found."
            />
          }
          imageWidth={35}
          image={EmptyImage}
        />
      </div>
    );
  };

  handleDiscountStatusFilter = (name, value) => {
    // const { name } = e.target;
    // console.log(name);
    this.setState({ selectedFilterRange: value })
    this.setState((prevState) => {
      return {
        discountFilter: {
          ...prevState.discountFilter,
          [name]: !prevState.discountFilter[name],
        },
      };
    });


    setTimeout(() => {
      let str = "";
      let selectedStatus = Object.entries(this.state?.discountFilter).filter(
        ([key, value]) => value === true
      );
      Object.values(selectedStatus).map(
        (value) => (str = str.concat(value[0], "&"))
      );
      this.setState({ discountStatus: str });
      this.props.updateVouchers(
        this.props.selectedCategories,
        this.state.discountStatus,
        this.state.selectedType?.id
      );
    }, 300);
  };

  FiltersBlock = (props) => {
    return (
      <React.Fragment>
        <div className="row mb-3 status border-bottom">
          <div className="col-6 pl-0">
            <span className="font-proxima-nova-bold h5">
              <FormattedMessage id="general.filters" defaultMessage="Filters" />
            </span>
          </div>
          <div className="col-6 pr-0">
            <button
              className="btn btn-outline float-right"
              onClick={() => {
                this.props.resetVoucherFilter();
              }}
              style={{ color: "#087CD8", marginTop: "-0.5rem" }}
            >
              <FormattedMessage id="general.reset" defaultMessage="Reset" />
            </button>
          </div>
        </div>
        {/* {this.DiscountFilters()} */}
        <div className="row">
          <div className="col-12">
            <p className="mb-2 mt-2 text-uppercase font-proxima-nova-bold">
              <FormattedMessage id="order.segment" defaultMessage="SEGMENT" />
            </p>
          </div>
        </div>
        {/* <this.Filters /> */}
        {this.Filters()}
      </React.Fragment>
    );
  };

  DiscountFilters = (props) => {
    return (
      <React.Fragment>
        <div className="border-bottom mt-3 pb-2 status">
          <p className="text-uppercase">
            <strong>
              <FormattedMessage
                id="vouchers.discount"
                defaultMessage="Discounts"
              />
            </strong>
          </p>

          {this.state?.customerDiscountFilter &&
            this.state?.customerDiscountFilter?.map((item, index) => {
              return (
                <div className="d-flex mb-3" key={"dicount-" + index}>
                  <span>{item.value}</span>
                  <div className="custom-control custom-checkbox ml-auto ">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={"dicount-" + item.key}
                      onChange={(e) =>
                        this.handleDiscountStatusFilter(e, item.key)
                      }
                      name={item.key}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={"dicount-" + item.key}
                    ></label>
                  </div>
                </div>
              );
            })}
        </div>
      </React.Fragment>
    );
  };

  Filters = (props) => {
    return (
      <React.Fragment>
        {this.props.isFetchingVoucherCategories ? (
          <div className="row">
            <div className="col-md-12">
              <ContentLoader
                height={400}
                width={200}
                speed={2}
                primaryColor="#F6F7F8"
                secondaryColor="#ecebeb"
              >
                <rect x="0" y="0" rx="2" ry="2" width="132" height="16" />
                <rect x="180" y="0" rx="2" ry="2" width="16" height="16" />
                <rect x="0" y="24" rx="2" ry="2" width="132" height="16" />
                <rect x="0" y="48" rx="2" ry="2" width="132" height="16" />
                <rect x="0" y="72" rx="2" ry="2" width="132" height="16" />
                <rect x="0" y="96" rx="2" ry="2" width="132" height="16" />
                <rect x="0" y="120" rx="2" ry="2" width="132" height="16" />
                <rect x="0" y="144" rx="2" ry="2" width="132" height="16" />
                <rect x="0" y="168" rx="2" ry="2" width="132" height="16" />
                <rect x="0" y="192" rx="2" ry="2" width="132" height="16" />
                <rect x="0" y="216" rx="2" ry="2" width="132" height="16" />
                <rect x="0" y="240" rx="2" ry="2" width="132" height="16" />
                <rect x="0" y="264" rx="2" ry="2" width="132" height="16" />
                <rect x="0" y="288" rx="2" ry="2" width="132" height="16" />
                <rect x="0" y="312" rx="2" ry="2" width="132" height="16" />
                <rect x="0" y="336" rx="2" ry="2" width="132" height="16" />
                <rect x="0" y="360" rx="2" ry="2" width="132" height="16" />
                <rect x="180" y="24" rx="2" ry="2" width="16" height="16" />
                <rect x="180" y="48" rx="2" ry="2" width="16" height="16" />
                <rect x="180" y="72" rx="2" ry="2" width="16" height="16" />
                <rect x="180" y="96" rx="2" ry="2" width="16" height="16" />
                <rect x="180" y="120" rx="2" ry="2" width="16" height="16" />
                <rect x="180" y="144" rx="2" ry="2" width="16" height="16" />
                <rect x="180" y="168" rx="2" ry="2" width="16" height="16" />
                <rect x="180" y="192" rx="2" ry="2" width="132" height="16" />
                <rect x="180" y="216" rx="2" ry="2" width="132" height="16" />
                <rect x="180" y="240" rx="2" ry="2" width="132" height="16" />
                <rect x="180" y="264" rx="2" ry="2" width="132" height="16" />
                <rect x="180" y="288" rx="2" ry="2" width="132" height="16" />
                <rect x="180" y="312" rx="2" ry="2" width="132" height="16" />
                <rect x="180" y="336" rx="2" ry="2" width="132" height="16" />
                <rect x="180" y="360" rx="2" ry="2" width="132" height="16" />
              </ContentLoader>
            </div>
          </div>
        ) : (
          <div
            style={{
              height: "600px",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            {this.props.categories.map((item, index) => {
              return (
                <div
                  className="row mt-3 align-item-center"
                  key={"voucher-category-box-" + index}
                >
                  <div className="col-9">
                    <h3 className="filter-voucher">{item.value}</h3>
                  </div>
                  <div className="col-3 mb-1 text-center">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={"voucher-" + item.key}
                        name={item.value}
                        checked={
                          lodash.indexOf(
                            this.props.selectedCategories,
                            item.key
                          ) > -1
                        }
                        onChange={(event) => {
                          this.onChange(event, item.key);
                        }}
                      />
                      <label
                        className="custom-control-label cursor-pointer"
                        htmlFor={"voucher-" + item.key}
                      ></label>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </React.Fragment>
    );
  };

  VoucherShimmer = (props) => {
    return (
      <React.Fragment>
        <div className="row mt-3">
          <div className="col-4 col-md-2">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
          <div className="col-4 col-md-2">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
          <div className="col-4 col-md-2">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
          <div className="col-4 col-md-2">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
          <div className="col-4 col-md-2">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
          <div className="col-4 col-md-2">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
        </div>
        
        <div className="row">
          <div className="col-4 col-md-2">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
          <div className="col-4 col-md-2">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
          <div className="col-4 col-md-2">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
          <div className="col-4 col-md-2">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
          <div className="col-4 col-md-2">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
          <div className="col-4 col-md-2">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
        </div><div className="row">
          <div className="col-4 col-md-2">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
          <div className="col-4 col-md-2">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
          <div className="col-4 col-md-2">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
          <div className="col-4 col-md-2">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
          <div className="col-4 col-md-2">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
          <div className="col-4 col-md-2">
            <div className="box shine mb-3 mb-md-4"></div>
          </div>
        </div>
        
      </React.Fragment>
    );
  };

  getVoucherDetail = (voucher, index) => {
    if (voucher.is_internal > 0) {
      this.setState({
        isMultipleVoucherAvailable: true,
        selected: index,
        redirect: false,
      });
    } else {
      this.setState({
        isMultipleVoucherAvailable: false,
        selected: index,
        redirect: true,
      });
    }
  };

  toggleBookmark = (event, id, isBookmarked) => {
    event.preventDefault();
    event.stopPropagation();
    this.onToggleBookmark(id, isBookmarked, `/vouchers/${id}/bookmark`);
  };

  onToggleBookmark = (id, isBookmarked, url) =>
    !isBookmarked
      ? this.props.addBookmark(id, url, this.props.vouchers)
      : this.props.deleteBookmark(id, url, this.props.vouchers);
  
  Vouchers = (props) => {
    return (
      <React.Fragment>
        <div className="row mt-3">
          {this.props.vouchers.map((item, index) => {
            let name, imageSrc;
            if (item.cashback_type === "fixed_value" && item.cashback !== 0) {
              name = `CASHBACK ₹${item.cashback}`;
              imageSrc = voucherCashback;
            } else if (
              item.cashback_type === "percentage" &&
              item.cashback !== 0
            ) {
              name = `CASHBACK ${item.cashback}%`;
              imageSrc = offer;
            } else if (item.customer_discount > 0) {
              name = `${item.customer_discount}% OFF`;
              imageSrc = offer;
            } else {
              name = `WINDS Coins`;
              imageSrc = offer;
            }
            return (
              <div
                className="col-4 col-md-2 voucher-card-padding"
                key={"voucher-card-" + index}
              >
                <div
                  style={{
                    cursor: "pointer",
                    overflow: "hidden",
                    border: "1px solid #F5F2FF",
                  }}
                  className="card mb-4 card--carousel-item card--carousel-item--vouchers voucher-bg"
                  onClick={() => this.getVoucherDetail(item, index)}
                >
                  <img
                    src={item.bookmarked ? bookmarkActive : bookmarkInactive}
                    className="bookmark"
                    alt="bookmark"
                    onClick={(event) =>
                      this.toggleBookmark(event, item.id, item.bookmarked)
                    }
                  />
                  <div
                    className={`card-body p-0`}
                    style={{ borderRadius: "8px", position: "relative" }}
                  >
                    <img
                      className="item-logo"
                      src={item.web_image_url}
                      alt={item.name}
                      title={item.name}
                      style={{
                        position: "absolute",
                        left: "0",
                        top: "0",
                        bottom: "0",
                        right: "0",
                        margin: "auto",
                      }}
                    />
                    <img
                      className="winds-logo"
                      src={windsLogo}
                      alt="windsLogo"
                      title="windsLogo"
                    />
                  </div>
                  <div
                    className="card-bottom d-flex justify-content-center align-items-center p-2"
                    style={{
                      background: "#ECE6FF",
                      borderRadius: "5px",
                      position: "unset",
                      bottom: "0",
                      width: "100%",
                      borderTopLeftRadius: "0",
                      borderTopRightRadius: "0",
                    }}
                  >
                    <span className="voucher-percent m-0 d-flex align-items-center">
                      <img
                        src={imageSrc}
                        alt="offer"
                        title="offer"
                        style={{ height: "auto", marginRight: "5px" }}
                      />
                      {name}
                    </span>
                  </div>
                </div>
                {this.state.redirect &&
                  this.state.selected === index &&
                  (item.slug ? (
                    <Redirect push to={`/voucher/${item?.slug}`} />
                  ) : (
                    <Redirect push to={`/voucher/${item?.id}`} />
                  ))}

                {this.state.isMultipleVoucherAvailable &&
                  this.state.selected === index && (
                    <SubVouchers
                      parentId={item.id}
                      onClose={() =>
                        this.setState({ isMultipleVoucherAvailable: false })
                      }
                    />
                  )}
              </div>
            );
          })}
        </div>
      </React.Fragment>
    );
  };
}

function mapStateToProps(state) {
  return {
    isFetchingVoucherCategories: state.voucher.isFetchingVoucherCategories,
    categories: state.voucher.categories,
    selectedCategories: state.voucher.selectedCategories,
    isFetchingVouchers: state.voucher.isFetchingVouchers,
    isFetchingInitialVouchers: state.voucher.isFetchingInitialVouchers,
    vouchers: state.voucher.vouchers,
    windsVoucher: state.voucher.windsVoucher,
    isFetchingNextPage: state.voucher.isFetchingNextPage,
    nextPage: state.voucher.nextPage,
    isVoucherFilterModelOpen: state.voucher.isVoucherFilterModelOpen,
    discountData: state.voucher.discountData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetFilterOnLoad: () => {
      dispatch(resetVoucherFilter());
    },
    initCategory: () => {
      dispatch(fetchingVoucherCategories());

      voucherClientX.get("v1/filters").then((response) => {
        let data = response.data.data.segment;

        dispatch(setVoucherCategories(data));
      });
    },
    initVouchers: (categories, discount) => {
      dispatch(fetchingVouchers());
      dispatch(fetchingInitialVouchers());

      voucherClientX
        .get("v3/vouchers", {
          params: {
            segment: categories,
            discount_percentage: discount,
            banner_platform: "web",
            banner_tags: "customer_web",
          },
        })
        .then((response) => {
          let data = response.data.data;
          let discountData = response.data.discount_data;
          let banner_data = response.data.banner_data;
          
          
      

          let windsVoucher = response.data.winds_gift_voucher_data;
          let nextPageURL =
            null !== response.data.links.next &&
            new URL(response.data.links.next);

          let nextPage =
            null !== response.data.links.next &&
            nextPageURL.searchParams.get("page");
          dispatch(setWindsGiftCard(windsVoucher));
          dispatch(setVouchers(data, nextPage, discountData));
          dispatch(setVouchersBannerData(banner_data));
        });
    },
    searchVouchers: (searchParam, categories, discount) => {
      dispatch(fetchingVouchers());
      customerCommonClientX
        .get(`v2/search/voucher?limit=30&q=${searchParam}`)
        .then((response) => {
          let data = response.data.data;

          let nextPageURL =
            null !== response.data.links.next &&
            new URL(response.data.links.next);

          let nextPage =
            null !== response.data.links.next &&
            nextPageURL.searchParams.get("page");

          dispatch(setSearchVouchers(data, nextPage));
        });
    },

    updateVouchers: (categories, discount, selected_type) => {
      dispatch(fetchingVouchers());

      voucherClientX
        .get("v3/vouchers", {
          params: {
            segment: categories,
            discount_percentage: discount,
            redeem_type: selected_type
          },
        })
        .then((response) => {
          
          let data = response.data.data;
          let discountData = response.data.discount_data;

          let nextPageURL =
            null !== response.data.links.next &&
            new URL(response.data.links.next);

          let nextPage =
            null !== response.data.links.next &&
            nextPageURL.searchParams.get("page");

          dispatch(setVouchers(data, nextPage, discountData));
        });
    },
    onSelectCategory: (id) => {
      dispatch(selectCategoryForVouchers(id));
    },
    onUnselectCategory: (id) => {
      dispatch(unselectCategoryForVouchers(id));
    },
    onVouchersFilterClick: () => {
      dispatch(onVouchersFilterClick());
    },
    resetVoucherFilter: () => {
      dispatch(resetVoucherFilter());
    },
    onPaginatedSearch: (categories, nextPage, discount, selected_type) => {
      dispatch(fetchingNextPage());

      voucherClientX
        .get("v3/vouchers", {
          params: {
            segment: categories,
            page: nextPage,
            discount_percentage: discount,
            redeem_type: selected_type
          },
        })
        .then((response) => {
          
          let data = response.data.data;

          let nextPageURL =
            null !== response.data.links.next &&
            new URL(response.data.links.next);

          let nextPage =
            null !== response.data.links.next &&
            nextPageURL.searchParams.get("page");

          dispatch(addVouchers(data, nextPage));
        });
    },
    addBookmark: (id, url, vouchers) => {
      voucherClientX
        .post(url, {})
        .then((response) => {
          dispatch(setVoucherListAfterBookmarkToggle(id, vouchers));
        })
        .catch((error) => { });
    },

    deleteBookmark: (id, url, vouchers) => {
      voucherClientX
        .delete(url)
        .then((response) => {
          dispatch(setVoucherListAfterBookmarkToggle(id, vouchers));
        })
        .catch((error) => { });
    },
  

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VouchersList);
