
export const VehicleNumberRejex = /^[A-Za-z]{2}[-]?[0-9]{2}[-]?[A-Za-z]{1,2}[-]?[0-9]{4}$/;
export const ModelNumberRegex=/^[a-zA-Z0-9\s-]{1,50}$/;
export const CompanyNameRegex=/^[a-zA-Z0-9\s,.'-]{2,50}$/;
export const ContactNumberRegex=/^[6-9]\d{9}$/;
export const NameRegex=/^[A-Za-z\s]+$/;
export const EmailRegex=/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const validateRegex = (value, regex) => {    
    return regex.test(value);
};