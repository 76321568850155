import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import VerifyMobileFormDialog from "../components/verifyMobileFormDialog";
import VerifyEmailFormDialog from "../components/verifyEmailFormDialog";
import unverifiedIcon from "../../../assets/ic_unverified.svg";
import verifiedIcon from "../../../assets/ic_verified.svg";
import verifyIllustration from "../../../assets/verify-details.svg";
import { connect, useSelector } from "react-redux";

const VerificationInfo = (props) => {
  const {profile} = useSelector((state)=> state?.global)
  const [canEditMobile, setEditMobile] = useState(false);
  const [canEditEmail, setEditEmail] = useState(false);
  const [id, setId] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [is_mobile_verified, setMobileVerified] = useState("false");
  const [is_email_verified, setEmailVerified] = useState("false");
  useEffect(() => {
    getProfileInfo();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editMobile = () => {
    setEditMobile(true);
  };
  const closeMobileFormModel = (is_verified) => {
    setEditMobile(false);
    if (is_verified) {
      setMobileVerified(true);
      props.history.push(`/rewards/vouchers/${id}`);
    }
  };

  const closeEmailFormModel = (is_verified) => {
    setEditEmail(false);
    if (is_verified) {
      setEmailVerified(true);
      //   props.history.push(`/rewards/vouchers/${id}`);
    }
  };

  const editEmail = () => {
    setEditEmail(true);
  };

  const getProfileInfo = () => {
    const id = props.match.params.id;
    setId(id);
    const { email, mobile, email_verified, mobile_verified } = props.profile; //response.data.data;
    if (email_verified && mobile_verified) {
      props.history.push(`/rewards/vouchers/${id}`);
    }
    setEmail(email);
    setMobile(mobile);
    setEmailVerified(email_verified);
    setMobileVerified(mobile_verified);
  };
  return (
    <React.Fragment>
      <div className="container-fluid mt-4 mt-md-5 mb-4 mb-md-5">
        <div className="row mb-3 mt-md-4 mb-md-4">
          <div className="col-12">
            <small>
              <Link to="/">
                <FormattedMessage id="breadcrumbs.home" defaultMessage="Home" />
              </Link>
            </small>{" "}
            /{" "}
            <small>
              <Link to="/rewards">
                <FormattedMessage
                  id="navbar.rewards"
                  defaultMessage="Cahback-Rewards"
                />
              </Link>
            </small>{" "}
            /{" "}
            <small>
              <strong>
                <FormattedMessage
                  id="rewards.verify-details"
                  defaultMessage="Verify Details"
                />
              </strong>
            </small>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-6 mt-2 mb-3 font-proxima-nova">
            <div
              className="card mt-4"
              style={{
                backgroundColor: "#F8F9FA",
                borderRadius: "10px",
                border: "none",
              }}
            >
              <div className="card-body">
                <div className="row">
                  <div className="col-8">
                    <p className="mb-1" style={{ fontSize: "1rem" }}>
                      <strong>
                        <FormattedMessage
                          id="profile.mobile"
                          defaultMessage="Mobile"
                        />
                      </strong>
                    </p>
                  </div>
                  <div className="col-4 text-right">
                    {is_mobile_verified ? (
                      <img src={verifiedIcon} alt="verify-icon" title="verify-icon" />
                    ) : (
                      <p
                        className="mb-1 winds-link-color "
                        style={{ fontSize: "1rem", cursor: "pointer" }}
                        onClick={editMobile}
                      >
                        {mobile ? (
                          <FormattedMessage
                            id="rewards.verify"
                            defaultMessage="Verify"
                          />
                        ) : (
                          <FormattedMessage
                            id="rewards.update"
                            defaultMessage="Update"
                          />
                        )}
                      </p>
                    )}
                    {canEditMobile && (
                      <VerifyMobileFormDialog
                        mobile={mobile}
                        closeMobileFormModel={(is_verified) =>
                          closeMobileFormModel(is_verified)
                        }
                      />
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-8">
                    <p
                      className="mb-1 text-secondary"
                      style={{ fontSize: ".9rem" }}
                    >
                      {mobile}
                    </p>
                  </div>
                  <div className="col-4 text-right">
                    {!is_mobile_verified && (
                      <img src={unverifiedIcon} alt="unverified icon." title="unverified icon." />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="card mt-4"
              style={{
                backgroundColor: "#F8F9FA",
                borderRadius: "10px",
                border: "none",
              }}
            >
              <div className="card-body">
                <div className="row">
                  <div className="col-8">
                    <p className="mb-1" style={{ fontSize: "1rem" }}>
                      <strong>
                        <FormattedMessage
                          id="profile.email"
                          defaultMessage="Email"
                        />
                      </strong>
                    </p>
                  </div>
                  <div className="col-4 text-right">
                    {is_email_verified ? (
                      <img src={verifiedIcon} alt="verify-icon" title="verify-icon" />
                    ) : (
                      <p
                        className="mb-1 winds-link-color "
                        style={{ fontSize: "1rem", cursor: "pointer" }}
                        onClick={editEmail}
                      >
                        {email ? (
                          <FormattedMessage
                            id="rewards.verify"
                            defaultMessage="Verify"
                          />
                        ) : (
                          <FormattedMessage
                            id="rewards.update"
                            defaultMessage="Update"
                          />
                        )}
                      </p>
                    )}

                    {canEditEmail && (
                      <VerifyEmailFormDialog
                        email={email}
                        closeEmailFormModel={(is_verified) =>
                          closeEmailFormModel(is_verified)
                        }
                      />
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-10">
                    <p
                      className="mb-1 text-secondary"
                      style={{ fontSize: ".9rem" }}
                    >
                      {profile?.email}
                    </p>
                  </div>
                  <div className="col-2 text-center">
                    {!is_email_verified && (
                      <img src={unverifiedIcon} alt="unverified icon." title="unverified icon." />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 text-center">
            <img
              src={verifyIllustration}
              alt="users verifying their contact information - illustration."
              title="users verifying their contact information - illustration."
              className="verify-img"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  return {
    profile: state.global.profile,
  };
}

export default connect(mapStateToProps, null)(VerificationInfo);
