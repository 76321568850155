import React from "react";
import ContentLoader from "react-content-loader";
export const CoverPageSkeleton = (props) => {
   const { rows, columns, width, height } = props;
    const padding = props.padding || 5;
    const speed = 1
    const coverWidth = width;
    const coverHeight =height;
    const coverHeightWithPadding = coverHeight + padding
    const coverWidthWithPadding = coverWidth + padding
    const initial = 0
    const covers = Array(columns * rows).fill(1)
  return (
    <ContentLoader
      speed={speed}
      width={columns * coverWidthWithPadding}
      height={rows * coverHeightWithPadding}
      primaryColor="#F6F7F8"
      secondaryColor="#ecebeb"
      {...props}
    >

      {covers.map((g, i) => {
        let vy = Math.floor(i / columns) * coverHeightWithPadding + initial
        let vx = (i * coverWidthWithPadding) % (columns * coverWidthWithPadding)
        return (
          <rect
            x={vx}
            y={vy}
            rx="0"
            ry="0"
            width={coverWidth}
            height={coverHeight}
            key={i}
          />
        )
      })}
    </ContentLoader>
  );
};

