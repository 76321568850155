import React from "react";
import { useState } from "react";
import { Card, Button, Accordion, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import arrow from "../../assets/img/about/arrow.png"

function BusinessReadMore(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="site-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter text-center">
          <FormattedMessage id="page.nav-winds-partner" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul>
          <li><FormattedMessage id="partner.section4.read-more-desc1" /></li>
          <li><FormattedMessage id="partner.section4.read-more-desc2" /></li>
          <li><FormattedMessage id="partner.section4.read-more-desc3" /></li>
        </ul>
      </Modal.Body>
    </Modal>
  );
}
const PartnerApp = () => {
  const [style, setStyle] = useState(false);
  const changeStyle = () => {
    style === false ? setStyle(true) : setStyle(false);
  };
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <section className="about-sec-1 site-partner site-page site-top">
        <div className="container font-proxima-nova">
          <div className="row reverse-col">
            <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 d-flex flex-column justify-content-center">
              <img width="100%" src={require('../../assets/img/site-partnerImage/ic_illustration_Partner.png')} alt="About Partner" title="About Partner" className="pr-md-5 pr-0 mt-md-0 mt-3" />
            </div>
            <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 d-flex flex-column justify-content-center">
              <h1 className="title-captain"><FormattedMessage id="partner.section1.title" /></h1>
              <p className="desc"><FormattedMessage id="partner.section1.desc" /></p>
              <h2 className="mt-4 mb-3 download-patron"><FormattedMessage id="partner.section1.subtitle" /></h2>
              <div className="paystore">
                <a href="https://p.winds.to/app" target="blank">
                  <img className="icons" src={require('../../assets/img/site-patrons/ic_google_pay.png')} alt="icon" title="icon" />
                </a>
                <a href="https://p.winds.to/app" target="blank">
                  <img className="icons" src={require('../../assets/img/site-patrons/ic_app_store.png')} alt="About Partner" title="About Partner" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-sec-2 site-page">
        <div className="container font-proxima-nova">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <h1 className="title-captain"><FormattedMessage id="partner.section2.title" /></h1>
              <p className="desc"><FormattedMessage id="partner.section2.desc1" /></p>
              <p className="desc"><FormattedMessage id="partner.section2.desc2" /> </p>
              <p className="desc"><FormattedMessage id="partner.section2.desc3" /></p>
              <p className="desc"><FormattedMessage id="partner.section2.desc4" /></p>
              <p className="desc"><FormattedMessage id="partner.section2.desc5" /></p>
              <Accordion defaultActiveKey="0">
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="1" onClick={changeStyle}>
                      <FormattedMessage id="partner.section2.mini-monthly-draw" />
                      <img src={arrow} width="15" style={{ marginLeft: "5px", style }} alt="arrow" title="arrow" className={`${style ? "down-arrow" : "up-arrow"}`} />
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <p className="desc"><FormattedMessage id="partner.section2.mini-monthly-draw-desc" /></p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <img width="100%" src={require('../../assets/img/site-partnerImage/ic_partner_shop.png')} alt="About Partner" title="About Partner" />
            </div>
          </div>
        </div>
      </section>
      <section className="about-sec-3redirect site-page">
        <div className="container font-proxima-nova">
          <div className="row reverse-col">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <img width="100%" src={require('../../assets/img/site-partnerImage/ic_partner_referral.png')} alt="About Partner" title="About Partner" className="px-5 mt-md-0 mt-3" />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <h1 className="title-captain"><FormattedMessage id="partner.section3.title" /></h1>
              <p className="desc"><FormattedMessage id="partner.section3.desc1" /></p>
              <p className="desc"><FormattedMessage id="partner.section3.desc2" /></p>
            </div>
          </div>
        </div>
      </section>
      <section className="about-sec-4 site-page">
        <div className="container font-proxima-nova">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <h1 className="title-captain"><FormattedMessage id="partner.section4.title" /></h1>
              <p className="desc" ><FormattedMessage id="partner.section4.desc" /></p>
              <p className="sub-title" style={{ "fontWeight": "600", cursor: "pointer" }} onClick={() => setModalShow(true)}>
                {/* <FormattedMessage id="partner.section4.read-more" /> */}
                <img src={arrow} width="15" style={{ marginLeft: "5px" }} alt="arrow" title="arrow" />
              </p>
              <BusinessReadMore
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
              <h1 className="download-patron pt-2"><FormattedMessage id="partner.section4.subtitle" /></h1>
              <p className="desc"><FormattedMessage id="partner.section4.subtitle-desc" /></p>
              <h1 className="download-patron pt-3" style={{ textDecoration: "underline" }}>
                <Link to="/partner-faq">
                  <FormattedMessage id="partner.faq" />
                </Link>
              </h1>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
              <img width="100%" src={require('../../assets/img/site-partnerImage/ic_partner_mockup.png')} alt="About Partner" title="About Partner" className="pr-md-5 pr-0 mt-md-0 mt-3" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default PartnerApp;
