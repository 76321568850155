import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import customerAffilateClientX from "../../../utils/api/customerAffiliateClientX";
import PreviewDownloadedFiles from "../components/previewDownloadedFiles";
import { OrderStatusLabel } from "../../../utils/helper";
export default class OrderDetail extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      order: null,
      isModelOpen: false,
    };
  }

  viewUploadBill = () => {
    this.setState({ isModelOpen: true });
  };

  closeModelHandler = () => {
    this.setState({ isModelOpen: false });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
    const {
      match: { params },
    } = this.props;
    customerAffilateClientX.get(`api/v1/bills/${params.id}`).then((response) => {
      let data = response.data.data;

      if (this._isMounted) {
        this.setState({
          order: data,
        });
      }
    });
  }

  cancelBill = (id) => {
    customerAffilateClientX
      .post(`/api/v1/bills/${id}/cancel`, {})
      .then((response) => {
        this.componentDidMount();
      });
  };

  Shimmer = (props) => {
    return (
      <div className="container-fluid py-3 py-md-5">
        <div className="row">
          <div className="col-12 col-md-6 offset-md-3">
            <div className="box shine box-voucher-detail-logo"></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { isModelOpen, order } = this.state;
    return (
      order === null ?
        <this.Shimmer />
        :
        null !== order && (
          <div className="container-fluid">
            <div className="row mt-3 mb-3 mt-md-4 mb-md-4 align-items-center">
              <div className="col-12 text-md-center">
                <small>
                  <Link to="/">
                    <FormattedMessage
                      id="breadcrumbs.home"
                      defaultMessage="Home"
                    />
                  </Link>
                </small>{" "}
                /{" "}
                <small>
                  <Link to="/orders">
                    <FormattedMessage id="navbar.orders" />
                  </Link>
                </small>{" "}
                /{" "}
                <small>
                  <strong>
                    <FormattedMessage id="orders.order" />
                  </strong>
                </small>
              </div>
            </div>
            <div className="row mb-3 mb-md-4">
              <div className="col-md-6 offset-md-3">
                <div className="list-group">
                  <div className="list-group-item list-group-item-action text-center text-md-left d-flex align-items-center justify-content-between">
                    <div className="col-3 col-md-3">
                      <img
                        className="mt-2 mt-md-0 p-2 border-0"
                        src={order.image}
                        style={{
                          background: "#fff",
                          width: "inherit",
                          // height: "60px",
                          border: "1px solid rgba(0, 0, 0, 0.125)",
                          display: "inline",
                          boxSizing: "content-box",
                          borderRadius: "0.25rem",
                          marginRight: "15px",
                        }}
                        alt={order.name}
                        title={order.name}
                      />
                    </div>
                    <div className="col-9 col-md-9 d-flex flex-column">
                      <p className="d-block d-md-inline mt-1 mt-md-0 mb-0">
                        <FormattedMessage id="order.purchased-from" />{" "}
                        {order.name}{" "}
                        <strong>{order.voucher_name}</strong>
                      </p>
                      <p className="d-block d-md-inline mt-1 mt-md-0 mb-0">
                        Transacted on{" "}
                        <strong>{order.transacted_on}</strong>
                      </p>
                    </div>
                  </div>
                  {isModelOpen && (
                    <PreviewDownloadedFiles
                      closePreview={() => this.closeModelHandler()}
                      bills={order.transaction_multiple_image_urls}
                    />
                  )}

                  <div className="list-group-item list-group-item-action">
                    <div className="row align-items-center">
                      <div className="col-md-6">
                        <OrderStatusLabel order={order} />
                      </div>
                      <div className="col-md-6 text-md-right">
                        <p className="mb-0">
                          <FormattedMessage id="home.shopping-sites" />{" "}
                          <strong>{order.order_number}</strong>
                        </p>
                        <p className="mb-0">{order.created_at}</p>
                      </div>
                    </div>
                  </div>

                  {order.status_text === "Rejected" ?
                    <div className="list-group-item list-group-item-action">
                      <p className="mb-0">This bill has been {order.status_text} by the vendor!</p>
                    </div> :
                    <></>}

                  <div className="list-group-item list-group-item-action">
                    <p className="mb-0">
                      <FormattedMessage id="general.amount" /> Rs.{" "}
                      <strong>{order.amount}</strong>
                    </p>
                  </div>

                  {order.status_text !== "Rejected" ?
                    <div className="list-group-item list-group-item-action">
                      <p className="mb-0">
                        <strong>{order.wrp}</strong> WINDS Coins{" "}
                        {order.wrp_credited ? "credited" : "are pending"}
                      </p>
                    </div> :
                    <></>}

                </div>

                {order.admin_note ?
                  <div className="list-group-item list-group-item-action">
                    <p className="mb-2">{order.admin_note}</p>
                  </div> :
                  <></>}

                {order.rejected_reason ?
                  <div className="list-group-item list-group-item-action">
                    <p className="mb-0">{order.rejected_reason}</p>
                  </div> :
                  <></>}

                {order.product_name ?
                  <div className="list-group-item list-group-item-action">
                    <p className="mb-0">{order.product_name}</p>
                  </div> :
                  <></>}


                <div className="list-group-item list-group-item-action">
                  {order.auto_tracked ?
                    <p className="mb-0">Tracking Automatic</p> :
                    <p className="mb-0">Tracking Manual</p>
                  }
                </div>


                <div className="d-md-flex mt-4 justify-content-between">
                  {(order.can_cancel || "") && (
                    <button
                      className="btn btn-outline-primary home-btn mr-md-2 mr-0 mb-3 mb-md-0"
                      onClick={() => this.cancelBill(order.bill_id)}
                    >
                      <FormattedMessage
                        id="order.cancel-bill"
                        defaultMessage="Cancel bill"
                      />
                    </button>
                  )}

                  {order.transaction_multiple_image_urls && (
                    <button
                      className="btn btn-outline-primary home-btn"
                      onClick={this.viewUploadBill}
                    >
                      <FormattedMessage
                        id="order.view-bill"
                        defaultMessage="View Uploaded Bill"
                      />
                    </button>
                  )}
                </div>

              </div>
            </div>
          </div>
        )
    );
  }
}
