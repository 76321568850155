import Modal from "react-bootstrap/Modal";
import React, { Component } from "react";
import { shuffle } from "../../../utils/helper";
import { withRouter } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import WonCardTitle from "./wonCardTitle";
import fortune from "../../../assets/fortune_wheel.png"
import spinWheelSound from "../../../assets/spinwheel.mp3"
import logo from "../../../assets/logo-icon.png"
import spinwheel_border from "../../../assets/ic_spinwheel_frame.png"

class Wheel extends Component {
  state = {
    isPlayedAlready: false,
    winner: "",
    isFinished: false,
  };
  timerHandle = 0;
  timerDelay = 33;
  angleCurrent = 0;
  angleDelta = 0;
  size = 290;
  canvasContext = null;
  colors = [];
  segments = [];
  seg_color = [];
  // Cache of segments to colors
  maxSpeed = Math.PI / 16;
  upTime = 5000;
  // How long to spin up for (in ms)
  downTime = 17000;
  winningSegment = [];
  // How long to slow down for (in ms)
  spinStart = 0;
  frames = 0;
  centerX = 300;
  centerY = 300;
  constructor(props) {
    super(props);
    if (
      this.props.item.spin_wheel_options &&
      this.props.item.spin_wheel_options.length > 0
    ) {
      this.segments = this.props.item.spin_wheel_options.map((i) => i.name);
      this.winningSegment = this.props.item.spin_wheel_options.filter(
        (option, index) => option.id === this.props.item.spin_wheel_winning_id
      );
    }
    this.maxSpeed = Math.PI / this.segments.length;

    this.audio = new Audio(spinWheelSound);
  }
  playSound() {
    this.audio.play();
  }
  componentDidMount() {
    if (
      this.props.item.spin_wheel_options &&
      this.props.item.spin_wheel_options.length > 0
    ) {
      this.segments = this.props.item.spin_wheel_options.map((i) => i.name);
      this.seg_color = this.props.item.spin_wheel_options.map((i) => i.color)
    }
    this.wheelInit();
    this.wheelUpdate();

    //Hide the address bar (for mobile devices)!
    setTimeout(() => {
      window.scrollTo(0, 1);
    }, 0);
  }
  
  
  wheelInit = () => {
    this.initWheel();
    this.initCanvas();
    this.wheelDraw();
  };

  initWheel = () => {
    shuffle(this.colors);
  };
  initCanvas = () => {
    var canvas = document.getElementById("canvas");
    if (navigator.appVersion.indexOf("MSIE") !== -1) {
      canvas = document.createElement("canvas");
      canvas.setAttribute("width", 1000);
      canvas.setAttribute("height", 600);
      canvas.setAttribute("id", "canvas");
      document.getElementById("wheel").appendChild(canvas);
      //   canvas = G_vmlCanvasManager.initElement(canvas);
    }
    canvas.addEventListener("click", this.spin, false);
    this.canvasContext = canvas.getContext("2d");
  };

  spin = () => {
    this.setState({
      isPlayedAlready: true,
    });
    // Start the wheel only if it's not already spinning
    if (this.timerHandle === 0) {
      this.spinStart = new Date().getTime();
      //   this.maxSpeed = Math.PI / (this.segments.length + Math.random()); // Randomly vary how hard the spin is
      this.maxSpeed = Math.PI / this.segments.length;

      this.frames = 0;
      this.timerHandle = setInterval(this.onTimerTick, this.timerDelay);
    }
  };

  closeWheelModel = () => {
    this.props.closeWheelModelHandler(this.state.isFinished,false);
  };

  componentDidUpdate(prevProps) {
    if(this.props.activatedCard !== prevProps.activatedCard){
     this.setState({
       isFinished:true
     },()=>{
      this.playSound()
     })
    }
}

  onTimerTick = () => {
    this.frames++;
    this.draw();

    var duration = new Date().getTime() - this.spinStart;
    var progress = 0;
    var finished = false;
    if (duration < this.upTime) {
      progress = duration / this.upTime;
      this.angleDelta = this.maxSpeed * Math.sin((progress * Math.PI) / 2);
    } else {
      if (
        this.state.winner === this.winningSegment[0].name &&
        this.frames > this.segments.length
      ) {
        progress = duration / this.downTime;
        this.angleDelta =
          this.maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2);
        progress = 1;
      } else {
        progress = duration / this.downTime;
        //   progress = duration / this.downTime;
        this.angleDelta =
          this.maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2);

        this.angleCurrent += this.angleDelta;
      }

      // progress = duration / this.downTime;
      // this.angleDelta =
      //   this.maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2);
      if (progress >= 1) finished = true;
    }

    this.angleCurrent += this.angleDelta;
    while (this.angleCurrent >= Math.PI * 2)
      // Keep the angle in a reasonable range
      this.angleCurrent -= Math.PI * 2;

    if (finished) {
      this.props.finished();
      // this.setState(
      //   {
      //     isFinished: true,
      //   },
      //   () => {
      //     console.log((this.frames / duration) * 1000);
      //     this.props.finished();
      //     //   this.winningSegment[0].type !== "no-prize" && this.props.finished();
      //   }
      // );
      clearInterval(this.timerHandle);
      this.timerHandle = 0;
      this.angleDelta = 0;
      //  document.getElementById('counter').innerHTML = this.frames / duration * 1000 + " FPS";
      //   $("#counter").html((this.frames / duration * 1000) + " FPS");
    }

    /*
    // Display RPM
    var rpm = (wheel.angleDelta * (1000 / wheel.timerDelay) * 60) / (Math.PI * 2);
    $("#counter").html( Math.round(rpm) + " RPM" );
     */
  };

  wheelDraw = () => {
    this.clear();
    this.drawWheel();
    this.drawNeedle();
  };

  draw = () => {
    this.clear();
    this.drawWheel();
    this.drawNeedle();
  };

  drawSegment = (key, lastAngle, angle) => {
    var ctx = this.canvasContext;
    var centerX = this.centerX;
    var centerY = this.centerY;
    var size = this.size;

    var segments = this.segments;
    //   var len = this.segments.length;
    var colors = this.seg_color;

    var value = segments[key];
    ctx.save();
    ctx.beginPath();

    // Start in the centre
    ctx.moveTo(centerX, centerY);
    ctx.arc(centerX, centerY, size, lastAngle, angle, false); // Draw a arc around the edge
    ctx.lineTo(centerX, centerY); // Now draw a line back to the centre
    // Clip anything that follows to this area
    //ctx.clip(); // It would be best to clip, but we can double performance without it
    ctx.closePath();

    ctx.fillStyle = colors[key];
    ctx.fill();
    ctx.stroke();

    // Now draw the text
    ctx.save(); // The save ensures this works on Android devices
    ctx.translate(centerX, centerY);
    ctx.rotate((lastAngle + angle) / 2);

    ctx.fillStyle = "#ffffff";
    ctx.font = "bold 1.3em proxima-nova";
    ctx.fillText(value.substr(0, 100), size / 2 + 20, 0);
    ctx.restore();

    ctx.restore();
  };

  drawWheel = () => {
    var ctx = this.canvasContext;

    var angleCurrent = this.angleCurrent;
    var lastAngle = angleCurrent;
    //   var segments = this.segments;
    var len = this.segments.length;
    //   var colors = this.colors;
    //    var colorsLen = this.colors.length;

    var centerX = this.centerX;
    var centerY = this.centerY;
    var size = this.size;

    var PI2 = Math.PI * 2;

    ctx.lineWidth = 1;
    ctx.strokeStyle = "#131848";
    ctx.textBaseline = "middle";
    ctx.textAlign = "center";
    ctx.font = "1em proxima-nova";

    for (var i = 1; i <= len; i++) {
      var angle = PI2 * (i / len) + angleCurrent;
      this.drawSegment(i - 1, lastAngle, angle);
      lastAngle = angle;
    }
    // Draw a center circle
    ctx.beginPath();
    ctx.arc(centerX, centerY, 50, 0, PI2, false);
    ctx.closePath();
    ctx.fillStyle = "#fff";
    ctx.lineWidth = 15;
    ctx.strokeStyle = "#f6b819";
    ctx.fill();
    ctx.font = "bold 1em proxima-nova";
    ctx.fillStyle = "#f6b819";
    ctx.textAlign = "center";
    // ctx.fillText(
    //   this.props.intl.formatMessage({
    //     id: "rewards.spin-text",
    //     defaultMessage: "Spin",
    //   }),
    //   centerX,
    //   centerY + 3
    // );

    //draw logo on center
    var image = new Image();
    image.src = logo
    ctx.drawImage(image, centerX - 17, centerY - 25, 35, 45)

    ctx.stroke();

    // Draw outer circle
    ctx.beginPath();
    ctx.arc(centerX, centerY, size, 0, PI2, false);
    ctx.closePath();

    //draw spin wheel border
    var spinBorder = new Image();
    spinBorder.src = spinwheel_border
    if (spinBorder.complete) {
      ctx.drawImage(spinBorder, -25, -25, 650, 650)
    } else {
      spinBorder.onload = function () {
        ctx.drawImage(spinBorder, -25, -25, 650, 650)
      };
    }

    // ctx.lineWidth = 15;
    // ctx.strokeStyle = "#f2a302";
    // ctx.stroke();
  };

  drawNeedle = () => {
    var ctx = this.canvasContext;
    var centerX = this.centerX;
    var centerY = this.centerY;
    var size = this.size;

    // ctx.lineWidth = 1;
    // ctx.strokeStyle = "#ffffff";
    ctx.fileStyle = "#fff";

    ctx.beginPath();

    ctx.moveTo(centerX + 20, centerY - 50);
    ctx.lineTo(centerX - 20, centerY - 50);
    ctx.lineTo(centerX, centerY - 90);
    ctx.closePath();

    // ctx.stroke();
    ctx.fill();

    // Which segment is being pointed to?
    var change = this.angleCurrent + Math.PI / 2;
    var i =
      this.segments.length -
      Math.floor((change / (Math.PI * 2)) * this.segments.length) -
      1;

    if (i < 0) i = i + this.segments.length;
    // Now draw the winning name
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillStyle = "#fff";
    ctx.font = "bold 1.5em proxima-nova";
    this.state.isPlayedAlready &&
      ctx.fillText(this.segments[i], centerX + 10, centerY + size + 50);
    this.setState({ winner: this.segments[i] });
  };

  goToClaimPage = () => {
    this.props.closeWheelModelHandler(true,true);
  };

  clear = () => {
    var ctx = this.canvasContext;
    ctx.clearRect(0, 0, 1000, 800);
  };
  wheelUpdate = () => {};
  render() {
    return (
      <React.Fragment>
        <Modal
          show
          onHide={this.closeWheelModel}
          className="font-proxima-nova rewards"
          onEntered={this.props.modelEntered}
          size="lg"
          backdrop="static"
        >
          <Modal.Header closeButton className="border-0"></Modal.Header>

          <Modal.Body>
            <div id="wheel">
              <img className="fortune" src={fortune} alt="fortune" title="fortune" />
              <canvas
                id="canvas"
                width="600"
                height="900"
                style={{
                  pointerEvents: this.state.isPlayedAlready ? "none" : "auto",
                }}
              ></canvas>
              <div className="spin-details won">
                {this.state.isFinished &&
                  this.winningSegment[0].type !== "no-prize" &&
                  this.props.activatedCard && (
                    <React.Fragment>
                      <h5>
                        {" "}
                        <FormattedMessage
                          id="general.congrats"
                          defaultMessage="
                        Congratulations!"
                        />
                      </h5>
                      <p className="win-title">YOU WON{" "}
                        <WonCardTitle card={this.props.item.rewards[0]} />
                      </p>
                      <small>
                        {" "}
                        <FormattedMessage
                          id="rewards.won-on"
                          defaultMessage="
                        Won on"
                        />{" "}
                        {this.props.activatedCard.length > 0 &&
                          this.props.activatedCard[0].winning_date}
                      </small>
                    </React.Fragment>
                  )}
                {this.state.isFinished &&
                  this.props.item.rewards &&
                  this.props.item.rewards.length > 0 &&
                  this.props.item.rewards[0].type === "item" && (
                    <button
                      className="btn btn-primary home-btn claim-btn"
                      onClick={this.goToClaimPage}
                    >
                      <FormattedMessage
                        id="rewards.claim-your-prize"
                        defaultMessage="
                        Claim Your Prize"
                      />
                    </button>
                  )}
              </div>
              {!this.state.isPlayedAlready && (
                <div className="spin-details">
                  <h5>{this.props.item.source}</h5>
                  <h5>{this.props.item.description}</h5>
                  <small className="text-white">
                    <FormattedMessage
                      id="rewards.spin-the-wheel"
                      defaultMessage="Spin the wheel and stand a chance to win exciting rewards!"
                    />
                  </small>
                  <button
                    className="btn btn-primary home-btn claim-btn"
                    onClick={this.spin}
                  >
                    <FormattedMessage
                      id="rewards.spin-wheel"
                      defaultMessage="Spin the Wheel"
                    />
                  </button>
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}
export default withRouter(injectIntl(Wheel));
