import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
// useReducer, 
import OtpInput from 'react-otp-input';

import walletClientX from "../../../utils/api/walletClientX";
import { FormattedMessage } from "react-intl";
import { showToastMessage } from "../../../utils/helper";
const VerifyOTP = (props) => {

  const [otp, setOTP] = useState("");

  const handleChange = (otp) => {
    setOTP(otp)
  };

  const goBack = () => {
    props.onCloseVerifyOTPModal(false);
  }

  const resendOtp = () => {
    walletClientX.post(`/v1/withdraw/send-otp`).then((response) => {

      if (response.data.success) {
        showToastMessage(
          "success",
          <FormattedMessage id="wallet.otp-sent" defaultMessage="OTP Sent Successfully" />
        );
        setOTP("");
      }
    });

    setOTP("");
  }
  const submitWithdrawRequest = () => {
    props.onVerifyOTP(otp);
  };


  return (

    <Modal show onHide={props.onCloseVerifyOTPModal}>
      <Modal.Header closeButton className="border-0">
        <Modal.Title>
          <strong>
            <FormattedMessage
              id="wallet.otp-verification"
              defaultMessage="OTP Verification"
            />
          </strong>
        </Modal.Title>

      </Modal.Header>
      <Modal.Body className="text-center font-proxima-nova">
        <p className="text-center otp-text"> Enter the OTP sent to registered mobile number.</p>

        <OtpInput
          value={otp}
          onChange={handleChange}
          numInputs={6}
          separator={<span></span>}
          inputStyle={{
            width: "3rem",
            height: "3rem",
            margin: "0 1rem",
            fontSize: "2rem",
            borderRadius: 0,
            borderWidth: 0,
            borderBottomColor: '#203BCF',
            borderBottomWidth: 1,
          }}
        />

        <p></p>
        <p className="text-center otp-text">Didn’t receive the OTP ? &nbsp;
          <div className="resendOtp cursor-pointer" onClick={() => { resendOtp(); }} >Resend OTP</div></p>


        <div className="row">
          <div className="col">
            <button
              type="button"
              className="btn btn-primary home-btn mt-4"
              onClick={submitWithdrawRequest}
            >
              <FormattedMessage
                id="wallet.verify-submit"
                defaultMessage="VERIFY & SUBMIT"
              />
            </button>

            <button
              type="button"
              className="btn btn-primary home-btn mt-4"
              onClick={goBack}
            >
              <FormattedMessage id="general.back" defaultMessage="Back" />
            </button>
          </div>


        </div>


      </Modal.Body>

    </Modal>

  );


}

export default VerifyOTP;
