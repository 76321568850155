import React, { Component } from "react";
import billPayClientX from "../../../utils/api/billPayClientX";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import bpAssuredHorizontal from "../../../assets/billpay/ic_bp_logo_horizontal.svg"
import ic_transaction from "../../../assets/billpay/ic_transaction_id.svg"
import ic_calendar from "../../../assets/billpay/ic_calendar.svg"
import ic_reference from "../../../assets/billpay/ic_reference.svg"
import ic_reason from "../../../assets/billpay/ic_reason.svg"
import ic_success from "../../../assets/billpay/ic_success.png"
import { Modal } from "react-bootstrap";
import InlineLoader from "../../../components/InlineLoader";
import { toast } from "react-toastify";


class RiseComplaint extends Component {
  constructor(props) {
    super(props);

    this.state = {
      btnLoading: false,
      order: null,
      complaintReasonList: null,
      transactionID: "",
      BBPSReferenceNumber: "",
      date: "",
      complaintReasonSelected: "",
      show: false
    };
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;

    billPayClientX.get(`v1/orders/${params.id}`).then((response) => {
      let order = response.data.data;
      this.getComplaintReasonsList(order)
      this.getBBPSReferenceNumber(order)
      this.setState({
        order: order,
        transactionID: order.transaction_id,
        date: order.created_at.split(',')[0]
      })
    });
  }

  getBBPSReferenceNumber = (order) => {
    order?.biller_response &&
      // eslint-disable-next-line array-callback-return
      order.biller_response.map(item => {
        if (item.name === "BBPS Reference Number: ")
          this.setState({ BBPSReferenceNumber: item.value })
      })
  }

  getComplaintReasonsList = (order) => {
    order?.complaint_option &&
      // eslint-disable-next-line array-callback-return
      order.complaint_option.map(item => {
        if (item.key === "raise_complain" && item?.disposition_list)
          this.setState({ complaintReasonList: item.disposition_list })
      })
  }

  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  onChangeField = (event, parameter) => {
    event.persist();
    const value = event.target.value;
    this.setState({
      complaintReasonSelected: value
    })
  };

  riseComplaint = () => {
    this.setState({ btnLoading: true })
    billPayClientX.post(`v3/complaint/register`, {
      order_id: this.state.order.id,
      disposition: this.state.complaintReasonSelected
    }).then((response) => {
      this.setState({ btnLoading: false })
      if (response.data.success) {
        this.handleShow()
      }
      else {
        toast.error(response.data.data.message)
      }
    }).catch((error) => {
      toast.error("Something went wrong. Please try again later")
      this.setState({ btnLoading: false })
    })
  }

  closeComplaintModal = () => {
    this.props.history.push(`/bill-pay/orders/${this.state.order.id}`);
  }

  Shimmer = (props) => {
    return (
      <div className="container-fluid py-3 py-md-5">
        <div className="row">
          <div className="col-12 col-md-6 offset-md-3">
            <div className="box shine box-voucher-detail-logo"></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { order } = this.state;
    return (
      order === null ?
        <this.Shimmer />
        :
        null !== order && (
          <div className="container">
            <div className="row mt-3 mb-3 mt-md-4 mb-md-4 align-items-center">
              <div className="col-12 col-md-8 mx-auto">
                <div className="row d-flex justify-content-between">
                  <div className="col-8">
                    <small>
                      <Link to="/">
                        <FormattedMessage id="breadcrumbs.home" defaultMessage="Home" />
                      </Link>
                    </small>{" "}
                    /{" "}
                    <small>
                      <Link to="/orders">
                        <FormattedMessage id="navbar.orders" />
                      </Link>
                    </small>{" "}
                    /{" "}
                    <small>
                      <strong>
                        Rise Complaint
                      </strong>
                    </small>
                  </div>
                  <div className="col-2 d-flex">
                    <img src={bpAssuredHorizontal} alt="Assured" title="Assured" className="bp-assured-horizontal" style={{ top: "0px" }} />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3 mb-md-5">
              <div className="col-12 col-md-8 mx-auto">
                <div className="position-relative p-4 mt-1 mb-3" style={{ background: "#F4F4FF", borderRadius: "20px" }}>
                  <h5 className="text-center mb-4 font-weight-bold">Enter Details to rise complaint</h5>
                  <div className="mb-3 d-flex flex-row">
                    <img src={ic_transaction} alt="transaction" title="transaction" className="mr-3" />
                    <div className="d-flex flex-column w-100">
                      <p className="mb-1">Transaction ID</p>
                      <input
                        type="text"
                        id="transactionID"
                        readOnly
                        className="complaint-form-control"
                        value={this.state.transactionID || ""}
                      />
                    </div>
                  </div>
                  <div className="mb-3 d-flex flex-row">
                    <img src={ic_reference} alt="reference" title="reference" className="mr-3" />
                    <div className="d-flex flex-column w-100">
                      <p className="mb-1">BBPS Reference ID</p>
                      <input
                        type="text"
                        id="BBPSReferenceNumber"
                        readOnly
                        className="complaint-form-control"
                        value={this.state.BBPSReferenceNumber || ""}
                      />
                    </div>
                  </div>
                  <div className="mb-3 d-flex flex-row">
                    <img src={ic_calendar} alt="calender" title="calender" className="mr-3" />
                    <div className="d-flex flex-column w-100">
                      <p className="mb-1">Date of Transaction</p>
                      <input
                        type="text"
                        id="date"
                        readOnly
                        className="complaint-form-control"
                        value={this.state.order?.created_at.split(',')[0] || ""}
                      />
                    </div>
                  </div>
                  <div className="mb-3 d-flex flex-row">
                    <img src={ic_reason} alt="reason" title="reason" className="mr-3" />
                    <div className="d-flex flex-column w-100">
                      <p className="mb-1">Complaint Reason</p>
                      <select
                        className="complaint-form-control"
                        style={{ "background": "white", "border": "1px solid #dfdfdf", "borderRadius": "5px", "padding": "10px" }}
                        onChange={(event) =>
                          this.onChangeField(event)
                        }
                      >
                        <option value="">
                          Please choose your reason
                        </option>
                        {this.state.complaintReasonList && this.state.complaintReasonList.map((option, index) => {
                          return (
                            <option value={option.message} key={"options-" + index}>
                              {option.message}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  {this.state.btnLoading ?
                    <div className="d-flex justify-content-center">
                      <InlineLoader />
                    </div> :
                    this.state.complaintReasonSelected === "" ?
                      <button className="animated-btn btn-disabled mt-4 w-75 mx-auto d-block">RAISE TICKET</button> :
                      <button className="animated-btn mt-4 w-75 mx-auto d-block" onClick={this.riseComplaint}>RAISE TICKET</button>
                  }
                  <Modal
                    className="no-header-modal"
                    show={this.state.show}
                    onHide={this.closeComplaintModal}
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body className="text-center no-header-modal">
                      <img src={ic_success} alt="success" title="success" width="50px" className="mb-4" />
                      <h5 className="font-weight-bold">Ticket Raised!</h5>
                      You have successfully raised the<br />
                      complaint to BBPS
                      <div className="d-flex flex-row justify-content-between w-100 mt-4">
                        <button variant="primary" onClick={this.closeComplaintModal} className="animated-btn m-2">OK</button>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        )
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

export default connect(null, mapDispatchToProps)(RiseComplaint);

