import axios from 'axios';
import { currentlySelectedLanguage } from '../helper';
import { getUserAccessToken, handleUnAuthentication } from "../authorization";

const rewardClientX = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_REWARDS,
  withCredentials: true
});


//Request Interceptor
rewardClientX.interceptors.request.use(function (config) {
  const token = getUserAccessToken()
  if(token) config.headers["Authorization"] = `Bearer ${token}`;
  config.headers['Accept-Language'] = currentlySelectedLanguage();

  // Do something before request is sent
  return config;

}, function (error) {

  // Do something with request error
  return Promise.reject(error);

});

//Response Interceptor
rewardClientX.interceptors.response.use(function (response) {
  // Do something with response data
  return response;

}, function (error) {
  // ErrorToast(error.response.data, CombineErrorMessages);
  handleUnAuthentication(error.response.status)

  // Do something with response error
  return Promise.reject(error);

});

export default rewardClientX;