import HelmetMetaData from "../components/HelmetMetaData";
import { useLocation } from "react-router-dom";

function PrivacyPolicyMetaData() {
	let location = useLocation();
	let currentAppUrl = process.env.REACT_APP_URL + location.pathname;

	return (
		<HelmetMetaData
			title={`WINDS App Official Privacy Policy Statement | WINDS App`}
			description={`You can read up on the privacy statement officially issued by Winds App. We ensure the security of your data while using WINDS App.`}
			keywords={`vouchers, gift vouchers, e-vouchers, discount vouchers, online shopping sites, winds app, shopping, online payments, rewards, cash rewards, about us, offline retail vendors, privacy policy`}
			voiceSchema={<script type="application/ld+json">
				{JSON.stringify(
					{
						"@context": "https://schema.org/",
						"@type": "WebPage",
						"name": "PRIVACY POLICY",
						"speakable":
						{
							"@type": "SpeakableSpecification",
							"xpath":
								[
									"/html/head/title",
									"/html/head/meta[@name='description']/@content"
								]
						},
						"url": currentAppUrl
					}
				)}
			</script>
			}
			breadcrumbSchema={<script type="application/ld+json">
				{JSON.stringify(
					{
						"@context": "https://schema.org/",
						"@type": "BreadcrumbList",
						"itemListElement": [{
							"@type": "ListItem",
							"position": 1,
							"name": "Home",
							"item": process.env.REACT_APP_URL
						}, {
							"@type": "ListItem",
							"position": 2,
							"name": "PRIVACY POLICY",
							"item": currentAppUrl
						}]
					}

				)}
			</script>}
		></HelmetMetaData>
	);
}

export default PrivacyPolicyMetaData;