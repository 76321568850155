import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
import LoginDialog from '../../components/LoginDialog'

import vehicle from '../../assets/icon/vehicle.png'
import health from '../../assets/icon/health.png';
import life from'../../assets/icon/life.png';
import LifeInsurance from "./components/LifeInsurance";
import VehicleInsurance from "./components/VehicleInsurance";
import HealthInsurance from "./components/Healthinsurance";
import { readCookie } from "../../utils/helper";
import { getIsMob, getIsKamaie } from "../../utils/helper";
const Insurance = ({heading_text, profile }) => {
  const [isLoginDialogOpen, setLoginDialog] = useState(false)
  const [vehicleInsurance, setvehicleInsurance] = useState(true)
  const [healthInsurance, sethealthInsurance] = useState(false)
  const [lifeInsurance, setlifeInsurance] = useState(false)
  const [selectedType, setSelectedType] = useState("");
  const [isMob, setIsMob] = useState(null);
  const [is_kamaie, setIsKamaie] = useState(null);

  // const queryParameters = new URLSearchParams(window.location.search);
  // const isMob = queryParameters.get("isMob");
  // const is_kamaie=queryParameters.get("is_kamaie");


  useEffect(() => {
    const timer = setTimeout(() => {
      const mobValue = getIsMob();
      const kamaieValue = getIsKamaie();
      setIsMob(mobValue);
      setIsKamaie(kamaieValue);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const openLoginDialog = () => {
    setLoginDialog(true)
  };
  const closeLoginDialog = () => {
    setLoginDialog(false)
  };
  useEffect(() => {
    if (!profile )
      openLoginDialog()
  }, [profile])
  const vehicleState = () => {
    setvehicleInsurance(true);
    sethealthInsurance(false);
    setlifeInsurance(false);
    setSelectedType("vehicle");
  }
  const healthState = () => {
    sethealthInsurance(true);
    setvehicleInsurance(false);
    setlifeInsurance(false)
    setSelectedType("health");
  }
  const lifeState = () => {
    setlifeInsurance(true);
    sethealthInsurance(false);
    setvehicleInsurance(false);
    setSelectedType("life");
  }
  const getStyle = (type) => {
    return {
      backgroundColor: selectedType === type ? "rgb(125,350,200,0.4)" : "transparent", 
    };
  };
  return (
    <section>
      {(isMob != 1 && is_kamaie !=1) && (
        <div className="insurance-banner">
          <div className="container pb-3">
          
            <div className="row  ">
              <div className="col-4 head-margin ">
                <h1 className="font-proxima-nova-bold">
                  <FormattedMessage
                    id="home.get-insurance"
                    defaultMessage="Get Insurance"
                    values={{ what: "react-intl" }}
                  />
                </h1>
                
                <h2>
                  
                  {heading_text?.insurance?.percentage}
                </h2>
              </div>
              <div className="col-8 d-flex justify-content-center"></div>
            </div>
          </div>
        </div>
      )}
      <div className="container" style={{ backgroundColor: "#FFF" }}>
        <div className="row">
          <div className="col-12">
            <h5 className="title mb-md-3 pt-4 mb-3 my-3 headingclass" style={{ textAlign: "center" }}>Choose your insurance type from below</h5>
            <div className="row">
              <div className="col-1 col-sm-2 col-md-3 col-lg-4 col-xl-4 "></div>
              <div className="col-10 col-sm-8 col-md-6 col-lg-4 col-xl-4 ">
                <div className="row">
                  <div className=" col-4 px-2 d-flex flex-column justify-content-center align-items-center border rounded-left  " onClick={vehicleState} style={vehicleInsurance ? { backgroundColor: "rgb(125,350,200,0.4)" } : getStyle("vehicle")}>
                    <img src={vehicle} className="img-fluid w-75 h-75" alt="Vehcile Insurance"></img>
                    vehicle
                  </div>
                  <div className=" col-4 insu-type health-div px-2 d-flex flex-column justify-content-center align-items-center border-top border-bottom" onClick={healthState} style={getStyle("health")}>
                    <img src={health} className="img-fluid w-75 h-75" alt="Health Insurance"></img>
                    Health</div>
                  <div className="col-4 insu-type life-insu-div px-2 d-flex flex-column justify-content-center fs-6 align-items-center border rounded-right" onClick={lifeState} style={getStyle("life")}>
                    <img src={life} className="img-fluid w-75 h-75" alt="Life Insurance"></img>
                    Life</div>
                </div>
              </div>
              <div className="col-1 col-sm-2 col-md-3 col-lg-4 col-xl-4 "></div>
            </div>
            <h6><p className="mt-2 text-center">By clicking on any insurance, you are agreeing to the <a href="https://winds-customer-static.web.app/terms-n-conditions/en/" target="_blank" rel="noopener noreferrer"><span className=" text-decoration-underline text-primary" style={{textDecoration:"underline"}}>Terms & Conditions</span></a></p></h6>
          </div>
        </div>
      </div>
      {!profile  && isLoginDialogOpen && (  
        <LoginDialog
          isLoginDialogOpen={isLoginDialogOpen}
          closeLoginDialog={closeLoginDialog}
        />
      )}
      {healthInsurance &&
        <HealthInsurance openLoginDialog={openLoginDialog} profile={profile} />
      }
      {vehicleInsurance &&
        <VehicleInsurance openLoginDialog={openLoginDialog} profile={profile} />
      }
      {
        lifeInsurance &&
        <LifeInsurance openLoginDialog={openLoginDialog} profile={profile} />
      }
    </section>
  )
};
function mapStateToProps(state) {
  return {
    insurance: state.home.insurance,
    heading_text: state.home.heading_text,
    profile: state.global.profile,
  };
}
function mapDispatchToProps(dispatch) {
  return {
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Insurance);

