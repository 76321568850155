import React, { createContext, useState, useEffect } from "react";
import { fetchRemoteConfiguration } from "../utils/helper";

export const FirebaseContext = createContext();

// This context provider is passed to any component requiring the context
export const FirebaseProvider = ({ children }) => {
  const [maintenanceData, setMaintenance] = useState({
    app_maintenance: false,
    bill_pay: false,
    pay_now: false,
    voucher_detail: false,
    wallet_withdraw_message: {},
    is_winds_giftcard_enabled: null,
    redeem_winds_giftcard_url: null,
    phonepe_refund_policy: null
  });

  useEffect(() => {
    fetchRemoteConfiguration("web_maintenance")
      .then((response) => {
        if (response) {
          setMaintenance(response);
        } else {
          setMaintenance(maintenanceData);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    fetchRemoteConfiguration("wallet_withdraw_message")
      .then((response) => {
        if (response) {
          setMaintenance({
            ...maintenanceData,
            wallet_withdraw_message: response
          });
          maintenanceData[`wallet_withdraw_message`] = response
        }
      })

    fetchRemoteConfiguration("is_winds_giftcard_enabled")
      .then((response) => {
        setMaintenance({
          ...maintenanceData,
          is_winds_giftcard_enabled: response
        });
        maintenanceData[`is_winds_giftcard_enabled`] = response
      })

    fetchRemoteConfiguration("redeem_winds_giftcard_url")
      .then((response) => {
        setMaintenance({
          ...maintenanceData,
          redeem_winds_giftcard_url: response
        });
        maintenanceData[`redeem_winds_giftcard_url`] = response
      })

      fetchRemoteConfiguration("phonepe_refund_policy")
      .then((response) => {
        setMaintenance({
          ...maintenanceData,
          phonepe_refund_policy: response
        });
        maintenanceData[`phonepe_refund_policy`] = response
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FirebaseContext.Provider value={maintenanceData} >
      {children}
    </FirebaseContext.Provider>
  );
};
