import React from "react";

function WinnersPartners() {
  return (
    <div className="winners-page testimonialsWrapper">
      <div className="container">
        <div className="hero">
          <div className="container text-center">
            <span>Testimonials</span>
            <h1 className="comm-head-fon">What Our Cashback Winners Are Saying About The WINDS App</h1>
          </div>
        </div>
        <div className="testimonials row">
          <div className="col-12 col-md-6 col-lg-4">
            <div className="testimonial">
              <div className="embed mb-3">
                <iframe src="https://www.youtube.com/embed/EjobAhgG8tk" title="YouTube video player" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen></iframe>
              </div>
              <h5 className="mb-2">Sawan Kumar, Varanasi (Uttar Pradesh) </h5>
              <p className="text-primary mb-1">Amount Won : ₹ 5 Lakhs</p>
              <small className="text-muted">Winner for the month of November, 2022</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WinnersPartners;