import React from "react";
import { ProgressBar } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const CompleteProfileDialog = (props) => {
  const { profile } = useSelector((state) => state.global)

  return (
    <React.Fragment>
      <Modal
        show={props.isCompleteProfileDialogOpen}
        // onHide={props.closeCompleteProfileDialog}
        className="font-proxima-nova"
        centered
      >
        <Modal.Body className="p-md-5 p-3">
          <h4 className="text-center pb-3 font-weight-bold">Account Details</h4>
          <div className="mb-2">
            <h5 className="pb-3 mb-0">Basic profile details {profile?.basic_profile_progress_percent}% Completed</h5>
            <ProgressBar now={profile?.basic_profile_progress_percent} className="profile-progress-bar" variant="progress-bar-style" />
            {
              profile?.basic_profile_progress_percent !== 100 &&
              (
                profile?.basic_profile_completion_wrp === 0 ?
                  <p style={{ color: "#4E77F7" }} className="mb-4 mt-1">Complete your basic profile</p> :
                  <p style={{ color: "#4E77F7" }} className="mb-4 mt-1">Complete your basic profile and win  {profile?.basic_profile_completion_wrp} WRP</p>
              )
            }
          </div>
          <div className="mb-2 mt-4">
            <h5 className="pb-3 mb-0">Preference details {profile?.preference_percentage}% Completed</h5>
            <ProgressBar now={profile?.preference_percentage} className="profile-progress-bar" variant="progress-bar-style" />
            {
              profile?.preference_percentage !== 100 &&
              (
                profile?.preference_alerts_completion_wrp === 0 ?
                  <p style={{ color: "#4E77F7" }} className="mb-4 mt-1">Complete your preference details</p> :
                  <p style={{ color: "#4E77F7" }} className="mb-4 mt-1">Complete your preference details and win {profile?.preference_alerts_completion_wrp} WRP</p>
              )
            }
          </div>
          <div className="d-flex flex-row justify-content-center w-100 ">
            <Link to="/profile">
              <button className="animated-btn py-1 px-5 " style={{ height: "40px" }}>Complete Now</button>
            </Link>
          </div>
          <span style={{ color: "#233ECF" }} className="d-flex justify-content-center mt-2 cursor-pointer" onClick={props.closeCompleteProfileDialog}>Skip</span>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default CompleteProfileDialog;
