import React from "react";
import Modal from "react-bootstrap/Modal";
import { FormattedMessage } from "react-intl";
import WindsLoader from "../../../components/WindsLoader";
import { copyToClipboard } from "../../../utils/helper";
import voucherClientX from "../../../utils/api/voucherClientX";

const ViewVoucher = (props) => {
  const id = props.voucher.item_order_id;
  const [vouchers, setVouchers] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    voucherClientX
      .get(`v1/vouchers/orders/${id}/codes`)
      .then((response) => {
        let data = response.data.data;
        setVouchers(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        props.onDismiss();
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Modal show onHide={props.onDismiss}>
      <Modal.Body className="text-center p-5 font-proxima-nova">
        <div>
          <h5>
            <strong>
              <FormattedMessage
                id="order.voucher-details"
                defaultMessage="Voucher Details"
              />
            </strong>
          </h5>

          {loading ? (
            <div className="row">
              <div className="col-12">
                <WindsLoader
                  rows={2}
                  columns={1}
                  height={50}
                  width={80}
                  padding={1}
                  speed={1}
                />
              </div>
            </div>
          ) : (
            vouchers &&
            vouchers.map((voucher, index) => {
              return (
                <React.Fragment key={voucher.voucher_number}>
                  <div className="text-left mt-5 voucher-details">
                    <p className="text-secondary">
                      {voucher.voucher_name} Code:
                    </p>
                    <div
                      className="input-group mb-3"
                      style={{ cursor: "pointer" }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        placeholder={voucher.voucher_number}
                        disabled
                      />
                      <div
                        className="input-group-append"
                        onClick={() => copyToClipboard(voucher.voucher_number)}
                      >
                        <span
                          className="input-group-text bg-success text-white text-uppercase"
                          id="copy"
                        >
                          <FormattedMessage
                            id="general.copy"
                            defaultMessage="Copy"
                          />
                        </span>
                      </div>
                    </div>
                    <p className="text-secondary">
                      <FormattedMessage
                        id="order.voucher-pin"
                        defaultMessage="Voucher PIN"
                      />
                    </p>
                    {voucher.voucher_pin && (
                      <div
                        className="input-group mb-3"
                        style={{ cursor: "pointer" }}
                      >
                        <input
                          type="text"
                          className="form-control"
                          placeholder={voucher.voucher_pin}
                          disabled
                        />
                        <div
                          className="input-group-append"
                          onClick={() => copyToClipboard(voucher.voucher_pin)}
                        >
                          <span
                            className="input-group-text bg-success text-white text-uppercase"
                            id="copy"
                          >
                            <FormattedMessage
                              id="general.copy"
                              defaultMessage="Copy"
                            />
                          </span>
                        </div>
                      </div>
                    )}
                    <p className="text-secondary">
                      <FormattedMessage
                        id="order.expiry-date"
                        defaultMessage="Expiry Date"
                      />
                      : {voucher.expiry_date}
                    </p>
                  </div>
                  <hr />
                </React.Fragment>
              );
            })
          )}

          <button
            className="btn btn-primary home-btn mt-4"
            onClick={props.onDismiss}
          >
            <FormattedMessage id="general.ok" defaultMessage="OK" />
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewVoucher;
