export function fetchingEarnings() {
	return {
		type: 'GET_EARNINGS',
	}
}

export function setEarnings(totalEarnings, nextPage) {
	return {
		type: 'GET_EARNINGS_SUCCESS',
		totalEarnings,
	}
}

export function fetchEarningsHistory() {
	return {
		type: 'FETCHING_EARNING_HISTORY',
	}
}

export function setEarningsHistory(earningsHistory) {
	return {
		type: 'SET_EARNINGS_HISTORY',
		earningsHistory,
	}
}

export function fetchEarningsHistoryDetails() {
	return {
		type: 'FETCHING_EARNING_HISTORY',
	}
}

export function fetchingNextPageDetails() {
	return {
		type: 'FETCHING_NEXT_PAGE_DETAILS'
	}
}

export function setEarningsHistoryDetails(earningsHistoryDetails) {
	return {
		type: 'SET_EARNINGS_HISTORY_DETAILS',
		earningsHistoryDetails,
	}
}

export function fetchReferrals() {
	return {
		type: 'GET_REFERRALS',
	}
}

export function setReferrals(referrals) {
	return {
		type: 'SET_REFERRALS',
		referrals
	}
}